import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import { IwPopoverOptions, IwModalService } from '@platform/shared';
import { TmDropzoneComponent } from '@tm-shared/dragndrop/dropzone.component';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmTechLoaderComponentService } from '@tm-shared/tech-loader/tech-loader-component.service';
import { TmTechLoaderType } from '@tm-shared/tech-loader/tech-loader.model';
import { Status } from 'plugins/config/generated/config';
import { TmConfigLocalService } from 'plugins/config/services';
import { getPrivilegeFor } from 'plugins/tech-classifier/tech-classifier-helpers';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import { CheckDocumentState, TrainingState } from 'typings/generated/technology-autoling';
import { TmAutolingDocumentCheckService } from '../../tech-autoling/autoling-document-check.service';
import { TmAutolingTrainingService } from '../../tech-autoling/autoling-training.service';
import {
  CategoryService,
  CheckService,
  ClassifierType,
  ClassifierTypeToken,
  TrainingService,
} from '../tech-classifier-providers';
import { CategoryServiceInterface, CheckFilesModal, Classifiers, Editable } from './tech-classifier-toolbar.model';

interface I18nErrorKeys {
  categoryNotSelected: string;
  categoryIncludedInProtectedDocs: string;
  noAccessPrivilege: string;
  trainingInProgress: string;
  trainingIsDisabled: string;
  checkIsDisabled: string;
  trainingSucceed: string;
  fewCategories: string;
  emptyCategory: string;
  trainingNotDone: string;
  documentInProgress: string;
  onlyNotEditableGo?: string;
}

// @translate 'tech-classifier.autoling.page.canTrainErrors.categoryNotSelected'
// @translate 'tech-classifier.autoling.page.canTrainErrors.categoryIncludedInProtectedDocs'
// @translate 'tech-classifier.autoling.page.canTrainErrors.noAccessPrivilege'
// @translate 'tech-classifier.autoling.page.canTrainErrors.trainingInProgress'
// @translate 'tech-classifier.autoling.page.canTrainErrors.checkIsDisabled'
// @translate 'tech-classifier.autoling.page.canTrainErrors.trainingIsDisabled'
// @translate 'tech-classifier.autoling.page.canTrainErrors.trainingSucceed'
// @translate 'tech-classifier.autoling.page.canTrainErrors.fewCategories'
// @translate 'tech-classifier.autoling.page.canTrainErrors.emptyCategory'
// @translate 'tech-classifier.autoling.page.canCheckErrors.trainingNotDone'
// @translate 'tech-classifier.autoling.page.canCheckErrors.documentInProgress'
const autolingI18nErrorKeys: I18nErrorKeys = {
  categoryNotSelected: 'tech-classifier.autoling.page.canTrainErrors.categoryNotSelected',
  categoryIncludedInProtectedDocs: 'tech-classifier.autoling.page.canTrainErrors.categoryIncludedInProtectedDocs',
  noAccessPrivilege: 'tech-classifier.autoling.page.canTrainErrors.noAccessPrivilege',
  trainingInProgress: 'tech-classifier.autoling.page.canTrainErrors.trainingInProgress',
  trainingIsDisabled: 'tech-classifier.autoling.page.canTrainErrors.trainingIsDisabled',
  checkIsDisabled: 'tech-classifier.autoling.page.canTrainErrors.checkIsDisabled',
  trainingSucceed: 'tech-classifier.autoling.page.canTrainErrors.trainingSucceed',
  fewCategories: 'tech-classifier.autoling.page.canTrainErrors.fewCategories',
  emptyCategory: 'tech-classifier.autoling.page.canTrainErrors.emptyCategory',
  trainingNotDone: 'tech-classifier.autoling.page.canCheckErrors.trainingNotDone',
  documentInProgress: 'tech-classifier.autoling.page.canCheckErrors.documentInProgress',
};

// @translate 'tech-classifier.imageClassifier.page.canTrainErrors.categoryNotSelected'
// @translate 'tech-classifier.imageClassifier.page.canTrainErrors.categoryIncludedInProtectedDocs'
// @translate 'tech-classifier.imageClassifier.page.canTrainErrors.noAccessPrivilege'
// @translate 'tech-classifier.imageClassifier.page.canTrainErrors.trainingInProgress'
// @translate 'tech-classifier.imageClassifier.page.canTrainErrors.checkIsDisabled'
// @translate 'tech-classifier.imageClassifier.page.canTrainErrors.trainingIsDisabled'
// @translate 'tech-classifier.imageClassifier.page.canTrainErrors.trainingSucceed'
// @translate 'tech-classifier.imageClassifier.page.canTrainErrors.fewCategories'
// @translate 'tech-classifier.imageClassifier.page.canTrainErrors.emptyCategory'
// @translate 'tech-classifier.imageClassifier.page.canCheckErrors.trainingNotDone'
// @translate 'tech-classifier.imageClassifier.page.canCheckErrors.documentInProgress'
// @translate 'tech-classifier.imageClassifier.page.canCheckErrors.onlyNotEditableGo'
const imageClassifierI18nErrorKeys: I18nErrorKeys = {
  categoryNotSelected: 'tech-classifier.imageClassifier.page.canTrainErrors.categoryNotSelected',
  categoryIncludedInProtectedDocs:
    'tech-classifier.imageClassifier.page.canTrainErrors.categoryIncludedInProtectedDocs',
  noAccessPrivilege: 'tech-classifier.imageClassifier.page.canTrainErrors.noAccessPrivilege',
  trainingInProgress: 'tech-classifier.imageClassifier.page.canTrainErrors.trainingInProgress',
  checkIsDisabled: 'tech-classifier.imageClassifier.page.canTrainErrors.checkIsDisabled',
  trainingIsDisabled: 'tech-classifier.imageClassifier.page.canTrainErrors.trainingIsDisabled',
  trainingSucceed: 'tech-classifier.imageClassifier.page.canTrainErrors.trainingSucceed',
  fewCategories: 'tech-classifier.imageClassifier.page.canTrainErrors.fewCategories',
  emptyCategory: 'tech-classifier.imageClassifier.page.canTrainErrors.emptyCategory',
  trainingNotDone: 'tech-classifier.imageClassifier.page.canCheckErrors.trainingNotDone',
  documentInProgress: 'tech-classifier.imageClassifier.page.canCheckErrors.documentInProgress',
  onlyNotEditableGo: 'tech-classifier.autoling.page.canCheckErrors.onlyNotEditableGo',
};

@Component({
  selector: 'tm-autoling-page-toolbar',
  templateUrl: './tech-classifier-toolbar.component.html',
  styleUrls: ['./tech-classifier-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
        closeOnMousemoveOutside: true,
        triggers: 'mouseenter:mouseleave',
        placement: 'bottom-start',
      }),
    },
  ],
})
export class TmClassifierPageToolbarComponent implements OnDestroy {
  /**
   * Selected category id or 'null'
   */
  @Input() public set categoryId(categoryId: string | null) {
    this.selectedCategoryId.next(categoryId);
  }

  public get categoryId(): string | null {
    return this.selectedCategoryId.getValue();
  }

  // TODO: use common interface
  @Input() public checkFilesComponent: Type<CheckFilesModal>;

  @Output() public create = new EventEmitter<void>();
  @Output() public edit = new EventEmitter<string>();
  @Output() public remove = new EventEmitter<string>();

  protected selectedCategoryId = new BehaviorSubject<string | null>(null);
  public canCheckFile: Observable<boolean> = this.getCanCheckFile();
  public checkFileProgress: Observable<boolean> = this.documentCheckStateIs(CheckDocumentState.progress);
  public canCreate: Observable<boolean> = this.getCanCreate();
  public canEdit: Observable<boolean> = this.getCanEdit();
  public canImportExport: Observable<boolean> = this.getCanImportExport();
  public canRemove: Observable<boolean> = this.getCanRemove();
  public canStartTraining: Observable<boolean> = this.getCanStartTraining();
  public selectedCategoryName = this.getSelectedCategoryName();
  public canRemoveErrors = this.getCanRemoveErrors().pipe(shareReplay(1));
  public canTrainErrors = this.getCanTrainErrors().pipe(shareReplay(1));
  public canCheckFileErrors = this.getCanCheckFileErrors().pipe(shareReplay(1));

  @ViewChild('documentCheckLoader', { static: false }) private documentCheckLoader: TmDropzoneComponent;
  private checkFileRequested = new Subject<void>();
  private destroyed = new Subject<void>();

  private get i18nErrorKey(): I18nErrorKeys {
    if (this.type === ClassifierType.autoling) {
      return autolingI18nErrorKeys;
    }
    return imageClassifierI18nErrorKeys;
  }

  constructor(
    @Inject(ClassifierTypeToken) public type: ClassifierType,
    // TODO: use interfaces instead of classes
    @Inject(TrainingService) protected trainingService: TmAutolingTrainingService,
    @Inject(CategoryService) private categoryService: CategoryServiceInterface,
    @Inject(CheckService) private checkService: TmAutolingDocumentCheckService,
    private configService: TmConfigLocalService,
    private privilegesService: TmPrivilegesService,
    private techLoaderService: TmTechLoaderComponentService,
    private modalService: IwModalService
  ) {}

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public removeCategory(): void {
    if (this.categoryId) {
      this.remove.emit(this.categoryId);
    }
  }

  public editCategory(): void {
    if (this.categoryId) {
      this.edit.emit(this.categoryId);
    }
  }

  public checkFileBtnClicked(): void {
    this.checkFileRequested.next();
    this.checkService.state$
      .pipe(
        take(1),
        switchMap((checkState) => {
          /**
           * Show file selector if we have nothing to display
           */
          if (checkState === CheckDocumentState.pristine) {
            this.documentCheckLoader.showFileSelector();
            return this.documentCheckLoader.change;
          }

          return of([]);
        }),
        takeUntil(this.destroyed),
        takeUntil(this.checkFileRequested)
      )
      .subscribe((files: File[]) => {
        this.documentCheckLoader.clear();
        this.showFileCheck(files);
      });
  }

  protected async showFileCheck(files: File[]): Promise<void> {
    const currentModal = await this.modalService.open(this.checkFilesComponent, {});

    // If something went wrong - throw error in console
    if (!currentModal) {
      throw new Error('Please check provided constructor in tmDeleteConfirmDialog input');
    }

    if (files) {
      currentModal.component.checkFiles(files);
    }
  }

  public startTraining() {
    this.trainingService.startTraining();
  }

  public importTech(): void {
    this.techLoaderService.import(TmTechLoaderType.technology);
  }

  public exportTech(): void {
    this.techLoaderService.export(TmTechLoaderType.technology);
  }

  private getCanImportExport() {
    return (
      this.canImportExport ||
      combineLatest([
        this.configStateIs(Status.LOCKED),
        this.configStateIs(Status.NEEDS_TRAINING),
        this.trainingStateIs(TrainingState.progress),
        this.getExportImportPrivilege(),
      ]).pipe(
        map(([configLock, needsTraining, trainingProgress, isCanImportExport]) =>
          Boolean(!configLock && !needsTraining && !trainingProgress && isCanImportExport)
        )
      )
    );
  }

  private getCanCheckFile() {
    return this.canCheckFile || this.getCanCheckFileErrors().pipe(map((errors) => !errors.length));
  }

  private getCanEdit() {
    return (
      this.canEdit ||
      combineLatest([
        this.categoryNotSelected(),
        this.canNot('full_access'),
        this.trainingStateIs(TrainingState.progress),
        this.categoryIsNotEditable(),
      ]).pipe(map((conditions) => conditions.every((con) => !con)))
    );
  }

  private getCanCreate() {
    return (
      this.canCreate ||
      combineLatest([this.can('full_access'), this.trainingStateIs(TrainingState.progress)]).pipe(
        map(([ok, trainingProgress]) => Boolean(ok && !trainingProgress))
      )
    );
  }

  private getSelectedCategoryName(): Observable<string> {
    return (
      this.selectedCategoryName ||
      this.selectedCategoryId.pipe(
        switchMap((id) =>
          id ? this.categoryService.getById(id).pipe(map((data) => (data ? data.DISPLAY_NAME : ''))) : of('')
        )
      )
    );
  }

  private getCanRemove(): Observable<boolean> {
    return (
      this.canRemove ||
      combineLatest([
        this.categoryNotSelected(),
        this.categoryInProtectedDocs(),
        this.categoryIsNotEditable(),
        this.canNot('full_access'),
        this.trainingStateIs(TrainingState.progress),
      ]).pipe(map((conditions) => conditions.every((con) => !con)))
    );
  }

  private trainingStateIs(checkState: TrainingState) {
    return this.trainingService.state$.pipe(map((state) => state === checkState));
  }

  private configStateIs(status: Status) {
    return this.configService.dataWithUpdates$.pipe(map((x) => x.STATUS === status));
  }

  private getExportImportPrivilege() {
    return this.privilegesService.can('analysis:export:full_access');
  }

  private documentCheckStateIs(checkState: CheckDocumentState) {
    return this.checkService.state$.pipe(map((state) => state === checkState));
  }

  private categoryNotSelected() {
    return this.selectedCategoryId.pipe(map((id) => id === null));
  }

  private categoryInProtectedDocs() {
    return this.selectedCategoryId.pipe(
      switchMap((id) => {
        if (id === null) {
          return of(false);
        }

        return this.categoryService
          .getById(id)
          .pipe(map((data) => Boolean(data && data.protected_documents && data.protected_documents.length > 0)));
      })
    );
  }

  private hasEditable(item?: Partial<Classifiers>): item is Editable {
    return (item && (item as Editable).editable) !== undefined;
  }

  private categoryIsNotEditable() {
    return this.selectedCategoryId.pipe(
      switchMap((id) => {
        if (id === null) {
          return of(false);
        }

        return (
          this.categoryService
            .getById(id)
            // Backend removes category before id changes
            .pipe(map((data?: Classifiers) => (data && this.hasEditable(data) ? Boolean(!data.editable) : false)))
        );
      })
    );
  }

  private can(action: 'show' | 'full_access') {
    return this.privilegesService.can(getPrivilegeFor(this.type, action));
  }

  private canNot(action: 'show' | 'full_access') {
    return this.can(action).pipe(map((can) => !can));
  }

  private getCanCheckFileErrors(): Observable<string[]> {
    const getCategoryList = this.categoryService.getDataStream().pipe(shareReplay(1));
    const trainingNotDone = this.trainingStateIs(TrainingState.success).pipe(map((x) => !x));
    const checkIsDisabled = this.trainingStateIs(TrainingState.disabled);
    const onlyNotEditableGo = getCategoryList.pipe(
      map((res) =>
        res.data.every((x) => {
          return this.hasEditable(x) ? !x.editable : false;
        })
      )
    );

    return (
      this.canCheckFileErrors ||
      combineLatest([
        trainingNotDone.pipe(map((e) => (e ? [this.i18nErrorKey.trainingNotDone] : false))),
        checkIsDisabled.pipe(map((err) => (err ? this.i18nErrorKey.checkIsDisabled : false))),
        onlyNotEditableGo.pipe(map((err) => (err ? this.i18nErrorKey.onlyNotEditableGo : false))),
      ]).pipe(
        map((errors) => {
          return errors.filter((e) => !!e);
        })
      )
    );
  }

  private getCanRemoveErrors(): Observable<string[]> {
    const includedInProtectedDocs = this.selectedCategoryId.pipe(
      switchMap((id) => (id ? this.categoryService.getById(id) : of(null))),
      map((data) => !!(data && data.protected_documents && data.protected_documents.length > 0))
    );

    return (
      this.canRemoveErrors ||
      combineLatest([
        includedInProtectedDocs.pipe(map((err) => (err ? this.i18nErrorKey.categoryIncludedInProtectedDocs : false))),
      ]).pipe(map((errors) => errors.filter((e) => !!e)))
    );
  }

  /**
   * Collect all errors which prevents from start training
   */
  private getCanTrainErrors(): Observable<string[]> {
    const getCategoryList = this.categoryService.getDataStream().pipe(shareReplay(1));

    const noAccessPrivilege = this.canNot('full_access');
    const trainingInProgress = this.trainingStateIs(TrainingState.progress);
    const fewCategories = getCategoryList.pipe(map((res) => res.data.length <= 1));
    const trainingSucceed = combineLatest([this.trainingStateIs(TrainingState.success), fewCategories]).pipe(
      // Do not report success if there is too few categories
      map(([succeed, few]) => (few ? false : succeed))
    );
    const trainingIsDisabled = this.trainingStateIs(TrainingState.disabled);
    const emptyCategory = getCategoryList.pipe(
      map((res) =>
        res.data.some((x) => {
          return this.hasEditable(x) ? x.editable && !x.documentCount : !x.documentCount;
        })
      )
    );

    return (
      this.canTrainErrors ||
      combineLatest([
        noAccessPrivilege.pipe(map((err) => (err ? this.i18nErrorKey.noAccessPrivilege : false))),
        trainingInProgress.pipe(map((err) => (err ? this.i18nErrorKey.trainingInProgress : false))),
        trainingSucceed.pipe(map((err) => (err ? this.i18nErrorKey.trainingSucceed : false))),
        fewCategories.pipe(map((err) => (err ? this.i18nErrorKey.fewCategories : false))),
        emptyCategory.pipe(map((err) => (err ? this.i18nErrorKey.emptyCategory : false))),
        trainingIsDisabled.pipe(map((err) => (err ? this.i18nErrorKey.trainingIsDisabled : false))),
      ]).pipe(map((errors) => errors.filter((e) => !!e)))
    );
  }

  private getCanStartTraining(): Observable<boolean> {
    return this.getCanTrainErrors().pipe(map((errors) => !errors.length));
  }
}
