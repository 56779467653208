import { Pipe, PipeTransform } from '@angular/core';
import { TmCronJobPresetType, TmCronJobServerModel, getPresetType } from './cronjob';

type TmCronJobPresetName = 'daily' | 'hours' | 'minutes' | 'weekdays' | 'unknown';

@Pipe({ name: 'tmCronJobPresetName' })
export class TmCronJobPresetNamePipe implements PipeTransform {
  public transform(job: Partial<TmCronJobServerModel>): TmCronJobPresetName {
    const presetType = getPresetType(job);

    switch (presetType) {
      case TmCronJobPresetType.daily:
        return 'daily';
      case TmCronJobPresetType.hours:
        return 'hours';
      case TmCronJobPresetType.minutes:
        return 'minutes';
      case TmCronJobPresetType.weekdays:
        return 'weekdays';
      default:
        return 'unknown';
    }
  }
}
