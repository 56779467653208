import { Input, OnDestroy, Type, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IwModalService, ModalDialogRef } from '@platform/shared';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { TmAccessSelectModalComponent } from './access-select-modal.component';

/**
 * @Directive is used due to Angular 10  DI system:
 * https://angular.io/guide/migration-undecorated-classes
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class TmAccessSelectComponent implements OnDestroy {
  @Input() public control: FormControl;

  public abstract modalComponent: Type<TmAccessSelectModalComponent>;

  public abstract service: TmCollectionLoader<unknown>;

  private _destroy$ = new Subject();

  private _modal?: ModalDialogRef<TmAccessSelectModalComponent>;

  constructor(private _modalService: IwModalService) {}

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public closeDialog(): void {
    if (this._modal) {
      this._modal.close(null);
      delete this._modal;
    }
  }

  public getSearchCallback(): (options: { query: string }) => Observable<IwSelectItem[]> {
    return ({ query }) => {
      return this.service
        .get({
          params: {
            'filter[DISPLAY_NAME]': `${query}*`,
          },
        })
        .pipe(map((response) => this._mapToIwSelect(response.data)));
    };
  }

  public getSelectedIds(): string[] {
    if (!this.control.value) {
      return [];
    }

    return this.control.value.map((itemOrId: IwSelectItem | string) => this._parseIwSelectValue(itemOrId));
  }

  public prepareDataForModal(_modal: any) {}

  public async openDialog(): Promise<void> {
    if (!this._modal) {
      const modal = (this._modal = await this._modalService.open(this.modalComponent));
      const component = modal.component;

      /**
       * Bind formControl -> modal data updates
       */
      component.selectedIds = this.getSelectedIds();
      this.prepareDataForModal(component);

      /**
       * Bind modal -> formControl data updates
       */
      component.change
        .pipe(
          takeUntil(modal.dismissed),
          switchMap((ids: string[]) => this.service.getItemsByIds(ids)),
          map((items: any[]) => this._mapToIwSelect(items)),
          takeUntil(this._destroy$)
        )
        .subscribe((data: IwSelectItem[]) => this.control.setValue(data));

      component.exit.pipe(take(1), takeUntil(this._destroy$), takeUntil(modal.dismissed)).subscribe(() => {
        modal.dismiss(null);
      });

      /**
       * Remove _modal reference
       */
      modal.dismissed.pipe(takeUntil(this._destroy$), take(1)).subscribe(() => delete this._modal);
    }
  }

  private _mapToIwSelect(items: any[]): IwSelectItem[] {
    return items.map((item) => {
      return {
        value: item[this.service.idAttribute],
        data: item,
        label: item.DISPLAY_NAME,
      };
    });
  }

  private _parseIwSelectValue<T = any>(itemOrId: IwSelectItem<T> | number | string): string {
    if (typeof itemOrId === 'object') {
      return `${itemOrId.value}`;
    }

    return `${itemOrId}`;
  }
}
