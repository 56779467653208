import { Component, Input } from '@angular/core';
import { AbstractTabComponentDirective } from './abstract-tab-component.directive';
import { TabComponent } from '../search-select.module';

@Component({
  selector: 'tm-search-tab-host',
  template: ` <ng-container *ngIf="component">
    <ng-container *ngxComponentOutlet="component"></ng-container>
  </ng-container>`,
})
export class SearchTabHostComponent extends AbstractTabComponentDirective {
  @Input() component?: TabComponent;
}
