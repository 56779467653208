import { TranslateService } from '@ngx-translate/core';
import { TermDiffProperties } from 'typings/generated/audit-term';
import { AuditExtendGridData } from '../audit-extend.service';
import { AuditEvent } from '../audit.model';
import { isNull } from 'lodash';
import {
  VisibleAttributesOption,
  getAuditExtendRowData,
  getAuditAttributesFrom,
  transformBooleanYesNo,
  getDefaultColumns,
  transformLanguage,
  TransformOptions,
  transformTech2Category,
} from './audit-common';
import { TmAuditBrItemsCellComponent } from '../audit-cell-components/audit-br-items.component';

const auditTermVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'TEXT', i18nKey: 'audit.entries.term.text' },
  { path: 'LANGUAGE', i18nKey: 'audit.entries.term.termLanguage', transform: transformLanguage },
  { path: 'MORPHOLOGY', i18nKey: 'audit.entries.term.termMorphology', transform: transformBooleanYesNo },
  {
    path: 'term2category',
    i18nKey: 'audit.entries.term.term2category',
    transform: transforTerm2category,
    component: TmAuditBrItemsCellComponent,
  },
  {
    path: 'CASE_SENSITIVE',
    i18nKey: 'audit.entries.term.termCaseSensitive',
    transform: transformBooleanYesNo,
  },
  { path: 'NOTE', i18nKey: 'audit.entries.term.note' },
  { path: 'term2category', i18nKey: 'audit.entries.term.categories', transform: transformTech2Category },
];

export function getTermGridData(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  const cols = getDefaultColumns(data);
  const result: AuditExtendGridData[] = [];
  const baseData = getAuditExtendRowData({
    t,
    cols: cols,
    data: getAuditAttributesFrom(data),
    visibleAttributes: auditTermVisibleAttributes,
  });

  if (baseData.length) {
    result.push({
      cols,
      data: baseData,
    });
  }

  return result;
}

export function transforTerm2category(
  term2category: TermDiffProperties['term2category'] | undefined,
  o: TransformOptions
): string[] {
  if (!term2category) {
    return [];
  }

  return term2category.map((c) => {
    const result: string[] = [];

    if (!isNull(c.CHARACTERISTIC)) {
      result.push(
        `${o.t.instant('audit.entries.term.charasteristic')} = ${transformBooleanYesNo(
          (c.CHARACTERISTIC as unknown) as boolean,
          o
        )}`
      );
    }

    if (c.WEIGHT) {
      result.push(`${o.t.instant('audit.entries.term.weight')} = ${c.WEIGHT}`);
    }

    return result.join(', ');
  });
}
