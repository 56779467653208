import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TmFormElementService } from './form-elements.service';
import { TmInputManyComponent } from './input-many/input-many.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TmInputManyComponent],
  entryComponents: [TmInputManyComponent],
  providers: [TmFormElementService, { provide: 'TmFormElementService', useExisting: TmFormElementService }],
})
export class TmFormElementsModule {}
