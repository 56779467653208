<tm-page>
  <tm-sidebar title="{{ 'lists-files.title' | translate }}">
    <button
      iw-button
      type="button"
      color="white"
      class="policy-create-button"
      data-qa="create-policy-from-tree"
      [disabled]="!!(createPolicyDisabled$ | async)"
      [iwPopover]="createPolitics"
      placement="bottom-start"
    >
      {{ 'lists-files.create-policy' | translate }}
    </button>
    <tm-tree
      class="tree"
      data-qa="files-tabs-tree"
      [data]="treeData$ | async"
      [treeItemLinkParams]="{ replaceUrl: true }"
      (onActiveNode)="onSidebarItemSelect($event.node.id)"
    >
    </tm-tree>
  </tm-sidebar>

  <tm-content [title]="title$ | async">
    <router-outlet></router-outlet>
  </tm-content>
</tm-page>

<ng-template #createPolitics>
  <div class="dropdown">
    <button (click)="createPolicyFromFiletypes('OBJECT')">
      {{ 'lists-files.create-policy' | translate }}
    </button>
    <button (click)="createPolicyFromFiletypes('AGENT')">
      {{ 'lists-files.create-policy-agent' | translate }}
    </button>
  </div>
</ng-template>
