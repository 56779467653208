import { Component, Input } from '@angular/core';

@Component({
  selector: 'tm-tumbler-indicator',
  templateUrl: './tumbler-indicator.component.html',
  styleUrls: ['./tumbler-indicator.component.scss'],
})
export class TmTumblerIndicatorComponent {
  @Input() public checked?: string;

  @Input() public disabled = false;
}
