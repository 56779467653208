import { TranslateService } from '@ngx-translate/core';
import { AuditExtendGridData } from '../audit-extend.service';
import {
  VisibleAttributesOption,
  getAuditExtendRowData,
  getAuditAttributesFrom,
  transformBooleanYesNo,
  getDefaultColumns,
  TransformOptions,
} from './audit-common';
import { AuditEvent } from '../audit.model';
import { getPrivilegesGridData } from './audit-event-query';
import { FORCE_PRIVILEGES, QueryDiffAuditLog } from 'typings/generated/audit-event-query';

const auditQueryBasicVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'DISPLAY_NAME', i18nKey: 'audit.auditQuery.attrs.DISPLAY_NAME' },
  { path: 'IS_SHARED', i18nKey: 'audit.auditQuery.attrs.query.isShared', transform: transformBooleanYesNo },
  { path: 'PARENT_FOLDER_NAME', i18nKey: 'audit.auditQuery.attrs.parentFolder' },
  {
    path: 'FORCE_PRIVILEGES',
    i18nKey: 'audit.auditQuery.attrs.query.forcePrivileges',
    transform: transformForcePrivileges,
  },
];

export function getEventQueryFolderGridData(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  const result: AuditExtendGridData[] = [];

  /**
   * Set correct columns
   */
  const cols = getDefaultColumns(data);

  /**
   * Show base attributes
   */
  const baseData = getAuditExtendRowData({
    t,
    cols: cols,
    data: getAuditAttributesFrom(data),
    visibleAttributes: auditQueryBasicVisibleAttributes,
  });

  if (baseData.length) {
    result.push({
      cols: cols,
      data: baseData,
    });
  }

  /**
   * Show privileges by user
   */
  const privilegesGridData = getPrivilegesGridData(
    getAuditAttributesFrom(data) as NonNullable<QueryDiffAuditLog['PROPERTY_CHANGES']>,
    cols,
    t
  );

  if (privilegesGridData) {
    result.push(privilegesGridData);
  }

  return result;
}

export function transformForcePrivileges(data: FORCE_PRIVILEGES, o: TransformOptions): string {
  if (data === FORCE_PRIVILEGES.INHERITED) {
    return o.t.instant('audit.auditQuery.attrs.query.forcePrivilegesKeys.inherited');
  } else {
    return transformBooleanYesNo(data, o);
  }
}
