import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { TmLdapGroupHierarchyApiService } from '@tm-shared/api-services';
import { AbstractTabComponentDirective } from './abstract-tab-component.directive';
import { TreeNode } from '@circlon/angular-tree-component';
import { ASYNC_ROOT, TmTreeComponent, TmTreeNodeData } from '../../../../@tm-shared/tree';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: 'group-tab.component.html',
  styleUrls: [],
})
export class GroupTabComponent extends AbstractTabComponentDirective implements AfterViewInit {
  public static scopeToSearch: 'group' = 'group';

  @ViewChild(TmTreeComponent) public tree: TmTreeComponent;

  public ngAfterViewInit() {
    if (this.selected) {
      this.tree.selectNodesByIds(this.selected);
    }
    this.tree.onSelectionChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      let selected = this.tree.getSelectedIds(true, true);
      const rootNode = this.tree.getNodeById(ASYNC_ROOT);
      if (rootNode?.isSelected) {
        selected = rootNode.children.map((item) => item.data.id);
      }
      this.selectedChange.emit(selected);
    });
  }

  constructor(public ldapGroupService: TmLdapGroupHierarchyApiService) {
    super();
  }

  /**
   * getChildren method for tm-tree getChildren input
   * sets logic for loading of lazy nodes
   */
  public getChildren = (node: TreeNode): Promise<TmTreeNodeData[]> => {
    return node.realParent
      ? this.ldapGroupService
          .get({
            params: {
              grouppath: node.data.ID_PATH || node.data.ID_NAME_PATH.ID_PATH,
            },
          })
          .pipe(map((response) => this._mapGroupToTreeNode(response.data)))
          .toPromise()
      : this.ldapGroupService
          .get()
          .pipe(map((response) => this._mapGroupToTreeNode(response.data)))
          .toPromise();
  };

  private _mapGroupToTreeNode(items: TmApi.ldapGroup.CollectionItem[]): TmTreeNodeData[] {
    return items.map((item) => {
      return {
        name: item.DISPLAY_NAME,
        hasChildren: !!item.childsCount,
        ID_PATH: item.ID_PATH || item.ID_NAME_PATH.ID_PATH,
        id: item.GROUP_ID,
      };
    });
  }
}
