/**
 * Do not use this class directly, use factory: createEventContentChain(content)
 */
export class TmEventContentChain {
  constructor(
    private _contents: TmPluginEvents.content.ContentParsed[],
    private _conditions: TmPluginEvents.content.ChainTask[]
  ) {}

  /**
   * Filter content with custom filters
   */
  public filter(filters: TmPluginEvents.content.ChainFilter[] = []): TmEventContentChain {
    return new TmEventContentChain(
      this._contents,
      this._conditions.concat(function (content) {
        return filterContentByConditions(content, filters);
      })
    );
  }

  /**
   * Filter content with all it's children with custom filters
   */
  public filterWithChildren(filters: TmPluginEvents.content.ChainFilter[] = []): TmEventContentChain {
    const scopesToOmitWithChildren: TmPluginEvents.content.ContentParsed[] = [];
    const nextConditions = Array.from(this._conditions);

    // This filter should be first to check every content
    nextConditions.unshift(function (content) {
      if (filterContentByScopes(content, scopesToOmitWithChildren, true)) {
        return false;
      }

      if (!filterContentByConditions(content, filters)) {
        scopesToOmitWithChildren.push(content);
        return false;
      }

      return true;
    });

    /**
     * 1. Verify content is sorted by LEFT_KEY (ensure children are processed after parent)
     * 2. Detect and store scopes (parents) to omit
     * 3. Apply content filter by scope
     */
    return new TmEventContentChain(
      Array.from(this._contents).sort(function (c1, c2) {
        return c1.LEFT_KEY - c2.LEFT_KEY;
      }),
      nextConditions
    );
  }

  /**
   * Filter content by parents
   */
  public filterByParents(filters: TmPluginEvents.content.ChainParentFilter[] = []) {
    return new TmEventContentChain(
      this._contents,
      this._conditions.concat(function (content, contents) {
        return filterContentByParents(content, contents, filters);
      })
    );
  }

  /**
   * Filter content by scope content (returns scope content with it's children)
   */
  public filterWithinScopes(scopes: TmPluginEvents.content.Content[]): TmEventContentChain {
    let parsedScopes = parseContents(scopes);

    return new TmEventContentChain(
      this._contents,
      this._conditions.concat(function (content) {
        return filterContentByScopes(content, parsedScopes);
      })
    );
  }

  public getResult(): TmPluginEvents.content.ContentParsed[] {
    return applyConditions(this._contents, this._conditions);
  }
}

/**
 * Apply filters and get results
 */
function applyConditions(
  contents: TmPluginEvents.content.ContentParsed[],
  tasks: TmPluginEvents.content.ChainTask[]
): TmPluginEvents.content.ContentParsed[] {
  let result: TmPluginEvents.content.ContentParsed[] = [];
  let contentLength = contents.length;
  let tasksLength = tasks.length;
  let pass = true;

  for (let contentIndex = 0; contentIndex < contentLength; contentIndex++) {
    pass = true;

    for (let taskIndex = 0; taskIndex < tasksLength; taskIndex++) {
      if (!tasks[taskIndex](contents[contentIndex], contents)) {
        pass = false;
        break;
      }
    }

    if (pass) {
      result.push(contents[contentIndex]);
    }
  }

  return result;
}

/**
 * Check content against each condition
 */
function filterContentByConditions(
  content: TmPluginEvents.content.ContentParsed,
  filters: TmPluginEvents.content.ChainFilter[] = []
): boolean {
  // Event should pass each condition
  for (let conditionIndex = 0; conditionIndex < filters.length; conditionIndex++) {
    if (!filters[conditionIndex](content)) {
      return false;
    }
  }

  return true;
}

/**
 * Check content's parents against each condition, content fails if any parent fails
 */
function filterContentByParents(
  content: TmPluginEvents.content.ContentParsed,
  currentResults: TmPluginEvents.content.ContentParsed[],
  filters: TmPluginEvents.content.ChainParentFilter[] = []
): boolean {
  let conditionIndex;
  for (let i = 0; i < currentResults.length; i++) {
    if (checkContentInScope(content, currentResults[i], true)) {
      for (conditionIndex = 0; conditionIndex < filters.length; conditionIndex++) {
        if (!filters[conditionIndex](content, currentResults[i])) {
          return false;
        }
      }
    }
  }

  return true;
}

/**
 * Check content is scope or within any scope
 */
function filterContentByScopes(
  content: TmPluginEvents.content.ContentParsed,
  scopes: TmPluginEvents.content.ContentParsed[],
  excludeSelf: boolean = false
): boolean {
  let scopesLength = scopes.length;

  for (let scopesIndex = 0; scopesIndex < scopesLength; scopesIndex++) {
    if (checkContentInScope(content, scopes[scopesIndex], excludeSelf)) {
      return true;
    }
  }

  return false;
}

/**
 * Check if content is inside scope
 * @param excludeSelf return false if content is scope by itself
 */
function checkContentInScope(
  content: TmPluginEvents.content.ContentParsed,
  scope: TmPluginEvents.content.ContentParsed,
  excludeSelf: boolean = false
): boolean {
  if (excludeSelf) {
    return content.LEFT_KEY > scope.LEFT_KEY && content.RIGHT_KEY < scope.RIGHT_KEY;
  }

  return content.LEFT_KEY >= scope.LEFT_KEY && content.RIGHT_KEY <= scope.RIGHT_KEY;
}

/**
 * TODO: Backend should provide numbers where it's possible by default
 */
function parseContents(contents: TmPluginEvents.content.Content[]): TmPluginEvents.content.ContentParsed[] {
  let result: TmPluginEvents.content.ContentParsed[] = [];

  for (let contentIndex = 0; contentIndex < contents.length; contentIndex++) {
    result.push(
      Object.assign({}, contents[contentIndex], {
        ORIGIN: +contents[contentIndex].ORIGIN,
        IS_TEXT: +contents[contentIndex].IS_TEXT,
        LEFT_KEY: +contents[contentIndex].LEFT_KEY,
        RIGHT_KEY: +contents[contentIndex].RIGHT_KEY,
      })
    );
  }

  return result;
}

export function createEventContentChain(contents: TmPluginEvents.content.Content[]): TmEventContentChain {
  return new TmEventContentChain(parseContents(contents), []);
}
