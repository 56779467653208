/**
 * @module
 * @description
 * sidebar component.
 */

export * from './sidebar.component';

/**
 * @module
 * @description
 * sidebar service.
 */

export * from './sidebar.service';
