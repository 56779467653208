import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TmSharedModule } from '@tm-shared/tm-shared.module';
import { TmContextSubMenuDirective } from './context-menu-sub.directive';
import { TmContextMenuComponent } from './context-menu.component';
import { TmContextMenuDirective } from './context-menu.directive';

@NgModule({
  declarations: [TmContextMenuDirective, TmContextMenuComponent, TmContextSubMenuDirective],
  exports: [TmContextMenuDirective],
  imports: [CommonModule, TmSharedModule],
  entryComponents: [TmContextMenuComponent],
})
export class TmContextMenuModule {}
