import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, forkJoin, merge, of, throwError } from 'rxjs';
import { catchError, map, pluck, shareReplay, switchMap, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';
import { TmFormComponent } from '@tm-shared/form';
import { ModalConfirmComponent } from '@tm-shared/modals';
import { TmSessionService } from '@tm-shared/session';
import { TmSidebarService } from '@tm-shared/structure/sidebar';
import { HttpErrorResponse } from '@angular/common/http';
import { TmUserApiService } from '@tm-shared/api-services/user-api.service';
import { TmAsyncValidatorsService } from '@tm-shared/helpers';
import { PATTERN_EMAIL, PATTERN_STRING_256_1, PATTERN_STRING_128_2 } from '@tm-shared/helpers/patterns';
import {
  fieldsEqualValidator,
  microsoftStandardPasswordValidator,
  namedPatternValidator,
  whitespacesValidator,
} from '@tm-shared/helpers/validators/validators';
import { TmPluginAccessUserEndpoints } from 'plugins/settings-access/access-exports';
import { TmPrivilegesService } from '../../../../@tm-shared/privileges';
import { UserModel, getPrivilegeRequest } from '../user.model';
import { IwNotificationsService, IwModalService } from '@platform/shared';

/**
 * @translate settings-access.validation.password.msPaswordOneOfFour
 * @translate settings-access.validation.password.duplicate_field_part
 * @translate settings-access.validation.password.invalid_characters
 * @translate settings-access.validation.password.repeat_exactly
 * @translate settings-access.validation.password.required
 * @translate settings-access.validation.password.maxlength
 * @translate settings-access.validation.password.minlength
 * @translate settings-access.validation.validationError
 * @translate settings-access.validation.empty
 * @translate settings-access.validation.not_unique_field
 * @translate settings-access.validation.invalid_characters
 * @translate settings-access.validation.email
 * @translate settings-access.validation.maxlength
 * @translate settings-access.validation.minlength
 */
@Component({
  selector: 'tm-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  providers: [TmAsyncValidatorsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserEditComponent extends TmFormComponent<FormGroup> implements OnInit {
  public createDate: string;
  public changeDate: string;

  /**
   * UserId from router params
   */
  public userId$: Observable<number> = this._activatedRoute.params.pipe(
    map((params: { id: string }) => parseInt(params.id, 10))
  );

  public confirmDataLoss$ = of(null).pipe(
    switchMap(() => {
      return this._modalService.open(ModalConfirmComponent, {
        title: this._t.instant('settings-access.confirmAction'),
        text: this._t.instant('settings-access.user.form.dataLossWarning'),
      });
    }),
    switchMap((modal) => {
      return modal.component.decision.pipe(take(1));
    })
  );

  public endpoints = TmPluginAccessUserEndpoints;

  public isNew$: Observable<boolean> = this.userId$.pipe(map((id) => isNaN(id)));

  /**
   * Stream: Form data
   * TODO: add form data loading overlay
   */
  public data$: Observable<UserModel> = this._activatedRoute.data.pipe(pluck('model'));

  private _hasSetRolePrivilege$: Observable<boolean> = this._privileges
    .can(getPrivilegeRequest('set_role'))
    .pipe(take(1), shareReplay(1));
  private _hasSetScopePrivilege$: Observable<boolean> = this._privileges
    .can(getPrivilegeRequest('set_scope'))
    .pipe(take(1), shareReplay(1));
  private _hasEditUserPrivilege$: Observable<boolean> = this._privileges
    .can(getPrivilegeRequest('edit'))
    .pipe(take(1), shareReplay(1));
  private _canCreateForm$ = forkJoin(
    this._hasEditUserPrivilege$,
    this._hasSetRolePrivilege$,
    this._hasSetScopePrivilege$
  );

  constructor(
    public service: TmUserApiService,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    private _notify: IwNotificationsService,
    private _modalService: IwModalService,
    private _privileges: TmPrivilegesService,
    private _sessionService: TmSessionService,
    private _asyncValidation: TmAsyncValidatorsService,
    private _sidebar: TmSidebarService,
    private _t: TranslateService,
    private _fb: FormBuilder
  ) {
    super();
  }

  /**
   * On init actions
   */
  public ngOnInit(): void {
    this._createUserFormGroup();

    this.data$
      .pipe(
        switchMap((data) => combineLatest([of(data), this.isNew$, this._canCreateForm$]).pipe(take(1))),
        takeUntil(this._destroyed$)
      )
      .subscribe(([data, isNew, [hasEditUserPrivilege, hasSetRolePrivilege, hasSetScopePrivilege]]) => {
        this.patchDataToForm(data, isNew);

        if (data.CREATE_DATE) {
          this.createDate = data.CREATE_DATE;
        }
        if (data.CHANGE_DATE) {
          this.changeDate = data.CHANGE_DATE;
        }
        this._handleFormDisableState(hasEditUserPrivilege, hasSetRolePrivilege, hasSetScopePrivilege, data);
      });

    merge(this.close$, this.submit$)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this._closeSidebar());

    this._watchIsNewToChangeTitle();

    this._sidebar.open('right');
  }

  /**
   * Convert form model data to server model data
   */
  public formModelToServerModel(data: TmPluginAccess.user.edit.Model): TmApi.user.CollectionItem {
    const modelToSave: UserModel = ({ ...data } as unknown) as TmApi.user.CollectionItem;
    modelToSave.PASSWORD = modelToSave.PASSWORD || undefined;
    modelToSave.PASSWORD_CONFIRMATION = modelToSave.PASSWORD_CONFIRMATION || undefined;
    modelToSave.roles = (data.roles || []).map((role) =>
      role.data ? { ROLE_ID: role.data.ROLE_ID } : { ROLE_ID: (role as unknown) as number }
    );
    modelToSave.visibilityareas = (data.scopes || []).map((scope) =>
      scope.data
        ? { VISIBILITY_AREA_ID: scope.data.VISIBILITY_AREA_ID }
        : { VISIBILITY_AREA_ID: (scope as unknown) as string }
    );

    return modelToSave;
  }

  /**
   * Convert server model data to form model data
   */
  public patchDataToForm(data: UserModel, isNew: boolean = false) {
    const formRoles = data.roles.map((m) => {
      return {
        label: `${m.DISPLAY_NAME}`,
        value: `${m.ROLE_ID}`,
        data: m,
      };
    });

    const formScopes = data.visibilityareas.map((m) => {
      return {
        label: `${m.DISPLAY_NAME}`,
        value: `${m.VISIBILITY_AREA_ID}`,
        data: m,
      };
    });

    this.form.reset();

    this.form.patchValue({
      ...data,
      STATUS: `${data.STATUS}`,
      roles: formRoles,
      scopes: formScopes,
    });

    if (isNew) {
      this.form
        .get('PASSWORD')!
        .setValidators([
          Validators.required,
          namedPatternValidator(PATTERN_STRING_128_2, 'invalid_characters'),
          microsoftStandardPasswordValidator,
        ]);
    } else {
      this.form.get('PASSWORD')!.clearValidators();
    }
    this.form.get('PASSWORD')!.updateValueAndValidity();
  }

  /**
   * HACK: provides types compability without redesigning search-select component
   */
  public getFormControl(path: string): FormControl {
    return this.get(path) as FormControl;
  }

  /**
   * Save form data
   */
  protected _onSubmit() {
    const serverData = this.formModelToServerModel(this.form.getRawValue());
    const session = this._sessionService.getCurrentSession()!;
    serverData.LANGUAGE = session.LANGUAGE;

    return this.service.createOrUpdate(serverData).pipe(
      tap((response) => {
        if (response.data.USER_ID === session.USER_ID) {
          this._sessionService.restoreSession();
        }
      }),
      catchError((response: HttpErrorResponse) => {
        this._notify.error(
          this._t.instant('settings-access.user.errors.error'),
          this._t.instant('settings-access.user.errors.requestFail')
        );
        return throwError(response);
      }),
      tap(() => this.close(true)),
      takeUntil(this._destroyed$)
    );
  }

  private _watchIsNewToChangeTitle() {
    merge(this.isNew$, this._t.onLangChange.pipe(switchMapTo(this.isNew$)))
      .pipe(takeUntil(this._destroyed$))
      .subscribe((isNew) => {
        let title;

        if (isNew) {
          title = this._t.instant('settings-access.user.form.titleCreateNew');
        } else {
          title = this._t.instant('settings-access.user.form.titleEdit');
        }

        this._sidebar.updateTitle(title);
      });
  }

  /**
   * Close sidebar
   */
  private _closeSidebar() {
    this._router.navigate(['./'], {
      relativeTo: this._activatedRoute.parent,
      queryParamsHandling: 'merge',
    });

    this._sidebar.close('right');
  }

  private _createUserFormGroup() {
    this.form = this._fb.group(
      {
        USER_ID: null,
        DISPLAY_NAME: [null, [Validators.required, namedPatternValidator(PATTERN_STRING_256_1, 'invalid_characters')]],
        EMAIL: [null, [Validators.maxLength(256), Validators.required, namedPatternValidator(PATTERN_EMAIL, 'email')]],
        NOTE: [null, [Validators.maxLength(4000)]],
        STATUS: [null, Validators.required],
        USERNAME: [
          null,
          [
            Validators.required,
            Validators.maxLength(256),
            namedPatternValidator(PATTERN_STRING_256_1, 'invalid_characters'),
            whitespacesValidator,
          ],
          this._asyncValidation.getAsyncObjectValidation('user', this.userId$),
        ],
        roles: null,
        scopes: null,
        PASSWORD: null,
        PASSWORD_CONFIRMATION: null,
      },
      {
        validator: fieldsEqualValidator('PASSWORD_CONFIRMATION', 'PASSWORD'),
      }
    );
  }
  private _handleFormDisableState(
    hasEditUserPrivilege: boolean,
    hasSetRolePrivilege: boolean,
    hasSetScopePrivilege: boolean,
    user: UserModel
  ) {
    const roleField = this.form.get('roles');
    const scopeField = this.form.get('scopes');
    const isOfficer = this.service.isOfficer(user);
    const isAdministrator = this.service.isAdministrator(user);
    const isLdapUser = this.service.isLdapUser(user);
    const preInstalledUser = isOfficer || isAdministrator;

    if (hasEditUserPrivilege) {
      this.form.enable();
      if (preInstalledUser) {
        this.form.get('USERNAME')!.disable();
        this.form.get('DISPLAY_NAME')!.disable();
        this.form.get('NOTE')!.disable();
      }

      if (isAdministrator) {
        this.form.get('STATUS')!.disable();
      }

      if (isLdapUser) {
        this.form.get('USERNAME')!.disable();
      }
    } else {
      this.form.disable();
    }

    if (!hasSetRolePrivilege || preInstalledUser) {
      roleField!.disable();
    } else {
      roleField!.enable();
    }

    if (!hasSetScopePrivilege || preInstalledUser) {
      scopeField!.disable();
    } else {
      scopeField!.enable();
    }
  }
}
