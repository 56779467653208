<iw-select
  *ngIf="control"
  class="selectControl"
  placeholder="{{ placeholder || 'settings-access.scope.form.search' | translate }}"
  [formControl]="control"
  [multiple]="true"
  [searchable]="true"
  [server]="getSearchCallback()"
  data-qa="scopeSelectInput"
  [labelTemplate]="labelTemplate"
  [optionTemplate]="optionTemplate"
></iw-select>

<ng-template #labelTemplate let-item="item" let-unselectItem="unselectItem">
  <div class="value-wrapper default" [class.disabled]="item.disabled">
    <span class="value-icon">
      <iw-icon *ngIf="searchSelectService.getIconByType(item.value.TYPE) as iconName" [svgIcon]="iconName"></iw-icon>
    </span>
    <span class="value-label">
      <span [ngClass]="{ 'deleted-item': item.value.IS_DELETED }">{{ item.label }}</span>
    </span>
    <span class="value-icon right" aria-hidden="true" (click)="unselectItem(item)">
      <tm-icon svgIcon="close"></tm-icon>
    </span>
  </div>
</ng-template>

<ng-template #optionTemplate let-item="item">
  <span class="option-label">
    <iw-icon *ngIf="searchSelectService.getIconByType(item.value.TYPE) as iconName" [svgIcon]="iconName"></iw-icon>
    <span [ngClass]="{ 'deleted-item': item.value.IS_DELETED }">{{ item.label }}</span> <br />
    <span
      *ngIf="
        additionalTypesToSelect &&
        searchSelectService.getSuggestionByMnemo(item.value.TYPE, additionalTypesToSelect) as suggestion
      "
      class="suggestion"
      >{{ suggestion }}</span
    >
  </span>
</ng-template>

<button
  class="openModal"
  iw-icon-button
  [disabled]="control?.disabled"
  type="button"
  (click)="openDialog()"
  data-qa="openDialogTrigger"
>
  <tm-icon class="icon" svgIcon="create"></tm-icon>
</button>
