<tm-content>
  <h2 class="title">{{ 'lists-tag.title' | translate }}</h2>
  <p class="description">{{ 'lists-tag.description' | translate }}</p>
  <tm-toolbar class="toolbar">
    <button
      iw-icon-button
      data-qa="tag-create"
      iwPopover="{{ 'lists-tag.toolbar.create' | translate }}"
      [disabled]="!(canCreate$ | async)"
      type="button"
      name="{{ 'lists-tag.toolbar.create' | translate }}'"
      (click)="showDialogCreate()"
    >
      <tm-icon svgIcon="create"></tm-icon>
    </button>
    <button
      iw-icon-button
      data-qa="tag-delete"
      [disabled]="!(canRemove$ | async)"
      iwPopover="{{ 'lists-tag.toolbar.remove' | translate }}"
      type="button"
      name="{{ 'lists-tag.toolbar.remove' | translate }}"
      (click)="showDialogRemove()"
    >
      <tm-icon svgIcon="delete"></tm-icon>
    </button>
  </tm-toolbar>
  <tm-grid #grid class="grid" [service]="service" [gridOptions]="gridOptions"></tm-grid>
</tm-content>
<tm-sidebar class="sidebar" direction="right" [isCollapsed]="true">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</tm-sidebar>
