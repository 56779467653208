import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TmToolbarComponent } from './tm-toolbar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TmToolbarComponent],
  exports: [TmToolbarComponent],
})
export class TmToolbarModule {
  constructor() {}
}
