<button
  iw-button
  *ngIf="localConfig"
  [progress]="localConfig?.state === 'loading'"
  [iwPopover]="popoverTpl"
  [noStyles]="true"
  container="body"
  placement="bottom-start"
  class="button"
  [ngClass]="{
    locked: localConfig?.state === 'locked',
    editing: localConfig?.state === 'editedByActiveUser'
  }"
  (isVisible)="popoverVisibilityChanged($event)"
>
  {{ configButtonText }}
  <i class="caret"></i>
</button>

<ng-template #popoverTpl>
  <div class="configPopover">
    <!-- Local config state header -->
    <div class="configState">
      <h2>{{ localConfigurationStatusText }}</h2>

      <button
        iw-button
        size="small"
        color="success"
        *ngIf="localConfig?.state === 'editedByActiveUser'"
        type="button"
        (click)="goToConfigDiffPage.emit()"
      >
        {{ 'config.widget.local.compareAndApply' | translate }}
      </button>
    </div>

    <div class="configState">
      <h2 *ngIf="queuedInboundConfig">
        {{ 'config.widget.queued.title' | translate: { version: queuedInboundConfig.version } }}
      </h2>

      <!-- Queued inbound config alert -->
      <ng-container *ngIf="queuedInboundConfig">
        <ng-container [ngSwitch]="queuedInboundConfig.completion">
          <ng-container *ngSwitchCase="'required'">
            <button iw-button size="small" color="success" type="button" (click)="goToFillPolicyPage.emit()">
              {{ 'config.widget.queued.completionRequired' | translate }}
            </button>
          </ng-container>
          <ng-container *ngSwitchCase="'available'">
            <button iw-button size="small" color="success" type="button" (click)="goToFillPolicyPage.emit()">
              {{ 'config.widget.queued.completionAvailable' | translate }}
            </button>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <button iw-button size="small" color="success" type="button" (click)="goToFillPolicyPage.emit()">
              {{ 'config.widget.queued.fetchAndPreview' | translate }}
            </button>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="currentConfig">
      <div *ngIf="agentConfig || inboundConfig || outboundConfig" class="config">
        <h4>{{ 'config.widget.local.title' | translate }}</h4>
        <p class="description">{{ 'config.widget.local.description' | translate }}</p>
      </div>

      <!-- Agent config summary -->
      <div class="configInfo" *ngIf="agentConfig">
        <tm-icon class="icon" svgIcon="local"></tm-icon>
        <div class="info">
          <label>{{ 'config.widget.agent.title' | translate }}</label>
          <span class="date">{{ formatTimestamp(agentConfig.timestamp) }}</span>
        </div>
        <span class="version">{{ agentConfig.version }}</span>
      </div>

      <!-- Inbound config summary -->
      <div class="configInfo" *ngIf="inboundConfig">
        <tm-icon class="icon" svgIcon="inbox"></tm-icon>
        <div class="info">
          <label>{{ 'config.widget.inbound.title' | translate }}</label>
          <span class="date">{{ formatTimestamp(inboundConfig.timestamp) }}</span>
        </div>
        <span class="version">{{ inboundConfig.version }}</span>

        <!-- Queued inbound config alert -->
        <ng-container *ngIf="queuedInboundConfig">
          <ng-container [ngSwitch]="queuedInboundConfig.completion">
            <ng-container *ngSwitchCase="'required'">
              <a class="state required" (click)="goToFillPolicyPage.emit()">{{
                'config.widget.queued.completionRequired' | translate
              }}</a>
            </ng-container>
            <ng-container *ngSwitchCase="'available'">
              <a class="state available" (click)="goToFillPolicyPage.emit()">{{
                'config.widget.queued.completionAvailable' | translate
              }}</a>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <a class="state available" (click)="fetchQueued.emit()">{{
                'config.widget.queued.fetchAndPreview' | translate
              }}</a>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="inboundConfig.completion as completionState" [ngSwitch]="completionState">
          <span *ngSwitchCase="'available'" class="state available">
            {{ 'config.widget.inbound.completion.available' | translate }}
          </span>
          <span *ngSwitchCase="'required'" class="state required">
            {{ 'config.widget.inbound.completion.required' | translate }}
          </span>
        </ng-container>

        <a class="action" (click)="goToConfigDiffPage.emit()">
          <ng-container>{{ 'config.widget.inbound.showDiff' | translate }}</ng-container>
          <ng-container *ngIf="inboundConfig.completion">
            {{ 'config.widget.inbound.andPolicy' | translate }}
          </ng-container>
        </a>
      </div>

      <!-- Outbound config summary -->
      <div class="configInfo" *ngIf="outboundConfig">
        <tm-icon class="icon" svgIcon="inbox"></tm-icon>
        <div class="info">
          <label>{{ 'config.widget.outbound.title' | translate }}</label>
          <span class="date">{{ formatTimestamp(outboundConfig.timestamp) }}</span>
        </div>
        <span class="version">{{ outboundConfig.version }}</span>
        <a class="action" (click)="goToAffiliatePage.emit()">
          {{ 'config.widget.outbound.sharingState' | translate }}
        </a>
      </div>
    </div>
  </div>
</ng-template>
