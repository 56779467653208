import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TmElement } from '@tm-shared/custom-elements';
import { TmFormComponent } from '@tm-shared/form';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TmAnalysisTextService } from '../analysis-text-service';
import { TmAnalysisTextPatternValidatorService } from './analysis-text-pattern-validator.service';

export const VALIDATE_URL = '/api/textObjectPattern/validateRegexp';

@TmElement('tme-analysis-text-pattern-validator')
@Component({
  selector: 'tm-analysis-text-pattern-validator',
  templateUrl: './analysis-text-pattern-validator.component.html',
  styleUrls: ['./analysis-text-pattern-validator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmAnalysisTextPatternValidatorComponent extends TmFormComponent implements OnInit {
  @Input() public pattern: string;

  @Input() public isSystem = false;

  @Input() public patternId?: ApiTextObjectPattern.TextObjectPattern['TEXT_OBJECT_PATTERN_ID'];

  public validationResults$: Subject<TmPluginAnalysisText.ParsedValidationResult[] | null> = new Subject();
  public validationErrors$: Subject<TmPluginAnalysisText.ParsedValidationResult[] | null> = new Subject();

  public form = this._fb.group({
    userInput: [null, Validators.required],
  });

  public loading$ = of(null).pipe(
    switchMap(() => this._loading$),
    debounceTime(500)
  );

  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private _fb: FormBuilder,
    private _service: TmAnalysisTextPatternValidatorService,
    public textService: TmAnalysisTextService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.textService.resetValidation$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this.validationErrors$.next(null));
  }

  protected _onSubmit(): Observable<boolean> {
    return this._validate(this.form.get('userInput')!.value);
  }

  private _validate(value: string): Observable<boolean> {
    this._loading$.next(true);
    const validator = this.isSystem
      ? this._service.validateById(this.patternId!, value)
      : this._service.validate(this.pattern, value);
    return validator.pipe(
      map((results) => {
        this.validationResults$.next(results);
        this.validationErrors$.next(null);
        return true;
      }),
      catchError((error: TmPluginAnalysisText.ParsedValidationResult) => {
        this.textService.resetValidationErrors();
        this.validationResults$.next(null);
        this.validationErrors$.next([error]);
        return of(false);
      }),
      tap(() => this._loading$.next(false))
    );
  }
}
