import { Routes } from '@angular/router';
import { ResourcesComponent } from './resources-component/resources.component';
import { ResourcesTableComponent } from './resources-table-component/resources-table.component';
import {
  CREATE_GROUP_PATH,
  CREATE_ITEM_PATH,
  EDIT_GROUP_PATH,
  EDIT_ITEM_PATH,
  RESOURCES,
  TREE_ROOT,
} from './resources.model';

export const ROUTES: Routes = [
  {
    path: RESOURCES,
    component: ResourcesComponent,
    data: {
      usePrivilege: 'lists/resources/resource',
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: TREE_ROOT,
      },
      {
        path: ':id',
        component: ResourcesTableComponent,
        data: {
          usePrivilege: 'lists/resources/resource',
        },
      },
      {
        path: CREATE_GROUP_PATH,
        component: ResourcesTableComponent,
        data: {
          usePrivilege: 'lists/resources/resource',
        },
      },
      {
        path: EDIT_GROUP_PATH,
        component: ResourcesTableComponent,
        data: {
          usePrivilege: 'lists/resources/resource',
        },
      },
      {
        path: CREATE_ITEM_PATH,
        component: ResourcesTableComponent,
        data: {
          usePrivilege: 'lists/resources/resource',
        },
      },
      {
        path: EDIT_ITEM_PATH,
        component: ResourcesTableComponent,
        data: {
          usePrivilege: 'lists/resources/resource',
        },
      },
    ],
  },
];
