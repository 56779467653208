import { OnDestroy, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, combineLatest, merge } from 'rxjs';
import { map, switchMapTo, takeUntil } from 'rxjs/operators';
import { TmPrivilegesService } from '../../@tm-shared/privileges';
import { TmTreeComponent, TmTreeNodeData } from '../../@tm-shared/tree';
import { ROLE_PATH, SCOPE_PATH, USER_PATH } from './access-exports';

/**
 * @Injectable is used due to Angular 10 DI system:
 * https://angular.io/guide/migration-injectable
 */
@Injectable()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TmAccessService implements OnDestroy {
  private _destroyed$ = new Subject();
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _t: TranslateService,
    private _privileges: TmPrivilegesService
  ) {}

  public ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public selectCurrentTreeNode(treeComponent: TmTreeComponent): boolean {
    if (
      this._activatedRoute.firstChild &&
      this._activatedRoute.firstChild.routeConfig &&
      this._activatedRoute.firstChild.routeConfig.path
    ) {
      return treeComponent.activateById(this._activatedRoute.firstChild.routeConfig.path);
    }
    return false;
  }

  /**
   * @translate settings-access.user.title
   * @translate settings-access.role.title
   * @translate settings-access.scope.title
   */
  public takeAvailableTabs(): Observable<TmTreeNodeData[]> {
    return merge(this._getCurrentTabs(), this._t.onLangChange.pipe(switchMapTo(this._getCurrentTabs()))).pipe(
      takeUntil(this._destroyed$)
    );
  }

  /**
   * Check privileges
   */
  private _can(moduleKey: 'user' | 'scope' | 'role'): Observable<boolean> {
    // TODO: https://devblogs.microsoft.com/typescript/announcing-typescript-4-1-beta/#template-literal-types
    // выпилить as в ts 4.1
    return this._privileges.can({ action: 'show', type: `settings:access:${moduleKey}` as 'settings:access:user' });
  }

  private _getCurrentTabs() {
    const accessRoutes = [USER_PATH, ROLE_PATH, SCOPE_PATH];
    return combineLatest(accessRoutes.map((item) => this._can(item))).pipe(
      map((result) => {
        const sidebarData: TmTreeNodeData[] = [];
        result.forEach((can, i) => {
          if (can) {
            sidebarData.push({
              id: accessRoutes[i],
              name: this._t.instant(`settings-access.${accessRoutes[i]}.title`),
            });
          }
        });
        return sidebarData;
      })
    );
  }
}
