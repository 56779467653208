import { Input, Output, EventEmitter, OnDestroy, Directive } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({})
export abstract class AbstractTabComponentDirective implements OnDestroy {
  public static scopeToSearch: TmApi.search.Scopes;
  public destroy$ = new Subject();

  @Input() selected?: (string | number)[];
  @Output() selectedChange = new EventEmitter();

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
