import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TmElement } from '@tm-shared/custom-elements';
import { Subject, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Value, ValueType, Values } from './input-many.model';
import { TmInputManyService } from './input-many.service';

@TmElement('tme-input-many')
@Component({
  selector: 'tm-input-many',
  templateUrl: './input-many.component.html',
  styleUrls: ['./input-many.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(click)': 'onHostClick($event)',
  },
  providers: [
    TmInputManyService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TmInputManyComponent),
      multi: true,
    },
  ],
})
export class TmInputManyComponent implements OnDestroy, OnInit, ControlValueAccessor {
  @Input() public value: Values | null = [];

  @Input() public placeholder = '';

  @Input() public name = '';

  @Input() public type: ValueType | null = null;

  @Input() public maxlength: string | null = null;

  @Input() public disabled = false;

  @Input() public addBy: string[] = ['Enter'];

  @Output() public change: EventEmitter<Values> = new EventEmitter();

  public inputElValue = '';

  public settledValues: Values = [];

  @ViewChild('input', { static: false }) private _inputEl: ElementRef<HTMLInputElement>;

  private _destroy$: Subject<void> = new Subject();

  constructor(private _service: TmInputManyService, private _cd: ChangeDetectorRef) {}

  public ngOnInit() {
    this._service.setValueExtractor(this.type);
    this._service.setValue(this.value || [], true);

    zip(this._service.value$, this._service.parsedValue$)
      .pipe(takeUntil(this._destroy$))
      .subscribe(([value, parsed]) => this._updateValue(value, parsed));
  }

  public registerOnChange(fn: any): void {
    this._ngHookOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._ngHookOnTouched = fn;
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onBlur() {
    this._ngHookOnTouched();
  }

  public onInputChange(value: Value): void {
    this._service.editValueByIndex(-1, value);
  }

  public writeValue(value: Values): void {
    this._service.setValue(value || [], true);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._cd.markForCheck();
  }

  public focusOnInputEl(): void {
    this._inputEl.nativeElement.focus();
  }

  public deleteItemByIndex(i: number): void {
    this._service.deleteValueByIndex(i);
  }

  public onInputElKeypress(e: KeyboardEvent): void {
    if (e.code === 'Backspace' && !this.inputElValue.length) {
      // When input is empty, make previous value item editable
      this._service.deleteValueByIndex(-1);
      e.preventDefault();
    } else if (this.addBy.includes(e.code) && this.inputElValue.length) {
      // Allow to commit value with special keys
      this._service.appendValue('');
      e.preventDefault();
    }
  }

  protected onHostClick(event: Event): void {
    if (event.target === event.currentTarget) {
      this.focusOnInputEl();
    }
  }

  private _updateValue(externalValue: Values, internalValue: Values): void {
    this.value = externalValue;
    this.settledValues = internalValue.slice(0, -1);
    this.inputElValue = internalValue.slice(-1)[0] || '';
    if (this.maxlength && this._service.getLengthByValues(this.settledValues) >= +this.maxlength) {
      this.inputElValue = '';
    }

    this.change.next(internalValue);
    this._ngHookOnChange(internalValue);

    if (this._inputEl) {
      this._inputEl.nativeElement.value = this.inputElValue;
    }

    this._cd.markForCheck();
  }

  private _ngHookOnChange: (value: Values) => void = () => {};

  private _ngHookOnTouched: () => any = () => {};
}
