import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TmAsyncValidatorsService } from '@tm-shared/helpers';
import { PATTERN_STRING_256_1 } from '@tm-shared/helpers/patterns';
import { whitespacesValidator } from '@tm-shared/helpers/validators/validators';
import { TmPrivilegeMapItem, TmPrivilegesService } from '@tm-shared/privileges';
import { TmTreeNodeData, TmTreeNodeDataState } from '@tm-shared/tree';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RoleModel } from './role.model';

@Injectable()
export class RoleFormService {
  public roleId$: Observable<number> = this._activatedRoute.paramMap.pipe(
    map((params) => parseInt(params.get('id') || '', 10)),
    shareReplay(1)
  );
  constructor(
    private _privileges: TmPrivilegesService,
    private _activatedRoute: ActivatedRoute,
    private _asyncValidation: TmAsyncValidatorsService,
    private _t: TranslateService,
    private _fb: FormBuilder
  ) {}

  public parseDataToTreeNodeData(_data: RoleModel): Observable<TmTreeNodeData[]> {
    let currentPrivileges: string[] = _data.privileges.map((_p) => _p.PRIVILEGE_CODE);

    let processor = (item: TmPrivilegeMapItem & { children: any }) => {
      const id = item.id.replace(/[:\/]/g, '_');
      const descriptionKey = `settings-access.privileges.${id}_description`;
      let descriptionTranslation = this._t.instant(descriptionKey);
      if (descriptionTranslation === descriptionKey) {
        descriptionTranslation = null;
      }
      let result: TmTreeNodeData = Object.assign({}, item, {
        // динамически подставляем локализацию привилегий
        name: this._t.instant(`settings-access.privileges.${id}`),
        hintIconText: descriptionTranslation,
        isExpanded: !!item.children,
        isSelected: currentPrivileges.indexOf(item.id) > -1,
      });

      let dependencies: (TmTreeNodeDataState & { id: string })[] = ((item.value && item.value.dependencies) || []).map(
        (key) => ({
          id: key,
          isSelected: true,
          isDisabled: true,
        })
      );

      if (dependencies.length) {
        result.dependencies = dependencies;
      }

      return result;
    };

    return this._privileges.getPrivilegeTreeFor(null, processor);
  }

  public createRoleFormGroup() {
    return this._fb.group({
      ROLE_ID: null,
      DISPLAY_NAME: [
        null,
        [
          Validators.required,
          Validators.pattern(PATTERN_STRING_256_1),
          whitespacesValidator,
          Validators.maxLength(256),
        ],
        this._asyncValidation.getAsyncObjectValidation('role', this.roleId$),
      ],
      privileges: [null, Validators.required],
      NOTE: [null, [Validators.maxLength(4000)]],
    });
  }
}
