export const MODEL_ID_KEY = 'ROLE_ID';

export const MODULE_KEY = 'settings:access:role';

export function getPrivilegeRequest(key: string) {
  return `${MODULE_KEY}:${key}`;
}

export class RoleModel implements TmApi.role.CollectionItem {
  public CHANGE_DATE: string;
  public CREATE_DATE: string;
  public DISPLAY_NAME = '';
  public EDITABLE: number;
  public NOTE: string;
  public ROLE_ID: number;
  public ROLE_TYPE: TmApi.role.Type;
  public privileges: any[] = [];
  public users: { USERNAME: string }[];
}
