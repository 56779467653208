import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmGridModule } from '@tm-shared/grid';
import { TmScopeSelectModalComponent } from './scope-select-modal.component';
import { TmScopeSelectComponent } from './scope-select.component';
import { TmIconModule } from '../../../@tm-shared/icons/tm-icon.module';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IwSharedModule,
    TmIconModule,
    TranslateModule,
    TmGridModule,
  ],
  exports: [TmScopeSelectComponent],
  declarations: [TmScopeSelectComponent, TmScopeSelectModalComponent],
  entryComponents: [TmScopeSelectModalComponent],
})
export class TmScopeSelectModule {}
