<iw-select
  class="selectControl"
  placeholder="{{ 'settings-access.roleSelect.empty' | translate }}"
  [formControl]="control"
  [multiple]="true"
  [searchable]="true"
  [server]="getSearchCallback()"
  [messageTemplate]="messageTemplate"
  data-qa="roleSelectInput"
></iw-select>
<button
  class="openModal"
  iw-icon-button
  [disabled]="control?.disabled"
  type="button"
  (click)="openDialog()"
  data-qa="openDialogTrigger"
>
  <tm-icon class="icon" svgIcon="create"></tm-icon>
</button>

<ng-template #messageTemplate>
  <h3>{{ 'settings-access.roleSelect.searchEntityTitle' | translate }}</h3>
  <p>{{ 'settings-access.roleSelect.searchEntityDescription' | translate }}</p>
</ng-template>
