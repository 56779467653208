import { TransformOptions, VisibleAttributesOption } from './audit-common';
import {
  ActionNotificationValue,
  ActionStatusValue,
  ActionTagValue,
  ActionTypeValue,
  ActionViolationValue,
  BooleanAsInteger,
  PolicyAction,
  PolicyActionType,
} from '../../../typings/generated/audit-policy-rule';
import { transformViolation } from './audit-policy-helpers';

export type PolicyActionsGrouped = {
  [key in PolicyActionType]: PolicyAction[];
};

export const visiblePolicyActionAttributes: VisibleAttributesOption[] = [
  {
    path: 'NOTIFICATION_TEMPLATE',
    i18nKey: 'audit.auditPolicyActions.attributes.NOTIFICATION_TEMPLATE',
    transform: transformNotificationTemplate,
  },
  {
    path: 'VIOLATION',
    i18nKey: 'audit.auditPolicyActions.attributes.VIOLATION',
    transform: transformActionViolation,
  },
  {
    path: 'ACTION',
    i18nKey: 'audit.auditPolicyActions.attributes.ACTION',
    transform: transformAction,
  },
  {
    path: 'TAG',
    i18nKey: 'audit.auditPolicyActions.attributes.TAG',
    transform: transformTags,
  },
  {
    path: 'ADD_PERSON_STATUS',
    i18nKey: 'audit.auditPolicyActions.attributes.ADD_PERSON_STATUS',
    transform: transformPersonStatus,
  },
  {
    path: 'DELETE',
    i18nKey: 'audit.auditPolicyActions.attributes.DELETE',
    transform: transformDelete,
  },
];

export function remapActionsByType(value: PolicyAction[]): PolicyActionsGrouped {
  return value
    .filter((x) => !!x)
    .reduce((result, value) => {
      if (value.TYPE) {
        if (!result[value.TYPE]) {
          result[value.TYPE] = [];
        }
        result[value.TYPE].push(value);
      }

      return result;
    }, {} as PolicyActionsGrouped);
}

function transformDelete(value: PolicyAction[] | null | undefined, o: TransformOptions): string {
  return (
    value
      ?.map((v) => {
        return (v.DATA?.VALUE as BooleanAsInteger)
          ? o.t.instant('audit.auditPolicyActions.attributes.deleteEvent')
          : o.t.instant('audit.auditPolicyActions.attributes.doNotDeleteEvent');
      })
      .join(', ') || ''
  );
}

function transformActionViolation(actions: PolicyAction[] | null | undefined, o: TransformOptions): string {
  return (
    actions
      ?.map((action) => {
        const v = action.DATA?.VALUE as ActionViolationValue;
        return transformViolation(v, o);
      })
      .filter((x) => !!x)
      .join(', ') || ''
  );
}

function transformAction(actions: PolicyAction[] | null | undefined, o: TransformOptions): string {
  return (
    actions
      ?.map((action) => {
        const v = action.DATA?.VALUE as ActionTypeValue;
        /**
         * @translate audit.auditPolicyActions.attributes.ALLOW
         * @translate audit.auditPolicyActions.attributes.BLOCK
         * @translate audit.auditPolicyActions.attributes.QUARANTINE
         */
        return o.t.instant(`audit.auditPolicyActions.attributes.${v}`);
      })
      .filter((x) => !!x)
      .join(', ') || ''
  );
}

function transformTags(actions: PolicyAction[] | null | undefined): string {
  return (
    actions
      ?.map((action) => (action.DATA?.VALUE as ActionTagValue[]).map((v) => v.NAME) || '')
      .filter((x) => !!x)
      .join(', ') || ''
  );
}

function transformPersonStatus(actions: PolicyAction[] | null | undefined): string {
  return (
    actions
      ?.map((action) => (action.DATA?.VALUE as ActionStatusValue[]).map((v) => v.NAME) || '')
      .filter((x) => !!x)
      .join(', ') || ''
  );
}

function transformNotificationTemplate(actions: PolicyAction[] | null | undefined): string {
  return (
    actions
      ?.map((action) => {
        const notification = action.DATA?.VALUE as ActionNotificationValue[];
        return notification.map((n) => n.NAME || '').join(', ');
      })
      .filter((x) => !!x)
      .join(', ') || ''
  );
}
