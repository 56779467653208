export enum TmTechLoaderStatus {
  error,
  inProgress,
  success,
  downloadable,
}

export enum TmTechLoaderAction {
  export = 'export',
  import = 'import',
}

export enum TmTechLoaderType {
  technology = 'technology',
  protected = 'protected',
  policy = 'policy',
}

export interface TmTechLoaderItem {
  key: string;
  title: string;
  status: TmTechLoaderStatus;
  statusText?: string;
  statusDescription?: string;
  errorPopover?: string;
  errorPopoverUseHtml?: boolean;
  url?: string;
}

export interface TmTechLoaderGroup {
  key: string;
  items: TmTechLoaderItem[];
  i18nKey: string;
}
