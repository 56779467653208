<tm-dropzone #documentCheckLoader [multiple]="true" hidden class="hidden"></tm-dropzone>
<tm-toolbar class="toolbar">
  <button
    iw-icon-button
    data-qa="addCategoryBtn"
    type="button"
    [disableClickEventOnly]="!(canCreate | async)"
    iwPopover="{{
      type
        | i18nSelect
          : {
              autoling: 'tech-classifier.autoling.page.toolbar.create' | translate,
              'image-classifier': 'tech-classifier.imageClassifier.page.toolbar.create' | translate
            }
    }}"
    (click)="create.emit()"
  >
    <tm-icon svgIcon="create"></tm-icon>
  </button>
  <button
    iw-icon-button
    data-qa="editCategoryBtn"
    type="button"
    [disableClickEventOnly]="!(canEdit | async)"
    iwPopover="{{
      type
        | i18nSelect
          : {
              autoling: 'tech-classifier.autoling.page.toolbar.edit' | translate,
              'image-classifier': 'tech-classifier.imageClassifier.page.toolbar.edit' | translate
            }
    }}"
    (click)="editCategory()"
  >
    <tm-icon svgIcon="edit"></tm-icon>
  </button>
  <button
    (tmDeleteConfirm)="removeCategory()"
    [tmDeleteConfirmItems]="selectedCategoryName | async"
    [tmDeleteConfirmDialogData]="{
      text:
        type
        | i18nSelect
          : {
              autoling: 'tech-classifier.autoling.modal.text' | translate: { items: selectedCategoryName | async },
              'image-classifier':
                'tech-classifier.imageClassifier.modal.text' | translate: { items: selectedCategoryName | async }
            }
    }"
    iw-icon-button
    data-qa="removeCategoryBtn"
    type="button"
    [disableClickEventOnly]="!(canRemove | async)"
    [iwPopover]="popoverWithErrorsTpl"
    [context]="{
      errors: canRemoveErrors | async,
      text:
        type
        | i18nSelect
          : {
              autoling: 'tech-classifier.autoling.page.toolbar.remove' | translate,
              'image-classifier': 'tech-classifier.imageClassifier.page.toolbar.remove' | translate
            }
    }"
  >
    <tm-icon svgIcon="delete"></tm-icon>
  </button>
  <button
    iw-icon-button
    data-qa="importExportBtn"
    #optionsPopover="iwPopover"
    [iwPopover]="optionsPopoverTpl"
    [closeOnMousemoveOutside]="true"
    triggers="click"
    placement="bottom-start"
    type="button"
    [disableClickEventOnly]="!(canImportExport | async)"
  >
    <span class="arrowDown"></span>
  </button>
  <button
    class="btnWithPadding"
    iw-icon-button
    data-qa="checkFileBtn"
    [disableClickEventOnly]="!(canCheckFile | async)"
    [progress]="(checkFileProgress | async) || false"
    (click)="checkFileBtnClicked()"
    [iwPopover]="popoverWithErrorsTpl"
    [context]="{
      errors: canCheckFileErrors | async,
      text:
        type
        | i18nSelect
          : {
              autoling: 'tech-classifier.autoling.page.toolbar.checkFile' | translate,
              'image-classifier': 'tech-classifier.imageClassifier.page.toolbar.checkFile' | translate
            }
    }"
  >
    <tm-icon svgIcon="checking"></tm-icon>
  </button>
  <button
    class="btnWithPadding"
    iw-icon-button
    data-qa="startTrainingBtn"
    [disableClickEventOnly]="!(canStartTraining | async)"
    (click)="startTraining()"
    [iwPopover]="popoverWithErrorsTpl"
    [context]="{
      errors: canTrainErrors | async,
      text:
        type
        | i18nSelect
          : {
              autoling: 'tech-classifier.autoling.page.toolbar.startTraining' | translate,
              'image-classifier': 'tech-classifier.imageClassifier.page.toolbar.startTraining' | translate
            }
    }"
  >
    <span class="arrowRight"></span>
  </button>
</tm-toolbar>

<ng-template #optionsPopoverTpl>
  <div class="dropdown">
    <button data-qa="importTechBtn" [disabled]="!(canImportExport | async)" (click)="importTech()">
      {{
        type
          | i18nSelect
            : {
                autoling: 'tech-classifier.autoling.page.toolbar.importTech' | translate,
                'image-classifier': 'tech-classifier.imageClassifier.page.toolbar.importTech' | translate
              }
      }}
    </button>
    <button data-qa="exportTechBtn" [disabled]="!(canImportExport | async)" (click)="exportTech()">
      {{
        type
          | i18nSelect
            : {
                autoling: 'tech-classifier.autoling.page.toolbar.exportTech' | translate,
                'image-classifier': 'tech-classifier.imageClassifier.page.toolbar.exportTech' | translate
              }
      }}
    </button>
  </div>
</ng-template>

<ng-template #popoverWithErrorsTpl let-errors="errors" let-text="text">
  <div>{{ text }}</div>
  <ul *ngIf="errors?.length" class="errors">
    <li *ngFor="let key of errors">
      {{ key | translate }}
    </li>
  </ul>
</ng-template>
