import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwModalService, IwNotificationsService } from '@platform/shared';
import { TmRoleApiService } from '@tm-shared/api-services/role-api.service';
import { TmFormComponent } from '@tm-shared/form';
import { TmAsyncValidatorsService } from '@tm-shared/helpers';
import { ModalConfirmComponent } from '@tm-shared/modals';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmSidebarService } from '@tm-shared/structure/sidebar/sidebar.service';
import { TmTreeComponent, TmTreeNodeData } from '@tm-shared/tree';
import { Observable, combineLatest, merge, of, throwError } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';
import { RoleFormService } from './role-form.service';
import { RoleModel, getPrivilegeRequest } from './role.model';

/**
 * @translate settings-access.validation.validationError
 * @translate settings-access.validation.maxlength
 * @translate settings-access.validation.required
 */
@Component({
  selector: 'tm-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss'],
  providers: [TmAsyncValidatorsService, RoleFormService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleFormComponent extends TmFormComponent<FormGroup> implements OnInit, AfterViewInit {
  @ViewChild(TmTreeComponent, { static: false }) public tree: TmTreeComponent;
  public createDate: string;
  public changeDate: string;

  public editable$: Observable<boolean>;

  public confirmDataLoss$ = of(null).pipe(
    switchMap(() => {
      return this._modalService.open(ModalConfirmComponent, {
        title: this._t.instant('settings-access.confirmAction'),
        text: this._t.instant('settings-access.user.form.dataLossWarning'),
      });
    }),
    switchMap((modal) => {
      return modal.component.decision.pipe(map((ok) => Boolean(ok)));
    })
  );

  public treeData$: Observable<TmTreeNodeData[]>;

  /**
   * Stream: Form data
   */
  public data$: Observable<TmApi.role.CollectionItem>;

  /**
   * Stream: new user creation flag
   */
  private _isNew$: Observable<boolean> = this._roleFormService.roleId$.pipe(map((id) => isNaN(id)));
  private _canEditRole$: Observable<boolean>;

  private _modelIsEditable$: Observable<boolean>;

  constructor(
    public service: TmRoleApiService,
    private _t: TranslateService,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    private _sidebarService: TmSidebarService,
    private _privileges: TmPrivilegesService,
    private _notify: IwNotificationsService,
    private _modalService: IwModalService,
    private _roleFormService: RoleFormService
  ) {
    super();

    this.data$ = this._roleFormService.roleId$.pipe(
      switchMap((id) => (isNaN(id) ? of(new RoleModel()) : this.service.getById(id))),
      filter((data) => (!data ? (this.close(), false) : true)),
      shareReplay(1),
      takeUntil(this._destroyed$)
    );

    this._canEditRole$ = this._privileges.can(getPrivilegeRequest('edit')).pipe(take(1), shareReplay(1));

    this._modelIsEditable$ = this.data$.pipe(map((data) => this.service.isModelEditable(data)));
    this.editable$ = combineLatest([this._modelIsEditable$, this._canEditRole$]).pipe(
      map(([canBeEditable, hasRolesToEdit]) => canBeEditable && hasRolesToEdit)
    );

    this.treeData$ = merge(this.data$, this._t.onLangChange.pipe(map(() => this.form.getRawValue() as RoleModel))).pipe(
      switchMap((data) => this._roleFormService.parseDataToTreeNodeData(data))
    );
  }

  public ngOnInit(): void {
    this._watchIsNewToChangeTitle();
    this.form = this._roleFormService.createRoleFormGroup();

    this.data$.pipe(takeUntil(this._destroyed$)).subscribe((data) => {
      this.form.reset();
      this.form.patchValue(data);
      if (data.CREATE_DATE) {
        this.createDate = data.CREATE_DATE;
      }
      if (data.CHANGE_DATE) {
        this.changeDate = data.CHANGE_DATE;
      }
    });

    this.editable$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((editable) => (editable ? this.form.enable() : this.form.disable()));

    merge(this.close$, this.submit$)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this._closeSidebar());

    this._sidebarService.open('right');
  }

  public ngAfterViewInit() {
    this.tree.onSelectionChanged.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this.form.get('privileges')!.setValue(this.tree.getSelectedIds().map((id) => ({ PRIVILEGE_CODE: id })));
    });
  }

  /**
   * Save form data
   */
  protected _onSubmit() {
    const serverData = this.form.getRawValue();
    return this.service.createOrUpdate(serverData).pipe(
      catchError((response: HttpErrorResponse) => {
        this._notify.error(
          this._t.instant('settings-access.role.errors.error'),
          this._t.instant('settings-access.role.errors.requestFail')
        );
        return throwError(response);
      }),
      tap(() => this.close(true)),
      takeUntil(this._destroyed$)
    );
  }

  private _watchIsNewToChangeTitle() {
    merge(this._isNew$, this._t.onLangChange.pipe(switchMapTo(this._isNew$)))
      .pipe(takeUntil(this._destroyed$))
      .subscribe((isNew) => {
        let title;

        if (isNew) {
          title = this._t.instant('settings-access.role.form.titleCreateNew');
        } else {
          title = this._t.instant('settings-access.role.form.titleEdit');
        }

        this._sidebarService.updateTitle(title);
      });
  }

  /**
   * Close sidebar
   */
  private _closeSidebar(): void {
    this._router.navigate(['./'], {
      relativeTo: this._activatedRoute.parent,
      queryParamsHandling: 'merge',
    });

    this._sidebarService.close('right');
  }
}
