import { Component, Input } from '@angular/core';
import { AuditGridCellRenderer } from 'plugins/audit/audit-extend.service';
import { CombinedCondition, ChildCondition } from 'typings/generated/audit-event-query';
import {
  parseCondition,
  ParsedCondition,
  ComponentCondition,
  SimpleCondition,
} from 'plugins/audit/audit-parsers/audit-event-query-conditions';
import { translateCategoryOrGroup } from 'plugins/audit/audit-parsers/audit-event-query';
import { TranslateService } from '@ngx-translate/core';

type InputData = NonNullable<CombinedCondition | ChildCondition>;

@Component({
  selector: 'tm-audit-condition-group',
  templateUrl: 'audit-condition-group.component.html',
  styleUrls: ['audit-condition-group.component.scss'],
})
export class TmAuditConditionGroupComponent implements AuditGridCellRenderer<InputData> {
  @Input() public data?: InputData;

  @Input() public showLabel = false;

  constructor(protected _t: TranslateService) {}

  public get group(): CombinedCondition | null {
    return (this.data && ('category' in this.data ? null : this.data)) || null;
  }

  public get simpleCondition(): SimpleCondition | null {
    const condition = this.condition;

    if (!condition || 'component' in condition) {
      return null;
    }

    return condition as SimpleCondition;
  }

  public get componentCondition(): ComponentCondition | null {
    const condition = this.condition;

    if (!condition || !('component' in condition)) {
      return null;
    }

    return condition;
  }

  public get condition(): ParsedCondition | null {
    if (this.data && 'category' in this.data) {
      const data = parseCondition(this.data, this._t);

      if (!data || typeof data === 'string') {
        return {
          data: data,
        };
      }

      return data;
    }

    return null;
  }

  public get label(): string | null {
    if (!this.data || !this.showLabel) {
      return null;
    }
    if ('category' in this.data) {
      return translateCategoryOrGroup(this.data.category, this._t);
    }

    return this._t.instant('audit.auditQuery.attrs.query.columns.conditionGroup');
  }
}
