<tm-content [hidden]="!categoryId" [title]="categoryData?.DISPLAY_NAME">
  <div class="category">
    <tm-toolbar class="toolbar">
      <button
        iw-icon-button
        data-qa="addDocumentBtn"
        type="button"
        [disableClickEventOnly]="!(canCreate$ | async)"
        iwPopover="{{ 'tech-autoling.category.toolbar.create' | translate }}"
        (click)="fileInput.showFileSelector()"
      >
        <tm-icon svgIcon="create"></tm-icon>
      </button>
      <tm-dropzone
        data-qa="autolingDocumentInput"
        class="hidden"
        hidden
        #fileInput
        [multiple]="true"
        (change)="loadDocuments($event)"
      ></tm-dropzone>
      <button
        iw-icon-button
        data-qa="editDocumentBtn"
        type="button"
        [disableClickEventOnly]="!(canEdit$ | async)"
        iwPopover="{{ 'tech-autoling.category.toolbar.edit' | translate }}"
        (click)="editDocument()"
      >
        <tm-icon svgIcon="edit"></tm-icon>
      </button>
      <button
        (tmDeleteConfirm)="deleteDocument()"
        [tmDeleteConfirmItems]="selectedNames$ | async"
        iw-icon-button
        data-qa="removeDocumentBtn"
        type="button"
        [disableClickEventOnly]="!(canRemove$ | async)"
        iwPopover="{{ 'tech-autoling.category.toolbar.remove' | translate }}"
      >
        <tm-icon svgIcon="delete"></tm-icon>
      </button>

      <input
        type="text"
        [tmGridFilter]="grid"
        propertyToFilter="DISPLAY_NAME"
        [startsWithAny]="true"
        class="tm-input search-input"
        placeholder="{{ 'tech-autoling.category.documentSearchPlaceholder' | translate }}"
        data-qa="autoling-document-search"
      />
    </tm-toolbar>

    <tm-panel
      class="notificationPanel"
      type="error"
      *ngIf="trainingError$ | async"
      [text]="'tech-autoling.category.trainingFailureNotification' | translate"
    ></tm-panel>

    <tm-grid #grid class="grid" [service]="service" [gridOptions]="gridOptions" [initialRequest]="false"> </tm-grid>
  </div>
</tm-content>

<tm-filler-text
  *ngIf="!categoryId"
  text="{{ 'tech-autoling.page.toolbar.selectCategory' | translate }}"
></tm-filler-text>

<tm-filler-text
  *ngIf="trainingInProgress$ | async"
  text="{{ 'tech-autoling.page.toolbar.trainingInProgress' | translate }}"
></tm-filler-text>
