import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TmAsyncValidatorsService } from '@tm-shared/helpers/validators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * @translate lists-statuses.validation.validationError
 * @translate lists-statuses.validation.empty
 * @translate lists-statuses.validation.not_unique_field
 * @translate lists-statuses.validation.maxlength
 * @translate lists-statuses.validation.required
 */
@Injectable()
export class StatusFormService {
  public statusId$: Observable<string> = this._activatedRoute.paramMap.pipe(map((params) => params.get('id') || ''));
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private _asyncValidation: TmAsyncValidatorsService
  ) {}

  public createStatusFormGroup() {
    return this._fb.group({
      IDENTITY_STATUS_ID: null,
      DISPLAY_NAME: [
        null,
        [Validators.required, Validators.maxLength(256)],
        this._asyncValidation.getAsyncObjectValidation('ldapStatus', this.statusId$),
      ],
      COLOR: '#4e954e',
      NOTE: [null, [Validators.maxLength(1025)]],
    });
  }

  public handleFormDisableState(form: FormGroup, modelIsEditable: boolean, canEditStatus: boolean) {
    if (!canEditStatus) {
      form.disable();
    } else {
      if (modelIsEditable) {
        form.enable();
      } else {
        form.disable();
        form.get('COLOR')!.enable();
      }
    }
  }
}
