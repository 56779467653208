import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmSharedModule } from '@tm-shared';
import { TmDeleteConfirmModule } from '@tm-shared/delete-confirm/delete-confirm.module';
import { TmDropzoneModule } from '@tm-shared/dragndrop/dropzone.module';
import { TmToolbarModule } from '@tm-shared/toolbar';
import en from './i18n/tech-classifier.en.json';
import ru from './i18n/tech-classifier.ru.json';
import { TmClassifierPageToolbarComponent } from './tech-classifier-toolbar/tech-classifier-toolbar.component';

@NgModule({
  declarations: [TmClassifierPageToolbarComponent],
  imports: [
    CommonModule,
    IwSharedModule,
    TranslateModule,
    TmToolbarModule,
    TmDeleteConfirmModule,
    TmDropzoneModule,
    TmSharedModule,
  ],
  exports: [TmClassifierPageToolbarComponent],
})
export class TmTechClassifierModule {
  constructor(protected _t: TranslateService) {
    this._t.setTranslation('ru', ru, true);
    this._t.setTranslation('en', en, true);
  }
}
