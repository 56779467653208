import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { AuditGridCellRenderer } from '../audit-extend.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tm-audit-br-item',
  template: ` <ng-container *ngFor="let item of data"> {{ item }}<br /> </ng-container> `,
  styles: [
    `
      :host {
        display: block;
        position: relative;
        flex: 0 0 auto;
      }
    `,
  ],
})
export class TmAuditBrItemsCellComponent implements AuditGridCellRenderer<string[]> {
  @Input() public data?: string[] = [];
}
