import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IwModalService } from '@platform/shared';
import { TmRoleApiService } from '@tm-shared/api-services/role-api.service';
import { TmAccessSelectComponent } from '../access-select';
import { TmRoleSelectModalComponent } from './role-select-modal.component';

@Component({
  selector: 'tm-role-select',
  templateUrl: './role-select.component.html',
  styleUrls: ['./role-select.component.scss'],
})
export class TmRoleSelectComponent extends TmAccessSelectComponent {
  @Input() public control: FormControl;

  public modalComponent = TmRoleSelectModalComponent;

  constructor(public service: TmRoleApiService, _modalService: IwModalService) {
    super(_modalService);
  }
}
