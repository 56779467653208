import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { TmGridComponent, TmGridOptions } from '@tm-shared/grid';
import { RoutingCellComponent } from '@tm-shared/grid/cell-renderers/html-cell/routing-cell.component';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { Observable, Subject, combineLatest, throwError } from 'rxjs';
import { catchError, debounceTime, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { SystemListItem } from '../generated/resources';
import { SystemList } from '../generated/resources-group';
import { ResourcesService } from '../resources-component/resources.service';
import { TREE_ROOT } from '../resources.model';
import { Privilege_lists_resources_resource } from '../../../typings/generated/privileges';

@Injectable()
export class ResourcesTableService implements OnDestroy {
  public set grid(_grid: TmGridComponent<SystemListItem>) {
    this._grid = _grid;

    this._resourceId$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._grid.resetSelection();
    });
  }

  private _grid: TmGridComponent<SystemListItem>;

  private _resourceId$: Observable<string>;

  private _destroy$: Subject<any>;

  constructor(
    private _t: TranslateService,
    private _route: ActivatedRoute,
    private _notify: IwNotificationsService,
    private _privileges: TmPrivilegesService,
    private _resourcesService: ResourcesService
  ) {
    this._resourceId$ = this._route.params.pipe(map((params) => params.id));

    this._destroy$ = new Subject();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public getTableConfig(): Observable<TmGridOptions> {
    return combineLatest([this._resourcesService.getResourcesGroup(), this._resourceId$]).pipe(
      map(([resources, selectedResourceGroupId]) => {
        const extendedConfig = this._getBasicConfig();

        let resourcesMap: { [key: string]: SystemList } = {};
        if (resources.data) {
          resources.data.forEach((resource) => {
            resourcesMap[resource.LIST_ID] = resource;
          });
        }

        if (selectedResourceGroupId === TREE_ROOT) {
          extendedConfig.columnDefs.splice(1, 0, {
            resizable: true,
            field: 'catalog',
            headerValueGetter: () => this._t.instant('lists-resources.table.catalog'),
            cellRendererFramework: RoutingCellComponent,
            valueGetter: ({ data }: { data: SystemListItem }): TmGrid.routingCell.RoutingData => {
              const resource = resourcesMap[data.LIST_ID];
              return {
                name: resource.DISPLAY_NAME,
                routerLink: ['/lists', 'resources', resource.LIST_ID],
                replaceUrl: true,
              };
            },
          });
        }
        return { ...extendedConfig };
      }),
      startWith(this._getBasicConfig())
    );
  }

  public setGridFilteringLogic(inputValueChanges$: Observable<string>): void {
    combineLatest([this._resourceId$, inputValueChanges$.pipe(startWith(''), debounceTime(300))])
      .pipe(
        tap(([resourceId, resourceName]) => {
          const filter = resourceId === TREE_ROOT ? '' : resourceId;
          this._grid.updateFilter('LIST_ID', filter);
          this._grid.updateFilterAndRefresh('VALUE', `*${resourceName}*`);
        }),
        takeUntil(this._destroy$),
        catchError((e) => {
          this._notify.error(
            this._t.instant('lists-resources.errors.error'),
            this._t.instant('lists-resources.errors.request-failed')
          );

          return throwError(e);
        })
      )
      .subscribe();
  }

  /**
   * Check privileges
   */
  public can(
    actionKey: NonNullable<Privilege_lists_resources_resource['action']>,
    ...rules$: Observable<boolean>[]
  ): Observable<boolean> {
    let can$: Observable<boolean> = this._privileges.can({
      type: 'lists:resources:resource',
      action: actionKey,
    });

    return combineLatest(can$, ...rules$).pipe(map((bools: boolean[]) => bools.every((b) => b)));
  }

  private _getBasicConfig(): TmGridOptions {
    return {
      domLayout: 'normal',
      columnDefs: [
        {
          field: 'VALUE',
          sort: 'asc',
          resizable: true,
          sortable: true,
          headerValueGetter: () => this._t.instant('lists-resources.table.display-name'),
        },
        {
          field: 'NOTE',
          sortable: true,
          resizable: true,
          headerValueGetter: () => this._t.instant('lists-resources.table.note'),
        },
      ],
    };
  }
}
