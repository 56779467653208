import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IwSharedModule, IwPopoverOptions } from '@platform/shared';
import { TmColorPickerComponent } from './color-picker.component';

@NgModule({
  imports: [CommonModule, IwSharedModule],
  declarations: [TmColorPickerComponent],
  exports: [TmColorPickerComponent],
  providers: [
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), {
        showDelay: 0,
        hideDelay: 0,
      }),
    },
  ],
})
export class TmColorPickerModule {}
