import { TransformOptions, VisibleAttributesOption } from './audit-common';
import { TmAuditBrItemsCellComponent } from '../audit-cell-components/audit-br-items.component';
import { PersonRule } from '../../../typings/generated/audit-policy-rule';
import { transformPluginAttributes, transformViolation } from './audit-policy-helpers';

export const visiblePolicyPersonRuleAttributes: VisibleAttributesOption[] = [
  {
    path: 'DATA.VIOLATION',
    i18nKey: 'audit.auditPolicyActions.attributes.VIOLATION',
    transform: transformViolation,
  },
  {
    path: 'DATA',
    i18nKey: 'audit.auditPolicyRule.attributes.CONNECTED_POLICY',
    transform: transformConnectedPolicy,
  },
  {
    path: 'DATA.PluginAttributes',
    i18nKey: 'audit.auditPolicyRule.attributes.PluginAttributes',
    transform: transformPluginAttributes,
    component: TmAuditBrItemsCellComponent,
  },
];

function transformConnectedPolicy(value: PersonRule['DATA'], _options: TransformOptions) {
  return value?.POLICY?.map((item) => item.DISPLAY_NAME).join(' ,') || '';
}
