import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { AuditGridCellRenderer } from '../../audit-extend.service';

export type GroupItem = {
  groupName: string;
  items: string[];
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'audit-simple-group.component.html',
  styleUrls: ['audit-simple-group.component.scss'],
})
export class TmAuditSimpleGroupCellComponent implements AuditGridCellRenderer<GroupItem[]> {
  @Input() public data?: GroupItem[];
}
