import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TmDescriptionGroupComponent } from './description-group.component.';
import { TmDescriptionValComponent } from './description-key.component';
import { TmDescriptionRowComponent } from './description-row.component';
import { TmDescriptionKeyComponent } from './description-val.component';
import { TmDescriptionComponent } from './description.component';
import { TmDescriptionModule } from './description.module';

@NgModule({
  imports: [CommonModule, TmDescriptionModule],
  entryComponents: [
    TmDescriptionComponent,
    TmDescriptionGroupComponent,
    TmDescriptionRowComponent,
    TmDescriptionValComponent,
    TmDescriptionKeyComponent,
  ],
})
export class TmDescriptionElementsModule {}
