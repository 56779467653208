import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TmDropzoneComponent, DropzoneOutValue } from '@tm-shared/dragndrop/dropzone.component';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil, switchMap, take } from 'rxjs/operators';
import { CheckImageResponseItem, CheckImageState } from 'typings/generated/technology-imageClassifier';
import { TmGraphicDocumentCheckService } from '../graphic-document-check.service';
import { ModalDialogRef } from '@platform/shared';

interface GridItem {
  filename: string;
  error?: string;
  category?: string;
}

@Component({
  selector: 'tm-graphic-document-check',
  templateUrl: 'graphic-document-check.component.html',
  styleUrls: ['./graphic-document-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmGraphicDocumentCheckComponent implements OnDestroy, OnInit {
  public showResults$ = this.service.state$.pipe(map((state) => this.canShowResults(state)));

  public canceled$ = this.service.state$.pipe(map((state) => state === CheckImageState.cancel));

  public lockButtons = false;

  public gridData$ = this.service.dataWithUpdates$.pipe(
    map((data) => data.map((item) => this._documentResponseToGridItem(item)).flat())
  );

  private _destroy$ = new Subject<void>();

  @ViewChild('fileInput', { static: true }) private fileInputEl: TmDropzoneComponent;

  constructor(
    public service: TmGraphicDocumentCheckService,
    private cd: ChangeDetectorRef,
    private _dialog: ModalDialogRef<unknown>
  ) {}

  public ngOnInit(): void {
    this.showResults$.pipe(debounceTime(0), takeUntil(this._destroy$)).subscribe(() => this.cd.detectChanges());
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public close(): void {
    this._dialog.dismiss(null);
  }

  public checkFiles(value: DropzoneOutValue): void {
    const files = value ? (Array.isArray(value) ? value : [value]) : [];

    this.service.checkFiles(files);
    this.fileInputEl.clear();
  }

  public stopFileCheckProcess(): void {
    this.lockButtons = true;
    this.service
      .stopCheckProcess()
      .pipe(switchMap(() => this.service.state$.pipe(take(1))))
      .subscribe({
        next: (state) => {
          /**
           * If user has stopped uploading before any file was uploaded, then close modal
           */
          if (state === CheckImageState.pristine) {
            this.close();
          }
        },
        complete: () => {
          this.lockButtons = false;
        },
      });
  }

  protected canShowResults(state: CheckImageState): boolean {
    return [CheckImageState.success, CheckImageState.cancel, CheckImageState.error].includes(state);
  }

  private _documentResponseToGridItem(document: CheckImageResponseItem): GridItem[] {
    const error = document.error_code ? this.service.getErrorText(document.error_code) : undefined;

    if (!document.categories?.length) {
      return [
        {
          filename: document.DISPLAY_NAME,
          error: error,
        },
      ];
    }

    return document.categories.map((category) => {
      return {
        filename: document.DISPLAY_NAME,
        error: error,
        category: category.DISPLAY_NAME,
      };
    });
  }
}
