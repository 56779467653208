import { Routes } from '@angular/router';
import { FilesComponent } from './files-component/files.component';
import { FilesTableComponent } from './files-table-component/files-table.component';
import { TREE_ROOT } from './files.model';

export const ROUTES: Routes = [
  {
    path: 'lists/file',
    component: FilesComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: TREE_ROOT,
      },
      {
        path: ':id',
        component: FilesTableComponent,
      },
    ],
  },
];
