import { Component, OnInit, forwardRef, Input, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  Validator,
  ValidationErrors,
  FormGroup,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { TmFormComponent } from '@tm-shared/form';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { DateRange, DateRangeMode, FromToRange } from './date-range.model';
import { isFromToMode, isVariableMode } from './date-range-utils';
import { IwPopoverDirective } from '@platform/shared';

type FormData = Pick<DateRange, 'mode'> & Partial<DateRange>;

@Component({
  selector: 'tm-date-range',
  styleUrls: ['date-range.component.scss'],
  templateUrl: 'date-range.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TmDateRangeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TmDateRangeComponent),
      multi: true,
    },
  ],
})
export class TmDateRangeComponent extends TmFormComponent<FormGroup>
  implements OnInit, ControlValueAccessor, Validator {
  @Input() public defaultFrom?: Date;

  @Input() public defaultTo?: Date;

  @Input() public defaultValue = 1;

  @Input() public allowTimeSelection = false;

  @Input() public set availableModes(modes: DateRangeMode[]) {
    this.dateRangeOptions = this.getDateRangeOptions(modes);
  }

  @ViewChild(IwPopoverDirective) public popover: IwPopoverDirective;

  public get fromToModeIsActive(): boolean {
    return isFromToMode(this.form.value);
  }

  public get variableModeIsActive(): boolean {
    return isVariableMode(this.form.value);
  }

  public dateRangeOptions: IwSelectItem<DateRangeMode>[] = this.getDateRangeOptions(Object.values(DateRangeMode));

  public ngHookOnChange: (value: DateRange) => void = () => {};
  public ngHookOnValidatorChange: (value: any) => void = () => {};
  public ngHookOnValidatorTouched: (value: any) => void = () => {};

  constructor(private t: TranslateService, formBuilder: FormBuilder) {
    super();

    this.form = formBuilder.group(this.getInitialFormData());
  }

  public ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._destroyed$)).subscribe(() => this.emitChange());

    this.get('mode')
      ?.valueChanges.pipe(takeUntil(this._destroyed$))
      .subscribe((val) => this.modeHasChanged(val));
  }

  public validate(): ValidationErrors | null {
    return null;
  }

  public registerOnChange(cb: any) {
    this.ngHookOnChange = cb;
  }

  public registerOnValidatorChange(cb: any) {
    this.ngHookOnValidatorChange = cb;
  }

  public registerOnTouched(cb: any) {
    this.ngHookOnValidatorTouched = cb;
  }

  public writeValue(value?: DateRange | null): void {
    if (value) {
      this.form.patchValue(this.getDateRange(value));
    }
  }

  /**
   * Update form values on mode change.
   */
  public modeHasChanged(mode: DateRangeMode): void {
    // Get form patch with updated mode
    const data: Partial<DateRange> = this.getDateRange({ ...this.form.value, mode });

    // Update everything but mode itself
    delete data.mode;
    if (Object.keys(data).length) {
      this.form.patchValue(data);
    }
  }

  public dateInputChanged(dateRange: FromToRange): void {
    this.form.patchValue({
      from: dateRange.from,
      to: dateRange.to,
    });
    this.closePopover();
  }

  public toInputStrFormat(date?: Date): string {
    if (!date) {
      return '';
    }

    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
  }

  public closePopover(): void {
    this.popover.hide();
  }

  private getDateRange(formData: FormData = this.form.value): DateRange {
    switch (formData.mode) {
      case DateRangeMode.fromTo:
        return {
          mode: formData.mode,
          ...this.getFromTo(),
        };
      case DateRangeMode.lastNHours:
      case DateRangeMode.lastNDays:
        return {
          mode: formData.mode,
          value: this.getValue(),
        };
      case DateRangeMode.none:
      case DateRangeMode.thisDay:
      case DateRangeMode.thisWeek:
      case DateRangeMode.thisMonth:
      case DateRangeMode.last3Days:
      case DateRangeMode.last7Days:
      case DateRangeMode.last30Days:
        return {
          mode: formData.mode,
        };
    }
  }

  private getValue(): number {
    const value = this.value<number | string>('value');
    return value && !isNaN(+value) ? +value : this.defaultValue;
  }

  private getFromTo(): Pick<FromToRange, 'from' | 'to'> {
    const from = this.value<Date | null>('from');
    const to = this.value<Date | null>('to');

    /**
     * If mode is set to dateRange but there is something wrong with the provided dates
     * use defaults
     */
    return from && to ? { from, to } : this.getDefaultFromToRange();
  }

  private getDefaultFromToRange(): Pick<FromToRange, 'from' | 'to'> {
    const to = this.defaultFrom || new Date();
    let from = this.defaultTo;

    if (!from || +from >= +to) {
      from = new Date(to);
      from.setMonth(to.getMonth() - 1);
    }

    return { from, to };
  }

  /**
   * Emit current form state in proper output format
   */
  private emitChange(): void {
    this.ngHookOnChange(this.getDateRange());
  }

  private getInitialFormData(): Record<string, unknown> {
    return {
      mode: DateRangeMode.none,
      from: null,
      to: null,
      value: null,
    };
  }

  private getDateRangeOptions(modes: DateRangeMode[]): IwSelectItem<DateRangeMode>[] {
    /**
     * @translate @tm-shared.dateRange.mode.all
     * @translate @tm-shared.dateRange.mode.range
     * @translate @tm-shared.dateRange.mode.last_hours
     * @translate @tm-shared.dateRange.mode.last_days
     * @translate @tm-shared.dateRange.mode.this_day
     * @translate @tm-shared.dateRange.mode.this_week
     * @translate @tm-shared.dateRange.mode.this_month
     * @translate @tm-shared.dateRange.mode.last_3_days
     * @translate @tm-shared.dateRange.mode.last_7_days
     * @translate @tm-shared.dateRange.mode.last_30_days
     */
    return modes.map((mode) => ({
      value: mode,
      label: this.t.instant(`@tm-shared.dateRange.mode.${mode}`),
    }));
  }
}
