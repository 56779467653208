import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmGridModule } from '@tm-shared/grid';
import { TmStructureModule } from '@tm-shared/structure';
import { TmTreeModule } from '@tm-shared/tree';
import { ScopeConditionService } from '../scope/scope-condition.service';
import { TmSearchSelectComponent } from './search-select.component';
import { SearchSelectService } from './search-select.service';
import { TmSearchTabModalComponent } from './search-tab-modal.component';
import { PersonTabComponent } from './tab-components/person-tab.component';
import { NgxdModule } from '@ngxd/core';
import { StatusTabComponent } from './tab-components/status-tab.component';
import { GroupTabComponent } from './tab-components/group-tab.component';
import { WorkstationTabComponent } from './tab-components/workstation-tab.component';
import { TagTabComponent } from './tab-components/tag-tab.component';
import { DocumentTabComponent } from './tab-components/document-tab.component';
import { SearchTabHostComponent } from './tab-components/search-tab-host.component';
import { PolicyTabComponent } from './tab-components/policy-tab.component';
import { CatalogTabComponent } from './tab-components/catalog-tab.component';
import { TmIconModule } from '../../../@tm-shared/icons/tm-icon.module';

const tabComponents = [
  StatusTabComponent,
  PersonTabComponent,
  GroupTabComponent,
  WorkstationTabComponent,
  TagTabComponent,
  PolicyTabComponent,
  DocumentTabComponent,
  CatalogTabComponent,
];
export type TabComponents = typeof tabComponents;
export type TabComponent = NonNullable<TabComponents[0]>;

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    IwSharedModule,
    TmIconModule,
    TranslateModule,
    TmStructureModule,
    TmTreeModule,
    TmGridModule,
    NgxdModule,
  ],
  exports: [TmSearchSelectComponent],
  providers: [ScopeConditionService, SearchSelectService],
  declarations: [TmSearchSelectComponent, TmSearchTabModalComponent, ...tabComponents, SearchTabHostComponent],
})
export class TmSearchSelectModule {}
