import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { delay, take, takeUntil } from 'rxjs/operators';
import { ModalDialogRef } from '@platform/shared';

@Component({
  selector: 'tm-modal-confirm',
  templateUrl: 'modal-confirm.component.html',
  styleUrls: ['modal-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmComponent<T = unknown> implements OnInit, OnDestroy {
  /**
   * Title
   */
  @Input() public title = '';

  /**
   * Question
   */
  @Input() public text = '';

  /**
   * Positive decision button text
   */
  @Input() public yes: string = this._t.instant('@tm-shared.modals.yes');

  /**
   * Negative decision button text
   */
  @Input() public no: string = this._t.instant('@tm-shared.modals.no');

  /**
   * Emits decision: yes (true), no (false)
   */
  @Output() public decision = new EventEmitter<boolean>();

  protected _destroy$: Subject<void> = new Subject();

  constructor(protected _t: TranslateService, private _dialog: ModalDialogRef<T>) {}

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnInit(): void {
    this.decision
      .pipe(
        take(1),
        takeUntil(this._destroy$),
        // Dismiss after decision emits
        delay(0)
      )
      .subscribe(() => {
        this._dialog.dismiss(null);
      });
  }
}
