import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TmPolicyApiService } from '@tm-shared/api-services';
import { TmBookwormFormatsService } from '@tm-shared/bookworm';
import { TmGridComponent, TmGridOptions } from '@tm-shared/grid';
import { RoutingCellComponent } from '@tm-shared/grid/cell-renderers/html-cell/routing-cell.component';
import { ColDef } from 'ag-grid-community';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FilesService } from '../files-component/files.service';
import { TREE_ROOT } from '../files.model';
import { FormatInfo } from '../../../typings/generated/bookworm';

@Injectable()
export class FilesTableService implements OnDestroy {
  public set grid(_grid: TmGridComponent<FormatInfo>) {
    this._grid = _grid;

    this._formatType$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._grid.resetSelection();
    });
  }

  private _grid: TmGridComponent<FormatInfo>;

  private _destroy$ = new Subject();

  private _formatType$: Observable<string> = this._route.params.pipe(map((params) => params.id));

  constructor(
    private _t: TranslateService,
    private _route: ActivatedRoute,
    private _formatsService: TmBookwormFormatsService,
    private _filesService: FilesService,
    private _policyApi: TmPolicyApiService
  ) {}

  public getTableConfig(): Observable<TmGridOptions> {
    return combineLatest([this._filesService.formatTypes$, this._formatType$]).pipe(
      map(([response, selectedFormatId]) => {
        const extendedConfig = this._getBasicConfig();
        if (selectedFormatId === TREE_ROOT) {
          extendedConfig.columnDefs.splice(1, 0, <ColDef>{
            resizable: true,
            field: 'catalog',
            headerValueGetter: () => this._t.instant('lists-files.table.catalog'),
            cellRendererFramework: RoutingCellComponent,
            valueGetter: ({ data }: { data: FormatInfo }): TmGrid.routingCell.RoutingData => {
              const formatType = response.data.find((format) => format.format_type_id === data.type_ref)!;
              return {
                name: formatType.name,
                routerLink: ['/lists', 'file', formatType.format_type_id],
                replaceUrl: true,
                queryParamsHandling: 'merge',
              };
            },
          });
        }
        return extendedConfig;
      })
    );
  }

  public setGridFilteringLogic(): void {
    this._formatType$.pipe(takeUntil(this._destroy$)).subscribe((formatType) => {
      this._grid.updateFilterAndRefresh('type_ref', formatType === TREE_ROOT ? '' : formatType);
    });
  }

  public createPolicyAndNavigate(policyType: 'OBJECT' | 'AGENT', idsToSelect: (string | number)[]) {
    let policy = this._policyApi.getEmptyPolicyObject(policyType as any);
    const data$ = this._formatsService.getDataStream().pipe(
      map((response) => {
        return response.data.filter((item) => idsToSelect.includes(item.format_id));
      }),
      map((items) => {
        const itemsForPolicy = items.map((item) => {
          return {
            ID: item.format_id,
            TYPE: 'fileformat',
            NAME: item.name,
          };
        });
        this._policyApi.setItemsToPolicy(policy, itemsForPolicy);
        return policy;
      })
    );
    this._policyApi.createPolicyAndNavigate(data$);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _getBasicConfig(): TmGridOptions {
    return {
      domLayout: 'normal',
      columnDefs: [
        {
          field: 'name',
          resizable: true,
          sortable: true,
          headerValueGetter: () => this._t.instant('lists-files.table.display-name'),
        },
        {
          field: 'mime_type',
          sortable: true,
          resizable: true,
          headerValueGetter: () => this._t.instant('lists-files.table.mime'),
        },
        {
          resizable: true,
          field: 'extensions',
          sortable: true,
          headerValueGetter: () => this._t.instant('lists-files.table.extensions'),
          valueGetter: ({ data }: { data: FormatInfo }): string => {
            return (data.extensions || []).join(',');
          },
        },
      ],
    };
  }
}
