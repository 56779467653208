import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { objectToFormData } from '@tm-shared/helpers/form';
import { Observable } from 'rxjs';
import * as ApiAdlibitum from './generated/adlibitum';

type LdapsCheckConnectionData = Omit<ApiAdlibitum.AdlibitumTestRequest['data'], 'page_size'>;
export type LdapsCheckConnection =
  | Omit<ApiAdlibitum.AdlibitumTestRequest, 'action'>
  | {
      data: LdapsCheckConnectionData;
    };

@Injectable()
export class TmLdapService extends TmCollectionLoader<ApiAdlibitum.AdlibitumInfoGet> {
  public src = '/api/adlibitum/server';

  public idAttribute = 'name';

  public checkServerConnection(
    data: LdapsCheckConnection,
    serverId?: string
  ): Observable<ApiAdlibitum.AdlibitumTestResponse> {
    const url = serverId ? `${this.src}/${serverId}` : this.src;
    const payload = objectToFormData(
      Object.assign(
        {
          action: 'test',
        },
        data
      )
    );
    return this._http.patch(url, payload) as Observable<ApiAdlibitum.AdlibitumInfoGet>;
  }

  public syncServer(id: string, forceFullSync: boolean = true) {
    const payload = objectToFormData(<ApiAdlibitum.AdlibitumStartSyncRequest>{
      action: 'startSync',
      data: {
        force_full_sync: forceFullSync,
      },
    });

    return this._http.patch(`${this.src}/${id}`, payload) as Observable<ApiAdlibitum.AdlibitumInfoGet>;
  }
}
