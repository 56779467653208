import { CheckboxCellComponent } from '../../../../@tm-shared/grid/cell-renderers';
import { TranslateService } from '@ngx-translate/core';
import { TmGridOptions } from '../../../../@tm-shared/grid';

export function getCommonColumnDefinition(t: TranslateService) {
  return <TmGridOptions>{
    sortingOrder: ['desc', 'asc'],
    columnDefs: [
      {
        width: 30,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        sort: 'asc',
        resizable: true,
        sortable: true,
        headerValueGetter: () => t.instant('settings-access.search.headerNames.ldapStatus.displayName'),
      },
      {
        field: 'NOTE',
        resizable: true,
        sortable: true,
        headerValueGetter: () => t.instant('settings-access.search.headerNames.ldapStatus.note'),
      },
    ],
  };
}
