import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmCronJobPresetType, getPreset } from '@tm-shared/cronjob/cronjob';
import { getWeekdayNameByIndex, localizeWeekdayName } from '@tm-shared/helpers/date';
import { Subject } from 'rxjs';
import * as ApiAdlibitum from '../generated/adlibitum';
import { LdapsCheckConnection } from '../ldap.service';

enum SyncState {
  pristine,
  progress,
  error,
  success,
}

@Component({
  selector: 'tm-ldap-server',
  templateUrl: './ldap-server.component.html',
  styleUrls: ['./ldap-server.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmLdapServerComponent implements OnDestroy {
  @Input() public set data(data: ApiAdlibitum.AdlibitumInfoGet | null) {
    const desc = data && data.sync_description ? data.sync_description : '';
    const progress = Boolean(data ? data.sync_in_progress : false);

    this._data = data || null;
    this._updateSyncState(desc, progress);
    this._cd.markForCheck();
  }

  public get data(): ApiAdlibitum.AdlibitumInfoGet | null {
    return this._data;
  }

  @Output() public check: EventEmitter<LdapsCheckConnection> = new EventEmitter();

  public syncState: SyncState = SyncState.pristine;

  public syncErrorTimestamp?: string;

  public syncErrorText?: string;

  public syncStateEnum: typeof SyncState = SyncState;

  private _data: ApiAdlibitum.AdlibitumInfoGet | null = null;

  private _destroy$: Subject<void> = new Subject();

  constructor(private _cd: ChangeDetectorRef, private _t: TranslateService) {}

  public getJobValue(): string {
    if (this.data && this.data.job) {
      const preset = getPreset(this.data.job);

      switch (preset.type) {
        case TmCronJobPresetType.daily:
          return preset.getLocalTime();
        case TmCronJobPresetType.hours:
          return preset.getHourInterval();
        case TmCronJobPresetType.minutes:
          return preset.getMinuteInterval();
        case TmCronJobPresetType.weekdays:
          return `${preset.getLocalTime()} - ${preset
            .getWeekdaysLocal()
            .map((day) => localizeWeekdayName(getWeekdayNameByIndex(day), this._t.currentLang))
            .join(', ')}`;
      }
    }

    return '';
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public checkConnection(): void {
    if (this.data) {
      this.check.next({
        data: this.data,
      });
    }
  }

  private _updateSyncState(state: string, progress: boolean): void {
    if (progress) {
      this.syncState = SyncState.progress;
    } else if (state.indexOf('success') === 0) {
      this.syncState = SyncState.success;
    } else if (!state) {
      this.syncState = SyncState.pristine;
    } else {
      this.syncState = SyncState.error;
    }

    if (this.syncState !== SyncState.error) {
      // Remove error details
      delete this.syncErrorText;
      delete this.syncErrorTimestamp;
      return;
    }

    // Parse error details
    const [, timestamp, , ...errorText] = state.split(' ');

    this.syncErrorText = errorText.join(' ');
    this.syncErrorTimestamp = timestamp.replace('@', '').trim();
  }
}
