/**
 * Server response on exports request
 */
export interface ExportsResponse {
  data?: ExportModel[];
}

export interface ExportMonitorURLAttributes {
  start: number;
  limit: number;
}

export type ExportModel = {
  DISPLAY_NAME: string;
  REPORT_ID: number;
  STATUS: number;
  PERCENT_COMPLETE: number;
  PARAMS?: ExportParamsModel[];
  COMPLETE_DATE: string;
  CREATE_DATE: string;
  HASH?: string;
  TYPE: string;
};

export type ExportParamsModel = {
  filename: string;
  filesize: number;
  path: string;
  type?: any;
};

export type ExportFilesModel = {
  filename: string;
  filesize: number;
  hash: string;
  path: string;
  query: string;
  queryId: number;
  report_id: number;
  time: number;
};

export const STATUSES = {
  NEW: 0,
  COMPLETE: 1,
  ERROR: 2,
  EXECUTING: 3,
};
