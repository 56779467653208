import { Injectable } from '@angular/core';
import { Observable, Subject, fromEvent, merge, of, timer } from 'rxjs';
import { mapTo, switchMap, takeUntil, throttleTime } from 'rxjs/operators';

@Injectable()
export class TmIdlerService {
  public reset$: Observable<void> = of(null).pipe(
    switchMap(() => this._start$),
    switchMap(() => this._reset$.pipe(takeUntil(this._stop$)))
  );

  public timeout$: Observable<void> = of(null).pipe(
    switchMap(() => this._start$),
    switchMap(() => this._timeout$.pipe(takeUntil(this._stop$)))
  );

  private _domEvents$ = of(null).pipe(
    switchMap(() =>
      merge(...this._options.events.map((event) => fromEvent(document, event))).pipe(
        throttleTime(this._options.throttleTime)
      )
    )
  );

  // Fired when idler reset
  private _reset$: Subject<void> = new Subject();

  // Fired when idler start
  private _start$: Subject<void> = new Subject();

  // Fired when idler stop
  private _stop$: Subject<void> = new Subject();

  // Fired when idler timeout
  private _timeout$: Subject<void> = new Subject();

  // Idler`s options
  private _options = {
    timeout: 30 * 60 * 1000,
    throttleTime: 1000,
    events: ['mousemove', 'mousedown', 'keypress', 'DOMMouseScroll', 'mousewheel', 'touchmove', 'MSPointerMove'],
  };

  private _resetTimeout$: Subject<void> = new Subject();

  /**
   * Start idler
   */
  public start(timeout: number): void {
    this._stop$.next();
    this._start$.next();

    merge(of(null), this._domEvents$, this._resetTimeout$.pipe(mapTo(null)))
      .pipe(
        switchMap((event) => {
          if (event !== null) {
            this._reset$.next();
          }

          return timer(timeout);
        }),
        takeUntil(this._stop$)
      )
      .subscribe(() => this._timeout$.next());
  }

  /**
   * Stop idler
   */
  public stop(): void {
    this._stop$.next();
  }

  /**
   * Reset idler
   */
  public reset(silent: boolean = false): void {
    this._resetTimeout$.next();

    if (!silent) {
      this._reset$.next();
    }
  }
}
