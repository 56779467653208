import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { AbstractGridApiAdapter, ParsedParams } from '../grid/abstract-grid-api-adapter';
import { TmBookwormFormatsService } from './bookworm-formats.service';
import { FORMATS_CUSTOM_FILTER } from './bookworm.model';
import { FormatInfo } from '../../typings/generated/bookworm';

const DEFAULT_RETRY_LIMIT = 50;
const DEFAULT_RETRY_DELAY = 5;

@Injectable()
export class BookwormFormatsTableAdapterService extends AbstractGridApiAdapter {
  public idAttribute = 'format_id';

  constructor(private _formatsService: TmBookwormFormatsService) {
    super();
  }

  protected getData(params: ParsedParams, retryDelayMs = DEFAULT_RETRY_DELAY, retryLimit = DEFAULT_RETRY_LIMIT) {
    return this._formatsService.getDataStream(retryDelayMs, retryLimit).pipe(
      take(1),
      map((response) => {
        let data = response.data;
        let processedData: any[] = data;
        if (params) {
          processedData = this._customFilter(data, params);
        }
        return {
          data: processedData,
          itemsLengthAfterFilter: processedData.length,
        };
      })
    );
  }

  /**
   *  Filter name OR mime-type OR extension when parameter filter[FORMATS_CUSTOM_FILTER] is used
   *  Default filter otherwise
   */
  private _customFilter(data: FormatInfo[], params: ParsedParams): FormatInfo[] {
    if (params.filter) {
      params.filter.forEach((parsedParam) => {
        if (parsedParam.field === FORMATS_CUSTOM_FILTER) {
          data = data.filter(
            (item) =>
              this._valuePassesFilter(item.name, parsedParam.value) ||
              this._valuePassesFilter(item.mime_type, parsedParam.value) ||
              this._valuePassesFilter((item.extensions || []).join(','), parsedParam.value)
          );
        } else {
          data = data.filter((item: any) => this._valuePassesFilter(item[parsedParam.field], parsedParam.value));
        }
      });
    }

    return data;
  }
}
