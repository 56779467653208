import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export const VALIDATE_URL = '/api/textObjectPattern/validateRegexp';

@Injectable()
export class TmAnalysisTextPatternValidatorService {
  constructor(private _http: HttpClient) {}

  public validateById(patternId: string, value: string): Observable<TmPluginAnalysisText.ParsedValidationResult[]> {
    return this._validate({
      TEXT_OBJECT_PATTERN_ID: patternId,
      VALUE: value,
    });
  }

  public validate(pattern: string, value: string): Observable<TmPluginAnalysisText.ParsedValidationResult[]> {
    return this._validate({
      REGEXP: pattern,
      VALUE: value,
    });
  }

  private _handleResponseError(e: HttpErrorResponse): string {
    if (e.error.error === 'validation') {
      let regexp = e.error.meta.REGEXP;
      if (regexp) {
        // @translate analysis-text.pattern.validation.regexp_error_bad_syntax
        // @translate analysis-text.pattern.validation.regexp_error_too_complex
        // @translate analysis-text.pattern.validation.regexp_error_too_short
        // @translate analysis-text.pattern.validation.not_unique_field
        return 'analysis-text.pattern.validation.' + regexp[Object.keys(regexp)[0]].message;
      } else {
        // @translate analysis-text.pattern.validation.validation
        return 'analysis-text.pattern.validation.validation';
      }
    }
    // @translate analysis-text.pattern.validation.unknown
    return 'analysis-text.pattern.validation.unknown';
  }

  private _validate(
    payload: TmPluginAnalysisText.ValidationPayload
  ): Observable<TmPluginAnalysisText.ParsedValidationResult[]> {
    return this._http.post(VALIDATE_URL, payload).pipe(
      catchError((e) => {
        // Throw parsed request error
        return throwError(this._handleResponseError(e));
      }),
      switchMap((response: { data: ApiTextObjectPatternValidation.ResponseData }) => {
        let results = this._parseResponse(payload.VALUE, response.data);

        if (!results.length) {
          // @translate analysis-text.patternValidator.validationErrors.noMatches
          return throwError('analysis-text.patternValidator.validationErrors.noMatches');
        }

        return of(results);
      })
    );
  }

  private _parseResponse(
    userInput: string,
    data: ApiTextObjectPatternValidation.ResponseData
  ): TmPluginAnalysisText.ParsedValidationResult[] {
    let currentOffset = 0;
    let total: number = data.length;

    return data.reduce((result, entry, i) => {
      if (currentOffset !== entry.offset) {
        result.push({
          value: userInput.slice(currentOffset, entry.offset),
        });
      }

      result.push({
        isMatch: true,
        value: userInput.substr(entry.offset, entry.length),
      });

      currentOffset = entry.offset + entry.length;

      if (i === total - 1 && currentOffset < userInput.length) {
        result.push({
          value: userInput.slice(currentOffset, userInput.length),
        });
      }

      return result;
    }, [] as TmPluginAnalysisText.ParsedValidationResult[]);
  }
}
