import { NgModule } from '@angular/core';
import { AutofocusDirective } from './autofocus.directive';

@NgModule({
  imports: [],
  declarations: [AutofocusDirective],
  exports: [AutofocusDirective],
})
export class TmFormHelpersModule {
  constructor() {}
}
