<div class="grid-sizing-wrapper">
  <ag-grid-angular
    *ngIf="!(error$ | async)"
    class="grid"
    [ngStyle]="{ 'min-height.px': gridHeight$ | async }"
    data-qa="grid"
    [gridOptions]="options"
  >
  </ag-grid-angular>

  <tm-panel
    *ngIf="error$ | async"
    type="error"
    title="{{ '@tm-shared.grid.error.errorTitle' | translate }}"
    text="{{ '@tm-shared.grid.error.generalError' | translate }}"
  >
  </tm-panel>

  <!-- Empty collection overlay  -->
  <tme-overlay *ngIf="tableService.empty$ | async">
    {{ '@tm-shared.grid.noItems' | translate }}
  </tme-overlay>

  <!-- Spinner -->
  <tme-overlay *ngIf="tableService.loading$ | async">
    <tme-spinner></tme-spinner>
  </tme-overlay>
</div>

<tm-pagination
  #paginator
  *ngIf="displayPagination"
  class="pagination"
  (onPaginate)="goToPage($event)"
  data-qa="tm-ag-grid-pagination"
  [current]="(state.page$ | async) || 0"
  [paginationSizeItems]="paginationItems"
  [selectedPaginationSize]="(state.pageLimit$ | async) || 0"
  (paginationSizeChange)="updateLimit($event)"
  [total]="(pagesTotal$ | async) || 0"
>
</tm-pagination>
