import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TmAuditEventFilterService } from '../audit-event-filter/audit-event-filter.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { UrlStreams } from '@tm-shared/url-streams';
import { map, takeUntil, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { AuditEvent, AuditResponse } from '../audit.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class TmAuditEventListService {
  public static readonly auditFirstEventParamKey = 'filter[AUDIT_LOG_ID][<=]';
  public static readonly startParamKey = 'start';
  public static readonly limitParamKey = 'limit';
  public static readonly sortParamKey = 'sort';
  public static readonly apiAuditEventsUrl = '/api/auditLog';

  public static filterChangeDebounce = 500;

  public dataToShow: Observable<AuditEvent[]>;

  /**
   * Maximum items to be loaded per chunk
   */
  public limit = 20;

  /**
   * Truthy if there is more items to fetch
   */
  public canLoadMore = true;

  public sortBy = 'CHANGE_DATE';

  public sortDirection: 'asc' | 'desc' = 'desc';

  /**
   * This observable emits when user changes filter (collection becomes invalid)
   */
  private filterChanges: Observable<unknown>;
  /**
   * All fetched audit events
   */
  private data = new BehaviorSubject<AuditEvent[]>([]);

  constructor(private http: HttpClient, private filterService: TmAuditEventFilterService) {
    this.filterChanges = this.filterService.changes.pipe(debounceTime(TmAuditEventListService.filterChangeDebounce));
    this.filterChanges
      .pipe(
        switchMap(() => this.resetData()),
        untilDestroyed(this)
      )
      .subscribe();

    this.dataToShow = this.data.asObservable();
  }

  public toggleSortDir(): Observable<'asc' | 'desc'> {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    return this.resetData().pipe(map(() => this.sortDirection));
  }

  /**
   * Load next chunk of data
   */
  public loadMore(chunks: number = 1): Observable<AuditEvent[] | null> {
    /**
     * Return null if there is nothing to load more
     */
    if (!this.canLoadMore) {
      return of(null);
    }

    const nextChunkUrl = UrlStreams.create(TmAuditEventListService.apiAuditEventsUrl);

    /**
     * Enforce filtration by ID to freeze results (always start from the 1st element)
     */
    const firstChunk = this.getFirstItemId();

    /**
     * TODO: support on backend
     */
    if (firstChunk !== null) {
      // nextChunkUrl.setParam(TmAuditEventListService.auditFirstEventParamKey, firstChunk);
    }

    nextChunkUrl.setParams(this.filterService.getFilter());
    nextChunkUrl.setParams(this.getPaginationParams(chunks));

    /**
     * Fetch next chunk
     */
    return this.http.get<AuditResponse>(nextChunkUrl.toString()).pipe(
      map((data) => this.onChunkLoaded(data)),
      takeUntil(this.filterChanges),
      untilDestroyed(this)
    );
  }

  /**
   * Clean data and load initial chunk
   */
  public resetData(): Observable<AuditEvent[] | null> {
    this.canLoadMore = true;
    this.data.next([]);

    return this.loadMore().pipe(
      catchError(() => {
        this.canLoadMore = false;
        return of(null);
      })
    );
  }

  /**
   * Get first audit event id if any
   */
  private getFirstItemId(): number | null {
    return this.data.getValue()[0]?.AUDIT_LOG_ID || null;
  }

  /**
   * Get pagination params (start, limit, sort)
   */
  private getPaginationParams(chunks: number = 1): Record<string, string | number> {
    return {
      [TmAuditEventListService.limitParamKey]: this.limit * chunks,
      [TmAuditEventListService.startParamKey]: this.data.getValue().length,
      [this.getSortByParamKey()]: this.sortDirection,
    };
  }

  /**
   * Deserialize response
   */
  private deserialize(response: AuditResponse): AuditEvent[] {
    return response.data;
  }

  /**
   * Run this on successful response, return all collection items
   */
  private onChunkLoaded(response: AuditResponse): AuditEvent[] {
    const auditEvents = this.deserialize(response);

    /**
     * Check if this chunk is the last one for this query
     */
    if (this.isLastChunk(response)) {
      this.canLoadMore = false;
    }

    /**
     * Update data prop
     */
    const nextData = this.mergeData(auditEvents);
    this.data.next(nextData);

    /**
     * Return collection
     */
    return nextData;
  }

  /**
   * Check if this is the last data chunk for the current query
   */
  private isLastChunk(res: AuditResponse): boolean {
    return this.data.getValue().length + res.data.length >= res.meta.totalCount;
  }

  /**
   * Push items to the end of the collection
   */
  private mergeData(data: AuditEvent[]): AuditEvent[] {
    return this.data.getValue().concat(data);
  }

  /**
   * Return sort url param key
   */
  private getSortByParamKey(): string {
    return `${TmAuditEventListService.sortParamKey}[${this.sortBy}]`;
  }
}
