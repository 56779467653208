<iw-modal-header>
  {{ 'settings-access.scopeSelect.modal.title' | translate }}
  <span *ngIf="grid.getAllSelectedIds().length as len"> ({{ len }})</span>
</iw-modal-header>

<iw-input-container class="search" [allowClear]="true">
  <input iwInput [formControl]="filterControl" placeholder="{{ 'settings-access.search_placeholder' | translate }}" />
</iw-input-container>

<tm-grid class="grid" #grid [gridOptions]="gridOptions" [service]="service" [storeKey]="null"> </tm-grid>

<iw-modal-footer>
  <button class="button" iw-button color="success" (click)="acceptChanges()">
    {{ 'settings-access.scopeSelect.modal.accept' | translate }}
  </button>
  <button class="button" iw-button (click)="rejectChanges()">
    {{ 'settings-access.scopeSelect.modal.reject' | translate }}
  </button>
</iw-modal-footer>
