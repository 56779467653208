import { TranslateService } from '@ngx-translate/core';
import { AuditExtendGridData } from '../audit-extend.service';
import { AuditEvent } from '../audit.model';
import {
  VisibleAttributesOption,
  getAuditExtendRowData,
  getAuditAttributesFrom,
  getDefaultColumns,
  transformTech2Category,
} from './audit-common';

const auditImageClassifierVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'DISPLAY_NAME', i18nKey: 'audit.entries.imageClassifier.displayName' },
  { path: 'NOTE', i18nKey: 'audit.entries.imageClassifier.note' },
  {
    path: 'image_classifier2category',
    i18nKey: 'audit.entries.imageClassifier.categories',
    transform: transformTech2Category,
  },
];

export function getImageClassifierGridData(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  const cols = getDefaultColumns(data);
  const result: AuditExtendGridData[] = [];
  const baseData = getAuditExtendRowData({
    t,
    cols: cols,
    data: getAuditAttributesFrom(data),
    visibleAttributes: auditImageClassifierVisibleAttributes,
  });

  if (baseData.length) {
    result.push({
      cols,
      data: baseData,
    });
  }

  return result;
}
