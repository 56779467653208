<iw-form data-qa="patternValidatorForm" [formGroup]="form" (ngSubmit)="form.valid && submit()">
  <div class="row">
    <textarea #userInput data-qa="patternValidatorUserInput" formControlName="userInput"></textarea>
  </div>

  <div class="row" *ngIf="validationResults$ | async as validationResults">
    <div class="validationResults">
      <span
        *ngFor="let item of validationResults"
        class="validationResultItem"
        [ngClass]="{ highlighted: item.isMatch }"
        data-qa="patternValidatorResultItem"
      >
        {{ item.value }}
      </span>
    </div>
  </div>

  <div class="row" *ngIf="validationErrors$ | async as validationErrors">
    <div class="validationErrors">
      <span data-qa="patternValidatorError" *ngFor="let result of validationErrors">
        {{ result.value | translate }}
      </span>
    </div>
  </div>

  <div class="row">
    <button
      data-qa="patternValidatorSubmit"
      iw-button
      type="submit"
      [disabled]="!!form.invalid"
      [progress]="(loading$ | async) || false"
    >
      {{ 'analysis-text.pattern.checkPattern' | translate }}
    </button>
  </div>
</iw-form>
