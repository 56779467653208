import { NgModule } from '@angular/core';
import { TmAuditObjectTypeCellComponent } from './audit-object-type-cell.component';
import { CommonModule } from '@angular/common';
import { TmSpinnerModule } from '@tm-shared/spinner';
import { TmAuditBrItemsCellComponent } from './audit-br-items.component';
import { TmAuditConditionGroupComponent } from './audit-condition-group.component/audit-condition-group.component';
import { TranslateModule } from '@ngx-translate/core';
import { TmDynamicComponentModule } from '@tm-shared/dynamic-component/dynamic-component.module';
import { TmAuditProtocolCellComponent } from './audit-protocol-cell.component';
import { TmAuditSimpleGroupCellComponent } from './audit-simple-group/audit-simple-group.component';

@NgModule({
  imports: [CommonModule, TmSpinnerModule, TranslateModule, TmDynamicComponentModule],
  exports: [
    TmAuditObjectTypeCellComponent,
    TmAuditBrItemsCellComponent,
    TmAuditConditionGroupComponent,
    TmAuditProtocolCellComponent,
    TmAuditSimpleGroupCellComponent,
  ],
  declarations: [
    TmAuditObjectTypeCellComponent,
    TmAuditBrItemsCellComponent,
    TmAuditConditionGroupComponent,
    TmAuditProtocolCellComponent,
    TmAuditSimpleGroupCellComponent,
  ],
  providers: [],
})
export class TmAuditCellComponentsModule {}
