import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TmElement } from '@tm-shared/custom-elements';

@TmElement('tme-page')
@Component({
  template: ` <router-outlet></router-outlet> `,
  styles: [
    `
      :host {
        display: block;
        height: 100%;
        overflow: hidden;
      }
    `,
  ],
})
export class TmePageComponent {
  constructor(private _router: Router) {
    const path = location.pathname;
    this._router.navigateByUrl('').then(() => {
      this._router.navigateByUrl(path);
    });
  }
}
