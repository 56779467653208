import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Output } from '@angular/core';
import { TmElement } from '@tm-shared/custom-elements';

@TmElement('tme-overlay')
@Component({
  selector: 'tm-overlay',
  templateUrl: 'overlay.component.html',
  styleUrls: ['overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmOverlayComponent {
  @Output() public onClick = new EventEmitter();

  @HostListener('click') public click() {
    this.onClick.emit();
  }
}
