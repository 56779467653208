import { TranslateService } from '@ngx-translate/core';
import { TextObjectDiffAuditLog, TextObjectPattern } from 'typings/generated/audit-text-object';
import { TmAuditBrItemsCellComponent } from '../audit-cell-components/audit-br-items.component';
import { AuditExtendGridData, AuditGridDataColumn } from '../audit-extend.service';
import { AuditEvent } from '../audit.model';
import {
  VisibleAttributesOption,
  getAuditExtendRowData,
  getAuditAttributesFrom,
  TransformOptions,
  getDefaultColumns,
  transformTech2Category,
} from './audit-common';

const auditTextObjectVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'DISPLAY_NAME', i18nKey: 'audit.entries.textObject.displayName' },
  { path: 'NOTE', i18nKey: 'audit.entries.textObject.note' },
  { path: 'COUNTRY', i18nKey: 'audit.entries.textObject.country', transform: transformTextObjectLanguage },
  {
    path: 'text_object2category',
    i18nKey: 'audit.entries.textObject.categories',
    transform: transformTech2Category,
  },
];

export function getTextObjectGridData(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  const cols = getDefaultColumns(data);
  const attrs = getAuditAttributesFrom(data) as TextObjectDiffAuditLog['PROPERTY_CHANGES'];
  const result: AuditExtendGridData[] = [];
  const baseData = getAuditExtendRowData({
    t,
    cols: cols,
    data: attrs,
    visibleAttributes: auditTextObjectVisibleAttributes,
  });

  if (baseData.length) {
    result.push({
      cols,
      data: baseData,
    });
  }

  const textObjectPatternsGrid = getTextObjectPatternsGrid(attrs, cols, t);

  if (textObjectPatternsGrid) {
    result.push(textObjectPatternsGrid);
  }

  return result;
}

export function getTextObjectPatternsGrid(
  change: TextObjectDiffAuditLog['PROPERTY_CHANGES'],
  cols: (AuditGridDataColumn.old | AuditGridDataColumn.new)[],
  t: TranslateService
): AuditExtendGridData | null {
  if (!change) {
    return null;
  }

  const visibleAttributesMap = new Map<string, VisibleAttributesOption>();

  const data = cols.reduce((data, columnKey) => {
    const patterns = change[columnKey]?.text_object_patterns;

    if (!patterns) {
      return data;
    }

    /**
     * Push each text pattern as row
     */
    data[columnKey] = patterns.reduce((columnData, pattern: TextObjectPattern) => {
      const patternId = pattern.TEXT_OBJECT_PATTERN_ID;
      columnData[patternId] = pattern;

      if (!visibleAttributesMap.has(patternId)) {
        visibleAttributesMap.set(patternId, {
          path: patternId,
          i18nKey: t.instant('audit.entries.textObject.patternTypeOptions.pattern'),
          transform: transformPattern,
          component: TmAuditBrItemsCellComponent,
        });
      }

      return columnData;
    }, {} as { [key: string]: TextObjectPattern });

    return data;
  }, {} as { [key in AuditGridDataColumn]: { [key: string]: TextObjectPattern } });

  const gridData = getAuditExtendRowData({
    t,
    cols,
    visibleAttributes: Array.from(visibleAttributesMap.values()),
    data,
  });

  return gridData.length
    ? {
        title: t.instant('audit.entries.textObject.textObjectGrid'),
        cols,
        data: gridData,
      }
    : null;
}

export function transformPattern(pattern: TextObjectPattern | null | undefined, o: TransformOptions): string[] {
  if (!pattern) {
    return [];
  }

  const enabledState = pattern.ENABLED
    ? o.t.instant('audit.entries.textObject.enabledOptions.yes')
    : o.t.instant('audit.entries.textObject.enabledOptions.no');

  const patternType = pattern.IS_REGEXP
    ? o.t.instant('audit.entries.textObject.patternTypeOptions.regexp')
    : o.t.instant('audit.entries.textObject.patternTypeOptions.plainText');

  const result: string[] = [
    `${o.t.instant('audit.entries.textObject.isEnabled')}: ${enabledState}`,
    `${o.t.instant('audit.entries.textObject.patternType')}: ${patternType}`,
    `${o.t.instant('audit.entries.textObject.pattern')}: ${pattern.TEXT}`,
  ];

  if (pattern.NOTE) {
    result.push(`${o.t.instant('audit.entries.textObject.note')}: ${pattern.NOTE}`);
  }

  return result;
}

export function transformTextObjectLanguage(pattern: string, o: TransformOptions): string {
  /**
   *  @translate audit.entries.textObject.countries.all
   *  @translate audit.entries.textObject.countries.are
   *  @translate audit.entries.textObject.countries.bhr
   *  @translate audit.entries.textObject.countries.blr
   *  @translate audit.entries.textObject.countries.kaz
   *  @translate audit.entries.textObject.countries.kir
   *  @translate audit.entries.textObject.countries.mys
   *  @translate audit.entries.textObject.countries.rus
   *  @translate audit.entries.textObject.countries.sau
   *  @translate audit.entries.textObject.countries.uzb
   *  @translate audit.entries.textObject.countries.vnm
   */
  return o.t.instant(`audit.entries.textObject.countries.${pattern}`);
}
