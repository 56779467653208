import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TmUserApiService } from '@tm-shared/api-services/user-api.service';
import { TmGridComponent, TmGridOptions } from '@tm-shared/grid';
import { BooleanCellComponent, CheckboxCellComponent } from '@tm-shared/grid/cell-renderers';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmSidebarService } from '@tm-shared/structure/sidebar';
import { Observable, Subject, combineLatest, forkJoin, merge, of } from 'rxjs';
import { auditTime, filter, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ACCESS_PATH, CREATE_PATH, EDIT_PATH, TmPluginAccessUserStatus, USER_PATH } from '../access-exports';
import { TmChangePasswordService } from './change-password';
import { TmLdapUserSelectComponent } from './ldap-user-select';
import { UserDeleteModalComponent } from './user-delete-modal';
import { UserEditComponent } from './user-edit/user-edit.component';
import { MODEL_ID_KEY, UserModel, getPrivilegeRequest } from './user.model';
import { IwModalService, IwPopoverDirective } from '@platform/shared';
import { TmApiUserStatus } from '@tm-shared/api';

@Component({
  selector: 'tm-users',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [TmSidebarService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent implements AfterViewInit, OnDestroy {
  public statuses = TmApiUserStatus;

  public modal = UserDeleteModalComponent;

  public CREATE_PATH = CREATE_PATH;
  public EDIT_PATH = EDIT_PATH;
  /**
   * Grid component
   */
  @ViewChild('grid', { static: false }) public grid: TmGridComponent<UserModel>;

  @ViewChild('createPopover', { static: false }) public createPopover: IwPopoverDirective;
  @ViewChild('actionsPopover', { static: false }) public actionsPopover: IwPopoverDirective;

  /**
   * Define can streams
   */
  public canEditSelection$ = of(null).pipe(
    switchMap(() => this.grid.selected$),
    map((data) => {
      return !!data.length && data.every((user) => this.userApi.canEditUser(user));
    }),
    takeUntil(of(null).pipe(switchMap(() => this._destroyed$)))
  );
  public canRemoveSelection$ = of(null).pipe(
    switchMap(() => this.grid.selected$),
    map((data) => {
      return !!data.length && data.every((user) => this.userApi.canRemoveUser(user));
    }),
    takeUntil(of(null).pipe(switchMap(() => this._destroyed$)))
  );

  public isAdministrator$ = of(null).pipe(
    switchMap(() => this.grid.selected$),
    map((data) => {
      return !!data.length && data.every((user) => !this.userApi.isAdministrator(user));
    }),
    takeUntil(of(null).pipe(switchMap(() => this._destroyed$)))
  );

  public canChangePassword$ = this._can(
    'edit',
    this.canEditSelection$,
    of(null).pipe(switchMap(() => this._canChangePassword$))
  );
  public canCreateLdap$ = this._can('edit');
  public canCreateUser$ = this._can('edit');
  public canNotCreate$ = this._canNotAll(this.canCreateUser$, this.canCreateLdap$);
  public canRemoveUser$ = this._can('delete', this.canRemoveSelection$);
  public canSetStatus$ = this._can('edit', this.canEditSelection$, this.isAdministrator$);
  public canNotAction$ = this._canNotAll(this.canSetStatus$, this.canChangePassword$);

  /**
   * User grid options
   */
  public gridOptions: TmGridOptions = {
    columnDefs: [
      {
        width: 40,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        width: 40,
        field: 'STATUS',
        headerName: '',
        cellRendererFramework: BooleanCellComponent,
        valueGetter: ({ data }: { data: UserModel }): boolean => {
          return data.STATUS === +TmPluginAccessUserStatus.Active;
        },
      },
      {
        field: 'USERNAME',
        sort: 'asc',
        sortable: true,
        resizable: true,
        headerValueGetter: () => this._t.instant('settings-access.user.fieldNames.login'),
      },
      {
        field: 'DISPLAY_NAME',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('settings-access.user.fieldNames.displayName'),
      },
      {
        field: 'EMAIL',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('settings-access.user.fieldNames.email'),
      },
      {
        resizable: true,
        sortable: true,
        field: 'roles',
        headerValueGetter: () => this._t.instant('settings-access.user.fieldNames.roles'),
        valueGetter: ({ data }: { data: TmApi.user.CollectionItem }): string => {
          return data.roles
            .map((role) => {
              return role.DISPLAY_NAME;
            })
            .sort()
            .join(', ');
        },
      },
      {
        resizable: true,
        sortable: true,
        field: 'visibilityareas',
        headerValueGetter: () => this._t.instant('settings-access.user.fieldNames.scopes'),
        valueGetter: ({ data }: { data: TmApi.user.CollectionItem }): string => {
          return data.visibilityareas
            .map((scope) => {
              return scope.DISPLAY_NAME;
            })
            .sort()
            .join(', ');
        },
      },
      {
        resizable: true,
        sortable: true,
        field: 'NOTE',
        headerValueGetter: () => this._t.instant('settings-access.user.fieldNames.note'),
      },
    ],
  };

  private _canChangePassword$: Observable<boolean> = of(null).pipe(
    switchMap(() => this.grid.selected$),
    map((data: TmApi.user.CollectionItem[]) => data.length === 1 && !this.userApi.isLdapUser(data[0]))
  );

  /**
   * Unsubscribe trigger
   */
  private _destroyed$: Subject<void> = new Subject();

  constructor(
    public userApi: TmUserApiService,
    public router: Router,
    private _t: TranslateService,
    private _activatedRoute: ActivatedRoute,
    private _sidebarService: TmSidebarService,
    private _changePasswordService: TmChangePasswordService,
    private _privileges: TmPrivilegesService,
    private _modalService: IwModalService
  ) {}

  /**
   * Ag init hook
   */
  public ngAfterViewInit() {
    this._sidebarService
      .onAction('*', 'complete')
      .pipe(
        switchMap(() => this.grid.initialized$),
        takeUntil(this._destroyed$)
      )
      .subscribe((gridApi) => {
        gridApi.sizeColumnsToFit();
      });

    this.grid.selectedOrdered$.pipe(skip(1), takeUntil(this._destroyed$)).subscribe((selection: UserModel[]) => {
      if (!selection.length) {
        this._sidebarService.close('right');
        this.router.navigate([ACCESS_PATH], {
          queryParamsHandling: 'merge',
        });
      } else {
        this.showDialogEdit(selection[0][MODEL_ID_KEY]);
      }
    });

    if (this._activatedRoute.firstChild && this._activatedRoute.firstChild.snapshot.params['id']) {
      this.grid.selectById(this._activatedRoute.firstChild.snapshot.params['id']);
    }
  }

  public ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public onActivate(component: UserEditComponent): void {
    component.close$
      .pipe(
        tap(() => this._sidebarService.close('right')),
        auditTime(200),
        take(1),
        filter(() => !!this.grid),
        tap(() => this.grid.resetSelection()),
        tap(() => this.grid.refresh()),
        takeUntil(this._destroyed$)
      )
      .subscribe();

    this._sidebarService.open('right');
  }

  /**
   * Set user status
   */
  public setUserStatus(status: TmApiUserStatus) {
    this.actionsPopover.hide();
    this.grid.selected$
      .pipe(
        take(1),
        switchMap((selectedModels: UserModel[]) => {
          const arrayToUpdate = selectedModels.map((model) => {
            const id = model[this.userApi.idAttribute];
            return this.userApi.updateById(id, {
              STATUS: status as number,
            });
          });
          return forkJoin(arrayToUpdate);
        })
      )
      .subscribe(() => {
        this.grid.refresh();
      });
  }

  /**
   * Show change password dialog
   */
  public showDialogChangePassword() {
    this.actionsPopover.hide();
    this.grid.selected$.pipe(take(1)).subscribe((selectedModels) => {
      this._changePasswordService.createModal(selectedModels[0][MODEL_ID_KEY].toString(), selectedModels[0].USERNAME);
    });
  }

  /**
   * Open create dialog
   */
  public showDialogCreateUser() {
    this.createPopover.hide();
    this.router
      .navigate([CREATE_PATH], {
        relativeTo: this._activatedRoute,
        queryParamsHandling: 'merge',
      })
      .then(() => {
        this._sidebarService.open('right');
      });
  }

  /**
   * Open create dialog
   */
  public async showDialogCreateUserLdap() {
    this.createPopover.hide();
    const modal = await this._modalService.open(TmLdapUserSelectComponent, null, { size: 'large' });

    const componentInstance = modal.component;
    const closeComponentForm$ = componentInstance.close$.pipe(
      tap(() => {
        modal.close(null);
      })
    );

    merge(modal.dismissed, closeComponentForm$)
      .pipe(
        tap(() => this.grid.refresh()),
        take(1),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /**
   * Show editor for first selected element
   */
  public showDialogEdit(id: number): void {
    this.router
      .navigate([EDIT_PATH.replace(':id', `${id}`)], {
        relativeTo: this._activatedRoute,
        queryParamsHandling: 'merge',
      })
      .then(() => {
        this._sidebarService.open('right');
      });
  }

  public deleteSelected() {
    this.grid.deleteAllSelected().subscribe();
    this.router.navigate([ACCESS_PATH, USER_PATH], {
      queryParamsHandling: 'merge',
    });
  }

  private _canNotAll(...rules$: Observable<boolean>[]): Observable<boolean> {
    return combineLatest(rules$).pipe(map((bools: boolean[]) => bools.every((b) => !b)));
  }

  /**
   * Check privileges
   */
  private _can(actionKey: TmPluginAccess.user.PrivilegeKey, ...rules$: Observable<boolean>[]): Observable<boolean> {
    const can$: Observable<boolean> = this._privileges.can(getPrivilegeRequest(actionKey));

    return combineLatest([can$, ...rules$]).pipe(map((bools: boolean[]) => bools.every((b) => b)));
  }
}
