import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TmElement } from '@tm-shared/custom-elements';
import { TmFormComponent } from '@tm-shared/form';
import { PATTERN_STRING_256_7 } from '@tm-shared/helpers/patterns';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { TmAnalysisTextService } from '../analysis-text-service';
import { TmAnalysisTextPatternService } from './analysis-text-pattern-service';

@TmElement('tme-analysis-text-pattern')
@Component({
  selector: 'tm-analysis-text-pattern',
  templateUrl: './analysis-text-pattern.component.html',
  styleUrls: ['./analysis-text-pattern.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TmAnalysisTextPatternComponent extends TmFormComponent<FormGroup> implements OnInit {
  @Input() public readonly = false;

  @Input() public data: TmPluginAnalysisText.PatternFormInput = {
    ENABLED: 1,
    IS_REGEXP: 0,
    IS_SYSTEM: 0,
  };

  @Input() public submitCallback: (formData: TmPluginAnalysisText.PatternFormOutput) => ObservableInput<boolean>;
  @Input() public getData: (formData: TmPluginAnalysisText.PatternFormOutput) => TmPluginAnalysisText.PatternFormOutput;
  @Input() public updateData: (formData: TmPluginAnalysisText.PatternFormOutput) => void;
  @Input() public close: () => Observable<boolean>;

  @Output() public onClose: EventEmitter<void> = new EventEmitter();

  constructor(
    private _fb: FormBuilder,
    private _service: TmAnalysisTextPatternService,
    public textService: TmAnalysisTextService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      isEnabled: [Boolean(this.data.ENABLED)],
      isRegexp: [Boolean(this.data.IS_REGEXP)],
      TEXT: [
        this.data.TEXT,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(256),
          Validators.pattern(PATTERN_STRING_256_7),
        ],
      ],
      description: [this.data.NOTE, Validators.maxLength(1000)],
    });

    this.textService.resetValidation$.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      Object.keys(this.form.controls).forEach((control) => {
        this.form.get(control)!.updateValueAndValidity();
      });
    });
    this.close$.pipe(takeUntil(this._destroyed$)).subscribe(() => this.onClose.next());
  }

  public serialize(data: TmPluginAnalysisText.PatternFormModel): TmPluginAnalysisText.PatternFormOutput {
    return {
      ENABLED: data.isEnabled ? 1 : 0,
      IS_REGEXP: data.isRegexp ? 1 : 0,
      NOTE: data.description,
      TEXT: data.TEXT,
    };
  }

  protected _onSubmit(): Observable<boolean> {
    let value = this.serialize(this.form.value);
    return this._validate(this.getData(value));
  }

  private _validate(data: TmPluginAnalysisText.PatternFormOutput): Observable<boolean> {
    const validator = this._service.validate(data);
    return validator.pipe(
      map((response: { data: TmPluginAnalysisText.PatternFormOutput }) => {
        this.updateData(response.data);
        this.close();
        return true;
      }),
      catchError((response) => {
        this.textService.resetValidationErrors();
        this.setErrorsAfterSubmit(response);
        return throwError(response);
      })
    );
  }
}
