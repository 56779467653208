import { NgModule } from '@angular/core';

/**
 * This module is used to resolve { providedIn: ... } issue with cyclic dependencies
 * see issue: https://github.com/angular/angular/issues/25784
 *
 * @example
 * TmAuditService --> [TmAuditSharedModule] <-- TmAuditModule
 *      ⬆                                           ⬇
 *       <------------ TmAuditComponent <-------------
 */
@NgModule()
export class TmAuditServiceModule {}
