import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmChannelService } from '@tm-shared/channel';
import { TmDropzoneComponent, DropzoneOutValue } from '@tm-shared/dragndrop/dropzone.component';
import { TmGridComponent, TmGridOptions } from '@tm-shared/grid';
import { objectToFormData } from '@tm-shared/helpers/form';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmTechLoaderComponentService } from '@tm-shared/tech-loader/tech-loader-component.service';
import { TmTechLoaderStatus, TmTechLoaderItem } from '@tm-shared/tech-loader/tech-loader.model';
import { DateTime } from 'luxon';
import { ClassifierType, ClassifierTypeToken } from 'plugins/tech-classifier/tech-classifier-providers';
import { BehaviorSubject, Observable, Subject, combineLatest, from, merge, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  AutolingImageCreateResponse,
  CategoryGet,
  ImageGet,
  TrainingState,
} from 'typings/generated/technology-imageClassifier';
import { getPrivilegeFor, getTechLoaderGroupKey } from '../../tech-classifier/tech-classifier-helpers';
import { TmGraphicCategoryService } from '../graphic-category.service';
import {
  TmGraphicDocumentEditComponent,
  TmGraphicDocumentEditData,
} from '../graphic-document-edit/graphic-document-edit.component';
import { TmGraphicDocumentService } from '../graphic-document.service';
import { TmGraphicTrainingService } from '../graphic-training.service';
import { IwBytesWithUnitPipe, IwModalService } from '@platform/shared';
import { ErrorType } from 'typings/generated/errors';
import { HttpErrorResponse } from '@angular/common/http';

const categoryIdFilter = 'image_classifier2category.CATEGORY_ID';

@Component({
  selector: 'tm-graphic-category',
  templateUrl: './graphic-category.component.html',
  styleUrls: ['./graphic-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmGraphicCategoryComponent implements OnDestroy, AfterViewInit {
  @Input() public set data(data: CategoryGet | null) {
    if (!data) {
      this.categoryId = '';
      this._cd.markForCheck();
      return;
    }

    this.categoryData = data;
    this._cd.markForCheck();
    if (this.categoryId === data.CATEGORY_ID) {
      return;
    }
    this.categoryId = data.CATEGORY_ID;
    this.systemCategory$.next(!!data.IS_SYSTEM);

    if (this.grid) {
      this.grid.updateFilterAndRefresh(categoryIdFilter, data.CATEGORY_ID);
      this.grid.resetSelection();
      this.grid.sizeColumnsToFit();
    }
  }

  @ViewChild(TmGridComponent, { static: false }) public grid?: TmGridComponent<ImageGet>;

  @ViewChild('fileInput', { static: false }) public fileInput: TmDropzoneComponent;

  public categoryId: CategoryGet['CATEGORY_ID'];

  public gridOptions: TmGridOptions = {
    rowDragManaged: true,
    columnDefs: [
      {
        dndSource: true,
        field: 'DISPLAY_NAME',
        resizable: true,
        sort: 'asc',
        sortable: true,
        headerValueGetter: () => this._t.instant('tech-graphic.category.document-grid.displayName'),
      },
      {
        field: 'NOTE',
        resizable: true,
        headerValueGetter: () => this._t.instant('tech-graphic.category.document-grid.description'),
      },
      {
        field: 'FILE_SIZE',
        resizable: true,
        headerValueGetter: () => this._t.instant('tech-graphic.category.document-grid.fileSize'),
        valueFormatter: (params) => this._bytesWithUnitPipe.transform(params.value),
      },
      {
        field: 'FILE_PATH',
        resizable: true,
        headerValueGetter: () => this._t.instant('tech-graphic.category.document-grid.filePath'),
      },
      {
        field: 'MIME',
        resizable: true,
        headerValueGetter: () => this._t.instant('tech-graphic.category.document-grid.fileFormat'),
      },
      {
        field: 'CREATE_DATE',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('tech-graphic.category.document-grid.createDate'),
        valueGetter: ({ data }): string => {
          return DateTime.fromSQL((data as ImageGet).CREATE_DATE, {
            zone: 'utc',
          })
            .setZone('local')
            .toFormat('f');
        },
      },
    ],
  };

  public canCreate$: Observable<boolean>;

  public canEdit$: Observable<boolean>;

  public canRemove$: Observable<boolean>;

  public selected$ = new BehaviorSubject<ImageGet[]>([]);

  public selectedNames$ = this.selected$.pipe(map((items) => items.map((i) => i.DISPLAY_NAME)));

  public categoryData: CategoryGet;

  public trainingInProgress$ = this._trainingService.state$.pipe(map((state) => state === TrainingState.progress));

  public trainingError$ = this._trainingService.state$.pipe(map((state) => state === TrainingState.error));

  protected systemCategory$ = new BehaviorSubject<boolean>(false);

  protected sampleCompilerSucceed$ = this._channels
    .getUserChannel('sample_compiler')
    .pipe(filter(({ meta, state }) => meta.type === 'image_learn' && state === 'save'));

  private _destroy$: Subject<void> = new Subject();

  constructor(
    @Inject(ClassifierTypeToken) private type: ClassifierType,
    private _privileges: TmPrivilegesService,
    private _modalService: IwModalService,
    private _channels: TmChannelService,
    private _techLoader: TmTechLoaderComponentService,
    private _bytesWithUnitPipe: IwBytesWithUnitPipe,
    private _categoryService: TmGraphicCategoryService,
    private _trainingService: TmGraphicTrainingService,
    public service: TmGraphicDocumentService,
    private _cd: ChangeDetectorRef,
    private _t: TranslateService
  ) {
    this.canCreate$ = combineLatest([
      this._privileges.can(getPrivilegeFor(this.type, 'full_access')),
      this._trainingService.state$,
    ]).pipe(map(([can, training]) => can && training !== TrainingState.progress));
    this.canEdit$ = combineLatest([
      this._privileges.can(getPrivilegeFor(this.type, 'full_access')),
      this.selected$,
      this.trainingInProgress$,
    ]).pipe(map(([can, selection, trainingProgress]) => !!can && selection.length === 1 && !trainingProgress));
    this.canRemove$ = combineLatest([
      this._privileges.can(getPrivilegeFor(this.type, 'full_access')),
      this.selected$,
      this.trainingInProgress$,
    ]).pipe(map(([can, selection, trainingProgress]) => !!can && selection.length > 0 && !trainingProgress));
  }

  public ngAfterViewInit(): void {
    this.grid?.selected$.pipe(takeUntil(this._destroy$)).subscribe((selection) => {
      this.selected$.next(selection);
    });

    this.grid?.updateFilterAndRefresh(categoryIdFilter, this.categoryId);

    merge(this.service.dataChanged$, this.sampleCompilerSucceed$)
      .pipe(
        debounceTime(2000),
        tap(() => this.grid?.updateFilterAndRefresh(categoryIdFilter, this.categoryId)),
        switchMap(() => this._categoryService.refresh()),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public deleteDocument(): void {
    this.grid?.deleteAllSelected().subscribe({
      complete: () => this.service.dataChanged$.next(),
    });
  }

  public editDocument(): void {
    const documents = this.selected$.getValue();
    if (documents.length) {
      this._showEditDocumentModal(documents[0]);
    }
  }

  public loadDocuments(value: DropzoneOutValue): void {
    const files = value ? (Array.isArray(value) ? value : [value]) : [];
    const techLoaderGroup = getTechLoaderGroupKey(this.type);

    from(Array.from(files))
      .pipe(
        map((file) => {
          const tempKey = `${file.name}${Math.random()}`;
          this._techLoader.addItemToGroup(techLoaderGroup, {
            title: file.name,
            key: tempKey,
            status: TmTechLoaderStatus.inProgress,
          });
          return [tempKey, file];
        }),
        mergeMap(([tempKey, file]: [string, File]) => {
          return this.service
            .create<AutolingImageCreateResponse['data']>(
              // TODO: find out how to parse data correctly with "objectToFormData"
              objectToFormData({
                'files[]': file,
                image_classifier2category: JSON.stringify([
                  {
                    CATEGORY_ID: this.categoryId,
                  },
                ]),
              })
            )
            .pipe(
              tap((res) => {
                const sampleCompilerItemKey = res.data[0].key;
                this._techLoader.updateItemKey(techLoaderGroup, tempKey, sampleCompilerItemKey);
                this._techLoader.updateItemInGroup(techLoaderGroup, {
                  key: sampleCompilerItemKey,
                  status: TmTechLoaderStatus.inProgress,
                });
              }),
              catchError((res) => {
                const nextState: Partial<TmTechLoaderItem> = {
                  key: tempKey,
                  status: TmTechLoaderStatus.error,
                };

                if (res instanceof HttpErrorResponse && res.error.error === ErrorType.badExtension) {
                  nextState.errorPopover = this._t.instant(`tech-graphic.sampleCompiler.badExtension`);
                }

                this._techLoader.updateItemInGroup(techLoaderGroup, nextState);
                return of(null);
              })
            );
        }, 5)
      )
      .subscribe();

    this.fileInput.clear();
  }

  private async _showEditDocumentModal(data: TmGraphicDocumentEditData): Promise<void> {
    const currentModal = await this._modalService.open(TmGraphicDocumentEditComponent, { data });
    currentModal.component.onSubmit = (component) => {
      return this.service.updateById(data.FINGERPRINT_ID, component.form.value).pipe(tap(() => this.grid?.refresh()));
    };
  }
}
