import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TmFormErrorsService, TmFormComponent } from '@tm-shared/form';
import { PATTERN_STRING_128_2 } from '@tm-shared/helpers/patterns';
import {
  fieldsEqualValidator,
  microsoftStandardPasswordValidator,
  namedPatternValidator,
} from '@tm-shared/helpers/validators/validators';

// TODO: write tests

/**
 * @translate settings-access.validation.password.duplicate_field_part
 * @translate settings-access.validation.password.msPaswordOneOfFour
 * @translate settings-access.validation.password.invalid_characters
 * @translate settings-access.validation.password.required
 * @translate settings-access.validation.password.maxlength
 * @translate settings-access.validation.password.minlength
 */

@Component({
  selector: 'tm-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmChangePasswordComponent implements OnInit {
  public form: FormGroup;

  @Input() public userID: number;

  @Input() public userName: string;

  @Output()
  public onSubmit = new EventEmitter();

  @Output()
  public onCancel = new EventEmitter<null>();

  constructor(protected _fb: FormBuilder, private _formErrorsService: TmFormErrorsService) {}

  public ngOnInit() {
    this.form = this._fb.group(
      {
        PASSWORD: [
          null,
          [
            Validators.required,
            namedPatternValidator(PATTERN_STRING_128_2, 'invalid_characters'),
            microsoftStandardPasswordValidator,
          ],
        ],
        PASSWORD_CONFIRMATION: [null, Validators.required],
      },
      {
        validator: fieldsEqualValidator('PASSWORD_CONFIRMATION', 'PASSWORD'),
      }
    );
  }

  public setErrors(data: any) {
    this._formErrorsService.setErrors(data, this.form);
  }

  public submit() {
    this.onSubmit.emit({ userID: this.userID, data: this.form.value });
  }

  public getErrors(path: string) {
    return TmFormComponent.prototype.getErrors(path);
  }
}
