export const cloneJson = <T>(json: T): T => {
  try {
    return JSON.parse(JSON.stringify(json));
  } catch (e) {
    throw new Error(e);
  }
};

export function stringifyAsJson(data: any, logInsteadOfThrow?: true): string {
  try {
    return JSON.stringify(data);
  } catch (e) {
    if (logInsteadOfThrow) {
      // eslint-disable-next-line
      console.error(e);
    } else {
      throw new Error(e);
    }
  }

  return '';
}
