import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { TmTagApiService } from '@tm-shared/api-services';
import { TmBookwormContactTypeService } from '@tm-shared/bookworm';
import { TmFormComponent } from '@tm-shared/form';
import { TmAsyncValidatorsService } from '@tm-shared/helpers';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmSidebarService } from '@tm-shared/structure/sidebar/sidebar.service';
import { EMPTY, Observable, combineLatest, merge, of, throwError } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';
import {
  TagCommon,
  TagCommonInternalError,
  TagInfo,
  TagPost400Response,
  TagTagIdPut404Response,
} from '../../../typings/generated/tag';
import { getPrivilegeRequest } from '../tag-exports';
import { TagFormService } from './tag-form.service';

@Component({
  selector: 'tm-tag-form',
  templateUrl: './tag-form.component.html',
  styleUrls: ['./tag-form.component.scss'],
  providers: [TmAsyncValidatorsService, TagFormService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagFormComponent extends TmFormComponent<FormGroup> implements OnInit {
  public createDate: string;
  public changeDate: string;
  public isNew$: Observable<boolean> = this._tagFormService.tagId$.pipe(map((id) => !id));

  /**
   * Stream: Form data
   */
  public data$ = this._tagFormService.tagId$.pipe(
    switchMap((id) => (id ? this.service.getById(id) : EMPTY)),
    filter((data) => (!data ? (this.close(), false) : true)),
    takeUntil(this._destroyed$)
  );

  private _modelIsEditable$: Observable<boolean> = this.data$.pipe(map((data) => this.service.isModelEditable(data)));

  private _canEditTag$: Observable<boolean> = this._privileges.can(getPrivilegeRequest('edit')).pipe(shareReplay(1));

  constructor(
    public service: TmTagApiService,
    private _sidebar: TmSidebarService,
    private _notify: IwNotificationsService,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    private _t: TranslateService,
    private _privileges: TmPrivilegesService,
    public contactTypesService: TmBookwormContactTypeService,
    private _tagFormService: TagFormService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = this._tagFormService.createTagFormGroup();

    this.data$
      .pipe(
        switchMap((data) => combineLatest(of(data), this._modelIsEditable$, this._canEditTag$).pipe(take(1))),
        takeUntil(this._destroyed$)
      )
      .subscribe(([data, modelIsEditable, canEditTag]) => {
        this.form.reset();
        this.form.patchValue(data);
        if (data.CREATE_DATE) {
          this.createDate = data.CREATE_DATE;
        }
        if (data.CHANGE_DATE) {
          this.changeDate = data.CHANGE_DATE;
        }

        this._tagFormService.handleFormDisableState(this.form, modelIsEditable, canEditTag);
      });

    merge(this.close$, this.submit$)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this._closeSidebar());

    this._watchIsNewToChangeTitle();
    this._sidebar.open('right');
  }
  /**
   * Save form data
   */
  protected _onSubmit() {
    const serverData: TagCommon | TagInfo = this.form.getRawValue();

    return this.service.createOrUpdate(serverData).pipe(
      catchError((response: TagTagIdPut404Response | TagPost400Response | TagCommonInternalError) => {
        this._notify.error(
          this._t.instant('lists-tag.errors.error'),
          this._t.instant('lists-tag.errors.request-failed')
        );
        return throwError(response);
      }),
      tap(() => this.close(true)),
      takeUntil(this._destroyed$)
    );
  }

  private _watchIsNewToChangeTitle() {
    merge(this.isNew$, this._t.onLangChange.pipe(switchMapTo(this.isNew$)))
      .pipe(takeUntil(this._destroyed$))
      .subscribe((isNew) => {
        let title;

        if (isNew) {
          title = this._t.instant('lists-tag.form.title-create-new');
        } else {
          title = this._t.instant('lists-tag.form.title-edit');
        }

        this._sidebar.updateTitle(title);
      });
  }

  /**
   * Close sidebar
   */
  private _closeSidebar() {
    this._router.navigate(['./'], {
      relativeTo: this._activatedRoute.parent,
      queryParamsHandling: 'merge',
    });

    this._sidebar.close('right');
  }
}
