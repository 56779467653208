import { NgModule } from '@angular/core';
import { TmSharedElementsModule } from '@tm-shared/tm-shared-elements.module';
import { TmPolicyRuleService } from './policy-rule.service';
import { TmPolicyService } from './policy.service';

@NgModule({
  imports: [TmSharedElementsModule],
  providers: [
    TmPolicyService,
    TmPolicyRuleService,
    { provide: 'TmPolicyService', useExisting: TmPolicyService },
    { provide: 'TmPolicyRuleService', useExisting: TmPolicyRuleService },
  ],
})
export class TmPolicyElementsModule {}
