import Scopes = TmApi.search.Scopes;

export function getIdStringByScopeString(scope: Scopes): string {
  // eslint-disable-next-line
  switch (scope) {
    case 'status':
      return 'IDENTITY_STATUS_ID';
    default:
      return scope.toUpperCase() + '_ID';
  }
}
