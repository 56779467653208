import { TmCustomElementsService, TmCustomElement } from './custom-elements.service';
import { Type } from '@angular/core';

export const tmCustomElementTagKey = 'tmCustomElementTag';

export function TmElement(tagname: string) {
  return function (elementConstructor: Type<unknown>): void {
    Object.defineProperty(elementConstructor, tmCustomElementTagKey, { value: tagname });
    TmCustomElementsService.setup(elementConstructor as TmCustomElement);
  };
}
