import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

interface AuditSettingsRetentionResponse {
  data: {
    audit_period: string;
  };
}

@Injectable()
export class TmAuditEventRetentionService {
  public static readonly apiSettingUrl = '/api/setting';
  public static readonly apiSettingRetentionPeriodKey = 'audit_period';

  constructor(private http: HttpClient) {}

  public setRetentionLimits(days: number): Observable<unknown> {
    return this.http.put(TmAuditEventRetentionService.apiSettingUrl, {
      [TmAuditEventRetentionService.apiSettingRetentionPeriodKey]: days,
    });
  }

  public getRetentionLimits(): Observable<number> {
    return this.http
      .get<AuditSettingsRetentionResponse>(TmAuditEventRetentionService.apiSettingUrl)
      .pipe(map((response) => +response.data.audit_period));
  }
}
