import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TabComponent } from './search-select.module';
import Scopes = TmApi.search.Scopes;

@Component({
  templateUrl: './search-tab-modal.component.html',
  styleUrls: ['./search-tab-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmSearchTabModalComponent {
  @Input() public selected: TmApi.search.ScopesWithIds = {};
  @Input() public scopes: Scopes[];
  @Input() public components: Map<Scopes, TabComponent>;
  @Input() public modalHeader: string;

  @Output() public changed: EventEmitter<TmApi.search.ScopesWithIds> = new EventEmitter();
  @Output() public exit: EventEmitter<void> = new EventEmitter();

  public getItems(scope: keyof TmApi.search.ScopesWithIds): string {
    const items = this.selected[scope];
    return items ? items.length.toString() : '';
  }

  public acceptChanges(): void {
    this.exit.emit();
    this.changed.emit(this.selected);
  }
  public rejectChanges(): void {
    this.exit.emit();
  }
}
