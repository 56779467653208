import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmConfigWidgetComponent } from './config-widget/config-widget.component';
import { TmConfigWidgetService } from './config-widget/config-widget.service';
import { TmConfigDiffItemDetailsComponent } from './configdiff-item-details';
import { TmConfigDiffItemComponent } from './configdiff-item/configdiff-item.component';
import { TmConfigDiffComponentService } from './configdiff/configdiff-component.service';
import { TmConfigDiffComponent } from './configdiff/configdiff.component';
import { TmIconModule } from '../../@tm-shared/icons/tm-icon.module';

const ru = require('./i18n/config.ru.json');
const en = require('./i18n/config.en.json');

@NgModule({
  imports: [CommonModule, IwSharedModule, TmIconModule, TranslateModule],
  exports: [
    TmConfigWidgetComponent,
    TmConfigDiffComponent,
    TmConfigDiffItemComponent,
    TmConfigDiffItemDetailsComponent,
  ],
  declarations: [
    TmConfigWidgetComponent,
    TmConfigDiffComponent,
    TmConfigDiffItemComponent,
    TmConfigDiffItemDetailsComponent,
  ],
  entryComponents: [TmConfigWidgetComponent, TmConfigDiffComponent],
  providers: [
    { provide: 'TmConfigWidgetService', useClass: TmConfigWidgetService },
    { provide: 'TmConfigDiffComponentService', useClass: TmConfigDiffComponentService },
  ],
})
export class TmConfigElementsModule {
  constructor(@Inject(TranslateService) private translate: TranslateService) {
    this.translate.setTranslation('ru', ru, true);
    this.translate.setTranslation('en', en, true);
  }
}
