import { TranslateService } from '@ngx-translate/core';
import { AuditExtendGridData } from '../audit-extend.service';
import { AuditEvent } from '../audit.model';
import {
  VisibleAttributesOption,
  getAuditExtendRowData,
  getAuditAttributesFrom,
  transformBooleanYesNo,
  getDefaultColumns,
  transformLanguage,
} from './audit-common';

const auditTextObjectVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'DISPLAY_NAME', i18nKey: 'audit.entries.category.displayName' },
  { path: 'TERM_WEIGHT', i18nKey: 'audit.entries.category.termWeight' },
  { path: 'TERM_LANGUAGE', i18nKey: 'audit.entries.category.termLanguage', transform: transformLanguage },
  { path: 'TERM_MORPHOLOGY', i18nKey: 'audit.entries.category.termMorphology', transform: transformBooleanYesNo },
  {
    path: 'TERM_CASE_SENSITIVE',
    i18nKey: 'audit.entries.category.termCaseSensitive',
    transform: transformBooleanYesNo,
  },

  { path: 'NOTE', i18nKey: 'audit.entries.category.note' },
  { path: 'parent.DISPLAY_NAME', i18nKey: 'audit.entries.category.parentCategory' },
  { path: 'FP_BIN_VALUE_THRESHOLD', i18nKey: 'audit.entries.category.binThreshold' },
  { path: 'FP_TEXT_VALUE_THRESHOLD', i18nKey: 'audit.entries.category.textThreshold' },
];

export function getCategoryGridData(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  const cols = getDefaultColumns(data);
  const result: AuditExtendGridData[] = [];
  const baseData = getAuditExtendRowData({
    t,
    cols: cols,
    data: getAuditAttributesFrom(data),
    visibleAttributes: auditTextObjectVisibleAttributes,
  });

  if (baseData.length) {
    result.push({
      cols,
      data: baseData,
    });
  }

  return result;
}
