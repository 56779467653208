import { TranslateService } from '@ngx-translate/core';
import { AuditExtendGridData, AuditGridDataColumn } from '../audit-extend.service';
import { AuditEvent, AuditEventOperation } from '../audit.model';
import {
  VisibleAttributesOption,
  getAuditExtendRowData,
  getAuditAttributesFrom,
  TransformOptions,
} from './audit-common';
import { TmAuditBrItemsCellComponent } from '../audit-cell-components/audit-br-items.component';
import { groupBy } from 'lodash';
import { PerimeterDiffContactsData, PerimeterDiffEntriesData } from '../../../typings/generated/audit-perimeter';

const auditObjectVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'DISPLAY_NAME', i18nKey: 'audit.auditPerimeter.attributes.DISPLAY_NAME' },
  { path: 'NOTE', i18nKey: 'audit.auditPerimeter.attributes.NOTE' },
  {
    path: 'contacts',
    i18nKey: 'audit.auditPerimeter.attributes.contact',
    transform: transformContacts,
    component: TmAuditBrItemsCellComponent,
  },
  {
    path: 'entries',
    i18nKey: 'audit.auditPerimeter.attributes.entry',
    transform: transformEntries,
    component: TmAuditBrItemsCellComponent,
  },
];

export function getPerimeterGridData(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  let cols: AuditGridDataColumn[] = [];
  /**
   * Set correct columns
   */
  switch (data.OPERATION) {
    case AuditEventOperation.create:
      cols = [AuditGridDataColumn.new];
      break;
    case AuditEventOperation.delete:
      cols = [AuditGridDataColumn.old];
      break;
    case AuditEventOperation.update:
    default:
      cols = [AuditGridDataColumn.old, AuditGridDataColumn.new];
      break;
  }

  return [
    {
      cols: cols,
      data: getAuditExtendRowData({
        t,
        cols: cols,
        data: getAuditAttributesFrom(data),
        visibleAttributes: auditObjectVisibleAttributes,
      }),
    },
  ];
}

export function transformContacts(
  data: PerimeterDiffContactsData | null | undefined,
  options: TransformOptions
): string[] {
  if (data === null || data?.[0] === null) {
    return [];
  }

  const result: string[] = [];
  const contacts = groupBy(data, 'CONTACT_TYPE');

  for (const key in contacts) {
    if (contacts[key]) {
      /**
       * @translate audit.auditPerimeter.attributes.contacts.email
       * @translate audit.auditPerimeter.attributes.contacts.auth
       * @translate audit.auditPerimeter.attributes.contacts.dnshostname
       * @translate audit.auditPerimeter.attributes.contacts.icq
       * @translate audit.auditPerimeter.attributes.contacts.skype
       * @translate audit.auditPerimeter.attributes.contacts.yahoo
       * @translate audit.auditPerimeter.attributes.contacts.phone
       * @translate audit.auditPerimeter.attributes.contacts.mobile
       * @translate audit.auditPerimeter.attributes.contacts.ip
       * @translate audit.auditPerimeter.attributes.contacts.sid
       * @translate audit.auditPerimeter.attributes.contacts.webaccount
       * @translate audit.auditPerimeter.attributes.contacts.url
       * @translate audit.auditPerimeter.attributes.contacts.domain
       * @translate audit.auditPerimeter.attributes.contacts.lotus
       */
      let value = options.t.instant(`audit.auditPerimeter.attributes.contacts.${key}`) + ' = ';
      value += contacts[key]
        ?.map((v) => {
          return v?.VALUE;
        })
        .join(', ');
      result.push(value);
    }
  }
  return result;
}

export function transformEntries(
  data: PerimeterDiffEntriesData | null | undefined,
  options: TransformOptions
): string[] {
  if (data === null) {
    return [];
  }

  const result: string[] = [];
  const entries = groupBy(data, 'ENTRY_TYPE');

  for (const key in entries) {
    if (entries[key]) {
      /**
       * @translate audit.auditPerimeter.attributes.entries.group
       * @translate audit.auditPerimeter.attributes.entries.person
       * @translate audit.auditPerimeter.attributes.entries.web_type
       */
      let value = options.t.instant(`audit.auditPerimeter.attributes.entries.${key}`) + ' = ';
      value += entries[key]
        ?.map((v) => {
          return v?.entry?.DISPLAY_NAME;
        })
        .join(', ');
      result.push(value);
      if (entries[key][0].USE_EMPLOYEE_CONTACTS_ONLY) {
        result.push(options.t.instant(`audit.auditPerimeter.attributes.use_employee_contacts_only`));
      }
    }
  }
  return result;
}
