import { Routes } from '@angular/router';
import { TagComponent } from './tag-component/tag.component';
import { CREATE_PATH, EDIT_PATH, TAG } from './tag-exports';
import { TagFormComponent } from './tag-form-component/tag-form.component';

export const ROUTES: Routes = [
  {
    path: TAG,
    component: TagComponent,
    children: [
      {
        path: CREATE_PATH,
        component: TagFormComponent,
      },
      {
        path: EDIT_PATH,
        component: TagFormComponent,
        data: {
          usePrivilege: 'lists/tag/show',
        },
      },
    ],
  },
];
