import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

const DEFAULT_HEART_BEAT = 15;

@Injectable()
export class TmTrackerService {
  private _tracker: any;
  private _previousRoute: { [name: string]: string };
  private _routerSubscription: Subscription;

  constructor() {
    // @ts-ignore
    if (window['Piwik']) {
      // @ts-ignore
      this._tracker = window['Piwik'].getAsyncTracker();
      this.setHeartBeatTimer(DEFAULT_HEART_BEAT);
    }
  }

  public startTrack(router: Router, activatedRoute: ActivatedRoute): void {
    if (!this._tracker) {
      return;
    }

    if (this._routerSubscription) {
      this._routerSubscription.unsubscribe();
    }

    this._routerSubscription = router.events
      .pipe(filter((val) => val instanceof NavigationEnd))
      .subscribe(() => this.trackNavigation(activatedRoute));
  }

  public trackEvent(action: string, { category, label, value }: TrackingEventParams): void {
    this._tracker.trackEvent(category, action, label, value);
  }

  public setHeartBeatTimer(value: number): void {
    this._tracker.enableHeartBeatTimer(value);
  }

  public trackNavigation(activatedRoute: ActivatedRoute) {
    let currentRoute = activatedRoute.root;

    while (currentRoute.children[0]) {
      currentRoute = currentRoute.children[0];
    }

    if (this._previousRoute) {
      this._tracker.setReferrerUrl(this._previousRoute['url']);
    }

    this._tracker.setCustomUrl(window.location.href);
    this._tracker.trackPageView(currentRoute.snapshot.data['name']);

    this._previousRoute = {
      name: currentRoute.snapshot.data['name'],
      url: window.location.href,
    };
  }
}

export type TrackingEventParams = {
  category: string;
  label: string;
  value: number;
};
