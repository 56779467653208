import { TmPluginAccessUserStatus, TmPluginAccessUserType } from '../access-exports';
import { RoleModel } from '../role/role.model';
import { ScopeModel } from '../scope/scope.model';
import { Privilege_settings_access_user } from '../../../typings/generated/privileges';

export const MODEL_ID_KEY = 'USER_ID';

export const MODULE_KEY = 'settings:access:user';

export function getPrivilegeRequest(key: NonNullable<Privilege_settings_access_user['action']>) {
  return {
    type: MODULE_KEY as 'settings:access:user',
    action: key,
  };
}

export class UserModel implements TmApi.user.CollectionItem {
  public CHANGE_DATE = '';
  public CREATE_DATE = '';
  public DISPLAY_NAME = '';
  public EDITABLE = 1;
  public EMAIL?: string;
  public HIDE_OBJECT_CONTENT = 0;
  public IS_SYSTEM = 0;
  public LANGUAGE = '';
  public LOGON_DATE = '';
  public NOTE?: string = '';
  public PASSWORD?: string;
  public PASSWORD_CONFIRMATION?: string;
  public PROVIDER = '';
  public RECEIVE_NOTIFICATION = 0;
  public STATUS: TmApi.user.Status = TmPluginAccessUserStatus.Active as number;
  public TIMEZONE = '';
  public USERNAME = '';
  public USER_ID = 0;
  public USER_TYPE: TmApi.user.Type = TmPluginAccessUserType.Other as number;
  public policyEntries: any[] = [];
  public privileges: any[] = [];
  public roles: Partial<RoleModel>[] = [];
  public visibilityareas: Partial<ScopeModel>[] = [];
  public notification_recipient: TmPluginNotifications.Model[] = [];
}

export interface ChangePasswordValue {
  userID: string;
  data: {
    PASSWORD: string;
    PASSWORD_CONFIRMATION: string;
  };
}
