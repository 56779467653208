<iw-form [formGroup]="formGroup" *ngIf="formGroup">
  <iw-form-row>
    <label iwFormRowLabel translate>@tm-shared.cronjob-select.selectPresetTitle</label>
    <iw-select
      formControlName="type"
      [clearable]="false"
      [multiple]="false"
      class="presetTypeInput"
      data-qa="presetTypeSelector"
      iwFormRowContent
      placeholder="{{ '@tm-shared.cronjob-select.selectPresetPlaceholder' | translate }}"
      [searchable]="false"
    >
      <!-- @translate @tm-shared.cronjob-select.selectPresetOptions.minutes -->
      <!-- @translate @tm-shared.cronjob-select.selectPresetOptions.hours -->
      <!-- @translate @tm-shared.cronjob-select.selectPresetOptions.daily -->
      <!-- @translate @tm-shared.cronjob-select.selectPresetOptions.weekdays -->
      <iw-select-option *ngFor="let presetKey of presetOptions" [value]="presetKey">{{
        '@tm-shared.cronjob-select.selectPresetOptions.' + presetKey | translate
      }}</iw-select-option>
    </iw-select>
  </iw-form-row>
  <ng-container [ngSwitch]="formGroup.value.type">
    <iw-form-row *ngSwitchCase="presetKeys.minutes">
      <label iwFormRowLabel translate>@tm-shared.cronjob-select.minutesTitle</label>
      <iw-input-container class="minutesInputContainer" iwFormRowContent>
        <input type="number" min="1" max="60" data-qa="minutes" formControlName="minutes" iwInput />
        <!--
          @translate @tm-shared.cronjob-select.minutes.validation.max
          @translate @tm-shared.cronjob-select.minutes.validation.min
          @translate @tm-shared.cronjob-select.minutes.validation.required
        -->
        <div iwErrorHints *ngFor="let error of getErrors('minutes')">
          {{ '@tm-shared.cronjob-select.minutes.validation.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>
    <iw-form-row *ngSwitchCase="presetKeys.hours">
      <label iwFormRowLabel translate>@tm-shared.cronjob-select.hoursTitle</label>
      <iw-input-container class="hoursInputContainer" iwFormRowContent>
        <input type="number" min="1" max="24" data-qa="hours" formControlName="hours" iwInput />
        <!--
          @translate @tm-shared.cronjob-select.hours.validation.max
          @translate @tm-shared.cronjob-select.hours.validation.min
          @translate @tm-shared.cronjob-select.hours.validation.required
        -->
        <div iwErrorHints *ngFor="let error of getErrors('hours')">
          {{ '@tm-shared.cronjob-select.hours.validation.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>
    <iw-form-row *ngSwitchCase="presetKeys.daily">
      <label iwFormRowLabel translate>@tm-shared.cronjob-select.timeTitle</label>
      <iw-input-container class="timeInputContainer" iwFormRowContent>
        <input type="time" data-qa="time" formControlName="time" iwInput />
      </iw-input-container>
    </iw-form-row>
    <iw-form-row class="weekdaysPreset" *ngSwitchCase="presetKeys.weekdays">
      <label iwFormRowLabel translate>@tm-shared.cronjob-select.weeklyTitle</label>
      <div class="cronjob-row" iwFormRowContent>
        <iw-input-container class="timeInput">
          <input type="time" data-qa="time" formControlName="time" iwInput />
        </iw-input-container>
        <iw-select
          formControlName="weekdays"
          [multiple]="true"
          class="dayOfWeekSelector"
          data-qa="weekdays"
          iwFormRowContent
          placeholder="{{ '@tm-shared.cronjob-select.selectDayOfWeekPlaceholder' | translate }}"
          [searchable]="false"
        >
          <iw-select-option *ngFor="let weekday of weekdayOptions" [value]="weekday.value">{{
            weekday.label | titlecase
          }}</iw-select-option>
        </iw-select>
      </div>
    </iw-form-row>
  </ng-container>
</iw-form>
