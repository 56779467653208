import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router, RouterLinkWithHref } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IActionMapping, ITreeOptions, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { Subject, merge } from 'rxjs';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { take, takeUntil } from 'rxjs/operators';
import { TmContextMenuItem } from '../context-menu';
import { LazyTreeService } from './lazy-tree.service';
import { ACTIONS, TmTreeDropEvent, TmTreeNodeBuffer, TmTreeNodeData } from './tm-tree.model';

export interface TreeItemLinkParams extends Partial<Omit<RouterLinkWithHref, 'routerLink'>> {
  routerLinkGetter?(nodeId: string): string[];
}

export const ASYNC_ROOT = 'asyncRoot';

@Component({
  selector: 'tm-tree',
  templateUrl: './tm-tree.component.html',
  styleUrls: ['./tm-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LazyTreeService],
})
export class TmTreeComponent implements OnInit, OnDestroy {
  @Input() public set stateKey(key: string) {
    this._stateStoreKey = key;
  }

  // Read from state
  public get state(): any {
    if (!this._stateStoreKey) {
      return;
    }

    return localStorage[this._stateStoreName] && JSON.parse(localStorage[this._stateStoreName]);
  }

  // Write to state
  public set state(state: any) {
    if (!this._stateStoreKey) {
      return;
    }

    localStorage[this._stateStoreName] = JSON.stringify(state);
  }

  public get treeModel(): TreeModel {
    return this.tree.treeModel;
  }

  private get _stateStoreName(): string {
    return `tree:${this._router.url.toString()}:${this._stateStoreKey || ''}`;
  }

  @Input() public set data(data: TmTreeNodeData[] | null) {
    this._data = data || [];
  }

  public get treeData(): TmTreeNodeData[] {
    return this._data;
  }

  @Input() public set focusedNodeId(id: string | null) {
    this.focusNodeById(id);
  }

  public get focusedNodeId(): string | null {
    return this._focusedNodeId;
  }

  private static readonly _TIMER_OPEN_DRAG_ENTER: number = 2000;

  /**
   * It's necessary to bind all styles to host's class to prevent css leaks
   */
  @HostBinding('class.tm-tree')
  public readonly tmTreeClass: boolean = true;

  @Input() public overrideNodeContentWrapperTpl: TemplateRef<{ node: TreeNode; index: number }>;

  @Input() public overrideNodeTpl: TemplateRef<{ node: TreeNode; index: number }>;

  /**
   * used for lazy loaded tree only.
   * getChildren function is required
   */
  @Input() public apiService: TmCollectionLoader<any>;
  @ViewChild(TreeComponent, { static: true }) public tree: TreeComponent;

  @ViewChild('treeNodeWrapperTemplate', { static: false }) public node: TreeNode;

  @Input() public options: ITreeOptions;

  /**
   * TODO: fix typing
   */
  public get treeOptions(): any {
    return this.options;
  }

  @Input() public treeItemsWithLinks = true;
  @Input() public treeItemLinkParams: TreeItemLinkParams;

  @Input() public disabled = false;

  @Input() public nodeClass: string;

  @Input() public statusField = 'STATUS';

  @Input() public allowDrag = false;

  @Input() public useCheckbox = false;
  /*
  состояния чекбокса: выбран, не выбран, частично выбран
   */
  @Input() public useTriState = false;

  @Input() public useContextMenu = false;
  @Input() public useVirtualScroll = false;

  @Input() public useStatus = false;

  @Input() public useFilter = false;

  @Input() public contextMenuItems: TmContextMenuItem[] = [
    {
      label: this._translate.instant('@tm-shared.copy'),
      onClick: (node: TreeNode) => this._contextCopyOrCut(node, ACTIONS.copy),
    },
    {
      label: this._translate.instant('@tm-shared.paste'),
      onClick: (node: TreeNode) => this._contextPaste(node),
      disabled: () => !this._contextMenuBuffer,
    },
    {
      label: this._translate.instant('@tm-shared.cut'),
      onClick: (node: TreeNode) => this._contextCopyOrCut(node, ACTIONS.cut),
    },
  ];

  /**
   * Fired when node is droped
   */
  @Output() public onDropNode: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Emit event on drop event (only if event contains payload in dataTransfer prop)
   */
  @Output() public onDataDropped = new EventEmitter<TmTreeDropEvent>();

  /**
   * Fired when node is moved
   */
  @Output() public onMoveNode: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Fired when node is activated(clicked)
   */
  @Output() public onActiveNode: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Fired when node is selected
   */
  @Output() public onSelectionChanged: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Fired when node is toggle expanded
   */
  @Output() public onExpandNode: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Fired when tree initialized
   */
  @Output() public onInitialized: EventEmitter<any> = new EventEmitter<void>();

  /**
   * Fired when data is changed
   */
  @Output() public updateData: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Fired when async children loaded
   */
  @Output() public loadNodeChildren: EventEmitter<any> = new EventEmitter<any>();

  private _data: TmTreeNodeData[];

  private _contextMenuBuffer: TmTreeNodeBuffer;

  private _destroy$: Subject<void> = new Subject();

  private _timerOpening: any;

  private _stateStoreKey?: string;

  private _dependencies: { [key: string]: string[] };

  private _selectedIds: { [key: string]: boolean };

  private _disabledIds: { [key: string]: boolean };

  private _focusedNodeId: string | null = null;

  constructor(private _router: Router, private _translate: TranslateService, private _lazyService: LazyTreeService) {}

  /**
   * method, which sends request to get children of current Node.
   * Used for lazy trees with apiService
   */
  @Input() public getChildren: (node: TreeNode) => Promise<TmTreeNodeData[]> = () => new Promise(() => {});

  @Input() public getNodeClone: (node: TreeNode) => TreeNode = (node: TreeNode) => ({
    ...node.data,
    id: node.data.id,
    name: node.data.name,
  });

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnInit() {
    if (this.apiService) {
      this._lazyService.apiService = this.apiService;
      this.data = this._lazyService.getFakeRootNode();
    }
    this.options = {
      levelPadding: 26,
      useCheckbox: this.useCheckbox,
      useTriState: this.useTriState,
      allowDrag: this.allowDrag,
      nodeHeight: 40,
      useVirtualScroll: this.useVirtualScroll,
      getChildren: this.getChildren.bind(this),
      getNodeClone: this.getNodeClone.bind(this),
      actionMapping: <IActionMapping>{
        mouse: {
          drop: this._drop.bind(this),
          dragEnter: this._dragEnter.bind(this),
          dragLeave: this._dragLeave.bind(this),
          dblClick: this._dblClick.bind(this),
        },
      },
    };

    merge(this.tree.initialized.pipe(take(1)), this.updateData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.updateTree());
  }

  public getNodeById(id: string | number): TreeNode | null {
    return this.treeModel.getNodeById(id);
  }

  /**
   * Activate node (think of it as clicking on node without DOM envolved)
   */
  public activateById(id: string): boolean {
    const node: TreeNode | null = this.treeModel.getNodeById(id);

    if (node) {
      node.setIsActive(true);
    }

    return !!node;
  }

  /**
   * Add node to other node or root
   */
  public add(data: any, to?: TreeNode): void {
    this._action(data, to, () => {
      if (to) {
        if (to.children) {
          to.data.children.push(data);
        } else {
          to.data.children = [data];
        }
      } else {
        this.tree.treeModel.nodes.push(data);
      }
    });
  }

  /**
   * Checkbox click handler
   */
  public checkboxClicked(node: TreeNode, event: any): void {
    node.mouseAction('checkboxClick', event);
  }

  /**
   * Unselect nodes
   */
  public clearActive(): void {
    this.treeModel.setState(Object.assign({}, this.treeModel.getState(), { activeNodeIds: [] }));
  }

  /**
   * Copy node and action
   */
  public copy(from: TreeNode, to: any): void {
    this._action(from, to.parent, () => {
      this.tree.treeModel.copyNode(from, to);
    });
  }

  public everyNode(fn: (node: TreeNode) => any): void {
    if (this.treeModel.roots) {
      this._forEachNode(this.treeModel.roots, fn);
    }
  }

  public focusNodeById(id: string | null): void {
    // Do nothing if already selected
    if (id === this._focusedNodeId) {
      return;
    }

    this._focusedNodeId = id;

    // Update nothing if tree is not ready
    if (!this.treeModel || !this.treeModel.roots) {
      return;
    }

    if (id !== null) {
      const node = this.treeModel.getNodeById(id);

      if (node) {
        node.focus();
      }
    } else {
      const node = this.treeModel.getFocusedNode();

      if (node) {
        node.blur();
        node.setIsActive(false);
      }
    }
  }

  public getActiveNodeIds(): (string | number)[] {
    const activeNodes: (string | number)[] = [];
    this.everyNode((node) => {
      if (node.isActive) {
        activeNodes.push(node.id);
      }
    });
    return activeNodes;
  }

  /**
   * Get selected nodes
   * @param getParentNodesOnly if parent node is selected or all children of parent node selected
   * -> only parent node id is returned
   */
  public getSelectedIds(ignoreRoot = false, getParentNodesOnly = false): string[] {
    const selected: string[] = [];

    this.everyNode((node) => {
      if (node.isSelected) {
        if ((ignoreRoot && node.isRoot) || node.isPartiallySelected) {
          return;
        }
        const parentNodeHasAllChildrenSelected =
          // у родительского узла выбраны не все элементы
          (node.parent && !node.parent.isAllSelected) ||
          // у родительского выбраны все, но он корневой
          (node.parent && node.parent.isAllSelected && node.parent.isRoot) ||
          !node.parent;

        // выбрать элементы максимально близкие к корню (если у эл-та выбраны все чайлды, то они не возвращаются)
        if ((getParentNodesOnly && parentNodeHasAllChildrenSelected) || !getParentNodesOnly) {
          selected.push(node.id);
        }
      }
    });

    return selected;
  }

  public isNodeDisabled(node: TreeNode, analyseChildren: boolean = false): boolean {
    const children = node.children || [];
    return (
      this.disabled ||
      node.data.isDisabled ||
      (analyseChildren &&
        children.some((child) => {
          return this.isNodeDisabled(child);
        }))
    );
  }

  /**
   * Move node and action
   */
  public move(from: TreeNode, to: any): void {
    this._action(from, to.parent, () => {
      this.tree.treeModel.moveNode(from, to);
    });
  }

  /**
   * Remove node
   */
  public remove(node: string | TreeNode): void {
    if (typeof node === 'string') {
      node = <TreeNode>this.tree.treeModel.getNodeById(node);
    }

    let index = this._data.indexOf(node.data);

    if (index > -1) {
      this._data.splice(index, 1);
    } else {
      const parent = this._searchNodeParent(node.data, this._data);
      index = parent.children.indexOf(node.data);
      parent.children.splice(index, 1);

      if (!parent.children.length) {
        parent.hasChildren = false;
      }
    }
    this.tree.treeModel.update();
  }

  public selectionChanged(e: any) {
    const isSelected: boolean = e.eventName === 'select';
    const node: TreeNode = e.node;
    const data: TmTreeNodeData = node.data;
    let state;

    // Write selected state to data
    data.isSelected = this._selectedIds[data.id] = isSelected;

    if (isSelected) {
      this._selectNode(data);
    } else {
      if (data.isDisabled) {
        this._selectedIds[data.id] = true;
      } else {
        this._deselectNode(data);
      }
    }

    state = Object.assign({}, this.treeModel.getState(), {
      selectedLeafNodeIds: this._selectedIds,
      disabledNodeIds: this._disabledIds,
    });

    this.treeModel.setState(state);
    this.onSelectionChanged.emit(e);
  }

  public selectNodesByIds(ids: (string | number)[]) {
    this._lazyService.loadPathsToNodesAndSelect(ids, this);
  }

  public setNodeSelected(node: TreeNode, selected: boolean) {
    (node.data as TmTreeNodeData).isSelected = selected;
    this.updateTree();
  }

  /**
   * Set filter value
   */
  public setFilter(value: string): void {
    this.tree.treeModel.filterNodes(value);
  }

  /**
   * Update node data
   */
  public updateNode(node: string | TreeNode, data: any): void {
    if (typeof node === 'string') {
      node = <TreeNode>this.tree.treeModel.getNodeById(node);
    }

    node.data = Object.assign(node.data, data);
    this.tree.treeModel.update();
  }

  /**
   * Update status node
   */
  public updateStatusNode(node: string | TreeNode, status: string): void {
    if (typeof node === 'string') {
      node = <TreeNode>this.tree.treeModel.getNodeById(node);
    }

    node.data[this.statusField] = status;
    this.tree.treeModel.update();
  }

  public updateTree(): void {
    let state;
    this._disabledIds = {};
    this._selectedIds = {};
    this._dependencies = {};
    const nodes = this.tree.treeModel.nodes;

    if (nodes) {
      for (let i = 0; i < nodes.length; i++) {
        this._updateNodesState(nodes[i]);
      }
    }

    state = Object.assign({}, this.treeModel.getState(), {
      selectedLeafNodeIds: this._selectedIds,
      disabledNodeIds: this._disabledIds,
    });

    // If we have no state, use initial
    if (!state.focusedNodeId && this.focusedNodeId) {
      state.focusedNodeId = this.focusedNodeId;
    }

    // NOTE: It's slow :(
    this.treeModel.setState(state);
  }

  public onNativeDropEvent(nodeId: string, event: DragEvent): void {
    if (event.dataTransfer) {
      let data: any;

      try {
        data = JSON.parse(event.dataTransfer.getData('application/json'));
      } catch (_e) {
        data = null;
      }

      this.onDataDropped.emit({
        node: this.treeModel.getNodeById(nodeId),
        data: data,
      });

      event.preventDefault();
      return;
    }
  }

  private _selectNode(data?: TmTreeNodeData): void {
    let node;
    if (data && data.dependencies) {
      data.dependencies.forEach((dependency) => {
        this._dependencies[dependency.id] = this._dependencies[dependency.id] || [];
        this._dependencies[dependency.id].push(data.id);
        this._selectedIds[dependency.id] = true;
        node = this.treeModel.getNodeById(dependency.id);
        if (node) {
          this._disabledIds[dependency.id] = node.data.isDisabled = true;
          if (node.data.dependencies) {
            this._selectNode(node.data);
          }
        }
      });
    }
  }

  private _deselectNode(data: TmTreeNodeData) {
    this._deselectNodeWithDependencies(data);
    this._deselectRelatedDependencies(data);
  }

  private _deselectNodeWithDependencies(data?: TmTreeNodeData): void {
    if (data && data.dependencies) {
      data.dependencies.forEach((dependency) => {
        const id = dependency.id;
        this._dependencies[id] = (this._dependencies[id] || []).filter((i) => i !== data.id);
        if (this._dependencies[id].length) {
          this._selectedIds[id] = true;
        } else {
          this.treeModel.getNodeById(id).data.isDisabled = false;
          this._disabledIds[id] = false;
          this._selectedIds[id] = false;
        }
      });
    }
  }

  private _deselectRelatedDependencies(data: TmTreeNodeData): void {
    let filteredDependency;
    if (data.dependencies) {
      filteredDependency = data.dependencies.filter((dependency) => !this._dependencies[dependency.id].length);
      filteredDependency.forEach((dependency) => {
        const dependencyNode = this.treeModel.getNodeById(dependency.id);
        if (dependencyNode.data) {
          this._deselectNode(dependencyNode.data);
        }
      });
    }
  }

  /**
   * Wrapper advanced of action node
   */
  private _action(from: any, to: any, action: (...args: any) => any): void {
    if (to && to.hasChildren && !to.isExpanded && !to.children) {
      to.loadNodeChildren().then(() => {
        action();
        this.tree.treeModel.update();
        this.tree.treeModel.getNodeById(from.id).setActiveAndVisible();
      });
    } else {
      action();
      this.tree.treeModel.update();
      this.tree.treeModel.getNodeById(from.id).setActiveAndVisible();
    }
  }

  /**
   * Copy or cut action from context menu
   */
  private _contextCopyOrCut(node: TreeNode, action: string): void {
    this._contextMenuBuffer = { node, action };
  }

  /**
   * Paste action from context menu
   */
  private _contextPaste(targetNode: TreeNode) {
    const { node, action } = this._contextMenuBuffer;

    switch (action) {
      case ACTIONS.copy:
        this.copy(node, { parent: targetNode });
        break;
      case ACTIONS.cut:
        this.move(node, { parent: targetNode });
        break;
    }
  }

  /**
   * Fired when have double click by node
   */
  private _dblClick(_model: TreeModel, node: TreeNode): void {
    node.toggleExpanded();
    node.setActiveAndVisible();
  }

  /**
   * Fired when node drop to target node
   */
  private _drop(tree: TreeModel, node: TreeNode, event: any, { from, to }: { from: TreeNode; to: TreeNode }): void {
    clearTimeout(this._timerOpening);

    if (from === to.parent) {
      return;
    }

    if (this._isChildTarget(from, to.parent)) {
      return;
    }

    this.onDropNode.emit({ tree, node, event, from, to });
  }

  /**
   * Fired when node enter to target node
   */
  private _dragEnter(_model: TreeModel, node: TreeNode): void {
    if (node.hasChildren && !node.isExpanded) {
      clearTimeout(this._timerOpening);
      this._timerOpening = setTimeout(() => node.expand(), TmTreeComponent._TIMER_OPEN_DRAG_ENTER);
    }
  }

  /**
   * Fired when node leave target node
   */
  private _dragLeave(_model: TreeModel, _node: TreeNode, { event }: { event: any }): void {
    if (
      event.fromElement === event.currentTarget ||
      event.fromElement === event.currentTarget.firstElementChild ||
      event.fromElement === event.currentTarget.firstElementChild.firstElementChild
    ) {
      return;
    }

    clearTimeout(this._timerOpening);
  }

  private _forEachNode(nodes: TreeNode[], fn: (node: TreeNode) => any): void {
    nodes.forEach((node) => {
      fn(node);

      if (node.children) {
        this._forEachNode(node.children, fn);
      }
    });
  }

  /**
   * Check target node is child
   */
  private _isChildTarget(current: TreeNode, target: TreeNode): boolean {
    let bool = false;
    if (target === current) {
      bool = true;
    } else if (target.parent) {
      bool = this._isChildTarget(current, target.parent);
    }

    return bool;
  }

  /**
   * Search node`s parent
   */
  private _searchNodeParent(item: any, path: any): any {
    let parent;

    for (let i = 0; i < path.length; i++) {
      if (path[i].children && path[i].children.find((child: any) => child.id === item.id)) {
        parent = path[i];
      } else if (path[i].children) {
        parent = this._searchNodeParent(item, path[i].children);
      }

      if (parent) {
        break;
      }
    }

    return parent;
  }

  private _updateNodesState(node: any) {
    const id = node.id;
    let dependencyNode;
    this._selectedIds[id] = node.isSelected;
    if (node.dependencies) {
      node.dependencies.forEach((dep: any) => {
        const depId = dep.id;
        if (node.isSelected) {
          dependencyNode = this.treeModel.getNodeById(depId);
          if (dependencyNode && dependencyNode.parent && id !== dependencyNode.parent.data.id) {
            this._disabledIds[depId] = dependencyNode.data.isDisabled = dep.isDisabled;
            this._dependencies[depId] = this._dependencies[depId] || [];
            this._dependencies[depId].push(id);
          }
        }
      });
    }

    if (node.children) {
      node.children.forEach((dep: any) => {
        this._updateNodesState(dep);
      });
    }
  }
}
