import { NgModule } from '@angular/core';
import { IwSharedModule } from '@platform/shared';
import { TmDeleteConfirmDirective } from './delete-confirm.directive';

@NgModule({
  imports: [IwSharedModule],
  exports: [TmDeleteConfirmDirective],
  entryComponents: [],
  declarations: [TmDeleteConfirmDirective],
})
export class TmDeleteConfirmModule {}
