import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmUserApiService } from '@tm-shared/api-services';
import { ModalConfirmComponent } from '@tm-shared/modals';
import { take, takeUntil } from 'rxjs/operators';
import { ModalDialogRef } from '@platform/shared';

@Component({
  selector: 'tm-user-delete-modal',
  templateUrl: './user-delete-modal.component.html',
  styleUrls: ['user-delete-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDeleteModalComponent extends ModalConfirmComponent implements OnInit {
  @Input() public users: (string | number)[];
  public usersData: TmApi.user.CollectionItem[];
  public usersWithNotifications: TmApi.user.CollectionItem[];

  constructor(
    _t: TranslateService,
    _dialog: ModalDialogRef<unknown>,
    private _userApi: TmUserApiService,
    private cd: ChangeDetectorRef
  ) {
    super(_t, _dialog);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._getUsersInNotifications();
  }

  private _getUsersInNotifications() {
    this._userApi
      .checkIfUserInNotifications(this.users)
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe(([users, usersWithNofifications]) => {
        this.usersData = users;
        this.usersWithNotifications = usersWithNofifications;
        this.cd.markForCheck();
      });
  }
}
