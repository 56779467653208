import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule, IwNotificationsService } from '@platform/shared';
import { TmSharedModule } from '@tm-shared';
import { TmChannelService } from '@tm-shared/channel';
import { TmDeleteConfirmModule } from '@tm-shared/delete-confirm/delete-confirm.module';
import { TmDropzoneModule } from '@tm-shared/dragndrop/dropzone.module';
import { CanDeactivateTmForm } from '@tm-shared/form';
import { TmFormHelpersModule } from '@tm-shared/form-helpers/form-helpers.module';
import { TmGridModule } from '@tm-shared/grid';
import { TmModals } from '@tm-shared/modals';
import { TmOverlayModule } from '@tm-shared/overlay';
import { TmSpinnerModule } from '@tm-shared/spinner';
import { TmStructureModule } from '@tm-shared/structure';
import { TmTechLoaderComponentService } from '@tm-shared/tech-loader/tech-loader-component.service';
import { TmTechLoaderStatus } from '@tm-shared/tech-loader/tech-loader.model';
import { TmToolbarModule } from '@tm-shared/toolbar';
import { TmTreeModule } from '@tm-shared/tree';
import { AgGridModule } from 'ag-grid-angular';
import { FileCheckErrors } from 'plugins/tech-classifier/tech-classifier-file-check/tech-classifier-file-check.service';
import { ClassifierType } from 'plugins/tech-classifier/tech-classifier-providers';
import { TmTechClassifierModule } from 'plugins/tech-classifier/tech-classifier.module';
import { filter } from 'rxjs/operators';
import { ErrorType, ValidationErrorType, ValidationFields } from 'typings/generated/errors';
import { TrainingErrorCode, WsImage, WsImageError } from 'typings/generated/technology-imageClassifier';
import { getTechLoaderGroupKey } from '../tech-classifier/tech-classifier-helpers';
import { TmGraphicCategoryEditComponent } from './graphic-category-edit/graphic-category-edit.component';
import { TmGraphicCategoryComponent } from './graphic-category/graphic-category.component';
import { TmGraphicDocumentCheckService } from './graphic-document-check.service';
import { TmGraphicDocumentCheckComponent } from './graphic-document-check/graphic-document-check.component';
import { TmGraphicDocumentEditComponent } from './graphic-document-edit/graphic-document-edit.component';
import { TmGraphicPageComponent } from './graphic-page.component';
import { TmGraphicTrainingService } from './graphic-training.service';
import { routes } from './graphic.routes';
import en from './i18n/tech-graphic.en.json';
import ru from './i18n/tech-graphic.ru.json';

export const LANG_DATA = { en, ru };

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    TmTreeModule,
    TmOverlayModule,
    TmSpinnerModule,
    IwSharedModule,
    TmToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    TmDeleteConfirmModule,
    TmGridModule,
    TmSharedModule,
    TmModals,
    TmDropzoneModule,
    TmGridModule,
    AgGridModule,
    TmStructureModule,
    TmTechClassifierModule,
    TmFormHelpersModule,
  ],
  declarations: [
    TmGraphicPageComponent,
    TmGraphicCategoryComponent,
    TmGraphicCategoryEditComponent,
    TmGraphicDocumentEditComponent,
    TmGraphicDocumentCheckComponent,
  ],
  providers: [CanDeactivateTmForm, TmGraphicDocumentCheckService, TmGraphicTrainingService],
  entryComponents: [TmGraphicCategoryEditComponent, TmGraphicDocumentEditComponent, TmGraphicDocumentCheckComponent],
})
export class TmGraphicModule {
  protected groupKey = getTechLoaderGroupKey(ClassifierType.graphicImageClassifier);

  constructor(
    private _t: TranslateService,
    private _training: TmGraphicTrainingService,
    private _fileCheck: TmGraphicDocumentCheckService,
    private _techLoader: TmTechLoaderComponentService,
    private _channels: TmChannelService,
    private _notify: IwNotificationsService
  ) {
    this._t.setTranslation('ru', ru, true);
    this._t.setTranslation('en', en, true);
    this._setupTechLoader();
    this._setupGlobalNotifications();
  }

  protected _setupGlobalNotifications(): void {
    /**
     * Report each training error
     */
    this._training.error$.subscribe((error) => {
      this.notifyTrainingError(error);
    });

    /**
     * Report training success
     */
    this._training.success$.pipe(filter((res) => !res.meta.is_silent)).subscribe(() => {
      this._notify.success(this._t.instant('tech-graphic.training.completed'), '');
    });

    /**
     * Report file check errors
     */
    this._fileCheck.errors.subscribe((errKey) => this.notifyFileCheckError(errKey));
  }

  private notifyFileCheckError(error: FileCheckErrors): void {
    if (error === FileCheckErrors.configurationNotFound) {
      this._notify.error(
        this._t.instant('tech-graphic.checkImage.checkError'),
        this._t.instant('tech-graphic.checkImage.errors.configurationNotFound')
      );
    }
  }

  protected notifyTrainingError(error: TrainingErrorCode | 'unknown'): void {
    let errorText: string;

    switch (error) {
      case TrainingErrorCode.compileError:
        errorText = this._t.instant('tech-graphic.training.compileError');
        break;
      case TrainingErrorCode.downloadError:
        errorText = this._t.instant('tech-graphic.training.downloadError');
        break;
      case TrainingErrorCode.failedLoadTech:
        errorText = this._t.instant('tech-graphic.training.failedLoadTech');
        break;
      case TrainingErrorCode.internalError:
        errorText = this._t.instant('tech-graphic.training.internalError');
        break;
      case TrainingErrorCode.tokenAbsent:
        errorText = this._t.instant('tech-graphic.training.tokenAbsent');
        break;
      case TrainingErrorCode.invalidXml:
        errorText = this._t.instant('tech-graphic.training.invalidXml');
        break;
      default:
        errorText = this._t.instant('tech-graphic.training.unknownError');
        break;
    }

    this._notify.error(this._t.instant('tech-graphic.training.failed'), errorText);
  }

  private _setupTechLoader(): void {
    /**
     * Create group in tech loader
     */
    this._techLoader.initGroup({
      key: this.groupKey,
      // @translate tech-graphic.groupTitle
      i18nKey: 'tech-graphic.groupTitle',
      items: [],
    });
    /**
     * Subscribe on socket update
     */
    this._channels.getUserChannel<WsImage>('sample_compiler').subscribe((data) => {
      switch (data.state) {
        case 'save':
          this._techLoader.updateItemInGroup(this.groupKey, {
            key: data.meta.key,
            status: TmTechLoaderStatus.success,
          });
          break;
        case 'error':
          this._techLoader.updateItemInGroup(this.groupKey, {
            key: data.meta.key,
            status: TmTechLoaderStatus.error,
            errorPopover: this.getLocalizedSampleCompilerError(data),
            errorPopoverUseHtml: true,
          });
          break;
        default:
          break;
      }
    });
  }

  private getLocalizedSampleCompilerError(msg: WsImageError) {
    if (msg.error === ErrorType.validation) {
      return this.localizeValidationError(msg);
    }

    if (msg.state === ErrorType.error) {
      return this.localizeError(msg);
    }

    return '';
  }

  private localizeValidationError(msg: WsImageError): string {
    const fields: ValidationFields = msg.meta.validationErrors || {};

    if (!Object.keys(fields).length) {
      return '';
    }

    const uniqueViolations: ValidationErrorType[] = [];

    Object.keys(fields).forEach((fieldName) => {
      Object.keys(fields[fieldName]).forEach((violation: ValidationErrorType) => {
        if (!uniqueViolations.includes(violation)) {
          uniqueViolations.push(violation);
        }
      });
    });

    return uniqueViolations
      .map((violation) => {
        /**
         * See WsDocumentValidationErrorKey enum for all possible values:
         * @translate tech-graphic.sampleCompiler.validationErrors.unique
         */
        return this._t.instant(`tech-graphic.sampleCompiler.validationErrors.${violation}`);
      })
      .join('<br/>');
  }

  private localizeError(msg: WsImageError) {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (msg.error) {
      case 'duplicate':
        return this._t.instant(`tech-graphic.sampleCompiler.errors.duplicate_content`, {
          DISPLAY_NAME: msg?.meta?.models?.[0].DISPLAY_NAME,
        }) as string;
    }
  }
}
