<tm-spinner *ngIf="!usersData; else modalContent"></tm-spinner>

<ng-template #modalContent>
  <iw-modal-header>{{ title }}</iw-modal-header>
  <span>{{ 'settings-access.checkDelete' | translate }}</span>

  <span class="user" *ngFor="let user of usersData; last as isLast">
    {{ user.DISPLAY_NAME }} <span *ngIf="!isLast">,</span>
  </span>
  ?

  <span *ngIf="usersWithNotifications.length">{{
    'settings-access.user.userDeletedFrom' | pluralize: usersData.length | translate
  }}</span>

  <p *ngFor="let user of usersWithNotifications">
    {{ user.DISPLAY_NAME }}<br />
    <strong>{{ 'settings-access.privileges.settings_notification' | translate }}<br /></strong>
    <a
      class="link"
      *ngFor="let notifRecipient of user.notification_recipient"
      [routerLink]="['/notification/edit/notification/', notifRecipient.notification.NOTIFICATION_ID]"
      >{{ notifRecipient.notification.DISPLAY_NAME }}</a
    >
  </p>

  <iw-modal-footer>
    <button iw-button color="success" data-qa="userDeleteModalYES" (click)="decision.emit(true)">{{ yes }}</button>
    <button iw-button data-qa="userDeleteModalNO" (click)="decision.emit(false)">{{ no }}</button>
  </iw-modal-footer>
</ng-template>
