import { NgIfContext } from '@angular/common';
import {
  Directive,
  EmbeddedViewRef,
  Inject,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
  forwardRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { TmPrivilegesService } from './tm-privileges.service';

@Directive({
  selector: '[tmCan]',
})
export class TmPrivilegeCanDirective implements OnDestroy {
  private _destroy$: Subject<void> = new Subject();

  private _thenTemplateRef: TemplateRef<NgIfContext> | null = null;

  private _thenViewRef: EmbeddedViewRef<NgIfContext> | null = null;

  constructor(
    @Inject(forwardRef(() => TmPrivilegesService)) private _privilegeService: TmPrivilegesService,
    private _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<NgIfContext>
  ) {
    this._thenTemplateRef = templateRef;
  }

  @Input()
  public set tmCan(condition: any) {
    this._destroy$.next();
    this._privilegeService
      .can(condition)
      .pipe(takeUntil(this._destroy$), distinctUntilChanged())
      .subscribe((ok) => this._updateView(ok));
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _updateView(show: boolean) {
    if (show && !this._thenViewRef) {
      if (this._thenTemplateRef) {
        this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef);
      }
    } else if (!show && this._thenViewRef) {
      this._viewContainer.clear();
    }
  }
}
