import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  forwardRef,
} from '@angular/core';
import { TmTabComponent } from '../tab/tab.component';

@Component({
  selector: 'tm-tabset',
  templateUrl: './tab-set.component.html',
  styleUrls: ['./tab-set.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmTabsetComponent implements AfterContentInit {
  @ContentChildren(forwardRef(() => TmTabComponent))
  public tabs: QueryList<TmTabComponent>;

  @Input() public activeIndex = 0;
  @Output() public activeIndexChange = new EventEmitter<number>();
  private previousActive: number;

  constructor(private _cd: ChangeDetectorRef) {}

  public ngAfterContentInit() {
    if (this.tabs.length) {
      this.selectTab(this.activeIndex);
    }
  }

  public markForCheck() {
    this._cd.markForCheck();
  }

  public selectTab(i: number): void {
    const tabs = this.tabs.toArray();
    if (tabs[i].disabled) {
      return;
    }
    this.previousActive = this.activeIndex;
    this.activeIndex = i;
    this.activeIndexChange.emit(this.activeIndex);
    tabs[this.previousActive].calcActiveState();
    tabs[this.activeIndex].calcActiveState();
  }
}
