<tm-content title="{{ 'settings-access.scope.title' | translate }}">
  <tm-toolbar class="toolbar">
    <button
      iw-icon-button
      type="button"
      [disabled]="!(canCreate$ | async)"
      data-qa="scope-create-button"
      name="{{ 'settings-access.scope.toolbar.create' | translate }}'"
      (click)="showDialogCreate()"
    >
      <tm-icon svgIcon="create"></tm-icon>
    </button>
    <button
      iw-icon-button
      type="button"
      data-qa="scope-delete-button"
      [disabled]="!(canRemove$ | async)"
      name="{{ 'settings-access.scope.toolbar.remove' | translate }}"
      (click)="showDialogRemove()"
    >
      <tm-icon svgIcon="delete"></tm-icon>
    </button>
  </tm-toolbar>

  <tm-grid #grid class="grid" [service]="service" [gridOptions]="gridOptions"> </tm-grid>
</tm-content>

<tm-sidebar
  [disabled]="!router.url.includes(CREATE_PATH) && !router.url.includes(EDIT_PATH)"
  class="sidebar"
  #sidebar
  direction="right"
  [isCollapsed]="true"
>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</tm-sidebar>
