<ng-container *ngIf="data">
  <tm-description data-qa="ldapDescription" class="description">
    <tm-d-group>
      <tm-d-row>
        <tm-d-key>{{ 'settings-ldap.serverDescription.serverType' | translate }}</tm-d-key>
        <!--
        @translate settings-ldap.serverDescription.serverTypeOptions.1
        @translate settings-ldap.serverDescription.serverTypeOptions.2
        @translate settings-ldap.serverDescription.serverTypeOptions.3
       -->
        <tm-d-value>{{
          'settings-ldap.serverDescription.serverTypeOptions.' + data!.server_type! | translate
        }}</tm-d-value>
      </tm-d-row>
      <tm-d-row>
        <tm-d-key>{{ 'settings-ldap.serverDescription.syncType' | translate }}</tm-d-key>
        <!--
        @translate settings-ldap.serverDescription.syncTypeOptions.manual
        @translate settings-ldap.serverDescription.syncTypeOptions.auto
       -->
        <tm-d-value>{{
          'settings-ldap.serverDescription.syncTypeOptions.' + (data!.enabled ? 'auto' : 'manual') | translate
        }}</tm-d-value>
      </tm-d-row>
      <tm-d-row *ngIf="data!.enabled">
        <tm-d-key>{{ 'settings-ldap.serverDescription.syncJobType' | translate }}</tm-d-key>
        <!--
        @translate settings-ldap.serverDescription.syncJobTypeOptions.daily
        @translate settings-ldap.serverDescription.syncJobTypeOptions.hours
        @translate settings-ldap.serverDescription.syncJobTypeOptions.minutes
        @translate settings-ldap.serverDescription.syncJobTypeOptions.weekdays
        @translate settings-ldap.serverDescription.syncJobTypeOptions.unknown
       -->
        <tm-d-value>{{
          'settings-ldap.serverDescription.syncJobTypeOptions.' + (data!.job! | tmCronJobPresetName) | translate
        }}</tm-d-value>
      </tm-d-row>
      <tm-d-row *ngIf="data!.enabled">
        <tm-d-key>{{ 'settings-ldap.serverDescription.syncJobValue' | translate }}</tm-d-key>
        <tm-d-value>{{ getJobValue() }}</tm-d-value>
      </tm-d-row>
    </tm-d-group>

    <tm-d-group title="{{ 'settings-ldap.serverDescription.connectionSettingsTitle' | translate }}">
      <tm-d-row>
        <tm-d-key>{{ 'settings-ldap.serverDescription.serverAddress' | translate }}</tm-d-key>
        <tm-d-value>{{ data!.address }}</tm-d-value>
      </tm-d-row>
      <tm-d-row>
        <tm-d-key>{{ 'settings-ldap.serverDescription.kerberosState' | translate }}</tm-d-key>
        <tm-d-value *ngIf="data!.kerberos">{{ 'settings-ldap.serverDescription.applicable' | translate }}</tm-d-value>
        <tm-d-value *ngIf="!data!.kerberos">{{
          'settings-ldap.serverDescription.inapplicable' | translate
        }}</tm-d-value>
      </tm-d-row>
      <tm-d-row *ngIf="data!.global_port">
        <tm-d-key>{{ 'settings-ldap.serverDescription.globalPort' | translate }}</tm-d-key>
        <tm-d-value>{{ data!.global_port }}</tm-d-value>
      </tm-d-row>
      <tm-d-row *ngIf="data!.dom_port">
        <tm-d-key>{{ 'settings-ldap.serverDescription.domainPort' | translate }}</tm-d-key>
        <tm-d-value>{{ data!.dom_port }}</tm-d-value>
      </tm-d-row>
      <tm-d-row *ngIf="data!.use_global_catalog">
        <tm-d-key>{{ 'settings-ldap.serverDescription.globalCatalog' | translate }}</tm-d-key>
        <tm-d-value *ngIf="data!.use_global_catalog">{{
          'settings-ldap.serverDescription.applicable' | translate
        }}</tm-d-value>
        <tm-d-value *ngIf="!data!.use_global_catalog">{{
          'settings-ldap.serverDescription.inapplicable' | translate
        }}</tm-d-value>
      </tm-d-row>
      <tm-d-row>
        <tm-d-key>{{ 'settings-ldap.serverDescription.allowAnonymState' | translate }}</tm-d-key>
        <tm-d-value *ngIf="data!.anon">{{ 'settings-ldap.serverDescription.applicable' | translate }}</tm-d-value>
        <tm-d-value *ngIf="!data!.anon">{{ 'settings-ldap.serverDescription.inapplicable' | translate }}</tm-d-value>
      </tm-d-row>
      <tm-d-row>
        <tm-d-key>{{ 'settings-ldap.serverDescription.ldapBaseQuery' | translate }}</tm-d-key>
        <tm-d-value>{{ data!.base }}</tm-d-value>
      </tm-d-row>
      <tm-d-row>
        <tm-d-key>{{ 'settings-ldap.serverDescription.username' | translate }}</tm-d-key>
        <tm-d-value>{{ data!.username }}</tm-d-value>
      </tm-d-row>
    </tm-d-group>
    <tm-d-group title="{{ 'settings-ldap.serverDescription.syncSettingsTitle' | translate }}">
      <tm-d-row *ngIf="syncState !== syncStateEnum.pristine">
        <tm-d-key>{{ 'settings-ldap.serverDescription.lastSyncDate' | translate }}</tm-d-key>
        <tm-d-value *ngIf="syncState === syncStateEnum.success && data!.last_sync_timestamp">{{
          data!.last_sync_timestamp | dateTimeFromIso | luxonDetectLangChange | dateTimeToFormat: 'DD t'
        }}</tm-d-value>
        <tm-d-value *ngIf="syncErrorTimestamp && syncState === syncStateEnum.error">{{
          syncErrorTimestamp | dateTimeFromIso | luxonDetectLangChange | dateTimeToFormat: 'DD t'
        }}</tm-d-value>
      </tm-d-row>
      <tm-d-row>
        <tm-d-key>{{ 'settings-ldap.serverDescription.lastSyncState' | translate }}</tm-d-key>
        <tm-d-value *ngIf="syncState === syncStateEnum.progress">
          {{ 'settings-ldap.serverDescription.lastSyncStateOptions.inProgress' | translate }}
        </tm-d-value>
        <tm-d-value *ngIf="syncState === syncStateEnum.error">{{
          'settings-ldap.serverDescription.lastSyncStateOptions.error' | translate: { error: syncErrorText }
        }}</tm-d-value>
        <tm-d-value *ngIf="syncState === syncStateEnum.success">{{
          'settings-ldap.serverDescription.lastSyncStateOptions.success' | translate
        }}</tm-d-value>
        <tm-d-value *ngIf="syncState === syncStateEnum.pristine">{{
          'settings-ldap.serverDescription.lastSyncStateOptions.pristine' | translate
        }}</tm-d-value>
      </tm-d-row>
      <tm-d-row *ngIf="data!.enabled && data.next_sync_timestamp">
        <tm-d-key>{{ 'settings-ldap.serverDescription.nextSyncDate' | translate }}</tm-d-key>
        <tm-d-value>{{
          data.next_sync_timestamp | dateTimeFromIso | luxonDetectLangChange | dateTimeToFormat: 'DD t'
        }}</tm-d-value>
      </tm-d-row>
    </tm-d-group>
  </tm-description>

  <div class="footer">
    <button data-qa="checkLdapConnectionBtn" iw-button type="button" (click)="checkConnection()">
      {{ 'settings-ldap.serverDescription.checkConnection' | translate }}
    </button>
  </div>
</ng-container>

<tm-overlay *ngIf="!data">
  <tm-spinner></tm-spinner>
</tm-overlay>
