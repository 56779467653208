import { TranslateService } from '@ngx-translate/core';
import { AuditExtendGridData, AuditGridDataColumn } from '../audit-extend.service';
import { AuditEvent, AuditEventOperation } from '../audit.model';
import {
  VisibleAttributesOption,
  getAuditExtendRowData,
  getAuditAttributesFrom,
  TransformOptions,
  transformBooleanYesNo,
} from './audit-common';
import { ObjectExportDiffProperties, UserDecisionState } from '../../../typings/generated/audit-object';
import { TmAuditBrItemsCellComponent } from '../audit-cell-components/audit-br-items.component';

const auditObjectVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'USER_DECISION', i18nKey: 'audit.auditObject.attributes.USER_DECISION', transform: transformUserDecision },
  { path: 'DISPLAY_NAME', i18nKey: 'audit.auditObject.attributes.DISPLAY_NAME' },
  {
    path: 'PARAMS',
    i18nKey: 'audit.auditUser.attributes.PARAMS',
    transform: transformParams,
    component: TmAuditBrItemsCellComponent,
  },
];

export function getEventObjectGridData(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  let cols: AuditGridDataColumn[] = [];
  /**
   * Set correct columns
   */
  switch (data.OPERATION) {
    case AuditEventOperation.removeTag:
      cols = [AuditGridDataColumn.old];
      break;
    case AuditEventOperation.exportObject:
    case AuditEventOperation.addTag:
      cols = [AuditGridDataColumn.new];
      break;
    default:
      cols = [AuditGridDataColumn.old, AuditGridDataColumn.new];
      break;
  }

  return [
    {
      cols: cols,
      data: getAuditExtendRowData({
        t,
        cols: cols,
        data: getAuditAttributesFrom(data),
        visibleAttributes: auditObjectVisibleAttributes,
      }),
    },
  ];
}

export function transformUserDecision(value: UserDecisionState | null | undefined, options: TransformOptions): string {
  if (value === undefined || value === null) {
    return '';
  }
  /**
   * @translate audit.commonAttributes.NOTPROCESSED
   * @translate audit.commonAttributes.NOVIOLATION
   * @translate audit.commonAttributes.VIOLATION
   * @translate audit.commonAttributes.ADDITIONALPROCESSINGNEEDED
   */
  return options.t.instant(`audit.commonAttributes.${value.toUpperCase()}`);
}

export function transformParams(
  data: ObjectExportDiffProperties | null | undefined,
  options: TransformOptions
): string[] {
  if (data === null) {
    return [];
  }

  const result: string[] = [];

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      switch (key) {
        case 'DISPLAY_NAME':
        case 'COMMENT':
          /**
           *  @translate audit.auditObject.attributes.DISPLAY_NAME
           *  @translate audit.auditObject.attributes.COMMENT
           */
          if (data[key]) {
            result.push(options.t.instant(`audit.auditObject.attributes.${key}`) + ' = ' + data[key]);
          }
          break;
        case 'IS_REPORT_ARCHIVE':
        case 'IS_ONE_REPORT':
        case 'LOAD_ATTACHMENT':
        case 'KEEP_HIERARCHY':
        case 'LOAD_EML':
        case 'LOAD_DEBUG_OBJECT':
        case 'SEVERAL_LOAD_REPORT':
        case 'LOAD_SNIPPET':
          /**
           *  @translate audit.auditObject.attributes.IS_REPORT_ARCHIVE
           *  @translate audit.auditObject.attributes.IS_ONE_REPORT
           *  @translate audit.auditObject.attributes.LOAD_ATTACHMENT
           *  @translate audit.auditObject.attributes.KEEP_HIERARCHY
           *  @translate audit.auditObject.attributes.LOAD_EML
           *  @translate audit.auditObject.attributes.LOAD_DEBUG_OBJECT
           *  @translate audit.auditObject.attributes.SEVERAL_LOAD_REPORT
           *  @translate audit.auditObject.attributes.LOAD_SNIPPET
           */
          result.push(
            options.t.instant(`audit.auditObject.attributes.${key}`) +
              ' - ' +
              transformBooleanYesNo(!!data[key], options)
          );
          break;
        case 'TYPE':
          if (data.TYPE) {
            for (let [key, value] of Object.entries(data.TYPE)) {
              /**
               * @translate audit.auditObject.attributes.one_report
               * @translate audit.auditObject.attributes.several_report
               * @translate audit.auditObject.attributes.format.docx
               * @translate audit.auditObject.attributes.format.pdf
               * @translate audit.auditObject.attributes.format.xls
               * @translate audit.auditObject.attributes.format.xlsx
               */
              result.push(
                options.t.instant(`audit.auditObject.attributes.${key}`) +
                  ' = ' +
                  options.t.instant(`audit.auditObject.attributes.format.${value}`)
              );
            }
          }
          break;
        case 'GROUPING':
          result.push(transformGrouping(data.GROUPING, options));
          break;
        default:
          break;
      }
    }
  }
  return result;
}

export function transformGrouping(
  data: ObjectExportDiffProperties['GROUPING'] | null | undefined,
  options: TransformOptions
): string {
  let result = options.t.instant(`audit.auditObject.attributes.GROUPING`) + ' ';

  if (data) {
    result += data
      .map((value: string) => {
        /**
         * @translate audit.auditObject.attributes.grouping.Computer
         * @translate audit.auditObject.attributes.grouping.CaptureDate
         * @translate audit.auditObject.attributes.grouping.Object
         * @translate audit.auditObject.attributes.grouping.ObjectType
         * @translate audit.auditObject.attributes.grouping.Person
         */
        return options.t.instant(`audit.auditObject.attributes.grouping.${value}`);
      })
      .join(', ');
  }

  return result;
}
