import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { langs } from '../session';

export type TmOptions = {
  apiHost: string;
  availableLanguages: (keyof typeof langs)[];
  availableSocketChannels: {
    BROADCAST_CHANNEL_NAME: string;
    SERVICE_CONFIG_CHANNEL_NAME: string;
  };
  defaultLanguage: string;
  defaultRoute: string;
  longPollingHost: string;
  longPollingInterval: number;
  websocketHost: string;
  helpdesks: { [key: string]: string };
};

@Injectable()
export class TmOptionsService {
  public get all(): TmOptions {
    return this._options$.getValue();
  }
  public options$: Observable<TmOptions> = of(null).pipe(switchMap(() => this._options$));
  private _options$: BehaviorSubject<TmOptions> = new BehaviorSubject({} as TmOptions);

  public static withOptions(_options: TmOptions): TmOptionsService {
    const optionsService = new TmOptionsService();
    optionsService.set(_options);
    return optionsService;
  }

  /**
   * @deprecated prefer .getWithUpdates() method
   */
  public get<P extends keyof TmOptions>(key: P): TmOptions[P] {
    return this._options$.getValue()[key];
  }

  public getWithUpdates<P extends keyof TmOptions>(key: P): Observable<TmOptions[P]> {
    return this._options$.pipe(map((o) => o[key]));
  }

  public set(data: { [key: string]: any }): void {
    this._options$.next(Object.assign({}, this._options$.getValue(), data));
  }
}
