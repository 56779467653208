import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { TmResourcesService } from '@tm-shared/api-services';
import { TmFormComponent } from '@tm-shared/form';
import { TmAsyncValidatorsService } from '@tm-shared/helpers';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { EMPTY, Observable, combineLatest, merge, of, throwError } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { SystemListItem } from '../generated/resources';
import { RESOURCES, getPrivilegeRequest } from '../resources.model';
import { ResourcesTableFormService } from './resources-table-form.service';

@Component({
  selector: 'tm-resources-table-form',
  templateUrl: './resources-table-form.component.html',
  styleUrls: ['./resources-table-form.component.scss'],
  providers: [TmAsyncValidatorsService, ResourcesTableFormService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesTableFormComponent extends TmFormComponent<FormGroup> implements OnInit {
  public title$: Observable<string>;
  public confirmDataLoss = false;
  public createDate: string;
  public changeDate: string;
  public itemId$: Observable<string | number>;
  public resourcesId$: Observable<string | number>;
  public isNew$: Observable<boolean>;

  /**
   * Stream: Form data
   */
  private _data$: Observable<SystemListItem>;

  private _canEditResources$: Observable<boolean> = this._privileges
    .can(getPrivilegeRequest('edit'))
    .pipe(shareReplay(1));

  constructor(
    public service: TmResourcesService,
    private _notify: IwNotificationsService,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    private _t: TranslateService,
    private _privileges: TmPrivilegesService,
    private _resourcesFormService: ResourcesTableFormService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.resourcesId$
      .pipe(
        tap((resourcesId) => {
          this.form = this._resourcesFormService.createResourcesForm(this.itemId$, resourcesId);
        })
      )
      .subscribe();

    this._data$ = this.itemId$.pipe(
      switchMap((id) => {
        return id ? this.service.getById(id) : EMPTY;
      }),
      filter((data) => {
        if (data) {
          return true;
        } else {
          this.close();
          return false;
        }
      }),
      takeUntil(this._destroyed$)
    );
    this._data$
      .pipe(
        switchMap((data) => combineLatest(of(data), this._canEditResources$).pipe(take(1))),
        takeUntil(this._destroyed$)
      )
      .subscribe(([data, canEditResources]) => {
        this.form.reset();
        this.form.patchValue(data);
        if (data.CREATE_DATE) {
          this.createDate = data.CREATE_DATE;
        }
        if (data.CHANGE_DATE) {
          this.changeDate = data.CHANGE_DATE;
        }

        this._resourcesFormService.handleFormDisableState(this.form, canEditResources);
      });

    this.isNew$ = this.itemId$.pipe(map((id) => !id));

    merge(this.close$, this.submit$)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this.resourcesId$
          .pipe(
            tap((resourcesId) => {
              this._router.navigate([RESOURCES, resourcesId], {
                relativeTo: this._activatedRoute,
                queryParamsHandling: 'preserve',
              });
            })
          )
          .subscribe();
      });

    this._watchIsNewToChangeTitle();
  }
  /**
   * Save form data
   */
  protected _onSubmit() {
    const serverData: SystemListItem = this.form.getRawValue();

    return this.service.createOrUpdate(serverData).pipe(
      catchError((response) => {
        this._notify.error(
          this._t.instant('lists-resources.errors.error'),
          this._t.instant('lists-resources.errors.request-failed')
        );
        return throwError(response);
      }),
      tap(() => this.close(true)),
      takeUntil(this._destroyed$)
    );
  }

  private _watchIsNewToChangeTitle() {
    this.title$ = this.isNew$.pipe(
      map((isNew) => {
        if (isNew) {
          // this._t.instant('lists-resources.table.form.title-create-new')
          return 'lists-resources.table.form.title-create-new';
        } else {
          // this._t.instant('lists-resources.table.form.title-edit')
          return 'lists-resources.table.form.title-edit';
        }
      })
    );
  }
}
