import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwModalService, IwNotificationsService } from '@platform/shared';
import { TmScopeApiService } from '@tm-shared/api-services/scope-api.service';
import { TmGridComponent, TmGridOptions } from '@tm-shared/grid';
import { CheckboxCellComponent } from '@tm-shared/grid/cell-renderers';
import { ModalConfirmComponent } from '@tm-shared/modals';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmSidebarService } from '@tm-shared/structure/sidebar/sidebar.service';
import { Observable, Subject, combineLatest, of, throwError } from 'rxjs';
import { auditTime, catchError, filter, map, mapTo, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ACCESS_PATH, CREATE_PATH, EDIT_PATH, SCOPE_PATH } from '../access-exports';
import { ScopeFormComponent } from './scope-form.component';
import { MODEL_ID_KEY, ScopeModel, getPrivilegeRequest } from './scope.model';

@Component({
  selector: 'tm-scopes',
  templateUrl: './scope.component.html',
  styleUrls: ['./scope.component.scss'],
  providers: [TmSidebarService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScopeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('grid', { static: true }) public grid: TmGridComponent<ScopeModel>;

  public CREATE_PATH = CREATE_PATH;
  public EDIT_PATH = EDIT_PATH;

  /**
   * Define can streams
   */
  public canEditSelection$ = of(null).pipe(
    switchMap(() => this.grid.selected$),
    map((data) => !!data.length),
    takeUntil(of(null).pipe(switchMap(() => this._destroyed$)))
  );

  public canRemoveSelection$ = of(null).pipe(
    switchMap(() => this.grid.selected$),
    map((data) => !!(data.length && data.every((elem) => !elem.IS_SYSTEM))),
    takeUntil(of(null).pipe(switchMap(() => this._destroyed$)))
  );
  public canCreate$ = this._can('edit');
  public canRemove$ = this._can('delete', this.canRemoveSelection$);

  /**
   * Scope grid options
   */
  public gridOptions: TmGridOptions = {
    columnDefs: [
      {
        width: 40,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        resizable: true,
        sort: 'asc',
        sortable: true,
        headerValueGetter: () => this._t.instant('settings-access.scope.fieldNames.displayName'),
      },
      {
        resizable: true,
        field: 'NOTE',
        sortable: true,
        headerValueGetter: () => this._t.instant('settings-access.scope.fieldNames.note'),
      },
    ],
  };

  /**
   * Unsubscribe trigger
   */
  private _destroyed$: Subject<void> = new Subject();

  constructor(
    public service: TmScopeApiService,
    public router: Router,
    private _activatedRoute: ActivatedRoute,
    private _t: TranslateService,
    private _sidebarService: TmSidebarService,
    private _privileges: TmPrivilegesService,
    private _modalService: IwModalService,
    private _notify: IwNotificationsService
  ) {}

  public ngOnInit() {
    this._sidebarService
      .onAction('*', 'complete')
      .pipe(
        switchMap(() => this.grid.initialized$),
        takeUntil(this._destroyed$)
      )
      .subscribe((gridApi) => {
        gridApi.sizeColumnsToFit();
      });

    this.grid.selectedOrdered$.pipe(skip(1), takeUntil(this._destroyed$)).subscribe((selection) => {
      if (!selection.length) {
        this._sidebarService.close('right');
        this.router.navigate([ACCESS_PATH, SCOPE_PATH], {
          queryParamsHandling: 'merge',
        });
      } else {
        this.showDialogEdit(selection[0][MODEL_ID_KEY]!);
      }
    });
  }

  public ngAfterViewInit(): void {
    if (this._activatedRoute.firstChild && this._activatedRoute.firstChild.snapshot.params['id']) {
      this.grid.selectById(this._activatedRoute.firstChild.snapshot.params['id']);
    }
  }

  /**
   * Ag destroy hook
   */
  public ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Handler load form component
   */
  public onActivate(component: ScopeFormComponent): void {
    component.close$
      .pipe(
        tap(() => this._sidebarService.close('right')),
        auditTime(200),
        take(1),
        filter(() => !!this.grid),
        tap(() => this.grid.resetSelection()),
        tap(() => this.grid.refresh()),
        takeUntil(this._destroyed$)
      )
      .subscribe();

    this._sidebarService.open('right');
  }

  /**
   * Open create dialog
   */
  public showDialogCreate(): void {
    this.router.navigate([CREATE_PATH], {
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge',
    });
    this._sidebarService.open('right');
  }

  /**
   * Open edit dialog
   */
  public showDialogEdit(id: string): void {
    this.router.navigate([EDIT_PATH.replace(':id', `${id}`)], {
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge',
    });
    this._sidebarService.open('right');
  }

  /**
   * Open delete confirmation dialog
   */
  public showDialogRemove(): void {
    this.service
      .getItemsByIds(this.grid.getAllSelectedIds())
      .pipe(
        switchMap((_selectedModels: ScopeModel[]) => {
          // Добавить в модалку список удаляемых ОВ...
          // _selectedModels
          return this._modalService.open(ModalConfirmComponent, {
            title: this._t.instant('settings-access.confirmAction'),
            text: this._t.instant('settings-access.scope.form.deleteUserText'),
          });
        }),
        switchMap((modal) => {
          const decision$: EventEmitter<boolean> = modal.component.decision;

          return decision$.pipe(
            switchMap((ok) => (ok ? this.grid.deleteAllSelected().pipe(mapTo(ok)) : of(ok))),
            tap((ok) => (ok ? this._sidebarService.close('right') : null))
          );
        }),
        catchError((e) => {
          this._notify.error(
            this._t.instant('settings-access.scope.errors.error'),
            this._t.instant('settings-access.scope.errors.requestFail')
          );

          return throwError(e);
        }),
        take(1)
      )
      .subscribe();
  }

  /**
   * Check privileges
   */
  private _can(actionKey: TmPluginAccess.scope.PrivilegeKey, ...rules$: Observable<boolean>[]): Observable<boolean> {
    const can$: Observable<boolean> = this._privileges.can(getPrivilegeRequest(actionKey));

    return combineLatest(can$, ...rules$).pipe(map((bools: boolean[]) => bools.every((b) => b)));
  }
}
