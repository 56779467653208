import { ClassifierType } from './tech-classifier-providers';

export function getPrivilegeFor(technology: ClassifierType | 'graphic', type: 'full_access' | 'show') {
  // TODO: remove 'graphic'
  if (technology === ClassifierType.graphicImageClassifier) {
    technology = 'graphic';
  }

  if (type === 'show') {
    return `analysis:${technology}`;
  }
  return `analysis:${technology}:${type}`;
}

export function getTechLoaderGroupKey(technology: ClassifierType) {
  return `${technology}-document-upload`;
}

export function getTechLoaderFileCheckKey(technology: ClassifierType) {
  return `${technology}-file-check`;
}
