<iw-modal-header>{{ title }}</iw-modal-header>

<span>{{ 'settings-access.checkDelete' | translate }}</span>
<b>
  {{ rolesToDelete }}
</b>
?

<iw-modal-footer>
  <button iw-button color="success" data-qa="userDeleteModalYES" (click)="decision.emit(true)">{{ yes }}</button>
  <button iw-button data-qa="userDeleteModalNO" (click)="decision.emit(false)">{{ no }}</button>
</iw-modal-footer>
