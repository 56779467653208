import { NgModule } from '@angular/core';
import { TmAuditPageComponent } from './audit-page.component';
import { TmAuditPageRoutingModule } from './audit-page-routing.module';
import { TmStructureModule } from '@tm-shared/structure';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { TmAuditEventFilterModule } from '../audit-event-filter/audit-event-filter.module';
import { TmAuditEventListModule } from '../audit-event-list/audit-event-list.module';

@NgModule({
  imports: [
    TmAuditPageRoutingModule,
    TmStructureModule,
    TranslateModule,
    CommonModule,
    TmAuditEventFilterModule,
    TmAuditEventListModule,
  ],
  declarations: [TmAuditPageComponent],
})
export class TmAuditPageModule {}
