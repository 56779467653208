import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { IwPopoverOptions } from '@platform/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tm-pagination',
  templateUrl: './tm-pagination.component.html',
  styleUrls: ['./tm-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
        closeOnMousemoveOutside: true,
        triggers: 'mouseenter:mouseleave',
      }),
    },
  ],
})
export class TmPaginationComponent implements TmShared.pagination.Component, OnInit, OnDestroy {
  /**
   * Current page
   */
  @Input() public set current(page: number) {
    this._current = page;
    delete this.requestedPage;
  }

  public get current(): number {
    return this._current;
  }

  /**
   * Pages count
   * To set 1 page, set 'total' to 1.
   */
  @Input() public total = 0;

  public paginationSize = new FormControl();
  @Output() public paginationSizeChange = new EventEmitter<number>();

  /**
   * Nearest pages limit:
   * <-- limit -- [ current ] -- limit -->
   */
  @Input() public limit = 2;

  /**
   * Emits requested page number
   */
  @Output() public onPaginate: EventEmitter<number> = new EventEmitter();

  /**
   * The page which should be displayed at loading state
   */
  @Input() public requestedPage?: number;

  @Input() public paginationSizeItems: number[] = [];
  @Input() public set selectedPaginationSize(size: number | null) {
    this.paginationSize.patchValue(size, { emitEvent: false });
  }

  private _destroy$ = new Subject();

  private _current = 0;

  constructor(private _cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.paginationSizeItems = [...this.paginationSizeItems];
    this.paginationSizeItems.sort((a, b) => a - b);
    this.paginationSize.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((val) => {
      this.paginationSizeChange.emit(val);
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Check page existance
   */
  public hasPage(i: number): boolean {
    return i >= 0 && i < this.total;
  }

  /**
   * Get nearest pages:
   * <-- limit -- [ current ] -- limit -->
   */
  public getNearest(current: number, limit: number = 2): number[] {
    // pagination buttons amount
    let length = Math.min(this.total, limit * 2 + 1);

    // Find shift values
    const shiftStart = Math.abs(Math.min(0, this.total - current - limit));

    // first displayed page number
    let start = Math.max(0, current - limit - shiftStart);

    if (length + start === this.total + 1) {
      length -= 1;
    }

    // Pages array
    return new Array(length).fill(null).map(() => start++);
  }

  /**
   * Set current page
   */
  public goTo(i: number): void {
    if (this.current === i || i < 0 || i >= this.total) {
      return;
    }

    this.requestedPage = i;
    this.onPaginate.emit(i);
  }

  public onPaginationFail(): void {
    if (this.requestedPage) {
      delete this.requestedPage;
      this._cd.markForCheck();
    }
  }
}
