import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class TmAnalysisTextPatternService {
  constructor(private _http: HttpClient) {}

  public validate(data: TmPluginAnalysisText.PatternFormOutput): Partial<ApiTextObjectPattern.TextObjectPattern> {
    let patternId = data.TEXT_OBJECT_PATTERN_ID;
    const VALIDATE_URL = patternId ? `api/textObjectPattern/${patternId}/validate` : 'api/textObjectPattern/validate';

    return patternId ? this._http.put(VALIDATE_URL, data) : this._http.post(VALIDATE_URL, data);
  }
}
