import { TranslateService } from '@ngx-translate/core';
import {
  CategoryDiffProperties,
  EntriesDiffProperties,
  FingerprintDiffProperties,
  ProtectedDocumentDiffProperties,
  TextObjectDiffProperties,
} from 'typings/generated/audit-protected-document';
import { TmAuditBrItemsCellComponent } from '../audit-cell-components/audit-br-items.component';
import { AuditExtendGridData, AuditGridDataColumn } from '../audit-extend.service';
import { AuditEvent, AuditEventOperation } from '../audit.model';
import {
  getAuditAttributesFrom,
  getAuditExtendRowData,
  transformBooleanYesNo,
  TransformOptions,
  VisibleAttributesOption,
} from './audit-common';

type ProtectedDocumentItem = CategoryDiffProperties | FingerprintDiffProperties | TextObjectDiffProperties;

const auditProtectedDocumentCommonVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'DISPLAY_NAME', i18nKey: 'audit.protectedDocument.displayName' },
  { path: 'NOTE', i18nKey: 'audit.protectedDocument.note' },
  { path: 'document2catalog.0.ENABLED', i18nKey: 'audit.protectedDocument.enabled', transform: transformBooleanYesNo },
  { path: '', i18nKey: 'audit.protectedDocument.itemsLabel', transform: extractAllItems },
  {
    path: '',
    i18nKey: 'audit.protectedDocument.conditionsLabel',
    transform: extractAllConditions,
    filter: 'deepEqual',
    component: TmAuditBrItemsCellComponent,
  },
  {
    path: 'document2catalog',
    i18nKey: 'audit.protectedDocument.catalogs',
    transform: transformDocument2Catalog,
  },
];

export function getProtectedDocumentGrids(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  let cols: AuditGridDataColumn[] = [];
  /**
   * Set correct columns
   */
  switch (data.OPERATION) {
    case AuditEventOperation.create:
      cols = [AuditGridDataColumn.new];
      break;
    case AuditEventOperation.delete:
      cols = [AuditGridDataColumn.old];
      break;
    case AuditEventOperation.move:
    case AuditEventOperation.update:
    default:
      cols = [AuditGridDataColumn.old, AuditGridDataColumn.new];
      break;
  }

  const gridData = getAuditExtendRowData({
    t,
    cols: cols,
    data: getAuditAttributesFrom(data),
    visibleAttributes: auditProtectedDocumentCommonVisibleAttributes,
  });

  if (gridData.length) {
    return [
      {
        cols: cols,
        data: gridData,
      },
    ];
  }

  return [];
}

export function extractAllItems(data: ProtectedDocumentDiffProperties | null | undefined): string | null {
  if (!data) {
    return null;
  }

  let items: (CategoryDiffProperties | FingerprintDiffProperties | TextObjectDiffProperties)[] = [];

  if (data.category_pool?.length) {
    items = items.concat(data.category_pool);
  }

  if (data.fingerprint_pool?.length) {
    items = items.concat(data.fingerprint_pool);
  }

  if (data.text_object_pool?.length) {
    items = items.concat(data.text_object_pool);
  }

  return items
    .map((x) => x.DISPLAY_NAME)
    .filter((x) => !!x)
    .join(', ');
}

const entryTypeToIdAttr = {
  category: 'CATEGORY_ID',
  text_object: 'TEXT_OBJECT_ID',
  fingerprint: 'FINGERPRINT_ID',
  form: 'FINGERPRINT_ID',
  stamp: 'FINGERPRINT_ID',
  table: 'FINGERPRINT_ID',
  image_classifier: 'FINGERPRINT_ID',
};

export function extractAllConditions(
  data: ProtectedDocumentDiffProperties | null | undefined,
  o: TransformOptions
): string[] | null {
  if (!data || !data.conditions) {
    return null;
  }

  /**
   * Collect protected documents grouped by conditions
   */
  const conditions = data.conditions.map((condition) => {
    if (!data.entries) {
      return [];
    }

    return data.entries
      .filter((entry) => entry.CONDITION_ID === condition.CONDITION_ID)
      .map((entry) => {
        const type = entry.ENTRY_TYPE;
        const item = data[type] as Record<keyof ProtectedDocumentItem, unknown>[] | undefined;
        const entryData = item?.find((item) => {
          return item[entryTypeToIdAttr[type] as keyof ProtectedDocumentItem] === entry.ENTRY_ID;
        }) as ProtectedDocumentItem | null;

        return entryData ? getInformation(entry, entryData, o) : '';
      })
      .filter((x) => !!x);
  });

  if (!conditions.length) {
    return null;
  }

  return conditions.reduce((result, items, i) => {
    result.push(items.join(`, `));

    if (i < conditions.length - 1) {
      result.push(`${o.t.instant('audit.protectedDocument.operators.or')}`);
    }

    return result;
  }, [] as string[]);
}

export function getInformation(entry: EntriesDiffProperties, data: ProtectedDocumentItem, o: TransformOptions): string {
  let result = data.DISPLAY_NAME;

  if (entry.QUANTITY_THRESHOLD) {
    result += ` (${o.t.instant('audit.protectedDocument.quantityThreshold')}: ${entry.QUANTITY_THRESHOLD})`;
  }

  if (entry.USE_DEFAULT_CONDITION) {
    const condition = entry.USE_DEFAULT_CONDITION
      ? o.t.instant('audit.protectedDocument.defaultCondition')
      : o.t.instant('audit.protectedDocument.orCondition');
    result += ` (${o.t.instant('audit.protectedDocument.detectionCondition')}: ${condition})`;
  }

  return result;
}

export function transformDocument2Catalog(
  doc2catalog: { CATALOG_ID: string }[] | undefined,
  o: TransformOptions & { columnData: { catalog?: { CATALOG_ID: string; DISPLAY_NAME: string }[] } }
): string {
  const data = o.columnData;
  return (
    doc2catalog
      ?.map((item) => data.catalog?.find((catalog) => catalog.CATALOG_ID === item.CATALOG_ID)?.DISPLAY_NAME)
      .filter((x) => !!x)
      .join(', ') || ''
  );
}
