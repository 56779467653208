import { ChangeDetectionStrategy, Component, Optional, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { TmChannelService } from '@tm-shared/channel';
import { TmElement } from '@tm-shared/custom-elements';
import { ExportModel, STATUSES } from './export-monitor.model';
import { TmExportMonitorService } from './export-monitor.service';

@TmElement('tme-export-monitor')
@Component({
  selector: 'tm-export-monitor',
  templateUrl: 'export-monitor.component.html',
  styleUrls: ['export-monitor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmExportMonitorComponent {
  @ViewChild('[iwPopover]', { static: false }) public popover: any;

  public complete = false;
  public length = 0;

  constructor(
    private _exportService: TmExportMonitorService,
    private _translate: TranslateService,
    private _notify: IwNotificationsService,
    @Optional() private _channelService?: TmChannelService
  ) {
    if (this._channelService) {
      this._channelService.getUserChannel('object_report').subscribe(this._readObjectReportMessage.bind(this));
      this._channelService.getUserChannel('object_reporter').subscribe(this._readObjectReporterMessage.bind(this));
      this._channelService.getUserChannel('reporter').subscribe(this._readReporterMessage.bind(this));
    }

    this._exportService.collection$.subscribe(this._updateExportStatus.bind(this));
  }

  public togglePopover(): void {
    this.popover.toggle();
    this.toggleComplete(false);
  }

  public toggleComplete(bool: boolean): void {
    this.complete = bool;
  }

  private _updateExportStatus(data: ExportModel[]): void {
    this.length = data.filter((item) => {
      return item.STATUS === STATUSES.EXECUTING || item.STATUS === STATUSES.NEW;
    }).length;
  }

  private _readObjectReportMessage({ meta, state }: { meta: any; state: any }): void {
    this._exportService.updateExportStatus(state, meta);
  }

  private _readObjectReporterMessage({
    error,
    meta,
    data,
    state,
  }: {
    error: any;
    meta: any;
    data: any;
    state: any;
  }): void {
    if (state === 'data') {
      if (meta.module === 'object_reporter') {
        this._exportService.updateExportFiles(state, data);
        this.toggleComplete(true);

        if (this.popover.isVisible) {
          return;
        }

        this._notify.success(
          this._translate.instant('events.exportMonitor.export'),
          `${this._translate.instant('events.exportMonitor.export_done', { selection: data.filename })}
          [<a href='${window.location.host}/api/object/reportFile?
          report_id=#{data.report_id}' target='_blank'>
          ${this._translate.instant('events.exportMonitor.download')}
          </a>]`,
          {
            timeOut: 0,
          }
        );
      }

      if (this.popover.isVisible) {
        return;
      }

      this._notify.success(
        this._translate.instant('events.exportMonitor.report'),
        `${this._translate.instant('events.exportMonitor.report_done', { name: data.DISPLAY_NAME })}
        [<a href='${window.location.host}/public/${data.HASH}.pdf' target='_blank'>pdf</a>]
        [<a href='${window.location.host}/public/${data.HASH}.html' target='_blank'>html</a>]`,
        {
          timeOut: 0,
        }
      );
    } else if (state === 'error') {
      let query_name = meta.query;

      switch (error) {
        case 'Error file open':
          error = this._translate.instant('events.exportMonitor.error_on_file_open', { selection: query_name });
          break;
        case 'File type is not supported':
          error = this._translate.instant('events.exportMonitor.error_file_type', { selection: query_name });
          break;
        default:
          if (query_name) {
            error = this._translate.instant('events.exportMonitor.export_fail', { selection: query_name });
          } else {
            error = this._translate.instant('events.exportMonitor.export_internal_error');
          }
      }

      this._notify.error(this._translate.instant('events.exportMonitor.export'), error, {
        timeOut: 0,
      });
    }
  }

  private _readReporterMessage({ data, state }: { data: any; state: any }): void {
    if (state === 'data') {
      this._exportService.updateExportStatus(1, data);
      this.toggleComplete(true);

      if (this.popover.isVisible) {
        return;
      }

      this._notify.success(
        this._translate.instant('events.exportMonitor.report'),
        `${this._translate.instant('events.exportMonitor.report_done', { selection: data.DISPLAY_NAME })}
        [<a href='${window.location.host}/public/#{data.HASH}.pdf' target='_blank'>pdf</a>]
        [<a href='${window.location.host}/public/#{data.HASH}.html' target='_blank'>html</a>]`,
        {
          timeOut: 0,
        }
      );
    }
    if (state === 'error') {
      this._exportService.updateExportStatus(2, data);

      if (this.popover.isVisible) {
        return;
      }

      this._notify.error(
        this._translate.instant('events.exportMonitor.report'),
        this._translate.instant('events.exportMonitor.undefined_error'),
        {
          timeOut: 0,
        }
      );
    }
  }
}
