import { Component, OnInit } from '@angular/core';
import { TmAuditEventFilterService } from './audit-event-filter.service';
import { TmUserApiService } from '@tm-shared/api-services';
import { TmAuditEventRetentionService } from './audit-event-retention.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { AuditEventEntityType, AuditEventOperation } from '../audit.model';
import { TmAuditService } from '../audit.service';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { FromToRange, DateRangeMode } from '@tm-shared/date-range/date-range.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';

interface AuditEventsFilterForm {
  user: string | null;
  action: string | null;
  entityType: string | null;
  query: string | null;
  dateRange: FromToRange | null;
}

interface AuditSettings {
  retention: number;
}

@UntilDestroy()
@Component({
  selector: 'tm-audit-event-filter',
  templateUrl: 'audit-event-filter.component.html',
  styleUrls: ['audit-event-filter.component.scss'],
})
export class TmAuditEventFilterComponent implements OnInit {
  public static settingsApplicationDebounceMs = 1000;

  public filtersForm: FormGroup;

  public settingsForm: FormGroup;

  public userFilterOptions: IwSelectItem[] = [];

  public allowTimeSelection = false;

  public actionFilterOptions: IwSelectItem<AuditEventOperation>[] = this.getAuditEventOperationOptions();

  public entityTypeFilterOptions: IwSelectItem<AuditEventEntityType>[] = this.getAuditEventEntityTypeOptions();

  public dateRangeModes = [DateRangeMode.none, DateRangeMode.fromTo];

  constructor(
    private filterService: TmAuditEventFilterService,
    private retentionService: TmAuditEventRetentionService,
    private userService: TmUserApiService,
    private auditService: TmAuditService,
    private t: TranslateService,
    private notify: IwNotificationsService,
    formBuilder: FormBuilder
  ) {
    this.filtersForm = formBuilder.group(<AuditEventsFilterForm>{
      user: null,
      action: null,
      entityType: null,
      dateRange: null,
      query: null,
    });

    this.settingsForm = formBuilder.group({
      retention: null,
    });

    /**
     * Disable control until value is loaded
     */
    this.settingsForm.get('retention')?.disable();
  }

  public ngOnInit(): void {
    this.setupFilters();
    this.setupSettings();
  }

  private setupFilters(): void {
    /**
     * Set user options
     */
    this.userService
      .get({
        params: {
          'sort[DISPLAY_NAME]': 'asc',
        },
      })
      .subscribe((response) => {
        this.userFilterOptions = response.data.map((user) => {
          return {
            label: user.DISPLAY_NAME,
            value: user.USER_ID,
          };
        });
      });

    this.filtersForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: AuditEventsFilterForm) => this.updateFilters(value));
  }

  private setupSettings(): void {
    /**
     * Update current retention value and enable control
     * then listen to settings changes
     */
    this.updateRetentionValue()
      .pipe(
        switchMap(() => this.settingsForm.valueChanges),
        debounceTime(TmAuditEventFilterComponent.settingsApplicationDebounceMs),
        untilDestroyed(this)
      )
      .subscribe((value: AuditSettings) => this.updateSettings(value));
  }

  private updateRetentionValue(): Observable<unknown> {
    return this.retentionService.getRetentionLimits().pipe(
      tap((val) => {
        this.settingsForm.patchValue({
          retention: val,
        });
        this.settingsForm.get('retention')?.enable();
      })
    );
  }

  private updateFilters(params: AuditEventsFilterForm): void {
    this.filterService.setUser(params.user);
    this.filterService.setAction(params.action);
    this.filterService.setEntity(params.entityType);

    /**
     * Support only from-to range mode
     */
    if (params.dateRange?.mode === DateRangeMode.fromTo) {
      const dateFrom = this.allowTimeSelection
        ? DateTime.fromJSDate(params.dateRange.from).startOf('minute').toJSDate()
        : DateTime.fromJSDate(params.dateRange.from).startOf('day').toJSDate();
      const dateTo = this.allowTimeSelection
        ? DateTime.fromJSDate(params.dateRange.to).endOf('minute').toJSDate()
        : DateTime.fromJSDate(params.dateRange.to).endOf('day').toJSDate();
      this.filterService.setPeriod(
        params.dateRange.from && params.dateRange.to ? ([dateFrom, dateTo] as [Date, Date]) : null
      );
    } else {
      this.filterService.setPeriod(null);
    }

    this.filterService.setQuery(params.query);
  }

  private updateSettings(params: AuditSettings): void {
    this.retentionService.setRetentionLimits(params.retention).subscribe({
      next: () =>
        this.notify.success(
          this.t.instant('audit.eventFilter.retentionUpdateSuccessTitle'),
          this.t.instant('audit.eventFilter.retentionUpdateSuccessText', { value: params.retention })
        ),
      error: () => {
        this.notify.error(
          this.t.instant('audit.eventFilter.retentionUpdateErrorTitle'),
          this.t.instant('audit.eventFilter.retentionUpdateErrorText')
        );
      },
    });
  }

  private getAuditEventOperationOptions(): IwSelectItem[] {
    return Object.values(AuditEventOperation)
      .map((key: AuditEventOperation) => {
        return {
          value: key,
          label: this.auditService.getLocalizedOperation(key),
        };
      })
      .sort((a, b) => (a.label >= b.label ? 1 : -1));
  }

  private getAuditEventEntityTypeOptions(): IwSelectItem[] {
    return Object.values(AuditEventEntityType)
      .map((key: AuditEventEntityType) => {
        return {
          value: key,
          label: this.auditService.getLocalizedEntityType(key),
        };
      })
      .sort((a, b) => (a.label >= b.label ? 1 : -1));
  }
}
