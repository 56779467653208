<tm-page class="page" data-qa="graphicPageContent">
  <tm-sidebar title="{{ 'tech-graphic.pageTitle' | translate }}">
    <p class="totalQuality" *ngIf="totalQuality !== undefined">
      {{ 'tech-graphic.totalQuality' | translate: { percent: totalQuality } }}
    </p>
    <tm-autoling-page-toolbar
      class="toolbar"
      type="autoling"
      [categoryId]="selectedId$ | async"
      [checkFilesComponent]="checkFilesComponent"
      (create)="createCategory()"
      (edit)="editCategory()"
      (remove)="removeCategory()"
    ></tm-autoling-page-toolbar>

    <tm-tree
      [useFilter]="true"
      class="graphic-menu"
      data-qa="graphic-menu"
      [focusedNodeId]="selectedId$ | async"
      [data]="graphicMenu$ | async"
      [treeItemLinkParams]="treeItemConfig"
      (onDataDropped)="pushUnknownDataToCategory($event)"
      [overrideNodeTpl]="customNodeTemplate"
    >
      <ng-template #customNodeTemplate let-node="node">
        <div class="categoryNode">
          <div class="categoryName">{{ node.data.name }}</div>
          <div class="categoryQuality" *ngIf="node.data.data.percent !== null">{{ node.data.data.percent }}%</div>
        </div>
      </ng-template>
    </tm-tree>
  </tm-sidebar>
  <router-outlet #mainOutlet (activate)="onRouterOutletActivate($event)"></router-outlet>
</tm-page>
