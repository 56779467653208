import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IwPopoverOptions, IwModalService } from '@platform/shared';
import { TmSidebarService } from '@tm-shared/structure/sidebar';
import { TmTreeDropEvent, TmTreeNodeData, TreeItemLinkParams } from '@tm-shared/tree';
import {
  CategoryService,
  CheckService,
  ClassifierType,
  ClassifierTypeToken,
  FingerprintService,
  TrainingService,
} from 'plugins/tech-classifier/tech-classifier-providers';
import { Observable, Subject, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CategoryGet, DocumentGet, TrainingState } from 'typings/generated/technology-autoling';
import { TmConfigLocalService } from '../config/services';
import {
  TmAutolingCategoryEditComponent,
  TmAutolingCategoryEditData,
} from './autoling-category-edit/autoling-category-edit.component';
import { TmAutolingCategoryService } from './autoling-category.service';
import { TmAutolingCategoryComponent } from './autoling-category/autoling-category.component';
import { TmAutolingDocumentCheckService } from './autoling-document-check.service';
import { TmAutolingDocumentCheckComponent } from './autoling-document-check/autoling-document-check.component';
import { TmAutolingDocumentService } from './autoling-document.service';
import { TmAutolingTrainingService } from './autoling-training.service';
import { TmChannelService } from '../../@tm-shared/channel';

type RouterOutletComponents = TmAutolingCategoryComponent | null;

@Component({
  selector: 'tm-autoling',
  templateUrl: './autoling-page.component.html',
  styleUrls: ['./autoling-page.component.scss'],
  providers: [
    TmAutolingCategoryService,
    TmSidebarService,
    TmAutolingDocumentService,
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
        closeOnMousemoveOutside: true,
        triggers: 'mouseenter:mouseleave',
      }),
    },
    {
      provide: CategoryService,
      useExisting: TmAutolingCategoryService,
    },
    {
      provide: FingerprintService,
      useExisting: TmAutolingDocumentService,
    },
    {
      provide: CheckService,
      useExisting: TmAutolingDocumentCheckService,
    },
    {
      provide: ClassifierTypeToken,
      useValue: ClassifierType.autoling,
    },
    {
      provide: TrainingService,
      useExisting: TmAutolingTrainingService,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmAutolingPageComponent implements OnDestroy {
  public autolingMenu$: Observable<TmTreeNodeData[]>;
  public selectedId$: Observable<string | null>;

  public checkFilesComponent = TmAutolingDocumentCheckComponent;

  public treeItemConfig: TreeItemLinkParams = {
    routerLinkGetter: (nodeId: string): string[] => {
      return ['/analysis/' + this.type, nodeId];
    },
  };

  public totalQuality?: number;

  public wsImportSuccess$: Observable<any> = this._channelService.getUserChannel('analysis').pipe(
    filter((data) => data.meta.method === 'import' && data.state === 'success'),
    shareReplay(1)
  );

  protected trainingSucceed$ = this._trainingService.state$.pipe(map((state) => state === TrainingState.success));

  private _destroy$: Subject<void> = new Subject();
  private _selectedCategory$: Observable<CategoryGet | null>;
  private _updateCategoryList$: Subject<void>;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    @Inject(CategoryService) private _categoryService: TmAutolingCategoryService,
    @Inject(FingerprintService) private _autolingDocumentService: TmAutolingDocumentService,
    @Inject(TrainingService) private _trainingService: TmAutolingTrainingService,
    @Inject(ClassifierTypeToken) protected type: ClassifierType,
    private _configurationService: TmConfigLocalService,
    private _channelService: TmChannelService,
    private _modalService: IwModalService
  ) {
    this._updateCategoryList$ = new Subject();
    this.selectedId$ = this._route.params.pipe(map(this._getIdParam));
    this._configurationService.refreshOnConfigChanges.pipe(takeUntil(this._destroy$)).subscribe();

    this.wsImportSuccess$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._updateCategoryList$.next();
    });

    this._selectedCategory$ = this.selectedId$.pipe(
      switchMap((id: string) => (id ? this._categoryService.getById(id).pipe(catchError(() => of(null))) : of(null)))
    );
    this.autolingMenu$ = this._updateCategoryList$.pipe(
      startWith(null),
      switchMap(() => this.trainingSucceed$),
      switchMap((trained) => this._categoryService.getSorted().pipe(map((response) => ({ response, trained })))),
      map(({ response, trained }) => {
        this._updateTotalQuality(response.data, trained);
        return response.data.map((categoryItem) => this._autolingCategoryItemToAutolingMenuNode(categoryItem, trained));
      })
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onRouterOutletActivate(component: RouterOutletComponents) {
    this._setupRouterOutletComponent(component);
  }

  public createCategory(): void {
    this._showEditCategoryModal();
  }

  public startTrainingBtnClicked(): void {
    this._trainingService.startTraining();
  }

  public editCategory(): void {
    const id = this._getIdParam(this._route.snapshot.params);

    if (!id) {
      this._showEditCategoryModal();
      return;
    }

    this._categoryService
      .getById(id)
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe((data) => {
        this._showEditCategoryModal(data);
      });
  }

  public removeCategory(): void {
    const id = this._getIdParam(this._route.snapshot.params);

    if (id) {
      this._categoryService
        .remove(id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          this._router.navigate([`analysis/${this.type}`]);
          this._updateCategoryList$.next();
        });
    }
  }

  public pushUnknownDataToCategory(dropData: TmTreeDropEvent<DocumentGet>): void {
    const newCategoryId = dropData.node.data.id;
    const newData = Object.assign({}, dropData.data, {
      autoling2category: [
        {
          FINGERPRINT_ID: dropData.data.FINGERPRINT_ID,
          CATEGORY_ID: newCategoryId,
        },
      ],
    });

    // Backend should receive only 1 relation (autoling2category) to store
    delete newData.category;

    this._autolingDocumentService.updateById(newData.FINGERPRINT_ID, newData).subscribe(() => {
      this._autolingDocumentService.dataChanged$.next();
    });
  }

  private _getIdParam = (params: Params) => {
    return params.id;
  };

  private _updateTotalQuality(data: CategoryGet[], trained: boolean): void {
    if (!trained || !data.length) {
      delete this.totalQuality;
      return;
    }

    this.totalQuality = Math.round((data.reduce((total, item) => (total += item.QUALITY || 0), 0) / data.length) * 100);
  }

  private async _showEditCategoryModal(data?: TmAutolingCategoryEditData): Promise<void> {
    const currentModal = await this._modalService.open(TmAutolingCategoryEditComponent, { data });

    currentModal.component.onSubmit = (component) => {
      return (data && data.CATEGORY_ID
        ? this._categoryService.updateById(data.CATEGORY_ID, component.changedValues)
        : this._categoryService.create(component.changedValues)
      ).pipe(tap(() => this._updateCategoryList$.next()));
    };
  }

  private _setupRouterOutletComponent(component: RouterOutletComponents): void {
    if (component instanceof TmAutolingCategoryComponent) {
      this._setupCategoryComponent(component);
    }
  }

  private _autolingCategoryItemToAutolingMenuNode(categoryItem: CategoryGet, trained: boolean): TmTreeNodeData {
    return {
      id: categoryItem.CATEGORY_ID,
      name: categoryItem.DISPLAY_NAME,
      data: {
        percent: trained ? this._getQualityInPercent(categoryItem) : null,
      },
    };
  }

  /**
   * Get percent value or 'null' if no quality provided
   */
  private _getQualityInPercent(categoryItem: CategoryGet): number | null {
    if (categoryItem.QUALITY === undefined || isNaN(categoryItem.QUALITY)) {
      return null;
    }

    return Math.round(categoryItem.QUALITY * 100);
  }

  private _setupCategoryComponent(component: TmAutolingCategoryComponent): void {
    this._selectedCategory$.pipe(distinctUntilChanged(), takeUntil(this._destroy$)).subscribe((data) => {
      component.data = data;
    });
  }
}
