import { Routes } from '@angular/router';
import { StatusComponent } from './status-component/status.component';
import { CREATE_PATH, EDIT_PATH, STATUS } from './status-exports';
import { StatusFormComponent } from './status-form-component/status-form.component';

export const ROUTES: Routes = [
  {
    path: STATUS,
    component: StatusComponent,
    children: [
      {
        path: CREATE_PATH,
        component: StatusFormComponent,
        data: {
          usePrivilege: 'lists/statuses/status/show',
        },
      },
      {
        path: EDIT_PATH,
        component: StatusFormComponent,
        data: {
          usePrivilege: 'lists/statuses/status/show',
        },
      },
    ],
  },
];
