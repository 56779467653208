import { NgModule } from '@angular/core';

import { TmAuditEventFilterComponent } from './audit-event-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IwSharedModule } from '@platform/shared';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { TmDateRangeModule } from '@tm-shared/date-range/date-range.module';
import { TmIconModule } from '../../../@tm-shared/icons/tm-icon.module';
import { TmPrivilegesModule } from '../../../@tm-shared/privileges/privileges.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IwSharedModule,
    TmIconModule,
    TmPrivilegesModule,
    TranslateModule,
    TmDateRangeModule,
  ],
  declarations: [TmAuditEventFilterComponent],
  exports: [TmAuditEventFilterComponent],
})
export class TmAuditEventFilterModule {}
