import { TranslateService } from '@ngx-translate/core';
import { FingerprintDiffProperties } from 'typings/generated/audit-fingerprint';
import { TmAuditBrItemsCellComponent } from '../audit-cell-components/audit-br-items.component';
import { AuditExtendGridData, AuditGridDataColumn } from '../audit-extend.service';
import { AuditEvent, AuditEventOperation } from '../audit.model';
import {
  VisibleAttributesOption,
  getAuditExtendRowData,
  getAuditAttributesFrom,
  TransformOptions,
  getDefaultColumns,
  transformTech2Category,
} from './audit-common';

const auditEtFormVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'FILE_PATH', i18nKey: 'audit.entries.form.filePath' },
  { path: 'NOTE', i18nKey: 'audit.entries.form.note' },
  { path: 'compileSource.name', i18nKey: 'audit.entries.form.displayName' },
  { path: 'compileSource.type', i18nKey: 'audit.entries.form.mime' },
  {
    path: 'conditions',
    i18nKey: 'audit.entries.form.conditions',
    transform: transformConditions,
    component: TmAuditBrItemsCellComponent,
  },
  { path: 'form2category', i18nKey: 'audit.entries.form.categories', transform: transformTech2Category },
];

export function getEtFormGridData(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  const cols = data.OPERATION === AuditEventOperation.compile ? [AuditGridDataColumn.new] : getDefaultColumns(data);
  const result: AuditExtendGridData[] = [];
  const baseData = getAuditExtendRowData({
    t,
    cols: cols,
    data: getAuditAttributesFrom(data),
    visibleAttributes: auditEtFormVisibleAttributes,
  });

  if (baseData.length) {
    result.push({
      cols,
      data: baseData,
    });
  }

  return result;
}

export function transformConditions(
  conditions: FingerprintDiffProperties['conditions'] | null | undefined,
  o: TransformOptions
): string[] {
  if (!conditions) {
    return [];
  }

  return conditions.map((c) => {
    const result: string[] = [];

    if (c.DISPLAY_NAME) {
      result.push(`${o.t.instant('audit.entries.form.conditionName')}: ${c.DISPLAY_NAME}`);
    }

    if (c.VALUE) {
      result.push(`${o.t.instant('audit.entries.form.value')}: ${c.VALUE}`);
    }

    if (c.MIN_ROWS) {
      result.push(`${o.t.instant('audit.entries.form.minRows')}: ${c.MIN_ROWS}`);
    }

    return result.join(', ');
  });
}
