<iw-modal-header>{{ 'settings-access.user.changePassword' | translate }} "{{ userName }}"</iw-modal-header>
<div>{{ 'settings-access.user.form.passwordChangeWarning' | translate }}</div>

<iw-form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <iw-form-block>
    <!-- PASSWORD -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.user.form.password' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <input
          formControlName="PASSWORD"
          iwInput
          type="password"
          autocomplete="off"
          name="PASSWORD"
          placeholder="{{ 'settings-access.user.form.placeholders.password' | translate }}"
        />
        <div iwErrorHints *ngFor="let error of getErrors('PASSWORD')">
          {{ 'settings-access.validation.password.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>

    <!-- PASSWORD CONFIRMATION -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.user.form.passwordConfirmation' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <input
          formControlName="PASSWORD_CONFIRMATION"
          iwInput
          type="password"
          autocomplete="off"
          name="PASSWORD_CONFIRMATION"
          placeholder="{{ 'settings-access.user.form.placeholders.passwordConfirmation' | translate }}"
        />
        <div iwErrorHints *ngFor="let error of getErrors('PASSWORD_CONFIRMATION')">
          {{ 'settings-access.validation.password.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>
  </iw-form-block>

  <iw-form-toolbar>
    <button iw-button type="submit" color="success" [disabled]="!form.valid">
      {{ 'settings-access.user.form.save' | translate }}
    </button>
    <button iw-button type="button" (click)="onCancel.emit()">
      {{ 'settings-access.user.form.cancel' | translate }}
    </button>
  </iw-form-toolbar>
</iw-form>
