import { Routes } from '@angular/router';
import { CanDeactivateTmForm } from '@tm-shared/form';
import { ACCESS_PATH, CREATE_PATH, EDIT_PATH, ROLE_PATH, SCOPE_PATH, USER_PATH } from './access-exports';
import { AccessComponent } from './access.component';
import { RoleComponent, RoleFormComponent } from './role';
import { ScopeComponent, ScopeFormComponent } from './scope';
import { ScopeFormResolver } from './scope/scope-form.resolver';
import { UserComponent, UserEditComponent, UserFormResolver } from './user';

export const ROUTES: Routes = [
  {
    path: ACCESS_PATH,
    component: AccessComponent,
    children: [
      {
        path: '',
        redirectTo: USER_PATH,
        pathMatch: 'full',
      },
      {
        path: USER_PATH,
        component: UserComponent,
        children: [
          {
            path: CREATE_PATH,
            component: UserEditComponent,
            resolve: { model: UserFormResolver },
            canDeactivate: [CanDeactivateTmForm],
          },
          {
            path: EDIT_PATH,
            component: UserEditComponent,
            resolve: { model: UserFormResolver },
            canDeactivate: [CanDeactivateTmForm],
            data: {
              usePrivilege: 'settings/access/user/show',
            },
          },
        ],
      },
      {
        path: ROLE_PATH,
        component: RoleComponent,
        children: [
          {
            path: CREATE_PATH,
            component: RoleFormComponent,
            canDeactivate: [CanDeactivateTmForm],
          },
          {
            path: EDIT_PATH,
            component: RoleFormComponent,
            canDeactivate: [CanDeactivateTmForm],
            data: {
              usePrivilege: 'settings/access/role/show',
            },
          },
        ],
      },
      {
        path: SCOPE_PATH,
        component: ScopeComponent,
        children: [
          {
            path: CREATE_PATH,
            component: ScopeFormComponent,
            resolve: { model: ScopeFormResolver },
            canDeactivate: [CanDeactivateTmForm],
          },
          {
            path: EDIT_PATH,
            component: ScopeFormComponent,
            resolve: { model: ScopeFormResolver },
            canDeactivate: [CanDeactivateTmForm],
            data: {
              usePrivilege: 'settings/access/scope/show',
            },
          },
        ],
      },
    ],
  },
];
