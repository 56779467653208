export const SCOPE_PATH: TmPluginAccess.router.CHILD_PATH = 'scope';
export const ROLE_PATH: TmPluginAccess.router.CHILD_PATH = 'role';
export const USER_PATH: TmPluginAccess.router.CHILD_PATH = 'user';
export const DEFAULT_PATH: TmPluginAccess.router.CHILD_PATH = USER_PATH;
export const CREATE_PATH: TmPluginAccess.router.FORM_PATH = 'edit';
export const EDIT_PATH: TmPluginAccess.router.FORM_PATH = 'edit/:id';
export const ACCESS_PATH = 'settings/access';
export const EMPTY_PATH = '';

export enum TmPluginAccessRoleType {
  Other = 0,
  Administrator = 3,
  Officer = 4,
}

export enum TmPluginAccessUserEndpoints {
  role = 'role',
  visibilityArea = 'visibilityArea',
}

export enum TmPluginAccessUserStatus {
  Active,
  Disable,
  Deleted,
}

export enum TmPluginAccessUserType {
  PreinstalledLinuxUser = 1,
  PreinstalledGuiUser = 2,
  PreinstalledAdministrator = 3,
  PreinstalledSecurityOfficer = 4,
  PluginToken = 5,
  NagiosUser = 6,
  AffiliateToken = 7,
  Other = 0,
}

export enum TmPluginAccessUserProvider {
  PASSWORD = 'PASSWORD',
  LDAP = 'LDAP',
}
