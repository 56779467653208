import { NgModule } from '@angular/core';
import { TmAuditItemComponent } from './audit-item.component';
import { TmAuditItemExtendedModule } from '../audit-item-extend/audit-item-extend.module';
import { TmLocalizeDateModule } from '@tm-shared/localize-date/localize-date.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, TmAuditItemExtendedModule, TmLocalizeDateModule],
  declarations: [TmAuditItemComponent],
  exports: [TmAuditItemComponent],
})
export class TmAuditItemModule {}
