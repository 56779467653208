import { TransformOptions, VisibleAttributesOption } from './audit-common';
import { TmAuditObjectTypeCellComponent } from '../audit-cell-components/audit-object-type-cell.component';
import { TmAuditBrItemsCellComponent } from '../audit-cell-components/audit-br-items.component';
import { TransferRuleData } from '../../../typings/generated/audit-policy-rule';
import { ApplicationSourceDestValue } from './audit-policy';
import { PolicyApplicationRule } from '../../../typings/generated/policy-from-schema';
import {
  transformDayValue,
  transformDirection,
  transformPluginAttributes,
  transformSourceDest,
  transformSourceDestRes,
  transformStatus,
} from './audit-policy-helpers';

export const visiblePolicyApplicationRuleAttributes: VisibleAttributesOption[] = [
  { path: 'STATUS', i18nKey: 'audit.auditPolicyRule.attributes.STATUS', transform: transformStatus },
  { path: 'DATA.DIRECTION', i18nKey: 'audit.auditPolicyRule.attributes.DIRECTION', transform: transformDirection },
  {
    path: 'DATA.EVENT_TYPE',
    i18nKey: 'audit.auditPolicyRule.attributes.EVENT_TYPE',
    transform: transformEventType,
  },
  {
    path: 'DATA.OBJECT_TYPE_CODE',
    i18nKey: 'audit.auditPolicyRule.attributes.OBJECT_TYPE_CODE',
    component: TmAuditObjectTypeCellComponent,
  },
  // Нужно написать отдельный обработчик для атрибутов плагинов
  {
    path: 'DATA.PluginAttributes',
    i18nKey: 'audit.auditPolicyRule.attributes.PluginAttributes',
    transform: transformPluginAttributes,
    component: TmAuditBrItemsCellComponent,
  },
  {
    path: 'DATA.SOURCE_RES',
    i18nKey: 'audit.auditPolicyRule.attributes.SOURCE_RES',
    transform: transformSourceDestRes,
  },
  { path: 'DATA.DEST_RES', i18nKey: 'audit.auditPolicyRule.attributes.DEST_RES', transform: transformSourceDestRes },
  { path: 'DATA.DAY', i18nKey: 'audit.auditPolicyRule.attributes.DAY', transform: transformDayValue },
  { path: 'DATA.APPLICATION_FROM', i18nKey: 'audit.auditPolicyRule.attributes.APPLICATION_FROM' },
  { path: 'DATA.APPLICATION_TO', i18nKey: 'audit.auditPolicyRule.attributes.APPLICATION_TO' },
  { path: 'DATA.START_TIME', i18nKey: 'audit.auditPolicyRule.attributes.START_TIME' },
  { path: 'DATA.END_TIME', i18nKey: 'audit.auditPolicyRule.attributes.END_TIME' },
  { path: 'DATA.TERMINAL_SESSION', i18nKey: 'audit.auditPolicyRule.attributes.TERMINAL_SESSION' },
  {
    path: 'DATA.SOURCE',
    i18nKey: 'audit.auditPolicyRule.attributes.SOURCE',
    transform: (data: ApplicationSourceDestValue, o) => transformSourceDest(data, o, 'CONDITION_SOURCE'),
  },
  {
    path: 'DATA.DEST',
    i18nKey: 'audit.auditPolicyRule.attributes.DEST',
    transform: (data: TransferRuleData['DEST'], o) => transformSourceDest(data, o, 'CONDITION_DEST'),
  },
  {
    path: 'DATA.WORKSTATION',
    i18nKey: 'audit.auditPolicyRule.attributes.WORKSTATION',
    transform: (data: ApplicationSourceDestValue, o) => transformSourceDest(data, o, 'CONDITION_WORKSTATION'),
  },
  {
    path: 'DATA.APPLICATION',
    i18nKey: 'audit.auditPolicyRule.attributes.APPLICATION',
    transform: (data: ApplicationSourceDestValue, o) => transformSourceDest(data, o, 'CONDITION_APPLICATION'),
  },
];

function transformEventType(
  value: PolicyApplicationRule['EVENT_TYPE'] | null | undefined,
  options: TransformOptions
): string {
  if (value === undefined || value === null) {
    return '';
  }

  if (value === 'clipboard') {
    return options.t.instant('audit.event_type.clipboard');
  }
  if (value === 'keylogger') {
    return options.t.instant('audit.event_type.keylogger');
  }

  return '';
}
