import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService, ModalDialogRef } from '@platform/shared';
import { ModalConfirmComponent } from '@tm-shared/modals';

@Component({
  templateUrl: './role-delete-modal.component.html',
  styleUrls: ['role-delete-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleDeleteModalComponent extends ModalConfirmComponent implements OnInit {
  @Input() public roles: TmApi.role.CollectionItem[];
  public rolesToDelete: string;

  constructor(_t: TranslateService, _dialog: ModalDialogRef<unknown>, private _notify: IwNotificationsService) {
    super(_t, _dialog);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const used = this._getUsedRoles();
    this.rolesToDelete = this.roles
      .filter((role) => {
        return !used.find((usedRole) => usedRole.ROLE_ID === role.ROLE_ID);
      })
      .map((role) => role.DISPLAY_NAME)
      .join(', ');

    if (used.length) {
      this._notify.info(
        this._t.instant('settings-access.role.usedInUsers'),
        used.map((role) => role.DISPLAY_NAME).join(', ')
      );
    }
  }

  private _getUsedRoles(): TmApi.role.CollectionItem[] {
    return this.roles.filter((role) => role.users.length);
  }
}
