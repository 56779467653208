import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TmContentComponent } from './content';
import { TmPageComponent } from './page';
import { TmSidebarComponent } from './sidebar';
import { TmSidebarService } from './sidebar/sidebar.service';
import { TmTabComponent, TmTabsetComponent } from './tabs';

@NgModule({
  imports: [CommonModule],
  exports: [TmContentComponent, TmSidebarComponent, TmPageComponent, TmTabsetComponent, TmTabComponent],
  declarations: [TmContentComponent, TmSidebarComponent, TmPageComponent, TmTabsetComponent, TmTabComponent],
  providers: [TmSidebarService],
})
export class TmStructureModule {}
