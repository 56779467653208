type Params = { [key: string]: string };

export function param(obj: any, prefix?: string): string {
  if (!Object.keys(obj).length) {
    return '';
  }

  let str: string[] = [];

  for (let item in obj) {
    if (obj.hasOwnProperty(item)) {
      let key = prefix ? `${prefix}[${item}]` : item;
      let value = obj[item];

      if (value !== null && typeof value === 'object') {
        str.push(param(value, key));
      } else {
        str.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  }

  return str.join('&');
}

export function paramParse(paramStr: string): Params {
  return decodeURIComponent(paramStr)
    .split('?')
    .pop()!
    .split('&')
    .reduce((result: Params, paramPair) => {
      let [key, value] = paramPair.split('=');

      result[key] = value;

      return result;
    }, {});
}

export function urlParse(url: string): [string, Params] {
  let params = {};
  let splitOn = url.indexOf('?');

  if (splitOn === -1) {
    return [url, params];
  }

  return [url.slice(0, splitOn), paramParse(url.slice(splitOn + 1, url.length))];
}
