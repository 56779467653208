import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TmConfigDiffComponentService } from '../configdiff/configdiff-component.service';
import { TmConfigDiffService } from '../services';

type MixedItems = (TmPluginConfig.diff.BaseItem | TmPluginConfig.diff.DetailedItem)[];

@Injectable()
export class TmConfigDiffPageService {
  /**
   * ConfigDiff collection
   */
  public data$: Observable<MixedItems> = of(null).pipe(
    switchMap(() => combineLatest(this._dataCollection$, this._dataDetailed$)),
    map(([collection, detailed]) => this._mergeData(collection, detailed))
  );

  private _dataCollection$: Observable<
    TmPluginConfig.diff.BaseItem[]
  > = this._configDiff
    .getDataWithUpdatesStream()
    .pipe(map((data) => data.map((item) => this._configDiffComponent.parseCollectionItem(item))));

  private _dataDetailed$: BehaviorSubject<TmPluginConfig.diff.DetailedItem[]> = new BehaviorSubject([]);

  constructor(private _configDiff: TmConfigDiffService, private _configDiffComponent: TmConfigDiffComponentService) {}

  public fetchDetailed(id: TmPluginConfig.diff.ItemId): void {
    // TODO: выпилить any
    this._configDiff.getById(id).subscribe((data: any) => {
      this._setDetailedData(id, data);
    });
  }

  private _setDetailedData(id: TmPluginConfig.diff.ItemId, data: TmApi.configDiff.DetailedItem[]): void {
    const current = this._dataDetailed$.getValue();
    const itemDeserialized = this._configDiffComponent.parseDetailedItem(id, data[0]);
    const index = current.findIndex((item) => item.id === id);
    let nextData: TmPluginConfig.diff.DetailedItem[] = [];

    if (index === -1) {
      nextData = current.concat(itemDeserialized);
    } else {
      nextData = nextData.concat(current);
      nextData.splice(index, 1, itemDeserialized);
    }

    this._dataDetailed$.next(nextData);
  }

  /**
   * Merge detailed data to collection when it's available
   */
  private _mergeData(
    collection: TmPluginConfig.diff.BaseItem[],
    detailed: TmPluginConfig.diff.DetailedItem[]
  ): MixedItems {
    let result = ([] as MixedItems).concat(collection);

    detailed.forEach((detailedItem) => {
      let i = collection.findIndex((item) => item.id === detailedItem.id);
      i === -1 ? result.push(detailedItem) : result.splice(i, 1, detailedItem);
    });

    return result;
  }
}
