import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { TmFormComponent } from '@tm-shared/form';
import { PATTERN_STRING_1000 } from '@tm-shared/helpers/patterns';
import { merge } from 'rxjs';
import { delay, take, takeUntil } from 'rxjs/operators';
import { DocumentGet } from 'typings/generated/technology-autoling';
import { ModalDialogRef } from '@platform/shared';

export type TmAutolingDocumentEditData = Pick<DocumentGet, 'DISPLAY_NAME' | 'NOTE' | 'FINGERPRINT_ID'>;

@Component({
  selector: 'tm-autoling-document-edit',
  templateUrl: 'autoling-document-edit.component.html',
  styleUrls: ['./autoling-document-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmAutolingDocumentEditComponent extends TmFormComponent<FormGroup> implements OnInit {
  @Input() public set data(data: TmAutolingDocumentEditData) {
    this.form.reset(data);
  }

  constructor(private _fb: FormBuilder, private _dialog: ModalDialogRef<unknown>) {
    super();

    this.form = this._fb.group({
      DISPLAY_NAME: [null, [Validators.required, Validators.maxLength(256)]],
      NOTE: [null, [Validators.pattern(PATTERN_STRING_1000), Validators.maxLength(1000)]],
    });
  }

  public ngOnInit(): void {
    merge(this.submit$, this.close$)
      .pipe(
        take(1),
        takeUntil(this._destroyed$),
        // Dismiss after decision emits
        delay(0)
      )
      .subscribe(() => {
        this._dialog.dismiss(null);
      });
  }
}
