export const EVENT_QUERY_COLUMNS_ORDERED: TmPluginEvents.query.ColumnPredefined[] = [
  { type: 'attribute', key: 'SUBJECT' },
  { type: 'attribute', key: 'FILE_NAME', sortable: true },
  { type: 'attribute', key: 'VIOLATION_LEVEL', sortable: true },
  { type: 'attribute', key: 'USER_DECISION', sortable: true },
  { type: 'attribute', key: 'VERDICT', sortable: true },
  { type: 'attribute', key: 'OBJECT_TYPE_CODE', sortable: true },
  { type: 'attribute', key: 'FORWARD_STATE_CODE', sortable: true },
  { type: 'attribute', key: 'OBJECT_ID', sortable: true },
  { type: 'related_entity', key: 'senders', sortable: true },
  { type: 'related_entity', key: 'recipients', sortable: true },
  { type: 'related_entity', key: 'workstations', sortable: true },
  { type: 'related_entity', key: 'workstation_type' },
  { type: 'related_entity', key: 'protected_documents', sortable: true },
  { type: 'attribute', key: 'ATTACHMENT_COUNT', sortable: true },
  { type: 'attribute', key: 'APPLICATION_FROM', sortable: true },
  { type: 'attribute', key: 'APPLICATION_TO', sortable: true },
  { type: 'attribute', key: 'CAPTURE_DATE', sortable: true },
  { type: 'attribute', key: 'SENT_DATE', sortable: true },
  { type: 'attribute', key: 'OBJECT_SIZE', sortable: true },
  { type: 'related_entity', key: 'policies', sortable: true },
  { type: 'attribute', key: 'RULE_GROUP_TYPE', sortable: true },
  { type: 'related_entity', key: 'lists', sortable: true },
  { type: 'attribute', key: 'CAPTURE_SERVER_HOSTNAME', sortable: true },
  { type: 'related_entity', key: 'tags', sortable: true },
  { type: 'attribute', key: 'OBJECT_ERROR_EXISTS', sortable: true },
  { type: 'header', key: 'task_name' },
  { type: 'header', key: 'create_date' },
  { type: 'header', key: 'modify_date' },
  { type: 'header', key: 'task_run_date' },
  { type: 'attribute', key: 'DESTINATION_PATH', sortable: true },
  { type: 'attribute', key: 'DESTINATION_ID', sortable: true },
  { type: 'attribute', key: 'DEVICE_NAME', name: 'DESTINATION_NAME', sortable: true },
  { type: 'attribute', key: 'DESTINATION_TYPE', sortable: true },
  { type: 'attribute', key: 'SOURCE_TYPE', sortable: true },
  { type: 'attribute', key: 'SOURCE_ID', sortable: true },
  { type: 'attribute', key: 'SOURCE_NAME', sortable: true },
  { type: 'attribute', key: 'SOURCE_PATH', sortable: true },
  { type: 'attribute', key: 'WEB_RESOURCE', sortable: true },
  { type: 'attribute', key: 'TEXT', sortable: true },
  { type: 'attribute', key: 'SCANNING_TARGET' },
];
