import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AbstractGridApiAdapter, GetDataResponse, ParsedParams } from './abstract-grid-api-adapter';

@Injectable()
export class GridLocalApiService extends AbstractGridApiAdapter {
  public idAttribute: string;
  private _data: any[];

  public setData(data: any[]) {
    this._data = data;
  }

  protected getData(
    parsedParams: ParsedParams,
    _retryDelayMs?: number,
    _retryLimit?: number
  ): Observable<GetDataResponse> {
    let data = this._applyAllFilters(this._data, parsedParams.filter);
    const length = data.length;
    data = this._sliceByPage(data, parsedParams.start, parsedParams.limit);
    return of({
      data: data,
      itemsLengthAfterFilter: length,
    });
  }
}
