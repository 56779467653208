<iw-form [formGroup]="filtersForm">
  <label class="input-group-title">
    {{ 'audit.eventFilter.query' | translate }}
    <tm-icon svgIcon="help" title="{{ 'audit.eventFilter.help' | translate }}"></tm-icon>
  </label>
  <input
    class="tm-input"
    formControlName="query"
    placeholder="{{ 'audit.eventFilter.inputPlaceholder' | translate }}"
  />
  <hr style="margin-bottom: 0;" />

  <iw-form-block [title]="'audit.eventFilter.title' | translate">
    <!-- Filter: user -->
    <iw-select
      data-qa="userInput"
      class="filterRow"
      formControlName="user"
      placeholder="{{ 'audit.eventFilter.anyUserOption' | translate }}"
      [searchable]="true"
      [items]="userFilterOptions"
      [labelTemplate]="userFilterOption"
    >
    </iw-select>

    <ng-template #userFilterOption let-item="item">
      <div class="value-wrapper default" [class.disabled]="item.disabled">
        <span class="value-label"> {{ 'audit.eventFilter.userOptionPrefix' | translate }}: {{ item.label }} </span>
      </div>
    </ng-template>

    <!-- Filter: operation (action) -->
    <iw-select
      data-qa="actionInput"
      class="filterRow"
      formControlName="action"
      placeholder="{{ 'audit.eventFilter.anyActionOption' | translate }}"
      [searchable]="true"
      [items]="actionFilterOptions"
      [labelTemplate]="actionFilterOption"
    >
    </iw-select>

    <ng-template #actionFilterOption let-item="item">
      <div class="value-wrapper default" [class.disabled]="item.disabled">
        <span class="value-label"> {{ 'audit.eventFilter.actionOptionPrefix' | translate }}: {{ item.label }} </span>
      </div>
    </ng-template>

    <!-- Filter: entity type -->
    <iw-select
      data-qa="entityInput"
      class="filterRow"
      formControlName="entityType"
      placeholder="{{ 'audit.eventFilter.anyEntityTypeOption' | translate }}"
      [searchable]="true"
      [items]="entityTypeFilterOptions"
      [labelTemplate]="entityFilterOption"
    >
    </iw-select>

    <ng-template #entityFilterOption let-item="item">
      <div class="value-wrapper default" [class.disabled]="item.disabled">
        <span class="value-label">
          {{ 'audit.eventFilter.entityTypeOptionPrefix' | translate }}: {{ item.label }}
        </span>
      </div>
    </ng-template>

    <tm-date-range
      [availableModes]="dateRangeModes"
      [allowTimeSelection]="allowTimeSelection"
      formControlName="dateRange"
    ></tm-date-range>
  </iw-form-block>
</iw-form>
<hr />

<iw-form [formGroup]="settingsForm" *tmCan="'settings/audit_events/audit_event:edit_duration'">
  <iw-form-block [title]="'audit.eventFilter.retentionSettingsTitle' | translate">
    <iw-input-container>
      <input data-qa="auditRetentionInput" iwInput type="number" formControlName="retention" />
    </iw-input-container>
  </iw-form-block>
</iw-form>
