import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { TmResourcesGroupService } from '@tm-shared/api-services';
import { TmFormComponent } from '@tm-shared/form';
import { TmAsyncValidatorsService } from '@tm-shared/helpers';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { EMPTY, Observable, combineLatest, merge, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
  switchMapTo,
  take,
  takeUntil,
  tap,
  finalize,
} from 'rxjs/operators';
import { SystemList } from '../generated/resources-group';
import { RESOURCES, TREE_ROOT, getPrivilegeRequest } from '../resources.model';
import { ResourcesFormService } from './resources-form.service';
import { ResourcesService } from '../resources-component/resources.service';

@Component({
  selector: 'tm-resources-form',
  templateUrl: './resources-form.component.html',
  styleUrls: ['./resources-form.component.scss'],
  providers: [TmAsyncValidatorsService, ResourcesFormService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesFormComponent extends TmFormComponent<FormGroup> implements OnInit {
  public title: string;
  public confirmDataLoss = false;
  public createDate: string;
  public changeDate: string;
  public resourcesId$: Observable<string | number>;
  public isNew$: Observable<boolean>;

  /**
   * Stream: Form data
   */
  private _data$: Observable<SystemList>;

  private _canEditResources$: Observable<boolean> = this._privileges
    .can(getPrivilegeRequest('edit'))
    .pipe(take(1), shareReplay(1));

  constructor(
    public service: TmResourcesGroupService,
    private _notify: IwNotificationsService,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    private _pageService: ResourcesService,
    private _t: TranslateService,
    private _privileges: TmPrivilegesService,
    private _resourcesFormService: ResourcesFormService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = this._resourcesFormService.createResourcesFormGroup(this.resourcesId$);

    this._data$ = this.resourcesId$.pipe(
      switchMap((id) => {
        return id ? this.service.getById(id) : EMPTY;
      }),
      filter((data) => (data ? true : (this.close(), false))),
      takeUntil(this._destroyed$)
    );
    this._data$
      .pipe(
        switchMap((data) => combineLatest(of(data), this._canEditResources$).pipe(take(1))),
        takeUntil(this._destroyed$)
      )
      .subscribe(([data, canEditResources]) => {
        this.form.reset();
        this.form.patchValue(data);
        if (data.CREATE_DATE) {
          this.createDate = data.CREATE_DATE;
        }
        if (data.CHANGE_DATE) {
          this.changeDate = data.CHANGE_DATE;
        }

        this._resourcesFormService.handleFormDisableState(this.form, canEditResources);
      });

    this.isNew$ = this.resourcesId$.pipe(
      map((id) => {
        return id === TREE_ROOT;
      })
    );

    merge(this.close$, this.submit$)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this.resourcesId$
          .pipe(
            tap((resourcesId) => {
              this._router.navigate([RESOURCES, resourcesId ? resourcesId : TREE_ROOT], {
                relativeTo: this._activatedRoute,
                queryParamsHandling: 'merge',
              });
            })
          )
          .subscribe();
      });

    this._watchIsNewToChangeTitle();
  }

  /**
   * Save form data
   */
  protected _onSubmit() {
    const serverData: SystemList = this.form.getRawValue();

    return this.service.createOrUpdate(serverData).pipe(
      catchError((response) => {
        this._notify.error(
          this._t.instant('lists-resources.errors.error'),
          this._t.instant('lists-resources.errors.request-failed')
        );
        return throwError(response);
      }),
      finalize(() => {
        this._pageService.updateTree();
        this.close(true);
      }),
      takeUntil(this._destroyed$)
    );
  }

  private _watchIsNewToChangeTitle() {
    merge(this.isNew$, this._t.onLangChange.pipe(switchMapTo(this.isNew$)))
      .pipe(takeUntil(this._destroyed$))
      .subscribe((isNew) => {
        let title;

        if (isNew) {
          title = this._t.instant('lists-resources.group.form.title-create-new');
        } else {
          title = this._t.instant('lists-resources.group.form.title-edit');
        }

        this.title = title;
      });
  }
}
