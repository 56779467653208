import { Injectable } from '@angular/core';
import { SCOPES } from '@tm-shared/api';
import { TmBookwormContactTypeService } from '@tm-shared/bookworm';

@Injectable()
export class SearchSelectService {
  constructor(private _contactType: TmBookwormContactTypeService) {}

  public getSelectedByScopes(value: any): TmApi.search.ScopesWithIds {
    return value ? this._parseValue(value) : {};
  }

  /**
   * get skype, telegram, facebook, etc..
   * @param value
   */
  public getSelectedOutOfScopesItems(value: any[]): TmApi.scope.ConditionValueObject[] {
    if (value) {
      return value
        .map((item) => (item.data ? item.data : item))
        .filter((item: TmApi.scope.ConditionValueObject) => !SCOPES.includes(item.TYPE as any));
    }
    return [];
  }

  public mapToIwSelect(items: any[]): IwSelectItem[] | undefined {
    if (items) {
      return items.map((item) => {
        return {
          value: item,
          data: item,
          label: item.NAME,
        };
      });
    }
  }

  public getIconByType(type: string) {
    let iconName = this._contactType.getIconNameByMnemo(type);
    if (iconName) {
      return iconName;
    }

    switch (type) {
      case 'person':
        iconName = 'sid';
        break;
      case 'tag':
      case 'policy':
      case 'document':
      case 'catalog':
        iconName = '';
        break;
      default:
        iconName = type;
    }
    return iconName;
  }

  /**
   * get translated icon name
   */
  public getSuggestionByMnemo(mnemo: string, additionalItems: TmApi.ContactType.CollectionItem[]) {
    const contactItem = additionalItems.find((item) => item.mnemo === mnemo);
    return contactItem ? contactItem.name : null;
  }

  public addAdditionalItems(
    currentItems: any[] | undefined,
    query: string,
    additionalItems: TmApi.ContactType.CollectionItem[]
  ) {
    if (additionalItems && currentItems) {
      const possibleItems = additionalItems.map((item) => ({
        TYPE: item.mnemo,
        KEY: item.mnemo,
        NAME: query,
        DATA: query,
      }));
      currentItems.push(...this.mapToIwSelect(possibleItems));
    }
    return currentItems;
  }

  private _parseValue(value: any[]) {
    const dataForModal: { [key: string]: string[] } = {};
    value.forEach((item) => {
      const itemData: TmApi.scope.ConditionValueObject = item.data ? item.data : item;
      if (!dataForModal[itemData.TYPE]) {
        dataForModal[itemData.TYPE] = [];
      }
      dataForModal[itemData.TYPE].push(itemData.DATA);
    });
    return dataForModal;
  }
}
