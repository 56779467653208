import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TmCommonErrorsInterceptor } from './tm-errors.interceptor';
/**
 * Support TM XSRF protection mechanism for jQuery (TMBB)
 */
@Injectable()
export class TmCommonErrorsAjaxInterceptor extends TmCommonErrorsInterceptor {
  public beforeSend(xhrReq: any, settings: { url: string }) {
    if (this._checkUrl(settings.url)) {
      xhrReq.fail((xhrRes: any) => {
        this._handleError(
          new HttpErrorResponse({
            error: xhrRes.responseJSON,
            status: xhrRes.status,
          })
        );
      });
    }
  }
}
