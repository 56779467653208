<div [ngSwitch]="params?.data?.STATUS">
  <ng-template [ngSwitchCase]="STATUSES.NEW">
    {{ 'events.exportMonitor.status_executing' | translate }} {{ params?.data?.PERCENT_COMPLETE ? '—' : '' }}
    <i>SPINNER</i>
    {{ params?.data?.PERCENT_COMPLETE }}{{ params?.data?.PERCENT_COMPLETE ? '%' : '' }}
  </ng-template>
  <ng-template [ngSwitchCase]="STATUSES.COMPLETE">
    {{ 'events.exportMonitor.status_ready' | translate }}
    <span *ngFor="let file of formatFiles(); let last = last">
      <a [href]="file.href" target="_blank" rel="noreferrer noopener">{{ file.text }}</a>
      {{ last ? '' : ('events.exportMonitor.or' | translate) }}
    </span>
  </ng-template>
  <ng-template [ngSwitchCase]="STATUSES.ERROR">
    {{ 'events.exportMonitor.status_error' | translate }}
  </ng-template>
  <ng-template [ngSwitchCase]="STATUSES.EXECUTING">
    {{ 'events.exportMonitor.status_executing' | translate }} {{ params?.data?.PERCENT_COMPLETE ? '—' : '' }}
    <i class="icon _spinner"></i>
    {{ params?.data?.PERCENT_COMPLETE }}{{ params?.data?.PERCENT_COMPLETE ? '%' : '' }}
  </ng-template>
</div>
