import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Implement BarWidget getBars method
 */
@Component({
  selector: 'tm-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmPanelComponent {
  @Input() public type: 'info' | 'success' | 'warn' | 'error' = 'info';

  @Input() public title = '';

  @Input() public text = '';
}
