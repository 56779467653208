import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TmPolicyApiService } from '@tm-shared/api-services/policy-api.service';
import { TmBookwormFormatTypesService } from '@tm-shared/bookworm';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmTreeComponent, TmTreeNodeData } from '@tm-shared/tree';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith, tap } from 'rxjs/operators';
import { TREE_ROOT } from '../files.model';

@Injectable()
export class FilesService {
  public formatTypes$ = this._getFormatTypes();
  public treeItems$ = this._getTreeItems();

  public canCreatePolicy$ = this._privileges.can('policy/policy_object/edit').pipe(shareReplay(1));

  constructor(
    private _formatTypes: TmBookwormFormatTypesService,
    private _policyApi: TmPolicyApiService,
    private _privileges: TmPrivilegesService,
    private _activatedRoute: ActivatedRoute,
    private _t: TranslateService
  ) {}

  public selectCurrentTreeNode(treeComponent: TmTreeComponent, items?: TmTreeNodeData[]): void {
    if (this._activatedRoute.firstChild && this._activatedRoute.firstChild.snapshot.params['id']) {
      treeComponent.activateById(this._activatedRoute.firstChild.snapshot.params['id']);
    } else if (items && items.length) {
      treeComponent.activateById(items[0].id);
    }
  }

  public createPolicyAndNavigate(policyType: 'OBJECT' | 'AGENT', idsToSelect: (string | number)[]) {
    let policy = this._policyApi.getEmptyPolicyObject(policyType as any);
    const data$ = this._formatTypes.getDataStream().pipe(
      map((response) => {
        return response.data.filter((item) => idsToSelect.includes(item.format_type_id));
      }),
      map((items) => {
        const itemsForPolicy = items.map((item) => {
          return {
            ID: item.format_type_id,
            TYPE: 'filetype',
            NAME: item.name,
          };
        });
        this._policyApi.setItemsToPolicy(policy, itemsForPolicy);
        return policy;
      })
    );
    this._policyApi.createPolicyAndNavigate(data$);
  }

  private _getFormatTypes() {
    return this._formatTypes.getDataStream().pipe(
      tap((response) => {
        response.data.sort((a, b) => (a.name > b.name ? 1 : -1));
      }),
      shareReplay(1)
    );
  }

  private _getTreeItems(): Observable<TmTreeNodeData[]> {
    const ROOT = {
      id: TREE_ROOT,
      name: this._t.instant('lists-files.tree.all-items'),
      isExpanded: true,
      children: [] as any[],
    };
    return this.formatTypes$.pipe(
      map((response) => {
        ROOT.children = response.data.map((item) => {
          return {
            id: item.format_type_id,
            name: item.name,
          };
        });
        return [ROOT];
      }),
      startWith([ROOT])
    );
  }
}
