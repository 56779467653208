import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { Subject } from 'rxjs';
import { DocumentGet } from 'typings/generated/technology-autoling';

@Injectable()
export class TmAutolingDocumentService extends TmCollectionLoader<DocumentGet> {
  public src = '/api/autoling/document';

  public idAttribute = 'FINGERPRINT_ID';

  public dataChanged$ = new Subject<void>();
}
