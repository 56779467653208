export const DEFAULT_LIST_TYPE = 'web_type';

export const TREE_ROOT = 'root';
export const CREATE_GROUP_PATH = `:edit/${TREE_ROOT}`;

// Приходится писать как :edit, потому что, во-первых роутинг ангуляра
// не воспринимает children руты и мы не можем сделать нормально
// ```
//   {
//     path: 'edit',
//     component: ResourcesTableComponent,
//     data: {
//       usePrivilege: 'lists/resources/resource'
//     },
//     children: [
//       {
//         path: '',
//         pathMatch: 'full',
//         component: ResourcesTableComponent,
//         data: {
//           usePrivilege: 'lists/resources/resource'
//         }
//       },
//       {
//         path: ':id',
//         component: ResourcesTableComponent,
//         data: {
//           usePrivilege: 'lists/resources/resource'
//         }
//       }
//     ]
//   }
// ```
// , а во вторых чтобы не парсить адресную строку в родительском компоненте
export const EDIT_GROUP_PATH = ':edit/:id';

export const CREATE_ITEM_PATH = `:id/:edit_item/${TREE_ROOT}`;
export const EDIT_ITEM_PATH = ':id/:edit_item/:item_id';

export const RESOURCES = 'lists/resources';

export const MODULE_KEY = 'lists:resources/resource';

export function getPrivilegeRequest(key: string) {
  return `${MODULE_KEY}:${key}`;
}
