<h4>{{ actionText }} {{ entityText }} "{{ data.name }}"</h4>
<p *ngIf="configText || dateText">{{ configText }} {{ dateText }}</p>
<p *ngIf="data.completion === 'available'" class="available">
  {{ 'config.diff.item.completion.available' | translate }}
</p>
<p *ngIf="data.completion === 'required'" class="required">
  {{ 'config.diff.item.completion.required' | translate }}
</p>
<button
  iw-button
  type="button"
  data-qa="showDetailedData"
  [progress]="loadingDetailed"
  [disabled]="loadingDetailed"
  (click)="toggleExpand()"
>
  {{ expanded ? ('config.diff.item.collapse' | translate) : ('config.diff.item.expand' | translate) }}
</button>

<tm-configdiff-item-details *ngIf="expanded && dataDetailed" [data]="dataDetailed"></tm-configdiff-item-details>
