import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Optional } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmStructureModule } from '@tm-shared/structure';
import { TmConfigElementsModule } from './config-elements.module';
import { ROUTER_CONFIG_DIFF_PAGE } from './config-exports';
import { TM_PLUGIN_CONFIG_ROUTES } from './config.routes';
import { TmConfigDiffPageComponent } from './configdiff-page/configdiff-page.component';
import { TmConfigDiffPageService } from './configdiff-page/configdiff-page.service';
import { TmConfigAffiliateService, TmConfigDiffService, TmConfigLocalService, TmConfigLogService } from './services';
import { TmConfigWidgetService } from './config-widget/config-widget.service';
import { TmIconModule } from '../../@tm-shared/icons/tm-icon.module';

const ru = require('./i18n/config.ru.json');
const en = require('./i18n/config.en.json');

const LEGACY_PROVIDERS = [
  { provide: 'TmConfigLocalService', useClass: TmConfigLocalService },
  { provide: 'TmConfigAffiliateService', useClass: TmConfigAffiliateService },
  { provide: 'TmConfigLogService', useClass: TmConfigLogService },
  { provide: 'TmConfigDiffService', useClass: TmConfigDiffService },
];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    TmConfigElementsModule,
    TranslateModule,
    TmStructureModule,
    RouterModule.forChild(TM_PLUGIN_CONFIG_ROUTES),
    IwSharedModule,
    TmIconModule,
    TmConfigElementsModule,
  ],
  providers: [
    ...LEGACY_PROVIDERS,
    TmConfigLocalService,
    TmConfigAffiliateService,
    TmConfigLogService,
    TmConfigDiffService,
    TmConfigDiffPageService,
  ],
  declarations: [TmConfigDiffPageComponent],
})
export class TmConfigModule {
  constructor(_t: TranslateService, private _router: Router, @Optional() _widgetService: TmConfigWidgetService) {
    _t.setTranslation('ru', ru, true);
    _t.setTranslation('en', en, true);

    if (_widgetService) {
      _widgetService.connect();
      _widgetService.goToConfigDiffPage$.subscribe(() => this._router.navigate([ROUTER_CONFIG_DIFF_PAGE]));
    }
  }
}
