import { Injectable } from '@angular/core';

@Injectable()
export class TmConfigDiffComponentService implements TmPluginConfig.diff.DiffComponentService {
  private _renderers: Map<TmPluginConfig.Entity, string> = new Map();

  public getRenderer(entity: TmPluginConfig.Entity): string | undefined {
    return this._renderers.get(entity);
  }

  public parseCollectionItem(item: TmApi.configDiff.CollectionItem): TmPluginConfig.diff.BaseItem {
    return {
      name: item.DISPLAY_NAME,
      action: item.OPERATION,
      id: item.ID,
      entity: item.ENTITY,
    };
  }

  public parseDetailedItem(
    id: TmPluginConfig.diff.ItemId,
    data: TmApi.configDiff.DetailedItem
  ): TmPluginConfig.diff.DetailedItem {
    return {
      name: data.DISPLAY_NAME,
      action: data.OPERATION,
      entity: data.ENTITY,
      id: id,
      details: data.FIELDS,
    };
  }

  public setRenderer(entity: TmPluginConfig.Entity, customElementTag: string): void {
    this._renderers.set(entity, customElementTag);
  }
}
