import { InjectionToken } from '@angular/core';

export const CategoryService = new InjectionToken('CategoryService');
export const FingerprintService = new InjectionToken('FingerprintService');
export const TrainingService = new InjectionToken('TrainingService');
export const CheckService = new InjectionToken('CheckService');
export const ClassifierTypeToken = 'ClassifierTypeToken';
export enum ClassifierType {
  autoling = 'autoling',
  graphicImageClassifier = 'image-classifier',
}
