import { DateTime } from 'luxon';

type WeekdayName = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
const weekdays: WeekdayName[] = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export function getTimestamp(unit: 's' | 'ms' = 'ms'): number {
  return unit === 'ms' ? Date.now() : Math.floor(Date.now() / 1000);
}

/**
 * Get name for day by index:
 * 0 and 7 is Sunday
 */
export function getWeekdayNameByIndex(dayIndex: number): WeekdayName {
  return weekdays[dayIndex];
}

/**
 * Get localized weekday
 *
 * @example
 * // translate Wednesday to Russian lang:
 * getLocalizedWeekday('wed', 'ru');
 */
export function localizeWeekdayName(shortName: string, targetLang: string, format: 'short' | 'long' = 'short') {
  // Store relative date
  const now = new Date();

  // Calculate necessary offset of the desired date
  let targetIndex = weekdays.indexOf(shortName.toLowerCase() as WeekdayName);
  const todayIndex = weekdays.indexOf(getWeekdayNameByIndex(new Date().getDay()));

  targetIndex += targetIndex < todayIndex ? 7 : 0;

  // Rewind to necessary date
  now.setDate(now.getDate() + (targetIndex - todayIndex));

  return now.toLocaleDateString(targetLang, { weekday: format });
}

/**
 * Get time in format compatible with <input type="time" />
 * @example
 *
 * formatTimeForTimeInput(12, 30) // result: '12:30'
 * formatTimeForTimeInput(5, 30) // result: '05:30'
 */
export function formatTimeForTimeInput(hours: string | number, minutes: string | number): string {
  return (+hours < 10 ? `0${hours}` : hours) + ':' + (+minutes < 10 ? `0${minutes}` : minutes);
}

/**
 * Convert SQL date (which should have no timezone and be stored as UTC) to local DateTime.
 * Appropriate for most of the dates in TM backend API.
 */
export function sqlStringToDateTime(dateString: string): DateTime {
  return DateTime.fromSQL(dateString, { zone: 'utc' }).toLocal();
}
