import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TmAbstractXsrfInterceptor } from './tm-xsrf-abstract.interceptor';

/**
 * Support TM XSRF protection mechanism for Angular (TMNG)
 */
@Injectable()
export class TmHttpXsrfInterceptor extends TmAbstractXsrfInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;

    if (this._token && !req.headers.has(this.headerKey)) {
      authReq = req.clone({
        headers: req.headers.set(this.headerKey, this._token),
      });
    }

    return next.handle(authReq).pipe(
      tap((event) => this._storeToken(this._extractTokenFromHttpEvent(event))),
      catchError((event) => {
        this._storeToken(this._extractTokenFromHttpEvent(event));
        return throwError(event);
      })
    );
  }

  private _extractTokenFromHttpEvent(event: HttpEvent<any>): string | null {
    if (event instanceof HttpErrorResponse || event instanceof HttpResponse) {
      return event.headers.get(this.headerKey);
    }

    return null;
  }
}
