export enum DateRangeMode {
  none = 'all',
  fromTo = 'range',
  lastNHours = 'last_hours',
  lastNDays = 'last_days',
  thisDay = 'this_day',
  thisWeek = 'this_week',
  thisMonth = 'this_month',
  last3Days = 'last_3_days',
  last7Days = 'last_7_days',
  last30Days = 'last_30_days',
}

export type FromToModes = DateRangeMode.fromTo;

export type VariableModes = DateRangeMode.lastNHours | DateRangeMode.lastNDays;

export type PredefinedModes =
  | DateRangeMode.none
  | DateRangeMode.thisDay
  | DateRangeMode.thisWeek
  | DateRangeMode.thisMonth
  | DateRangeMode.last3Days
  | DateRangeMode.last7Days
  | DateRangeMode.last30Days;

/**
 * Is calculated on backend in the moment of query execution
 */
export interface PredefinedRange {
  mode: PredefinedModes;
}

/**
 * Is calculated on backend in the moment of query execution
 */
export interface VariableRange {
  mode: VariableModes;
  value: number;
}

/**
 * Is fixed date range
 */
export interface FromToRange {
  mode: FromToModes;
  from: Date;
  to: Date;
}

export type DateRange = PredefinedRange | VariableRange | FromToRange;

/**
 * Backend default formats are below:
 */
export interface ServerPredefinedRange {
  interval: PredefinedModes;
}

export interface ServerVariableRange {
  number: number;
  interval: VariableModes;
}

export interface ServerFromToRange {
  start_date: string;
  end_date: string;
  interval: DateRangeMode.fromTo;
}

export type ServerRange = ServerPredefinedRange | ServerVariableRange | ServerFromToRange;
