import { Injectable } from '@angular/core';
import { createEventContentChain } from './event-content-chain';
import { ContentOrigin } from './event-type-values';

/**
 * TmEventsService should contain only common logic for events
 */
@Injectable()
export class TmEventsService {
  /**
   * Get event content chain, useful for heavy processing
   */
  public chainContent(content: TmPluginEvents.content.Content[]): TmPluginEvents.content.Chain {
    return createEventContentChain(content);
  }

  /**
   * Extract unidentified contact text from event (name, login or id)
   * PR: https://wiki.infowatch.ru/x/7Q3fBw
   */
  public getUnidentifiedContactText(event: { KEY: string; META: TmApi.ContactMeta }): string {
    return event.META ? event.META.display_name || event.META.login || event.KEY : event.KEY;
  }

  /**
   * Get origin bitmask by providing necessary flags
   *
   * @example
   * eventService.getOriginBitmask({ tm: true; dm: true }) // returns enum: ORIGIN_TMDM
   */
  public getOriginBitmask(origin: TmPluginEvents.content.OriginOptions): TmPluginEvents.content.ContentOriginBitmask {
    let result = 0;

    if (origin.dm) {
      // eslint-disable-next-line
      result = result | ContentOrigin.ORIGIN_DM;
    }

    if (origin.tm) {
      // eslint-disable-next-line
      result = result | ContentOrigin.ORIGIN_TM;
    }

    if (origin.papi) {
      // eslint-disable-next-line
      result = result | ContentOrigin.ORIGIN_PAPI;
    }

    return result;
  }

  /**
   * Extract supported origins from it's bitmask
   *
   * @example
   * eventService.getOriginBitmask(5) // returns { tm: true; dm: false; papi: true }
   */
  public getOriginFromBitmask(
    bitmask: TmPluginEvents.content.ContentOriginBitmask
  ): TmPluginEvents.content.OriginOptions {
    return {
      tm: this._checkOrigin(bitmask, ContentOrigin.ORIGIN_TM),
      dm: this._checkOrigin(bitmask, ContentOrigin.ORIGIN_DM),
      papi: this._checkOrigin(bitmask, ContentOrigin.ORIGIN_PAPI),
    };
  }

  /**
   * Check if originBitmask includes originValue
   */
  private _checkOrigin(
    originBitmask: TmPluginEvents.content.ContentOriginBitmask,
    originValue: TmPluginEvents.content.ContentOrigin
  ): boolean {
    // eslint-disable-next-line
    return Boolean(originBitmask & originValue);
  }
}
