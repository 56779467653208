import { Directive, Input, OnInit, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

/**
 * Small helper to track scroll event
 */
@Directive({ selector: '[tmScroll]' })
export class TmScrollDirective implements OnInit, OnDestroy {
  /**
   * Debounce time for scroll event in ms
   */
  @Input() public debounce: number | string = 500;

  /**
   * Emit debounced event
   */
  @Output() public tmScroll = new EventEmitter<HTMLElement>();

  private destroy = new Subject<void>();

  constructor(private elementRef: ElementRef) {}

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public ngOnInit(): void {
    fromEvent(this.elementRef.nativeElement, 'scroll')
      .pipe(takeUntil(this.destroy), debounceTime(+this.debounce))
      .subscribe(() => this.runScrollCallback(this.elementRef.nativeElement));
  }

  private runScrollCallback(el: HTMLElement): void {
    this.tmScroll.next(el);
  }
}
