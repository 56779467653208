<iw-modal-header>{{ title }}</iw-modal-header>
<div>
  {{ text }}:
  <p>
    <b *ngIf="preparedItemsToDisplay">
      {{ preparedItemsToDisplay }}
    </b>
  </p>
</div>
<iw-modal-footer>
  <button iw-button color="success" data-qa="confirmModalYES" (click)="decision.emit(true)">{{ yes }}</button>
  <button iw-button data-qa="confirmModalNO" (click)="decision.emit(false)">{{ no }}</button>
</iw-modal-footer>
