import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable()
export class TmLocalStorageService {
  public handleStorage$: Observable<StorageEvent> = of(null).pipe(switchMap(() => this._handleStorage$));

  private _handleStorage$: Subject<StorageEvent> = new Subject();

  constructor() {
    window.addEventListener('storage', (e) => this._handleStorage$.next(e));
  }

  /**
   * Get item localstorage by key
   */
  public getItem(key: string): any {
    let data;

    try {
      const item = localStorage.getItem(key);
      if (item) {
        data = JSON.parse(item);
      }
    } catch (e) {
      data = {};
    }

    return data;
  }

  /**
   * Set item localstorage by key
   */
  public setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Get handle storage by key
   */
  public getHandleStorageEvent(key: string): Observable<StorageEvent> {
    return this.handleStorage$.pipe(
      filter((item: StorageEvent) => {
        return item.key === key;
      })
    );
  }
}
