<iw-form *ngIf="form" class="form" [formGroup]="form" (ngSubmit)="submit()" iwFormSubmit>
  <iw-form-block class="content">
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.role.form.display_name' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <input iwInput type="text" formControlName="DISPLAY_NAME" [tmAutofocus]="data$" />

        <!--
          @translate settings-access.validation.required
          @translate settings-access.validation.empty
          @translate settings-access.validation.maxlength
          @translate settings-access.validation.validationError
        -->
        <div iwErrorHints *ngFor="let error of getErrors('DISPLAY_NAME')">
          {{ 'settings-access.validation.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>

    <tm-tree
      class="tree"
      [disabled]="!(editable$ | async)"
      [data]="treeData$ | async"
      [useCheckbox]="true"
      [useTriState]="true"
      [treeItemsWithLinks]="false"
    ></tm-tree>

    <label iwFormRowLabel>{{ 'settings-access.role.form.note' | translate }}</label>
    <iw-input-container iwFormRowContent>
      <textarea iwInput formControlName="NOTE" maxlength="4000"></textarea>

      <!--
        @translate settings-access.validation.maxlength
      -->
      <div iwErrorHints *ngFor="let error of getErrors('NOTE')">
        {{ 'settings-access.validation.' + error.key | translate: error.value }}
      </div>
    </iw-input-container>
    <div class="dates" *ngIf="createDate && changeDate">
      {{ 'settings-access.created' | translate }}: <span [tmLocalizeDate]="createDate"></span> —
      {{ 'settings-access.changed' | translate }}: <span [tmLocalizeDate]="changeDate"></span>
    </div>
  </iw-form-block>

  <div class="footer">
    <iw-form-toolbar>
      <button
        iw-button
        type="submit"
        color="success"
        [disabled]="!(editable$ | async) || form.invalid || form.status === 'PENDING'"
      >
        {{ 'settings-access.scope.form.save' | translate }}
      </button>
      <button iw-button type="button" (click)="close()">{{ 'settings-access.scope.form.cancel' | translate }}</button>
    </iw-form-toolbar>
  </div>
</iw-form>
