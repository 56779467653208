import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmDragndropDirective } from './dragndrop.directive';
import { TmDropzoneComponent } from './dropzone.component';
import { TmIconModule } from '../icons/tm-icon.module';

@NgModule({
  declarations: [TmDropzoneComponent, TmDragndropDirective],
  imports: [TranslateModule, IwSharedModule, TmIconModule, CommonModule],
  exports: [TmDropzoneComponent],
})
export class TmDropzoneModule {}
