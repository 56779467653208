import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TmOverlayComponent } from './overlay.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TmOverlayComponent],
  entryComponents: [TmOverlayComponent],
  exports: [TmOverlayComponent],
})
export class TmOverlayModule {}
