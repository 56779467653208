import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmDropzoneModule } from '@tm-shared/dragndrop/dropzone.module';
import { TmSpinnerModule } from '@tm-shared/spinner';
import { TmTechLoaderComponent } from './tech-loader.component';
import { TmIconModule } from '../icons/tm-icon.module';

@NgModule({
  imports: [CommonModule, IwSharedModule, TmIconModule, TranslateModule, TmDropzoneModule, TmSpinnerModule],
  declarations: [TmTechLoaderComponent],
  entryComponents: [TmTechLoaderComponent],
})
export class TmTechLoaderElementsModule {}
