import { Injectable } from '@angular/core';

@Injectable()
export class TmPluginValidationErrorService {
  public parse(response: TmPlugin.validationError.Response): TmPlugin.validationError.ParsedError {
    /**
     * Backend stops validation after any error, so there's only one header and one error per response
     */
    let errorData = response.meta;
    let headerName = errorData.headerName;
    let errorKey = Object.keys(errorData.errors)[0] as TmPlugin.validationError.Key;

    return {
      headerName: headerName,
      errorKey: errorKey,
      entities: this._getEntities(errorData.errors[errorKey]),
    };
  }

  private _getEntities(error: any): TmPlugin.validationError.ParsedEntities[] {
    let entityTypes = Object.keys(error) as TmPlugin.validationError.ValidationErrorEntity[];

    return entityTypes.map((entityType) => {
      return {
        entities: this._parseEntities(entityType, error[entityType]),
        entitiesType: entityType,
      };
    });
  }

  private _parseEntities(
    entityType: TmPlugin.validationError.ValidationErrorEntity,
    entities: any[]
  ): TmPlugin.validationError.Entity[] {
    /**
     * TODO: entities should be parsed with external parser (registered by plugin)
     */
    switch (entityType) {
      case 'plugin':
        return entities.map((entity) => ({ entityId: entity.PLUGIN_ID, entityName: entity.DISPLAY_NAME }));
      case 'policy':
        return entities.map((entity) => ({ entityId: entity.POLICY_ID, entityName: entity.DISPLAY_NAME }));
      case 'notification':
        return entities.map((entity) => ({ entityId: entity.NOTIFICATION_ID, entityName: entity.DISPLAY_NAME }));
      case 'query':
        return entities.map((entity) => ({ entityId: entity.QUERY_ID, entityName: entity.DISPLAY_NAME }));
      default:
        return [];
    }
  }
}
