import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { TmResourcesGroupService } from '@tm-shared/api-services';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmTreeComponent, TmTreeNodeData } from '@tm-shared/tree';
import { Observable, Subject, combineLatest, merge, throwError } from 'rxjs';
import { catchError, map, shareReplay, startWith, switchMapTo, take } from 'rxjs/operators';
import { SystemList } from '../generated/resources-group';
import { TREE_ROOT, getPrivilegeRequest } from '../resources.model';

@Injectable()
export class ResourcesService {
  public treeItems$: Observable<TmTreeNodeData[]>;

  private _getResourcesGroupRequest$: Observable<TmApi.GetArrayResponse<SystemList>>;
  private _getResourcesGroup$: Observable<TmApi.GetArrayResponse<SystemList>>;
  private _updateTreeDataSubject$: Subject<void>;
  private _restoreResourcesGroup$: Observable<TmApi.GetArrayResponse<SystemList>>;
  private _restoreSubject$: Subject<void>;

  constructor(
    private _resourcesGroupService: TmResourcesGroupService,
    private _t: TranslateService,
    private _activatedRoute: ActivatedRoute,
    private _notify: IwNotificationsService,
    private _privileges: TmPrivilegesService
  ) {
    const params = {
      params: {
        'sort[DISPLAY_NAME]': 'asc',
      },
    };
    this._getResourcesGroupRequest$ = this._resourcesGroupService.get(params);
    this._getResourcesGroup$ = this._getResourcesGroupRequest$.pipe(shareReplay(1));
    this._updateTreeDataSubject$ = new Subject();

    this._restoreResourcesGroup$ = this._resourcesGroupService.restore(params).pipe(
      take(1),
      catchError((e) => {
        this._notify.error(
          this._t.instant('lists-resources.errors.error'),
          this._t.instant('lists-resources.errors.restore-failed')
        );

        return throwError(e);
      })
    );
    this._restoreSubject$ = new Subject();
    this.treeItems$ = this._getTreeItems();
  }

  public getResourcesGroup(): Observable<TmApi.GetArrayResponse<SystemList>> {
    return this._getResourcesGroup$;
  }

  public isGarbageNode(id: string): Observable<boolean> {
    return this._isNodeWithCode('_GARBAGE_', id);
  }

  public isWebMailNode(id: string): Observable<boolean> {
    return this._isNodeWithCode('_WEBMAIL_', id);
  }

  public selectCurrentTreeNode(treeComponent: TmTreeComponent): boolean {
    return treeComponent.activateById(this.getNodeId());
  }

  public getNodeId(): string {
    if (this._activatedRoute.firstChild && this._activatedRoute.firstChild.firstChild!.snapshot.params['id']) {
      return this._activatedRoute.firstChild.firstChild!.snapshot.params['id'];
    }
    return '';
  }

  public updateTree(): void {
    this._updateTreeDataSubject$.next();
  }

  public restore(): void {
    this._restoreSubject$.next();
  }

  /**
   * Check privileges
   */
  public can(actionKey: TmPluginAccess.user.PrivilegeKey, ...rules$: Observable<boolean>[]): Observable<boolean> {
    let can$: Observable<boolean> = this._privileges.can(getPrivilegeRequest(actionKey));

    return combineLatest(can$, ...rules$).pipe(map((bools: boolean[]) => bools.every((b) => b)));
  }

  public getNearestIdById(id: string): Observable<string> {
    return this._getResourcesGroup$.pipe(
      map((items$) => {
        const i = items$.data.findIndex((item) => item.LIST_ID === id);
        if (items$.data.length > 1) {
          if (i === items$.data.length - 1) {
            return items$.data[i - 1].LIST_ID;
          } else {
            return items$.data[i + 1].LIST_ID;
          }
        } else {
          // этот вариант в принципе не возможен, так как, у нас всегда останется Веб-Почта и Мусорный трафик
          return TREE_ROOT;
        }
      })
    );
  }

  private _getTreeItems(): Observable<TmTreeNodeData[]> {
    let updateTreeData$ = this._updateTreeDataSubject$.pipe(
      switchMapTo(this._getResourcesGroupRequest$),
      shareReplay(1)
    );
    let restore$ = this._restoreSubject$.pipe(switchMapTo(this._restoreResourcesGroup$), shareReplay(1));
    let lang$ = this._t.onLangChange.pipe(switchMapTo(this._getResourcesGroup$));

    const ROOT = {
      id: TREE_ROOT,
      name: this._t.instant('lists-resources.tree.root-node'),
      isExpanded: true,
      children: [] as any[],
    };

    return merge(this._getResourcesGroup$, updateTreeData$, restore$, lang$).pipe(
      map((items$) => {
        ROOT.children = items$.data.map((item) => {
          return {
            id: item.LIST_ID,
            name: item.DISPLAY_NAME,
          };
        });
        return [ROOT];
      }),
      startWith([ROOT])
    );
  }

  private _getNodeDataByCode(code: string): Observable<SystemList | undefined> {
    return this._getResourcesGroup$.pipe(
      map((items$) => {
        return items$.data.find((item) => item.CODE === code);
      })
    );
  }

  private _isNodeWithCode(code: string, id: string): Observable<boolean> {
    return this._getNodeDataByCode(code).pipe(
      map((item) => item!.LIST_ID === id),
      shareReplay(1)
    );
  }
}
