import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService, IwPopoverOptions, IwModalService, IwPopoverDirective } from '@platform/shared';
import { TmResourcesGroupService } from '@tm-shared/api-services';
import { ModalConfirmComponent } from '@tm-shared/modals';
import { TmTreeComponent, TmTreeNodeData } from '@tm-shared/tree';
import { TreeNode } from '@circlon/angular-tree-component';
import { TmConfigLocalService } from 'plugins/config/services';
import { Observable, Subject, merge, of, throwError } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  skip,
  switchMap,
  switchMapTo,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ResourcesFormComponent } from '../resources-form-component/resources-form.component';
import { CREATE_GROUP_PATH, EDIT_GROUP_PATH, RESOURCES, TREE_ROOT } from '../resources.model';
import { ResourcesService } from './resources.service';

@Component({
  selector: 'tm-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
        placement: 'bottom-start',
      }),
    },
  ],
})
export class ResourcesComponent implements AfterViewInit, OnDestroy {
  @ViewChild(TmTreeComponent, { static: false }) public treeComponent: TmTreeComponent;
  @ViewChild('actionsPopover', { static: false }) public actionsPopover: IwPopoverDirective;

  public title$: Observable<string>;
  public treeData$: Observable<TmTreeNodeData[]>;
  public selectedNode$: Observable<TreeNode | undefined>;
  public editMessage$: Observable<string>;
  public removeMessage$: Observable<string>;

  public canCreateResourcesGroup$: Observable<boolean>;
  public canEditResourcesGroup$: Observable<boolean>;
  public canRemoveResourcesGroup$: Observable<boolean>;
  public canRestoreResourcesGroup$: Observable<boolean>;
  public isDeletable$: Observable<boolean>;

  private _isNotRoot$: Observable<boolean>;
  private _isNotGarbage$: Observable<boolean>;
  private _isNotWebMail$: Observable<boolean>;
  private _selectedNodeId: number | string = TREE_ROOT;

  private _destroy$: Subject<any>;

  constructor(
    private _resourcesService: ResourcesService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _notify: IwNotificationsService,
    private _t: TranslateService,
    private _modalService: IwModalService,
    private _resourcesGroupService: TmResourcesGroupService,
    private _configLocal: TmConfigLocalService
  ) {
    this.treeData$ = _resourcesService.treeItems$;
    this._destroy$ = new Subject();

    this._router.events
      .pipe(
        takeUntil(this._destroy$),
        filter((event) => event instanceof NavigationEnd),
        map(() => this._route.firstChild!.snapshot.params['edit']),
        distinctUntilChanged()
      )
      .subscribe((condition) => {
        this._selectedNodeId = this._route.firstChild!.snapshot.params['id'];
        if (condition) {
          this._showDialogEditGroup();
        }
      });
  }

  public ngAfterViewInit(): void {
    this._configLocal.refreshOnConfigChanges.pipe(takeUntil(this._destroy$)).subscribe();

    const treeDataReceived$ = this.treeData$.pipe(skip(1), take(1), delay(0));
    const navigated$ = this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      mapTo(undefined)
    );
    merge(treeDataReceived$, navigated$)
      .pipe(
        takeUntil(this._destroy$),
        catchError((e) => {
          this._notify.error(
            this._t.instant('lists-resources.errors.error'),
            this._t.instant('lists-resources.errors.request-failed')
          );

          return throwError(e);
        })
      )
      .subscribe((treeItems) => this._setInitialTreeState(treeItems!));

    this._defineSelectedNode();
    this.title$ = this.selectedNode$.pipe(map((node: TreeNode): string => node.data.name));
    this._definePrivileges();

    this.editMessage$ = this.canEditResourcesGroup$.pipe(
      map((canEditResourceGroup: boolean) => {
        if (canEditResourceGroup) {
          return this._t.instant('lists-resources.toolbar.edit');
        } else {
          return this._t.instant('lists-resources.toolbar.uneditable');
        }
      })
    );

    this.removeMessage$ = this.canRemoveResourcesGroup$.pipe(
      switchMap((canRemoveResourceGroup: boolean) => {
        if (canRemoveResourceGroup) {
          return of(this._t.instant('lists-resources.toolbar.delete'));
        } else {
          return this.isDeletable$.pipe(
            map((isDeletable: boolean) => {
              if (isDeletable) {
                return this._t.instant('lists-resources.toolbar.root');
              } else {
                return this._t.instant('lists-resources.toolbar.undeletable');
              }
            })
          );
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public showDialogCreateGroup(): void {
    this.actionsPopover.hide();
    this._router.navigate([CREATE_GROUP_PATH.replace(':edit', 'edit')], {
      relativeTo: this._route,
      queryParamsHandling: 'preserve',
    });
  }

  public showDialogEditGroup(): void {
    this.actionsPopover.hide();
    this._router.navigate([EDIT_GROUP_PATH.replace(':edit', 'edit').replace(':id', `${this._selectedNodeId}`)], {
      relativeTo: this._route,
      queryParamsHandling: 'preserve',
    });
  }

  public showDialogRemoveGroup(): void {
    this.actionsPopover.hide();
    this.selectedNode$
      .pipe(
        take(1),
        switchMap((selectedNode: TreeNode) => {
          return this._modalService
            .open(ModalConfirmComponent, {
              title: this._t.instant('lists-resources.group.confirm-delete-title'),
              text: this._t.instant('lists-resources.group.confirm-delete-text', { group: selectedNode.data.name }),
              yes: this._t.instant('lists-resources.group.yes'),
              no: this._t.instant('lists-resources.group.no'),
            })
            .then((modal) => ({ modal, selectedNode }));
        }),
        switchMap(({ modal, selectedNode }) => {
          const decision$: EventEmitter<boolean> = modal.component.decision;

          return decision$.pipe(
            switchMap((ok) => {
              if (ok) {
                return this._resourcesGroupService.remove(selectedNode.data.id).pipe(
                  // eslint-disable-next-line
                  catchError((e) => {
                    this._notify.error(
                      this._t.instant('lists-resources.errors.error'),
                      this._t.instant('lists-resources.group.delete-failed')
                    );

                    return throwError(e);
                  }),
                  switchMapTo(this._resourcesService.getNearestIdById(selectedNode.data.id)),
                  tap((nearestId) => {
                    this._router.navigate([nearestId], { relativeTo: this._route, replaceUrl: true });
                    this._resourcesService.updateTree();
                  })
                );
              } else {
                return of(ok);
              }
            })
          );
        })
      )
      .subscribe();
  }

  /**
   * Open restore confirmation dialog
   */
  public async restore(): Promise<void> {
    this.actionsPopover.hide();
    const modal = await this._modalService.open(ModalConfirmComponent, {
      title: this._t.instant('lists-resources.restore.confirm-action'),
      text: this._t.instant('lists-resources.restore.check-restore'),
      no: this._t.instant('lists-resources.restore.no'),
    });

    const decision$: EventEmitter<boolean> = modal.component.decision;

    decision$.pipe(take(1), takeUntil(this._destroy$)).subscribe((ok) => {
      if (ok) {
        this._resourcesService.restore();
      }
    });
  }

  private _defineSelectedNode(): void {
    this.selectedNode$ = merge(this.treeData$.pipe(skip(1), delay(0)), this.treeComponent.onActiveNode).pipe(
      map(() => {
        const selected = this.treeComponent.getActiveNodeIds();
        if (selected.length) {
          this._selectedNodeId = selected[0];
          return this.treeComponent.getNodeById(selected[0])!;
        }
      }),
      takeUntil(this._destroy$)
    );
  }

  private _definePrivileges(): void {
    this._isNotRoot$ = this.selectedNode$.pipe(map((node: TreeNode): boolean => node.data.id !== TREE_ROOT));
    this._isNotGarbage$ = this.selectedNode$.pipe(
      switchMap(
        (node: TreeNode): Observable<boolean> => {
          return this._resourcesService.isGarbageNode(node.data.id);
        }
      ),
      map((b: boolean): boolean => !b)
    );
    this._isNotWebMail$ = this.selectedNode$.pipe(
      switchMap(
        (node: TreeNode): Observable<boolean> => {
          return this._resourcesService.isWebMailNode(node.data.id);
        }
      ),
      map((b: boolean): boolean => !b)
    );

    this.isDeletable$ = this._resourcesService.can('delete', this._isNotGarbage$, this._isNotWebMail$);
    this.canCreateResourcesGroup$ = this._resourcesService.can('edit');
    this.canEditResourcesGroup$ = this._resourcesService.can('edit', this._isNotRoot$);
    this.canRemoveResourcesGroup$ = this._resourcesService.can(
      'delete',
      this._isNotRoot$,
      this._isNotGarbage$,
      this._isNotWebMail$
    );
    this.canRestoreResourcesGroup$ = this._resourcesService.can('edit');
  }

  private async _showDialogEditGroup(): Promise<void> {
    const params = {
      resourcesId$: of(this._selectedNodeId),
    };

    const modal = await this._modalService.open(ResourcesFormComponent, params, { size: 'large' });

    const componentInstance = modal.component;
    const closeComponentForm$ = componentInstance.close$.pipe(
      tap(() => {
        if (componentInstance) {
          modal.close(null);
          this._router.navigate([this._selectedNodeId], {
            relativeTo: this._route,
            queryParamsHandling: 'merge',
          });
        }
      })
    );

    merge(modal.dismissed, modal.closed, closeComponentForm$)
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe(() => {
        this._router.navigate(this._selectedNodeId ? [RESOURCES, this._selectedNodeId] : [RESOURCES], {
          queryParamsHandling: 'merge',
        });
        this._resourcesService.updateTree();
      });
  }

  private _setInitialTreeState(treeItems: TmTreeNodeData[]): void {
    if (!this._resourcesService.selectCurrentTreeNode(this.treeComponent) && treeItems && treeItems.length) {
      this.treeComponent.activateById(treeItems[0].id);
    }
  }
}
