import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmLdapStatusApiService, TmPolicyApiService } from '@tm-shared/api-services';
import { map } from 'rxjs/operators';
import { CheckboxCellComponent, ColorCellComponent } from '../../../@tm-shared/grid/cell-renderers';

@Injectable()
export class StatusService {
  constructor(
    private _t: TranslateService,
    private _statusApi: TmLdapStatusApiService,
    private _policyApi: TmPolicyApiService
  ) {}

  public getGridConfig() {
    return {
      columnDefs: [
        {
          width: 10,
          field: 'checkbox',
          headerName: '',
          cellRendererFramework: CheckboxCellComponent,
        },
        {
          width: 10,
          field: 'COLOR',
          headerName: '',
          sortable: true,
          cellRendererFramework: ColorCellComponent,
        },
        {
          field: 'DISPLAY_NAME',
          sort: 'asc',
          resizable: true,
          sortable: true,
          headerValueGetter: () => this._t.instant('lists-statuses.field-names.display-name'),
        },
        {
          resizable: true,
          sortable: true,
          field: 'NOTE',
          headerValueGetter: () => this._t.instant('lists-statuses.field-names.note'),
        },
      ],
    };
  }

  public createPolicy(ids: (string | number)[]): void {
    let policy = this._policyApi.getEmptyPolicyObject('PERSON');
    const data$ = this._statusApi.getItemsByIds(ids).pipe(
      map((items) => {
        const itemsForPolicy = items.map((item) => {
          return {
            ID: item.IDENTITY_STATUS_ID,
            TYPE: 'status',
            NAME: item.DISPLAY_NAME,
          };
        });
        this._policyApi.setItemsToPolicy(policy, itemsForPolicy);
        return policy;
      })
    );
    this._policyApi.createPolicyAndNavigate(data$);
  }
}
