import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { en, ru } from '../i18n';
import { TmPaginationComponent } from './tm-pagination.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [TranslateModule.forChild(), CommonModule, IwSharedModule, ReactiveFormsModule],
  declarations: [TmPaginationComponent],
  entryComponents: [TmPaginationComponent],
  exports: [TmPaginationComponent],
})
export class TmPaginationModule {
  constructor(t: TranslateService) {
    t.setTranslation('ru', ru, true);
    t.setTranslation('en', en, true);
  }
}
