import { TranslateService } from '@ngx-translate/core';
import { AuditExtendGridData, AuditGridDataColumn } from '../audit-extend.service';
import { AuditEvent, AuditEventOperation, AuditUserRole, AuditUserScope } from '../audit.model';
import {
  VisibleAttributesOption,
  getAuditExtendRowData,
  getAuditAttributesFrom,
  TransformOptions,
  transformLanguage,
} from './audit-common';
import { sqlStringToDateTime } from '@tm-shared/helpers/date';

const auditUserVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'hostname', i18nKey: 'audit.auditUser.attributes.hostname' },
  { path: 'ip', i18nKey: 'audit.auditUser.attributes.ip' },
  { path: 'login', i18nKey: 'audit.auditUser.attributes.login' },
  { path: 'LANGUAGE', i18nKey: 'audit.auditUser.attributes.LANGUAGE', transform: transformLanguage },
  { path: 'STATUS', i18nKey: 'audit.auditUser.attributes.STATUS', transform: transformStatus },
  { path: 'DISPLAY_NAME', i18nKey: 'audit.auditUser.attributes.DISPLAY_NAME' },
  { path: 'display_name', i18nKey: 'audit.auditUser.attributes.DISPLAY_NAME' },
  { path: 'EMAIL', i18nKey: 'audit.auditUser.attributes.EMAIL' },
  { path: 'NOTE', i18nKey: 'audit.auditUser.attributes.NOTE' },
  { path: 'USERNAME', i18nKey: 'audit.auditUser.attributes.USERNAME' },
  { path: 'LDAP_SERVER_ID', i18nKey: 'audit.auditUser.attributes.LDAP_SERVER_ID' },
  { path: 'roles', i18nKey: 'audit.auditUser.attributes.roles', transform: transformRolesScopes },
  { path: 'visibilityareas', i18nKey: 'audit.auditUser.attributes.visibilityareas', transform: transformRolesScopes },
  { path: 'unlock_date', i18nKey: 'audit.auditUser.attributes.unlockDate', transform: transformDateTime },
];

export function getUserGridData(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  let cols: AuditGridDataColumn[] = [];
  /**
   * Set correct columns
   */
  switch (data.OPERATION) {
    case AuditEventOperation.bruteforce:
    case AuditEventOperation.login:
    case AuditEventOperation.logout:
      cols = [AuditGridDataColumn.request];
      break;
    case AuditEventOperation.delete:
      cols = [AuditGridDataColumn.old];
      break;
    case AuditEventOperation.create:
      cols = [AuditGridDataColumn.new];
      break;
    default:
      cols = [AuditGridDataColumn.old, AuditGridDataColumn.new];
      break;
  }

  return [
    {
      cols: cols,
      data: getAuditExtendRowData({
        t,
        cols: cols,
        data: getAuditAttributesFrom(data),
        visibleAttributes: auditUserVisibleAttributes,
      }),
    },
  ];
}

export function transformRolesScopes(user: (AuditUserScope | AuditUserRole)[] | null | undefined): string {
  return (
    user
      ?.map((action: AuditUserScope | AuditUserRole) => action.DISPLAY_NAME)
      .filter((x) => !!x)
      .join(', ') || ''
  );
}

export function transformStatus(value: TmApi.user.Status | null | undefined, options: TransformOptions): string {
  if (value === undefined || value === null) {
    return '';
  }

  if (+value === 2) {
    return options.t.instant('audit.auditUser.attributes.status.deleted');
  }
  if (+value === 1) {
    return options.t.instant('audit.auditUser.attributes.status.active');
  }
  if (+value === 0) {
    return options.t.instant('audit.auditUser.attributes.status.inactive');
  }

  return '';
}

export function transformDateTime(value?: string): string {
  if (!value) {
    return '';
  }

  return sqlStringToDateTime(value).toFormat('f');
}
