import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwModalService, IwNotificationsService, IwPluralizePipe, IwPopoverOptions } from '@platform/shared';
import { TmTagApiService } from '@tm-shared/api-services/tag-api.service';
import { TmElement } from '@tm-shared/custom-elements';
import { TmGridComponent, TmGridOptions } from '@tm-shared/grid';
import { CheckboxCellComponent, ColorCellComponent } from '@tm-shared/grid/cell-renderers';
import { ModalConfirmWithItemsComponent } from '@tm-shared/modals/confirm-with-items';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmSidebarService } from '@tm-shared/structure/sidebar';
import { Observable, Subject, combineLatest, of, throwError } from 'rxjs';
import { auditTime, catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TmConfigLocalService } from '../../config/services';
import { CREATE_PATH, EDIT_PATH, getPrivilegeRequest } from '../tag-exports';
import { TagFormComponent } from '../tag-form-component/tag-form.component';

@TmElement('tme-tag')
@Component({
  selector: 'tm-tag',
  styleUrls: ['tag.component.scss'],
  templateUrl: 'tag.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
        closeOnMousemoveOutside: true,
        triggers: 'mouseenter:mouseleave',
      }),
    },
  ],
})
export class TagComponent implements AfterViewInit, OnDestroy {
  @ViewChild('grid', { static: false }) public grid: TmGridComponent<ApiTag.TagInfo>;

  /**
   * Define can streams
   */
  public canEdit$: Observable<boolean>;
  public canCreate$: Observable<boolean>;
  public canRemove$: Observable<boolean>;

  public gridOptions: TmGridOptions = {
    columnDefs: [
      {
        width: 10,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        width: 10,
        field: 'COLOR',
        headerName: '',
        sortable: true,
        cellRendererFramework: ColorCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        sort: 'asc',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('lists-tag.field-names.display-name'),
      },
      {
        resizable: true,
        sortable: true,
        field: 'NOTE',
        headerValueGetter: () => this._t.instant('lists-tag.field-names.note'),
      },
    ],
  };

  /**
   * Unsubscribe trigger
   */
  private _destroyed$: Subject<void> = new Subject();

  constructor(
    public service: TmTagApiService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _t: TranslateService,
    private _modalService: IwModalService,
    private _privileges: TmPrivilegesService,
    private _notify: IwNotificationsService,
    private _sidebarService: TmSidebarService,
    private _configLocal: TmConfigLocalService
  ) {}

  /**
   * Open create dialog
   */
  public showDialogCreate(): void {
    this._router.navigate([CREATE_PATH], {
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'preserve',
    });
    this._sidebarService.open('right');
  }

  /**
   * Open edit dialog
   */
  public showDialogEdit(id: string): void {
    this._router.navigate([EDIT_PATH.replace(':id', `${id}`)], {
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'preserve',
    });
    this._sidebarService.open('right');
  }

  /**
   * Open delete confirmation dialog
   */
  public showDialogRemove(): void {
    this.service
      .getItemsByIds(this.grid.getAllSelectedIds())
      .pipe(
        switchMap((selectedModels) => {
          const plural = new IwPluralizePipe();
          const textToShow = plural.transform('lists-tag.form.delete-text', selectedModels.length);
          return this._modalService.open(ModalConfirmWithItemsComponent, {
            title: this._t.instant('lists-tag.form.confirm-action'),
            text: this._t.instant(textToShow, { number: selectedModels.length }),
            itemsToDisplay: selectedModels.map((item) => item.DISPLAY_NAME),
          });
        }),
        switchMap((modal) => {
          const decision$: EventEmitter<boolean> = modal.component.decision;
          return decision$.pipe(switchMap((ok) => (ok ? this._deleteTag() : of(ok))));
        }),
        catchError((e) => {
          this._notify.error(
            this._t.instant('lists-tag.errors.error'),
            this._t.instant('lists-tag.errors.request-failed')
          );

          return throwError(e);
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /**
   * Handler load form component
   */
  public onActivate(component: TagFormComponent): void {
    component.close$
      .pipe(
        tap(() => this._sidebarService.close('right')),
        auditTime(200),
        take(1),
        filter(() => !!this.grid),
        tap(() => this.grid.resetSelection()),
        tap(() => this.grid.refresh()),
        takeUntil(this._destroyed$)
      )
      .subscribe();

    this._sidebarService.open('right');
  }

  public ngAfterViewInit() {
    if (this._activatedRoute.firstChild && this._activatedRoute.firstChild.snapshot.params['id']) {
      this.grid.selectById(this._activatedRoute.firstChild.snapshot.params['id']);
    }

    this._configLocal.refreshOnConfigChanges.pipe(takeUntil(this._destroyed$)).subscribe();

    this._sidebarService
      .onAction('*', 'complete')
      .pipe(
        switchMap(() => this.grid.initialized$),
        takeUntil(this._destroyed$)
      )
      .subscribe((gridApi) => {
        gridApi.sizeColumnsToFit();
      });
    this.grid.selectedOrdered$.pipe(takeUntil(this._destroyed$)).subscribe((selection: any[]) => {
      if (!selection.length) {
        return;
      }
      this.showDialogEdit(selection[0].TAG_ID);
    });
    this.canEdit$ = this.grid.selected$.pipe(
      takeUntil(this._destroyed$),
      map((data) => this.service.isModelEditable(data))
    );
    this.canCreate$ = this._can('edit');
    this.canRemove$ = this._can('delete', this.canEdit$).pipe(
      switchMap((can) => {
        // If has privileges
        if (can && this.grid) {
          return this.grid.allSelectedIds$.pipe(
            // Allow if selected items > 0
            map((items) => items.length > 0)
          );
        }

        return of(false);
      })
    );
  }

  /**
   * Clean up component on destroy event
   */
  public ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Check privileges
   */
  private _can(actionKey: TmPluginAccess.role.PrivelegeKey, ...rules$: Observable<boolean>[]): Observable<boolean> {
    const can$: Observable<boolean> = this._privileges.can(getPrivilegeRequest(actionKey));

    return combineLatest([can$, ...rules$]).pipe(map((bools: boolean[]) => bools.every((b) => b)));
  }

  private _deleteTag() {
    this._sidebarService.close('right');
    return this.grid.deleteAllSelected();
  }
}
