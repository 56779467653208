import { Injectable, NgZone, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TmTechLoaderComponentService } from './tech-loader-component.service';
import { TmTechLoaderItem, TmTechLoaderStatus, TmTechLoaderType } from './tech-loader.model';

// TODO:
type SampleCompilerStates = 'running' | 'success' | 'error' | 'upload';
type Action = 'create' | 'update' | 'export' | 'import';

interface TmbbUpdateModelState {
  // Const ?
  type: 'fingerprint';
  // Action type
  action: Action;
  // file name
  name: string;
  // Sample compiler states
  state: SampleCompilerStates;
  // File key
  sectionId: string;
  // Group key
  module: 'analysis';
  // Uploaded percent
  percent?: number;
  // File size
  size: number;
  // File type
  fileType: string;
  // ???
  compileType: 'TEXT' | any;
  // ???
  id: string;
  // File key
  key: string;
  // Error text. May contain HTML
  error?: string;
  // Group name
  groupName: string;
}

@Injectable()
export class TmbbTechLoaderService {
  private groupList: string[] = [];

  constructor(private service: TmTechLoaderComponentService, private sanitizer: DomSanitizer, private zone: NgZone) {}

  public pushUpdate(state: TmbbUpdateModelState): void {
    this.zone.run(() => this.addOrUpdateItem(state));
  }

  public export(type: TmTechLoaderType): void {
    this.zone.run(() => this.service.export(type));
  }

  public import(type: TmTechLoaderType): void {
    this.zone.run(() => this.service.import(type));
  }

  protected getGroupId(state: TmbbUpdateModelState): string {
    const groupId = state.sectionId;

    if (this.groupList.indexOf(groupId) === -1) {
      this.service.initGroup({
        key: groupId,
        items: [],
        i18nKey: state.groupName,
      });
      this.groupList.push(groupId);
    }

    return groupId;
  }

  protected getStatus(state: TmbbUpdateModelState): TmTechLoaderStatus {
    if (state.error) {
      return TmTechLoaderStatus.error;
    }

    // TODO: move statuses out somewhere
    if (['success', 'ready', 'save', 'updated'].includes(state.state)) {
      return TmTechLoaderStatus.success;
    }

    return TmTechLoaderStatus.inProgress;
  }

  protected addOrUpdateItem(state: TmbbUpdateModelState): void {
    const groupId = this.getGroupId(state);
    const status = this.getStatus(state);
    let item: TmTechLoaderItem;

    switch (status) {
      case TmTechLoaderStatus.error:
        item = {
          key: state.key,
          title: state.name,
          status: status,
          statusText: '',
          errorPopover: this.sanitizer.sanitize(SecurityContext.HTML, state.error || '') || undefined,
          errorPopoverUseHtml: true,
        };
        break;
      case TmTechLoaderStatus.inProgress:
        item = {
          key: state.key,
          title: state.name,
          status: status,
          statusText: state.percent ? `${state.percent || ''}%` : '',
        };
        break;
      case TmTechLoaderStatus.success:
      default:
        item = {
          key: state.key,
          title: state.name,
          status: status,
          statusText: '',
        };
    }

    this.service.addItemToGroup(groupId, item);
  }
}
