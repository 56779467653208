import { Component } from '@angular/core';
import { TmAuditEventFilterService } from '../audit-event-filter/audit-event-filter.service';
import { TmAuditEventRetentionService } from '../audit-event-filter/audit-event-retention.service';
import { TmAuditEventListService } from '../audit-event-list/audit-event-list.service';

@Component({
  selector: 'tm-audit-page',
  templateUrl: 'audit-page.component.html',
  styleUrls: ['audit-page.component.scss'],
  providers: [TmAuditEventFilterService, TmAuditEventRetentionService, TmAuditEventListService],
})
export class TmAuditPageComponent {
  constructor() {}
}
