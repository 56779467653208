import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { IconName } from '../../typings/generated/icons';

// компонент для иконок, которые лежат в проекте (а не грузятся динамически)
// Для динамических иконок использовать iw-icon
@Component({
  selector: 'tm-icon',
  template: `<iw-icon style="vertical-align: middle" [svgIcon]="svgIcon" [size]="size"></iw-icon>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TmIconComponent {
  @Input() public svgIcon: IconName;
  @Input() public size?: string;
}
