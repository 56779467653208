import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmScopeApiService } from '@tm-shared/api-services/scope-api.service';
import { CheckboxCellComponent } from '@tm-shared/grid/cell-renderers';
import { GridOptions } from 'ag-grid-community';
import { TmAccessSelectModalComponent } from '../access-select';

@Component({
  templateUrl: './scope-select-modal.component.html',
  styleUrls: ['./scope-select-modal.component.scss'],
})
export class TmScopeSelectModalComponent extends TmAccessSelectModalComponent {
  public readonly gridOptions: GridOptions = {
    columnDefs: [
      {
        width: 30,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        sortable: true,
        resizable: true,
        headerValueGetter: () => this._t.instant('settings-access.scope.fieldNames.displayName'),
      },
      {
        width: 600,
        sortable: true,
        resizable: true,
        field: 'NOTE',
        headerValueGetter: () => this._t.instant('settings-access.scope.fieldNames.note'),
      },
    ],
  };

  constructor(protected _t: TranslateService, public service: TmScopeApiService) {
    super(_t);
  }
}
