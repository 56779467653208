import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import {
  TmPluginAccessUserProvider,
  TmPluginAccessUserStatus,
  TmPluginAccessUserType,
} from 'plugins/settings-access/access-exports';
import { forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class TmUserApiService extends TmCollectionLoader<TmApi.user.CollectionItem> {
  public readonly src = '/api/user';
  public idAttribute: 'USER_ID' = 'USER_ID';

  public checkIfUserInNotifications = (usersIds: (string | number)[]) => {
    return this.getItemsByIds(usersIds).pipe(
      switchMap((users) => {
        const usersInRequest = users.reduce((acc, current) => acc + `filter[USER_ID][]=${current.USER_ID}&`, '');
        return forkJoin(
          of(users),
          this._http
            .get<TmApi.GetResponse<TmApi.user.CollectionItem[]>>(
              `${this.src}?${usersInRequest}with[notification_recipient]=notification`
            )
            .pipe(
              map((response) => {
                return response.data.filter((user) => user.notification_recipient.length);
              })
            )
        );
      })
    );
  };

  public isOfficer(user: TmApi.user.CollectionItem): boolean {
    return user.USER_TYPE === (TmPluginAccessUserType.PreinstalledSecurityOfficer as number);
  }

  public isAdministrator(user: TmApi.user.CollectionItem): boolean {
    return user.USER_TYPE === (TmPluginAccessUserType.PreinstalledAdministrator as number);
  }

  public isLdapUser(user: TmApi.user.CollectionItem): boolean {
    return user.PROVIDER === TmPluginAccessUserProvider.LDAP;
  }

  public canEditUser(user: TmApi.user.CollectionItem): boolean {
    return !!user.EDITABLE && !user.IS_SYSTEM && user.STATUS !== (TmPluginAccessUserStatus.Deleted as number);
  }

  public canRemoveUser(user: TmApi.user.CollectionItem): boolean {
    return this.canEditUser(user) && !this.isOfficer(user) && !this.isAdministrator(user);
  }
}
