<tm-content>
  <h2 class="title">{{ 'lists-statuses.title' | translate }}</h2>
  <p class="description">{{ 'lists-statuses.description' | translate }}</p>
  <tm-toolbar class="toolbar">
    <button
      iw-icon-button
      [disabled]="!(canCreate$ | async)"
      type="button"
      iwPopover="{{ 'lists-statuses.toolbar.create' | translate }}"
      data-qa="status-create"
      (click)="showDialogCreate()"
    >
      <tm-icon svgIcon="create"></tm-icon>
    </button>
    <button
      *ngIf="grid?.allSelected$ | async as selected"
      iw-icon-button
      [disabled]="!(canRemove$ | async) || selected.length === 0"
      type="button"
      iwPopover="{{ 'lists-statuses.toolbar.remove' | translate }}"
      data-qa="status-delete"
      tmDeleteConfirm
      [tmDeleteConfirmDialog]="modal"
      [tmDeleteConfirmDialogData]="{
        itemsToDisplay: selectedItemsNames$ | async,
        title: 'lists-statuses.form.confirm-action' | translate,
        text: 'lists-statuses.form.delete-text' | pluralize: selected.length | translate: { number: selected.length }
      }"
      (tmDeleteConfirm)="deleteSelectedItems()"
    >
      <tm-icon svgIcon="delete"></tm-icon>
    </button>
    <button
      iw-icon-button
      [disabled]="!!(createPolicyDisabled$ | async)"
      type="button"
      (click)="createPolicy()"
      iwPopover="{{ 'lists-statuses.toolbar.createPolicy' | translate }}"
    >
      <tm-icon svgIcon="createPolicy"></tm-icon>
    </button>
  </tm-toolbar>
  <tm-grid #grid class="grid" [service]="service" [gridOptions]="gridOptions"></tm-grid>
</tm-content>
<tm-sidebar class="sidebar" direction="right" [isCollapsed]="true">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</tm-sidebar>
