<input
  type="text"
  [tmGridFilter]="grid"
  propertyToFilter="query"
  [startsWithAny]="false"
  [endsWithAny]="false"
  class="tm-input search-input"
  placeholder="{{ 'settings-access.search_placeholder' | translate }}"
  data-qa="search-tab-input"
/>
<tm-grid class="grid" #grid [gridOptions]="options" [service]="service" [storeKey]="null" [tableConfig]="config">
</tm-grid>
