import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface SidebarAction {
  isCollapsed: boolean;
  direction: 'left' | 'right' | '*';
  action: 'start' | 'complete';
}

@Injectable()
export class TmSidebarService {
  public actions$: Subject<SidebarAction> = new Subject<SidebarAction>();

  public title$: Subject<string> = new Subject<string>();

  public onAction(direction: 'left' | 'right' | '*', action: 'start' | 'complete' | '*'): Observable<SidebarAction> {
    return this.actions$.pipe(
      filter((sidebar: SidebarAction) => {
        if (direction === '*' && action === '*') {
          return true;
        } else {
          if (action === '*') {
            return direction.indexOf(sidebar.direction) !== -1;
          }
          if (direction === '*') {
            return action.indexOf(sidebar.action) !== -1;
          }

          return direction.indexOf(sidebar.direction) !== -1 && action.indexOf(sidebar.action) !== -1;
        }
      })
    );
  }

  public actionDone(sidebar: Omit<SidebarAction, 'action'>): void {
    this.actions$.next({
      isCollapsed: sidebar.isCollapsed,
      direction: sidebar.direction,
      action: 'complete',
    });
  }

  // eslint-disable-next-line
  public toggle(sidebar: Omit<SidebarAction, 'action'>): void {
    this.actions$.next({
      isCollapsed: sidebar.isCollapsed,
      direction: sidebar.direction,
      action: 'start',
    });
  }

  public open(direction: SidebarAction['direction']): void {
    this.actions$.next({
      isCollapsed: false,
      direction: direction,
      action: 'start',
    });
  }

  public close(direction: SidebarAction['direction']): void {
    this.actions$.next({
      isCollapsed: true,
      direction: direction,
      action: 'start',
    });
  }

  public updateTitle(title: string): void {
    this.title$.next(title);
  }
}
