import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

type Value = File | File[] | FileList | null;
export type DropzoneOutValue = File | File[] | null;

@Component({
  selector: 'tm-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TmDropzoneComponent),
      multi: true,
    },
  ],
})
export class TmDropzoneComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @Input() public set value(file: DropzoneOutValue) {
    this._setFiles(file, true);
  }

  public get value(): DropzoneOutValue {
    return this.files.length ? this.files : null;
  }

  @Input() public accept?: string;

  @Input() public disabled = false;

  @Input() public downloadLink?: string;

  @Input() public downloadText?: string;

  @Input() public multiple: null | true = null;

  @Output() public change = new EventEmitter<DropzoneOutValue>();

  @ViewChild('fileInput', { static: false }) public fileInput?: ElementRef;

  public get files(): File[] {
    return Array.from(this._files.values());
  }

  private _afterViewInit$ = new ReplaySubject<void>(1);

  private _files: Map<string, File> = new Map();

  private _destroy$: Subject<void> = new Subject();

  constructor(private _cd: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this._afterViewInit$.next();
  }

  public ngOnInit(): void {}

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._cd.markForCheck();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onBlur() {
    this._ngHookonTouched();
  }

  public registerOnChange(fn: any): void {
    this._ngHookOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._ngHookonTouched = fn;
  }

  public writeValue(value: Value): void {
    this._setFiles(value, true);
  }

  public addFiles(files: FileList) {
    this._setFiles(files, !this.multiple);
    this._ngHookOnChange(this._getValue());
    this.change.emit(this._getValue());
  }

  public showFileSelector(): void {
    this._afterViewInit$.pipe(take(1)).subscribe(() => {
      if (this.fileInput) {
        (this.fileInput.nativeElement as HTMLInputElement).click();
      }
    });
  }

  public removeFileByIndex(index: number, e?: Event) {
    if (e) {
      e.stopPropagation();
    }
    this._files.delete(this._getFileId(this.files[index]));
    this._ngHookOnChange(this._getValue());
    this.change.emit(this._getValue());
  }

  public clear(): void {
    this._files.clear();
    if (this.fileInput) {
      (this.fileInput.nativeElement as HTMLInputElement).files = null;
      (this.fileInput.nativeElement as HTMLInputElement).value = '';
    }
  }

  public fileInputChanged(e: Event): void {
    const files = (e.target as HTMLInputElement).files;

    // stopImmediatePropagation fails in jest env
    if (e && e.stopImmediatePropagation) {
      e.stopImmediatePropagation();
    }

    if (files) {
      this.addFiles(files);
    }
  }

  private _getValue(): DropzoneOutValue {
    return this.files.length ? (this.multiple ? this.files : this.files[0]) : null;
  }

  private _setFiles(files: Value, clearBeforeSet: boolean = false): void {
    files = this._toArray(files);

    if (clearBeforeSet) {
      this.clear();
    }

    for (let index = 0; index < files.length; index++) {
      if (!this._files.has(this._getFileId(files[index]))) {
        this._files.set(this._getFileId(files[index]), files[index]);
      }
    }

    this._cd.markForCheck();
  }

  private _getFileId(file: File): string {
    return `${file.type}:${file.size}:${file.name}`;
  }

  private _toArray(value: Value): File[] {
    if (!value) {
      return [];
    }

    if (value instanceof FileList) {
      return Array.from(value);
    }

    return Array.isArray(value) ? value : [value];
  }

  private _ngHookOnChange: (value: DropzoneOutValue) => void = () => {};

  private _ngHookonTouched: () => any = () => {};
}
