export const MODEL_ID_KEY = 'VISIBILITY_AREA_ID';

export const MODULE_KEY = 'settings:access:scope';

export function getPrivilegeRequest(key: string): string {
  return `${MODULE_KEY}:${key}`;
}

export class ScopeModel implements TmApi.scope.CollectionItem {
  public CHANGE_DATE?: string;
  public CREATE_DATE?: string;
  public DISPLAY_NAME = '';
  public IS_SYSTEM?: number;
  public NOTE?: string;
  public VISIBILITY_AREA_CONDITION: { data: TmApi.scope.ConditionNode };
  public VISIBILITY_AREA_ID?: string;
}
