//  TODO: coming soon in further commits (to reduce commit size)
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'tm-configdiff-item',
  templateUrl: './configdiff-item.component.html',
  styleUrls: ['./configdiff-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmConfigDiffItemComponent implements OnInit, OnChanges {
  @Input() public data: TmPluginConfig.diff.BaseItem | TmPluginConfig.diff.DetailedItem;

  @Output() public fetchDetailed: EventEmitter<void> = new EventEmitter();

  public actionText: string;

  public dataDetailed?: TmPluginConfig.diff.DetailedItem;

  public dateText: string;

  public entityText: string;

  public expanded = false;

  public configText: string;

  public loadingDetailed = false;

  constructor(private _t: TranslateService) {}

  public ngOnChanges() {
    this._updateDataDetailed();
  }

  public ngOnInit() {
    this._updateActionText();
    this._updateEntityText();
    this._updateDateText();
    this._updateDataDetailed();
  }

  public toggleExpand(): void {
    /**
     * Fetch detailed data if none, or just change expanded state
     */
    if (!this.loadingDetailed && !this.dataDetailed) {
      this.fetchDetailed.emit();
      this.loadingDetailed = true;
    } else {
      this.expanded = !this.expanded;
    }
  }

  private _hasDetailedData(): boolean {
    return 'details' in (this.data as TmPluginConfig.diff.DetailedItem);
  }

  private _updateActionText(): void {
    /**
     * @translate config.diff.item.action.add
     * @translate config.diff.item.action.edit
     * @translate config.diff.item.action.delete
     */
    this.actionText = this._t.instant(`config.diff.item.action.${this.data.action}`);
  }

  private _updateDateText(): void {
    this.dateText = this.data.timestamp ? DateTime.fromMillis(this.data.timestamp).toFormat('DD HH:mm') : '';
  }

  private _updateDataDetailed(): void {
    if (!this._hasDetailedData()) {
      delete this.dataDetailed;
      this.loadingDetailed = false;
      return;
    }

    this.dataDetailed = this.data as TmPluginConfig.diff.DetailedItem;

    /**
     * On loading happened
     */
    if (this.loadingDetailed) {
      this.expanded = true;
      this.loadingDetailed = false;
    }
  }

  private _updateEntityText(): void {
    /**
     * @translate config.diff.item.entity.Category
     * @translate config.diff.item.entity.EtForm
     * @translate config.diff.item.entity.EtGraphic
     * @translate config.diff.item.entity.EtStamp
     * @translate config.diff.item.entity.EtTable
     * @translate config.diff.item.entity.Fingerprint
     * @translate config.diff.item.entity.LdapGroup
     * @translate config.diff.item.entity.LdapPerson
     * @translate config.diff.item.entity.LdapStatus
     * @translate config.diff.item.entity.LdapWorkstation
     * @translate config.diff.item.entity.Notification
     * @translate config.diff.item.entity.Perimeter
     * @translate config.diff.item.entity.Policy
     * @translate config.diff.item.entity.ProtectedCatalog
     * @translate config.diff.item.entity.ProtectedDocument
     * @translate config.diff.item.entity.SystemList
     * @translate config.diff.item.entity.Tag
     * @translate config.diff.item.entity.Term
     * @translate config.diff.item.entity.TextObject
     */
    this.entityText = this._t.instant(`config.diff.item.entity.${this.data.entity}`);
  }
}
