<iw-form *ngIf="form" class="form" [formGroup]="form" (ngSubmit)="submit()" iwFormSubmit>
  <iw-form-block>
    <iw-form-row>
      <label iwFormRowLabel>{{ 'lists-statuses.form.display-name' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <input iwInput type="text" formControlName="DISPLAY_NAME" data-qa="status-display-name" [tmAutofocus]="data$" />

        <!--
                @translate lists-statuses.validation.required
                @translate lists-statuses.validation.empty
                @translate lists-statuses.validation.maxlength
                @translate lists-statuses.validation.validationError
              -->
        <div iwErrorHints *ngFor="let error of getErrors('DISPLAY_NAME')" data-qa="status-display-name-error">
          {{ 'lists-statuses.validation.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>
    <iw-form-row>
      <label iwFormRowLabel>{{ 'lists-statuses.form.color' | translate }}</label>
      <tm-color-picker formControlName="COLOR" iwFormRowContent data-qa="status-color"></tm-color-picker>
    </iw-form-row>
    <iw-form-row>
      <label iwFormRowLabel>{{ 'lists-statuses.form.note' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <textarea iwInput formControlName="NOTE" maxlength="1025" data-qa="status-note"></textarea>

        <!--
          @translate lists-statuses.validation.maxlength
        -->
        <div iwErrorHints *ngFor="let error of getErrors('NOTE')">
          {{ 'lists-statuses.validation.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>
    <div class="dates" *ngIf="createDate && changeDate">
      {{ 'lists-statuses.created' | translate }}: <span [tmLocalizeDate]="createDate"></span> —
      {{ 'lists-statuses.changed' | translate }}: <span [tmLocalizeDate]="changeDate"></span>
    </div>
  </iw-form-block>

  <div class="footer">
    <iw-form-toolbar>
      <button
        iw-button
        type="submit"
        color="success"
        data-qa="status-save"
        [disabled]="form.invalid || form.disabled || form.status === 'PENDING'"
      >
        {{ 'lists-statuses.form.save' | translate }}
      </button>
      <button iw-button type="button" (click)="close()">
        {{ 'lists-statuses.form.cancel' | translate }}
      </button>
    </iw-form-toolbar>
  </div>
</iw-form>
