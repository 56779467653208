import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const supportedUrls = ['user/check', 'checkVersion', 'setting', 'license'];

export enum TmBackendErrorKey {
  'unknown' = 'unknown',
  'db' = 'db',
  'consul' = 'consul',
  'redis' = 'redis',
  'phpfpm' = 'phpfpm',
}

@Injectable({ providedIn: 'root' })
export class TmCommonErrorsInterceptor implements HttpInterceptor {
  /**
   * Emits localized error as an array: [error_key, error_title, errro_text]
   *
   * @example
   * commonErrors.localizedError$.pipe(
   *   filter(([key]) => !shownErrors.includes(key))
   * ).subscribe(([, title, text]) => notify(title, text));
   */
  public localizedError$: Subject<[string, string, string]> = new Subject();

  constructor(protected _t: TranslateService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this._checkUrl(req.url)) {
      return next.handle(req).pipe(
        catchError((event) => {
          this._handleError(event);
          return throwError(event);
        })
      );
    }

    return next.handle(req);
  }

  /**
   * Check if url should be intercepted
   */
  protected _checkUrl(url: string): boolean {
    return !!supportedUrls.find((item) => url.indexOf(`api/${item}`) > -1);
  }

  protected _getErrorKey(req: HttpErrorResponse): TmBackendErrorKey | null {
    // Ignore auth errors
    if ([401, 403].includes(req.status)) {
      return null;
    }

    // Check error key if any
    switch (req.error && req.error.error) {
      case 'database_problem':
        return TmBackendErrorKey.db;
      case 'redis_error':
        return TmBackendErrorKey.redis;
      case 'consul_not_found':
      case 'consul_error':
        return TmBackendErrorKey.consul;
    }

    // Check status code
    if (req.status === 502) {
      return TmBackendErrorKey.phpfpm;
    }

    // Fallback to unknown
    return TmBackendErrorKey.unknown;
  }

  protected _handleError(req: HttpErrorResponse): void {
    const errorKey = this._getErrorKey(req);

    if (!errorKey) {
      return;
    }

    /**
     * See TmBackendErrorKey for possible values:
     *
     * @translate tm.backendErrors.db
     * @translate tm.backendErrors.redis
     * @translate tm.backendErrors.consul
     * @translate tm.backendErrors.phpfpm
     * @translate tm.backendErrors.unknown
     */
    this.localizedError$.next([
      errorKey,
      this._t.instant('tm.backendErrors.commonTitle'),
      this._t.instant(`tm.backendErrors.${errorKey}`),
    ]);
  }
}
