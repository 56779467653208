import { Injectable } from '@angular/core';
import { PluginResponseData, UsesEvent, AddsEvent } from './plugin.model';
import {
  TmLicenseFeature,
  TmInterceptFeature,
  TmLicenseDataModel,
  TmImportFeature,
  TmAutoupdateFeature,
} from '../license';

type TmbbPluginLicense = TmLicenseDataModel & { pluginInterceptorFeatures: TmLicenseFeature[] };

/**
 * License status in order from worst (no license) to best (active license)
 */
enum LicenseStatus {
  none,
  expired,
  active,
}

interface InterceptorInfo {
  protocols: Set<string>;
  origins: Set<string>;
}

/**
 * Plugin license
 * Requirements: https://wiki.infowatch.ru/x/1y97Bw
 */
@Injectable()
export class TmPluginService {
  /**
   * Allows any event or protocol
   */
  public static anyValue = '*';

  /**
   * Value is undefined (allow any)
   */
  public static noneValue = 'NONE';

  /**
   * Check license for auto updaters
   * Requirements: https://wiki.infowatch.ru/x/tArrBw
   */
  public checkUpdaterLicense(plugin: PluginResponseData): LicenseStatus {
    let result = LicenseStatus.none;

    /**
     * Iterate over every plugin license feature
     */
    for (let i = 0; i < plugin.licenses.length; i++) {
      const license = plugin.licenses[i];

      /**
       * Backend collects only those licenses which are used by plugins,
       * frontend checks licenses against only one condition, expiration date.
       */
      result = Math.max(result, this.isLicenseExpired(license));
      if (result === LicenseStatus.active) {
        return result;
      }
    }

    return LicenseStatus.none;
  }

  /**
   * We always allow importers ("Alisa") to access data
   * Requirements: https://wiki.infowatch.ru/x/KrRvBw
   */
  public checkImporterLicense(): LicenseStatus {
    return LicenseStatus.active;
  }

  /**
   * Check interceptor license
   */
  public checkInterceptorLicense(
    plugin: PluginResponseData,
    eventType: string,
    protocol?: string,
    origin?: string[]
  ): LicenseStatus {
    let result = LicenseStatus.none;

    /**
     * Iterate over every plugin license feature
     */
    for (let i = 0; i < plugin.licenses.length; i++) {
      const license = plugin.licenses[i] as TmbbPluginLicense;

      for (let z = 0; z < license.pluginInterceptorFeatures.length; z++) {
        const feature = license.pluginInterceptorFeatures[z] as TmInterceptFeature;

        /**
         * Check event type
         */
        const typeOk = [eventType, TmPluginService.anyValue].includes(feature.object_type);

        /**
         * Check event protocol
         */
        const protocolOk =
          !protocol || [protocol, TmPluginService.noneValue, TmPluginService.anyValue].includes(feature.protocol);

        /**
         * Check event origin
         */
        const originOk = !origin || !feature.origin || origin.includes(feature.origin);

        /**
         * Check license expiration date
         */
        if (typeOk && protocolOk && originOk) {
          result = Math.max(result, this.isLicenseExpired(license));
          if (result === LicenseStatus.active) {
            return result;
          }
        }
      }
    }

    return LicenseStatus.none;
  }

  /**
   * Get plugin licenses with additional data
   * NOTE: This logic came from tmbb, we may improve it later when implementing Plugins in tmng.
   */
  public getPluginLicenses(plugin: PluginResponseData): TmbbPluginLicense[] {
    /**
     * Get all events and protocols used in plugin
     */
    const interceptors = this.getAllInterceptors(plugin);

    /**
     * Get only interceptor license features
     */
    return plugin.licenses.map((license) => {
      const filteredFeatures = license.features.filter((licenseFeature) => {
        if (this.isInterceptFeature(licenseFeature)) {
          return this.checkInterceptorFeature(plugin.VENDOR, interceptors, licenseFeature as TmInterceptFeature);
        }

        return false;
      });

      return Object.assign({}, license, {
        pluginInterceptorFeatures: filteredFeatures,
      });
    });
  }

  /**
   * Check if license is expired
   */
  private isLicenseExpired(license: TmLicenseDataModel): LicenseStatus {
    const date = new Date(license.issue_date);
    date.setDate(date.getDate() + license.active_days);

    return Date.now() > +date ? LicenseStatus.expired : LicenseStatus.active;
  }

  /**
   * Check if feature (license item) belongs to plugin
   */
  private checkInterceptorFeature(
    pluginVendor: string,
    pluginInterceptors: Map<string, InterceptorInfo>,
    feature: TmInterceptFeature
  ): boolean {
    /**
     * License should be emitted for correct vendor
     */
    if (!this.hasVendor(feature, pluginVendor)) {
      return false;
    }

    /**
     * License should allow ANY interceptor or have interceptor used by plugin
     */
    const interceptorOk =
      feature.object_type === TmPluginService.anyValue || pluginInterceptors.has(feature.object_type);

    if (!interceptorOk) {
      return false;
    }

    /**
     * Set interceptor scope (we may have ANY or particular one)
     */
    let interceptorScope: InterceptorInfo[];

    /**
     * Search in all interceptors if we have general license (without interceptor type)
     */
    if (feature.object_type === TmPluginService.anyValue) {
      interceptorScope = Array.from(pluginInterceptors.values());
    } else {
      /**
       * Search in particular interceptor
       */
      const interceptorInfo = pluginInterceptors.get(feature.object_type);
      interceptorScope = interceptorInfo ? [interceptorInfo] : [];
    }

    /**
     * License should be emitted for correct origin.
     */
    const originOk =
      !feature.origin ||
      feature.origin === TmPluginService.anyValue ||
      interceptorScope.some((i) => !i.origins.keys.length || i.origins.has(feature.origin as string));

    if (!originOk) {
      return false;
    }

    /**
     * Check protocols
     */
    const protocolOk =
      feature.protocol === TmPluginService.anyValue ||
      feature.protocol === TmPluginService.noneValue ||
      interceptorScope.some((i) => !i.protocols.keys.length || i.protocols.has(feature.protocol));

    if (!protocolOk) {
      return false;
    }

    return true;
  }

  /**
   * Extract all interceptors from plugin
   */
  private getAllInterceptors(plugin: PluginResponseData) {
    const interceptors = new Map<string, InterceptorInfo>();
    let interceptorTypes: (UsesEvent | AddsEvent)[] = [];

    if (plugin.uses_events) {
      interceptorTypes = interceptorTypes.concat(plugin.uses_events);
    }

    if (plugin.adds_events) {
      interceptorTypes = interceptorTypes.concat(plugin.adds_events);
    }

    interceptorTypes.forEach((interceptor) => {
      /**
       * Add interceptor by type
       */
      const eventType = interceptor.MNEMO;
      if (!interceptors.has(eventType)) {
        interceptors.set(eventType, { protocols: new Set<string>(), origins: new Set<string>() });
      }

      /**
       * Set all protocols
       */
      interceptor.protocols.forEach((protocol) => interceptors.get(eventType)?.protocols.add(protocol.MNEMO));

      /**
       * Set all origins (if any)
       */
      if ('origin' in interceptor) {
        interceptor.origin.forEach((origin) => interceptors.get(eventType)?.origins.add(origin));
      }
    });

    return interceptors;
  }

  private isInterceptFeature(feature: TmLicenseFeature): boolean {
    return 'object_type' in feature;
  }

  private hasVendor(feature: TmInterceptFeature | TmAutoupdateFeature | TmImportFeature, vendor: string): boolean {
    return [vendor, TmPluginService.anyValue].includes(feature.common_name);
  }
}
