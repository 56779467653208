import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { objectToFormData } from '@tm-shared/helpers/form';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmSidebarService } from '@tm-shared/structure/sidebar';
import { TmTreeNodeData } from '@tm-shared/tree';
import { BehaviorSubject, Observable, Subject, combineLatest, empty, merge, of, race, throwError, timer } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import * as ApiAdlibitum from './generated/adlibitum';
import { LDAP_ACTION_CREATE_SERVER, LDAP_ACTION_EDIT_SERVER, LDAP_ROUTE_BASE } from './ldap-consts';
import { TmLdapServerEditComponent } from './ldap-server-edit/ldap-server-edit.component';
import { TmLdapServerComponent } from './ldap-server/ldap-server.component';
import { LdapsCheckConnection, TmLdapService } from './ldap.service';

type RouterOutletComponents = TmLdapServerComponent | TmLdapServerEditComponent | null;

const POLLING_INTERVAL = 5000;
const POLLING_START_IN = 1000;

@Component({
  templateUrl: './ldap-page.component.html',
  styleUrls: ['./ldap-page.component.scss'],
  providers: [TmLdapService, TmSidebarService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmLdapPageComponent implements OnDestroy {
  public canCreate$: Observable<boolean>;
  public canEdit$: Observable<boolean>;
  public canRemove$: Observable<boolean>;
  public canStartSync$: Observable<boolean>;
  public ldapMenu$: Observable<TmTreeNodeData[]>;
  public selectedId$: BehaviorSubject<string | null>;
  public selectedServerName$: Observable<string>;
  public outletActivated$ = new BehaviorSubject<boolean>(false);

  private _destroy$: Subject<void> = new Subject();
  private _routerOutletActivate$: Subject<RouterOutletComponents> = new Subject();
  private _routerOutletDeactivate$: Subject<RouterOutletComponents> = new Subject();
  private _selectedServer$: Observable<ApiAdlibitum.AdlibitumInfoGet | null>;
  private _updateServerList$: Subject<void>;
  private setSyncProgressForServer = new Subject<string>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _ldapService: TmLdapService,
    private _t: TranslateService,
    private _privileges: TmPrivilegesService,
    private _notify: IwNotificationsService
  ) {
    this._updateServerList$ = new Subject();
    this.selectedId$ = new BehaviorSubject(null);

    this._selectedServer$ = this.selectedId$.pipe(
      switchMap((id: string) => {
        if (!id) {
          return of(null);
        }

        return merge(
          this._ldapService.getById(id, true),
          this.setSyncProgressForServer.pipe(
            filter((msg) => msg === id),
            switchMap(() => this.pollUntilSyncFinished(id))
          )
        );
      }),
      shareReplay(1)
    );

    this.selectedServerName$ = this._selectedServer$.pipe(map((data) => (data ? data.display_name : '')));

    this.ldapMenu$ = this._updateServerList$.pipe(
      startWith(null),
      switchMap(() => this._ldapService.get()),
      map((response) => response.data.map((serverItem) => this._ldapServerItemToLdapMenuNode(serverItem)))
    );

    this._routerOutletDeactivate$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.selectedId$.next(null);
      this.outletActivated$.next(false);
    });

    this._routerOutletActivate$.pipe(takeUntil(this._destroy$)).subscribe((component) => {
      this._route
        .firstChild!.paramMap.pipe(takeUntil(this._routerOutletActivate$), takeUntil(this._routerOutletDeactivate$))
        .subscribe((params) => {
          this.selectedId$.next(params.get('id'));
          this.outletActivated$.next(true);
        });
      this._setupRouterOutletComponent(component);
    });

    this.canStartSync$ = combineLatest([this._selectedServer$, this._privileges.can('settings:ldap')]).pipe(
      map(([server, ok]) => Boolean(server && !server.sync_in_progress && ok))
    );
    this.canEdit$ = combineLatest([this._selectedServer$, this._privileges.can('settings:ldap:edit')]).pipe(
      map(([server, ok]) => Boolean(server && !server.sync_in_progress && ok))
    );
    this.canRemove$ = combineLatest([this._selectedServer$, this._privileges.can('settings:ldap:edit')]).pipe(
      map(([server, ok]) => Boolean(server && !server.sync_in_progress && ok))
    );
    this.canCreate$ = this._privileges.can('settings:ldap:edit');
  }

  private pollUntilSyncFinished(id: string) {
    return timer(POLLING_START_IN, POLLING_INTERVAL).pipe(
      switchMap(() => this._ldapService.getById(id, true)),
      takeWhile((item) => !!item.sync_in_progress, true),
      catchError(() => of(null))
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public deselectServer(): void {
    this._router.navigate([LDAP_ROUTE_BASE]);
  }

  public showServerDetailsById(id: any): void {
    this._router.navigate([LDAP_ROUTE_BASE, id]);
  }

  public onRouterOutletActivate(component: RouterOutletComponents) {
    this._routerOutletActivate$.next(component);
  }

  public onRouterOutletDeactivate() {
    this._routerOutletDeactivate$.next();
  }

  public createServer(): void {
    this._router.navigate([LDAP_ROUTE_BASE, LDAP_ACTION_CREATE_SERVER]);
  }

  public editServer(): void {
    const id = this.selectedId$.getValue();

    if (id !== null) {
      this._router.navigate([LDAP_ROUTE_BASE, id, LDAP_ACTION_EDIT_SERVER]);
      this._updateServerList$.next();
    }
  }

  public deleteServer(): void {
    const id = this.selectedId$.getValue();

    if (id !== null) {
      this._ldapService
        .remove(id)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: () => {
            this._router.navigate([LDAP_ROUTE_BASE]);
            this._updateServerList$.next();
          },
          error: (res: HttpErrorResponse) => {
            const error: ApiAdlibitum.AdlibitumDeleteError = res.error;
            const errorTitle = this._t.instant('settings-ldap.ldapServerDelete.error');
            let errorText;

            if (error.error === 'imported_user_exists') {
              const users = error.meta.users.map((user: any) => user.DISPLAY_NAME).join(', ');
              errorText = this._t.instant('settings-ldap.ldapServerDelete.userExists', { users: users });
            } else {
              errorText = '';
            }

            this._notify.error(errorTitle, errorText);
          },
        });
    }
  }

  public startServerSync(): void {
    const id = this.selectedId$.getValue();

    if (id !== null) {
      this.setSyncProgressForServer.next(id);
      this._ldapService
        .syncServer(id)
        .pipe(
          take(1),
          takeUntil(this._destroy$),
          catchError(() => {
            this._onServerSyncError();
            return empty();
          })
        )
        .subscribe(() => this._onServerSyncSuccess());
    }
  }

  private _checkServerConnection(data: LdapsCheckConnection): Observable<string> {
    const serverId = this.selectedId$.getValue();
    return this._ldapService.checkServerConnection(data, serverId ? serverId : undefined).pipe(
      mapTo(data.data.display_name),
      catchError(() => {
        this._onServerConnectionError(data.data.display_name);
        return empty();
      })
    );
  }

  private _setupRouterOutletComponent(component: RouterOutletComponents): void {
    if (component instanceof TmLdapServerComponent) {
      this._setupServerComponent(component);
    } else if (component instanceof TmLdapServerEditComponent) {
      this._setupServerEditComponent(component);
    }
  }

  private _ldapServerItemToLdapMenuNode(serverItem: ApiAdlibitum.AdlibitumInfoGet): TmTreeNodeData {
    return {
      id: serverItem.name,
      name: serverItem.display_name,
    };
  }

  private _setupServerComponent(component: TmLdapServerComponent): void {
    // Update data on server selection changed and by polling
    this.selectedId$
      .pipe(
        distinctUntilChanged(),
        switchMap((id) => {
          // Show loading state on server id changed
          component.data = null;
          return id ? this._selectedServer$ : of(null);
        }),
        takeUntil(this._destroy$),
        takeUntil(this._routerOutletActivate$),
        takeUntil(this._routerOutletDeactivate$)
      )
      .subscribe((data) => (component.data = data));

    component.check
      .pipe(
        switchMap((data) => this._checkServerConnection(data)),
        takeUntil(this._destroy$),
        takeUntil(this._routerOutletActivate$),
        takeUntil(this._routerOutletDeactivate$)
      )
      .subscribe((name) => this._onServerConnectionSuccess(name));
  }

  private _setupServerEditComponent(component: TmLdapServerEditComponent): void {
    const serverId = this.selectedId$.getValue();

    if (!serverId) {
      component.data = null;
    } else {
      this._ldapService
        .getById(serverId)
        .pipe(
          takeUntil(this._destroy$),
          takeUntil(this._routerOutletActivate$),
          takeUntil(this._routerOutletDeactivate$),
          takeUntil(component.destroy$)
        )
        .subscribe((data) => (component.data = data));
    }

    component.check
      .pipe(
        switchMap((data) => this._checkServerConnection(data)),
        takeUntil(this._destroy$),
        takeUntil(this._routerOutletActivate$),
        takeUntil(this._routerOutletDeactivate$)
      )
      .subscribe((name) => this._onServerConnectionSuccess(name));

    race([component.close$, component.submit$])
      .pipe(
        switchMap(() => this.selectedId$),
        takeUntil(this._destroy$),
        takeUntil(this._routerOutletActivate$),
        takeUntil(this._routerOutletDeactivate$)
      )
      .subscribe((id) => (id ? this.showServerDetailsById(id) : this.deselectServer()));

    component.onSubmit = () => {
      const id = this.selectedId$.getValue();
      return (!id
        ? this._ldapService.create(objectToFormData(component.serialize('', { excludeDisabled: true })))
        : this._ldapService.updateById(id, <any>objectToFormData(component.serialize('', { excludePristine: true })))
      ).pipe(
        catchError((e) => {
          this._onServerSaveError();
          return throwError(e);
        }),
        tap(() => this._updateServerList$.next()),
        takeUntil(this._destroy$),
        takeUntil(this._routerOutletActivate$),
        takeUntil(this._routerOutletDeactivate$)
      );
    };
  }

  private _onServerSaveError(): void {
    this._notify.error(
      this._t.instant('settings-ldap.ldapServerSave.title'),
      this._t.instant('settings-ldap.ldapServerSave.error')
    );
  }

  // eslint-disable-next-line
  private _onServerConnectionError(name?: string): void {
    let serverErrorText = this._t.instant('settings-ldap.ldapConnectionCheck.error');
    if (name && name.trim()) {
      serverErrorText = `${name}: ${serverErrorText}`;
    }

    this._notify.error(this._t.instant('settings-ldap.ldapConnectionCheck.title'), serverErrorText);
  }

  private _onServerConnectionSuccess(name?: string): void {
    let serverErrorText = this._t.instant('settings-ldap.ldapConnectionCheck.success');
    if (name && name.trim()) {
      serverErrorText = `${name}: ${serverErrorText}`;
    }

    this._notify.success(this._t.instant('settings-ldap.ldapConnectionCheck.title'), serverErrorText);
  }

  // eslint-disable-next-line
  private _onServerSyncError(): void {
    this.selectedServerName$
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe((name) =>
        this._notify.error(
          this._t.instant('settings-ldap.ldapSync.title'),
          this._t.instant('settings-ldap.ldapSync.error', { name })
        )
      );
  }

  // eslint-disable-next-line
  private _onServerSyncSuccess(): void {
    this.selectedServerName$
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe((name) =>
        this._notify.success(
          this._t.instant('settings-ldap.ldapSync.title'),
          this._t.instant('settings-ldap.ldapSync.success', { name })
        )
      );
  }
}
