<tm-page class="page" data-qa="ldapPageContent">
  <tm-sidebar title="{{ 'settings-ldap.pageTitle' | translate }}">
    <tm-toolbar class="toolbar">
      <button
        iw-icon-button
        data-qa="addServerBtn"
        type="button"
        [disabled]="!(canCreate$ | async)"
        name="{{ 'settings-ldap.page.toolbar.create' | translate }}'"
        (click)="createServer()"
      >
        <tm-icon svgIcon="create"></tm-icon>
      </button>
      <button
        iw-icon-button
        data-qa="editServerBtn"
        type="button"
        [disabled]="!(canEdit$ | async)"
        name="{{ 'settings-ldap.page.toolbar.edit' | translate }}"
        (click)="editServer()"
      >
        <tm-icon svgIcon="edit"></tm-icon>
      </button>
      <button
        (tmDeleteConfirm)="deleteServer()"
        [tmDeleteConfirmItems]="selectedServerName$ | async"
        iw-icon-button
        data-qa="removetServerBtn"
        type="button"
        [disabled]="!(canRemove$ | async)"
        name="{{ 'settings-ldap.page.toolbar.remove' | translate }}"
      >
        <tm-icon svgIcon="delete"></tm-icon>
      </button>
      <button
        [disabled]="!(canStartSync$ | async)"
        data-qa="serverOptionsBtn"
        iw-icon-button
        #optionsPopover="iwPopover"
        [iwPopover]="otherOptionsPopover"
        placement="bottom-start"
      >
        <tm-icon svgIcon="list"></tm-icon>
      </button>
    </tm-toolbar>

    <tm-tree
      class="ldap-menu"
      data-qa="ldap-menu"
      [focusedNodeId]="selectedId$ | async"
      [data]="ldapMenu$ | async"
      (onActiveNode)="showServerDetailsById($event.node.id)"
    >
    </tm-tree>
  </tm-sidebar>
  <tm-content [title]="selectedServerName$ | async">
    <router-outlet
      #mainOutlet
      (activate)="onRouterOutletActivate($event)"
      (deactivate)="onRouterOutletDeactivate()"
    ></router-outlet>
    <tm-filler-text
      *ngIf="!(outletActivated$ | async)"
      text="{{ 'settings-ldap.serverNotSelected' | translate }}"
    ></tm-filler-text>
  </tm-content>
</tm-page>

<ng-template #otherOptionsPopover>
  <div class="dropdown">
    <button data-qa="syncServerBtn" [disabled]="!(canStartSync$ | async)" (click)="startServerSync()">
      {{ 'settings-ldap.page.toolbar.startSync' | translate }}
    </button>
  </div>
</ng-template>
