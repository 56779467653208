import { Route } from '@angular/router';
import { CanDeactivateTmForm } from '@tm-shared/form';
import { LDAP_ACTION_CREATE_SERVER, LDAP_ACTION_EDIT_SERVER, LDAP_ROUTE_BASE } from './ldap-consts';
import { TmLdapPageComponent } from './ldap-page.component';
import { TmLdapServerEditComponent } from './ldap-server-edit/ldap-server-edit.component';
import { TmLdapServerComponent } from './ldap-server/ldap-server.component';

export const routes: Route[] = [
  {
    path: LDAP_ROUTE_BASE,
    component: TmLdapPageComponent,
    data: {
      usePrivilege: 'settings/ldap',
    },
    children: [
      {
        path: `${LDAP_ACTION_CREATE_SERVER}`,
        component: TmLdapServerEditComponent,
        canDeactivate: [CanDeactivateTmForm],
        data: {
          usePrivilege: 'settings/ldap/edit',
        },
      },
      {
        path: ':id',
        component: TmLdapServerComponent,
        data: {
          usePrivilege: 'settings/ldap',
        },
      },
      {
        path: `:id/${LDAP_ACTION_EDIT_SERVER}`,
        component: TmLdapServerEditComponent,
        canDeactivate: [CanDeactivateTmForm],
        data: {
          usePrivilege: 'settings/ldap/edit',
        },
      },
    ],
  },
];
