import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TmSpinnerComponent } from './spinner.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TmSpinnerComponent],
  entryComponents: [TmSpinnerComponent],
  exports: [TmSpinnerComponent],
})
export class TmSpinnerModule {}
