import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmElement } from '@tm-shared/custom-elements';
import { DateTime } from 'luxon';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { TmConfigWidgetService } from './config-widget.service';

/**
 * TODO: complete after /api/config.* is updated
 *
 * Presentational (dumb) component, it shows summary for all types of available configuration.
 * Local configuration is required, others are optional.
 */
@TmElement('tme-config-widget')
@Component({
  selector: 'tm-config-widget',
  templateUrl: './config-widget.component.html',
  styleUrls: ['./config-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmConfigWidgetComponent implements OnInit, OnDestroy {
  @Input() public agentConfig?: TmPluginConfig.widget.Config;

  @Input() public inboundConfig?: TmPluginConfig.widget.InboundConfig;

  @Input() public localConfig?: TmPluginConfig.widget.LocalConfig;

  @Input() public outboundConfig?: TmPluginConfig.widget.Config;

  @Input() public queuedInboundConfig?: TmPluginConfig.widget.InboundConfig;

  @Output() public fetchQueued: EventEmitter<void> = new EventEmitter();

  @Output() public goToAffiliatePage: EventEmitter<void> = new EventEmitter();

  @Output() public goToConfigDiffPage: EventEmitter<void> = new EventEmitter();

  @Output() public goToFillPolicyPage: EventEmitter<void> = new EventEmitter();

  public configButtonText: string;

  public localConfigurationStatusText: string;

  private _destroy$ = new Subject<void>();

  constructor(
    private _t: TranslateService,
    public cd: ChangeDetectorRef,
    @Optional() private _service: TmConfigWidgetService
  ) {}

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnInit() {
    if (this._service) {
      this._listenToService();
    }

    this.goToAffiliatePage.pipe(takeUntil(this._destroy$)).subscribe(() => this._service.goToAffiliatePage());

    this.goToConfigDiffPage.pipe(takeUntil(this._destroy$)).subscribe(() => this._service.goToConfigDiffPage());

    this.goToFillPolicyPage.pipe(takeUntil(this._destroy$)).subscribe(() => this._service.goToFillPolicyPage());

    this.fetchQueued.pipe(takeUntil(this._destroy$)).subscribe(() => this._service.fetchQueued());
  }

  public popoverVisibilityChanged(_visible: boolean): void {
    this.cd.detectChanges();
  }

  public formatTimestamp(timestamp: number): string {
    return DateTime.fromMillis(timestamp).toFormat('D T');
  }

  private _listenToService() {
    const doUntilDestroy = <T = any>(stream$: Observable<T>, cb: (data: T) => void): void => {
      stream$
        .pipe(
          takeUntil(this._destroy$),
          tap(cb),
          tap(() => this.cd.detectChanges())
        )
        .subscribe();
    };

    doUntilDestroy(
      (this._service.agentConfig$ as unknown) as Observable<TmPluginConfig.widget.LocalConfig>,
      (data) => (this.agentConfig = data)
    );
    doUntilDestroy(
      (this._service.inboundConfig$ as unknown) as Observable<TmPluginConfig.widget.Config>,
      (data) => (this.inboundConfig = data)
    );
    doUntilDestroy(
      (this._service.outboundConfig$ as unknown) as Observable<TmPluginConfig.widget.Config>,
      (data) => (this.outboundConfig = data)
    );
    doUntilDestroy(
      (this._service.queuedInboundConfig$ as unknown) as Observable<TmPluginConfig.widget.Config>,
      (data) => (this.queuedInboundConfig = data)
    );
    doUntilDestroy((this._service.localConfig$ as unknown) as Observable<TmPluginConfig.widget.LocalConfig>, (data) => {
      this.localConfig = data;
      this._updateButtonText();
      this._updateConfigStatusText();
    });
  }

  private _updateButtonText(): void {
    if (!this.localConfig) {
      this.configButtonText = '';

      return;
    }

    switch (this.localConfig.state) {
      case 'applied':
        this.configButtonText = this._t.instant('config.widget.button.applied');
        return;
      case 'locked':
        this.configButtonText = this._t.instant('config.widget.button.locked');
        return;
      case 'default':
        this.configButtonText = this._t.instant('config.widget.button.default');
        return;
      case 'loading':
        this.configButtonText = this._t.instant('config.widget.button.loading');
        return;
      case 'editedByActiveUser':
        this.configButtonText = this._t.instant('config.widget.button.editing');
        return;
      default:
        this.configButtonText = `${this.localConfig.state}`;
        return;
    }
  }

  private _updateConfigStatusText() {
    if (!this.localConfig) {
      return;
    }

    const { state, username, timestamp } = this.localConfig;

    const statusTextParts: string[] = [];

    switch (state) {
      case 'applied':
        statusTextParts.push(this._t.instant('config.widget.title.applied'));
        break;
      case 'locked':
        statusTextParts.push(this._t.instant('config.widget.title.locked'));
        break;
      case 'default':
        statusTextParts.push(this._t.instant('config.widget.title.default'));
        break;
      case 'loading':
        statusTextParts.push(this._t.instant('config.widget.title.loading'));
        break;
      case 'editedByActiveUser':
        statusTextParts.push(this._t.instant('config.widget.title.editing'));
        break;
      default:
        statusTextParts.push(`${this.localConfig.state}`);
        break;
    }

    if (username && state === 'locked') {
      statusTextParts.push(
        this._t.instant('config.widget.title.byUser', {
          user: username,
        })
      );
    }

    if (['editedByActiveUser', 'locked'].indexOf(state) > -1) {
      statusTextParts.push(
        this._t.instant('config.widget.title.dateFrom', {
          date: DateTime.fromMillis(timestamp).toFormat('DD HH:mm'),
        })
      );
    }

    this.localConfigurationStatusText = statusTextParts.join(' ');
  }
}
