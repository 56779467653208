import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TmSpinnerModule } from '@tm-shared/spinner';
import { TmLazyBlocksComponent } from './lazy-blocks.component';

@NgModule({
  imports: [CommonModule, TmSpinnerModule],
  declarations: [TmLazyBlocksComponent],
  entryComponents: [TmLazyBlocksComponent],
})
export class TmLazyBlocksElementsModule {}
