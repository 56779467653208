import { Injectable, Type } from '@angular/core';
import { AuditEvent, AuditEventEntityType } from './audit.model';
import { TmAuditServiceModule } from './audit-service.module';
import { getPolicyGridData } from './audit-parsers/audit-policy';
import { getUnknownGridData } from './audit-parsers/audit-common';
import { TranslateService } from '@ngx-translate/core';
import { getUserGridData } from './audit-parsers/audit-user';
import { getEventQueryGridData } from './audit-parsers/audit-event-query';
import { getProtectedCatalogGrids } from './audit-parsers/audit-protected-catalog';
import { getEventObjectGridData } from './audit-parsers/audit-object-query';
import { getProtectedDocumentGrids } from './audit-parsers/audit-protected-document';
import { getPerimeterGridData } from './audit-parsers/audit-perimeter';
import { getAutolingGridData } from './audit-parsers/audit-entries-autoling';
import { getTextObjectGridData } from './audit-parsers/audit-entries-text-object';
import { getCategoryGridData } from './audit-parsers/audit-entries-category';
import { getCategoryAutolingGridData } from './audit-parsers/audit-entries-category-autoling';
import { getFingerprintGridData } from './audit-parsers/audit-entries-fingerprint';
import { getImageClassifierGridData } from './audit-parsers/audit-entries-image-classifier';
import { getEtFormGridData } from './audit-parsers/audit-entries-form';
import { getCategoryGraphicGridData } from './audit-parsers/audit-entries-category-graphic';
import { getEtTableGridData } from './audit-parsers/audit-entries-table';
import { getEtStampGridData } from './audit-parsers/audit-entries-stamp';
import { getTermGridData } from './audit-parsers/audit-entries-term';
import { getEventQueryFolderGridData } from './audit-parsers/audit-event-query-folder';

/**
 * Grid column keys (data attributes) which may contain data
 */
export enum AuditGridDataColumn {
  'old' = 'old',
  'new' = 'new',
  'request' = 'request',
}

/**
 * Grid column key which contain localized row description
 */
export const AuditGridNameColumn = 'name';

/**
 * All available grid common keys
 */
export type AuditGridColumn = AuditGridDataColumn | typeof AuditGridNameColumn;

export interface AuditGridCellRenderer<T> {
  data?: T;
}

/**
 * Each field should contain parsed and localized data.
 * Attribute field is required, it contains localized title for the row values.
 *
 * @example
 * {
 *  data: {
 *    attribute: 'Display name'
 *    new: 'New rule 01'
 *  }
 * }
 */
export type AuditExtendRowData<T = unknown> = {
  component?: Type<AuditGridCellRenderer<T>>;
  [AuditGridNameColumn]: string;
  [AuditGridDataColumn.request]?: T;
  [AuditGridDataColumn.old]?: T;
  [AuditGridDataColumn.new]?: T;
};

/**
 * Title is optional,
 * cols - a list of visible column keys
 * data - contains values mapped by column keys
 *
 * @example
 * {
 *  title: 'Policy rule'
 *  cols: ['new']
 *  data: {
 *    attribute: 'Display name'
 *    new: 'New rule 01'
 *  }
 * }
 */
export type AuditExtendGridData = {
  title?: string;
  cols: AuditGridDataColumn[];
  data: AuditExtendRowData[];
};

@Injectable({
  providedIn: TmAuditServiceModule,
})
export class TmAuditExtendService {
  constructor(private t: TranslateService) {}
  /**
   * Each audit extend grid contains parsed and localized data.
   * Grid title is optional.
   */
  public getAuditExtendGrids(data: AuditEvent): AuditExtendGridData[] {
    switch (data.ENTITY_TYPE as AuditEventEntityType) {
      case AuditEventEntityType.Autoling:
        return getAutolingGridData(data, this.t);
      case AuditEventEntityType.Policy:
        return getPolicyGridData(data, this.t);
      case AuditEventEntityType.User:
        return getUserGridData(data, this.t);
      case AuditEventEntityType.Query:
        return getEventQueryGridData(data, this.t);
      case AuditEventEntityType.Export:
        return getEventObjectGridData(data, this.t);
      case AuditEventEntityType.QueryFolder:
        return getEventQueryFolderGridData(data, this.t);
      case AuditEventEntityType.ProtectedCatalog:
        return getProtectedCatalogGrids(data, this.t);
      case AuditEventEntityType.ProtectedDocument:
        return getProtectedDocumentGrids(data, this.t);
      case AuditEventEntityType.Object:
        return getEventObjectGridData(data, this.t);
      case AuditEventEntityType.Perimeter:
        return getPerimeterGridData(data, this.t);
      case AuditEventEntityType.TextObject:
        return getTextObjectGridData(data, this.t);
      case AuditEventEntityType.Category:
        return getCategoryGridData(data, this.t);
      case AuditEventEntityType.CategoryAutoling:
        return getCategoryAutolingGridData(data, this.t);
      case AuditEventEntityType.Term:
        return getTermGridData(data, this.t);
      case AuditEventEntityType.Fingerprint:
        return getFingerprintGridData(data, this.t);
      case AuditEventEntityType.EtForm:
        return getEtFormGridData(data, this.t);
      case AuditEventEntityType.EtTable:
        return getEtTableGridData(data, this.t);
      case AuditEventEntityType.EtStamp:
        return getEtStampGridData(data, this.t);
      case AuditEventEntityType.ImageClassifier:
        return getImageClassifierGridData(data, this.t);
      case AuditEventEntityType.CategoryGraphic:
        return getCategoryGraphicGridData(data, this.t);
      default:
        return getUnknownGridData(data, this.t);
    }
  }
}
