/**
 * Contain Unicode letters for every available language
 */
export const abcLettersArray: string[] = [
  '\u0410-\u042F', // "а-я"
  '\u0430-\u044F', // "а-я"
  '\u0041-\u005A', // "A-Z"
  '\u0061-\u007A', // "a-z"
  '\u00AA', // "ª"
  '\u00B5', // "µ"
  '\u00BA', // "º"
  '\u00C0-\u00D6', // "À-Ö"
  '\u00D8-\u00F6', // "Ø-ö"
  '\u00F8-\u02C1', // "ø-ˁ"
  '\u02C6-\u02D1', // "ˆ-ˑ"
  '\u02E0-\u02E4', // "ˠ-ˤ"
  '\u02EC', // "ˬ"
  '\u02EE', // "ˮ"
  '\u0370-\u0374', // "Ͱ-ʹ"
  '\u0376', // "Ͷ"
  '\u0377', // "ͷ"
  '\u037A-\u037D', // "ͺ-ͽ"
  '\u0386', // "Ά"
  '\u0388-\u038A', // "Έ-Ί"
  '\u038C', // "Ό"
  '\u038E-\u03A1', // "Ύ-Ρ"
  '\u03A3-\u03F5', // "Σ-ϵ"
  '\u03F7-\u0481', // "Ϸ-ҁ"
  '\u048A-\u0527', // "Ҋ-ԧ"
  '\u0531-\u0556', // "Ա-Ֆ"
  '\u0559', // "ՙ"
  '\u0561-\u0587', // "ա-և"
  '\u05D0-\u05EA', // "א-ת"
  '\u05F0-\u05F2', // "װ-ײ"
  '\u0620-\u064A', // "ؠ-ي"
  '\u066E', // "ٮ"
  '\u066F', // "ٯ"
  '\u0671-\u06D3', // "ٱ-ۓ"
  '\u06D5', // "ە"
  '\u06E5', // "ۥ"
  '\u06E6', // "ۦ"
  '\u06EE', // "ۮ"
  '\u06EF', // "ۯ"
  '\u06FA-\u06FC', // "ۺ-ۼ"
  '\u06FF', // "ۿ"
  '\u0710', // "ܐ"
  '\u0712-\u072F', // "ܒ-ܯ"
  '\u074D-\u07A5', // "ݍ-ޥ"
  '\u07B1', // "ޱ"
  '\u07CA-\u07EA', // "ߊ-ߪ"
  '\u07F4', // "ߴ"
  '\u07F5', // "ߵ"
  '\u07FA', // "ߺ"
  '\u0800-\u0815', // "ࠀ-ࠕ"
  '\u081A', // "ࠚ"
  '\u0824', // "ࠤ"
  '\u0828', // "ࠨ"
  '\u0840-\u0858', // "ࡀ-ࡘ"
  '\u08A0', // "ࢠ"
  '\u08A2-\u08AC', // "ࢢ-ࢬ"
  '\u0904-\u0939', // "ऄ-ह"
  '\u093D', // "ऽ"
  '\u0950', // "ॐ"
  '\u0958-\u0961', // "क़-ॡ"
  '\u0971-\u0977', // "ॱ-ॷ"
  '\u0979-\u097F', // "ॹ-ॿ"
  '\u0985-\u098C', // "অ-ঌ"
  '\u098F', // "এ"
  '\u0990', // "ঐ"
  '\u0993-\u09A8', // "ও-ন"
  '\u09AA-\u09B0', // "প-র"
  '\u09B2', // "ল"
  '\u09B6-\u09B9', // "শ-হ"
  '\u09BD', // "ঽ"
  '\u09CE', // "ৎ"
  '\u09DC', // "ড়"
  '\u09DD', // "ঢ়"
  '\u09DF-\u09E1', // "য়-ৡ"
  '\u09F0', // "ৰ"
  '\u09F1', // "ৱ"
  '\u0A05-\u0A0A', // "ਅ-ਊ"
  '\u0A0F', // "ਏ"
  '\u0A10', // "ਐ"
  '\u0A13-\u0A28', // "ਓ-ਨ"
  '\u0A2A-\u0A30', // "ਪ-ਰ"
  '\u0A32', // "ਲ"
  '\u0A33', // "ਲ਼"
  '\u0A35', // "ਵ"
  '\u0A36', // "ਸ਼"
  '\u0A38', // "ਸ"
  '\u0A39', // "ਹ"
  '\u0A59-\u0A5C', // "ਖ਼-ੜ"
  '\u0A5E', // "ਫ਼"
  '\u0A72-\u0A74', // "ੲ-ੴ"
  '\u0A85-\u0A8D', // "અ-ઍ"
  '\u0A8F-\u0A91', // "એ-ઑ"
  '\u0A93-\u0AA8', // "ઓ-ન"
  '\u0AAA-\u0AB0', // "પ-ર"
  '\u0AB2', // "લ"
  '\u0AB3', // "ળ"
  '\u0AB5-\u0AB9', // "વ-હ"
  '\u0ABD', // "ઽ"
  '\u0AD0', // "ૐ"
  '\u0AE0', // "ૠ"
  '\u0AE1', // "ૡ"
  '\u0B05-\u0B0C', // "ଅ-ଌ"
  '\u0B0F', // "ଏ"
  '\u0B10', // "ଐ"
  '\u0B13-\u0B28', // "ଓ-ନ"
  '\u0B2A-\u0B30', // "ପ-ର"
  '\u0B32', // "ଲ"
  '\u0B33', // "ଳ"
  '\u0B35-\u0B39', // "ଵ-ହ"
  '\u0B3D', // "ଽ"
  '\u0B5C', // "ଡ଼"
  '\u0B5D', // "ଢ଼"
  '\u0B5F-\u0B61', // "ୟ-ୡ"
  '\u0B71', // "ୱ"
  '\u0B83', // "ஃ"
  '\u0B85-\u0B8A', // "அ-ஊ"
  '\u0B8E-\u0B90', // "எ-ஐ"
  '\u0B92-\u0B95', // "ஒ-க"
  '\u0B99', // "ங"
  '\u0B9A', // "ச"
  '\u0B9C', // "ஜ"
  '\u0B9E', // "ஞ"
  '\u0B9F', // "ட"
  '\u0BA3', // "ண"
  '\u0BA4', // "த"
  '\u0BA8-\u0BAA', // "ந-ப"
  '\u0BAE-\u0BB9', // "ம-ஹ"
  '\u0BD0', // "ௐ"
  '\u0C05-\u0C0C', // "అ-ఌ"
  '\u0C0E-\u0C10', // "ఎ-ఐ"
  '\u0C12-\u0C28', // "ఒ-న"
  '\u0C2A-\u0C33', // "ప-ళ"
  '\u0C35-\u0C39', // "వ-హ"
  '\u0C3D', // "ఽ"
  '\u0C58', // "ౘ"
  '\u0C59', // "ౙ"
  '\u0C60', // "ౠ"
  '\u0C61', // "ౡ"
  '\u0C85-\u0C8C', // "ಅ-ಌ"
  '\u0C8E-\u0C90', // "ಎ-ಐ"
  '\u0C92-\u0CA8', // "ಒ-ನ"
  '\u0CAA-\u0CB3', // "ಪ-ಳ"
  '\u0CB5-\u0CB9', // "ವ-ಹ"
  '\u0CBD', // "ಽ"
  '\u0CDE', // "ೞ"
  '\u0CE0', // "ೠ"
  '\u0CE1', // "ೡ"
  '\u0CF1', // "ೱ"
  '\u0CF2', // "ೲ"
  '\u0D05-\u0D0C', // "അ-ഌ"
  '\u0D0E-\u0D10', // "എ-ഐ"
  '\u0D12-\u0D3A', // "ഒ-ഺ"
  '\u0D3D', // "ഽ"
  '\u0D4E', // "ൎ"
  '\u0D60', // "ൠ"
  '\u0D61', // "ൡ"
  '\u0D7A-\u0D7F', // "ൺ-ൿ"
  '\u0D85-\u0D96', // "අ-ඖ"
  '\u0D9A-\u0DB1', // "ක-න"
  '\u0DB3-\u0DBB', // "ඳ-ර"
  '\u0DBD', // "ල"
  '\u0DC0-\u0DC6', // "ව-ෆ"
  '\u0E01-\u0E30', // "ก-ะ"
  '\u0E32', // "า"
  '\u0E33', // "ำ"
  '\u0E40-\u0E46', // "เ-ๆ"
  '\u0E81', // "ກ"
  '\u0E82', // "ຂ"
  '\u0E84', // "ຄ"
  '\u0E87', // "ງ"
  '\u0E88', // "ຈ"
  '\u0E8A', // "ຊ"
  '\u0E8D', // "ຍ"
  '\u0E94-\u0E97', // "ດ-ທ"
  '\u0E99-\u0E9F', // "ນ-ຟ"
  '\u0EA1-\u0EA3', // "ມ-ຣ"
  '\u0EA5', // "ລ"
  '\u0EA7', // "ວ"
  '\u0EAA', // "ສ"
  '\u0EAB', // "ຫ"
  '\u0EAD-\u0EB0', // "ອ-ະ"
  '\u0EB2', // "າ"
  '\u0EB3', // "ຳ"
  '\u0EBD', // "ຽ"
  '\u0EC0-\u0EC4', // "ເ-ໄ"
  '\u0EC6', // "ໆ"
  '\u0EDC-\u0EDF', // "ໜ-ໟ"
  '\u0F00', // "ༀ"
  '\u0F40-\u0F47', // "ཀ-ཇ"
  '\u0F49-\u0F6C', // "ཉ-ཬ"
  '\u0F88-\u0F8C', // "ྈ-ྌ"
  '\u1000-\u102A', // "က-ဪ"
  '\u103F', // "ဿ"
  '\u1050-\u1055', // "ၐ-ၕ"
  '\u105A-\u105D', // "ၚ-ၝ"
  '\u1061', // "ၡ"
  '\u1065', // "ၥ"
  '\u1066', // "ၦ"
  '\u106E-\u1070', // "ၮ-ၰ"
  '\u1075-\u1081', // "ၵ-ႁ"
  '\u108E', // "ႎ"
  '\u10A0-\u10C5', // "Ⴀ-Ⴥ"
  '\u10C7', // "Ⴧ"
  '\u10CD', // "Ⴭ"
  '\u10D0-\u10FA', // "ა-ჺ"
  '\u10FC-\u1248', // "ჼ-ቈ"
  '\u124A-\u124D', // "ቊ-ቍ"
  '\u1250-\u1256', // "ቐ-ቖ"
  '\u1258', // "ቘ"
  '\u125A-\u125D', // "ቚ-ቝ"
  '\u1260-\u1288', // "በ-ኈ"
  '\u128A-\u128D', // "ኊ-ኍ"
  '\u1290-\u12B0', // "ነ-ኰ"
  '\u12B2-\u12B5', // "ኲ-ኵ"
  '\u12B8-\u12BE', // "ኸ-ኾ"
  '\u12C0', // "ዀ"
  '\u12C2-\u12C5', // "ዂ-ዅ"
  '\u12C8-\u12D6', // "ወ-ዖ"
  '\u12D8-\u1310', // "ዘ-ጐ"
  '\u1312-\u1315', // "ጒ-ጕ"
  '\u1318-\u135A', // "ጘ-ፚ"
  '\u1380-\u138F', // "ᎀ-ᎏ"
  '\u13A0-\u13F4', // "Ꭰ-Ᏼ"
  '\u1401-\u166C', // "ᐁ-ᙬ"
  '\u166F-\u167F', // "ᙯ-ᙿ"
  '\u1681-\u169A', // "ᚁ-ᚚ"
  '\u16A0-\u16EA', // "ᚠ-ᛪ"
  '\u1700-\u170C', // "ᜀ-ᜌ"
  '\u170E-\u1711', // "ᜎ-ᜑ"
  '\u1720-\u1731', // "ᜠ-ᜱ"
  '\u1740-\u1751', // "ᝀ-ᝑ"
  '\u1760-\u176C', // "ᝠ-ᝬ"
  '\u176E-\u1770', // "ᝮ-ᝰ"
  '\u1780-\u17B3', // "ក-ឳ"
  '\u17D7', // "ៗ"
  '\u17DC', // "ៜ"
  '\u1820-\u1877', // "ᠠ-ᡷ"
  '\u1880-\u18A8', // "ᢀ-ᢨ"
  '\u18AA', // "ᢪ"
  '\u18B0-\u18F5', // "ᢰ-ᣵ"
  '\u1900-\u191C', // "ᤀ-ᤜ"
  '\u1950-\u196D', // "ᥐ-ᥭ"
  '\u1970-\u1974', // "ᥰ-ᥴ"
  '\u1980-\u19AB', // "ᦀ-ᦫ"
  '\u19C1-\u19C7', // "ᧁ-ᧇ"
  '\u1A00-\u1A16', // "ᨀ-ᨖ"
  '\u1A20-\u1A54', // "ᨠ-ᩔ"
  '\u1AA7', // "ᪧ"
  '\u1B05-\u1B33', // "ᬅ-ᬳ"
  '\u1B45-\u1B4B', // "ᭅ-ᭋ"
  '\u1B83-\u1BA0', // "ᮃ-ᮠ"
  '\u1BAE', // "ᮮ"
  '\u1BAF', // "ᮯ"
  '\u1BBA-\u1BE5', // "ᮺ-ᯥ"
  '\u1C00-\u1C23', // "ᰀ-ᰣ"
  '\u1C4D-\u1C4F', // "ᱍ-ᱏ"
  '\u1C5A-\u1C7D', // "ᱚ-ᱽ"
  '\u1CE9-\u1CEC', // "ᳩ-ᳬ"
  '\u1CEE-\u1CF1', // "ᳮ-ᳱ"
  '\u1CF5', // "ᳵ"
  '\u1CF6', // "ᳶ"
  '\u1D00-\u1DBF', // "ᴀ-ᶿ"
  '\u1E00-\u1F15', // "Ḁ-ἕ"
  '\u1F18-\u1F1D', // "Ἐ-Ἕ"
  '\u1F20-\u1F45', // "ἠ-ὅ"
  '\u1F48-\u1F4D', // "Ὀ-Ὅ"
  '\u1F50-\u1F57', // "ὐ-ὗ"
  '\u1F59', // "Ὑ"
  '\u1F5B', // "Ὓ"
  '\u1F5D', // "Ὕ"
  '\u1F5F-\u1F7D', // "Ὗ-ώ"
  '\u1F80-\u1FB4', // "ᾀ-ᾴ"
  '\u1FB6-\u1FBC', // "ᾶ-ᾼ"
  '\u1FBE', // "ι"
  '\u1FC2-\u1FC4', // "ῂ-ῄ"
  '\u1FC6-\u1FCC', // "ῆ-ῌ"
  '\u1FD0-\u1FD3', // "ῐ-ΐ"
  '\u1FD6-\u1FDB', // "ῖ-Ί"
  '\u1FE0-\u1FEC', // "ῠ-Ῥ"
  '\u1FF2-\u1FF4', // "ῲ-ῴ"
  '\u1FF6-\u1FFC', // "ῶ-ῼ"
  '\u2071', // "ⁱ"
  '\u207F', // "ⁿ"
  '\u2090-\u209C', // "ₐ-ₜ"
  '\u2102', // "ℂ"
  '\u2107', // "ℇ"
  '\u210A-\u2113', // "ℊ-ℓ"
  '\u2115', // "ℕ"
  '\u2119-\u211D', // "ℙ-ℝ"
  '\u2124', // "ℤ"
  '\u2126', // "Ω"
  '\u2128', // "ℨ"
  '\u212A-\u212D', // "K-ℭ"
  '\u212F-\u2139', // "ℯ-ℹ"
  '\u213C-\u213F', // "ℼ-ℿ"
  '\u2145-\u2149', // "ⅅ-ⅉ"
  '\u214E', // "ⅎ"
  '\u2183', // "Ↄ"
  '\u2184', // "ↄ"
  '\u2C00-\u2C2E', // "Ⰰ-Ⱞ"
  '\u2C30-\u2C5E', // "ⰰ-ⱞ"
  '\u2C60-\u2CE4', // "Ⱡ-ⳤ"
  '\u2CEB-\u2CEE', // "Ⳬ-ⳮ"
  '\u2CF2', // "Ⳳ"
  '\u2CF3', // "ⳳ"
  '\u2D00-\u2D25', // "ⴀ-ⴥ"
  '\u2D27', // "ⴧ"
  '\u2D2D', // "ⴭ"
  '\u2D30-\u2D67', // "ⴰ-ⵧ"
  '\u2D6F', // "ⵯ"
  '\u2D80-\u2D96', // "ⶀ-ⶖ"
  '\u2DA0-\u2DA6', // "ⶠ-ⶦ"
  '\u2DA8-\u2DAE', // "ⶨ-ⶮ"
  '\u2DB0-\u2DB6', // "ⶰ-ⶶ"
  '\u2DB8-\u2DBE', // "ⶸ-ⶾ"
  '\u2DC0-\u2DC6', // "ⷀ-ⷆ"
  '\u2DC8-\u2DCE', // "ⷈ-ⷎ"
  '\u2DD0-\u2DD6', // "ⷐ-ⷖ"
  '\u2DD8-\u2DDE', // "ⷘ-ⷞ"
  '\u2E2F', // "ⸯ"
  '\u3005', // "々"
  '\u3006', // "〆"
  '\u3031-\u3035', // "〱-〵"
  '\u303B', // "〻"
  '\u303C', // "〼"
  '\u3041-\u3096', // "ぁ-ゖ"
  '\u309D-\u309F', // "ゝ-ゟ"
  '\u30A1-\u30FA', // "ァ-ヺ"
  '\u30FC-\u30FF', // "ー-ヿ"
  '\u3105-\u312D', // "ㄅ-ㄭ"
  '\u3131-\u318E', // "ㄱ-ㆎ"
  '\u31A0-\u31BA', // "ㆠ-ㆺ"
  '\u31F0-\u31FF', // "ㇰ-ㇿ"
  '\u3400-\u4DB5', // "㐀-䶵"
  '\u4E00-\u9FCC', // "一-鿌"
  '\uA000-\uA48C', // "ꀀ-ꒌ"
  '\uA4D0-\uA4FD', // "ꓐ-ꓽ"
  '\uA500-\uA60C', // "ꔀ-ꘌ"
  '\uA610-\uA61F', // "ꘐ-ꘟ"
  '\uA62A', // "ꘪ"
  '\uA62B', // "ꘫ"
  '\uA640-\uA66E', // "Ꙁ-ꙮ"
  '\uA67F-\uA697', // "ꙿ-ꚗ"
  '\uA6A0-\uA6E5', // "ꚠ-ꛥ"
  '\uA717-\uA71F', // "ꜗ-ꜟ"
  '\uA722-\uA788', // "Ꜣ-ꞈ"
  '\uA78B-\uA78E', // "Ꞌ-ꞎ"
  '\uA790-\uA793', // "Ꞑ-ꞓ"
  '\uA7A0-\uA7AA', // "Ꞡ-Ɦ"
  '\uA7F8-\uA801', // "ꟸ-ꠁ"
  '\uA803-\uA805', // "ꠃ-ꠅ"
  '\uA807-\uA80A', // "ꠇ-ꠊ"
  '\uA80C-\uA822', // "ꠌ-ꠢ"
  '\uA840-\uA873', // "ꡀ-ꡳ"
  '\uA882-\uA8B3', // "ꢂ-ꢳ"
  '\uA8F2-\uA8F7', // "ꣲ-ꣷ"
  '\uA8FB', // "ꣻ"
  '\uA90A-\uA925', // "ꤊ-ꤥ"
  '\uA930-\uA946', // "ꤰ-ꥆ"
  '\uA960-\uA97C', // "ꥠ-ꥼ"
  '\uA984-\uA9B2', // "ꦄ-ꦲ"
  '\uA9CF', // "ꧏ"
  '\uAA00-\uAA28', // "ꨀ-ꨨ"
  '\uAA40-\uAA42', // "ꩀ-ꩂ"
  '\uAA44-\uAA4B', // "ꩄ-ꩋ"
  '\uAA60-\uAA76', // "ꩠ-ꩶ"
  '\uAA7A', // "ꩺ"
  '\uAA80-\uAAAF', // "ꪀ-ꪯ"
  '\uAAB1', // "ꪱ"
  '\uAAB5', // "ꪵ"
  '\uAAB6', // "ꪶ"
  '\uAAB9-\uAABD', // "ꪹ-ꪽ"
  '\uAAC0', // "ꫀ"
  '\uAAC2', // "ꫂ"
  '\uAADB-\uAADD', // "ꫛ-ꫝ"
  '\uAAE0-\uAAEA', // "ꫠ-ꫪ"
  '\uAAF2-\uAAF4', // "ꫲ-ꫴ"
  '\uAB01-\uAB06', // "ꬁ-ꬆ"
  '\uAB09-\uAB0E', // "ꬉ-ꬎ"
  '\uAB11-\uAB16', // "ꬑ-ꬖ"
  '\uAB20-\uAB26', // "ꬠ-ꬦ"
  '\uAB28-\uAB2E', // "ꬨ-ꬮ"
  '\uABC0-\uABE2', // "ꯀ-ꯢ"
  '\uAC00-\uD7A3', // "가-힣"
  '\uD7B0-\uD7C6', // "ힰ-ퟆ"
  '\uD7CB-\uD7FB', // "ퟋ-ퟻ"
  '\uF900-\uFA6D', // "豈-舘"
  '\uFA70-\uFAD9', // "並-龎"
  '\uFB00-\uFB06', // "ﬀ-ﬆ"
  '\uFB13-\uFB17', // "ﬓ-ﬗ"
  '\uFB1D', // "יִ"
  '\uFB1F-\uFB28', // "ײַ-ﬨ"
  '\uFB2A-\uFB36', // "שׁ-זּ"
  '\uFB38-\uFB3C', // "טּ-לּ"
  '\uFB3E', // "מּ"
  '\uFB40', // "נּ"
  '\uFB41', // "סּ"
  '\uFB43', // "ףּ"
  '\uFB44', // "פּ"
  '\uFB46-\uFBB1', // "צּ-ﮱ"
  '\uFBD3-\uFD3D', // "ﯓ-ﴽ"
  '\uFD50-\uFD8F', // "ﵐ-ﶏ"
  '\uFD92-\uFDC7', // "ﶒ-ﷇ"
  '\uFDF0-\uFDFB', // "ﷰ-ﷻ"
  '\uFE70-\uFE74', // "ﹰ-ﹴ"
  '\uFE76-\uFEFC', // "ﹶ-ﻼ"
  '\uFF21-\uFF3A', // "Ａ-Ｚ"
  '\uFF41-\uFF5A', // "ａ-ｚ"
  '\uFF66-\uFFBE', // "ｦ-ﾾ"
  '\uFFC2-\uFFC7', // "ￂ-ￇ"
  '\uFFCA-\uFFCF', // "ￊ-ￏ"
  '\uFFD2-\uFFD7', // "ￒ-ￗ"
  '\uFFDA-\uFFDC', // "ￚ-ￜ"
];
