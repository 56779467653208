import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TreeModule } from '@circlon/angular-tree-component';
import { IwSharedModule } from '@platform/shared';
import { TmContextMenuModule } from '../context-menu';
import { en, ru } from '../i18n';
import { TmTreeComponent } from './tm-tree.component';

@NgModule({
  imports: [TranslateModule.forChild(), CommonModule, TreeModule, IwSharedModule, RouterModule, TmContextMenuModule],
  declarations: [TmTreeComponent],
  exports: [TmTreeComponent],
})
export class TmTreeModule {
  constructor(private _t: TranslateService) {
    this._t.setTranslation('ru', ru, true);
    this._t.setTranslation('en', en, true);
  }
}
