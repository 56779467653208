import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TmElement } from '@tm-shared/custom-elements';
import { getWeekdayNameByIndex, localizeWeekdayName } from '@tm-shared/helpers/date';
import { Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { TmCronJobPresetType, TmCronJobPresets, TmCronJobServerModel, getPreset, getPresetByType } from './cronjob';

interface FormData {
  type?: TmCronJobPresetType;
  hours?: number | null;
  minutes?: number | null;
  time?: string | null;
  weekdays?: number[] | null;
}

type FormControlBuilderInput<T> = { [key in keyof T]: T[key] | [T[key], ...any[]] };

@TmElement('tme-cronjob-select')
@Component({
  selector: 'tm-cronjob-select',
  templateUrl: './cronjob-select.component.html',
  styleUrls: ['./cronjob-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TmCronjobSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TmCronjobSelectComponent),
      multi: true,
    },
  ],
})
export class TmCronjobSelectComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor, Validator {
  @Input() public set value(value: TmCronJobServerModel | null) {
    this._updateForm((this.preset = getPreset(value)));
  }

  public get value(): TmCronJobServerModel | null {
    return this.preset ? this.preset.toJson() : null;
  }

  @Input() public placeholder = '';

  @Input() public name = '';

  @Input() public disabled = false;

  @Output() public change: EventEmitter<TmCronJobServerModel> = new EventEmitter();

  public formGroup: FormGroup;

  public preset: TmCronJobPresets | null = null;

  public presetKeys = TmCronJobPresetType;

  public presetOptions: TmCronJobPresetType[] = [
    TmCronJobPresetType.minutes,
    TmCronJobPresetType.hours,
    TmCronJobPresetType.daily,
    TmCronJobPresetType.weekdays,
  ];

  public weekdayOptions: IwSelectItem[] = [];

  private _destroy$: Subject<void> = new Subject();

  constructor(private _t: TranslateService, private _cd: ChangeDetectorRef, private _fb: FormBuilder) {
    this.formGroup = this._fb.group(<FormControlBuilderInput<FormData>>{
      type: TmCronJobPresetType.minutes,
      weekdays: null,
      hours: [null, [Validators.required, Validators.max(24), Validators.min(1)]],
      minutes: [null, [Validators.required, Validators.max(60), Validators.min(1)]],
      time: null,
    });
  }

  public getErrors(path: string) {
    const errors = this.formGroup.get(path)?.errors || ({} as ValidationErrors);

    return Object.keys(errors).map((key) => ({ key, value: errors[key] }));
  }

  public registerOnChange(fn: any): void {
    this._ngHookOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._ngHookonTouched = fn;
  }

  public writeValue(value: TmCronJobServerModel): void {
    this.value = value;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._cd.markForCheck();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnInit(): void {
    this.formGroup
      .get('type')!
      .valueChanges.pipe(
        filter((value) => !this.preset || this.preset.type !== value),
        takeUntil(this._destroy$)
      )
      .subscribe((value) => {
        this._updateForm((this.preset = getPresetByType(value)));
        this._cd.markForCheck();
      });

    this._t.onLangChange
      .pipe(startWith(null), takeUntil(this._destroy$))
      .subscribe(() => (this.weekdayOptions = this._getWeekdaysLocalized(this._t.currentLang)));

    this.formGroup.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((values: FormData) => {
      this._ngHookOnChange(this._convertFormDataToCronJob(values));
    });
  }

  public ngAfterViewInit(): void {}

  public onBlur() {
    this._ngHookonTouched();
  }

  public validate(): ValidationErrors | null {
    return Object.values(this.formGroup.controls).some((c) => c.errors)
      ? {
          error: null,
        }
      : null;
  }

  private _convertFormDataToCronJob(data: FormData): TmCronJobServerModel {
    const preset = getPresetByType(data.type);

    switch (preset.type) {
      case TmCronJobPresetType.minutes:
        if (data.minutes) {
          preset.setMinuteInterval(data.minutes);
        }
        break;
      case TmCronJobPresetType.hours:
        if (data.hours) {
          preset.setHourInterval(data.hours);
        }
        break;
      case TmCronJobPresetType.daily:
        if (data.time) {
          preset.setLocalTime(data.time);
        }
        break;
      case TmCronJobPresetType.weekdays:
        // We should set time first to correctly set weekdays for local time
        if (data.time) {
          preset.setLocalTime(data.time);
        }
        if (data.weekdays) {
          preset.setLocalWeekdays(data.weekdays);
        }
        break;
    }

    return preset.toJson();
  }

  /**
   * Use this method only to reset complete data state
   */
  private _updateForm(preset: TmCronJobPresets): void {
    const data = preset.toJson();

    switch (preset.type) {
      case TmCronJobPresetType.minutes:
        this.formGroup.patchValue(<FormData>{
          type: TmCronJobPresetType.minutes,
          minutes: +data.minute_interval,
        });
        this.formGroup.get('minutes')!.enable();
        this.formGroup.get('hours')!.disable();
        this.formGroup.get('time')!.disable();
        this.formGroup.get('weekdays')!.disable();
        break;
      case TmCronJobPresetType.hours:
        this.formGroup.patchValue(<FormData>{
          type: TmCronJobPresetType.hours,
          hours: +data.hour_interval,
        });
        this.formGroup.get('minutes')!.disable();
        this.formGroup.get('hours')!.enable();
        this.formGroup.get('time')!.disable();
        this.formGroup.get('weekdays')!.disable();
        break;
      case TmCronJobPresetType.daily:
        this.formGroup.patchValue(<FormData>{
          type: TmCronJobPresetType.daily,
          time: preset.getLocalTime(),
        });
        this.formGroup.get('minutes')!.disable();
        this.formGroup.get('hours')!.disable();
        this.formGroup.get('time')!.enable();
        this.formGroup.get('weekdays')!.disable();
        break;
      case TmCronJobPresetType.weekdays:
        try {
          this.formGroup.patchValue(<FormData>{
            type: TmCronJobPresetType.weekdays,
            time: preset.getLocalTime(),
            weekdays: preset.getWeekdaysLocal(),
          });
          this.formGroup.get('minutes')!.disable();
          this.formGroup.get('hours')!.disable();
          this.formGroup.get('time')!.enable();
          this.formGroup.get('weekdays')!.enable();
        } catch (_e) {
          // TODO: fix @platform/shared iw-select: attempt to access destroyed view error
        }
        break;
    }
  }

  private _getWeekdaysLocalized(langKey: string): IwSelectItem[] {
    const result: IwSelectItem[] = [];
    for (let i = 1; i <= 7; i++) {
      result.push({
        value: i,
        label: localizeWeekdayName(getWeekdayNameByIndex(i), langKey, 'short'),
      });
    }
    return result;
  }

  private _ngHookOnChange: (value: TmCronJobServerModel) => void = () => {};

  private _ngHookonTouched: () => any = () => {};
}
