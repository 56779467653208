import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TmAnalysisTextService {
  public resetValidation$: Subject<null> = new Subject();

  public resetValidationErrors() {
    this.resetValidation$.next();
  }
}
