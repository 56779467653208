import { Component } from '@angular/core';
import { TmSearchService } from '../../../../@tm-shared/api-services';
import { TranslateService } from '@ngx-translate/core';
import { TableTabComponentDirective } from './abstract-table-tab.component';
import { getCommonColumnDefinition } from './common';

@Component({
  templateUrl: './table.html',
  styleUrls: [],
})
export class PolicyTabComponent extends TableTabComponentDirective {
  public static scopeToSearch: 'policy' = 'policy';

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: PolicyTabComponent.scopeToSearch,
  };

  constructor(public service: TmSearchService, private _t: TranslateService) {
    super();
  }

  public options = getCommonColumnDefinition(this._t);
}
