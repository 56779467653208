import { Injectable } from '@angular/core';
import { TmHttpXsrfInterceptor } from './tm-xsrf.interceptor';
/**
 * Support TM XSRF protection mechanism for jQuery (TMBB)
 */
@Injectable()
export class TmJqueryAndElementsXsrfInterceptor extends TmHttpXsrfInterceptor {
  public beforeSend(xhrReq: any) {
    if (this._token && !xhrReq.getResponseHeader(this.headerKey)) {
      xhrReq.setRequestHeader(this.headerKey, this._token);
    }

    xhrReq.done((_res: any, _status: any, xhrRes: any) => {
      this._storeToken(xhrRes.getResponseHeader(this.headerKey));
    });

    xhrReq.fail((xhrRes: any) => {
      this._storeToken(xhrRes.getResponseHeader(this.headerKey));
    });
  }
}
