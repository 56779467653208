import { NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmPluginValidationErrorModule } from './plugin-validation-error/plugin-validation-error.module';

const ru = require('./i18n/plugin.ru.json');
const en = require('./i18n/plugin.en.json');

@NgModule({
  imports: [TmPluginValidationErrorModule],
})
export class TmPluginsElementsModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('ru', ru, true);
    this.translate.setTranslation('en', en, true);
  }
}
