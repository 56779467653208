import { TmPrivilegeModel } from '../privileges';

export const MODEL_ID_KEY = 'USER_ID';

/**
 * Session data
 */
export interface SessionModel {
  USER_ID: number;
  USER_TYPE: number;
  PROVIDER: string;
  LANGUAGE: string;
  USERNAME: string;
  CHANNEL_NAME: string;
  DISPLAY_NAME?: string;
  TIMEZONE: string;
  NOTE?: string;
  EMAIL?: string;
  RECEIVE_NOTIFICATION: number;
  HIDE_OBJECT_CONTENT: number;
  CREATE_DATE: string;
  CHANGE_DATE: string;
  LOGON_DATE: string;
  STATUS: number;
  IS_SYSTEM: number;
  EDITABLE: number;
  policyEntries: any[];
  privileges: TmPrivilegeModel[];
  roles: any[];
  visibilityareas: any[];
}

export interface SessionResponse {
  data: SessionModel;
}

export interface SaltResponse {
  data: {
    salt: string;
  };
}
