import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TmElement } from '@tm-shared/custom-elements';

@TmElement('tme-configdiff')
@Component({
  selector: 'tm-configdiff',
  templateUrl: 'configdiff.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmConfigDiffComponent {
  @Input() public data: TmPluginConfig.diff.BaseItem[] = [];

  @Output() public fetchDetailed: EventEmitter<TmPluginConfig.diff.ItemId> = new EventEmitter();

  public trackItemBy(_i: number, item: TmPluginConfig.diff.BaseItem): TmPluginConfig.diff.ItemId {
    return item.id;
  }
}
