import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IwPopoverOptions } from '@platform/shared';
import { TmLdapStatusApiService } from '@tm-shared/api-services/';
import { TmGridComponent, TmGridOptions } from '@tm-shared/grid';
import { ModalConfirmWithItemsComponent } from '@tm-shared/modals/confirm-with-items';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmSidebarService } from '@tm-shared/structure/sidebar';
import { Observable, Subject, combineLatest } from 'rxjs';
import { auditTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TmConfigLocalService } from '../../config/services';
import { CREATE_PATH, EDIT_PATH, STATUS, getPrivilegeRequest } from '../status-exports';
import { StatusFormComponent } from '../status-form-component/status-form.component';
import { StatusService } from './status.service';

@Component({
  selector: 'tm-status',
  styleUrls: ['status.component.scss'],
  templateUrl: 'status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StatusService,
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
        closeOnMousemoveOutside: true,
        triggers: 'mouseenter:mouseleave',
      }),
    },
  ],
})
export class StatusComponent implements AfterViewInit, OnDestroy {
  @ViewChild('grid', { static: false }) public grid: TmGridComponent<TmApi.ldapStatus.CollectionItem>;

  public modal = ModalConfirmWithItemsComponent;

  public selectedItemsNames$: Observable<string[]>;

  /**
   * Define can streams
   */
  public canEdit$: Observable<boolean>;
  public canCreate$ = this._can('edit');
  public canRemove$: Observable<boolean>;
  public createPolicyDisabled$: Observable<boolean>;

  public gridOptions: TmGridOptions = this._statusService.getGridConfig();

  /**
   * Unsubscribe trigger
   */
  private _destroyed$: Subject<void> = new Subject();

  constructor(
    public service: TmLdapStatusApiService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _privileges: TmPrivilegesService,
    private _statusService: StatusService,
    private _sidebarService: TmSidebarService,
    private _configLocal: TmConfigLocalService
  ) {}

  /**
   * Open create dialog
   */
  public showDialogCreate(): void {
    this._router.navigate([CREATE_PATH], {
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge',
    });
    this._sidebarService.open('right');
  }

  /**
   * Open edit dialog
   */
  public showDialogEdit(id: string): void {
    this._router.navigate([EDIT_PATH.replace(':id', `${id}`)], {
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge',
    });
    this._sidebarService.open('right');
  }

  public deleteSelectedItems() {
    this._router.navigate([STATUS], {
      queryParamsHandling: 'merge',
    });
    this.grid.deleteAllSelected().subscribe();
  }

  /**
   * Handler load form component
   */
  public onActivate(component: StatusFormComponent): void {
    component.close$
      .pipe(
        tap(() => this._sidebarService.close('right')),
        auditTime(200),
        take(1),
        filter(() => !!this.grid),
        tap(() => this.grid.resetSelection()),
        tap(() => this.grid.refresh()),
        takeUntil(this._destroyed$)
      )
      .subscribe();

    this._sidebarService.open('right');
  }

  public ngAfterViewInit() {
    if (this._activatedRoute.firstChild && this._activatedRoute.firstChild.snapshot.params['id']) {
      this.grid.selectById(this._activatedRoute.firstChild.snapshot.params['id']);
    }

    this.createPolicyDisabled$ = combineLatest([
      this._privileges.can('policy/policy_object/edit'),
      this.grid.allSelected$,
    ]).pipe(
      map(([canCreatePolicy, selected]) => {
        return !canCreatePolicy || selected.length === 0;
      })
    );
    this.selectedItemsNames$ = this.grid.allSelected$.pipe(map((items) => items.map((item) => item.DISPLAY_NAME)));
    this._configLocal.refreshOnConfigChanges.pipe(takeUntil(this._destroyed$)).subscribe();

    this._sidebarService
      .onAction('*', 'complete')
      .pipe(
        switchMap(() => this.grid.initialized$),
        takeUntil(this._destroyed$)
      )
      .subscribe((gridApi) => {
        gridApi.sizeColumnsToFit();
      });
    this.grid.selectedOrdered$.pipe(takeUntil(this._destroyed$)).subscribe((selection) => {
      if (!selection.length) {
        this._sidebarService.close('right');
      } else {
        this.showDialogEdit(selection[0].IDENTITY_STATUS_ID);
      }
    });
    this.canEdit$ = this.grid.selected$.pipe(
      takeUntil(this._destroyed$),
      map((data) => this.service.isModelEditable(data))
    );
    this.canRemove$ = this._can('delete', this.canEdit$);
  }

  /**
   * Clean up component on destroy event
   */
  public ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public createPolicy() {
    this._statusService.createPolicy(this.grid.getAllSelectedIds());
  }

  /**
   * Check privileges
   */
  private _can(actionKey: TmPluginAccess.role.PrivelegeKey, ...rules$: Observable<boolean>[]): Observable<boolean> {
    let can$: Observable<boolean> = this._privileges.can(getPrivilegeRequest(actionKey));

    return combineLatest([can$, ...rules$]).pipe(map((bools: boolean[]) => bools.every((b) => b)));
  }
}
