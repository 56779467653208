import { Route } from '@angular/router';
import { TmGraphicCategoryComponent } from './graphic-category/graphic-category.component';
import { TmGraphicPageComponent } from './graphic-page.component';

export const routes: Route[] = [
  {
    path: 'analysis/graphic',
    redirectTo: 'analysis/graphic/',
    pathMatch: 'full',
  },
  {
    path: 'analysis/graphic/:id',
    component: TmGraphicPageComponent,
    data: {
      usePrivilege: 'analysis/graphic',
    },
    children: [
      {
        path: '',
        component: TmGraphicCategoryComponent,
        data: {
          usePrivilege: 'analysis/graphic',
        },
      },
    ],
  },
];
