import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { TmConfigDiffPageService } from './configdiff-page.service';

/**
 * Pages are smart (though we should keep them as dumb as possible)
 * and they handle interaction between inner dumb components.
 *
 * To keep code clear all complex logic is hidden inside injected page service,
 * think of this service as a page controller.
 */
@Component({
  templateUrl: './configdiff-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmConfigDiffPageComponent {
  public data$: Observable<(TmPluginConfig.diff.BaseItem | TmPluginConfig.diff.DetailedItem)[]> = this._service.data$;

  constructor(private _service: TmConfigDiffPageService) {}

  public fetchDetailed(id: TmPluginConfig.diff.ItemId): void {
    this._service.fetchDetailed(id);
  }
}
