<iw-form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()" class="form" data-qa="userEditForm" iwFormSubmit>
  <iw-form-block>
    <!-- USERNAME -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.user.form.username' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <input
          iwInput
          formControlName="USERNAME"
          type="text"
          [tmAutofocus]="data$"
          autocomplete="on"
          placeholder="{{ 'settings-access.user.form.placeholders.username' | translate }}"
          data-qa="userName"
        />
        <div iwErrorHints *ngFor="let error of getErrors('USERNAME')">
          {{ 'settings-access.validation.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>

    <!-- STATUS -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.user.form.status' | translate }}</label>
      <iw-select
        iwFormRowContent
        placeholder="{{ 'settings-access.user.form.selectedNone' | translate }}"
        formControlName="STATUS"
        class="full-width"
        [clearable]="false"
        data-qa="userStatus"
      >
        <iw-select-option value="1">{{ 'settings-access.user.form.inactive' | translate }}</iw-select-option>
        <iw-select-option value="0">{{ 'settings-access.user.form.active' | translate }}</iw-select-option>
      </iw-select>
    </iw-form-row>

    <!-- EMAIL -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.user.form.email' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <input
          iwInput
          formControlName="EMAIL"
          type="text"
          autocomplete="on"
          placeholder="{{ 'settings-access.user.form.placeholders.email' | translate }}"
          data-qa="userMail"
        />
        <div iwErrorHints *ngFor="let error of getErrors('EMAIL')">
          {{ 'settings-access.validation.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>

    <!-- DISPLAY_NAME -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.user.form.displayname' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <input
          iwInput
          formControlName="DISPLAY_NAME"
          type="text"
          autocomplete="off"
          maxlength="256"
          placeholder="{{ 'settings-access.user.form.placeholders.displayname' | translate }}"
          data-qa="userDisplayName"
        />
        <div iwErrorHints *ngFor="let error of getErrors('DISPLAY_NAME')">
          {{ 'settings-access.validation.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>

    <!-- ROLES[] -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.user.form.roles' | translate }}</label>
      <tm-role-select iwFormRowContent [control]="getFormControl('roles')" data-qa="userRoles"> </tm-role-select>
    </iw-form-row>

    <!-- SCOPES[] -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.user.form.scopes' | translate }}</label>
      <tm-scope-select iwFormRowContent [control]="getFormControl('scopes')" data-qa="userScopes"> </tm-scope-select>
    </iw-form-row>

    <!-- DESCRIPTION -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.user.form.description' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <textarea
          iwInput
          type="text"
          placeholder="{{ 'settings-access.user.form.placeholders.description' | translate }}"
          data-qa="userNote"
          formControlName="NOTE"
          maxlength="4000"
        ></textarea>
      </iw-input-container>
    </iw-form-row>

    <!-- PASSWORD -->
    <iw-form-row *ngIf="isNew$ | async">
      <label iwFormRowLabel>{{ 'settings-access.user.form.password' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <input
          iwInput
          formControlName="PASSWORD"
          type="password"
          autocomplete="new-password"
          placeholder="{{ 'settings-access.user.form.placeholders.password' | translate }}"
          data-qa="userPass"
        />
        <div iwErrorHints *ngFor="let error of getErrors('PASSWORD')">
          {{ 'settings-access.validation.password.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>

    <!-- PASSWORD CONFIRMATION -->
    <iw-form-row *ngIf="isNew$ | async">
      <label iwFormRowLabel>{{ 'settings-access.user.form.passwordConfirmation' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <input
          iwInput
          formControlName="PASSWORD_CONFIRMATION"
          type="password"
          autocomplete="off"
          placeholder="{{ 'settings-access.user.form.placeholders.passwordConfirmation' | translate }}"
          data-qa="userPassConf"
        />
        <div iwErrorHints *ngFor="let error of getErrors('PASSWORD_CONFIRMATION')">
          {{ 'settings-access.validation.password.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>
    <div class="dates" *ngIf="createDate && changeDate">
      {{ 'settings-access.created' | translate }}: <span [tmLocalizeDate]="createDate"></span> —
      {{ 'settings-access.changed' | translate }}: <span [tmLocalizeDate]="changeDate"></span>
    </div>
  </iw-form-block>

  <iw-form-toolbar>
    <button iw-button type="submit" color="success" data-qa="userSubmit" [disabled]="form.status === 'PENDING'">
      {{ 'settings-access.user.form.save' | translate }}
    </button>
    <button iw-button type="button" (click)="close()">
      {{ 'settings-access.user.form.cancel' | translate }}
    </button>
  </iw-form-toolbar>
</iw-form>
