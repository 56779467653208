import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tm-toolbar',
  templateUrl: 'tm-toolbar.component.html',
  styleUrls: ['tm-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TmToolbarComponent {
  @HostBinding('class.btnToolbar') public btn = true;
}
