import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

import { TmFormComponent } from './form.component';

@Injectable()
export class CanDeactivateTmForm implements CanDeactivate<TmFormComponent<any>> {
  public canDeactivate(component: any): Observable<boolean> {
    return component.canClose$;
  }
}
