import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmUserApiService } from '@tm-shared/api-services/user-api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangePasswordValue } from '../user.model';
import { TmChangePasswordComponent } from './change-password.component';
import { ModalDialogRef, IwModalService } from '@platform/shared';

@Injectable()
export class TmChangePasswordService implements OnDestroy {
  public onModalCancelCloseEvent$: Subject<boolean> = new Subject();

  private _modalRef: ModalDialogRef<TmChangePasswordComponent>;

  private _destroy$: Subject<void> = new Subject();

  constructor(private _service: TmUserApiService, private _t: TranslateService, private _modal: IwModalService) {}

  public async createModal(userID: string, userName: string): Promise<void> {
    this._modalRef = await this._modal.open(
      TmChangePasswordComponent,
      {
        title: this._t.instant('settings-access.user.changePassword'),
        userID: userID,
        userName: userName,
      },
      { position: 'top' }
    );

    this._modalRef.component.onCancel.pipe(takeUntil(this._destroy$)).subscribe(this.cancel.bind(this));
    this._modalRef.component.onSubmit.pipe(takeUntil(this._destroy$)).subscribe(this.changePassword.bind(this));
  }

  public changePassword(params: ChangePasswordValue): void {
    this._service.updateById(params.userID, params.data).subscribe((resp) => {
      if (resp.state === 'error') {
        this._modalRef.component.setErrors(resp);
        return;
      }

      this._modalRef.close(null);
      this.onModalCancelCloseEvent$.next(true);
    });
  }

  public cancel(): void {
    this._modalRef.dismiss(null);
    this.onModalCancelCloseEvent$.next(true);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
