<iw-form class="form" [formGroup]="form">
  <div class="dateRangeRow">
    <iw-select
      data-qa="modeSelector"
      class="modeSelector"
      formControlName="mode"
      [clearable]="false"
      [items]="dateRangeOptions"
    >
    </iw-select>

    <div
      *ngIf="fromToModeIsActive"
      class="dateRangePreview"
      [iwPopover]="popoverTpl"
      [triggers]="'click:click'"
      [noStyles]="true"
    >
      <span
        class="valueFrom"
        [tmLocalizeDate]="value('from')!"
        dateInputFormat="JSDate"
        [dateOutputFormat]="allowTimeSelection ? 'D T' : 'D'"
      >
      </span>
      <span
        class="valueTo"
        [tmLocalizeDate]="value('to')!"
        dateInputFormat="JSDate"
        [dateOutputFormat]="allowTimeSelection ? 'D T' : 'D'"
      >
      </span>
    </div>

    <div *ngIf="variableModeIsActive" class="variableModeInput">
      <iw-input-container>
        <input iwInput type="number" formControlName="value" />
      </iw-input-container>
    </div>
  </div>

  <ng-template #popoverTpl>
    <tm-date-range-calendar
      class="calendar"
      [type]="allowTimeSelection ? 'datetime-local' : 'date'"
      [from]="value('from')!"
      [to]="value('to')!"
      (changed)="dateInputChanged($event)"
      (closed)="closePopover()"
    ></tm-date-range-calendar>
  </ng-template>
</iw-form>
