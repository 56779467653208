/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AuditEvent, AuditEventOperation } from '../audit.model';
import { AuditExtendGridData, AuditGridDataColumn } from '../audit-extend.service';
import {
  getAuditAttributesFrom,
  getAuditExtendRowData,
  VisibleAttributesOption,
  TransformOptions,
} from './audit-common';
import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationRuleData, PolicyAction } from 'typings/generated/audit-policy-rule';
import { PolicyDiffAuditLog, SimpleItem, FileformatItem } from 'typings/generated/audit-policy';
import {
  GroupItem,
  TmAuditSimpleGroupCellComponent,
} from '../audit-cell-components/audit-simple-group/audit-simple-group.component';
import { visiblePolicyTransferRuleAttributes } from './audit-policy-transfer-rule';
import { visiblePolicyCopyRuleAttributes } from './audit-policy-copy-rule';
import { visiblePolicyPlacementRuleAttributes } from './audit-policy-placement-rule';
import { visiblePolicyApplicationRuleAttributes } from './audit-policy-application-rule';
import { visiblePolicyBlockadeRuleAttributes } from './audit-policy-blockade-rule';
import { visiblePolicyPersonRuleAttributes } from './audit-policy-person-rule';
import { remapActionsByType, visiblePolicyActionAttributes } from './audit-policy-action';
import { transformStatus } from './audit-policy-helpers';

export type ApplicationSourceDestValue =
  | ApplicationRuleData['SOURCE']
  | ApplicationRuleData['WORKSTATION']
  | ApplicationRuleData['APPLICATION'];

const visiblePolicyAttributes: VisibleAttributesOption[] = [
  { path: 'DISPLAY_NAME', i18nKey: 'audit.auditPolicy.attributes.DISPLAY_NAME' },
  { path: 'NOTE', i18nKey: 'audit.auditPolicy.attributes.NOTE' },
  { path: 'START_DATE', i18nKey: 'audit.auditPolicy.attributes.START_DATE', transform: transformDate },
  { path: 'END_DATE', i18nKey: 'audit.auditPolicy.attributes.END_DATE', transform: transformDate },
  {
    path: 'DATA.ITEMS',
    i18nKey: 'audit.auditPolicy.attributes.DATA',
    transform: transformProtectedDataItems,
    component: TmAuditSimpleGroupCellComponent,
  },
  { path: 'STATUS', i18nKey: 'audit.auditPolicy.attributes.STATUS', transform: transformStatus },
];

const ruleMap = {
  rule_transfer: visiblePolicyTransferRuleAttributes,
  rule_copy: visiblePolicyCopyRuleAttributes,
  rule_placement: visiblePolicyPlacementRuleAttributes,
  rule_application: visiblePolicyApplicationRuleAttributes,
  rule_blockade: visiblePolicyBlockadeRuleAttributes,
  rule_person: visiblePolicyPersonRuleAttributes,
};

export function getPolicyGridData(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  const attrs = getAuditAttributesFrom(data) as PolicyDiffAuditLog['PROPERTY_CHANGES'];
  const gridData: AuditExtendGridData[] = [];
  let cols: AuditGridDataColumn[] = [];

  switch (data.OPERATION) {
    case AuditEventOperation.delete:
      cols = [AuditGridDataColumn.old];
      break;
    case AuditEventOperation.create:
      cols = [AuditGridDataColumn.new];
      break;
    default:
      cols = [AuditGridDataColumn.old, AuditGridDataColumn.new];
      break;
  }

  /**
   * Collect rule changes
   */
  if (attrs?.rule && attrs.rule.ENTITY_TYPE === 'PolicyRule') {
    // использование ENTITY_DISPLAY_NAME для определения типа правила политики
    // это надежный способ (с) бек
    const ruleType = attrs.rule.ENTITY_DISPLAY_NAME;

    const data = getAuditExtendRowData({
      t,
      cols: cols,
      data: attrs.rule,
      visibleAttributes: ruleMap[ruleType],
    });

    if (data.length) {
      gridData.push({
        title: t.instant('audit.auditPolicyRule.gridRulesTitle'),
        cols,
        data,
      });
    }
  }

  /**
   * Collect actions changes
   */
  if (attrs?.rule?.new?.actions || attrs?.rule?.old?.actions) {
    const actionsNew: PolicyAction[] = attrs.rule.new?.actions || [];
    const actionsOld: PolicyAction[] = attrs.rule.old?.actions || [];

    /**
     * Parse actions to better model first. Group actions by type, remove NULL items 0__o
     */
    const data = getAuditExtendRowData({
      t,
      cols: cols,
      data: {
        old: remapActionsByType(actionsOld),
        new: remapActionsByType(actionsNew),
      },
      visibleAttributes: visiblePolicyActionAttributes,
    });

    if (data.length) {
      gridData.push({
        title: t.instant('audit.auditPolicyActions.gridActionsTitle'),
        cols,
        data,
      });
    }
  }

  /**
   *
   */
  if (attrs?.new || attrs?.old) {
    const data = getAuditExtendRowData({
      t,
      cols: cols,
      data: attrs,
      visibleAttributes: visiblePolicyAttributes,
    });

    if (data.length) {
      gridData.push({
        title: t.instant('audit.auditPolicy.gridPolicyTitle'),
        cols,
        data,
      });
    }
  }

  return gridData;
}

export function transformProtectedDataItems(
  items: (SimpleItem | FileformatItem)[] | undefined,
  o: TransformOptions
): GroupItem[] {
  if (!items) {
    return [];
  }

  const groups = groupBy(items, 'TYPE');
  return Object.keys(groups).reduce((result, type) => {
    result.push(getDataItemAsGroupItem(groups, type, o));
    return result;
  }, [] as GroupItem[]);
}

function getDataItemAsGroupItem(
  groups: Record<string, (SimpleItem | FileformatItem)[]>,
  type: string,
  o: TransformOptions
): GroupItem {
  const groupData = {
    /**
     * @translate audit.auditPolicy.items.catalog
     * @translate audit.auditPolicy.items.document
     * @translate audit.auditPolicy.items.person
     * @translate audit.auditPolicy.items.group
     * @translate audit.auditPolicy.items.status
     * @translate audit.auditPolicy.items.fileformat
     * @translate audit.auditPolicy.items.filetype
     */
    groupName: o.t.instant(`audit.auditPolicy.items.${type}`),
    items: groups[type].map((item) => item.NAME).filter((x) => !!x) as string[],
  };

  /**
   * File format items group should contain file modifiers (same for each item).
   */
  if (type === 'fileformat') {
    const groupNameParts: string[] = [];

    /**
     * Take any item, all of them have same flags.
     */
    const sampleItem = groups[type][0] as FileformatItem;

    if (sampleItem.ENCRYPTED) {
      groupNameParts.push(o.t.instant('audit.auditPolicy.items.encrypted'));
    }

    if (sampleItem.MERGED) {
      groupNameParts.push(o.t.instant('audit.auditPolicy.items.merged'));
    }

    if (sampleItem.WRONG_EXTENSION) {
      groupNameParts.push(o.t.instant('audit.auditPolicy.items.wrongExtension'));
    }

    if (sampleItem.MIN) {
      groupNameParts.push(
        o.t.instant('audit.auditPolicy.items.minSize', getFileSize(sampleItem.MIN, sampleItem.MIN_TYPE || 0, o))
      );
    }

    if (sampleItem.MAX) {
      groupNameParts.push(
        o.t.instant('audit.auditPolicy.items.maxSize', getFileSize(sampleItem.MAX, sampleItem.MAX_TYPE || 0, o))
      );
    }

    if (groupNameParts.length) {
      groupData.groupName += ` (${groupNameParts.join(', ')})`;
    }
  }

  return groupData;
}

function getFileSize(bytes: number, unitsKey: number, o: TransformOptions): { value: number; unit: string } {
  switch (unitsKey) {
    case 1:
      return { unit: o.t.instant('audit.auditPolicy.items.fileSizeUnits.kb'), value: bytes / Math.pow(1024, 1) };
    case 2:
      return { unit: o.t.instant('audit.auditPolicy.items.fileSizeUnits.mb'), value: bytes / Math.pow(1024, 2) };
    case 3:
      return { unit: o.t.instant('audit.auditPolicy.items.fileSizeUnits.gb'), value: bytes / Math.pow(1024, 3) };
    case 4:
      return { unit: o.t.instant('audit.auditPolicy.items.fileSizeUnits.tb'), value: bytes / Math.pow(1024, 4) };
    default:
      return { unit: o.t.instant('audit.auditPolicy.items.fileSizeUnits.byte'), value: bytes };
  }
}

export function transformDate(value: string | null | undefined, o: TransformOptions): string {
  if (!value) {
    return o.t.instant(`audit.date.all_time`);
  }

  return DateTime.fromSQL(value).toFormat('D') || value;
}
