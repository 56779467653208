<iw-form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()" class="form" iwFormSubmit>
  <iw-form-block class="content">
    <!-- Name -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.scope.form.display_name' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <input iwInput formControlName="DISPLAY_NAME" data-qa="scope-display-name" type="text" [tmAutofocus]="data$" />

        <div iwErrorHints *ngFor="let error of getErrors('DISPLAY_NAME')">
          {{ 'settings-access.validation.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>

    <!-- Violation level -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.scope.form.violation_level' | translate }}</label>
      <iw-select
        iwFormRowContent
        data-qa="scope-violation-level"
        placeholder="{{ getPlaceholder('settings-access.scope.form.none' | translate) | async }}"
        [multiple]="true"
        formControlName="violation_level"
      >
        <iw-select-option translate value="High">settings-access.scope.form.high</iw-select-option>
        <iw-select-option value="Medium" translate>settings-access.scope.form.medium</iw-select-option>
        <iw-select-option value="Low" translate>settings-access.scope.form.low</iw-select-option>
        <iw-select-option value="No" translate>settings-access.scope.form.no</iw-select-option>
      </iw-select>
    </iw-form-row>

    <!-- Verdict -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.scope.form.verdict' | translate }}</label>
      <iw-select
        iwFormRowContent
        data-qa="scope-verdict"
        placeholder="{{ getPlaceholder('settings-access.scope.form.none' | translate) | async }}"
        [multiple]="true"
        formControlName="verdict"
      >
        <iw-select-option value="Allowed">{{ 'settings-access.scope.form.allowed' | translate }}</iw-select-option>
        <iw-select-option value="Forbidden">{{ 'settings-access.scope.form.forbidden' | translate }}</iw-select-option>
        <iw-select-option value="Quarantined">{{
          'settings-access.scope.form.quarantined' | translate
        }}</iw-select-option>
      </iw-select>
    </iw-form-row>

    <!-- Persons -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.scope.form.persons' | translate }}</label>
      <tm-search-select
        iwFormRowContent
        data-qa="scope-person"
        [control]="getFormControl('recipients')"
        [modalComponents]="recipientsComponents"
        placeholder="{{ getPlaceholder() | async }}"
        modalHeader="{{ 'settings-access.scope.form.persons' | translate }}"
        [additionalTypesToSelect]="contactTypesService.getContactTypesFor(['person', 'group'], 'auth') | async"
      ></tm-search-select>
      <iw-checkbox
        class="checkboxElement"
        iwFormRowContent
        formControlName="withoutrecipients"
        (change)="checkValue($event, 'withoutrecipients')"
        >{{ 'settings-access.scope.form.without' | translate }}</iw-checkbox
      >
    </iw-form-row>
    <iw-form-row *ngIf="getFormControl('withoutrecipients').value">
      <tm-search-select
        iwFormRowContent
        data-qa="scope-person"
        [control]="getFormControl('exceptrecipients')"
        placeholder="{{ getPlaceholder() | async }}"
        [modalComponents]="recipientsComponents"
        modalHeader="{{ 'settings-access.scope.form.persons' | translate }}"
        [additionalTypesToSelect]="contactTypesService.getContactTypesFor(['person', 'group'], 'auth') | async"
      ></tm-search-select>
    </iw-form-row>

    <!-- WorkStations -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.scope.form.workstations' | translate }}</label>
      <tm-search-select
        iwFormRowContent
        placeholder="{{ getPlaceholder() | async }}"
        [control]="getFormControl('workstations')"
        [modalComponents]="workStationComponents"
        modalHeader="{{ 'settings-access.scope.form.workstations' | translate }}"
        [additionalTypesToSelect]="contactTypesService.getContactTypesFor(['workstation', 'group'], 'auth') | async"
      ></tm-search-select>
      <iw-checkbox
        class="checkboxElement"
        iwFormRowContent
        formControlName="withoutworkstations"
        (change)="checkValue($event, 'exceptworkstations')"
        >{{ 'settings-access.scope.form.without' | translate }}</iw-checkbox
      >
    </iw-form-row>
    <iw-form-row *ngIf="getFormControl('withoutworkstations').value">
      <tm-search-select
        iwFormRowContent
        placeholder="{{ getPlaceholder() | async }}"
        [control]="getFormControl('exceptworkstations')"
        [modalComponents]="workStationComponents"
        modalHeader="{{ 'settings-access.scope.form.workstations' | translate }}"
        [additionalTypesToSelect]="contactTypesService.getContactTypesFor(['workstation', 'group'], 'auth') | async"
      ></tm-search-select>
    </iw-form-row>
    <!-- Tags -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.scope.form.tags' | translate }}</label>
      <tm-search-select
        iwFormRowContent
        placeholder="{{ getPlaceholder() | async }}"
        data-qa="scope-tag"
        [control]="getFormControl('tags')"
        [modalComponents]="tagComponents"
        modalHeader="{{ 'settings-access.scope.form.tags' | translate }}"
      ></tm-search-select>
      <iw-checkbox
        class="checkboxElement"
        iwFormRowContent
        formControlName="withouttags"
        (change)="checkValue($event, 'excepttags')"
        >{{ 'settings-access.scope.form.without' | translate }}</iw-checkbox
      >
    </iw-form-row>
    <iw-form-row *ngIf="getFormControl('withouttags').value">
      <tm-search-select
        iwFormRowContent
        placeholder="{{ getPlaceholder() | async }}"
        [control]="getFormControl('excepttags')"
        [modalComponents]="tagComponents"
        modalHeader="{{ 'settings-access.scope.form.tags' | translate }}"
      ></tm-search-select>
    </iw-form-row>

    <!-- Policies -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.scope.form.policies' | translate }}</label>
      <tm-search-select
        iwFormRowContent
        placeholder="{{ getPlaceholder() | async }}"
        [control]="getFormControl('policies')"
        [modalComponents]="policyComponents"
        modalHeader="{{ 'settings-access.scope.form.policies' | translate }}"
      ></tm-search-select>
      <iw-checkbox class="checkboxElement" iwFormRowContent formControlName="anyPolicies" value="any">{{
        'settings-access.scope.form.any_policies' | translate
      }}</iw-checkbox>
    </iw-form-row>

    <!-- Protected objects -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.scope.form.documents' | translate }}</label>
      <tm-search-select
        iwFormRowContent
        placeholder="{{ getPlaceholder() | async }}"
        [control]="getFormControl('documents')"
        [modalComponents]="documentComponents"
        modalHeader="{{ 'settings-access.scope.form.documents' | translate }}"
      ></tm-search-select>
      <iw-checkbox class="checkboxElement" iwFormRowContent formControlName="anyDocuments" value="any">{{
        'settings-access.scope.form.any_documents' | translate
      }}</iw-checkbox>
    </iw-form-row>

    <!-- Note -->
    <iw-form-row>
      <label iwFormRowLabel>{{ 'settings-access.scope.form.note' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <textarea iwInput formControlName="NOTE" data-qa="scope-note"></textarea>
        <div iwErrorHints *ngFor="let error of getErrors('NOTE')">
          {{ 'settings-access.validation.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>

    <span *ngIf="!form.pristine && form.errors" class="error-message">
      {{ 'settings-access.validation.anyElemIsRequired' | translate }}
    </span>
  </iw-form-block>
  <div class="dates" *ngIf="createDate && changeDate">
    {{ 'settings-access.created' | translate }}: <span [tmLocalizeDate]="createDate"></span> —
    {{ 'settings-access.changed' | translate }}: <span [tmLocalizeDate]="changeDate"></span>
  </div>

  <div class="footer">
    <iw-form-toolbar class="iw-form-toolbar">
      <button
        iw-button
        type="submit"
        color="success"
        data-qa="scope-submit-button"
        [disabled]="!form.valid || form.disabled || form.status === 'PENDING'"
      >
        {{ 'settings-access.scope.form.save' | translate }}
      </button>
      <button iw-button type="button" (click)="close()">
        {{ 'settings-access.scope.form.cancel' | translate }}
      </button>
    </iw-form-toolbar>
  </div>
</iw-form>
