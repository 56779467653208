import { Component, HostBinding, Input } from '@angular/core';

@Component({
  template: '<ng-content></ng-content>',
  selector: 'tm-filler-text',
  styleUrls: ['./filler-text.component.scss'],
})
export class TmFillerTextComponent {
  @HostBinding('attr.data-text') @Input() public text = '';
}
