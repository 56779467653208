import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TmDropzoneComponent, DropzoneOutValue } from '@tm-shared/dragndrop/dropzone.component';
import { Subject } from 'rxjs';
import { debounceTime, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { CheckDocumentResponseItem, CheckDocumentState } from 'typings/generated/technology-autoling';
import { TmAutolingDocumentCheckService } from '../autoling-document-check.service';
import { ModalDialogRef } from '@platform/shared';

interface GridItem {
  filename: string;
  error?: string;
  category?: string;
  quality?: string;
}

@Component({
  selector: 'tm-autoling-document-check',
  templateUrl: 'autoling-document-check.component.html',
  styleUrls: ['./autoling-document-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmAutolingDocumentCheckComponent implements OnDestroy, OnInit {
  public showResults$ = this.service.state$.pipe(map((state) => this.canShowResults(state)));

  public canceled$ = this.service.state$.pipe(map((state) => state === CheckDocumentState.cancel));

  public lockButtons = false;

  public gridData$ = this.service.dataWithUpdates$.pipe(
    map((data) => data.map((item) => this._documentResponseToGridItem(item)).flat())
  );

  @ViewChild('fileInput', { static: true }) private fileInputEl: TmDropzoneComponent;

  private _destroy$ = new Subject<void>();

  constructor(
    public service: TmAutolingDocumentCheckService,
    private _dialog: ModalDialogRef<unknown>,
    private cd: ChangeDetectorRef
  ) {}

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnInit(): void {
    this.showResults$.pipe(debounceTime(0), takeUntil(this._destroy$)).subscribe(() => this.cd.detectChanges());
  }

  public close(): void {
    this._dialog.dismiss(null);
  }

  public checkFiles(value: DropzoneOutValue): void {
    const files = value ? (Array.isArray(value) ? value : [value]) : [];

    this.service.checkFiles(Array.from(files));
    this.fileInputEl.clear();
  }

  public stopFileCheckProcess(): void {
    this.lockButtons = true;
    this.service
      .stopCheckProcess()
      .pipe(switchMap(() => this.service.state$.pipe(take(1))))
      .subscribe({
        next: (state) => {
          /**
           * If user has stopped uploading before any file was uploaded, then close modal
           */
          if (state === CheckDocumentState.pristine) {
            this.close();
          }
        },
        complete: () => {
          this.lockButtons = false;
        },
      });
  }

  protected canShowResults(state: CheckDocumentState): boolean {
    return [CheckDocumentState.success, CheckDocumentState.error].includes(state);
  }

  private _documentResponseToGridItem(document: CheckDocumentResponseItem): GridItem[] {
    const error = document.error_code ? this.service.getErrorText(document.error_code) : undefined;

    if (!document.categories?.length) {
      return [
        {
          filename: document.DISPLAY_NAME,
          error: error,
        },
      ];
    }

    return document.categories.map((category) => {
      const qualityCheck = category.QUALITY_CHECK_DOCUMENT || NaN;
      return {
        filename: document.DISPLAY_NAME,
        error: error,
        category: category.DISPLAY_NAME,
        quality: isNaN(qualityCheck) ? '' : `${Math.round(qualityCheck * 100)}%`,
      };
    });
  }
}
