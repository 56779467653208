import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TmUserApiService } from '@tm-shared/api-services/user-api.service';
import { Observable, of } from 'rxjs';
import { UserModel } from '../user.model';

// TODO: выпилить резолверы
@Injectable()
export class UserFormResolver implements Resolve<TmApi.user.CollectionItem> {
  constructor(private _userService: TmUserApiService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<TmApi.user.CollectionItem> {
    const idParam = route.paramMap.get('id');
    const id = idParam ? parseInt(idParam, 10) : NaN;
    return !isNaN(id) ? this._userService.getById(id) : of(new UserModel());
  }
}
