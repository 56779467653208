type cookieType = {
  name: string;
  value: string;
};

export let cookies = {
  delete: function (names?: string | string[]) {
    if (!names) {
      names = cookies.get().map(function (cookie) {
        return cookie.name;
      });
    }

    if (typeof names === 'string') {
      names = [names];
    }

    names.forEach(function (name) {
      cookies.set(name, '', {
        expires: -1,
      });
    });
  },
  get: function (): cookieType[] {
    return document.cookie.split(';').reduce(function (result: any, part: string) {
      let [key, value] = part.split('=');

      if (!key || !value) {
        return result;
      }

      let cookie: cookieType = {
        name: key.trim(),
        value: value.trim(),
      };

      result.push(cookie);

      return result;
    }, []);
  },
  getByName: function (name: string): string | null {
    let cookie = cookies.get().filter(function (cookieElem: cookieType) {
      return cookieElem.name === name;
    })[0];

    if (!cookie) {
      return null;
    }

    return cookie.value;
  },
  set: function (name: string, value: string, options: any = {}): void {
    let expires = options.expires;

    if (typeof expires === 'number' && expires) {
      let d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
      options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = name + '=' + value;

    for (let propName in options) {
      if (!options.hasOwnProperty(propName)) {
        continue;
      }

      updatedCookie += '; ' + propName;
      let propValue = options[propName];

      if (propValue !== true) {
        updatedCookie += '=' + propValue;
      }
    }

    document.cookie = updatedCookie;
  },
};
