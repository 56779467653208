<iw-form
  *ngIf="form && data"
  class="form"
  data-qa="ldapEditForm-ldapEditForm"
  [formGroup]="form"
  (ngSubmit)="validateAndSubmit()"
  iwFormSubmit
>
  <tm-description>
    <tm-d-group>
      <iw-form-row>
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.display_name</label>
        <iw-input-container iwFormRowContent>
          <input data-qa="ldapEditForm-display_name" maxlength="256" formControlName="display_name" iwInput />
          <!--
            @translate settings-ldap.serverEditForm.validation.required
            @translate settings-ldap.serverEditForm.validation.pattern
            @translate settings-ldap.serverEditForm.validation.unique
          -->
          <div iwErrorHints *ngFor="let error of getErrors('display_name')">
            {{ 'settings-ldap.serverEditForm.validation.' + error.key | translate: error.value }}
          </div>
        </iw-input-container>
      </iw-form-row>
      <iw-form-row>
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.server_type</label>
        <iw-select
          class="serverTypeInput"
          data-qa="ldapEditForm-server_type"
          iwFormRowContent
          placeholder="{{ 'settings-ldap.serverEditForm.server_type' | translate }}"
          [searchable]="false"
          [clearable]="false"
          formControlName="server_type"
        >
          <iw-select-option [value]="1">{{
            'settings-ldap.serverEditForm.serverTypeOptions.ad' | translate
          }}</iw-select-option>
          <iw-select-option [value]="2">{{
            'settings-ldap.serverEditForm.serverTypeOptions.dd' | translate
          }}</iw-select-option>
          <iw-select-option [value]="3">{{
            'settings-ldap.serverEditForm.serverTypeOptions.ald' | translate
          }}</iw-select-option>
        </iw-select>
      </iw-form-row>
      <iw-form-row>
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.enabled</label>
        <iw-button-toggle-group data-qa="ldapEditForm-enabled" formControlName="enabled" iwFormRowContent>
          <iw-button-toggle [value]="false">
            <a iw-button>{{ 'settings-ldap.serverEditForm.enabledOptions.disabled' | translate }}</a>
          </iw-button-toggle>
          <iw-button-toggle [value]="true">
            <a iw-button>{{ 'settings-ldap.serverEditForm.enabledOptions.enabled' | translate }}</a>
          </iw-button-toggle>
        </iw-button-toggle-group>
      </iw-form-row>
      <tm-cronjob-select *ngIf="isEnabled('job')" formControlName="job"></tm-cronjob-select>
    </tm-d-group>
    <tm-d-group title="{{ 'settings-ldap.serverEditForm.connectionSettings' | translate }}">
      <!-- TODO: add disclaimer: all sync data will be erased -->
      <tm-panel
        *ngIf="!isNew"
        class="disclaimer"
        type="info"
        text="{{ 'settings-ldap.serverEditForm.dataLossOnEdit' | translate }}"
      ></tm-panel>
      <iw-form-row>
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.address</label>
        <iw-input-container iwFormRowContent>
          <input data-qa="ldapEditForm-address" formControlName="address" iwInput />
          <!--
            @translate settings-ldap.serverEditForm.validation.required
          -->
          <div iwErrorHints *ngFor="let error of getErrors('address')">
            {{ 'settings-ldap.serverEditForm.validation.' + error.key | translate: error.value }}
          </div>
        </iw-input-container>
      </iw-form-row>
      <iw-form-row>
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.protocol</label>
        <iw-select
          class="serverTypeInput"
          data-qa="ldapEditForm-protocol"
          iwFormRowContent
          placeholder="{{ 'settings-ldap.serverEditForm.protocol' | translate }}"
          [searchable]="false"
          [clearable]="false"
          formControlName="protocol"
        >
          <iw-select-option [value]="1">{{
            'settings-ldap.serverEditForm.protocolOptions.ldap' | translate
          }}</iw-select-option>
          <iw-select-option [value]="2">{{
            'settings-ldap.serverEditForm.protocolOptions.ldapWithStartTls' | translate
          }}</iw-select-option>
          <iw-select-option [value]="3">{{
            'settings-ldap.serverEditForm.protocolOptions.ldaps' | translate
          }}</iw-select-option>
        </iw-select>
      </iw-form-row>
      <tm-panel
        class="disclaimer"
        iwFormRowContent
        *ngIf="isInsecureConnection()"
        type="info"
        text="{{ 'settings-ldap.serverEditForm.noCertWarning' | translate }}"
      >
      </tm-panel>
      <iw-form-row *ngIf="isEnabled('ca_cert_file')">
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.ca_cert_file</label>
        <tm-dropzone
          iwFormRowContent
          data-qa="ldapEditForm-ca_cert_file"
          formControlName="ca_cert_file"
          [downloadLink]="getCertDownloadLink('server')"
        ></tm-dropzone>
      </iw-form-row>
      <iw-form-row *ngIf="isEnabled('client_cert_file')">
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.client_cert_file</label>
        <tm-dropzone
          iwFormRowContent
          data-qa="ldapEditForm-client_cert_file"
          formControlName="client_cert_file"
          [downloadLink]="getCertDownloadLink('client')"
        ></tm-dropzone>
      </iw-form-row>
      <iw-form-row *ngIf="isEnabled('private_key_file')">
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.private_key_file</label>
        <tm-dropzone
          iwFormRowContent
          data-qa="ldapEditForm-private_key_file"
          formControlName="private_key_file"
        ></tm-dropzone>
      </iw-form-row>
      <iw-form-row *ngIf="isEnabled('kerberos')">
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.kerberos</label>
        <iw-checkbox
          iwFormRowContent
          formControlName="kerberos"
          [indicatorTemplate]="indicatorTemplate"
          data-qa="ldapEditForm-kerberos"
        ></iw-checkbox>
      </iw-form-row>
      <iw-form-row *ngIf="isEnabled('global_port')">
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.global_port</label>
        <iw-input-container class="globalPortInputContainer" iwFormRowContent>
          <input
            data-qa="ldapEditForm-global_port"
            formControlName="global_port"
            type="number"
            min="1"
            max="65535"
            iwInput
          />
          <!--
            @translate settings-ldap.serverEditForm.validation.required
            @translate settings-ldap.serverEditForm.validation.pattern
            @translate settings-ldap.serverEditForm.validation.max_global
            @translate settings-ldap.serverEditForm.validation.min_global
          -->
          <div iwErrorHints *ngFor="let error of getErrors('global_port')">
            {{ 'settings-ldap.serverEditForm.validation.' + error.key | translate: error.value }}
          </div>
        </iw-input-container>
      </iw-form-row>
      <iw-form-row>
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.dom_port</label>
        <iw-input-container class="ldapPortInputContainer" iwFormRowContent>
          <input data-qa="ldapEditForm-dom_port" formControlName="dom_port" type="number" min="1" max="65535" iwInput />
          <!--
            @translate settings-ldap.serverEditForm.validation.pattern
            @translate settings-ldap.serverEditForm.validation.max
            @translate settings-ldap.serverEditForm.validation.min
          -->
          <div iwErrorHints *ngFor="let error of getErrors('dom_port')">
            {{ 'settings-ldap.serverEditForm.validation.' + error.key | translate: error.value }}
          </div>
        </iw-input-container>
      </iw-form-row>
      <iw-form-row *ngIf="isEnabled('use_global_catalog')">
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.use_global_catalog</label>
        <iw-checkbox
          iwFormRowContent
          formControlName="use_global_catalog"
          [indicatorTemplate]="indicatorTemplate"
          data-qa="ldapEditForm-use_global_catalog"
        ></iw-checkbox>
      </iw-form-row>
      <iw-form-row>
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.base</label>
        <iw-input-container iwFormRowContent>
          <input data-qa="ldapEditForm-base" formControlName="base" iwInput />
          <!--
            @translate settings-ldap.serverEditForm.validation.required
          -->
          <div iwErrorHints *ngFor="let error of getErrors('base')">
            {{ 'settings-ldap.serverEditForm.validation.' + error.key | translate: error.value }}
          </div>
        </iw-input-container>
      </iw-form-row>
      <iw-form-row *ngIf="isEnabled('cert_authentication')">
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.cert_authentication</label>
        <iw-checkbox
          iwFormRowContent
          formControlName="cert_authentication"
          [indicatorTemplate]="indicatorTemplate"
          data-qa="ldapEditForm-cert_authentication"
        ></iw-checkbox>
      </iw-form-row>
      <iw-form-row *ngIf="isEnabled('anon')">
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.anon</label>
        <iw-checkbox
          iwFormRowContent
          formControlName="anon"
          [indicatorTemplate]="indicatorTemplate"
          data-qa="ldapEditForm-anon"
        ></iw-checkbox>
      </iw-form-row>
      <iw-form-row *ngIf="isEnabled('username')">
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.username</label>
        <iw-input-container iwFormRowContent>
          <input data-qa="ldapEditForm-username" autocomplete="off" type="text" formControlName="username" iwInput />
          <!--
            @translate settings-ldap.serverEditForm.validation.required
            @translate settings-ldap.serverEditForm.validation.pattern
          -->
          <div iwErrorHints *ngFor="let error of getErrors('username')">
            {{ 'settings-ldap.serverEditForm.validation.' + error.key | translate: error.value }}
          </div>
        </iw-input-container>
      </iw-form-row>
      <iw-form-row *ngIf="isEnabled('password')">
        <label iwFormRowLabel translate>settings-ldap.serverEditForm.password</label>
        <iw-input-container iwFormRowContent>
          <input
            class="passwordInput"
            data-qa="ldapEditForm-password"
            autocomplete="new-password"
            type="password"
            formControlName="password"
            [placeholder]="passwordStored ? '••••••••••••' : ''"
            iwInput
          />
          <!--
            @translate settings-ldap.serverEditForm.validation.required
            @translate settings-ldap.serverEditForm.validation.pattern
          -->
          <div iwErrorHints *ngFor="let error of getErrors('password')">
            {{ 'settings-ldap.serverEditForm.validation.' + error.key | translate: error.value }}
          </div>
        </iw-input-container>
      </iw-form-row>
    </tm-d-group>
  </tm-description>

  <iw-form-toolbar class="footer">
    <button data-qa="ldapEditForm-submit" iw-button type="submit" color="success">
      {{ 'settings-ldap.serverEditForm.submit' | translate }}
    </button>
    <button data-qa="ldapEditForm-checkConnection" iw-button type="button" (click)="checkConnection()">
      {{ 'settings-ldap.serverDescription.checkConnection' | translate }}
    </button>
    <button data-qa="ldapEditForm-close" iw-button type="button" (click)="close()">
      {{ 'settings-ldap.serverEditForm.cancel' | translate }}
    </button>
  </iw-form-toolbar>
</iw-form>

<ng-template #indicatorTemplate let-checked="checked" let-disabled="disabled">
  <tm-tumbler-indicator [checked]="checked" [disabled]="disabled"></tm-tumbler-indicator>
</ng-template>
