import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IwModalService } from '@platform/shared';
import { TmScopeApiService } from '@tm-shared/api-services/scope-api.service';
import { TmAccessSelectComponent } from '../access-select';
import { TmScopeSelectModalComponent } from './scope-select-modal.component';

@Component({
  selector: 'tm-scope-select',
  templateUrl: './scope-select.component.html',
  styleUrls: ['./scope-select.component.scss'],
})
export class TmScopeSelectComponent extends TmAccessSelectComponent {
  @Input() public control: FormControl;

  public modalComponent = TmScopeSelectModalComponent;

  constructor(public service: TmScopeApiService, _modalService: IwModalService) {
    super(_modalService);
  }
}
