import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TmChannelService } from '@tm-shared/channel';
import { TmStatefulService } from '@tm-shared/dataloader';
import { Observable, Subject, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { TrainingErrorCode, TrainingResponse, TrainingState, WsTraining } from 'typings/generated/technology-autoling';

@Injectable()
export class TmAutolingTrainingService extends TmStatefulService<TrainingResponse> {
  public src = '/api/autoling/training';

  public wsUpdates$ = this._channelService.getUserChannel<WsTraining>('autoling').pipe(
    // TODO: uncomment next string when backend is ready
    // filter(msg => msg.entity === 'al_training_status'),
    // and remove next one!
    filter((data) => data.meta.entity === 'training'),
    shareReplay(1)
  );

  public state$: Observable<TrainingState>;

  public error$: Observable<TrainingErrorCode | 'unknown'> = this.wsUpdates$.pipe(
    filter((res) => res.state === TrainingState.error),
    map((data) => data.meta.error_code || 'unknown')
  );

  public success$: Observable<WsTraining> = this.wsUpdates$.pipe(
    filter((res) => res.state === TrainingState.success),
    map((data) => data)
  );

  protected immediateState$ = new Subject<TrainingState>();

  constructor(protected http: HttpClient, private _channelService: TmChannelService) {
    super(http);

    this.state$ = merge(
      merge(this.wsUpdates$, this.getDataStream(-1)).pipe(
        map((res) => res.state),
        distinctUntilChanged()
      ),
      this.immediateState$
    ).pipe(shareReplay(1));
  }

  public startTraining(): void {
    this.http.post(this.src.toString(), null).subscribe();
    this.immediateState$.next(TrainingState.progress);
  }
}
