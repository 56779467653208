<iw-modal-header>
  {{ modalHeader }}
</iw-modal-header>

<tm-tabset>
  <!-- @translate settings-access.search.searchTitles.person -->
  <!-- @translate settings-access.search.searchTitles.group -->
  <!-- @translate settings-access.search.searchTitles.status -->
  <!-- @translate settings-access.search.searchTitles.workstation -->
  <!-- @translate settings-access.search.searchTitles.tag -->
  <!-- @translate settings-access.search.searchTitles.policy -->
  <!-- @translate settings-access.search.searchTitles.document -->
  <!-- @translate settings-access.search.searchTitles.catalog -->
  <tm-tab
    *ngFor="let scope of scopes"
    tabTitle="{{ 'settings-access.search.searchTitles.' + scope | translate }} {{ getItems(scope) }}"
  >
    <ng-template>
      <div class="tab-container">
        <tm-search-tab-host [component]="components.get(scope)" [(selected)]="selected[scope]"></tm-search-tab-host>
      </div>
    </ng-template>
  </tm-tab>
</tm-tabset>

<iw-modal-footer>
  <button class="button" iw-button color="success" (click)="acceptChanges()">
    {{ 'settings-access.roleSelect.modal.accept' | translate }}
  </button>
  <button class="button" iw-button (click)="rejectChanges()">
    {{ 'settings-access.roleSelect.modal.reject' | translate }}
  </button>
</iw-modal-footer>
