import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { param, paramParse } from '@tm-shared/helpers/url';
import { Subject } from 'rxjs';

@Injectable()
export class TmEventsSearchWidgetService {
  public clear$ = new Subject();

  constructor(private router: Router) {}

  public clear(): void {
    this.clear$.next();
  }

  /**
   * Navigate to events query result page
   */
  public searchBy(ids: string): void {
    // Remove trailing comma
    if (ids.slice(-1) === ',') {
      ids = ids.slice(0, -1);
    }

    if (this.router) {
      this.router
        .navigate([`/events`], {
          queryParams: {
            OBJECT_ID: ids,
          },
        })
        .catch(() => this.tryTmbbNavigation(ids));
    } else {
      this.tryTmbbNavigation(ids);
    }
  }

  /**
   * Try to extract ids from string (multiline, with text, with any delimiters, etc)
   */
  public extractIds(value: string): string {
    const ids = (value.match(/[0-9]+/g) || []).filter((x) => !!x);

    if (/[, ;]/.test(value.slice(-1))) {
      ids.push('');
    }

    return ids.join(',');
  }

  public getIdsFromPageUrl(): string {
    const params: { OBJECT_ID?: string } = paramParse(window.location.search);
    return params.OBJECT_ID ? this.extractIds(params.OBJECT_ID) : '';
  }

  protected tryTmbbNavigation(ids: string): void {
    window.history.pushState(null, '', `/events?${param({ OBJECT_ID: ids })}`);
    window.dispatchEvent(new Event('popstate'));
  }
}
