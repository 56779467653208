import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalDialogRef, IwModalService } from '@platform/shared';
import { TmSearchService } from '@tm-shared/api-services';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ScopeConditionService } from '../scope/scope-condition.service';
import { SearchSelectService } from './search-select.service';
import { TmSearchTabModalComponent } from './search-tab-modal.component';
import { TabComponent, TabComponents } from './search-select.module';

@Component({
  selector: 'tm-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
})
export class TmSearchSelectComponent implements OnInit, OnDestroy {
  @Input() modalComponents: TabComponents = [];

  // scopes for api/search?scopes
  private scopesToSearch: TmApi.search.Scopes[] = [];
  private componentsByScope = new Map<TmApi.search.Scopes, TabComponent>();
  @Input() public additionalTypesToSelect: TmApi.ContactType.CollectionItem[] | null;
  @Input() public modalHeader: string;
  @Input() public placeholder?: string;
  @Input() public control?: FormControl;

  private _destroy$ = new Subject();

  private _modal?: ModalDialogRef<TmSearchTabModalComponent>;

  constructor(
    public service: TmSearchService,
    private _modalService: IwModalService,
    public searchSelectService: SearchSelectService,
    private scopeConditionService: ScopeConditionService
  ) {}

  ngOnInit(): void {
    this.modalComponents.forEach((componentClass) => {
      this.scopesToSearch.push(componentClass.scopeToSearch);
      this.componentsByScope.set(componentClass.scopeToSearch, componentClass);
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public closeDialog(): void {
    if (this._modal) {
      this._modal.close(null);
      delete this._modal;
    }
  }

  public async openDialog(): Promise<void> {
    if (!this._modal) {
      const modal = (this._modal = await this._modalService.open(TmSearchTabModalComponent));

      modal.component.scopes = this.scopesToSearch;
      modal.component.components = this.componentsByScope;
      modal.component.selected = this.searchSelectService.getSelectedByScopes(this.control?.value);
      modal.component.modalHeader = this.modalHeader;
      const outOfScopesItems = this.searchSelectService.getSelectedOutOfScopesItems(this.control?.value);

      /**
       * Bind modal -> formControl data updates
       */
      modal.component.changed
        .pipe(
          take(1),
          takeUntil(modal.dismissed),
          switchMap((items) => this.service.getItemsByScopeAndId(items)),
          map((scopes) => this.scopeConditionService.mapScopesToCondition(scopes)),
          tap((conditions) => conditions.push(...outOfScopesItems)),
          map((conditions) => this.searchSelectService.mapToIwSelect(conditions)),
          takeUntil(this._destroy$)
        )
        .subscribe((data) => this.control?.setValue(data));

      modal.component.exit.pipe(take(1), takeUntil(this._destroy$), takeUntil(modal.dismissed)).subscribe(() => {
        modal.dismiss(null);
      });

      /**
       * Remove _modal reference
       */
      this._modal.dismissed.pipe(takeUntil(this._destroy$), take(1)).subscribe(() => delete this._modal);
    }
  }

  public getSearchCallback(): (options: { query: string }) => Observable<IwSelectItem[]> {
    return ({ query }) => {
      return this.service
        .get({
          params: {
            scopes: this.scopesToSearch.join(','),
            type: 'query',
            query: query,
          },
        })
        .pipe(
          map((response) => response.data),
          map((scopes) => this.scopeConditionService.mapScopesToCondition(scopes)),
          map((conditions) => this.searchSelectService.mapToIwSelect(conditions)),
          map((currentItems) =>
            this.searchSelectService.addAdditionalItems(currentItems, query, this.additionalTypesToSelect || [])
          )
        ) as Observable<IwSelectItem[]>;
    };
  }
}
