import { NgModule } from '@angular/core';
import { TmAuditItemExtendComponent } from './audit-item-extend.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IwSharedModule } from '@platform/shared';
import { TmSharedModule } from '@tm-shared';
import { HttpClientModule } from '@angular/common/http';
import { TmAuditServiceModule } from '../audit-service.module';
import { TmDynamicComponentModule } from '@tm-shared/dynamic-component/dynamic-component.module';
import { TmAuditCellComponentsModule } from '../audit-cell-components/audit-cell-components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    IwSharedModule,
    TmSharedModule,
    HttpClientModule,
    TmAuditServiceModule,
    TmDynamicComponentModule,
    TmAuditCellComponentsModule,
  ],
  declarations: [TmAuditItemExtendComponent],
  exports: [TmAuditItemExtendComponent],
})
export class TmAuditItemExtendedModule {}
