import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { debounceable } from '@platform/shared';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TmGridComponent } from './tm-grid.component';

@Directive({
  selector: '[tmGridFilter]',
})
export class GridFilterDirective implements AfterViewInit, OnDestroy {
  @Input('tmGridFilter') public tableToFilter: TmGridComponent<any>;
  /**
   * api model attribute or any special
   */
  @Input() public propertyToFilter: string;

  /**
   * add * to start of searchable value
   */
  @Input() public startsWithAny: boolean;

  /**
   * add * to end of searchable value
   */
  @Input() public endsWithAny = true;

  private _destroy$ = new Subject();

  constructor(private _el: ElementRef) {}

  public ngAfterViewInit(): void {
    if (this.tableToFilter) {
      this.tableToFilter.restoredState$
        .pipe(take(1), takeUntil(this._destroy$))
        .subscribe(() => this._restoreInputState());
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  @HostListener('input', ['$event'])
  @debounceable(300)
  protected _onInput(event: KeyboardEvent) {
    let value = (<HTMLInputElement>event.target).value;
    if (this.startsWithAny) {
      value = '*' + value;
    }
    if (this.endsWithAny) {
      value += '*';
    }
    this.tableToFilter.updateFilterAndRefresh(this.propertyToFilter, value);
  }

  private _restoreInputState() {
    let state = this.tableToFilter.getGridFilterState(this.propertyToFilter);
    if (state) {
      if (state.startsWith('*')) {
        state = state.substring(1);
      }
      if (state.endsWith('*')) {
        state = state.substring(0, state.length - 1);
      }
      (<HTMLInputElement>this._el.nativeElement).value = state;
    }
  }
}
