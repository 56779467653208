import { TranslateService } from '@ngx-translate/core';
import { BooleanAsStringOrInteger } from 'typings/generated/audit-event-query';
import { AuditExtendGridData, AuditGridDataColumn } from '../audit-extend.service';
import { AuditEvent, AuditEventOperation } from '../audit.model';
import {
  getAuditAttributesFrom,
  getAuditExtendRowData,
  TransformOptions,
  VisibleAttributesOption,
} from './audit-common';

const auditProtectedCatalogVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'DISPLAY_NAME', i18nKey: 'audit.protectedCatalog.displayName' },
  { path: 'NOTE', i18nKey: 'audit.protectedCatalog.note' },
  { path: 'ENABLED', i18nKey: 'audit.protectedCatalog.enabled', transform: transformStatus },
  { path: 'parent.DISPLAY_NAME', i18nKey: 'audit.protectedCatalog.parentFolder' },
];

export function getProtectedCatalogGrids(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  let cols: AuditGridDataColumn[] = [];
  /**
   * Set correct columns
   */
  switch (data.OPERATION) {
    case AuditEventOperation.create:
      cols = [AuditGridDataColumn.new];
      break;
    case AuditEventOperation.delete:
      cols = [AuditGridDataColumn.old];
      break;
    case AuditEventOperation.move:
    case AuditEventOperation.update:
    default:
      cols = [AuditGridDataColumn.old, AuditGridDataColumn.new];
      break;
  }

  return [
    {
      cols: cols,
      data: getAuditExtendRowData({
        t,
        cols: cols,
        data: getAuditAttributesFrom(data),
        visibleAttributes: auditProtectedCatalogVisibleAttributes,
      }),
    },
  ];
}

export function transformStatus(
  enabled: BooleanAsStringOrInteger | null | undefined,
  o: TransformOptions
): string | null {
  if (enabled !== undefined && enabled !== null) {
    return +enabled === 1
      ? o.t.instant('audit.protectedCatalog.enabledOptions.active')
      : o.t.instant('audit.protectedCatalog.enabledOptions.inactive');
  }

  return null;
}
