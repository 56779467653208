import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmRoleApiService } from '@tm-shared/api-services/role-api.service';
import { CheckboxCellComponent } from '@tm-shared/grid/cell-renderers';
import { GridOptions } from 'ag-grid-community';
import { TmAccessSelectModalComponent } from '../access-select';

@Component({
  templateUrl: './role-select-modal.component.html',
  styleUrls: ['./role-select-modal.component.scss'],
})
export class TmRoleSelectModalComponent extends TmAccessSelectModalComponent {
  public tableParams = {
    'merge_with[]': 'users',
  };

  public readonly gridOptions: GridOptions = {
    columnDefs: [
      {
        width: 30,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        sort: 'asc',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('settings-access.role.fieldNames.displayName'),
      },
      {
        resizable: true,
        sortable: true,
        field: 'users',
        headerValueGetter: () => this._t.instant('settings-access.role.fieldNames.users'),
        valueGetter: ({ data }: { data: TmApi.role.CollectionItem }): string => {
          return data.users
            .map((user) => {
              return user.USERNAME;
            })
            .sort()
            .join(', ');
        },
      },
      {
        width: 400,
        resizable: true,
        sortable: true,
        field: 'NOTE',
        headerValueGetter: () => this._t.instant('settings-access.role.fieldNames.note'),
      },
    ],
  };

  constructor(protected _t: TranslateService, public service: TmRoleApiService) {
    super(_t);
  }
}
