import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TmSharedModule } from '@tm-shared';
import { TmDeleteConfirmModule } from '@tm-shared/delete-confirm/delete-confirm.module';
import { CanDeactivateTmForm } from '@tm-shared/form';
import { TmFormHelpersModule } from '@tm-shared/form-helpers/form-helpers.module';
import { TmGridModule } from '@tm-shared/grid';
import { TmLocalizeDateModule } from '@tm-shared/localize-date/localize-date.module';
import { TmModals } from '@tm-shared/modals';
import { TmSpinnerModule } from '@tm-shared/spinner';
import { TmStructureModule } from '@tm-shared/structure';
import { TmToolbarModule } from '@tm-shared/toolbar';
import { TmTreeModule } from '@tm-shared/tree';
import { AccessComponent } from './access.component';
import { ROUTES } from './access.routes';
import LANG_DATA from './i18n';
import { RoleComponent, RoleFormComponent } from './role';
import { TmRoleSelectModule } from './role-select/role-select.module';
import { RoleDeleteModalComponent } from './role/role-delete';
import { ScopeComponent, ScopeFormComponent } from './scope';
import { TmScopeSelectModule } from './scope-select/scope-select.module';
import { ScopeFormResolver } from './scope/scope-form.resolver';
import { TmSearchSelectModule } from './search-select/search-select.module';
import { UserComponent, UserEditComponent, UserFormResolver } from './user';
import { TmChangePasswordComponent, TmChangePasswordService } from './user/change-password';
import { TmLdapUserSelectComponent } from './user/ldap-user-select';
import { UserDeleteModalComponent } from './user/user-delete-modal';
import { IwPopoverOptions, IwSharedModule } from '@platform/shared';

@NgModule({
  imports: [
    IwSharedModule,
    RouterModule.forChild(ROUTES),
    ReactiveFormsModule,
    TmGridModule,
    TranslateModule,
    TmToolbarModule,
    TmTreeModule,
    TmStructureModule,
    CommonModule,
    TmModals,
    TmSharedModule,
    TmRoleSelectModule,
    TmScopeSelectModule,
    TmSearchSelectModule,
    TmLocalizeDateModule,
    TmDeleteConfirmModule,
    TmSpinnerModule,
    TmFormHelpersModule,
  ],
  declarations: [
    AccessComponent,
    ScopeComponent,
    ScopeFormComponent,
    RoleComponent,
    RoleFormComponent,
    UserComponent,
    UserEditComponent,
    TmLdapUserSelectComponent,
    UserDeleteModalComponent,
    TmChangePasswordComponent,
    RoleDeleteModalComponent,
  ],
  providers: [
    CanDeactivateTmForm,
    UserFormResolver,
    ScopeFormResolver,
    TmChangePasswordService,
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
      }),
    },
  ],
  entryComponents: [
    TmLdapUserSelectComponent,
    UserDeleteModalComponent,
    TmChangePasswordComponent,
    RoleDeleteModalComponent,
  ],
})
export class AccessModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('ru', LANG_DATA.ru, true);
    this.translate.setTranslation('en', LANG_DATA.en, true);
  }
}

export { LANG_DATA };
