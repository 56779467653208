import { cookies } from '@tm-shared/helpers';

export abstract class TmAbstractXsrfInterceptor {
  public readonly headerKey = 'x-csrf-token';

  public readonly cookieKey = 'x-csrf-token';

  protected _token: string | null;

  constructor() {
    this._token = cookies.getByName(this.cookieKey);
  }

  protected _storeToken(token: string | null | undefined): void {
    if (token) {
      cookies.set(this.cookieKey, (this._token = token));
    }
  }
}
