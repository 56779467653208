import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TmTreeComponent } from '@tm-shared/tree';
import { BehaviorSubject, Observable, Subject, combineLatest, merge } from 'rxjs';
import { delay, filter, map, mapTo, skip, take, takeUntil } from 'rxjs/operators';
import { TREE_ROOT } from '../files.model';
import { FilesService } from './files.service';

@Component({
  templateUrl: 'files.component.html',
  styleUrls: ['files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FilesService],
})
export class FilesComponent implements AfterViewInit, OnDestroy {
  public treeData$ = this._filesService.treeItems$;
  public title$: Observable<string>;

  public readonly TREE_ROOT = TREE_ROOT;

  public createPolicyDisabled$: Observable<boolean>;
  private _selectedNodeId$ = new BehaviorSubject<string>('');
  private _selectedNodeIsRootOrAbsent$ = this._selectedNodeId$.pipe(
    map((selectedNodeId) => !selectedNodeId || selectedNodeId === TREE_ROOT)
  );

  private _destroy$ = new Subject();
  @ViewChild(TmTreeComponent, { static: false }) private _treeComponent: TmTreeComponent;
  constructor(private _filesService: FilesService, private _router: Router) {}

  public onSidebarItemSelect(id: string) {
    this._selectedNodeId$.next(id);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngAfterViewInit() {
    this.createPolicyDisabled$ = combineLatest([
      this._filesService.canCreatePolicy$,
      this._selectedNodeIsRootOrAbsent$,
    ]).pipe(map(([canCreate, isRootOrAbsent]) => !canCreate || isRootOrAbsent));

    const treeDataReceived$ = this.treeData$.pipe(skip(1), take(1), delay(0));
    const navigated$ = this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      mapTo(undefined)
    );

    merge(treeDataReceived$, navigated$)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data) => this._filesService.selectCurrentTreeNode(this._treeComponent, data));

    this.title$ = merge(this.treeData$.pipe(skip(1)), this._treeComponent.onActiveNode).pipe(
      map(() => {
        const selected = this._treeComponent.getActiveNodeIds();
        if (selected.length) {
          return this._treeComponent.getNodeById(selected[0])!.data.name;
        }
      })
    );
  }

  public createPolicyFromFiletypes(policyType: 'OBJECT' | 'AGENT') {
    this._filesService.createPolicyAndNavigate(policyType, this._treeComponent.getActiveNodeIds());
  }
}
