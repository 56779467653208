import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmSharedModule } from '@tm-shared';
import { TmAnalysisTextPatternValidatorComponent } from './analysis-text-pattern-validator/analysis-text-pattern-validator.component';
import { TmAnalysisTextPatternValidatorService } from './analysis-text-pattern-validator/analysis-text-pattern-validator.service';
import { TmAnalysisTextPatternService } from './analysis-text-pattern/analysis-text-pattern-service';
import { TmAnalysisTextPatternComponent } from './analysis-text-pattern/analysis-text-pattern.component';
import { TmAnalysisTextService } from './analysis-text-service';

const ru = require('./i18n/analysis-text.ru.json');
const en = require('./i18n/analysis-text.en.json');

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    IwSharedModule,
    TmSharedModule,
    HttpClientModule,
    TmSharedModule,
  ],
  providers: [TmAnalysisTextPatternValidatorService, TmAnalysisTextPatternService, TmAnalysisTextService],
  declarations: [TmAnalysisTextPatternComponent, TmAnalysisTextPatternValidatorComponent],
  entryComponents: [TmAnalysisTextPatternComponent, TmAnalysisTextPatternValidatorComponent],
})
export class TmAnalysisTextElementsModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('ru', ru, true);
    this.translate.setTranslation('en', en, true);
  }
}
