import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { Observable, Subject, of } from 'rxjs';
import { map, pluck, shareReplay, switchMap, take } from 'rxjs/operators';
import { TmAccessRequest, isTmActionRequest, isTmModuleRequest } from '../privileges';
import {
  LICENSE_API,
  TmLicense,
  TmLicenseDataModel,
  TmLicenseFeatureContainer,
  TmTechnologyFeature,
  TmTechnologyFeaturesMap,
} from './tm-license.model';

export const TM_INTERCEPTOR_ORIGIN = 'tm';
export const ANY_OBJECT_TYPE = '*';
export const ANY_PROTOCOL = '*';
export const NONE_PROTOCOL = 'NONE';

const TECHNOLOGY_FEATURES_MAP: TmTechnologyFeaturesMap = {
  term: 'classifier',
  fingerprint: 'stamper',
  text_object: 'researcher',
  form: 'formanalysis',
  stamp: 'stamp_detector',
  graphic: 'graphic',
  table: 'tableanalysis',
  affiliate: 'affiliate',
  autoling: 'autoling_classifier',
};

export type TmTechKey = keyof TmTechnologyFeaturesMap;

/**
 * License manager service
 */
@Injectable()
export class TmLicenseService extends TmCollectionLoader<TmLicenseDataModel> {
  public idAttribute = 'fingerprint';
  public src: string = LICENSE_API.licenses;

  /**
   * All licenses stream
   */
  public all$ = of(null).pipe(
    switchMap(() => this.getWithRetries(1000, 3)),
    map((response) => response.data),
    map((models) => models.map((model) => new TmLicense(model))),
    shareReplay(1)
  );

  /**
   * Active licenses stream
   */
  public active$: Observable<TmLicense[]> = this.all$.pipe(
    map((licenses: TmLicense[]) => licenses.filter((license) => license.isActive)),
    shareReplay(1)
  );

  /**
   * Unactive licenses stream
   */
  public unactive$: Observable<TmLicense[]> = this.all$.pipe(
    map((licenses: TmLicense[]) => licenses.filter((license) => license.isExpired || license.isReserved)),
    shareReplay(1)
  );

  /**
   * Reserved licenses stream
   */
  public reserved$: Observable<TmLicense[]> = this.all$.pipe(
    map((licenses: TmLicense[]) => licenses.filter((license) => license.isReserved)),
    shareReplay(1)
  );

  /**
   * Expiring licenses stream
   */
  public expireSoon$: Observable<TmLicense[]> = this.all$.pipe(
    map((licenses: TmLicense[]) => licenses.filter((license) => license.isExpireSoon)),
    shareReplay(1)
  );

  /**
   * Aactive features stream
   */
  public activeFeatures$: Observable<TmLicenseFeatureContainer> = this.active$.pipe(
    map((licenses: TmLicense[]) => new TmLicenseFeatureContainer(licenses)),
    shareReplay(1)
  );

  /**
   * Unactive features stream
   */
  public unactiveFeatures$: Observable<TmLicenseFeatureContainer> = this.unactive$.pipe(
    map((licenses: TmLicense[]) => new TmLicenseFeatureContainer(licenses)),
    shareReplay(1)
  );

  constructor(private _httpClient: HttpClient) {
    super(_httpClient);
  }

  public isActiveTechnologyFeature(name: TmTechKey): Observable<boolean>;
  public isActiveTechnologyFeature(name: TmTechKey[]): Observable<{ [key in TmTechKey]?: boolean }>;
  public isActiveTechnologyFeature(
    name: TmTechKey | TmTechKey[]
  ): Observable<boolean | { [key in TmTechKey]?: boolean }> {
    return this.activeFeatures$.pipe(
      map((features: TmLicenseFeatureContainer) => {
        if (!Array.isArray(name)) {
          return this._hasTechnologyFeature(features, TECHNOLOGY_FEATURES_MAP[name]);
        }

        return name.reduce((result, key) => {
          result[key] = this._hasTechnologyFeature(features, TECHNOLOGY_FEATURES_MAP[key]);
          return result;
        }, {} as { [key in TmTechKey]?: boolean });
      })
    );
  }

  public isUnactiveTechnologyFeature(name: TmTechKey): Observable<boolean> {
    return this.unactiveFeatures$.pipe(
      map((features: TmLicenseFeatureContainer) => {
        return this._hasTechnologyFeature(features, TECHNOLOGY_FEATURES_MAP[name]);
      })
    );
  }

  public getLicenseAccessStream(accessRequest: TmAccessRequest): Observable<boolean> {
    /**
     * Check if license stream is applicable
     */
    if (!this.isApplicable(accessRequest)) {
      return of(true);
    }

    return this.activeFeatures$.pipe(
      map((features: TmLicenseFeatureContainer): boolean => {
        if (isTmActionRequest(accessRequest) && this.isObjKey(accessRequest.type, TECHNOLOGY_FEATURES_MAP)) {
          return this._hasTechnologyFeature(features, TECHNOLOGY_FEATURES_MAP[accessRequest.type]);
        }

        if (isTmModuleRequest(accessRequest) && accessRequest.module === 'analysis') {
          return this._findAnyTechnologyFeature(features);
        }

        if (isTmModuleRequest(accessRequest) && accessRequest.module === 'crawler') {
          return this._findInterceptFeature(features, accessRequest.module, TM_INTERCEPTOR_ORIGIN);
        }

        return true;
      })
    );
  }

  private isObjKey<T>(key: any, obj: T): key is keyof T {
    return key in obj;
  }

  public uploadLicense(json: any): Observable<TmLicenseDataModel> {
    return this._httpClient.post(LICENSE_API.parse, json, { observe: 'response' }).pipe(pluck('data'));
  }

  public readLicenseFile(file: File): Observable<{}> {
    const reader: FileReader = new FileReader();
    const json$: Subject<string> = new Subject();

    reader.addEventListener(
      'loadend',
      ({ target }) => {
        const result = target && (target as any).result;

        if (!result) {
          throw Error('Error while reading license file');
        }

        json$.next(result);
      },
      { once: true }
    );

    reader.readAsText(file);

    return json$.pipe(
      map((json) => JSON.parse(json)),
      take(1)
    );
  }

  public isApplicable(accessRequest: TmAccessRequest): boolean {
    return Boolean(
      (isTmActionRequest(accessRequest) && accessRequest.type in TECHNOLOGY_FEATURES_MAP) ||
        (isTmModuleRequest(accessRequest) && ['analysis', 'crawler'].indexOf(accessRequest.module) > -1)
    );
  }

  /**
   * TODO: process socket message
   */
  public updateBySocketMessage(_socketData: any) {}

  private _hasTechnologyFeature(features: TmLicenseFeatureContainer, requestedFeature: string): boolean {
    return (
      features.technologyFeatures.findIndex(
        (feature: TmTechnologyFeature): boolean => feature.cas === requestedFeature
      ) !== -1
    );
  }

  private _findAnyTechnologyFeature(features: TmLicenseFeatureContainer) {
    return !!features.technologyFeatures.find((feature: TmTechnologyFeature) => feature.cas in TECHNOLOGY_FEATURES_MAP);
  }

  private _findInterceptFeature(features: TmLicenseFeatureContainer, objectType: string, origin: string): boolean {
    for (const feature of features.interceptFeatures) {
      if (
        [ANY_OBJECT_TYPE, objectType].includes(feature.object_type) &&
        [ANY_PROTOCOL, NONE_PROTOCOL].includes(feature.protocol) &&
        feature.origin === origin
      ) {
        return true;
      }
    }

    return false;
  }
}
