import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalConfirmComponent } from '../confirm';
import { ModalDialogRef } from '@platform/shared';

@Component({
  templateUrl: 'modal-confirm-with-items.component.html',
  styleUrls: ['modal-confirm-with-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmWithItemsComponent extends ModalConfirmComponent implements OnInit {
  @Input() public itemsToDisplay: string[];
  public preparedItemsToDisplay: string;

  constructor(_t: TranslateService, _dialog: ModalDialogRef<unknown>) {
    super(_t, _dialog);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.preparedItemsToDisplay = this.itemsToDisplay.join(', ');
  }
}
