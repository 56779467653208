import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Type,
  ComponentFactoryResolver,
  ViewContainerRef,
  ComponentRef,
  OnChanges,
  OnInit,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

type Inputs<T> = Record<keyof T, T[keyof T]>;

/**
 * It differs from <iw-dynamic-container> (no PluginModules dependency, allow to provide constructor),
 * and provides easier input binding than *ngComponentOutlet.
 */
@Component({
  selector: 'tm-dynamic-component',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmDynamicComponent<T> implements OnChanges, OnInit, OnDestroy {
  @Input() public component?: Type<T>;

  @Input() public inputs?: Inputs<T>;

  private childrenComponent?: ComponentRef<T>;

  constructor(private container: ViewContainerRef, private cfr: ComponentFactoryResolver) {}

  public ngOnDestroy(): void {
    this.destroyChildComponent();
  }

  public ngOnInit(): void {
    if (this.component) {
      this.appendComponent(this.component);

      if (this.inputs) {
        this.updateInputs(this.inputs);
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.component && this.component) {
      this.appendComponent(this.component);
    }

    if (changes.inputs && this.inputs) {
      this.updateInputs(this.inputs);
    }
  }

  private destroyChildComponent(): void {
    if (this.childrenComponent) {
      this.childrenComponent.destroy();
    }
  }

  private appendComponent(component?: Type<T>): void {
    this.destroyChildComponent();
    if (component) {
      this.childrenComponent = this.container.createComponent(this.cfr.resolveComponentFactory(component));
    }
  }

  private updateInputs(inputs?: Inputs<T>): void {
    const component = this.childrenComponent;

    if (inputs && component) {
      Object.keys(inputs).forEach((key) => (component.instance[key as keyof T] = inputs[key as keyof T]));
    }
  }
}
