<a class="audit-event-trigger" (click)="toggleShow()">
  {{ 'audit.moreInfo' | translate }}
</a>
<div *ngIf="moreInfoIsShowed" class="audit-event-advanced">
  <ng-container *ngFor="let grid of grids">
    <div *ngIf="grid.title" class="audit-event-header">
      {{ grid.title }}
    </div>
    <table>
      <thead>
        <th>
          {{ 'audit.gridHeaders.name' | translate }}
        </th>
        <th *ngFor="let columnKey of grid.cols">
          <!-- @translate audit.gridHeaders.request -->
          <!-- @translate audit.gridHeaders.old -->
          <!-- @translate audit.gridHeaders.new -->
          {{ 'audit.gridHeaders.' + columnKey | translate }}
        </th>
      </thead>
      <tr *ngFor="let rowData of grid.data">
        <td>{{ rowData.name }}</td>
        <ng-container *ngFor="let columnKey of grid.cols">
          <td>
            <tm-dynamic-component
              *ngIf="rowData.component"
              [component]="rowData.component"
              [inputs]="{ data: rowData[columnKey] }"
            ></tm-dynamic-component>
            <span *ngIf="!rowData.component" [innerHTML]="rowData[columnKey]"></span>
          </td>
        </ng-container>
      </tr>
    </table>
  </ng-container>
</div>
