import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { TmLdapStatusApiService } from '@tm-shared/api-services';
import { TmBookwormContactTypeService } from '@tm-shared/bookworm';
import { TmFormComponent } from '@tm-shared/form';
import { TmAsyncValidatorsService } from '@tm-shared/helpers';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmSidebarService } from '@tm-shared/structure/sidebar/sidebar.service';
import { EMPTY, Observable, combineLatest, merge, of, throwError } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';
import { getPrivilegeRequest } from '../status-exports';
import { StatusFormService } from './status-form.service';

@Component({
  selector: 'tm-status-form',
  templateUrl: './status-form.component.html',
  styleUrls: ['./status-form.component.scss'],
  providers: [TmAsyncValidatorsService, StatusFormService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusFormComponent extends TmFormComponent<FormGroup> implements OnInit {
  public createDate: string;
  public changeDate: string;
  public isNew$: Observable<boolean> = this._statusFormService.statusId$.pipe(map((id) => !id));

  /**
   * Stream: Form data
   */
  public data$ = this._statusFormService.statusId$.pipe(
    switchMap((id) => (id ? this.service.getById(id) : EMPTY)),
    filter((data) => (!data ? (this.close(), false) : true)),
    takeUntil(this._destroyed$)
  );

  private _modelIsEditable$: Observable<boolean> = this.data$.pipe(map((data) => this.service.isModelEditable(data)));

  private _canEditStatus$: Observable<boolean> = this._privileges.can(getPrivilegeRequest('edit')).pipe(shareReplay(1));

  constructor(
    public service: TmLdapStatusApiService,
    private _sidebar: TmSidebarService,
    private _notify: IwNotificationsService,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    private _t: TranslateService,
    private _privileges: TmPrivilegesService,
    public contactTypesService: TmBookwormContactTypeService,
    private _statusFormService: StatusFormService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = this._statusFormService.createStatusFormGroup();

    this.data$
      .pipe(
        switchMap((data) => combineLatest([of(data), this._modelIsEditable$, this._canEditStatus$]).pipe(take(1))),
        takeUntil(this._destroyed$)
      )
      .subscribe(([data, modelIsEditable, canEditStatus]) => {
        this.form.reset();
        this.form.patchValue(data);
        if (data.CREATE_DATE) {
          this.createDate = data.CREATE_DATE;
        }
        if (data.CHANGE_DATE) {
          this.changeDate = data.CHANGE_DATE;
        }

        this._statusFormService.handleFormDisableState(this.form, modelIsEditable, canEditStatus);
      });

    merge(this.close$, this.submit$)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this._closeSidebar());

    this._watchIsNewToChangeTitle();
    this._sidebar.open('right');
  }
  /**
   * Save form data
   */
  protected _onSubmit() {
    const serverData: TmApi.ldapStatus.CollectionItem = this.form.getRawValue();

    return this.service.createOrUpdate(serverData).pipe(
      catchError((response) => {
        this._notify.error(
          this._t.instant('lists-statuses.errors.error'),
          this._t.instant('lists-statuses.errors.request-failed')
        );
        return throwError(response);
      }),
      tap(() => this.close(true)),
      takeUntil(this._destroyed$)
    );
  }

  private _watchIsNewToChangeTitle() {
    merge(this.isNew$, this._t.onLangChange.pipe(switchMapTo(this.isNew$)))
      .pipe(takeUntil(this._destroyed$))
      .subscribe((isNew) => {
        let title;

        if (isNew) {
          title = this._t.instant('lists-statuses.form.title-create-new');
        } else {
          title = this._t.instant('lists-statuses.form.title-edit');
        }

        this._sidebar.updateTitle(title);
      });
  }

  /**
   * Close sidebar
   */
  private _closeSidebar() {
    this._router.navigate(['./'], {
      relativeTo: this._activatedRoute.parent,
      queryParamsHandling: 'merge',
    });

    this._sidebar.close('right');
  }
}
