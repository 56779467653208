import { TranslateService } from '@ngx-translate/core';
import { AuditExtendGridData } from '../audit-extend.service';
import { AuditEvent } from '../audit.model';
import {
  VisibleAttributesOption,
  getAuditExtendRowData,
  getAuditAttributesFrom,
  getDefaultColumns,
  transformTech2Category,
} from './audit-common';

const auditFingerprintVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'DISPLAY_NAME', i18nKey: 'audit.entries.fingerprint.displayName' },
  { path: 'FILE_PATH', i18nKey: 'audit.entries.fingerprint.filePath' },
  { path: 'MIME', i18nKey: 'audit.entries.fingerprint.mime' },
  { path: 'NOTE', i18nKey: 'audit.entries.fingerprint.note' },
  { path: 'BIN_VALUE_THRESHOLD', i18nKey: 'audit.entries.fingerprint.binValThreshold' },
  { path: 'TEXT_VALUE_THRESHOLD', i18nKey: 'audit.entries.fingerprint.textValThreshold' },
  { path: 'fingerprint2category', i18nKey: 'audit.entries.fingerprint.categories', transform: transformTech2Category },
];

export function getFingerprintGridData(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  const cols = getDefaultColumns(data);
  const result: AuditExtendGridData[] = [];
  const baseData = getAuditExtendRowData({
    t,
    cols: cols,
    data: getAuditAttributesFrom(data),
    visibleAttributes: auditFingerprintVisibleAttributes,
  });

  if (baseData.length) {
    result.push({
      cols,
      data: baseData,
    });
  }

  return result;
}
