<div class="tree">
  <ng-container *ngIf="useFilter">
    <iw-input-container [allowClear]="true" (valueChanged)="setFilter(filter.value)">
      <input iwInput id="filter" #filter placeholder="{{ '@tm-shared.searchPlaceholder' | translate }}" />
      <a iwPostfix href="javascript:void(0)" class="removeBtn"></a>
    </iw-input-container>
  </ng-container>

  <div class="tree-wrapper">
    <tree-root
      (moveNode)="onMoveNode.emit($event)"
      (initialized)="onInitialized.emit($event)"
      (activate)="onActiveNode.emit($event)"
      (select)="selectionChanged($event)"
      (deselect)="selectionChanged($event)"
      (toggleExpanded)="onExpandNode.emit($event)"
      (loadNodeChildren)="loadNodeChildren.emit($event)"
      (updateData)="updateData.emit($event)"
      [nodes]="treeData"
      [options]="treeOptions"
      [(state)]="state"
    >
      <ng-template #loadingTemplate>{{ '@tm-shared.tree.loading' | translate }}</ng-template>

      <ng-template #treeNodeTemplate let-node let-index="index">
        <!-- Use external template if any -->
        <ng-container *ngIf="overrideNodeTpl">
          <ng-container
            [ngTemplateOutlet]="overrideNodeTpl"
            [ngTemplateOutletContext]="{ node: node, index: index }"
          ></ng-container>
        </ng-container>
        <ng-container *ngIf="!overrideNodeTpl">
          <span>{{ node.data.name }}</span>
          <i
            *ngIf="node.data.hintIconText"
            class="question-icon"
            [iwPopover]="node.data.hintIconText"
            [closeOnMousemoveOutside]="true"
            [triggers]="'mouseenter:mouseleave'"
          ></i>
          <span *ngIf="useStatus && node.data[statusField]" [class]="'node-status-' + node.data[statusField]"></span>
        </ng-container>
      </ng-template>

      <ng-template #treeNodeWrapperTemplate let-node let-index="index">
        <!-- Use external template if any -->
        <ng-container *ngIf="overrideNodeContentWrapperTpl">
          <ng-container
            [ngTemplateOutlet]="overrideNodeContentWrapperTpl"
            [ngTemplateOutletContext]="{ node: node, index: index }"
          ></ng-container>
        </ng-container>

        <!-- Use template with links if configured -->
        <a
          *ngIf="!overrideNodeContentWrapperTpl && treeItemsWithLinks"
          class="node-wrapper"
          (drop)="onNativeDropEvent(node.id, $event)"
          [routerLink]="
            treeItemLinkParams?.routerLinkGetter ? treeItemLinkParams?.routerLinkGetter!(node.id) : [node.id]
          "
          [replaceUrl]="!!treeItemLinkParams?.replaceUrl"
          [queryParamsHandling]="treeItemLinkParams?.queryParamsHandling || 'merge'"
        >
          <ng-container
            [ngTemplateOutlet]="nodeContentWrapperTpl"
            [ngTemplateOutletContext]="{ node: node, index: index }"
          ></ng-container>
        </a>

        <div
          *ngIf="!overrideNodeContentWrapperTpl && !treeItemsWithLinks"
          class="node-wrapper"
          [class._disabled]="isNodeDisabled(node)"
        >
          <ng-container
            [ngTemplateOutlet]="nodeContentWrapperTpl"
            [ngTemplateOutletContext]="{ node: node, index: index }"
          ></ng-container>
        </div>

        <ng-template #nodeContentWrapperTpl>
          <div
            class="node-content-wrapper"
            [class.node-content-wrapper-active]="node.isActive"
            [class.node-content-wrapper-focused]="node.isFocused"
            (click)="node.mouseAction('click', $event)"
            (dblclick)="node.mouseAction('dblClick', $event)"
            (treeDrop)="node.onDrop($event)"
            (treeDropDragOver)="node.mouseAction('dragOver', $event)"
            (treeDropDragLeave)="node.mouseAction('dragLeave', $event)"
            (treeDropDragEnter)="node.mouseAction('dragEnter', $event)"
            [treeAllowDrop]="node.allowDrop"
            [treeDrag]="node"
            [treeDragEnabled]="node.allowDrag()"
            [tmContextMenu]="useContextMenu ? contextMenuItems : []"
            [tmContextMenuData]="node"
            [style.padding-left]="node.getNodePadding()"
          >
            <div class="expander">
              <tree-node-expander *ngIf="node.hasChildren" [node]="node"></tree-node-expander>
            </div>
            <label
              *ngIf="node.options.useCheckbox"
              class="checkbox"
              [class.allSelected]="node.isAllSelected"
              [class.someSelected]="node.isPartiallySelected"
            >
              <input
                type="checkbox"
                (change)="checkboxClicked(node, $event)"
                [indeterminate]="node.isPartiallySelected"
                [checked]="node.isSelected"
                [disabled]="isNodeDisabled(node)"
              />
              <span></span>
            </label>

            <tree-node-content
              class="treeNodeContent"
              [node]="node"
              [index]="index"
              [template]="treeNodeTemplate"
            ></tree-node-content>
          </div>
        </ng-template>
      </ng-template>
    </tree-root>
  </div>
</div>
