import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwModalService, IwNotificationsService } from '@platform/shared';
import { TmScopeApiService } from '@tm-shared/api-services/scope-api.service';
import { TmBookwormContactTypeService } from '@tm-shared/bookworm';
import { TmFormComponent } from '@tm-shared/form';
import { TmAsyncValidatorsService } from '@tm-shared/helpers';
import { PATTERN_STRING_256_1 } from '@tm-shared/helpers/patterns';
import { requiredAnyValidator, whitespacesValidator } from '@tm-shared/helpers/validators/validators';
import { ModalConfirmComponent } from '@tm-shared/modals';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmSidebarService } from '@tm-shared/structure/sidebar/sidebar.service';
import { Observable, forkJoin, merge, of, throwError } from 'rxjs';
import { catchError, map, pluck, shareReplay, switchMap, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';
import { SearchSelectService } from '../search-select/search-select.service';
import { getPrivilegeRequest } from './scope.model';
import LinkOperator = TmApi.scope.LinkOperator;
import { PersonTabComponent } from '../search-select/tab-components/person-tab.component';
import { StatusTabComponent } from '../search-select/tab-components/status-tab.component';
import { GroupTabComponent } from '../search-select/tab-components/group-tab.component';
import { WorkstationTabComponent } from '../search-select/tab-components/workstation-tab.component';
import { TagTabComponent } from '../search-select/tab-components/tag-tab.component';
import { PolicyTabComponent } from '../search-select/tab-components/policy-tab.component';
import { DocumentTabComponent } from '../search-select/tab-components/document-tab.component';
import { CatalogTabComponent } from '../search-select/tab-components/catalog-tab.component';

const FULL_ACCESS_SCOPE_ID = 'F00207A1E7E7743EE0433D003C0A5DD400000000';

/**
 * @translate settings-access.validation.validationError
 * @translate settings-access.validation.empty
 * @translate settings-access.validation.not_unique_field
 * @translate settings-access.validation.maxlength
 * @translate settings-access.validation.minlength
 * @translate settings-access.validation.required
 * @translate settings-access.validation.pattern
 */
@Component({
  selector: 'tm-scope-form',
  templateUrl: './scope-form.component.html',
  styleUrls: ['./scope-form.component.scss'],
  providers: [TmAsyncValidatorsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScopeFormComponent extends TmFormComponent<FormGroup> implements OnInit {
  public createDate: string;
  public changeDate: string;

  public recipientsComponents = [PersonTabComponent, GroupTabComponent, StatusTabComponent];
  public workStationComponents = [WorkstationTabComponent, GroupTabComponent, StatusTabComponent];
  public tagComponents = [TagTabComponent];
  public policyComponents = [PolicyTabComponent];
  public documentComponents = [DocumentTabComponent, CatalogTabComponent];

  /**
   * UserId from router params
   */
  public scopeId$: Observable<string> = this._activatedRoute.params.pipe(map((params: { id: string }) => params.id));

  public confirmDataLoss$ = of(null).pipe(
    switchMap(() => {
      return this._modalService.open(ModalConfirmComponent, {
        title: this._t.instant('settings-access.confirmAction'),
        text: this._t.instant('settings-access.user.form.dataLossWarning'),
      });
    }),
    switchMap((modal) => {
      return modal.component.decision.pipe(map((ok) => Boolean(ok)));
    })
  );

  /**
   * Stream: Form data
   */
  public data$: Observable<TmApi.scope.CollectionItem | null> = this._activatedRoute.data.pipe(pluck('model'));

  private _isNew$: Observable<boolean> = this.scopeId$.pipe(map((id) => !id));

  private _canEditScope$: Observable<boolean> = this._privileges
    .can(getPrivilegeRequest('edit'))
    .pipe(take(1), shareReplay(1));

  constructor(
    public service: TmScopeApiService,
    private _sidebar: TmSidebarService,
    private _t: TranslateService,
    protected _router: Router,
    private _selectService: SearchSelectService,
    protected _activatedRoute: ActivatedRoute,
    public contactTypesService: TmBookwormContactTypeService,
    private _privileges: TmPrivilegesService,
    private _notify: IwNotificationsService,
    private _asyncValidation: TmAsyncValidatorsService,
    private _modalService: IwModalService,
    private _fb: FormBuilder
  ) {
    super();
  }

  public ngOnInit(): void {
    this._createScopeFormGroup();

    this.data$
      .pipe(
        switchMap((data) => forkJoin(of(data), this._canEditScope$)),
        takeUntil(this._destroyed$)
      )
      .subscribe(([data, isEditable]) => {
        if (!data) {
          this.close();
          return;
        }

        this.patchDataToForm(data, isEditable);
        if (data.CREATE_DATE) {
          this.createDate = data.CREATE_DATE;
        }
        if (data.CHANGE_DATE) {
          this.changeDate = data.CHANGE_DATE;
        }
      });

    merge(this.close$, this.submit$)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this._closeSidebar());

    this._watchIsNewToChangeTitle();

    this._sidebar.open('right');
  }

  public getPlaceholder(defaultPlaceholder?: string) {
    return this.scopeId$.pipe(
      map((scopeId) => {
        if (scopeId === FULL_ACCESS_SCOPE_ID) {
          return this._t.instant('settings-access.all');
        } else {
          return defaultPlaceholder;
        }
      })
    );
  }

  public checkValue(element: HTMLInputElement, formControlName: string) {
    const formControl = this.form.get(formControlName);

    if (!element.checked && formControl) {
      formControl.reset();
    }
  }

  /**
   * Convert server model data to form model data
   */
  public patchDataToForm(data: TmApi.scope.CollectionItem, isEditable: boolean) {
    const getValue: (condition: string) => TmApi.scope.ConditionValue[] = this.service.getValue.bind(
      this.service,
      data.VISIBILITY_AREA_CONDITION!.data.children
    );

    const getValueWithCondition: (
      condition: string
    ) => TmApi.scope.ConditionValue[] = this.service.getValueWithCondition.bind(
      this.service,
      data.VISIBILITY_AREA_CONDITION!.data.children,
      '0'
    );

    const getExceptValue: (condition: string) => TmApi.scope.ConditionValue[] = this.service.getValueWithCondition.bind(
      this.service,
      data.VISIBILITY_AREA_CONDITION!.data.children,
      '1'
    );

    const policyValues = getValue('policies');
    const isPolicyAnyFlag = this._isAnyValues(policyValues);

    const documentValues = getValue('documents');
    const isDocumentAnyFlag = this._isAnyValues(documentValues);

    this.form.enable({ emitEvent: false });

    this.form.patchValue({
      VISIBILITY_AREA_ID: data.VISIBILITY_AREA_ID,
      DISPLAY_NAME: data.DISPLAY_NAME,
      NOTE: data.NOTE,
      violation_level: getValue('violation_level'),
      verdict: getValue('verdict'),
      tags: this._selectService.mapToIwSelect(getValueWithCondition('tags')),
      withouttags: getExceptValue('tags') && getExceptValue('tags').length,
      excepttags: this._selectService.mapToIwSelect(getExceptValue('tags')),
      workstations: this._selectService.mapToIwSelect(getValueWithCondition('workstations')),
      withoutworkstations: getExceptValue('workstations') && getExceptValue('workstations').length,
      exceptworkstations: this._selectService.mapToIwSelect(getExceptValue('workstations')),
      recipients: this._selectService.mapToIwSelect(getValueWithCondition('recipients')),
      withoutrecipients: getExceptValue('recipients') && getExceptValue('recipients').length,
      exceptrecipients: this._selectService.mapToIwSelect(getExceptValue('recipients')),
      anyPolicies: isPolicyAnyFlag,
      policies: this._selectService.mapToIwSelect(policyValues),
      anyDocuments: isDocumentAnyFlag,
      documents: this._selectService.mapToIwSelect(documentValues),
    });

    if (data.IS_SYSTEM || !isEditable) {
      this.form.disable({ emitEvent: false });
    }
  }

  /**
   * HACK: provides types compability without redesigning search-select component
   */
  public getFormControl(path: string): FormControl {
    return this.get(path) as FormControl;
  }

  /**
   * Save form data
   */
  protected _onSubmit() {
    const serverData = this.formModelToServerModel(this.form.getRawValue());
    return this.service.createOrUpdate(serverData).pipe(
      catchError((response: HttpErrorResponse) => {
        this._notify.error(
          this._t.instant('settings-access.scope.errors.error'),
          this._t.instant('settings-access.scope.errors.requestFail')
        );
        return throwError(response);
      }),
      tap(() => this.close(true)),
      takeUntil(this._destroyed$)
    );
  }

  private _watchIsNewToChangeTitle() {
    merge(this._isNew$, this._t.onLangChange.pipe(switchMapTo(this._isNew$)))
      .pipe(takeUntil(this._destroyed$))
      .subscribe((isNew) => {
        let title;

        if (isNew) {
          title = this._t.instant('settings-access.scope.form.titleCreateNew');
        } else {
          title = this._t.instant('settings-access.scope.form.titleEdit');
        }

        this._sidebar.updateTitle(title);
      });
  }

  /**
   * Convert form model data to server model data
   */
  private formModelToServerModel(formModel: TmApi.scope.CollectionItem): TmApi.scope.CollectionItem {
    return {
      DISPLAY_NAME: formModel.DISPLAY_NAME,
      NOTE: formModel.NOTE,
      VISIBILITY_AREA_CONDITION: this._collectListsData(formModel),
      VISIBILITY_AREA_ID: formModel.VISIBILITY_AREA_ID,
    };
  }

  private _collectListsData(formModel: TmPluginAccess.scope.edit.Model) {
    const getData = (item: any) => (item.data ? item.data : item);

    const root = this.service.createRootNode();

    const optionalCategories: TmPluginAccess.scope.edit.VisibilityAreaConditionCategories[] = [
      'violation_level',
      'verdict',
      'documents',
      'policies',
      'tags',
      'workstations',
      'recipients',
      'excepttags',
      'exceptworkstations',
      'exceptrecipients',
    ];
    optionalCategories.forEach((category) => {
      if (formModel[category]) {
        if (!Array.isArray(formModel[category]) || !formModel[category]!.length) {
          return;
        }
        // @ts-ignore
        if (category.startsWith('except') && !formModel[category.replace('except', 'without')]) {
          return '';
        }
        // senders === recipients
        // Тут мы обрабатываем поле person и поле исключения person и сохраняем их c одинаковым значением категории
        if (category === 'recipients' || category === 'exceptrecipients') {
          // @ts-ignore
          const value = formModel[category].map(getData);
          const sendersAndRecipients: TmApi.scope.Condition = {
            link_operator: this.getLinkOperationByCategoryName(category),
            children: [
              {
                category: 'recipients',
                is_negative: this.getConditionByCategoryName(category),
                value: value,
              },
              {
                category: 'senders',
                is_negative: this.getConditionByCategoryName(category),
                value: value,
              },
            ],
          };
          root.children.push(sendersAndRecipients);
        } else {
          // Тут мы обрабатываем поля workstation и tags и поле исключения workstation и tags
          // и сохраняем их c одинаковым значением категории
          root.children.push({
            category: category.replace('except', ''),
            is_negative: this.getConditionByCategoryName(category),
            // @ts-ignore
            value: formModel[category].map(getData),
          });
        }
      }
    });

    return { data: root };
  }

  private getConditionByCategoryName(name: string): string {
    return name.includes('except') ? '1' : '0';
  }

  private getLinkOperationByCategoryName(name: string): LinkOperator {
    return name.includes('except') ? 'and' : 'or';
  }

  /**
   * Close sidebar
   */
  private _closeSidebar() {
    this._router.navigate(['./'], {
      relativeTo: this._activatedRoute.parent,
      queryParamsHandling: 'merge',
    });

    this._sidebar.close('right');
  }

  private _createScopeFormGroup() {
    this.form = this._fb.group(
      {
        VISIBILITY_AREA_ID: null,
        DISPLAY_NAME: [
          null,
          [
            Validators.required,
            Validators.maxLength(256),
            Validators.pattern(PATTERN_STRING_256_1),
            whitespacesValidator,
          ],
          this._asyncValidation.getAsyncObjectValidation('visibilityArea', this.scopeId$),
        ],
        NOTE: [null, [Validators.maxLength(1000)]],
        violation_level: null,
        verdict: null,
        tags: null,
        excepttags: null,
        workstations: null,
        exceptworkstations: null,
        recipients: null,
        exceptrecipients: null,
        anyPolicies: null,
        policies: null,
        anyDocuments: null,
        documents: null,
        withouttags: null,
        withoutworkstations: null,
        withoutrecipients: null,
      },
      {
        validator: requiredAnyValidator([
          'violation_level',
          'verdict',
          'tags',
          'workstations',
          'recipients',
          'policies',
          'documents',
          'anyDocuments',
          'anyPolicies',
          'withouttags',
          'withoutworkstations',
          'withoutrecipients',
        ]),
      }
    );

    this.form
      .get('anyDocuments')!
      .valueChanges.pipe(takeUntil(this._destroyed$))
      .subscribe((value) => {
        this._setAnyEntry('documents', value);
      });
    this.form
      .get('anyPolicies')!
      .valueChanges.pipe(takeUntil(this._destroyed$))
      .subscribe((value) => {
        this._setAnyEntry('policies', value);
      });
  }

  private _isAnyValues(values: TmApi.scope.ConditionValue[] | undefined): boolean {
    return (
      !!values &&
      values.every((value: TmApi.scope.ConditionValue) => {
        if (typeof value === 'string') {
          return false;
        } else {
          return value.DATA === 'any';
        }
      })
    );
  }

  /**
   * Set any entry for policy and document
   */
  private _setAnyEntry(category: string, isAnyChecked: boolean): void {
    if (!isAnyChecked) {
      this.form.controls[category].enable();
      this.form.controls[category].setValue([]);

      return;
    }

    let label;
    let type = '';
    let value = '';

    switch (category) {
      case 'policies':
        type = 'policy';
        label = this._t.instant('settings-access.scope.form.any_policies');
        value = 'any_policy';

        break;
      case 'documents':
        type = 'document';
        label = this._t.instant('settings-access.scope.form.any_documents');
        value = 'any_document';

        break;
    }

    const item: IwSelectItem<TmApi.scope.ConditionValue> = {
      label: label,
      value: value,
      data: {
        TYPE: type,
        DATA: 'any',
        NAME: label,
      },
    };

    this.form.controls[category].disable();
    this.form.controls[category].setValue([item]);
  }
}
