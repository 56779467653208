export enum TmApiAffiliateType {
  Self,
  Parent,
  Child,
  Other,
}

export enum TmApiAffiliateToDo {
  Nothing,
  Add,
  Modify,
  Delete,
}

export enum TmApiAffiliateConfigMode {
  Auto,
  Manual,
}

export enum TmApiAffiliateConfigStatus {
  Queued = 'QUEUED',
  Approved = 'APPROVED',
  Downloading = 'DOWNLOADING',
  Applying = 'APPLYING',
  Error = 'ERROR',
  Skipped = 'SKIPPED',
  Ready = 'READY',
}

export enum TmApiAffiliateIsOnline {
  NotAvailable,
  Available,
  Connecting,
}

export enum TmApiConfigStatus {
  Free,
  Locked,
  Saved,
}

export enum TmApiConfigIsManual {
  true,
  false,
}

export const SCOPES: TmApi.search.Scopes[] = [
  'document',
  'catalog',
  'person',
  'status',
  'group',
  'workstation',
  'tag',
  'policy',
];

export enum TmApiUserStatus {
  Active,
  Disable,
  Deleted,
}
