export class TmFormElementService {
  public serializeForTmbbSyphon(values?: any): string {
    if (!values) {
      return '';
    }

    return JSON.stringify(values);
  }

  public deserializeFromTmbbSyphon(values?: string): any {
    if (!values) {
      return [];
    }

    return JSON.parse(values);
  }
}
