import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TmStatefulService } from '@tm-shared/dataloader';
import { TmSessionService } from '@tm-shared/session';
import { EMPTY, Observable, combineLatest, merge } from 'rxjs';
import { distinctUntilKeyChanged, filter, map, shareReplay, skip, tap } from 'rxjs/operators';
import { ConfigDataGet, LastOperation, Status } from '../generated/config';
import { TmChannelService } from '../../../@tm-shared/channel';
import { WsMessage } from '../../../typings/generated/websocket';

/**
 * Configuration service without affiliate support
 */
@Injectable()
export class TmConfigLocalService extends TmStatefulService<TmApi.GetResponse<ConfigDataGet[]>> {
  public readonly src = '/api/config';

  public dataWithUpdates$ = merge(this.get().pipe(map((response) => response.data[0])), this._getChannelStream()).pipe(
    shareReplay(1)
  );

  /**
   * При сбросе конфигурации рефрешить данные попадающие под конфигурацию на страницах данного пользователя.
   * При коммите рефрешить данные у других пользователей
   *
   */
  public refreshOnConfigChanges = combineLatest([
    this.dataWithUpdates$.pipe(distinctUntilKeyChanged('LAST_OPERATION')),
    this._session.session$,
  ]).pipe(
    skip(1),
    filter(([data, session]) => {
      return (
        (data.LAST_OPERATION === LastOperation.ROLLBACK && data.USER_ID === session.USER_ID) ||
        (data.LAST_OPERATION === LastOperation.COMMIT && data.USER_ID !== session.USER_ID)
      );
    }),
    tap(() => {
      const url = this._router.url;
      this._router.navigateByUrl('').then(() => this._router.navigateByUrl(url));
    })
  );

  /**
   * Если конфигурация заблокирована редактированием, то блокировать интерфейс у других пользователей
   */
  public configurationAllowsEdit$ = combineLatest([this._session.session$, this.dataWithUpdates$]).pipe(
    map(([session, config]) => {
      if (config.STATUS === Status.FREE) {
        return true;
      }
      return config.USER_ID === session.USER_ID;
    })
  );

  constructor(
    http: HttpClient,
    private _wsService: TmChannelService,
    private _session: TmSessionService,
    private _router: Router
  ) {
    super(http);
  }

  private _getChannelStream(): Observable<ConfigDataGet> {
    if (!this._wsService) {
      return EMPTY;
    }

    return this._wsService.getChannel('service_config').pipe(
      map((response: WsMessage & { data: ConfigDataGet }) => {
        return response.data;
      })
    );
  }
}
