<iw-modal-header *ngIf="categoryIsNew">{{ 'tech-graphic.editCategory.createTitle' | translate }}</iw-modal-header>
<iw-modal-header *ngIf="!categoryIsNew">{{ 'tech-graphic.editCategory.title' | translate }}</iw-modal-header>
<form [formGroup]="form" (submit)="submit()">
  <iw-form-row>
    <label iwFormRowLabel>{{ 'tech-graphic.editCategory.categoryName' | translate }}</label>
    <iw-input-container iwFormRowContent class="graphic-input-container">
      <input data-qa="graphicCategoryName" iwInput formControlName="DISPLAY_NAME" tmAutofocus />
      <!--
        @translate tech-graphic.editCategory.errors.maxlength
        @translate tech-graphic.editCategory.errors.not_match
        @translate tech-graphic.editCategory.errors.pattern
        @translate tech-graphic.editCategory.errors.required
        @translate tech-graphic.editCategory.errors.unique
      -->

      <div iwErrorHints *ngFor="let error of getErrors('DISPLAY_NAME')">
        {{ 'tech-graphic.editCategory.errors.' + error.key | translate: error.value }}
      </div>
    </iw-input-container>
  </iw-form-row>
  <iw-form-row>
    <label iwFormRowLabel>{{ 'tech-graphic.editCategory.categoryDescription' | translate }}</label>
    <iw-input-container iwFormRowContent>
      <input data-qa="graphicCategoryDescription" iwInput formControlName="NOTE" />
      <!--
        @translate tech-graphic.editCategory.errors.maxlength
        @translate tech-graphic.editCategory.errors.not_match
        @translate tech-graphic.editCategory.errors.pattern
      -->
      <div iwErrorHints *ngFor="let error of getErrors('NOTE')">
        {{ 'tech-graphic.editCategory.errors.' + error.key | translate: error.value }}
      </div>
    </iw-input-container>
  </iw-form-row>
  <iw-form-toolbar>
    <button [disabled]="saveInProgress" iw-button color="success" data-qa="graphicCategoryEditSubmit" type="submit">
      {{ 'tech-graphic.editCategory.submit' | translate }}
    </button>
    <button iw-button data-qa="graphicCategoryEditClose" (click)="close()">
      {{ 'tech-graphic.editCategory.close' | translate }}
    </button>
  </iw-form-toolbar>
</form>
