import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TmElement } from '@tm-shared/custom-elements';
import { TmPluginValidationErrorService } from './plugin-validation-error.service';

@TmElement('tme-plugin-validation-error')
@Component({
  templateUrl: './plugin-validation-error.component.html',
  styleUrls: ['./plugin-validation-error.component.scss'],
  selector: 'tm-plugin-validation-error',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmPluginValidationErrorComponent {
  @Input() public set errorResponse(response: TmPlugin.validationError.Response) {
    this.parsedResponse = this._service.parse(response);
    this._cd.markForCheck();
  }

  @Output() public entityClick: EventEmitter<{
    entityId: string;
    entityType: TmPlugin.validationError.ValidationErrorEntity;
  }> = new EventEmitter();

  public parsedResponse: TmPlugin.validationError.ParsedError;

  constructor(private _service: TmPluginValidationErrorService, private _cd: ChangeDetectorRef) {}

  public clickOnEntity(entityType: TmPlugin.validationError.ValidationErrorEntity, entityId: string): void {
    this.entityClick.emit({
      entityId: entityId,
      entityType: entityType,
    });
  }
}
