import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  styleUrls: ['./boolean-cell.component.scss'],
  templateUrl: 'boolean-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BooleanCellComponent implements AgRendererComponent {
  public state: boolean;
  public classNames: any = {};

  public agInit(data: any): void {
    this._updateStatus(Boolean(data.value));
  }

  public refresh(data: any): boolean {
    this._updateStatus(Boolean(data.value));
    return true;
  }

  private _updateStatus(state: boolean) {
    this.state = state;
    this.classNames.active = state;
  }
}
