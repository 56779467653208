import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlStreams } from '@tm-shared/url-streams';
import { BehaviorSubject, Observable, combineLatest, throwError, timer } from 'rxjs';
import { filter, map, retryWhen, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { LoadingTracker } from './loading-tracker';

const DEFAULT_RETRY_LIMIT = 10;
const DEFAULT_RETRY_TIMEOUT = 1000;

type HttpOptions = Parameters<HttpClient['get']>[1];

/**
 * Stateful класс для редко запрашиваемых сущностей.
 * TODO: Generic Deserialized type is not always suitable. Think of making better interface
 */
@Injectable()
export abstract class TmStatefulService<Deserialized> {
  public abstract src: UrlStreams<unknown> | string;

  protected loadingService = new LoadingTracker();

  protected dataSnapshot = new BehaviorSubject<Deserialized | null>(null);

  /**
   * Latest deserialized data
   */
  protected data$: Observable<Deserialized> = combineLatest([this.dataSnapshot, this.loadingService.loading]).pipe(
    filter<[Deserialized, boolean]>(([data, loading]) => !!data && !loading),
    map(([response]) => response)
  );

  constructor(protected http: HttpClient) {}

  /**
   * Refresh stored data once
   */
  public refresh(): Observable<Deserialized> {
    return this.get();
  }

  /**
   * Get data once
   */
  public get(options?: HttpOptions): Observable<Deserialized> {
    const req$ = this.http.get(this.src.toString(), options).pipe(
      map((response) => this.deserialize(response)),
      tap((response) => this.saveLatestData(response))
    );
    return this.loadingService.trackRequest(req$);
  }

  public getWithRetries(
    retryDelayMs = DEFAULT_RETRY_TIMEOUT,
    retryLimit = DEFAULT_RETRY_LIMIT
  ): Observable<Deserialized> {
    return this.get().pipe(
      retryWhen((errors$) => {
        return errors$.pipe(
          switchMap((err) => {
            return retryDelayMs > -1 && --retryLimit > 0 ? timer(retryDelayMs) : throwError(err);
          })
        );
      })
    );
  }

  /**
   * Get data if exists, make request if not exists & no requests pending
   */
  public getDataStream(
    retryDelayMs = DEFAULT_RETRY_TIMEOUT,
    retryLimit = DEFAULT_RETRY_LIMIT
  ): Observable<Deserialized> {
    if (!this.dataSnapshot.getValue() && !this.loadingService.isLoadingNow()) {
      return this.getWithRetries(retryDelayMs, retryLimit).pipe(switchMapTo(this.data$));
    }

    return this.data$;
  }

  /**
   * It should deserialize response to data
   */
  protected deserialize(response: unknown): Deserialized {
    return response as Deserialized;
  }

  /**
   * It replaces old data with new one
   */
  protected saveLatestData(response: Deserialized): void {
    this.dataSnapshot.next(response);
  }
}
