import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmChannelService } from '@tm-shared/channel';
import { TmTechLoaderComponentService } from '@tm-shared/tech-loader/tech-loader-component.service';
import { ClassifierType } from 'plugins/tech-classifier/tech-classifier-providers';
import { Observable, Subject, merge, of } from 'rxjs';
import { catchError, filter, map, shareReplay } from 'rxjs/operators';
import {
  CheckDocumentErrorCode,
  CheckDocumentResponse,
  CheckDocumentResponseItem,
  CheckDocumentState,
  WsCheckDocumentResponse,
} from 'typings/generated/technology-autoling';
import { TmTechClassifierFileCheck } from '../tech-classifier/tech-classifier-file-check/tech-classifier-file-check.service';

@Injectable()
export class TmAutolingDocumentCheckService extends TmTechClassifierFileCheck<CheckDocumentResponse> {
  public type: ClassifierType = ClassifierType.autoling;

  public src = '/api/autoling/checkDocument';

  public wsUpdates$ = this.channelService.getUserChannel<WsCheckDocumentResponse>('autoling').pipe(
    // TODO: this hack should not be required after openapi schema merge https://gerrit.infowatch.ru/#/c/242557/
    filter(
      (msg: WsCheckDocumentResponse & { data?: WsCheckDocumentResponse['data'] }) =>
        msg.data && msg.data.state === 'checkDocument'
    ),
    shareReplay(1)
  );

  public wsData$ = this.wsUpdates$.pipe(map((msg) => msg.meta.models || []));

  public dataWithUpdates$ = merge(
    this.getDataStream(-1).pipe(
      map((response) => response.data),
      catchError(() => of([] as CheckDocumentResponse['data']))
    ),
    this.wsData$
  ).pipe(shareReplay(1));

  /**
   * Common state for all documents
   */
  public state$: Observable<CheckDocumentState>;

  protected immediateState$ = new Subject<CheckDocumentState>();

  constructor(
    protected http: HttpClient,
    protected _t: TranslateService,
    protected techLoader: TmTechLoaderComponentService,
    private channelService: TmChannelService
  ) {
    super(http);

    this.state$ = merge(
      this.dataWithUpdates$.pipe(map((models) => this._getStateForMultipleFiles(models))),
      this.immediateState$
    ).pipe(shareReplay(1));
  }

  public getErrorText(errorKey: CheckDocumentErrorCode): string {
    switch (errorKey) {
      case CheckDocumentErrorCode.extractionFailed:
        return this._t.instant(`tech-autoling.checkDocument.errors.extractionFailed`);
      case CheckDocumentErrorCode.failedSaveFile:
        return this._t.instant(`tech-autoling.checkDocument.errors.failedSaveFile`);
      case CheckDocumentErrorCode.internalError:
        return this._t.instant(`tech-autoling.checkDocument.errors.internalError`);
      case CheckDocumentErrorCode.noAnalyseData:
        return this._t.instant(`tech-autoling.checkDocument.errors.noAnalyseData`);
      case CheckDocumentErrorCode.unsupportedFileType:
        return this._t.instant(`tech-autoling.checkDocument.errors.unsupportedFileType`);
      default:
        return '';
    }
  }

  protected setCheckStateToCancel(): void {
    this.immediateState$.next(CheckDocumentState.cancel);
  }

  protected setCheckStateToProgress(): void {
    this.immediateState$.next(CheckDocumentState.progress);
  }

  private _getStateForMultipleFiles(items?: CheckDocumentResponseItem[]): CheckDocumentState {
    let result: CheckDocumentState = CheckDocumentState.success;

    /**
     * Less index => higher priority
     */
    const priority: CheckDocumentState[] = [
      CheckDocumentState.cancel,
      CheckDocumentState.error,
      CheckDocumentState.progress,
      CheckDocumentState.success,
    ];

    if (!items || !items.length) {
      return CheckDocumentState.pristine;
    }

    for (let i = 0; i < items.length; i++) {
      if (priority.indexOf(items[i].STATE) < priority.indexOf(result)) {
        result = items[i].STATE;
      }
    }

    /**
     * NOTE: Backend always return state for 1 file, and
     * Success, Error and Cancel are possible only if all files are succeed.
     */
    if (items.length < this.latestCheckFilesQueryLength) {
      result = CheckDocumentState.progress;
    }

    return result;
  }
}
