import { NgModule } from '@angular/core';
import { TmAuditEventListComponent } from './audit-event-list.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TmAuditItemModule } from '../audit-item/audit-item.module';
import { HttpClientModule } from '@angular/common/http';
import { TmOverlayModule } from '@tm-shared/overlay';
import { TmSpinnerModule } from '@tm-shared/spinner';
import { TmScrollModule } from '@tm-shared/scroll/scroll.module';
import { TmSharedModule } from '@tm-shared';

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    TranslateModule,
    TmAuditItemModule,
    TmOverlayModule,
    TmSpinnerModule,
    TmScrollModule,
    TmSharedModule,
  ],
  declarations: [TmAuditEventListComponent],
  exports: [TmAuditEventListComponent],
})
export class TmAuditEventListModule {}
