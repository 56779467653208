import { Injectable } from '@angular/core';
import { IwNotificationsService } from '@platform/shared';
import { Optional } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface TmItemHealthErrorState {
  localizedError: [string, string];
}

/**
 * TmHealthService is a singleton which is used to manage common system errors to prevent duplicates
 *
 * @example
 * // Enable built-in notify feature which check and prevent duplicates
 * health.notifyError(true);
 *
 * function checkService(serviceKey, url) {
 *   http.get(url).subscribe(() => {
 *    health.removeError(serviceKey);
 *   }, (err) => {
 *     health.setError(serviceKey, 'localized error title', 'localized error message');
 *   });
 * }
 *
 * checkService('redis', '/api/user/check');
 */
@Injectable()
export class TmHealthService {
  public errors: { [key: string]: TmItemHealthErrorState | null } = {};

  private _changed$: Subject<string> = new Subject();

  private _stopReport$: Subject<void> = new Subject();

  constructor(@Optional() private _notify: IwNotificationsService) {}

  public hasError(key: string): boolean {
    return !!this.errors[key];
  }

  public notifyOnError(turnOn: boolean): void {
    this._stopReport$.next();

    if (this._notify && turnOn) {
      this._changed$.pipe(takeUntil(this._stopReport$)).subscribe((key) => {
        const state = this._getState(key);
        if (state) {
          this._notify.error(state.localizedError[0], state.localizedError[1], {
            timeOut: 0,
            preventDuplicates: true,
          });
        }
      });
    }
  }

  public removeError(key: string): void {
    this._setState(key, null);
  }

  public setError(key: string, title: string, text: string): void {
    this._setState(key, { localizedError: [title, text] });
  }

  private _getState(key: string): TmItemHealthErrorState | null {
    if (!this.errors[key]) {
      this.errors[key] = null;
    }

    return this.errors[key];
  }

  private _setState(key: string, state: TmItemHealthErrorState | null): void {
    // Do not process if already has error
    if (!!this.errors[key] === !!state) {
      return;
    }

    this.errors[key] = state;
    this._changed$.next(key);
  }
}
