<div class="toggle" [ngClass]="{ isCollapsed: isCollapsed, disabled: disabled }" (click)="toggle(!isCollapsed)"></div>

<div class="grabber" [ngClass]="{ isHidden: isCollapsed }" #grabber></div>

<div class="wrapper">
  <div class="animationWrapper" #sidebarWrap>
    <div class="content" [@contentToggle]="getToggleState()">
      <!-- HEADER -->
      <div class="header" *ngIf="title">
        <h2 data-qa="sidebarTitle">{{ title }}</h2>
      </div>

      <!-- CONTENT -->
      <div class="indent" #indent>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
