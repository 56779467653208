<div data-qa="dropzone" tmDragndrop class="dropzone" (click)="fileInput.click()" (onFileDrop)="addFiles($event)">
  <button data-qa="addFileBtn" iw-button color="success" *ngIf="!files.length" iw-button type="button">
    {{ '@tm-shared.dropzone.addFileButton' | translate }}
  </button>
  <div data-qa="fileItem" class="fileItem" *ngFor="let file of files; let i = index">
    <button
      iw-icon-button
      class="fileRemove"
      (click)="removeFileByIndex(i, $event)"
      type="button"
      data-qa="deleteFileBtn"
    >
      <tm-icon svgIcon="delete"></tm-icon>
    </button>
    <div class="fileName">{{ file.name }}</div>
    <div data-qa="downloadLink" class="downloadLink" *ngIf="downloadLink">
      <a [href]="downloadLink" target="_blank" (click)="$event.stopPropagation()">
        {{ downloadText || ('@tm-shared.dropzone.downloadFile' | translate) }}
      </a>
    </div>
  </div>
  <input
    data-qa="fileInput"
    hidden
    type="file"
    #fileInput
    [attr.multiple]="multiple"
    [accept]="accept"
    (change)="fileInputChanged($event)"
  />
</div>
