import { TranslateService } from '@ngx-translate/core';
import { FingerprintDiffProperties } from 'typings/generated/audit-protected-document';
import { TmAuditBrItemsCellComponent } from '../audit-cell-components/audit-br-items.component';
import { AuditExtendGridData, AuditGridDataColumn } from '../audit-extend.service';
import { AuditEvent, AuditEventOperation } from '../audit.model';
import {
  VisibleAttributesOption,
  getAuditExtendRowData,
  getAuditAttributesFrom,
  TransformOptions,
  getDefaultColumns,
  transformTech2Category,
} from './audit-common';

const auditTableVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'DISPLAY_NAME', i18nKey: 'audit.entries.table.displayName' },
  { path: 'FILE_PATH', i18nKey: 'audit.entries.table.filePath' },
  { path: 'MIME', i18nKey: 'audit.entries.table.mime' },
  { path: 'NOTE', i18nKey: 'audit.entries.table.note' },
  {
    path: 'conditions',
    i18nKey: 'audit.entries.table.conditions',
    transform: transformConditions,
    component: TmAuditBrItemsCellComponent,
  },
  { path: 'table2category', i18nKey: 'audit.entries.table.categories', transform: transformTech2Category },
];

export function getEtTableGridData(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  const cols = data.OPERATION === AuditEventOperation.compile ? [AuditGridDataColumn.new] : getDefaultColumns(data);
  const result: AuditExtendGridData[] = [];
  const baseData = getAuditExtendRowData({
    t,
    cols: cols,
    data: getAuditAttributesFrom(data),
    visibleAttributes: auditTableVisibleAttributes,
  });

  if (baseData.length) {
    result.push({
      cols,
      data: baseData,
    });
  }

  return result;
}

export function transformConditions(
  conditions: FingerprintDiffProperties['conditions'] | null | undefined,
  o: TransformOptions
): string[] {
  if (!conditions) {
    return [];
  }

  return conditions.map((c) => {
    const result: string[] = [];

    if (c.DISPLAY_NAME) {
      result.push(`${o.t.instant('audit.entries.table.conditionName')}: ${c.DISPLAY_NAME}`);
    }

    if (c.VALUE) {
      result.push(`${o.t.instant('audit.entries.table.value')}: ${c.VALUE}`);
    }

    if (c.MIN_ROWS) {
      result.push(`${o.t.instant('audit.entries.table.minRows')}: ${c.MIN_ROWS}`);
    }

    return result.join(', ');
  });
}
