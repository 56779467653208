import { Injectable } from '@angular/core';
import { UrlStreams } from '@tm-shared/url-streams';
import { Observable } from 'rxjs';
import { skip } from 'rxjs/operators';

interface Filters {
  [TmAuditEventFilterService.dateStartKey]: string;
  [TmAuditEventFilterService.dateEndKey]: string;
  [TmAuditEventFilterService.userIdKey]: string;
  [TmAuditEventFilterService.actionKey]: string;
  [TmAuditEventFilterService.entityTypeKey]: string;
  [TmAuditEventFilterService.filterKey]: string;
}

/**
 * Audit filter allows to synchronize filter settings between different components on audit page
 */
@Injectable()
export class TmAuditEventFilterService {
  public static readonly dateStartKey = 'filter[CHANGE_DATE_START]';
  public static readonly dateEndKey = 'filter[CHANGE_DATE_END]';
  public static readonly userIdKey = 'filter[USER_ID]';
  public static readonly actionKey = 'filter[OPERATION]';
  public static readonly filterKey = 'filter[query]';
  public static readonly entityTypeKey = 'filter[ENTITY_TYPE]';

  public changes: Observable<AnifyProps<Filters>>;

  private readonly src = new UrlStreams<Filters>();

  constructor() {
    /**
     * Skip initial state, return updates only
     */
    this.changes = this.src.params$.pipe(skip(1));
  }

  public getFilter(): Record<string, string> {
    return this.src.getParams();
  }

  /**
   * fulltext search
   */
  public setQuery(query: string | null): void {
    if (!query) {
      this.src.deleteParam(TmAuditEventFilterService.filterKey);
    } else {
      this.src.setParam(TmAuditEventFilterService.filterKey, query);
    }
  }

  public setUser(userId: string | null): void {
    if (!userId) {
      this.userFilterRemove();
    } else {
      this.userFilterSet(userId);
    }
  }

  public setAction(action: string | null): void {
    if (!action) {
      this.actionFilterRemove();
    } else {
      this.actionFilterSet(action);
    }
  }

  public setEntity(entityType: string | null): void {
    if (!entityType) {
      this.entityFilterRemove();
    } else {
      this.entityFilterSet(entityType);
    }
  }

  public setPeriod(period: [Date, Date] | null): void {
    if (!period) {
      this.periodFilterRemove();
    } else {
      this.periodFilterSet(period[0], period[1]);
    }
  }

  protected entityFilterSet(entityType: string): void {
    this.src.setParam(TmAuditEventFilterService.entityTypeKey, entityType);
  }

  protected entityFilterRemove(): void {
    this.src.deleteParam(TmAuditEventFilterService.entityTypeKey);
  }

  protected actionFilterSet(action: string): void {
    this.src.setParam(TmAuditEventFilterService.actionKey, action);
  }

  protected actionFilterRemove(): void {
    this.src.deleteParam(TmAuditEventFilterService.actionKey);
  }

  protected userFilterSet(user: string): void {
    this.src.setParam(TmAuditEventFilterService.userIdKey, user);
  }

  protected userFilterRemove(): void {
    this.src.deleteParam(TmAuditEventFilterService.userIdKey);
  }

  protected periodFilterSet(startDate: Date, endDate: Date): void {
    this.src.setParams({
      [TmAuditEventFilterService.dateStartKey]: Math.round(+startDate / 1000),
      [TmAuditEventFilterService.dateEndKey]: Math.round(+endDate / 1000),
    });
  }

  protected periodFilterRemove(): void {
    this.src.deleteParam(TmAuditEventFilterService.dateStartKey);
    this.src.deleteParam(TmAuditEventFilterService.dateEndKey);
  }
}
