<ng-container *ngIf="serverOptions$ | async as serverItems">
  <header *ngIf="serverItems.length">{{ 'settings-access.ldapUserSelect.title' | translate }}</header>
  <iw-form [formGroup]="form" *ngIf="serverItems.length; else noLdapServers" (ngSubmit)="submit()" class="form">
    <iw-form-block>
      <!-- SERVER -->
      <iw-form-row>
        <label iwFormRowLabel>{{ 'settings-access.ldapUserSelect.server_for_search' | translate }}</label>
        <iw-select iwFormRowContent [items]="serverItems" formControlName="server" class="select-control"></iw-select>
      </iw-form-row>

      <iw-form-row *ngIf="form.get('server')?.value">
        <label iwFormRowLabel>{{ 'settings-access.ldapUserSelect.search_field' | translate }}</label>
        <iw-input-container iwFormRowContent>
          <input
            iwInput
            formControlName="search"
            type="text"
            class="select-control"
            placeholder="{{ 'settings-access.search_placeholder' | translate }}"
            data-qa="userSearchField"
          />
        </iw-input-container>
      </iw-form-row>

      <tm-grid
        #grid
        class="grid"
        *ngIf="form.get('server')?.value"
        [displayPagination]="false"
        [service]="service.ldapUserService"
        [storeKey]="null"
        [gridOptions]="service.gridOptions"
        [tableConfig]="service.defaultTableParams"
      ></tm-grid>
    </iw-form-block>

    <iw-form-toolbar>
      <button iw-button type="submit" [disabled]="!form.valid">
        {{ 'settings-access.user.form.save' | translate }}
      </button>
      <button iw-button type="button" (click)="close()">{{ 'settings-access.user.form.cancel' | translate }}</button>
    </iw-form-toolbar>
  </iw-form>

  <ng-template #noLdapServers>
    <h2 class="centered">
      <a routerLink="/settings/ldap">{{ 'settings-access.ldapUserSelect.no_server' | translate }}</a>
    </h2>
  </ng-template>
</ng-container>
