import { Injectable } from '@angular/core';
import { TmStatefulService } from '@tm-shared/dataloader';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CategoryGet, CategoryListResponse } from 'typings/generated/technology-autoling';

@Injectable()
export class TmAutolingCategoryService extends TmStatefulService<CategoryListResponse> {
  public src = '/api/autoling/category';

  public idAttribute = 'CATEGORY_ID';

  public getSorted(dir: 'asc' | 'desc' = 'asc') {
    return this.get({
      params: {
        'sort[DISPLAY_NAME]': dir,
      },
    });
  }

  public getById(id: string): Observable<CategoryGet> {
    return this.getDataStream().pipe(map((data) => data.data.find((item) => item.CATEGORY_ID === id)!));
  }

  public create(body: CategoryGet): Observable<any> {
    return this.http.post(this.src.toString(), body).pipe(switchMap(() => this.refresh()));
  }

  public remove(id: string): Observable<any> {
    return this.http.delete(`${this.src}/${id}`).pipe(switchMap(() => this.refresh()));
  }

  public updateById(id: string, body: CategoryGet): Observable<any> {
    return this.http.put(`${this.src}/${id}`, body).pipe(switchMap(() => this.refresh()));
  }
}
