import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { TreeNode } from '@circlon/angular-tree-component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ASYNC_ROOT, TmTreeComponent } from '.';
import { TmTreeNodeData } from './tm-tree.model';

/**
 * Service to maintain logic for tree with lazy loaded nodes
 * Maintains logic for opening selected nodes, loading all path before them
 * (persons page/ users and roles -> scopes-> field 'users' with modal)
 * @WARNING getChildren method for tree component must be implemented explicitly
 */
@Injectable()
export class LazyTreeService implements OnDestroy {
  public apiService: TmCollectionLoader<any>;

  private _destroy$ = new Subject();

  constructor(private _t: TranslateService) {}

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * fake node for lazy tree
   */
  public getFakeRootNode() {
    return [
      {
        name: this._t.instant('@tm-shared.tree.rootNode'),
        id: ASYNC_ROOT,
        hasChildren: true,
        isExpanded: true,
      },
    ] as TmTreeNodeData[];
  }

  /**
   * Load paths to nodes by ids, expand and select.
   * @param tree instance
   */
  public loadPathsToNodesAndSelect(ids: (string | number)[], tree: TmTreeComponent): void {
    this._destroy$.next();
    const selectedIds = [...ids];
    this._listenLoadToSelect(selectedIds, tree);

    if (this.apiService) {
      this.apiService.getItemsByIds(selectedIds).subscribe((data) => {
        const pathsToGroups = new Set<string>();

        data.forEach((group) => {
          (group.ID_PATH || group.ID_NAME_PATH.ID_PATH)
            .split('\\')
            .forEach((pathItem: string) => pathsToGroups.add(pathItem));
        });
        this._listenLoadToExpand(
          [...pathsToGroups].filter((item) => item),
          tree
        );
      });
    } else {
      this._expandNodes(ids, tree);
    }
  }

  private _listenLoadToExpand(nodesToExpandIds: string[], tree: TmTreeComponent) {
    tree.loadNodeChildren.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._expandNodes(nodesToExpandIds, tree);
    });
  }

  private _expandNodes(nodesToExpandIds: (string | number)[], tree: TmTreeComponent) {
    for (let i = nodesToExpandIds.length - 1; i >= 0; i--) {
      const node = tree.treeModel.getNodeById(nodesToExpandIds[i]) as TreeNode;
      if (node) {
        node.expand();
        nodesToExpandIds.splice(i, 1);
      }
    }
  }

  private _listenLoadToSelect(nodesToExpandIds: (string | number)[], tree: TmTreeComponent) {
    this._selectNodes(nodesToExpandIds, tree);
    tree.loadNodeChildren.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._selectNodes(nodesToExpandIds, tree);
    });
  }

  private _selectNodes(selectedIds: (string | number)[], tree: TmTreeComponent) {
    for (let i = selectedIds.length - 1; i >= 0; i--) {
      const node = tree.treeModel.getNodeById(selectedIds[i]);
      if (node) {
        tree.setNodeSelected(node, true);
        selectedIds.splice(i, 1);
      }
    }
  }
}
