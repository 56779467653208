import { Route } from '@angular/router';
import { TmAutolingCategoryComponent } from './autoling-category/autoling-category.component';
import { TmAutolingPageComponent } from './autoling-page.component';

export const routes: Route[] = [
  {
    path: 'analysis/autoling',
    redirectTo: 'analysis/autoling/',
    pathMatch: 'full',
  },
  {
    path: 'analysis/autoling/:id',
    component: TmAutolingPageComponent,
    data: {
      usePrivilege: 'analysis/autoling',
    },
    children: [
      {
        path: '',
        component: TmAutolingCategoryComponent,
        data: {
          usePrivilege: 'analysis/autoling',
        },
      },
    ],
  },
];
