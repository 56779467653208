<p *ngIf="parsedResponse" class="errorDescription">
  <!--
        @translate plugin.validationError.schema_not_found
        @translate plugin.validationError.not_valid_attr_name
        @translate plugin.validationError.not_valid_json
        @translate plugin.validationError.json_schema
        @translate plugin.validationError.header_disabled
        @translate plugin.validationError.header_delete
        @translate plugin.validationError.header_uses
        @translate plugin.validationError.header_uses_plugin
        @translate plugin.validationError.not_valid_prefix_name
        @translate plugin.validationError.service_not_found
        @translate plugin.validationError.object_header_not_valid
     -->
  {{ 'plugin.validationError.' + parsedResponse.errorKey | translate: { attribute: parsedResponse.headerName } }}
</p>

<ng-container *ngFor="let group of parsedResponse?.entities">
  <!--
        @translate plugin.validationError.policy
        @translate plugin.validationError.notification
        @translate plugin.validationError.query
        @translate plugin.validationError.plugin
     -->
  <h3 class="entitiesType">{{ 'plugin.validationError.' + group.entitiesType | translate }}</h3>
  <ul class="entities">
    <li class="entity" *ngFor="let entity of group.entities">
      <a (click)="clickOnEntity(group.entitiesType, entity.entityId)">
        {{ entity.entityName }}
      </a>
    </li>
  </ul>
</ng-container>
