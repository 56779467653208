import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewContainerRef,
} from '@angular/core';

import { TmContextMenuComponent } from './context-menu.component';
import { TmContextMenuItem, TmContextMenuPosition } from './context-menu.model';

@Directive({
  selector: '[tmContextMenuSub]',
})
export class TmContextSubMenuDirective {
  public menuComponent: ComponentRef<TmContextMenuComponent>;

  @Input() public tmContextMenuSub: TmContextMenuItem[];

  @Output() public closeSubMenu = new EventEmitter();

  constructor(
    private viewRef: ViewContainerRef,
    private elementRef: ElementRef,
    private resolver: ComponentFactoryResolver
  ) {}

  @HostListener('mouseover', [])
  public onMouseOver() {
    this._closeCurrent();
    this.menuComponent = this._createContextComponent();

    this.menuComponent.instance.items = this.tmContextMenuSub;

    this.menuComponent.instance.onClose.subscribe(() => {
      this.closeSubMenu.emit();
    });

    this._setPosition();

    return false;
  }

  @HostListener('closemenu', [])
  public onCloseMenu() {
    if (this.menuComponent) {
      this._closeCurrent();
    }
  }

  /**
   * Create sub context menu
   *
   * @private
   * @returns {ComponentRef<TmContextMenuComponent>}
   * @memberof TmContextSubMenuDirective
   */
  private _createContextComponent(): ComponentRef<TmContextMenuComponent> {
    const contextMenuFactory = this.resolver.resolveComponentFactory(TmContextMenuComponent);
    return this.viewRef.createComponent(contextMenuFactory);
  }

  /**
   * Set current position
   *
   * @private
   * @memberof TmContextSubMenuDirective
   */
  private _setPosition(): void {
    const { top, left, width } = this.elementRef.nativeElement.getBoundingClientRect();

    const position: TmContextMenuPosition = {
      top: top,
      left: left + width,
    };

    this.menuComponent.instance.position = position;
  }

  /**
   * Close current sub menu
   *
   * @private
   * @memberof TmContextSubMenuDirective
   */
  private _closeCurrent(): void {
    this.viewRef.clear();
  }
}
