<div class="header">
  <tm-toolbar class="toolbar" data-qa="toolbar">
    <button
      iw-icon-button
      [disableClickEventOnly]="!(canCreateResources$ | async)"
      [iwPopover]="tooltipPopover"
      [context]="{ message: createMessage$ | async }"
      data-qa="createToolbarButton"
      (click)="showDialogCreate()"
    >
      <tm-icon svgIcon="create"></tm-icon>
    </button>

    <button
      iw-icon-button
      [disableClickEventOnly]="!(canEditResources$ | async)"
      [iwPopover]="tooltipPopover"
      [context]="{ message: editMessage$ | async }"
      data-qa="editToolbarButton"
      (click)="showDialogEdit()"
    >
      <tm-icon svgIcon="edit"></tm-icon>
    </button>

    <button
      iw-icon-button
      [disableClickEventOnly]="!(canRemoveResources$ | async)"
      [iwPopover]="tooltipPopover"
      [context]="{ message: removeMessage$ | async }"
      data-qa="deleteToolbarButton"
      (click)="showDialogDelete()"
    >
      <tm-icon svgIcon="delete"></tm-icon>
    </button>
  </tm-toolbar>

  <!-- @translate lists-resources.table.toolbar.create -->
  <!-- @translate lists-resources.table.toolbar.uncreatable -->
  <!-- @translate lists-resources.table.toolbar.edit -->
  <!-- @translate lists-resources.table.toolbar.uneditable -->
  <!-- @translate lists-resources.table.toolbar.delete.one -->
  <!-- @translate lists-resources.table.toolbar.delete.many -->
  <!-- @translate lists-resources.table.toolbar.undeletable -->
  <ng-template #tooltipPopover let-message="message">{{ message | translate }}</ng-template>

  <input
    type="text"
    [tmGridFilter]="grid"
    propertyToFilter="VALUE"
    [startsWithAny]="true"
    class="tm-input search-input"
    placeholder="{{ 'lists-resources.search' | translate }}"
    data-qa="resources-search"
  />
</div>

<tm-grid
  data-qa="resources-table-grid"
  [initialRequest]="false"
  [service]="tableApi"
  [gridOptions]="tableConfig$ | async"
  [paginationItems]="[100, 10, 50, 1000]"
>
</tm-grid>
