import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { TmElement } from '@tm-shared/custom-elements';

@TmElement('tme-description')
@Component({
  selector: 'tm-description',
  template: '<ng-content></ng-content>',
  styleUrls: ['./description.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TmDescriptionComponent {
  @HostBinding('class.tm-description-component') public publishStyle = true;
}
