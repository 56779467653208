import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[tmAutofocus]',
})
export class AutofocusDirective implements AfterViewInit, OnDestroy {
  @Input() public tmAutofocus: Observable<unknown> | string;

  private _destroy$ = new Subject();

  constructor(private _el: ElementRef) {}

  public ngAfterViewInit(): void {
    this._el.nativeElement.focus();
    if (this.tmAutofocus instanceof Observable) {
      this.tmAutofocus.pipe(takeUntil(this._destroy$)).subscribe(() => {
        this._el.nativeElement.focus();
      });
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
