<tm-dropzone #fileInput [multiple]="true" class="hidden" (change)="checkFiles($event)"></tm-dropzone>

<ng-container *ngIf="!(showResults$ | async); else filesReport">
  <tm-spinner class="spinner" size="100px"></tm-spinner>
  <p class="progressMsg">{{ 'tech-autoling.checkDocument.checkInProgress' | translate }}</p>
  <iw-form-toolbar class="modalFooter">
    <button [disabled]="lockButtons" iw-button type="button" (click)="close()">
      {{ 'tech-autoling.checkDocument.closePopup' | translate }}
    </button>
    <button [disabled]="lockButtons" iw-button type="button" (click)="stopFileCheckProcess()">
      {{ 'tech-autoling.checkDocument.stopFileCheckProcess' | translate }}
    </button>
  </iw-form-toolbar>
</ng-container>

<ng-template #filesReport>
  <iw-modal-header>{{ 'tech-autoling.checkDocument.checkCompleted' | translate }}</iw-modal-header>
  <div class="filesReportWrapper">
    <table class="filesReport">
      <thead>
        <th>{{ 'tech-autoling.checkDocument.fileName' | translate }}</th>
        <th class="categoryLabel">{{ 'tech-autoling.checkDocument.fileCategory' | translate }}</th>
        <th>{{ 'tech-autoling.checkDocument.quality' | translate }}</th>
      </thead>
      <tbody>
        <tr *ngFor="let item of gridData$ | async" class="row">
          <td class="filename">{{ item.filename }}</td>
          <td [ngClass]="{ result: true, error: item.error }">
            {{ item.error || item.category || ('tech-autoling.checkDocument.unknownCategory' | translate) }}
          </td>
          <td class="filename">{{ item.quality }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>

  <iw-form-toolbar class="modalFooter">
    <button [disabled]="lockButtons" iw-button type="button" (click)="fileInput.showFileSelector()">
      {{ 'tech-autoling.checkDocument.selectFiles' | translate }}
    </button>
  </iw-form-toolbar>
</ng-template>
