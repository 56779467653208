<tm-content title="{{ 'settings-access.user.title' | translate }}">
  <!-- Toolbar, show only when grid is ready -->
  <tm-toolbar class="toolbar" *ngIf="grid.initialized$ | async" data-qa="toolbar">
    <!-- Add user options -->
    <button
      iw-icon-button
      [iwPopover]="addUserPopover"
      [disabled]="!!(canNotCreate$ | async)"
      #createPopover="iwPopover"
      data-qa="createToolbarButton"
      placement="bottom-start"
    >
      <tm-icon svgIcon="create"></tm-icon>
    </button>

    <!-- Remove user -->
    <button
      iw-icon-button
      [disabled]="!(canRemoveUser$ | async)"
      data-qa="deleteToolbarButton"
      tmDeleteConfirm
      [tmDeleteConfirmDialog]="modal"
      [tmDeleteConfirmDialogData]="{
        title: 'settings-access.confirmAction' | translate,
        users: grid?.allSelectedIds$ | async
      }"
      (tmDeleteConfirm)="deleteSelected()"
    >
      <tm-icon svgIcon="delete"></tm-icon>
    </button>

    <button
      iw-icon-button
      #actionsPopover="iwPopover"
      [disabled]="!!(canNotAction$ | async)"
      [iwPopover]="otherActionsPopover"
      placement="bottom-start"
    >
      <tm-icon svgIcon="list"></tm-icon>
    </button>
  </tm-toolbar>

  <!-- Grid -->
  <tm-grid #grid class="grid" [service]="userApi" [gridOptions]="gridOptions"></tm-grid>
</tm-content>

<tm-sidebar
  [disabled]="!router.url.includes(CREATE_PATH) && !router.url.includes(EDIT_PATH)"
  class="sidebar"
  direction="right"
  [isCollapsed]="true"
>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</tm-sidebar>

<!-- Toolbar popovers -->
<ng-template #addUserPopover>
  <div class="dropdown">
    <button [disabled]="!(canCreateUser$ | async)" (click)="showDialogCreateUser()">
      {{ 'settings-access.user.createUser' | translate }}
    </button>
    <button [disabled]="!(canCreateLdap$ | async)" (click)="showDialogCreateUserLdap()">
      {{ 'settings-access.user.createUserLdap' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #otherActionsPopover>
  <div class="dropdown">
    <button [disabled]="!(canChangePassword$ | async)" (click)="showDialogChangePassword()">
      {{ 'settings-access.user.changePassword' | translate }}
    </button>
    <button [disabled]="!(canSetStatus$ | async)" (click)="setUserStatus(statuses.Active)">
      {{ 'settings-access.user.activateUser' | translate }}
    </button>
    <button [disabled]="!(canSetStatus$ | async)" (click)="setUserStatus(statuses.Disable)">
      {{ 'settings-access.user.deactivateUser' | translate }}
    </button>
  </div>
</ng-template>
