import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DateTime } from 'luxon';

/**
 * Wrap validation errors in single named property
 */
export function wrap(validator: ValidatorFn | ValidatorFn[], name: string): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors | null {
    const errors = {};
    const validators = Array.isArray(validator) ? validator : [validator];

    for (let validatorIndex = 0; validatorIndex < validators.length; validatorIndex++) {
      Object.assign(errors, validators[validatorIndex](control));
    }

    return Object.keys(errors).length ? { [name]: errors } : null;
  };
}

/**
 * Create validator wich allow rules to fail while they are below threshold
 */
function setThreshold(rules: any[], shouldPass: number): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};

    let successCounter = 0;
    for (let ruleIndex = 0; ruleIndex < rules.length; ruleIndex++) {
      const _errors = rules[ruleIndex](control);

      if (_errors) {
        Object.assign(errors, _errors);
      } else {
        successCounter++;
      }
    }

    if (successCounter < shouldPass) {
      return errors;
    }

    return null;
  };
}

/**
 * Invalidate input if trimmed value === ''
 */
export function whitespacesValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value && control.value.trim() === '') {
    return { empty: true };
  }
  return null;
}

/**
 * At least one control from list is required (FormGroup.get('validated control'))
 * @param controlNamesToToValidate list of controls: ['name', 'control.subcontrol']
 */
export const requiredAnyValidator = (controlNamesToToValidate: string[]) => {
  return (formGroup: AbstractControl) => {
    const allEmpty = controlNamesToToValidate.some((controlName) => {
      if (!formGroup.get(controlName)) {
        throw new Error(`requiredAnyValidator got unexistent control: ${controlName}`);
      } else {
        const value = formGroup.get(controlName)!.value;
        if (Array.isArray(value)) {
          return value.length;
        } else {
          return value;
        }
      }
    });
    if (!allEmpty) {
      return { anyElemIsRequired: true };
    }
    return null;
  };
};

/**
 * Validate like Validators.pattern and return named error
 * @param errorName name of returned error
 */
export const namedPatternValidator = (pattern: string | RegExp, errorName: string) => {
  return (control: AbstractControl) => {
    const error: { [key: string]: boolean } = {};
    if (typeof pattern === 'string') {
      pattern = new RegExp(pattern);
    }
    if (!pattern.test(control.value)) {
      error[errorName] = true;
      return error;
    }
    return null;
  };
};

/**
 * password must match with confirmation
 * @param controlNameToValidate control path.Name('xxx.18.myControl') to set error
 * @param controlNameToCompareWith control path.Name('yyy.19.myControl2') to compare with
 */
export const fieldsEqualValidator = (controlNameToValidate: string, controlNameToCompareWith: string) => {
  return (formGroup: AbstractControl) => {
    if (formGroup.get(controlNameToCompareWith) && formGroup.get(controlNameToValidate)) {
      if (formGroup.get(controlNameToCompareWith)!.value !== formGroup.get(controlNameToValidate)!.value) {
        formGroup.get(controlNameToValidate)!.setErrors({ repeat_exactly: true });
      }
    } else {
      throw new Error(`${controlNameToCompareWith} or ${controlNameToValidate} control does not exist in formGroup`);
    }
    return null;
  };
};

export function getOneOfCharactersValidator(chars: string): ValidatorFn {
  const errCode = 'oneOfChars';

  return function (control: AbstractControl): ValidationErrors | null {
    return control.value &&
      chars.split('').some(function (char) {
        return control.value.indexOf(char) > -1;
      })
      ? null
      : {
          [errCode]: {
            chars: chars,
          },
        };
  };
}

/**
 *
 * Microsoft password policy: https://docs.microsoft.com/en-us/previous-versions/sql/sql-server-2005/ms161959(v=sql.90)
 */
export function microsoftStandardPasswordValidator(control: AbstractControl): ValidationErrors | null {
  const errors = {};
  const rules = [
    Validators.minLength(8),
    Validators.maxLength(128),
    wrap(
      setThreshold(
        [
          namedPatternValidator('^.*\\d.*$', 'noNumber'),
          namedPatternValidator('^.*[A-Z].*$', 'noLatinUppercase'),
          namedPatternValidator('^.*[a-z].*$', 'noLatinLowercase'),
          getOneOfCharactersValidator('!$#%'),
        ],
        3
      ),
      'msPaswordOneOfFour'
    ),
  ];

  for (let ruleIndex = 0; ruleIndex < rules.length; ruleIndex++) {
    const rule = rules[ruleIndex];
    Object.assign(errors, rule(control));
  }

  if (Object.keys(errors).length) {
    return errors;
  }

  return null;
}

/**
 * Strict date (or date time) input validator
 */
export function dateTimeISOInputValidator(
  allowNull: boolean = true
): (control: AbstractControl) => ValidationErrors | null {
  return function (control: AbstractControl): ValidationErrors | null {
    if (control.value === null && allowNull) {
      return null;
    }

    if (typeof control.value === 'string' && DateTime.fromISO(control.value).isValid) {
      return null;
    }

    return [
      {
        dateTimeFormat: true,
      },
    ];
  };
}

export function dateFromToIsoValidator(
  fromPath: string,
  toPath: string
): (control: AbstractControl) => ValidationErrors | null {
  return (form: AbstractControl): ValidationErrors | null => {
    const from = form.get(fromPath);
    const to = form.get(toPath);

    if (from && to && +new Date(from.value) <= +new Date(to.value)) {
      return null;
    }

    return [
      {
        fromToError: true,
      },
    ];
  };
}
