import { NgModule } from '@angular/core';
import { TmAuditItemExtendedModule } from './audit-item-extend/audit-item-extend.module';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import * as en from './i18n/audit.en.json';
import * as ru from './i18n/audit.ru.json';
import { TmAuditServiceModule } from './audit-service.module';

@NgModule({
  imports: [TranslateModule, TmAuditItemExtendedModule, TmAuditServiceModule],
})
export class TmAuditElementsModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('ru', ru, true);
    this.translate.setTranslation('en', en, true);
  }
}
