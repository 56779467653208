import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { Subject } from 'rxjs';
import { ImageGet } from 'typings/generated/technology-imageClassifier';

@Injectable()
export class TmGraphicDocumentService extends TmCollectionLoader<ImageGet> {
  public src = '/api/image_classifier/image';

  public idAttribute = 'FINGERPRINT_ID';

  public dataChanged$ = new Subject<void>();
}
