import { Component } from '@angular/core';
import { TmSearchService } from '../../../../@tm-shared/api-services';
import { TranslateService } from '@ngx-translate/core';
import { TableTabComponentDirective } from './abstract-table-tab.component';
import { TmGridOptions } from '../../../../@tm-shared/grid';
import { CheckboxCellComponent, ColorCellComponent } from '../../../../@tm-shared/grid/cell-renderers';

@Component({
  templateUrl: './table.html',
  styleUrls: [],
})
export class StatusTabComponent extends TableTabComponentDirective {
  public static scopeToSearch: 'status' = 'status';

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: StatusTabComponent.scopeToSearch,
  };

  constructor(public service: TmSearchService, private _t: TranslateService) {
    super();
  }

  public options: TmGridOptions = {
    columnDefs: [
      {
        width: 30,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        width: 30,
        field: 'COLOR',
        headerName: '',
        cellRendererFramework: ColorCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        sort: 'asc',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('settings-access.search.headerNames.ldapStatus.displayName'),
      },
      {
        field: 'NOTE',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('settings-access.search.headerNames.ldapStatus.note'),
      },
    ],
  };
}
