/**
 * Cronjob is an utility to help set/get/validate cron format.
 * It support only the following patterns: weekly, daily, hours, minutes.
 *
 * TODO: add strict input validation
 *
 * @example
 *
 *   let ldapServerData = http.get(...);
 *   let cronPreset = cronjob.getPreset(ldapServerData.job);
 *
 *   form.set('time', cronPreset.getLocalTime());
 *   form.on('time has changed', newValue => {
 *     cronPreset.setLocalTime(newValue);
 *     http.put({ job: cronPreset.toJson() });
 *   });
 */

import { formatTimeForTimeInput } from '@tm-shared/helpers/date';
import { wrapNum } from '@tm-shared/helpers/math';

export enum TmCronJobPresetType {
  minutes = 'minutes',
  hours = 'hours',
  daily = 'daily',
  weekdays = 'weekdays',
}

export interface TmCronJobServerModel {
  day_of_month: string;
  day_of_week: string;
  hour: string;
  minute: string;
  month: string;
}

export interface TmCronJobExtendedModel extends TmCronJobServerModel {
  minute_interval: string;
  hour_interval: string;
}

abstract class TmCronJobPreset implements TmCronJobServerModel {
  public static anyDayOfMonth = '1-31';
  public static anyDayOfWeek = '1-7';
  public static anyHour = '0-23';
  public static anyMinute = '0-59';
  public static anyMonth = '1-12';
  public static weekdayStart = 1;
  public static weekdayEnd = 7;

  public abstract type: TmCronJobPresetType;

  public readonly hoursLocalOffset = new Date().getTimezoneOffset() / -60;

  public day_of_month: string = TmCronJobPreset.anyDayOfMonth;
  public day_of_week: string = TmCronJobPreset.anyDayOfWeek;
  public hour: string = TmCronJobPreset.anyHour;
  public minute: string = TmCronJobPreset.anyMinute;
  public month: string = TmCronJobPreset.anyMonth;

  /**
   * Return data as a server model JSON
   */
  public toJson(): TmCronJobExtendedModel {
    return {
      day_of_month: this.day_of_month,
      day_of_week: this.day_of_week,
      hour: this.hour,
      minute: this.minute,
      month: this.month,
      minute_interval: this._extractInterval(this.minute),
      hour_interval: this._extractInterval(this.hour),
    };
  }

  /**
   * Stringify with the format: "second minute hour day_of_month month day_of_week"
   */
  public toString(): string {
    return `${this.minute} ${this.hour} ${this.day_of_month} * ${this.day_of_week}`;
  }

  /**
   * Get localized time in HH:mm format
   */
  protected _getLocalTime(): string {
    // Add offset to convert hours to UTC
    const hours = wrapNum((this.hour ? parseInt(this.hour, 10) : 0) + this.hoursLocalOffset, [0, 23]);
    const minutes = this.minute ? parseInt(this.minute, 10) : 0;

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    return formatTimeForTimeInput(hours, minutes);
  }

  /**
   * Accept local time and store as UTC hours and minutes
   */
  protected _setLocalTime(hour: string, minute?: string): this {
    if (hour.includes(':')) {
      minute = hour.split(':')[1];
      hour = hour.split(':')[0];
    }

    // Subtract offset to convert hours to UTC
    const hours = wrapNum((hour ? parseInt(hour, 10) : 0) - this.hoursLocalOffset, [0, 23]);
    const minutes = minute ? parseInt(minute, 10) : 0;

    this.minute = minutes.toString();
    this.hour = hours.toString();
    return this;
  }

  /**
   * Accept local time and store as UTC hours and minutes
   */
  protected _setTime(hour: string, minute?: string): this {
    if (hour.includes(':')) {
      minute = hour.split(':')[1];
      hour = hour.split(':')[0];
    }

    const hours = wrapNum(hour ? parseInt(hour, 10) : 0, [0, 23]);
    const minutes = minute ? parseInt(minute, 10) : 0;

    this.minute = minutes.toString();
    this.hour = hours.toString();
    return this;
  }

  protected _extractInterval(value?: string | number): string {
    if (typeof value === 'number') {
      return value.toString();
    }

    if (!value) {
      return '0';
    }

    if (value.indexOf('/') > -1) {
      value = value.split('/')[1];
    }

    return value;
  }
}

export class TmCronJobMinutesPreset extends TmCronJobPreset {
  public type: TmCronJobPresetType.minutes = TmCronJobPresetType.minutes;
  public minute = '*/15';

  public setMinuteInterval(value?: string | number): this {
    this.minute = `*/${this._extractInterval(value)}`;
    return this;
  }

  public getMinuteInterval(): string {
    return this.minute.split('/')[1];
  }
}

export class TmCronJobHoursPreset extends TmCronJobPreset {
  public type: TmCronJobPresetType.hours = TmCronJobPresetType.hours;
  public minute = '0';
  public hour = '*/1';

  public setHourInterval(value?: string | number): this {
    this.hour = `*/${this._extractInterval(value)}`;
    return this;
  }

  public getHourInterval(): string {
    return this.hour.split('/')[1];
  }
}

// https://wiki.infowatch.ru/pages/viewpage.action?pageId=147562403 1.5
const utcDefaultHours = 0;

export class TmCronJobDailyPreset extends TmCronJobPreset {
  public type: TmCronJobPresetType.daily = TmCronJobPresetType.daily;
  public hour = `${utcDefaultHours + new Date().getTimezoneOffset() / 60}`;
  public minute = '0';

  public setLocalTime(hour: string = '0', minute?: string): this {
    return super._setLocalTime(hour, minute);
  }

  public setTime(hour: string = '0', minute?: string): this {
    return super._setTime(hour, minute);
  }

  public getLocalTime(): string {
    return super._getLocalTime();
  }
}

export class TmCronJobWeekdaysPreset extends TmCronJobPreset {
  public type: TmCronJobPresetType.weekdays = TmCronJobPresetType.weekdays;
  public day_of_week = normalize(TmCronJobPreset.anyDayOfWeek);
  public hour = `${utcDefaultHours + new Date().getTimezoneOffset() / 60}`;
  public minute = '0';

  public setLocalTime(hour: string = '0', minute?: string): this {
    return super._setLocalTime(hour, minute);
  }

  public setTime(hour: string = '0', minute?: string): this {
    return super._setTime(hour, minute);
  }

  public getLocalTime(): string {
    return super._getLocalTime();
  }

  // Return weekdays as array of numbers (mon - sun, 1 - 7)
  public getWeekdaysLocal(): number[] {
    // Calculate day offset
    const hour = this.hour ? parseInt(this.hour, 10) + this.hoursLocalOffset : 0;
    const dayOffset = hour < 0 ? -1 : hour >= 24 ? 1 : 0;

    return this.day_of_week
      .split(',')
      .map((day) => wrapNum(parseInt(day, 10) + dayOffset, [TmCronJobPreset.weekdayStart, TmCronJobPreset.weekdayEnd]))
      .sort((x, y) => x - y);
  }

  public setWeekdays(weekdays?: any): this {
    if (!weekdays) {
      return this;
    }

    return this._setWeekdays(weekdays, false);
  }

  public setLocalWeekdays(weekdays?: any): this {
    if (!weekdays) {
      return this;
    }

    return this._setWeekdays(weekdays, true);
  }

  protected _setWeekdays(weekdays: any, local: boolean): this {
    let utcDayOffset = 0;

    if (local) {
      const hour = this.hour ? parseInt(this.hour, 10) + this.hoursLocalOffset : 0;
      utcDayOffset = hour < 0 ? -1 : hour >= 24 ? 1 : 0;
    }

    // Normalize to Mon-Sun, 1-7 and apply day offset
    this.day_of_week = normalize(weekdays, TmCronJobPreset.anyDayOfWeek)
      .split(',')
      .map((day) => wrapNum(+day - utcDayOffset, [TmCronJobPreset.weekdayStart, TmCronJobPreset.weekdayEnd]))
      .sort((x, y) => x - y)
      .join(',');

    return this;
  }
}

export type TmCronJobPresets =
  | TmCronJobMinutesPreset
  | TmCronJobHoursPreset
  | TmCronJobDailyPreset
  | TmCronJobWeekdaysPreset;

/**
 * Get preset from Cron string, datetime considered to be UTC
 */
export function getPreset(
  job: Partial<TmCronJobServerModel> | null,
  DefaultPreset = TmCronJobMinutesPreset
): TmCronJobPresets {
  if (!job) {
    return new DefaultPreset();
  }

  switch (getPresetType(job)) {
    case TmCronJobPresetType.daily:
      return new TmCronJobDailyPreset().setTime(job.hour, job.minute);
    case TmCronJobPresetType.hours:
      return new TmCronJobHoursPreset().setHourInterval(job.hour);
    case TmCronJobPresetType.minutes:
      return new TmCronJobMinutesPreset().setMinuteInterval(job.minute);
    case TmCronJobPresetType.weekdays:
      return new TmCronJobWeekdaysPreset().setTime(job.hour, job.minute).setWeekdays(job.day_of_week);
    default:
      return new DefaultPreset();
  }
}

/**
 * Normalize value:
 *
 * @example
 * normalize('*', '1-7') // result: 1-7
 * normalize('', '1-12') // result: 1-12
 * normalize('1-2,4,12-16', '1-31') // result: 1,2,4,12,13,14,15,16
 */
export function normalize(value: any, starValue?: string): string {
  if (!value || value === '*') {
    return starValue ? normalize(starValue) : '';
  }

  if (typeof value === 'number') {
    return value.toString();
  }

  if (Array.isArray(value)) {
    return value.join(',');
  }

  return value
    .split(',')
    .reduce((result: string[], item: string) => {
      if (item.indexOf('-') > -1) {
        const [a, b] = item.split('-').map((x) => parseInt(x, 10));
        for (let i = a; i <= b; i++) {
          result.push(i.toString());
        }
      } else {
        result.push(item);
      }

      return result;
    }, [] as string[])
    .join(',');
}

export function getPresetType(job: Partial<TmCronJobServerModel> | null): TmCronJobPresetType | null {
  if (!job) {
    return null;
  }

  // hours pattern: every N minutes
  if (job.minute && job.minute.includes('/')) {
    return TmCronJobPresetType.minutes;
  }

  // hours pattern: every N hours
  if (job.hour && job.hour.includes('/')) {
    return TmCronJobPresetType.hours;
  }

  // Weekdays pattern: on days, on time
  // If we have selective day_of_week it's the week pattern
  if (job.day_of_week && job.day_of_week !== '*' && job.day_of_week !== TmCronJobPreset.anyDayOfWeek) {
    return TmCronJobPresetType.weekdays;
  }

  // daily pattern: everyday at time
  if (job.hour && job.minute) {
    return TmCronJobPresetType.daily;
  }

  return null;
}

export function getPresetByType(type: TmCronJobPresetType = TmCronJobPresetType.minutes): TmCronJobPresets {
  switch (type) {
    case TmCronJobPresetType.minutes:
      return new TmCronJobMinutesPreset();
    case TmCronJobPresetType.hours:
      return new TmCronJobHoursPreset();
    case TmCronJobPresetType.daily:
      return new TmCronJobDailyPreset();
    case TmCronJobPresetType.weekdays:
      return new TmCronJobWeekdaysPreset();
  }
}
