import { Injectable } from '@angular/core';
import { getIdStringByScopeString } from '@tm-shared/api-services/functions';

@Injectable()
export class ScopeConditionService {
  public mapScopesToCondition(scopes: TmApi.search.CollectionItem): TmApi.scope.ConditionValueObject[] {
    return Object.keys(scopes).reduce((accum: TmApi.scope.ConditionValueObject[], scope: TmApi.search.Scopes) => {
      const mappedValues = (scopes[scope] as any[]).map((item) => this.getValueByScope(item, scope));
      if (mappedValues.length) {
        accum.push(...mappedValues);
      }
      return accum;
    }, []);
  }

  private getValueByScope(item: any, scope: TmApi.search.Scopes): TmApi.scope.ConditionValueObject {
    const id = getIdStringByScopeString(scope);
    return {
      TYPE: scope,
      DATA: item[id],
      IS_DELETED: item.IS_DELETED,
      NAME: item.DISPLAY_NAME,
    };
  }
}
