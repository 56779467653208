import { Component, Input } from '@angular/core';
import { AuditEvent, AuditEventOperation, AuditEventEntityType } from '../audit.model';
import { TmAuditService } from '../audit.service';

interface AuditViewData {
  operationLocalized: string;
  entityTypeLocalized: string;
  entityDescription: string;
}

@Component({
  selector: 'tm-audit-item',
  styleUrls: ['audit-item.component.scss'],
  templateUrl: 'audit-item.component.html',
})
export class TmAuditItemComponent {
  @Input() public set data(data: AuditEvent) {
    this.initialData = data;

    this.viewData = Object.assign({}, data, {
      operationLocalized: this.service.getLocalizedOperation(data.OPERATION as AuditEventOperation),
      entityTypeLocalized: this.service.getLocalizedEntityType(data.ENTITY_TYPE as AuditEventEntityType),
      entityDescription: this.service.getChangeSummary(data),
    });

    this.canShowDetails = this.service.hasDetailedData(data);
  }

  constructor(private service: TmAuditService) {}

  public initialData: AuditEvent;

  public viewData: AuditEvent & AuditViewData;

  public canShowDetails = false;
}
