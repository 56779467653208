import { Component } from '@angular/core';
import { TmElement } from '@tm-shared/custom-elements';

@TmElement('tme-d-value')
@Component({
  selector: 'tm-d-value',
  template: '<ng-content></ng-content>',
  styleUrls: ['./description-val.component.scss'],
})
export class TmDescriptionKeyComponent {}
