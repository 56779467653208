import { Injectable } from '@angular/core';
import { TmStatefulService } from '@tm-shared/dataloader/stateful.service';
import { UrlStreams } from '@tm-shared/url-streams';

/**
 * API's versions service
 */
@Injectable()
export class TmVersionService extends TmStatefulService<TmApi.GetResponse<TmShared.version.Model>> {
  public src = UrlStreams.create('/api/checkVersion');
}
