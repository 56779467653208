import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IwPopoverOptions, IwModalService } from '@platform/shared';
import { TmSidebarService } from '@tm-shared/structure/sidebar';
import { TmTreeDropEvent, TmTreeNodeData, TreeItemLinkParams } from '@tm-shared/tree';
import { TmConfigLocalService } from 'plugins/config/services';
import {
  CategoryService,
  CheckService,
  ClassifierType,
  ClassifierTypeToken,
  FingerprintService,
  TrainingService,
} from 'plugins/tech-classifier/tech-classifier-providers';
import { Observable, Subject, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CategoryGet, ImageGet, TrainingState } from 'typings/generated/technology-imageClassifier';
import { TmChannelService } from '../../@tm-shared/channel';
import {
  TmGraphicCategoryEditComponent,
  TmGraphicCategoryEditData,
} from './graphic-category-edit/graphic-category-edit.component';
import { TmGraphicCategoryService } from './graphic-category.service';
import { TmGraphicCategoryComponent } from './graphic-category/graphic-category.component';
import { TmGraphicDocumentCheckService } from './graphic-document-check.service';
import { TmGraphicDocumentCheckComponent } from './graphic-document-check/graphic-document-check.component';
import { TmGraphicDocumentService } from './graphic-document.service';
import { TmGraphicTrainingService } from './graphic-training.service';

type RouterOutletComponents = TmGraphicCategoryComponent | null;

@Component({
  templateUrl: './graphic-page.component.html',
  styleUrls: ['./graphic-page.component.scss'],
  providers: [
    TmGraphicCategoryService,
    TmSidebarService,
    TmGraphicDocumentService,
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
        closeOnMousemoveOutside: true,
        triggers: 'mouseenter:mouseleave',
      }),
    },
    {
      provide: CategoryService,
      useExisting: TmGraphicCategoryService,
    },
    {
      provide: FingerprintService,
      useExisting: TmGraphicDocumentService,
    },
    {
      provide: ClassifierTypeToken,
      useValue: ClassifierType.graphicImageClassifier,
    },
    {
      provide: TrainingService,
      useExisting: TmGraphicTrainingService,
    },
    {
      provide: CheckService,
      useExisting: TmGraphicDocumentCheckService,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmGraphicPageComponent implements OnDestroy {
  public graphicMenu$: Observable<TmTreeNodeData[]>;
  public selectedId$: Observable<string | null>;
  public checkFilesComponent = TmGraphicDocumentCheckComponent;

  public treeItemConfig: TreeItemLinkParams = {
    routerLinkGetter: (nodeId: string): string[] => {
      return ['/analysis/graphic', nodeId];
    },
  };

  public totalQuality?: number;

  public wsImportSuccess$: Observable<any> = this._channelService.getUserChannel('analysis').pipe(
    filter((data) => data.meta.method === 'import' && data.state === 'success'),
    shareReplay(1)
  );

  protected trainingSucceed$ = this._trainingService.state$.pipe(map((state) => state === TrainingState.success));

  private _destroy$: Subject<void> = new Subject();
  private _selectedCategory$: Observable<CategoryGet | null>;
  private _updateCategoryList$: Subject<void>;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    @Inject(CategoryService) private _categoryService: TmGraphicCategoryService,
    @Inject(FingerprintService) private _graphicDocumentService: TmGraphicDocumentService,
    @Inject(TrainingService) private _trainingService: TmGraphicTrainingService,
    @Inject(ClassifierTypeToken) protected type: ClassifierType,

    private _configurationService: TmConfigLocalService,
    private _channelService: TmChannelService,
    private _modalService: IwModalService
  ) {
    this._updateCategoryList$ = new Subject();
    this.selectedId$ = this._route.params.pipe(map(this._getIdParam));

    this._configurationService.refreshOnConfigChanges.pipe(takeUntil(this._destroy$)).subscribe();

    this.wsImportSuccess$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._updateCategoryList$.next();
    });

    this._selectedCategory$ = this.selectedId$.pipe(
      switchMap((id: string) => (id ? this._categoryService.getById(id).pipe(catchError(() => of(null))) : of(null)))
    );

    this.graphicMenu$ = this._updateCategoryList$.pipe(
      startWith(null),
      switchMap(() => this.trainingSucceed$),
      switchMap((trained) => this._categoryService.getSorted().pipe(map((response) => ({ response, trained })))),
      map(({ response, trained }) => {
        this._updateTotalQuality(response.data, trained);
        return response.data.map((categoryItem) => this._graphicCategoryItemToGraphicMenuNode(categoryItem, trained));
      })
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onRouterOutletActivate(component: RouterOutletComponents) {
    this._setupRouterOutletComponent(component);
  }

  public createCategory(): void {
    this._showEditCategoryModal();
  }

  public editCategory(): void {
    const id = this._getIdParam(this._route.snapshot.params);

    if (!id) {
      this._showEditCategoryModal();
      return;
    }

    this._categoryService
      .getById(id)
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe((data) => {
        this._showEditCategoryModal(data);
      });
  }

  public removeCategory(): void {
    const id = this._getIdParam(this._route.snapshot.params);

    if (id) {
      this._categoryService
        .remove(id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          this._router.navigate([`analysis/graphic`]);
          this._updateCategoryList$.next();
        });
    }
  }

  public pushUnknownDataToCategory(dropData: TmTreeDropEvent<ImageGet>): void {
    const newCategoryId = dropData.node.data.id;
    const newData = Object.assign({}, dropData.data, {
      image_classifier2category: [
        {
          FINGERPRINT_ID: dropData.data.FINGERPRINT_ID,
          CATEGORY_ID: newCategoryId,
        },
      ],
    });

    // Backend should receive only 1 relation (graphic2category) to store
    delete newData.category;

    this._graphicDocumentService.updateById(newData.FINGERPRINT_ID, newData).subscribe(() => {
      this._graphicDocumentService.dataChanged$.next();
    });
  }

  private _getIdParam = (params: Params) => {
    return params.id;
  };

  private _updateTotalQuality(data: CategoryGet[], trained: boolean): void {
    if (!trained || !data.length) {
      delete this.totalQuality;
      return;
    }

    // Do not show quality if no quality data was sent
    // NOTE: this is applicable only for graphic
    if (data.some((x) => x.QUALITY !== undefined)) {
      this.totalQuality = Math.round(
        (data.reduce((total, item) => (total += item.QUALITY || 0), 0) / data.length) * 100
      );
    } else {
      delete this.totalQuality;
    }
  }

  private async _showEditCategoryModal(data?: TmGraphicCategoryEditData): Promise<void> {
    const currentModal = await this._modalService.open(TmGraphicCategoryEditComponent, { data });
    currentModal.component.onSubmit = (component) => {
      return (data && data.CATEGORY_ID
        ? this._categoryService.updateById(data.CATEGORY_ID, component.changedValues)
        : this._categoryService.create(component.changedValues)
      ).pipe(tap(() => this._updateCategoryList$.next()));
    };
  }

  private _setupRouterOutletComponent(component: RouterOutletComponents): void {
    if (component instanceof TmGraphicCategoryComponent) {
      this._setupCategoryComponent(component);
    }
  }

  private _graphicCategoryItemToGraphicMenuNode(categoryItem: CategoryGet, trained: boolean): TmTreeNodeData {
    return {
      id: categoryItem.CATEGORY_ID,
      name: categoryItem.DISPLAY_NAME,
      data: {
        percent: trained ? this._getQualityInPercent(categoryItem) : null,
      },
    };
  }

  /**
   * Get percent value or 'null' if no quality provided
   */
  private _getQualityInPercent(categoryItem: CategoryGet): number | null {
    if (categoryItem.QUALITY === undefined || isNaN(categoryItem.QUALITY)) {
      return null;
    }

    return Math.round(categoryItem.QUALITY * 100);
  }

  private _setupCategoryComponent(component: TmGraphicCategoryComponent): void {
    this._selectedCategory$.pipe(distinctUntilChanged(), takeUntil(this._destroy$)).subscribe((data) => {
      component.data = data;
    });
  }
}
