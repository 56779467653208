import {
  ActionViolationValue,
  ApplicationRule,
  ApplicationRuleData,
  TransferRule,
  TransferRuleData,
} from '../../../typings/generated/audit-policy-rule';
import { TransformOptions } from './audit-common';
import { PolicyDiffProperties } from '../../../typings/generated/audit-policy';
import { getWeekdayNameByIndex } from '../../../@tm-shared/helpers/date';
import { ApplicationSourceDestValue } from './audit-policy';
import {
  PolicyApplicationRule,
  PolicyCopyRule,
  RuleAttrDestRes,
  RuleAttrSourceRes,
} from '../../../typings/generated/policy-from-schema';
import { AuditPolicyPluginAttribute } from '../audit.model';
import { isArray } from 'lodash';
import { DateTime } from 'luxon';

export function transformViolation(value: ActionViolationValue, o: TransformOptions) {
  /**
   * @translate audit.commonAttributes.LOW
   * @translate audit.commonAttributes.MEDIUM
   * @translate audit.commonAttributes.HIGH
   * @translate audit.commonAttributes.NO
   */
  return value ? o.t.instant(`audit.commonAttributes.${value}`) : '';
}

export function transformStatus(
  value: PolicyDiffProperties['STATUS'] | null | undefined,
  options: TransformOptions
): string {
  if (value === undefined || value === null) {
    return '';
  }
  return +value ? options.t.instant(`audit.status.active`) : options.t.instant(`audit.status.disabled`);
}

export function transformDayValue(
  value: ApplicationRuleData['DAY'] | null | undefined,
  options: TransformOptions
): string {
  if (!value) {
    return '';
  }

  let result = '';

  if (value) {
    const dayKeys = value.map((x: string) => getWeekdayNameByIndex(+x));
    const formattedValues: string[] = [];
    for (const key of dayKeys) {
      /**
       * @translate audit.daysOfWeekFull.mon
       * @translate audit.daysOfWeekFull.tue
       * @translate audit.daysOfWeekFull.wed
       * @translate audit.daysOfWeekFull.thu
       * @translate audit.daysOfWeekFull.fri
       * @translate audit.daysOfWeekFull.sat
       * @translate audit.daysOfWeekFull.sun
       */
      formattedValues.push(options.t.instant(`audit.daysOfWeekFull.${key}`));
    }
    result = formattedValues.join(', ');
  }
  return result;
}

export function transformSourceDest<T>(
  value: ApplicationSourceDestValue | null | undefined,
  options: TransformOptions,
  conditionKey: 'CONDITION_SOURCE' | 'CONDITION_WORKSTATION' | 'CONDITION_APPLICATION'
): string;
export function transformSourceDest<T>(
  value: TransferRuleData['DEST'] | null | undefined,
  options: TransformOptions,
  conditionKey: 'CONDITION_DEST'
): string;
export function transformSourceDest(
  value: ApplicationSourceDestValue | TransferRuleData['DEST'] | null | undefined,
  options: TransformOptions,
  conditionKey: 'CONDITION_SOURCE' | 'CONDITION_DEST' | 'CONDITION_WORKSTATION' | 'CONDITION_APPLICATION'
): string {
  if (!value || !Array.isArray(value)) {
    return '';
  }

  // TODO: Very bad typings, make it better
  const colData = options.columnData as ApplicationRule & TransferRule;

  const result: string[] = [];

  for (let i = 0; i < value.length; i++) {
    const element = value[i];

    if (typeof element === 'string') {
      result.push(element);
      continue;
    }

    let item = '';

    if (element.TYPE) {
      /**
       * @translate audit.type.perimeter
       * @translate audit.type.group
       * @translate audit.type.person
       * @translate audit.type.workstation
       * @translate audit.type.resource
       * @translate audit.type.icq
       * @translate audit.type.share
       * @translate audit.type.sharepoint
       * @translate audit.type.skype
       * @translate audit.type.facebook
       * @translate audit.type.telegram
       * @translate audit.type.vkontakte
       * @translate audit.type.webaccount
       * @translate audit.type.mobile
       * @translate audit.type.phone
       * @translate audit.type.email
       * @translate audit.type.lotus
       * @translate audit.type.domain
       */
      item += options.t.instant(`audit.type.${element.TYPE}`) + ' ';
    }

    if (colData.DATA && conditionKey in colData.DATA) {
      item += getCondition(colData.DATA[conditionKey]);
    }

    if (element.NAME) {
      item += element.NAME;
    }

    if (item.length) {
      result.push(item);
    }
  }
  return result.join('<br>');
}

export function transformSourceDestRes(
  value: RuleAttrSourceRes | RuleAttrDestRes | null | undefined,
  options: TransformOptions
): string {
  if (!value) {
    return '';
  }

  let result = '';
  for (let i = 0; i < value.length; i++) {
    const item = value[i];
    if (item.TYPE) {
      result += options.t.instant('audit.type.RES_TYPE');
      result += ' = ';

      /**
       *  @translate audit.type.FTP
       *  @translate audit.type.NetworkResource
       *  @translate audit.type.TerminalSessionDevice
       *  @translate audit.type.RemovableDevice
       *  @translate audit.type.Computer
       *  @translate audit.type.CloudStorage
       */
      result += options.t.instant(`audit.type.${item.TYPE}`);
      result += ' <br>';
    }

    ['NAMES', 'PATHS', 'IDS'].forEach((key: 'NAMES' | 'PATHS' | 'IDS') => {
      if (item[key]) {
        /**
         *  @translate audit.type.NAMES
         *  @translate audit.type.PATHS
         *  @translate audit.type.IDS
         */
        result += options.t.instant(`audit.type.${key}`);
        result += getCondition(item[key].CONDITION);
        result += item[key].REGEXPS.join(', ');
        result += ' <br>';
      }
    });
  }

  return result;
}

export function transformDirection(
  value: PolicyCopyRule['DIRECTION'] | null | undefined,
  options: TransformOptions
): string {
  if (value === undefined || value === null) {
    return '';
  }

  if (+value === 1) {
    return '⇄ ' + options.t.instant('audit.both_direction_rule');
  }
  if (+value === 0) {
    return '→ ' + options.t.instant('audit.single_direction_rule');
  }

  return '';
}

export function getCondition(
  value?: '0' | '1' | 1 | 0 | '=' | '!=' | '>' | '<' | '<=' | '>=' | 'between' | 'in' | 'like' | null | undefined
): string {
  switch (value) {
    case '1':
    case 1:
    case '!=':
      return ' ≠ ';
    case '0':
    case 0:
    case '=':
      return ' = ';
    case '>':
    case '>=':
    case '<':
    case '<=':
      return ' ' + value + ' ';
    default:
      return ' : ';
  }
}

export function transformPluginAttributes(
  data: PolicyApplicationRule['PluginAttributes'] | null | undefined,
  options: TransformOptions
): string[] {
  if (data === undefined || data === null) {
    return [];
  }
  const result: string[] = [];

  data.map((action) => {
    let value;
    switch (action.OPERATION) {
      case '=':
      case '!=':
      case '>':
      case '>=':
      case '<':
      case '<=':
        value = getConditionValue(action.FORMAT, action.VALUE, options);
        result.push(action.NOTE + getCondition(action.OPERATION) + value);
        break;
      case 'between':
        value =
          '(' +
          options.t.instant(`audit.auditPolicyRule.attributes.between.from`) +
          ' ' +
          action.VALUE[0] +
          ' ' +
          options.t.instant(`audit.auditPolicyRule.attributes.between.to`) +
          ' ' +
          action.VALUE[1] +
          ')';
        result.push(action.NOTE + ' = ' + options.t.instant(`audit.auditPolicyRule.attributes.interval`) + ' ' + value);
        break;
      default:
        result.push(action.NOTE + getCondition(action.OPERATION) + action.VALUE);
        break;
    }
  });
  return result;
}

function getConditionValue(format: string | undefined, value: AuditPolicyPluginAttribute, options: TransformOptions) {
  let result;
  switch (format) {
    case 'date':
      result = transformCaptureDate(value, options);
      break;
    case 'duration':
      break;
    case 'integer':
    case 'link':
    case 'enum':
      if (isArray(value)) {
        result = value.filter((x) => !!x).join(', ') || '';
      } else {
        result = value;
      }
      break;
    default:
      if (isArray(value)) {
        result =
          value
            .map((value) => value.DATA)
            .filter((x) => !!x)
            .join(', ') || '';
      } else {
        result = value;
      }
      break;
  }
  return result;
}

function transformCaptureDate(condition: AuditPolicyPluginAttribute, options: TransformOptions): string {
  /**
   * @translate audit.date.none
   * @translate audit.date.all_time
   * @translate audit.date.this_day
   * @translate audit.date.this_week
   * @translate audit.date.this_month
   * @translate audit.date.last_several_hours
   * @translate audit.date.last_several_days
   * @translate audit.date.last_3_days
   * @translate audit.date.last_7_days
   * @translate audit.date.last_30_days
   * @translate audit.date.period
   */

  let result: string;

  switch (condition.type) {
    case 'last_days':
      if ([3, 7, 30].indexOf(condition.days) > -1) {
        result = options.t.instant(`audit.date.last_${condition.days}_days`);
      } else {
        result = `${options.t.instant('audit.date.last_several_days')}: ${condition.days}`;
      }
      break;
    case 'last_hours':
      result = `${options.t.instant('audit.date.last_several_hours')}: ${condition.hours}`;
      break;
    case 'period':
      result =
        `${options.t.instant('audit.date.period')}: ` +
        condition.period
          .map((dateMs: number) => {
            return DateTime.fromMillis(dateMs * 1000)
              .toLocal()
              .toFormat('f');
          })
          .join(' - ');
      break;
    default:
      result = options.t.instant(`audit.date.${condition.type}`);
  }

  return result;
}
