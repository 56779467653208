import { Injectable, Optional } from '@angular/core';
import { TmApiConfigStatus } from '@tm-shared/api';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { TmConfigAffiliateService, TmConfigLocalService } from '../services';
import { TmSessionService } from '../../../@tm-shared/session';
import { TmLicenseService } from '../../../@tm-shared/license';

/**
 * May be injected system-wide, usage example:
 *
 *   @Optional() _widget: TmPluginConfig.widget.Service
 * _widget.localConfig$.next(...newdata);
 */
@Injectable()
export class TmConfigWidgetService implements TmPluginConfig.widget.Service {
  public agentConfig$ = new ReplaySubject<TmPluginConfig.widget.Config>(1);

  public localConfig$ = new ReplaySubject<TmPluginConfig.widget.LocalConfig>(1);

  public inboundConfig$ = new ReplaySubject<TmPluginConfig.widget.InboundConfig>(1);

  public outboundConfig$ = new ReplaySubject<TmPluginConfig.widget.Config>(1);

  public queuedInboundConfig$ = new ReplaySubject<TmPluginConfig.widget.QueuedInboundConfig>(1);

  public goToAffiliatePage$: Subject<void> = new Subject();

  public goToConfigDiffPage$: Subject<void> = new Subject();

  public goToFillPolicyPage$: Subject<void> = new Subject();

  public fetchQueued$: Subject<void> = new Subject();

  private _disconnectWidget$: Subject<void> = new Subject();

  constructor(
    @Optional() private _configLocal: TmConfigLocalService,
    @Optional() private _configAffiliate: TmConfigAffiliateService,
    @Optional() private _session: TmSessionService,
    @Optional() private _license: TmLicenseService
  ) {}

  public connect(): void {
    if (!this._license) {
      return;
    }

    this._license
      .isActiveTechnologyFeature('affiliate')
      .pipe(take(1), takeUntil(this._disconnectWidget$))
      .subscribe((useAffiliate) => {
        useAffiliate ? this._connectWidgetToAffiliateService() : this._connectWidgetToLocalService();
      });
  }

  public disconnect(): void {
    this._disconnectWidget$.next();
  }

  public goToAffiliatePage(): void {
    this.goToAffiliatePage$.next();
  }

  public goToConfigDiffPage(): void {
    this.goToConfigDiffPage$.next();
  }

  public goToFillPolicyPage(): void {
    this.goToFillPolicyPage$.next();
  }

  public fetchQueued(): void {
    this.fetchQueued$.next();
  }

  private _connectWidgetToAffiliateService(): void {
    this._disconnectWidget$.next();

    this._configAffiliate.localConfig$
      .pipe(
        switchMap((data: TmPluginConfig.affiliate.LocalConfig) => {
          return this._session
            .isCurrentUserById(data.USER_ID)
            .pipe(map((isCurrent) => [data, isCurrent])) as Observable<[TmApi.configAffiliate.ConfigData, boolean]>;
        }),
        takeUntil(this._disconnectWidget$)
      )
      .subscribe(([config, isCurrentUser]) => {
        this.localConfig$.next({
          state: this._getConfigWidgetState(config.STATUS as number, isCurrentUser),
          timestamp: new Date(config.CHANGE_DATE).getTime(),
          version: config.CHANGE_ID,
          username: config.user.DISPLAY_NAME,
        });
      });

    this._configAffiliate.agentConfig$
      .pipe(takeUntil(this._disconnectWidget$))
      .subscribe((data: TmPluginConfig.affiliate.AgentConfig) => {
        this.agentConfig$.next({
          timestamp: new Date(data.CHANGE_DATE).getTime(),
          version: data.CHANGE_ID,
        });
      });

    this._configAffiliate.queuedInboundConfig$
      .pipe(takeUntil(this._disconnectWidget$))
      .subscribe((data: TmPluginConfig.affiliate.QueuedConfig) => {
        this.queuedInboundConfig$.next({
          timestamp: new Date(data.OUTBOUND_CHANGE_DATE).getTime(),
          version: data.OUTBOUND_VERSION,
        });
      });

    this._configAffiliate.inboundConfig$
      .pipe(takeUntil(this._disconnectWidget$))
      .subscribe((data: TmPluginConfig.affiliate.InboundConfig) => {
        this.inboundConfig$.next({
          timestamp: new Date(data.INBOUND_CHANGE_DATE).getTime(),
          version: data.INBOUND_VERSION_TAG,
          // TODO: use real data when available
          completion: 'available',
        });
      });

    this._configAffiliate.outboundConfig$
      .pipe(takeUntil(this._disconnectWidget$))
      .subscribe((data: TmPluginConfig.affiliate.OutboundConfig) => {
        this.outboundConfig$.next({
          timestamp: new Date(data.OUTBOUND_CHANGE_DATE).getTime(),
          version: data.OUTBOUND_VERSION,
        });
      });
  }

  private _connectWidgetToLocalService(): void {
    this._disconnectWidget$.next();

    this._configLocal.dataWithUpdates$
      .pipe(
        switchMap((config) => {
          return this._session.isCurrentUserById(config.USER_ID).pipe(map((isCurrent) => ({ config, isCurrent })));
        }),
        takeUntil(this._disconnectWidget$)
      )
      .subscribe(({ config, isCurrent }) => {
        this.localConfig$.next({
          state: this._getConfigWidgetState(config.STATUS as number, isCurrent),
          timestamp: new Date(config.CHANGE_DATE!).getTime(),
          version: config.CHANGE_ID,
          username: config.user.DISPLAY_NAME,
        });
      });
  }

  private _getConfigWidgetState(status: TmApiConfigStatus, isCurrentUser: boolean): TmPluginConfig.widget.ConfigState {
    switch (status) {
      case TmApiConfigStatus.Free:
        return 'default';
      case TmApiConfigStatus.Locked:
        return isCurrentUser ? 'editedByActiveUser' : 'locked';
      case TmApiConfigStatus.Saved:
        return 'applied';
      default:
        return 'default';
    }
  }
}
