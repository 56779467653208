import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';

@Injectable()
export class TmTagApiService extends TmCollectionLoader<ApiTag.TagInfo, ApiTag.TagCommon> {
  public readonly src = '/api/tag';

  public idAttribute = 'TAG_ID';

  public isModelEditable(tag: ApiTag.TagInfo[] | ApiTag.TagInfo): boolean {
    if (Array.isArray(tag)) {
      return tag.every((_tag) => this.isModelEditable(_tag));
    }

    return Boolean(tag.IS_SYSTEM !== 1);
  }
}
