import { Component, Input, ViewEncapsulation, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import { FromToRange, DateRangeMode } from './date-range.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TmFormComponent } from '@tm-shared/form';
import { of, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { dateTimeISOInputValidator, dateFromToIsoValidator } from '@tm-shared/helpers/validators/validators';

type CalendarType = 'date' | 'datetime-local';

@Component({
  selector: 'tm-date-range-calendar',
  templateUrl: 'date-range-calendar.component.html',
  styleUrls: ['date-range-calendar.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmDateRangeCalendarComponent extends TmFormComponent<FormGroup> {
  @Input() public set type(type: CalendarType) {
    this.inputType = type;
    if (this.initialFrom) {
      this.get('from')?.setValue(this.toInputFormat(this.initialFrom));
    }
    if (this.initialTo) {
      this.get('to')?.setValue(this.toInputFormat(this.initialTo));
    }
    this.get('from')?.setValidators(this.getInputValidators());
    this.get('to')?.setValidators(this.getInputValidators());
    this.form.setValidators(this.getFormValidators());
  }

  @Input() public set from(date: Date) {
    this.initialFrom = date;
    this.fromToMoment[0] = moment(date);
    this.get('from')?.setValue(this.toInputFormat(date));
  }

  @Input() public set to(date: Date) {
    this.initialTo = date;
    this.fromToMoment[1] = moment(date);
    this.get('to')?.setValue(this.toInputFormat(date));
  }

  @Output() public changed = new EventEmitter<FromToRange>();

  @Output() public closed = new EventEmitter<void>();

  public inputType: CalendarType = 'date';

  public todayMoment = moment();

  public fromToMoment: Moment[] = [];

  public get locale(): string {
    return this.t.currentLang;
  }

  private initialFrom?: Date;

  private initialTo?: Date;

  constructor(private t: TranslateService, fb: FormBuilder) {
    super();

    this.form = fb.group(
      {
        from: [this.initialFrom ? this.toInputFormat(this.initialFrom) : null, this.getInputValidators()],
        to: [this.initialTo ? this.toInputFormat(this.initialTo) : null, this.getInputValidators()],
      },
      {
        validators: this.getFormValidators(),
      }
    );

    /**
     * Sync iw calendar and local form if it has valid both "from" and "to" values
     */
    this.form.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((values: { from: string | null; to: string | null }) => {
        if (this.form.valid) {
          this.fromToMoment = [moment(values.from), moment(values.to)];
        }
      });
  }

  public iwCalendarChanged(range: Moment[] | Moment | null): void {
    if (Array.isArray(range) && range.length >= 2) {
      this.updateLocalRange(range as [Moment, Moment]);
    }
  }

  public onSubmit = (): Observable<boolean> => {
    const from = this.value('from');
    const to = this.value('to');

    if (from && to) {
      this.changed.emit({
        mode: DateRangeMode.fromTo,
        from: new Date(from),
        to: new Date(to),
      });

      return of(true);
    }

    return of(false);
  };

  private updateLocalRange(range: [Moment, Moment]): void {
    this.form.patchValue({
      from: this.toInputFormat(range[0].toDate()),
      to: this.toInputFormat(range[1].toDate()),
    });
  }

  private toInputFormat(date: Date): string {
    const dateTime = DateTime.fromJSDate(date);
    return this.inputType === 'datetime-local'
      ? dateTime.toFormat('yyyy-MM-dd') + 'T' + dateTime.toFormat('HH:mm')
      : dateTime.toFormat('yyyy-MM-dd');
  }

  private getInputValidators() {
    return [dateTimeISOInputValidator(false)];
  }

  private getFormValidators() {
    return [dateFromToIsoValidator('from', 'to')];
  }
}
