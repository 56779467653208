import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Use luxonDetectLangChange pipe right after transforming date to Luxon.DateTime format.
 * It'll validate current language and trigger change detection only if necessary.
 *
 * @example
 *
 * Ng
 * Component({
 *   template: `
 *     jsDateObject | dateTimeFromJsDate | luxonDetectLangChange | dateTimeToFormat: 'DD t'
 *   `
 * })
 */
@Pipe({ name: 'luxonDetectLangChange', pure: false })
export class TmLuxonDetectLangChangePipe implements PipeTransform, OnDestroy {
  private _dateTime?: DateTime;

  private _invalidLang = false;

  private _destroy$: Subject<void> = new Subject();

  constructor(private _cd: ChangeDetectorRef, private _t: TranslateService) {
    this._t.onLangChange.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._invalidLang = true;
      this._cd.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public transform(value: DateTime) {
    if (!this._dateTime || this._dateTime.toMillis() !== value.toMillis()) {
      this._dateTime = value;
      this._invalidLang = false;
    } else if (this._invalidLang) {
      this._dateTime = DateTime.fromJSDate(value.toJSDate());
      this._invalidLang = false;
    }
    return this._dateTime;
  }
}
