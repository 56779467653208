import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tm-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmContentComponent {
  @Input() public title?: string | null;

  /**
   * Set 'true' if tm-content component should have scrollBar,
   * set 'false' if some child component should have scrollBar.
   */
  @Input() public scrollable = true;

  /**
   * Apply default paddings
   */
  @Input() public defaultPadding = true;
}
