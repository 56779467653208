import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmChannelService } from '@tm-shared/channel';
import { TmTechLoaderComponentService } from '@tm-shared/tech-loader/tech-loader-component.service';
import { ClassifierType } from 'plugins/tech-classifier/tech-classifier-providers';
import { Observable, Subject, merge, of } from 'rxjs';
import { catchError, filter, map, shareReplay } from 'rxjs/operators';
import {
  CheckImageErrorCode,
  CheckImageResponse,
  CheckImageResponseItem,
  CheckImageState,
  WsCheckImageResponse,
} from 'typings/generated/technology-imageClassifier';
import { TmTechClassifierFileCheck } from '../tech-classifier/tech-classifier-file-check/tech-classifier-file-check.service';

@Injectable()
export class TmGraphicDocumentCheckService extends TmTechClassifierFileCheck<CheckImageResponse> {
  public type: ClassifierType = ClassifierType.graphicImageClassifier;

  public src = '/api/image_classifier/checkImage';

  public wsUpdates$ = this.channelService.getUserChannel<WsCheckImageResponse>('image_classifier').pipe(
    // TODO: this hack should not be required after openapi schema merge https://gerrit.infowatch.ru/#/c/242557/
    filter(
      (msg: WsCheckImageResponse & { data?: WsCheckImageResponse['data'] }) =>
        msg.data && msg.data.state === 'checkImage'
    ),
    shareReplay(1)
  );

  public wsData$ = this.wsUpdates$.pipe(map((msg) => msg.meta.models || []));

  public dataWithUpdates$ = merge(
    this.getDataStream(-1).pipe(
      map((response) => response.data),
      catchError(() => of([] as CheckImageResponse['data']))
    ),
    this.wsData$
  ).pipe(shareReplay(1));

  /**
   * Common state for all documents
   */
  public state$: Observable<CheckImageState>;

  protected immediateState$ = new Subject<CheckImageState>();

  constructor(
    protected http: HttpClient,
    protected _t: TranslateService,
    protected techLoader: TmTechLoaderComponentService,
    protected channelService: TmChannelService
  ) {
    super(http);
    this.state$ = merge(
      this.dataWithUpdates$.pipe(map((models) => this.getStateForMultipleFiles(models))),
      this.immediateState$
    ).pipe(shareReplay(1));
  }

  public getErrorText(errorKey: CheckImageErrorCode): string {
    switch (errorKey) {
      case CheckImageErrorCode.extractionFailed:
        return this._t.instant(`tech-graphic.checkImage.errors.extractionFailed`);
      case CheckImageErrorCode.failedSaveFile:
        return this._t.instant(`tech-graphic.checkImage.errors.failedSaveFile`);
      case CheckImageErrorCode.internalError:
        return this._t.instant(`tech-graphic.checkImage.errors.internalError`);
      case CheckImageErrorCode.noAnalyseData:
        return this._t.instant(`tech-graphic.checkImage.errors.noAnalyseData`);
      case CheckImageErrorCode.unsupportedFileType:
        return this._t.instant(`tech-graphic.checkImage.errors.unsupportedFileType`);
      default:
        return '';
    }
  }

  protected setCheckStateToCancel(): void {
    this.immediateState$.next(CheckImageState.cancel);
  }

  protected getStateForMultipleFiles(items?: CheckImageResponseItem[]): CheckImageState {
    let result: CheckImageState = CheckImageState.success;

    /**
     * Less index => higher priority
     */
    const priority: CheckImageState[] = [
      CheckImageState.error,
      CheckImageState.progress,
      CheckImageState.cancel,
      CheckImageState.success,
    ];

    /**
     * "0" items are equal to "pristine" (show file loader dialog)
     */
    if (!items || !items.length) {
      return CheckImageState.pristine;
    }

    for (let i = 0; i < items.length; i++) {
      if (priority.indexOf(items[i].STATE) < priority.indexOf(result)) {
        result = items[i].STATE;
      }
    }

    /**
     * NOTE: Backend always return state for 1 file, and
     * Success, Error and Cancel are possible only if all files are succeed.
     */
    if (items.length < this.latestCheckFilesQueryLength) {
      result = CheckImageState.progress;
    }

    return result;
  }

  protected setCheckStateToProgress(): void {
    this.immediateState$.next(CheckImageState.progress);
  }
}
