<form iwForm [formGroup]="form" (submit)="submit()">
  <div class="fromToContainer">
    <iw-input-container class="fromToItem from">
      <input iwInput [type]="inputType" data-qa="dateRangeFrom" formControlName="from" />
      <div class="inputErrors" iwErrorHints></div>
    </iw-input-container>
    <iw-input-container class="fromToItem to">
      <input iwInput [type]="inputType" data-qa="dateRangeTo" formControlName="to" />
      <div class="inputErrors" iwErrorHints></div>
    </iw-input-container>
  </div>

  <iw-calendar
    mode="range"
    [showTwoMonth]="true"
    [date]="todayMoment"
    [locale]="locale"
    [selected]="fromToMoment"
    (selectedChange)="iwCalendarChanged($event)"
  ></iw-calendar>

  <iw-form-toolbar>
    <button iw-button type="submit" color="success" data-qa="dateRangeSubmit">
      {{ '@tm-shared.dateRange.calendar.save' | translate }}
    </button>
    <button iw-button type="button" (click)="closed.emit()" data-qa="dateRangeClose">
      {{ '@tm-shared.dateRange.calendar.cancel' | translate }}
    </button>
  </iw-form-toolbar>
</form>
