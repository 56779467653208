import { Injectable } from '@angular/core';
import { QueryItem } from './interfaces';

/**
 * Преобразования устаревших форматов событий в актуальные
 */
@Injectable()
export class TmEventMigrationService {
  // атрибут rule_group_type, KRT-23621
  public parseRuleGroupType(item: QueryItem): QueryItem {
    if (item.value.includes('Clipboard')) {
      item.value = item.value.filter((valueItem) => !['Clipboard', 'Application'].includes(valueItem));
      item.value.push('Application');
    }
    return item;
  }

  // атрибут rule_group_type, KRT-23621
  public prepareRuleGroupType(value: string[]): string[] {
    if (value.includes('Application')) {
      value.push('Clipboard');
    }
    return value;
  }

  /**
   * DESTINATION_HOST, URL_HOST ----> DESTINATION_NAME
   * KRT-18459
   */
  public moveDestinationUrlHostToDestinationName(object: any) {
    if (object.DESTINATION_HOST || object.URL_HOST) {
      object.DESTINATION_NAME = object.DESTINATION_HOST || object.URL_HOST;
      delete object.DESTINATION_HOST;
      delete object.URL_HOST;
    }
  }
}
