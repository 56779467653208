import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwPluralizePipe, IwPopoverOptions, IwModalService } from '@platform/shared';
import { TmResourcesService } from '@tm-shared/api-services';
import { TmGridComponent, TmGridOptions } from '@tm-shared/grid';
import { ModalConfirmWithItemsComponent } from '@tm-shared/modals';
import { Observable, Subject, empty, merge, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { SystemListItem } from '../generated/resources';
import { ResourcesTableFormComponent } from '../resources-table-form-component/resources-table-form.component';
import { CREATE_ITEM_PATH, EDIT_ITEM_PATH, TREE_ROOT } from '../resources.model';
import { ResourcesTableService } from './resources-table.service';

@Component({
  selector: 'tm-resources-table',
  templateUrl: 'resources-table.component.html',
  styleUrls: ['./resources-table.component.scss'],
  providers: [
    ResourcesTableService,
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
        placement: 'bottom-start',
        closeOnMousemoveOutside: true,
        triggers: 'mouseenter:mouseleave',
      }),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesTableComponent implements OnDestroy, AfterViewInit {
  @ViewChild(TmGridComponent, { static: false }) public grid: TmGridComponent<SystemListItem>;
  public filterControl = new FormControl();

  public title$: Observable<string>;
  public createMessage$: Observable<string>;
  public editMessage$: Observable<string>;
  public removeMessage$: Observable<string>;

  public tableConfig$: Observable<TmGridOptions>;

  public canEditSelection$: Observable<boolean>;
  public canRemoveSelection$: Observable<boolean>;

  public canCreateResources$: Observable<boolean>;
  public canEditResources$: Observable<boolean>;
  public canRemoveResources$: Observable<boolean>;

  private _isNotRoot$: Observable<boolean>;

  private _resourcesId$: Observable<string>;
  private _destroy$: Subject<any>;

  constructor(
    public resourcesTableService: ResourcesTableService,
    private _router: Router,
    private _route: ActivatedRoute,
    public tableApi: TmResourcesService,
    private _t: TranslateService,
    private _modalService: IwModalService
  ) {
    this._resourcesId$ = this._route.params.pipe(map((params) => params.id));

    this.tableConfig$ = resourcesTableService.getTableConfig();
    this._destroy$ = new Subject();

    this._router.events
      .pipe(
        takeUntil(this._destroy$),
        filter((event) => event instanceof NavigationEnd),
        map(() => this._route.snapshot),
        distinctUntilChanged((snapshotOld, snapshotNew) => {
          const paramsOld = snapshotOld.params;
          const paramsNew = snapshotNew.params;
          return paramsOld['edit_item'] === paramsNew['edit_item'] && paramsOld['item_id'] === paramsNew['item_id'];
        }),
        map((snapshot) => [snapshot.params['edit_item'], snapshot.params['item_id']])
      )
      .subscribe(([condition, itemId]) => {
        if (condition) {
          this._showDialogEdit(itemId);
        }
      });
  }

  public ngAfterViewInit(): void {
    this.canEditSelection$ = this.grid.selected$.pipe(
      map((data) => {
        return data.length === 1;
      }),
      takeUntil(this._destroy$)
    );

    this.canRemoveSelection$ = this.grid.selected$.pipe(
      map((data) => {
        return !!data.length;
      }),
      takeUntil(this._destroy$)
    );

    this._isNotRoot$ = this._resourcesId$.pipe(map((id) => id !== TREE_ROOT));

    this.canCreateResources$ = this.resourcesTableService.can('edit', this._isNotRoot$);
    this.canEditResources$ = this.resourcesTableService.can('edit', this.canEditSelection$);
    this.canRemoveResources$ = this.resourcesTableService.can('delete', this.canRemoveSelection$);

    this.resourcesTableService.grid = this.grid;
    this.resourcesTableService.setGridFilteringLogic(this.filterControl.valueChanges);

    this.title$ = this.grid.selected$.pipe(
      map((selectedModels: SystemListItem[]) => {
        return selectedModels[0].VALUE;
      })
    );

    this.createMessage$ = this.canCreateResources$.pipe(
      map((canCreateResources: boolean) => {
        if (canCreateResources) {
          return this._t.instant('lists-resources.table.toolbar.create');
        } else {
          return this._t.instant('lists-resources.table.toolbar.uncreatable');
        }
      })
    );

    this.editMessage$ = this.canEditResources$.pipe(
      map((canEditResources: boolean) => {
        if (canEditResources) {
          return this._t.instant('lists-resources.table.toolbar.edit');
        } else {
          return this._t.instant('lists-resources.table.toolbar.uneditable');
        }
      })
    );

    this.removeMessage$ = this.canRemoveResources$.pipe(
      map((canRemoveResources: boolean) => {
        if (canRemoveResources) {
          const selected = this.grid.getAllSelected();
          if (selected.length > 1) {
            return this._t.instant('lists-resources.table.toolbar.delete.many');
          } else {
            return this._t.instant('lists-resources.table.toolbar.delete.one');
          }
        } else {
          return this._t.instant('lists-resources.table.toolbar.undeletable');
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public showDialogCreate(): void {
    const path: string = CREATE_ITEM_PATH.replace(':id/', '').replace(':edit_item', 'edit_item');
    this._router.navigate([path], {
      relativeTo: this._route,
      queryParamsHandling: 'preserve',
    });
  }

  public showDialogEdit(): void {
    const selectedModels: SystemListItem[] = this.grid.getAllSelected();
    const path = EDIT_ITEM_PATH.replace(':id/', '')
      .replace(':edit_item', 'edit_item')
      .replace(':item_id', `${selectedModels[0].LIST_ITEM_ID}`);
    this._router.navigate([path], {
      relativeTo: this._route,
      queryParamsHandling: 'preserve',
    });
  }

  public showDialogDelete(): void {
    this.grid.selected$
      .pipe(
        take(1),
        switchMap((selectedModels: SystemListItem[]) => {
          const plural = new IwPluralizePipe();
          const titleToShow = plural.transform('lists-resources.table.delete.title', selectedModels.length);
          const textToShow = plural.transform('lists-resources.table.delete.text', selectedModels.length);
          return this._modalService.open(ModalConfirmWithItemsComponent, {
            title: `${this._t.instant(titleToShow)}`,
            text: `${this._t.instant(textToShow, { number: selectedModels.length })}`,
            itemsToDisplay: selectedModels.map((item) => item.VALUE),
          });
        }),
        switchMap((modal) => {
          return modal.component.decision.pipe(switchMap((ok) => (ok ? this.grid.deleteAllSelected() : empty())));
        })
      )
      .subscribe();
  }

  private async _showDialogEdit(itemId: number): Promise<void> {
    const params = {
      itemId$: of(itemId),
      resourcesId$: this._resourcesId$.pipe(map((resourcesId) => (resourcesId ? resourcesId : TREE_ROOT))),
    };

    const modal = await this._modalService.open(ResourcesTableFormComponent, params, { size: 'large' });

    const componentInstance = modal.component;
    const closeComponentForm$ = componentInstance.close$.pipe(
      tap(() => {
        modal.close(null);
      })
    );

    merge(modal.dismissed, modal.closed, closeComponentForm$)
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe(() => {
        this._router.navigate(['../../'], {
          relativeTo: this._route,
          queryParamsHandling: 'preserve',
        });
      });
  }
}
