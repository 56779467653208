import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';

/**
 * Allows to sync data between browser tabs
 */
@Injectable()
export class TmBrowserTabSyncService {
  public readonly storageKey = `TmBrowserTabSyncService[${location.origin}]`;

  private _source$ = (fromEvent(window, 'storage') as Observable<StorageEvent>).pipe(
    startWith({ key: this.storageKey }),
    filter((e) => e.key === this.storageKey),
    map(() => this._getData()),
    shareReplay(1)
  );

  public remove(key: string): void {
    this._removeItem(key);
  }

  public set(key: string, value: string): void {
    this._setItem(key, value);
  }

  public listen(key: string): Observable<string | undefined> {
    return this._source$.pipe(map((data) => this._extract(data, key)));
  }

  private _extract(data: any, key: string): string | undefined {
    return key in data ? data[key] : undefined;
  }

  private _getData(): any {
    let data = localStorage.getItem(this.storageKey);
    return data === null ? {} : JSON.parse(data);
  }

  private _storeData(data: any): void {
    localStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  private _setItem(key: string, value: string): void {
    let data = this._getData();
    data[key] = value;
    this._storeData(data);
  }

  private _removeItem(key: string): void {
    let data = this._getData();
    delete data[key];
    this._storeData(data);
  }
}
