import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, finalize, map, switchMapTo, take } from 'rxjs/operators';

export class LoadingTracker {
  public loading: Observable<boolean>;

  private activeRequests: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor() {
    this.loading = this.activeRequests.pipe(
      map(() => this.isLoadingNow()),
      debounceTime(0)
    );
  }

  public isLoadingNow() {
    return !!this.activeRequests.getValue();
  }

  public trackRequest<T>(stream$: Observable<T>): Observable<T> {
    return of(this.activeRequestAdd(1)).pipe(
      switchMapTo(stream$),
      finalize(() => this.activeRequestAdd(-1)),
      take(1)
    );
  }

  private activeRequestAdd(n: number): void {
    this.activeRequests.next(this.activeRequests.getValue() + n);
  }
}
