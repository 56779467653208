import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { getPreset } from '@tm-shared/cronjob/cronjob';
import { FormControlPath, GetValueOptions, TmFormComponent } from '@tm-shared/form';
import { toBoolAsInt } from '@tm-shared/helpers/form';
import { PATTERN_STRING_256_1, PATTERN_STRING_LDAP_AD_ALD, PATTERN_STRING_LDAP_DD } from '@tm-shared/helpers/patterns';
import { wrap } from '@tm-shared/helpers/validators/validators';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, startWith, takeUntil } from 'rxjs/operators';
import * as ApiAdlibitum from '../generated/adlibitum';
import { LdapsCheckConnection } from '../ldap.service';

type FormInputData = Partial<{ [key in keyof ApiAdlibitum.AdlibitumInfoGet]: ApiAdlibitum.AdlibitumInfoGet[key] }>;
type FormBuilderData = { [key in keyof FormInputData]: [any, ValidatorFn[]?, AsyncValidatorFn[]?] };

@Component({
  selector: 'tm-ldap-server-edit',
  templateUrl: './ldap-server-edit.component.html',
  styleUrls: ['./ldap-server-edit.component.scss'],
})
export class TmLdapServerEditComponent extends TmFormComponent<FormGroup> implements OnInit {
  public static readonly DEFAULT_GLOBAL_PORT: FormInputData['global_port'] = 3268;
  public static readonly DEFAULT_GLOBAL_PORT_SECURE: FormInputData['global_port'] = 3269;
  public static readonly DEFAULT_DOM_PORT: FormInputData['dom_port'] = 389;
  public static readonly DEFAULT_DOM_PORT_SECURE: FormInputData['dom_port'] = 636;
  public static readonly DEFAULT_SCHEME: FormInputData['protocol'] = ApiAdlibitum.ConnectionType.ldap;
  public static readonly DEFAULT_SERVER_TYPE: FormInputData['server_type'] = ApiAdlibitum.ServerType.ad;
  public static readonly DEFAULT_ENABLED: FormInputData['enabled'] = ApiAdlibitum.BooleanAsInteger.true;
  public static readonly DEFAULT_KERBEROS: FormInputData['kerberos'] = ApiAdlibitum.BooleanAsInteger.false;
  public static readonly DEFAULT_USE_GLOBAL_CATALOG: FormInputData['use_global_catalog'] =
    ApiAdlibitum.BooleanAsInteger.true;

  @Input() public set data(data: FormInputData | null) {
    if (this._data === undefined) {
      this.isNew = !data || !data.name;
      this.passwordStored = this._passwordWasStored(data);
    }

    this._data = data || {};
    this.serverId = this._data.name;

    if (this.form) {
      this.form.reset(this._getFormGroupFrom(this._data).value);
      this._formReset();
    }
  }

  public get data(): FormInputData | null {
    return this._data || null;
  }

  @Output() public check: EventEmitter<LdapsCheckConnection> = new EventEmitter();

  public serverId?: ApiAdlibitum.AdlibitumInfoGet['name'];

  public destroy$: Observable<void> = this._destroyed$.asObservable();

  public isNew = false;

  public passwordStored = false;

  private _data?: FormInputData | null;

  constructor(private _fb: FormBuilder) {
    super();
  }

  public ngOnDestroy(): void {
    delete this.onSubmit;
    super.ngOnDestroy();
  }

  public ngOnInit(): void {
    this.form = this._getFormGroupFrom(this._data || {});
    this._formReset();

    this.get('server_type')!
      .valueChanges.pipe(
        startWith(this.get('server_type')!.value),
        filter(() => this.get('server_type')!.enabled),
        distinctUntilChanged(),
        takeUntil(this._destroyed$)
      )
      .subscribe((nexValue) => this.serverTypeChanged(nexValue));
    this.get('protocol')!
      .valueChanges.pipe(
        startWith(this.get('protocol')!.value),
        filter(() => this.get('protocol')!.enabled),
        distinctUntilChanged(),
        takeUntil(this._destroyed$)
      )
      .subscribe((nexValue) => this.protocolChanged(nexValue));
    this.get('anon')!
      .valueChanges.pipe(
        startWith(this.get('anon')!.value),
        filter(() => this.get('anon')!.enabled),
        distinctUntilChanged(),
        takeUntil(this._destroyed$)
      )
      .subscribe((nexValue) => this.anonChanged(nexValue));
    this.get('kerberos')!
      .valueChanges.pipe(
        startWith(this.get('kerberos')!.value),
        filter(() => this.get('kerberos')!.enabled),
        distinctUntilChanged(),
        takeUntil(this._destroyed$)
      )
      .subscribe((nexValue) => this.kerberosChanged(nexValue));
    this.get('enabled')!
      .valueChanges.pipe(
        startWith(this.get('enabled')!.value),
        filter(() => this.get('enabled')!.enabled),
        distinctUntilChanged(),
        takeUntil(this._destroyed$)
      )
      .subscribe((nexValue) => this.enabledChanged(nexValue));
    this.get('cert_authentication')!
      .valueChanges.pipe(
        startWith(this.get('cert_authentication')!.value),
        filter(() => this.get('cert_authentication')!.enabled),
        distinctUntilChanged(),
        takeUntil(this._destroyed$)
      )
      .subscribe((nexValue) => this.clientCertChanged(nexValue));
  }

  public checkConnection(): void {
    const value = this.serialize('', { excludeDisabled: true });

    // If password is empty, then do not save it
    if (!('password' in this.changedValues)) {
      delete value.data.password;
    }

    this.check.next(value);
  }

  public getCertDownloadLink(type: 'server' | 'client'): string {
    if (!this.serverId || !this._data) {
      return '';
    }

    const fileExists = !!(type === 'server' ? this._data.ca_cert_file : this._data.client_cert_file);

    if (!fileExists) {
      return '';
    }

    return this.serverId
      ? `/api/adlibitum/server/${this.serverId}/certificate?type=${
          type === 'server' ? ApiAdlibitum.CertType.CA : ApiAdlibitum.CertType.Client
        }`
      : '';
  }

  public isInsecureConnection(): boolean {
    return Boolean(
      this.get('protocol')!.value !== ApiAdlibitum.ConnectionType.ldap &&
        !this.get('ca_cert_file')!.value &&
        !this.get('client_cert_file')!.value
    );
  }

  public serverTypeChanged(value: ApiAdlibitum.ServerType): void {
    switch (value) {
      case ApiAdlibitum.ServerType.ad:
        this._setIfPristine('kerberos', ApiAdlibitum.BooleanAsInteger.false);
        this.enable('use_global_catalog');
        this.enable('global_port');
        break;
      case ApiAdlibitum.ServerType.lotus:
        this._setIfPristine('kerberos', ApiAdlibitum.BooleanAsInteger.false);
        this.disable('use_global_catalog');
        this.disable('global_port');
        break;
      case ApiAdlibitum.ServerType.ald:
        this._setIfPristine('kerberos', ApiAdlibitum.BooleanAsInteger.true);
        this.disable('use_global_catalog');
        this.disable('global_port');
        break;
    }

    this.get('base')!.updateValueAndValidity();
  }

  public protocolChanged(value: ApiAdlibitum.ConnectionType): void {
    switch (value) {
      case ApiAdlibitum.ConnectionType.ldap:
        this._setIfPristine('global_port', TmLdapServerEditComponent.DEFAULT_GLOBAL_PORT);
        this._setIfPristine('dom_port', TmLdapServerEditComponent.DEFAULT_DOM_PORT);
        this.disable('ca_cert_file');
        this.disable('client_cert_file');
        this.disable('private_key_file');
        this.disable('cert_authentication');
        break;
      case ApiAdlibitum.ConnectionType.startTls:
        this._setIfPristine('global_port', TmLdapServerEditComponent.DEFAULT_GLOBAL_PORT);
        this._setIfPristine('dom_port', TmLdapServerEditComponent.DEFAULT_DOM_PORT);
        this.enable('ca_cert_file');
        this.enable('client_cert_file');
        this.enable('private_key_file');
        this.enable('cert_authentication');
        break;
      case ApiAdlibitum.ConnectionType.ldaps:
        this._setIfPristine('global_port', TmLdapServerEditComponent.DEFAULT_GLOBAL_PORT_SECURE);
        this._setIfPristine('dom_port', TmLdapServerEditComponent.DEFAULT_DOM_PORT_SECURE);
        this.enable('ca_cert_file');
        this.enable('client_cert_file');
        this.enable('private_key_file');
        this.enable('cert_authentication');
        break;
    }

    this.updateUsernamePassword();
  }

  public anonChanged(anonEnabled: boolean): void {
    if (anonEnabled) {
      this.disable('kerberos');
    } else {
      this.enable('kerberos');
    }
    this.updateUsernamePassword();
  }

  public kerberosChanged(enabled: boolean): void {
    if (enabled) {
      this.disable('anon');
    } else {
      this.enable('anon');
    }
  }

  public enabledChanged(enabled: boolean): void {
    if (enabled) {
      this.enable('job');
    } else {
      this.disable('job');
    }
    this.get('job')!.updateValueAndValidity();
  }

  public clientCertChanged(useClientCert: boolean): void {
    if (useClientCert) {
      this.disable('anon');
    } else {
      this.enable('anon');
    }

    this.updateUsernamePassword();
  }

  public updateUsernamePassword(): void {
    if (
      !!this.value('cert_authentication', {
        excludeDisabled: true,
      }) ||
      !!this.value('anon', {
        excludeDisabled: true,
      })
    ) {
      this.disable('username');
      this.disable('password');
    } else {
      this.enable('username');
      this.enable('password');
    }
  }

  public serialize(path?: FormControlPath, options: GetValueOptions = {}): any {
    if (!path) {
      return this._getAllSerialized(options);
    }

    switch (this.controlPathAsString(path)) {
      case 'anon':
      case 'enabled':
      case 'kerberos':
      case 'use_global_catalog':
      case 'cert_authentication':
        // HACK: I don't know why linter is reporting "unnecessary not-null assertion" here,
        // this.value(path) may be 'undefined' (by type) and toBoolAsInt() does not accept 'undefined'
        // @ts-ignore
        return toBoolAsInt(this.value(path, { disabledValueOverride: ApiAdlibitum.BooleanAsInteger.false }));
      case 'username':
      case 'password':
        return this.serialize('anon') ? undefined : this.value(path, options);
      case 'job':
        return this.serialize('enabled') ? this.value(path, options) : undefined;
      case 'base':
        return this.value('base') || '';
      default:
        return this.value(path, options);
    }
  }

  protected _formReset(): void {
    this.serverTypeChanged(this.get('server_type')!.value);
    this.protocolChanged(this.get('protocol')!.value);
    this.anonChanged(this.get('anon')!.value);
    this.kerberosChanged(this.get('kerberos')!.value);
    this.enabledChanged(this.get('enabled')!.value);
    this.clientCertChanged(this.get('cert_authentication')!.value);

    if (!this.isNew) {
      this.get('server_type')!.disable();
    }
  }

  /**
   * Get serialized form value
   */
  private _getAllSerialized(options: GetValueOptions = {}): ApiAdlibitum.AdlibitumInfoCert {
    // Get all required by openapi schema props
    const dataProps: any = {
      dom_port: this.serialize('dom_port'),
      display_name: this.serialize('display_name'),
      address: this.serialize('address'),
      base: this.serialize('base'),
      page_size: this.serialize('page_size'),
    };

    // Prepare output object
    const multipartFormDataPayload: ApiAdlibitum.AdlibitumInfoCert = {
      data: dataProps,
    };

    // Collect other properties
    // We should include also disabled props to show correct information on server's page
    for (const key in this.form.controls) {
      if (key && !(key in dataProps)) {
        const value = this.serialize(key, options);
        // Collect only non-empty data
        if (value !== undefined) {
          // @ts-ignore
          multipartFormDataPayload.data[key] = value;
        }
      }
    }

    /**
     * Process files:
     * if file is attached move its content to 'multipartFormDataPayload'
     * NOTE: openapi to ts ignores 'nullable: true', so we use 'as unknown' to assign null value
     */
    if (dataProps.ca_cert_file) {
      if (dataProps.ca_cert_file instanceof File) {
        multipartFormDataPayload.ca_cert_content = dataProps.ca_cert_file;
      }
      delete dataProps.ca_cert_file;
    } else if (dataProps.ca_cert_file === null) {
      (dataProps.ca_cert_file as unknown) = null;
    }

    if (dataProps.client_cert_file) {
      if (dataProps.client_cert_file instanceof File) {
        multipartFormDataPayload.client_cert_content = dataProps.client_cert_file;
      }
      delete dataProps.client_cert_file;
    } else if (dataProps.client_cert_file === null) {
      (dataProps.client_cert_file as unknown) = null;
    }

    if (dataProps.private_key_file) {
      if (dataProps.private_key_file instanceof File) {
        multipartFormDataPayload.private_key_content = dataProps.private_key_file;
      }
      delete dataProps.private_key_file;
    } else if (dataProps.private_key_file === null) {
      (dataProps.private_key_file as unknown) = null;
    }

    return multipartFormDataPayload;
  }

  private _setIfPristine<T extends keyof FormInputData>(key: T, value: FormInputData[T]): void {
    if (this.get(key)!.pristine && this._data && !(key in this._data)) {
      this.get(key)!.setValue(value);
    }
  }

  // TODO: Field validation is not defined in PR, track page changes:
  // https://wiki.infowatch.ru/pages/viewpage.action?pageId=132851050#
  // eslint-disable-next-line
  private _getFormGroupFrom(data: Partial<FormInputData>): FormGroup {
    return this._fb.group(<Partial<FormBuilderData>>{
      display_name: [
        'display_name' in data ? data.display_name : null,
        [Validators.required, Validators.pattern(PATTERN_STRING_256_1), Validators.maxLength(256)],
      ],
      address: ['address' in data ? data.address : null, [Validators.required]],
      global_port: [
        'global_port' in data ? data.global_port : TmLdapServerEditComponent.DEFAULT_GLOBAL_PORT,
        [
          Validators.required,
          Validators.pattern(/\d+/),
          wrap(Validators.max(65535), 'max_global'),
          wrap(Validators.min(1), 'min_global'),
        ],
      ],
      dom_port: [
        'dom_port' in data ? data.dom_port : TmLdapServerEditComponent.DEFAULT_DOM_PORT,
        [Validators.pattern(/\d+/), Validators.max(65535), Validators.min(1)],
      ],
      use_global_catalog: [
        !!('use_global_catalog' in data
          ? data.use_global_catalog
          : TmLdapServerEditComponent.DEFAULT_USE_GLOBAL_CATALOG),
        [Validators.required],
      ],
      username: ['username' in data ? data.username : null, [Validators.required]],
      password: ['password' in data ? data.password : null, [this._passwordValidator]],
      base: ['base' in data ? data.base : null, [this._queryValidator]],
      server_type: [
        'server_type' in data ? data.server_type : TmLdapServerEditComponent.DEFAULT_SERVER_TYPE,
        [Validators.required, Validators.pattern(/[1-3]{1}/)],
      ],
      enabled: [!!('enabled' in data ? data.enabled : TmLdapServerEditComponent.DEFAULT_ENABLED)],
      kerberos: [!!('kerberos' in data ? data.kerberos : TmLdapServerEditComponent.DEFAULT_KERBEROS)],
      anon: ['anon' in data ? !!data.anon : null],
      protocol: [
        'protocol' in data ? data.protocol : TmLdapServerEditComponent.DEFAULT_SCHEME,
        [Validators.required, Validators.pattern(/[1-3]{1}/)],
      ],
      cert_authentication: [data.cert_authentication ? data.cert_authentication : false],
      ca_cert_file: [data.ca_cert_file ? { name: data.ca_cert_file } : null],
      client_cert_file: [data.client_cert_file ? { name: data.client_cert_file } : null],
      private_key_file: [data.private_key_file ? { name: data.private_key_file } : null],
      job: ['job' in data ? data.job : getPreset(null).toJson()],
      page_size: [500],
    });
  }

  /**
   * Password required validation applied if passwored was stored and not changed
   */
  private _passwordValidator = (control: AbstractControl): ValidationErrors | null => {
    return this._passwordWasStored(this._data) && (control.pristine || !control.value)
      ? null
      : Validators.required(control);
  };

  private _queryValidator = (control: AbstractControl): ValidationErrors | null => {
    const type = this.value('server_type');
    const validators: ValidatorFn[] = [];

    /**
     * @translate settings-ldap.serverEditForm.validation.pattern_dd
     * @translate settings-ldap.serverEditForm.validation.pattern_ad
     */
    validators.push(
      type === ApiAdlibitum.ServerType.lotus
        ? wrap(Validators.pattern(PATTERN_STRING_LDAP_DD), 'pattern_dd')
        : wrap(Validators.pattern(PATTERN_STRING_LDAP_AD_ALD), 'pattern_ad')
    );

    if (type === ApiAdlibitum.ServerType.ad) {
      validators.push(Validators.required);
    }

    return Validators.compose(validators)!(control);
  };

  private _passwordWasStored(data: any): boolean {
    return !!data && !!data.name && !data.anon && !data.cert_authentication;
  }
}
