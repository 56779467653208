import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogRef, IwModalService } from '@platform/shared';
import { ModalConfirmComponent } from '@tm-shared/modals';
import { Subject, merge } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

export type TmComponentConstructorWithDecision = new (...args: any) => {
  decision: EventEmitter<boolean>;
};

@Directive({
  selector: '[tmDeleteConfirm]',
})
export class TmDeleteConfirmDirective implements OnDestroy {
  @Input() public tmDeleteConfirmDialog?: TmComponentConstructorWithDecision;

  @Input() public tmDeleteConfirmDialogData?: { [key: string]: any };

  @Input() public tmDeleteConfirmItems?: string | string[] | null;

  @Input() public disabled: boolean | string = false;

  @Output() public tmDeleteDecision: EventEmitter<boolean> = new EventEmitter();

  @Output() public tmDeleteConfirm: EventEmitter<void> = new EventEmitter();

  @Output() public tmDeleteReject: EventEmitter<void> = new EventEmitter();

  public currentModal?: ModalDialogRef<InstanceType<TmComponentConstructorWithDecision>>;

  private _destroy$: Subject<void> = new Subject();

  constructor(private _modalService: IwModalService, private _t: TranslateService) {}

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  @HostListener('click')
  public async showConfirmationDialog(): Promise<void> {
    // Prevent if element is disabled or modal is opened already
    if (!!this.disabled || !!this.currentModal) {
      return;
    }
    const data = { ...this._getDefaultModalContext(), ...this.tmDeleteConfirmDialogData };
    this.currentModal = await this._modalService.open(this.tmDeleteConfirmDialog || ModalConfirmComponent, data);

    // If something went wrong - throw error in console
    if (!this.currentModal) {
      throw new Error('Please check provided constructor in tmDeleteConfirmDialog input');
    }

    const modalIsClosed$ = merge(this.currentModal.closed, this.currentModal.dismissed);

    // Subscribe on decision results until modal is dissmised
    this.currentModal.component.decision
      .pipe(take(1), takeUntil(merge(this._destroy$, modalIsClosed$)))
      .subscribe((confirm) => {
        this.tmDeleteDecision.emit(confirm);

        if (confirm) {
          this.tmDeleteConfirm.emit();
        } else {
          this.tmDeleteReject.emit();
        }
      });

    // Delete model when it is dismissed or closed
    modalIsClosed$.pipe(take(1), takeUntil(this._destroy$)).subscribe(() => delete this.currentModal);
  }

  private _getDefaultModalContext(): any {
    const title = this._t.instant('@tm-shared.deleteBtn.defaultConfirmTitle');
    const text = this._t.instant('@tm-shared.deleteBtn.defaultConfirmTextWithItems', {
      items: this._getItemsToDeleteString(),
    });

    return { title, text };
  }
  private _getItemsToDeleteString(): string {
    if (Array.isArray(this.tmDeleteConfirmItems) && this.tmDeleteConfirmItems.length) {
      return this.tmDeleteConfirmItems.join(', ');
    }

    if (typeof this.tmDeleteConfirmItems === 'string' && this.tmDeleteConfirmItems.length) {
      return this.tmDeleteConfirmItems;
    }

    return this._t.instant('@tm-shared.deleteBtn.unknownItemName');
  }
}
