import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[tmDragndrop]',
})
export class TmDragndropDirective {
  @Output() public onFileDrop = new EventEmitter<FileList>();
  @Output() public onDragover = new EventEmitter<DragEvent>();
  @Output() public onDragleave = new EventEmitter<DragEvent>();

  @HostBinding('draggable') public draggable: true = true;

  @HostListener('dragover', ['$event']) public onDragOver(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.onDragover.emit(e);
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.onDragleave.emit(e);
  }

  @HostListener('drop', ['$event']) public ondrop(e: DragEvent): void {
    if (e.dataTransfer) {
      e.preventDefault();
      e.stopPropagation();
      let files = e.dataTransfer.files;
      if (files.length > 0) {
        this.onFileDrop.emit(files);
      }
    }
  }
}
