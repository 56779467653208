import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmColorPickerModule } from '@tm-shared/color-picker';
import { TmFormHelpersModule } from '@tm-shared/form-helpers/form-helpers.module';
import { TmGridModule } from '@tm-shared/grid';
import { TmLocalizeDateModule } from '@tm-shared/localize-date/localize-date.module';
import { TmModals } from '@tm-shared/modals';
import { TmStructureModule } from '@tm-shared/structure';
import { TmToolbarModule } from '@tm-shared/toolbar';
import LANG_DATA from './i18n';
import { TagComponent } from './tag-component/tag.component';
import { TagFormComponent } from './tag-form-component/tag-form.component';
import { ROUTES } from './tag.routes';
import { TmIconModule } from '../../@tm-shared/icons/tm-icon.module';

@NgModule({
  imports: [
    RouterModule.forChild(ROUTES),
    TmGridModule,
    TmColorPickerModule,
    TmStructureModule,
    TmToolbarModule,
    TmModals,
    ReactiveFormsModule,
    CommonModule,
    IwSharedModule,
    TmIconModule,
    TmLocalizeDateModule,
    TmFormHelpersModule,
    TranslateModule.forChild(),
  ],
  entryComponents: [],
  declarations: [TagComponent, TagFormComponent],
})
export class TagModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('ru', LANG_DATA.ru, true);
    this.translate.setTranslation('en', LANG_DATA.en, true);
  }
}
