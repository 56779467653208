import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TmFormComponent } from '@tm-shared/form';
import { TmGridComponent } from '@tm-shared/grid';
import { TmLdapServer } from '@tm-shared/ldap';
import { TmPersonSearchModel } from '@tm-shared/person-search';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LdapUserSelectService } from './ldap-user-select.service';

@Component({
  selector: 'tm-ldap-user-select',
  templateUrl: 'ldap-user-select.component.html',
  styleUrls: ['ldap-user-select.component.scss'],
  providers: [LdapUserSelectService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmLdapUserSelectComponent extends TmFormComponent<FormGroup> implements OnInit {
  public confirmDataLoss = false;

  public serverOptions$: Observable<IwSelectItem<TmLdapServer>[]>;
  public createdUser: TmApi.user.CollectionItem;

  @ViewChild(TmGridComponent, { static: false }) public set grid(grid: TmGridComponent<TmPersonSearchModel>) {
    this.service.grid = grid;
  }

  constructor(public service: LdapUserSelectService) {
    super();
  }

  public ngOnInit(): void {
    this.form = this.service.getForm();
    this.serverOptions$ = this.service.getServerOptions();
  }

  protected _onSubmit() {
    return this.service.getSubmitLogic().pipe(
      map((users: (TmApi.user.CollectionItem | null)[]) => {
        const createdUser = users.find((item) => !!item);
        if (createdUser) {
          this.createdUser = createdUser;
          this.close();
        } else {
          this.service.grid.resetSelection();
        }
        return !!createdUser;
      })
    );
  }
}
