import { Injectable } from '@angular/core';

import { CollectionService } from '@tm-shared/collection';
import { UrlStreams } from '@tm-shared/url-streams';
import { take, takeLast } from 'rxjs/operators';
import { ExportFilesModel, ExportModel, ExportMonitorURLAttributes } from '../export-monitor/export-monitor.model';

const SERVER_API = {
  url: '/api/reportExport',
};

@Injectable()
export class TmExportMonitorService extends CollectionService<ExportModel, ExportMonitorURLAttributes> {
  public src: UrlStreams<ExportMonitorURLAttributes> = UrlStreams.create(SERVER_API.url);
  public idAttribute = 'REPORT_ID';

  /**
   * Set status to export
   */
  public updateExportStatus(_state: any, data: ExportModel): void {
    this.collection$.pipe(takeLast(1)).subscribe((currentExports: ExportModel[]) => {
      if (currentExports.find((item) => item.REPORT_ID === data.REPORT_ID)) {
        this._changeExport(data, currentExports);
      } else {
        currentExports.push(data);
      }

      this._resetData(currentExports);
    });
  }

  /**
   * Set params to export
   */
  public updateExportFiles(_state: any, data: ExportFilesModel): void {
    this.collection$.pipe(take(1)).subscribe((currentExports: ExportModel[]) => {
      currentExports.forEach((item) => {
        if (item.REPORT_ID === data.report_id) {
          item.PARAMS = [data];
        }
      });
      this._resetData(currentExports);
    });
  }

  private _changeExport(exportModel: ExportModel, currentExports: ExportModel[]): ExportModel[] {
    currentExports.forEach((item) => {
      if (item.REPORT_ID === exportModel.REPORT_ID) {
        Object.assign(item, exportModel);
      }
    });

    return currentExports;
  }
}
