<ng-container *ngIf="items | async as auditEvents">
  <!-- TODO: This should be a separate component -->
  <div class="sortControl" *ngIf="canLoadMore || auditEvents.length > 1">
    <div class="sortControlTitle">{{ 'audit.auditEventList.sortControl.title' | translate }}:</div>
    <div class="sortControlButtons">
      <button
        type="button"
        [class.asc]="sortDirection === 'asc'"
        [class.desc]="sortDirection === 'desc'"
        (click)="changeSortDir()"
      >
        {{ 'audit.auditEventList.sortControl.byDate' | translate }}
      </button>
    </div>
  </div>
  <div class="auditEvents" (tmScroll)="checkIfDataShouldBeLoaded($event)" debounce="100">
    <ng-container *ngFor="let eventItem of auditEvents; trackBy: trackAuditItemBy">
      <tm-audit-item [data]="eventItem"></tm-audit-item>
    </ng-container>
    <div *ngIf="canLoadMore" class="loadMoreWrapper">
      <tm-spinner class="spinner"></tm-spinner>
    </div>
  </div>

  <tm-filler-text
    *ngIf="!canLoadMore && auditEvents.length === 0"
    text="{{ 'audit.auditEventList.noItems' | translate }}"
  ></tm-filler-text>
</ng-container>

<tm-overlay *ngIf="!items">
  <tm-spinner class="spinner"></tm-spinner>
</tm-overlay>
