/**
 * Public interface
 */
export interface TmLazyBlock {
  loader: () => Promise<string>;
  supportHtml?: boolean;
}

/**
 * Private interface
 */
export interface TmLazyBlockInternal extends TmLazyBlock {
  index: number;
  offsetTop?: number;
  height?: number;
  width?: number;
  content?: string;
  state: TmLazyBlockState;
  loading?: boolean;
  visible?: boolean;
}

/**
 * Private interface
 */
export enum TmLazyBlockState {
  error,
  untouched,
  loading,
  contentReady,
}

/**
 * Private interface
 */
export interface TmLazyBlocksUpdate {
  hasChanges: boolean;
  show: TmLazyBlockInternal[];
  hide: TmLazyBlockInternal[];
}
