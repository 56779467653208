import { Component } from '@angular/core';
import { TmElement } from '@tm-shared/custom-elements';

@TmElement('tme-d-row')
@Component({
  selector: 'tm-d-row',
  template: '<ng-content></ng-content>',
  styleUrls: ['./description-row.component.scss'],
})
export class TmDescriptionRowComponent {}
