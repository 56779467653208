import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmFormHelpersModule } from '@tm-shared/form-helpers/form-helpers.module';
import { TmGridModule } from '@tm-shared/grid';
import { TmLocalizeDateModule } from '@tm-shared/localize-date/localize-date.module';
import { ModalConfirmComponent, TmModals } from '@tm-shared/modals';
import { TmStructureModule } from '@tm-shared/structure';
import { TmToolbarModule } from '@tm-shared/toolbar';
import { TmTreeModule } from '@tm-shared/tree';
import LANG_DATA from './i18n';
import { ResourcesComponent } from './resources-component/resources.component';
import { ResourcesFormComponent } from './resources-form-component/resources-form.component';
import { ResourcesTableComponent } from './resources-table-component/resources-table.component';
import { ResourcesTableFormComponent } from './resources-table-form-component/resources-table-form.component';
import { ROUTES } from './resources.routes';
import { ResourcesService } from './resources-component/resources.service';
import { TmIconModule } from '../../@tm-shared/icons/tm-icon.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES),
    TmStructureModule,
    TranslateModule.forChild(),
    TmToolbarModule,
    TmTreeModule,
    TmGridModule,
    IwSharedModule,
    ReactiveFormsModule,
    TmModals,
    TmLocalizeDateModule,
    TmFormHelpersModule,
    TmIconModule,
  ],
  declarations: [ResourcesComponent, ResourcesTableComponent, ResourcesFormComponent, ResourcesTableFormComponent],
  providers: [ResourcesService],
  entryComponents: [ModalConfirmComponent, ResourcesFormComponent, ResourcesTableFormComponent],
})
export class ResourcesModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('ru', LANG_DATA.ru, true);
    this.translate.setTranslation('en', LANG_DATA.en, true);
  }
}
