import { NgModule } from '@angular/core';
import { TmDateRangeComponent } from './date-range.component';
import { IwSharedModule, IwPopoverModule } from '@platform/shared';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TmLocalizeDateModule } from '@tm-shared/localize-date/localize-date.module';
import { TmDateRangeCalendarComponent } from './date-range-calendar.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IwSharedModule,
    IwPopoverModule,
    TmLocalizeDateModule,
    TranslateModule,
  ],
  exports: [TmDateRangeComponent],
  declarations: [TmDateRangeComponent, TmDateRangeCalendarComponent],
})
export class TmDateRangeModule {}
