import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule, IwPopoverOptions } from '@platform/shared';
import { TmGridModule } from '@tm-shared/grid';
import { TmModals } from '@tm-shared/modals';
import { TmStructureModule } from '@tm-shared/structure';
import { TmToolbarModule } from '@tm-shared/toolbar';
import { TmTreeModule } from '@tm-shared/tree';
import { FilesComponent } from './files-component/files.component';
import { FilesTableComponent } from './files-table-component/files-table.component';
import { ROUTES } from './files.routes';
import LANG_DATA from './i18n';

@NgModule({
  imports: [
    RouterModule.forChild(ROUTES),
    CommonModule,
    ReactiveFormsModule,
    TmStructureModule,
    IwSharedModule,
    TmTreeModule,
    TmGridModule,
    TranslateModule.forChild(),
    TmToolbarModule,
    TmModals,
  ],
  declarations: [FilesComponent, FilesTableComponent],
  providers: [
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
      }),
    },
  ],
  entryComponents: [],
})
export class FilesModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('ru', LANG_DATA.ru, true);
    this.translate.setTranslation('en', LANG_DATA.en, true);
  }
}
