import { Injectable } from '@angular/core';
import { TmStatefulService } from '@tm-shared/dataloader';
import { shareReplay, take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class TmBookwormProtocolsService extends TmStatefulService<TmApi.GetResponse<TmApi.Protocol.CollectionItem[]>> {
  public src = '/api/bookworm/protocols';
  /**
   * Allows to fetch data once for all requests,
   * on locale change data should be re-fetched.
   */
  public sharedData = this.getDataStream().pipe(shareReplay(1));

  /**
   * Get localized name by event key
   */
  public getNameByKey(key: string): Observable<string> {
    return this.sharedData.pipe(
      take(1),
      map((response) => response.data.find((item) => item.protocol_id === key)?.name || key)
    );
  }
}
