import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { TmSidebarService } from './sidebar.service';

enum Direction {
  Left = 'left',
  Right = 'right',
}

enum SidebarState {
  Expanded = 'expanded',
  CollapsedLeft = 'collapsedLeft',
  СollapsedRight = 'collapsedRight',
}

@Component({
  selector: 'tm-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('contentToggle', [
      state(
        'expanded',
        style({
          'margin-left': '0',
          'margin-right': '0',
        })
      ),
      state(
        'collapsedLeft',
        style({
          'margin-left': '-100%',
        })
      ),
      state(
        'collapsedRight',
        style({
          'margin-right': '-100%',
        })
      ),
      transition('* <=> *', [animate('250ms')]),
    ]),

    trigger('sidebarToggle', [
      state(
        'collapsedLeft',
        style({
          minWidth: 0,
          width: 0,
        })
      ),
      state(
        'collapsedRight',
        style({
          width: 0,
          minWidth: 0,
        })
      ),
      transition(
        'expanded => collapsedLeft, expanded => collapsedRight',
        [
          style({ width: '{{ width }}' }),
          group([animate('250ms', style({ width: 0, minWidth: 0 })), query('@contentToggle', [animateChild()])]),
        ],
        { params: { width: '*' } }
      ),
      transition(
        'collapsedLeft => expanded, collapsedRight => expanded',
        [group([animate('250ms', style({ width: '{{ width }}' })), query('@contentToggle', [animateChild()])])],
        { params: { width: '*' } }
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmSidebarComponent implements AfterViewInit {
  @HostBinding('@sidebarToggle') public get state() {
    return {
      value: this.getToggleState(),
      params: {
        width: `${Math.ceil(this._width)}px`,
      },
    };
  }
  @ViewChild('sidebarWrap', { static: false }) public wrapEl: ElementRef;

  @ViewChild('grabber', { static: false }) public grabberEl: ElementRef;

  @Input() public title = '';

  @Input() public direction: 'left' | 'right' | '*' = 'left';

  @Input() public isCollapsed = false;

  @Input() public disabled = false;

  @ViewChild('indent', { static: true }) public indent: ElementRef;

  public animationDone$: Subject<void> = new Subject();

  public isAnimated = false;

  public toggleState: SidebarState = SidebarState.Expanded;

  private _width = 0;

  private _oldX = 0;

  private _destroy$: Subject<void> = new Subject();

  constructor(
    private sidebarService: TmSidebarService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _cdr: ChangeDetectorRef
  ) {}

  @HostListener('@sidebarToggle.start')
  public animationStart() {
    this.isAnimated = true;
  }

  @HostListener('@sidebarToggle.done')
  public animationDone() {
    this.isAnimated = false;
    this.sidebarService.actionDone({
      isCollapsed: this.isCollapsed,
      direction: this.direction,
    });
    this.animationDone$.next();
  }

  public ngAfterViewInit() {
    this.sidebarService
      .onAction(this.direction, 'start')
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (sidebar) => {
          this.toggle(sidebar.isCollapsed);
        },
      });

    this.sidebarService.title$.pipe(takeUntil(this._destroy$)).subscribe({
      next: (title) => {
        this.title = title;

        this._cdr.markForCheck();
      },
    });

    if (this.direction) {
      this._renderer.addClass(this._elementRef.nativeElement, `direction-${this.direction}`);
    }

    this._width = this._elementRef.nativeElement.offsetWidth;
    this._setSidebarWidth();
    this._setResizeListener();
    this.getToggleState();
  }

  public toggle(isCollapsed: boolean) {
    if (this.isAnimated || this.disabled) {
      return;
    }

    this.isCollapsed = isCollapsed;
    this.toggleState = this.getToggleState();
  }

  public getToggleState() {
    if (this.direction === Direction.Right) {
      return this.isCollapsed ? SidebarState.СollapsedRight : SidebarState.Expanded;
    }

    return this.isCollapsed ? SidebarState.CollapsedLeft : SidebarState.Expanded;
  }

  public onDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _setSidebarWidth() {
    this._renderer.setStyle(this._elementRef.nativeElement, 'width', `${this._width}px`);
    this._renderer.setStyle(this.wrapEl.nativeElement, 'width', `${this._width}px`);
  }

  private _checkExtremes() {
    let minWidth = parseInt(window.getComputedStyle(this._elementRef.nativeElement).minWidth || '', 10);
    let maxWidth = parseInt(window.getComputedStyle(this._elementRef.nativeElement).maxWidth || '', 10);

    if (this._width < minWidth) {
      this._width = minWidth;
    }

    if (this._width > maxWidth) {
      this._width = maxWidth;
    }
  }

  private _setResizeListener() {
    const move$ = fromEvent(document, 'mousemove');
    const down$ = fromEvent(this.grabberEl.nativeElement, 'mousedown');
    const up$ = fromEvent(document, 'mouseup');

    down$
      .pipe(
        mergeMap((event: MouseEvent) => {
          this._oldX = event.clientX;
          return move$.pipe(takeUntil(up$));
        })
      )
      .subscribe((e: MouseEvent) => {
        this._resize(e);
      });
  }

  private _resize(event: MouseEvent) {
    if (this.direction === Direction.Right) {
      this._width += this._oldX - event.clientX;
    } else {
      this._width += event.clientX - this._oldX;
    }

    this._oldX = event.clientX;

    this._checkExtremes();
    this._setSidebarWidth();
    this.sidebarService.actionDone({
      isCollapsed: false,
      direction: this.direction,
    });
  }
}
