import { Component } from '@angular/core';
import { TmSearchService } from '../../../../@tm-shared/api-services';
import { TranslateService } from '@ngx-translate/core';
import { TableTabComponentDirective } from './abstract-table-tab.component';
import { CheckboxCellComponent } from '../../../../@tm-shared/grid/cell-renderers';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { TmGridOptions } from '../../../../@tm-shared/grid';

@Component({
  templateUrl: './table.html',
  styleUrls: [],
})
export class WorkstationTabComponent extends TableTabComponentDirective {
  public static scopeToSearch: 'workstation' = 'workstation';

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: WorkstationTabComponent.scopeToSearch,
  };

  constructor(public service: TmSearchService, private _t: TranslateService) {
    super();
  }

  public options = <TmGridOptions>{
    sortingOrder: ['desc', 'asc'],
    columnDefs: [
      {
        width: 30,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        sortable: true,
        sort: 'asc',
        resizable: true,
        headerValueGetter: () => this._t.instant('settings-access.scope.fieldNames.displayName'),
      },
      {
        field: 'SOURCE',
        sortable: true,
        resizable: true,
        width: 100,
        headerValueGetter: () => this._t.instant('settings-access.search.headerNames.ldapPerson.source'),
      },
      {
        resizable: true,
        valueGetter: (params: ValueGetterParams) => {
          return params.data.real_server && params.data.DNSHOSTNAME && params.data.DNSHOSTNAME.replace(/^[^\.]*\./, '');
        },
        headerValueGetter: () => this._t.instant('settings-access.search.headerNames.ldapPerson.server'),
      },
      {
        resizable: true,
        valueGetter: (params: ValueGetterParams) => {
          return params.data.real_server && params.data.real_server.DISPLAY_NAME;
        },
        headerValueGetter: () => this._t.instant('settings-access.search.headerNames.ldapPerson.ldapServer'),
      },
    ],
  };
}
