import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TmStatefulService } from '@tm-shared/dataloader';
import { objectToFormData } from '@tm-shared/helpers/form';
import { TmTechLoaderComponentService } from '@tm-shared/tech-loader/tech-loader-component.service';
import { TmTechLoaderStatus } from '@tm-shared/tech-loader/tech-loader.model';
import { BooleanAsInteger } from 'plugins/settings-ldap/generated/adlibitum';
import { Observable, Subject, from, of } from 'rxjs';
import { catchError, finalize, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { getTechLoaderGroupKey } from '../tech-classifier-helpers';
import { ClassifierType } from '../tech-classifier-providers';

export enum FileCheckErrors {
  configurationNotFound,
}

/**
 * TODO: merge graphic and autoling in this service when JSON schema allows
 */
@Injectable()
export abstract class TmTechClassifierFileCheck<T> extends TmStatefulService<T> {
  public readonly maxParallelUploads: number = 7;

  public errors = new Subject<FileCheckErrors>();

  protected abstract type: ClassifierType;

  protected abstract techLoader: TmTechLoaderComponentService;

  protected stopCheck = new Subject<void>();

  /**
   * Total count of files to check
   */
  protected latestCheckFilesQueryLength = 0;

  public checkFiles(files: File[]): void {
    if (!files.length) {
      this.latestCheckFilesQueryLength = 0;
      return;
    }

    this.setCheckStateToProgress();

    // Update file counter (used to get all files state)
    this.latestCheckFilesQueryLength = files.length;
    // Clone array to prevent side effects
    const fileQuery = Array.from(files);
    // Sort files by size
    fileQuery.sort((f1, f2) => f1.size - f2.size);
    // Show all files in tech loader list
    this.addToTechLoader(fileQuery);

    // Upload first file to start session
    this.upload(fileQuery[0], true)
      .pipe(
        // Upload others
        switchMap(() => from(fileQuery.slice(1))),
        mergeMap((file) => this.upload(file), this.maxParallelUploads),
        takeUntil(this.stopCheck.pipe(tap(() => (this.latestCheckFilesQueryLength = 0)))),
        finalize(() => {
          this.removeFromTechLoader(fileQuery);
        })
      )
      .subscribe();
  }

  public addToTechLoader(files: File[]): void {
    const techLoaderGroup = getTechLoaderGroupKey(this.type);
    this.techLoader.addItemsToGroup(
      techLoaderGroup,
      files.map((f) => {
        return {
          title: f.name,
          key: f.name,
          status: TmTechLoaderStatus.inProgress,
        };
      })
    );
  }

  public removeFromTechLoader(files: File[]): void {
    const techLoaderGroup = getTechLoaderGroupKey(this.type);
    this.techLoader.removeItemsFromGroup(
      techLoaderGroup,
      files.map((f) => f.name)
    );
  }

  public stopCheckProcess(): Observable<unknown> {
    this.stopCheck.next();
    return this.http.delete(this.src.toString()).pipe(
      catchError(() => of(null)),
      switchMap(() => this.refresh())
    );
  }

  protected upload(file: File, openSession: boolean = false): Observable<unknown> {
    const techLoaderGroup = getTechLoaderGroupKey(this.type);
    const key = `${file.name}`;

    const payload: any = {
      'files[]': file,
    };

    if (openSession) {
      payload.newSession = BooleanAsInteger.true;
    }

    return this.http.post(this.src.toString(), objectToFormData(payload)).pipe(
      takeUntil(this.stopCheck),
      tap(() => {
        this.techLoader.updateItemInGroup(techLoaderGroup, {
          key: key,
          status: TmTechLoaderStatus.success,
        });
      }),
      catchError((e) => this.catchUploadError(e, key, techLoaderGroup))
    );
  }

  protected catchUploadError(e: HttpErrorResponse, key: string, techLoaderGroup: string): Observable<unknown> {
    /**
     * Handle "configuration not found" error
     * ref. #KRT-30370
     */
    if (e.status === 404) {
      this.errors.next(FileCheckErrors.configurationNotFound);
      return this.stopCheckProcess();
    }

    this.techLoader.updateItemInGroup(techLoaderGroup, {
      key: key,
      status: TmTechLoaderStatus.error,
    });

    return of(null);
  }

  protected abstract setCheckStateToProgress(): void;

  protected abstract setCheckStateToCancel(): void;
}
