import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { TmChannelService } from '@tm-shared/channel';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { Observable, empty, merge, of } from 'rxjs';
import { map, shareReplay, switchMap, switchMapTo } from 'rxjs/operators';

const DEFAULT_RETRY_LIMIT = 10;
/**
 * Config history service v2
 */
@Injectable()
export class TmConfigDiffService extends TmCollectionLoader<TmApi.configDiff.CollectionItem> {
  public readonly src = '/api/configDiff';

  public dataWithUpdates$: Observable<TmApi.configDiff.CollectionItem[]> = of(null).pipe(
    switchMap(() => merge(this.get().pipe(map((response) => response.data)), this._getChannelStream())),
    shareReplay(1)
  );

  constructor(_httpClient: HttpClient, @Optional() private _wsService: TmChannelService) {
    super(_httpClient);
  }

  public getDataWithUpdatesStream(
    retryInMs: number = -1,
    retryLimit: number = DEFAULT_RETRY_LIMIT,
    options?: TmApi.GetOptions
  ): Observable<TmApi.configDiff.CollectionItem[]> {
    return this.getWithRetries(retryInMs, retryLimit, options).pipe(switchMapTo(this.dataWithUpdates$));
  }

  private _getChannelStream(): Observable<TmApi.configDiff.CollectionItem[]> {
    if (!this._wsService) {
      return empty();
    }

    /**
     * TODO: backend should provide socket service which sends data on each update
     */
    return this._wsService.getChannel('service_config').pipe(
      switchMap(() => {
        this.get().subscribe();
        return empty();
      })
    );
  }
}
