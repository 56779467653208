import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, of, throwError } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { cloneJson } from '../helpers/data';
import { UrlStreams } from '../url-streams';

/**
 * @deprecated in favor of DataLoader
 */
@Injectable()
export abstract class CollectionService<Model = any, Params = any> {
  /**
   * Id attribute of model
   */
  public abstract readonly idAttribute: string;

  /**
   * UrlStreams
   */
  public abstract src: UrlStreams<Params>;
  public activeRequests$: Observable<number> = of(null).pipe(switchMap(() => this._activeRequests$));

  /**
   * Stream: is data loading
   */
  public loading$: Observable<boolean> = this.activeRequests$.pipe(map((tasks) => tasks > 0));
  public data$ = <Observable<TmShared.collection.DataWithMeta<Model>>>of(null).pipe(
    switchMap(() => this._data$),
    tap(() => {
      // Request data at least once
      if (this._dataWasNotRequested()) {
        this.fetch().subscribe();
      }
    }),
    filter((x) => x !== null)
  );

  /**
   * This stream returns data only whent it's loaded
   */
  public dataStable$: Observable<TmShared.collection.DataWithMeta<Model>> = combineLatest(
    this.data$,
    this.loading$
  ).pipe(
    filter(([response, loading]) => !!response && !loading),
    map(([response]) => {
      return {
        data: this.deserializeData(response.data),
        meta: response.meta || {},
      };
    }),
    shareReplay(1)
  );

  /**
   * Collection data stream
   */
  public collection$: Observable<Model[]> = this.dataStable$.pipe(map((x) => x.data));

  public errors$: Observable<HttpErrorResponse> = of(null).pipe(switchMap(() => this._errors$));

  /**
   * Metadata stream
   */
  public metadata$: Observable<TmShared.collection.Meta> = this.dataStable$.pipe(
    map((response: TmShared.collection.DataWithMeta<Model>) => {
      return response.meta || {};
    })
  );

  /**
   * Stream: active requests
   */
  protected _activeRequests$: BehaviorSubject<number> = new BehaviorSubject(0);

  /**
   * Complete server response with the latest valid data (errors are ignored)
   */
  protected _data$: BehaviorSubject<TmShared.collection.DataWithMeta<Model> | null> = new BehaviorSubject(null);

  /**
   * Errors stream
   */
  protected _errors$: ReplaySubject<HttpErrorResponse> = new ReplaySubject(1);

  constructor(protected _http: HttpClient) {}

  public deserializeData(responseData: any): Model[] {
    return responseData;
  }

  public deserializeModel(model: any): Model {
    return model;
  }

  public serializeData(data: AnyKeysFrom<Model>[]): any {
    return data;
  }

  public serializeModel(model: AnyKeysFrom<Model>): any {
    return model;
  }

  /**
   * Create new collection item
   */
  public create(data: AnyKeysFrom<Model>): Observable<{ data: Model }> {
    const url = `${this.src.baseUrl}`;

    let stream$ = this._http.post(url, this.serializeModel(data)).pipe(
      catchError(this._catchErrorResponse.bind(this)),
      tap((response: { data: Model }) => {
        let id = (response.data as any)[this.idAttribute];
        this._setModelDataById(id, response.data);
      })
    );

    return this.wrapWithLoadingState(stream$);
  }

  /**
   * Fetch collection
   */
  public fetch(): Observable<TmShared.collection.DataWithMeta<Model>> {
    let request$ = this._http.get(this.src.url).pipe(
      catchError(this._catchErrorResponse.bind(this)),
      tap((response: TmShared.collection.DataWithMeta<Model>) => this._data$.next(response))
    );

    return this.wrapWithLoadingState(request$);
  }

  /**
   * Get collection item by ID
   */
  public getById(id: string | number): Observable<Model | null> {
    return this.collection$.pipe(
      map((collection: Model[]) => {
        let model = collection.find((m: any) => m[this.idAttribute] === id) || null;
        return cloneJson(model);
      })
    );
  }

  public isModelEditable(_model: Model | Model[]): boolean {
    return true;
  }

  /**
   * Remove collection item
   */
  public remove(id: string | number): Observable<any> {
    const url = `${this.src.baseUrl}/${id}`;

    let stream$ = this._http.delete(url).pipe(
      catchError(this._catchErrorResponse.bind(this)),
      tap(() => {
        let curData = <TmShared.collection.DataWithMeta<Model>>this._data$.getValue();
        let nextData: Model[] = curData.data.filter((item: any) => {
          return item[this.idAttribute] !== id;
        });
        this._resetData(nextData);
      })
    );

    return this.wrapWithLoadingState(stream$);
  }

  /**
   * Update collection item
   */
  public updateById(id: any, data: AnyKeysFrom<Model>): Observable<{ data: Model }> {
    const url = `${this.src.baseUrl}/${id}`;
    let curData = this._getById(id);
    let serializedPatch = this.serializeModel(data);

    let stream$ = this._http.put(url, serializedPatch).pipe(
      catchError(this._catchErrorResponse.bind(this)),
      tap((response: { data: Model }) => {
        this._setModelDataById(id, Object.assign({}, curData, response.data || serializedPatch));
      })
    );

    return this.wrapWithLoadingState(stream$);
  }

  /**
   * Wrap stream with loading counter
   */
  public wrapWithLoadingState<T>(stream$: Observable<T>): Observable<T> {
    return of(true).pipe(
      tap(() => this._loadingIncrease()),
      switchMapTo(stream$),
      tap(() => this._loadingDecrease()),
      catchError((e) => {
        this._loadingDecrease();
        return throwError(e);
      })
    );
  }

  /**
   * Send error to errors$ stream, return Observable with the error
   */
  protected _error(e: HttpErrorResponse): Observable<HttpErrorResponse> {
    this._errors$.next(e);
    return this.errors$;
  }

  /**
   * Check if data was never requested
   */
  protected _dataWasNotRequested(): boolean {
    return this._data$.getValue() === null && !this._activeRequests$.getValue();
  }

  /**
   * Get collection item by ID
   */
  protected _getById(id: string | number): Model | null {
    let response = this._data$.getValue();

    if (!response || !response.data) {
      return null;
    }

    return response.data.find((m: any) => m[this.idAttribute] === id) || null;
  }

  /**
   * Subtract 1 to loading counter
   */
  protected _loadingDecrease(): void {
    this._activeRequests$.next(this._activeRequests$.getValue() - 1);
  }

  /**
   * Add 1 to loading counter
   */
  protected _loadingIncrease(): void {
    this._activeRequests$.next(this._activeRequests$.getValue() + 1);
  }

  /**
   * Check response for errors, use with switchMap(...)
   */
  protected _catchErrorResponse(response: HttpErrorResponse): Observable<HttpErrorResponse> {
    this._error(response);
    return throwError(response);
  }

  /**
   * Set collection item by id
   */
  protected _setModelDataById(id: string, modelData: Model | null): void {
    let curData = this._data$.getValue();

    if (!curData) {
      return;
    }

    let nextData = Object.assign(curData, {
      data: curData.data.map((model: any) => {
        return model[this.idAttribute] === id ? Object.assign({}, model, modelData) : model;
      }),
    });

    this._data$.next(nextData);
  }

  /**
   * Reset data manually
   */
  protected _resetData(collection: Model[]): void {
    if (!Array.isArray(collection)) {
      throw new Error('Invalid data, should be an Array');
    }

    let curResponse = this._data$.getValue();
    let curMeta = (curResponse ? curResponse.meta : null) || null;

    // Update data length
    let nextMeta = Object.assign({}, curMeta, {
      totalCount: collection.length,
    });

    this._data$.next({
      data: collection,
      meta: nextMeta,
    });
  }
}
