import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { TmContextMenuItem, TmContextMenuPosition } from './context-menu.model';

@Component({
  selector: 'tm-context-menu',
  templateUrl: 'context-menu.component.html',
  styleUrls: ['context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmContextMenuComponent {
  @ViewChild('childRef', { static: false }) public childRef: ElementRef;
  @ViewChild('subMenuRef', { static: false }) public subMenuRef: ElementRef;

  @Input() public position: TmContextMenuPosition;
  @Input() public items: TmContextMenuItem[];
  @Input() public context: any;

  // Fired when menu closed
  @Output() public onClose = new EventEmitter();

  /**
   * Fn close menu
   *
   * @memberof TmContextMenuComponent
   */
  public close(): void {
    this.onClose.emit();
  }

  /**
   * Close sub menu if current item hasn`t sub menu
   *
   * @param {TmContextMenuItem} item
   * @returns {void}
   * @memberof TmContextMenuComponent
   */
  public closeSubMenu(item: TmContextMenuItem): void {
    if (item.subMenu || !this.subMenuRef) {
      return;
    }

    let event = new CustomEvent('closemenu');

    this.subMenuRef.nativeElement.dispatchEvent(event);
  }

  /**
   * Fn click event
   *
   * @param {TmContextMenuItem} item
   * @returns {void}
   * @memberof TmContextMenuComponent
   */
  public onClick(item: TmContextMenuItem): void {
    if (this.isItemDisabled(item)) {
      return;
    }

    if (item.onClick) {
      this.close();

      item.onClick(this.context, item);
    }
  }

  /**
   * Get ng-class item
   *
   * @param {TmContextMenuItem} item
   * @returns {*}
   * @memberof TmContextMenuComponent
   */
  public getClassItem(item: TmContextMenuItem): any {
    return {
      'tm-context-menu-item': !item.divider,
      'tm-context-menu-divider': item.divider,
      'tm-context-menu-disabled': this.isItemDisabled(item),
    };
  }

  /**
   * Formatting menu item
   *
   * @param {TmContextMenuItem} item
   * @returns {string}
   * @memberof TmContextMenuComponent
   */
  public getLabel(item: TmContextMenuItem): string {
    let label = item.label;

    if (item.subMenu) {
      label += `<div class="tm-context-menu-arrow"></div>`;
    }

    return label;
  }

  /**
   * Check disabled menu item
   *
   * @param {TmContextMenuItem} item
   * @returns {boolean}
   * @memberof TmContextMenuComponent
   */
  public isItemDisabled(item: TmContextMenuItem): boolean {
    if (!item.disabled) {
      return false;
    }

    return item.disabled(this.context, item);
  }
}
