<tm-page>
  <tm-sidebar title="{{ 'lists-resources.title' | translate }}">
    <tm-toolbar class="toolbar" data-qa="toolbar">
      <button
        iw-icon-button
        [disabled]="!(canCreateResourcesGroup$ | async)"
        iwPopover="{{ 'lists-resources.toolbar.create' | translate }}"
        [triggers]="'mouseenter:mouseleave'"
        data-qa="createToolbarButton"
        (click)="showDialogCreateGroup()"
      >
        <tm-icon svgIcon="create"></tm-icon>
      </button>

      <button
        iw-icon-button
        [disableClickEventOnly]="!(canEditResourcesGroup$ | async)"
        [iwPopover]="tooltipPopover"
        [triggers]="'mouseenter:mouseleave'"
        [context]="{ message: editMessage$ | async }"
        data-qa="editToolbarButton"
        (click)="showDialogEditGroup()"
      >
        <tm-icon svgIcon="edit"></tm-icon>
      </button>

      <button
        iw-icon-button
        data-qa="deleteToolbarButton"
        (click)="showDialogRemoveGroup()"
        [iwPopover]="tooltipPopover"
        [closeOnMousemoveOutside]="true"
        [context]="{ message: removeMessage$ | async }"
        [triggers]="'mouseenter:mouseleave'"
        [disableClickEventOnly]="!(canRemoveResourcesGroup$ | async)"
      >
        <tm-icon svgIcon="delete"></tm-icon>
      </button>

      <button iw-icon-button #actionsPopover="iwPopover" [iwPopover]="otherActionsPopover" placement="bottom-start">
        <tm-icon svgIcon="list"></tm-icon>
      </button>
    </tm-toolbar>

    <tm-tree
      class="tree"
      data-qa="resources-tabs-tree"
      [data]="treeData$ | async"
      [treeItemLinkParams]="{ replaceUrl: true }"
    >
    </tm-tree>
  </tm-sidebar>

  <tm-content [title]="title$ | async">
    <router-outlet></router-outlet>
  </tm-content>
</tm-page>

<ng-template #otherActionsPopover>
  <div class="dropdown">
    <button [disabled]="!(canRestoreResourcesGroup$ | async)" data-qa="restoreToolbarButton" (click)="restore()">
      {{ 'lists-resources.toolbar.restore' | translate }}
    </button>
  </div>
</ng-template>

<!-- @translate lists-resources.toolbar.create -->
<!-- @translate lists-resources.toolbar.edit -->
<!-- @translate lists-resources.toolbar.delete -->
<!-- @translate lists-resources.toolbar.undeletable -->
<!-- @translate lists-resources.toolbar.uneditable -->
<!-- @translate lists-resources.toolbar.root -->
<ng-template #tooltipPopover let-message="message">{{ message | translate }}</ng-template>
