import { Component, OnInit } from '@angular/core';
import { TmAuditEventListService } from './audit-event-list.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AuditEvent } from '../audit.model';
import { Subject } from 'rxjs';
import { isBelowOffset } from '@tm-shared/scroll/scroll-helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tm-audit-event-list',
  styleUrls: ['audit-event-list.component.scss'],
  templateUrl: 'audit-event-list.component.html',
})
export class TmAuditEventListComponent implements OnInit {
  public items = this.service.dataToShow;

  public loading = false;

  public sortDirection: 'asc' | 'desc' = this.service.sortDirection;

  public get canLoadMore(): boolean {
    return this.service.canLoadMore;
  }

  private toggleSortDir = new Subject<void>();

  constructor(private service: TmAuditEventListService) {}

  public ngOnInit(): void {
    /**
     * Load initial chunk
     */
    this.loadMore();

    /**
     * Listen to sort dir trigger, decorate loading state with spinner.
     * Previous audit events fetch request is canceled on sort.
     */
    this.toggleSortDir
      .pipe(
        switchMap(() => this.service.toggleSortDir().pipe(takeUntil(this.toggleSortDir))),
        untilDestroyed(this)
      )
      .subscribe((dir) => (this.sortDirection = dir));
  }

  /**
   * Track changes by id attribute
   */
  public trackAuditItemBy(_i: number, item: AuditEvent): number {
    return item.AUDIT_LOG_ID;
  }

  public changeSortDir(): void {
    this.toggleSortDir.next();
  }

  /**
   * Scroll callback
   */
  public checkIfDataShouldBeLoaded = (el: HTMLElement): void => {
    /**
     * Do not load next chunk if there is no next chunk or if user is over the threshold value
     */
    if (!this.service.canLoadMore || !isBelowOffset(el, el.clientHeight * 2)) {
      return;
    }

    this.loadMore();
  };

  private loadMore(): void {
    /**
     * Do not interrupt loading process on scrolling
     */
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.service.loadMore().subscribe({
      complete: () => (this.loading = false),
    });
  }
}
