import { Injectable, Injector, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { tmCustomElementTagKey } from './custom-element-tag.decorator';

/**
 * TmCustomElement extends the default angular component class
 */
export interface TmCustomElement extends Type<unknown> {
  [tmCustomElementTagKey]: string;
}

export const TMNG_CUSTOM_ELEMENTS_PREFIX = 'tme-';

@Injectable()
export class TmCustomElementsService {
  private static toBeRegistered: TmCustomElement[] = [];

  public registry: CustomElementRegistry = customElements;

  /**
   * Register all elements before TmCustomElementsService is created in the Injector
   */
  public static setup(constructor: TmCustomElement): void {
    TmCustomElementsService.toBeRegistered.push(constructor);
  }

  constructor(private _injector: Injector) {
    /**
     * Run the queue
     */
    this.addMany(TmCustomElementsService.toBeRegistered);

    /**
     * Support registration of the elements in the runtime
     */
    TmCustomElementsService.setup = (x) => this.add(x);
  }

  private add(tmElementConstructor: TmCustomElement): void {
    const tag: string = tmElementConstructor[tmCustomElementTagKey];
    const errors = this._getErrors(tmElementConstructor);

    if (errors) {
      throw new Error(errors.join('\n'));
    }

    this.registry.define(
      tag,
      createCustomElement(tmElementConstructor, {
        injector: this._injector,
      })
    );
  }

  private addMany(tmElements: TmCustomElement[]): void {
    tmElements.forEach((o) => this.add(o));
  }

  public get(tag: string | TmCustomElement): FunctionConstructor {
    if (typeof tag === 'string') {
      return this.registry.get(tag);
    }

    return this.registry.get(tag[tmCustomElementTagKey]);
  }

  private _getErrors(tmElementConstructor: TmCustomElement): null | string[] {
    const tag: string = tmElementConstructor[tmCustomElementTagKey];
    const errors: string[] = [];

    if (!tag) {
      errors.push('Tag is not defined. Use @TmCustomElement(tagname) decorator on provided constructor.');
    }

    if (!new RegExp(`^${TMNG_CUSTOM_ELEMENTS_PREFIX}.+`).test(tag)) {
      errors.push(`For custom elements you should use '${TMNG_CUSTOM_ELEMENTS_PREFIX}' prefix \
to avoid tagname collision with common selectors`);
    }

    if (this.get(tag)) {
      errors.push(`You cannot redefine custom elements, '${tag}' exists`);
    }

    return errors.length ? errors : null;
  }
}
