<div
  #dropdown="iwPopover"
  [ngClass]="{ button: true, isOpened: dropdown.isVisible | async }"
  [iwPopover]="exportMonitor"
  container="body"
  #exportMonitorButton
>
  <div>
    <i *ngIf="complete">NEW</i>
    <i *ngIf="length">SPINNER</i>
  </div>
  {{ 'events.menu.export' | translate }}
  <span *ngIf="length" class="count">{{ length }}</span>
  <i class="caret"></i>
</div>

<ng-template #exportMonitor>
  <div class="exportMonitor dropdown">
    <header data-search class="header">
      <h2>{{ 'events.exportMonitor.title' | translate }}</h2>
      <p>{{ 'events.exportMonitor.export_store_time_note' | translate }}</p>
    </header>
    <div>
      <tm-export-monitor-list></tm-export-monitor-list>
    </div>
  </div>
</ng-template>
