import { TranslateService } from '@ngx-translate/core';
import { AuditExtendGridData, AuditExtendRowData, AuditGridDataColumn } from '../audit-extend.service';
import {
  VisibleAttributesOption,
  getAuditExtendRowData,
  getAuditAttributesFrom,
  TransformOptions,
  transformBooleanYesNo,
  FilterFnCols,
  VisibleAttributesFilterFn,
} from './audit-common';
import { AuditEvent, AuditEventOperation } from '../audit.model';
import {
  QueryDiffAuditLog,
  QueryPrivilege,
  QueryJson,
  ChildCondition,
  CombinedCondition,
  QueryParent,
} from 'typings/generated/audit-event-query';
import { TmAuditBrItemsCellComponent } from '../audit-cell-components/audit-br-items.component';
import { TmAuditConditionGroupComponent } from '../audit-cell-components/audit-condition-group.component/audit-condition-group.component';
import { pick, isEqual } from 'lodash';

const auditQueryBasicVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'DISPLAY_NAME', i18nKey: 'audit.auditQuery.attrs.DISPLAY_NAME' },
  { path: 'NOTE', i18nKey: 'audit.auditQuery.attrs.NOTE' },
  { path: 'IS_SHARED', i18nKey: 'audit.auditQuery.attrs.query.isShared', transform: transformBooleanYesNo },
  { path: 'parent', i18nKey: 'audit.auditQuery.attrs.query.parent', transform: transformParentCatalog },
  {
    path: 'FORCE_PRIVILEGES',
    i18nKey: 'audit.auditQuery.attrs.query.forcePrivileges',
    transform: transformBooleanYesNo,
  },
];

export function transformQueryMode(queryMode: QueryJson['mode'], o: TransformOptions): string {
  return queryMode === 'advanced'
    ? o.t.instant('audit.auditQuery.attrs.queryModes.advanced')
    : o.t.instant('audit.auditQuery.attrs.queryModes.lite');
}

export function transformParentCatalog(data: QueryParent | undefined, o: TransformOptions): string {
  return (data?.DISPLAY_NAME || o.t.instant('audit.auditQuery.attrs.root')) as string;
}

const auditQueryColumnsVisibleAttributes: VisibleAttributesOption[] = [
  { path: 'QUERY.sort', i18nKey: 'audit.auditQuery.attrs.query.sortAttr', transform: transformSortAttr },
  { path: 'QUERY.sort', i18nKey: 'audit.auditQuery.attrs.query.sortDirection', transform: transformSortDir },
  { path: 'QUERY.columns', i18nKey: 'audit.auditQuery.attrs.query.visibleColumns', transform: transformColumns },
];

export function getEventQueryGridData(data: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  const attrs = getAuditAttributesFrom(data) as NonNullable<QueryDiffAuditLog['PROPERTY_CHANGES']>;

  if (!attrs) {
    return [];
  }

  const result: AuditExtendGridData[] = [];

  /**
   * Set correct columns
   */
  let cols: (AuditGridDataColumn.old | AuditGridDataColumn.new)[] = [];
  switch (data.OPERATION) {
    case AuditEventOperation.delete:
      cols = [AuditGridDataColumn.old];
      break;
    case AuditEventOperation.run:
    case AuditEventOperation.create:
      cols = [AuditGridDataColumn.new];
      break;
    default:
      cols = [AuditGridDataColumn.old, AuditGridDataColumn.new];
      break;
  }

  /**
   * Show base attributes
   */
  const baseData = getAuditExtendRowData({
    t,
    cols: cols,
    data: attrs,
    visibleAttributes: auditQueryBasicVisibleAttributes,
  });

  if (baseData.length) {
    result.push({
      cols: cols,
      data: baseData,
    });
  }

  /**
   * Show query details
   */
  const queryGridData = getQueryGridData(attrs, cols, t);

  if (queryGridData) {
    result.push(queryGridData);
  }

  /**
   * Show columns
   */
  if (cols.some((key) => attrs[key]?.QUERY?.columns || attrs[key]?.QUERY?.sort)) {
    const colData = getAuditExtendRowData({
      t,
      cols: cols,
      data: attrs,
      visibleAttributes: auditQueryColumnsVisibleAttributes,
    });

    if (colData.length) {
      result.push({
        title: t.instant('audit.auditQuery.columnsTitle'),
        cols: cols,
        data: colData,
      });
    }
  }

  /**
   * Show privileges by user
   */
  const privilegesGridData = getPrivilegesGridData(attrs, cols, t);

  if (privilegesGridData) {
    result.push(privilegesGridData);
  }

  return result;
}

export function transformSortAttr(value: Record<string, string> | null | undefined, o: TransformOptions): string {
  return value ? translateCategoryOrGroup(Object.keys(value)[0], o.t) : '';
}

export function translateCategoryOrGroup(category: string, t: TranslateService): string {
  /**
   * @translate audit.auditQuery.attrs.query.columns.analysis
   * @translate audit.auditQuery.attrs.query.columns.application
   * @translate audit.auditQuery.attrs.query.columns.application_from
   * @translate audit.auditQuery.attrs.query.columns.application_to
   * @translate audit.auditQuery.attrs.query.columns.attachment_count
   * @translate audit.auditQuery.attrs.query.columns.capture_date
   * @translate audit.auditQuery.attrs.query.columns.capture_server_hostname
   * @translate audit.auditQuery.attrs.query.columns.crawler_destination_type
   * @translate audit.auditQuery.attrs.query.columns.create_date
   * @translate audit.auditQuery.attrs.query.columns.destination_id
   * @translate audit.auditQuery.attrs.query.columns.destination_path
   * @translate audit.auditQuery.attrs.query.columns.destination_type
   * @translate audit.auditQuery.attrs.query.columns.device_id
   * @translate audit.auditQuery.attrs.query.columns.device_name
   * @translate audit.auditQuery.attrs.query.columns.device_path
   * @translate audit.auditQuery.attrs.query.columns.device_type
   * @translate audit.auditQuery.attrs.query.columns.documents
   * @translate audit.auditQuery.attrs.query.columns.emitter
   * @translate audit.auditQuery.attrs.query.columns.file_format
   * @translate audit.auditQuery.attrs.query.columns.file_name
   * @translate audit.auditQuery.attrs.query.columns.file_size
   * @translate audit.auditQuery.attrs.query.columns.forward_state_code
   * @translate audit.auditQuery.attrs.query.columns.lists
   * @translate audit.auditQuery.attrs.query.columns.mac_level
   * @translate audit.auditQuery.attrs.query.columns.modify_date
   * @translate audit.auditQuery.attrs.query.columns.object_error_exists
   * @translate audit.auditQuery.attrs.query.columns.object_header
   * @translate audit.auditQuery.attrs.query.columns.object_id
   * @translate audit.auditQuery.attrs.query.columns.object_size
   * @translate audit.auditQuery.attrs.query.columns.object_type_code
   * @translate audit.auditQuery.attrs.query.columns.perimeter_in
   * @translate audit.auditQuery.attrs.query.columns.perimeter_out
   * @translate audit.auditQuery.attrs.query.columns.policies
   * @translate audit.auditQuery.attrs.query.columns.protected_documents
   * @translate audit.auditQuery.attrs.query.columns.protocol
   * @translate audit.auditQuery.attrs.query.columns.recipient_count
   * @translate audit.auditQuery.attrs.query.columns.recipients
   * @translate audit.auditQuery.attrs.query.columns.resources
   * @translate audit.auditQuery.attrs.query.columns.rule_group_type
   * @translate audit.auditQuery.attrs.query.columns.service_code
   * @translate audit.auditQuery.attrs.query.columns.scanning_target
   * @translate audit.auditQuery.attrs.query.columns.senders
   * @translate audit.auditQuery.attrs.query.columns.sent_date
   * @translate audit.auditQuery.attrs.query.columns.source_destination_copy
   * @translate audit.auditQuery.attrs.query.columns.source_id
   * @translate audit.auditQuery.attrs.query.columns.source_name
   * @translate audit.auditQuery.attrs.query.columns.source_path
   * @translate audit.auditQuery.attrs.query.columns.source_type
   * @translate audit.auditQuery.attrs.query.columns.subject
   * @translate audit.auditQuery.attrs.query.columns.tags
   * @translate audit.auditQuery.attrs.query.columns.task_name
   * @translate audit.auditQuery.attrs.query.columns.task_run_date
   * @translate audit.auditQuery.attrs.query.columns.text
   * @translate audit.auditQuery.attrs.query.columns.user_decision
   * @translate audit.auditQuery.attrs.query.columns.verdict
   * @translate audit.auditQuery.attrs.query.columns.violation_level
   * @translate audit.auditQuery.attrs.query.columns.web_resource
   * @translate audit.auditQuery.attrs.query.columns.workstation_type
   * @translate audit.auditQuery.attrs.query.columns.workstations
   */
  return t.instant(`audit.auditQuery.attrs.query.columns.${category.toLowerCase()}`);
}

export function transformSortDir(value: Record<string, string> | null | undefined, o: TransformOptions): string {
  /**
   * @translate audit.auditQuery.attrs.query.sortDirectionKeys.desc
   * @translate audit.auditQuery.attrs.query.sortDirectionKeys.asc
   */
  return value ? o.t.instant(`audit.auditQuery.attrs.query.sortDirectionKeys.${Object.values(value)[0]}`) : '';
}

export function transformColumns(
  columns: { type: string; key: string }[] | undefined | null,
  o: TransformOptions
): string {
  // Tranlation uses the same keys as in transformSortAttr
  return columns
    ? columns.map((value) => o.t.instant(`audit.auditQuery.attrs.query.columns.${value.key.toLowerCase()}`)).join(', ')
    : '';
}

type QueryColumnData = { [key: string]: CombinedCondition | ChildCondition };
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getQueryGridData(
  attrs: NonNullable<QueryDiffAuditLog['PROPERTY_CHANGES']>,
  cols: (AuditGridDataColumn.old | AuditGridDataColumn.new)[],
  t: TranslateService
): AuditExtendGridData | null {
  let auditQueryVisibleAttributes: VisibleAttributesOption[] = [
    {
      path: 'QUERY.mode',
      i18nKey: 'audit.auditQuery.attrs.queryMode',
      transform: transformQueryMode,
    },
  ];

  /**
   * Counter for row types
   */
  const sharedCategoryCounter = new Map<string, number>();
  const uniqueOperatorPath = `___linkOperator`;
  const dataLinkAndLocalized = t.instant(`audit.auditQuery.attrs.queryOperators.and`) as string;
  const dataLinkOrLocalized = t.instant(`audit.auditQuery.attrs.queryOperators.or`) as string;

  /**
   * Remap data and pick root level link operator
   */
  const queryData = cols.reduce((result, colKey) => {
    const originalQueryData = attrs[colKey]?.QUERY?.data;
    const columnRowCategoryCounter = new Map<string, number>();

    /**
     * Create row for each child and insert operator value between each
     */
    if (originalQueryData?.children) {
      const gridColumnData = (result[colKey] ? result[colKey] : (result[colKey] = {})) as QueryColumnData;

      /**
       * Each child may be a condition or a group of conditions
       */
      for (let i = 0; i < originalQueryData.children.length; i++) {
        const groupOrCondition = originalQueryData.children[i];
        /**
         * Always push a link operator row between each row
         */
        if (i !== 0) {
          gridColumnData[uniqueOperatorPath] = originalQueryData;
        }

        /**
         * Track category count to keep correct rows length (check if we need to add visibleAttributes)
         */
        let rowCategory: string;
        if ('link_operator' in groupOrCondition) {
          rowCategory = 'group';
        } else if (groupOrCondition.category === 'object_header') {
          // Object header may contain different attributes, based on it's name property
          rowCategory = groupOrCondition.value?.name || groupOrCondition.category;
        } else {
          rowCategory = groupOrCondition.category;
        }

        // Operate with column counter
        if (!columnRowCategoryCounter.has(rowCategory)) {
          columnRowCategoryCounter.set(rowCategory, 0);
        }
        let currentRowsOfCategory = columnRowCategoryCounter.get(rowCategory) as number;
        /**
         * Push row with original data by its type + index
         */
        const dataPath = `${rowCategory} ${currentRowsOfCategory}`;
        gridColumnData[dataPath] = groupOrCondition;

        columnRowCategoryCounter.set(rowCategory, ++currentRowsOfCategory);

        // Operate with table (shared) counter
        if (!sharedCategoryCounter.has(rowCategory)) {
          sharedCategoryCounter.set(rowCategory, 0);
        }
        let sharedRowCategoryCounter = sharedCategoryCounter.get(rowCategory) as number;

        if (sharedRowCategoryCounter >= currentRowsOfCategory) {
          continue;
        }

        sharedCategoryCounter.set(rowCategory, ++sharedRowCategoryCounter);

        if ('link_operator' in groupOrCondition) {
          auditQueryVisibleAttributes.push({
            path: dataPath,
            i18nKey: `audit.auditQuery.attrs.query.columns.conditionGroup`,
            component: TmAuditConditionGroupComponent,
            filter: getFilterForQueryAttr(uniqueOperatorPath),
          });
        } else {
          auditQueryVisibleAttributes.push({
            path: dataPath,
            i18nKey: `audit.auditQuery.attrs.query.columns.${rowCategory.toLowerCase()}`,
            component: TmAuditConditionGroupComponent,
            filter: getFilterForQueryAttr(uniqueOperatorPath),
          });
        }
      }
    }

    return result;
  }, {} as { [key in AuditGridDataColumn.new | AuditGridDataColumn.old]?: QueryColumnData });

  auditQueryVisibleAttributes = auditQueryVisibleAttributes.reduce((result, value, i) => {
    if (i > 0) {
      result.push({
        path: uniqueOperatorPath,
        name: '',
        transform: (data?: CombinedCondition) =>
          data ? (data.link_operator === 'or' ? dataLinkOrLocalized : dataLinkAndLocalized) : '',
        filter: false,
      });

      result.push(value);
    }

    return result;
  }, [] as VisibleAttributesOption[]);

  function isLinkOp(data?: AuditExtendRowData): boolean {
    if (!data) {
      return false;
    }
    return (data.old || data.new) === dataLinkOrLocalized || (data.old || data.new) === dataLinkAndLocalized;
  }

  let data = getAuditExtendRowData({
    t,
    cols,
    data: queryData,
    visibleAttributes: auditQueryVisibleAttributes,
  });

  /**
   * Remove first, last and sequential link operators
   */
  data = data.reduce((result, row, i) => {
    const prevElement = result[result.length - 1];
    const isFirstElement = prevElement === undefined;
    const isLastElement = i - 1 === data.length;

    if (isLinkOp(row) && (isFirstElement || isLastElement || isLinkOp(prevElement))) {
      return result;
    }

    result.push(row);

    return result;
  }, [] as AuditExtendRowData[]);

  for (let i = 0; i < data.length; i++) {
    const rowData = data[i];
    const prevRowData = data[i - 1];

    if (
      ((rowData.old || rowData.new) === dataLinkOrLocalized || (rowData.old || rowData.new) === dataLinkAndLocalized) &&
      ((prevRowData.old || prevRowData.new) === dataLinkOrLocalized ||
        (prevRowData.old || prevRowData.new) === dataLinkAndLocalized)
    ) {
    }
  }

  if (!data.length) {
    return null;
  }

  return {
    title: t.instant('audit.auditQuery.queryTitle'),
    cols,
    data,
  };
}

function getFilterForQueryAttr(uniqueOperatorPath: string): VisibleAttributesFilterFn {
  return function (cols: FilterFnCols) {
    return (
      // Check if operator is changed
      pick(cols[AuditGridDataColumn.new], uniqueOperatorPath) !==
        pick(cols[AuditGridDataColumn.old], uniqueOperatorPath) ||
      // Check if value is changed
      !isEqual(cols[AuditGridDataColumn.new], cols[AuditGridDataColumn.old])
    );
  };
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function getPrivilegesGridData(
  attrs: NonNullable<QueryDiffAuditLog['PROPERTY_CHANGES']>,
  cols: (AuditGridDataColumn.old | AuditGridDataColumn.new)[],
  t: TranslateService
): AuditExtendGridData | null {
  const auditQueryPrivilegeVisibleAttributes: VisibleAttributesOption[] = [];
  const privilegesData = cols.reduce((result, colKey) => {
    const privileges = attrs[colKey]?.privileges;

    if (!privileges) {
      return result;
    }

    if (!result[colKey]) {
      result[colKey] = {};
    }

    /**
     * We need to display a row for each user,
     * so we generate dynamically generate visible attributes.
     */
    for (let i = 0; i < privileges.length; i++) {
      const privilege = privileges[i];
      const userName = privilege.user?.USERNAME || '';
      const resultColData = result[colKey];

      if (!resultColData || !privilege.user) {
        continue;
      }

      /**
       * Push row for user
       */
      if (!((result.new && userName in result.new) || (result.old && userName in result.old))) {
        auditQueryPrivilegeVisibleAttributes.push({
          path: `${userName}`,
          name: `${privilege.user.DISPLAY_NAME} (${userName})`,
          transform: transformQueryPrivilege,
          component: TmAuditBrItemsCellComponent,
          filter: 'deepEqual',
        });
      }

      /**
       * Push data for row
       */
      if (!resultColData[userName]) {
        resultColData[userName] = [privilege];
      } else {
        resultColData[userName].push(privilege);
      }
    }

    return result;
  }, {} as { [key in AuditGridDataColumn.new | AuditGridDataColumn.old]?: { [key: string]: QueryPrivilege[] } });

  if (Object.keys(privilegesData).length) {
    return {
      title: t.instant('audit.auditQuery.privilegesTitle'),
      cols: cols,
      data: getAuditExtendRowData({
        t,
        cols: cols,
        data: privilegesData,
        visibleAttributes: auditQueryPrivilegeVisibleAttributes,
      }),
    };
  }

  return null;
}

export function transformQueryPrivilege(
  privileges: QueryPrivilege[] | null | undefined,
  o: TransformOptions
): string[] {
  const canRead = !!privileges?.some((p) => p.PRIVILEGE_CODE === 'read');
  const canWrite = !!privileges?.some((p) => p.PRIVILEGE_CODE === 'write');
  const yes = o.t.instant('audit.auditQuery.common.yes');
  const no = o.t.instant('audit.auditQuery.common.no');

  return [
    `${o.t.instant('audit.auditQuery.attrs.privilegeCanRead')} (${canRead ? yes : no})`,
    `${o.t.instant('audit.auditQuery.attrs.privilegeCanWrite')} (${canWrite ? yes : no})`,
  ];
}
