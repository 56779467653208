<!-- Condition template -->
<ng-container *ngIf="condition">
  <!-- Show label with prefix (if any) -->
  <ng-container *ngIf="label"
    >{{ label }}
    <ng-container *ngIf="condition.labelPostfix">{{ condition.labelPostfix }}</ng-container>
  </ng-container>

  <!-- Simple (string value) condition -->
  <ng-container *ngIf="simpleCondition">
    <ul>
      <li [ngClass]="{ bullet: showLabel }">
        <ng-container *ngIf="condition.labelPostfix">{{ condition.labelPostfix }}</ng-container>
        {{ simpleCondition.data }}
      </li>
    </ul>
  </ng-container>

  <!-- Condition component template -->
  <ng-container *ngIf="componentCondition">
    <tm-dynamic-component
      *ngIf="componentCondition"
      [component]="componentCondition.component"
      [inputs]="{ data: componentCondition.data }"
    ></tm-dynamic-component>
  </ng-container>
</ng-container>

<!-- Group template -->
<ng-container *ngIf="group">
  <!-- Show first-level children -->
  <ng-container *ngIf="group && !showLabel">
    <ng-container *ngFor="let child of group.children">
      <tm-audit-condition-group [showLabel]="true" [data]="child"></tm-audit-condition-group>
      <!--
        @translate audit.auditQuery.attrs.queryOperators.and
        @translate audit.auditQuery.attrs.queryOperators.or
      -->
      <li class="operator">{{ 'audit.auditQuery.attrs.queryOperators.' + group.link_operator | translate }}</li>
    </ng-container>
  </ng-container>

  <!-- Show sub-level children -->
  <ng-container *ngIf="group && showLabel">
    <!-- Group label -->
    <ng-container *ngIf="label">{{ label }}</ng-container>

    <ul>
      <ng-container *ngFor="let child of group.children">
        <li class="bullet">
          <tm-audit-condition-group [showLabel]="true" [data]="child"></tm-audit-condition-group>
        </li>

        <!--
        @translate audit.auditQuery.attrs.queryOperators.and
        @translate audit.auditQuery.attrs.queryOperators.or
      -->
        <li class="operator">{{ 'audit.auditQuery.attrs.queryOperators.' + group.link_operator | translate }}</li>
      </ng-container>
    </ul>
  </ng-container>
</ng-container>
