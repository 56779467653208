import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { debounceable } from '@platform/shared';
import { BookwormFormatsTableAdapterService } from '@tm-shared/bookworm/bookworm-formats.table-adapter.service';
import { TmGridComponent } from '@tm-shared/grid';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { FORMATS_CUSTOM_FILTER } from '../../../@tm-shared/bookworm/bookworm.model';
import { FilesService } from '../files-component/files.service';
import { FilesTableService } from './files-table.service';
import { FormatInfo } from '../../../typings/generated/bookworm';

@Component({
  selector: 'tm-file-table',
  styleUrls: ['files-table.component.scss'],
  templateUrl: './files-table.component.html',
  providers: [FilesTableService, BookwormFormatsTableAdapterService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesTableComponent implements OnDestroy, AfterViewInit {
  public screenHeight: string;

  public FORMATS_CUSTOM_FILTER = FORMATS_CUSTOM_FILTER;

  public tableConfig$ = this.fileTableService.getTableConfig();

  public createPolicyDisabled$: Observable<boolean>;

  @ViewChild(TmGridComponent, { static: false }) public grid: TmGridComponent<FormatInfo>;

  private _destroy$ = new Subject();

  constructor(
    public fileTableService: FilesTableService,
    private _filesService: FilesService,
    public tableApi: BookwormFormatsTableAdapterService,
    private _el: ElementRef,
    private _cd?: ChangeDetectorRef
  ) {
    this._onResize();
  }

  public ngAfterViewInit(): void {
    this.fileTableService.grid = this.grid;
    this.fileTableService.setGridFilteringLogic();

    this.createPolicyDisabled$ = combineLatest([this._filesService.canCreatePolicy$, this.grid.selected$]).pipe(
      map(([canCreate, selected]) => !canCreate || selected.length === 0)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public createPolicyFromFiletypes(policyType: 'OBJECT' | 'AGENT') {
    this.fileTableService.createPolicyAndNavigate(policyType, this.grid.getAllSelectedIds());
  }

  @HostListener('window:resize', ['$event'])
  @debounceable(50)
  private _onResize() {
    if (this._el.nativeElement.parentElement) {
      this.screenHeight = this._el.nativeElement.parentElement.offsetHeight + -120 + 'px';
      if (this._cd) {
        this._cd.markForCheck();
      }
    }
  }
}
