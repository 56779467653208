import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { AgGridModule } from 'ag-grid-angular';
import { ExportMonitorCellDateComponent } from './components/export-monitor-list-cell-date/export-monitor-list-cell-date.component';
import { ExportMonitorCellDeleteComponent } from './components/export-monitor-list-cell-delete/export-monitor-list-cell-delete.component';
import { ExportMonitorCellStatusComponent } from './components/export-monitor-list-cell-status/export-monitor-list-cell-status.component';
import { ExportMonitorListComponent } from './components/export-monitor-list/export-monitor-list.component';
import { TmExportMonitorComponent } from './components/export-monitor/export-monitor.component';
import { TmEventSearchWidgetComponent } from './events-search-widget/events-search-widget.component';
import { TmEventsSearchWidgetService } from './events-search-widget/events-search-widget.service';
import { TmEventsService } from './events.service';
import { TmEventsQueryService } from './query/events-query.service';
import ru from './i18n/events.ru.json';
import en from './i18n/events.en.json';
import { LuxonModule } from 'luxon-angular';
import { TmIconModule } from '../../@tm-shared/icons/tm-icon.module';

@NgModule({
  imports: [
    CommonModule,
    IwSharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LuxonModule,
    TmIconModule,
    AgGridModule.withComponents([
      ExportMonitorListComponent,
      ExportMonitorCellStatusComponent,
      ExportMonitorCellDeleteComponent,
      ExportMonitorCellDateComponent,
    ]),
  ],
  declarations: [
    TmEventSearchWidgetComponent,
    TmExportMonitorComponent,
    ExportMonitorListComponent,
    ExportMonitorCellStatusComponent,
    ExportMonitorCellDeleteComponent,
    ExportMonitorCellDateComponent,
  ],
  entryComponents: [TmEventSearchWidgetComponent, TmExportMonitorComponent],
  providers: [
    TmEventsService,
    TmEventsSearchWidgetService,
    TmEventsQueryService,
    { provide: 'TmEventsService', useExisting: TmEventsService },
    { provide: 'TmEventsSearchWidgetService', useExisting: TmEventsSearchWidgetService },
    { provide: 'TmEventsQueryService', useExisting: TmEventsQueryService },
  ],
})
export class TmEventsElementsModule {
  constructor(private _translate: TranslateService) {
    this._translate.setTranslation('ru', ru, true);
    this._translate.setTranslation('en', en, true);
  }
}
