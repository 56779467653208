import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TmScopeApiService } from '@tm-shared/api-services/scope-api.service';
import { Observable, of } from 'rxjs';

// TODO: выпилить резолверы
@Injectable()
export class ScopeFormResolver implements Resolve<TmApi.scope.CollectionItem> {
  constructor(private _service: TmScopeApiService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<TmApi.scope.CollectionItem> {
    const idParam = route.paramMap.get('id');
    return idParam ? this._service.getById(idParam) : of(this._service.createScopeModel());
  }
}
