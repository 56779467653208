<iw-modal-header>{{ 'tech-graphic.editDocument.title' | translate }}</iw-modal-header>
<form [formGroup]="form" (submit)="submit()">
  <iw-form-row>
    <label iwFormRowLabel>{{ 'tech-graphic.editDocument.documentName' | translate }}</label>
    <iw-input-container iwFormRowContent>
      <input iwInput formControlName="DISPLAY_NAME" maxlength="256" tmAutofocus />
      <!--
        @translate tech-graphic.editDocument.errors.required
        @translate tech-graphic.editDocument.errors.unique
      -->
      <div iwErrorHints *ngFor="let error of getErrors('DISPLAY_NAME')">
        {{ 'tech-graphic.editDocument.errors.' + error.key | translate: error.value }}
      </div>
    </iw-input-container>
  </iw-form-row>
  <iw-form-row>
    <label iwFormRowLabel>{{ 'tech-graphic.editDocument.description' | translate }}</label>
    <iw-input-container iwFormRowContent>
      <input iwInput formControlName="NOTE" maxlength="1000" />
      <!--
        @translate tech-graphic.editDocument.errors.required
        @translate tech-graphic.editDocument.errors.unique
      -->
      <div iwErrorHints *ngFor="let error of getErrors('NOTE')">
        {{ 'tech-graphic.editDocument.errors.' + error.key | translate: error.value }}
      </div>
    </iw-input-container>
  </iw-form-row>
  <iw-form-toolbar>
    <button iw-button color="success" data-qa="graphicDocumentEditSubmit" type="submit">
      {{ 'tech-graphic.editDocument.submit' | translate }}
    </button>
    <button iw-button data-qa="graphicDocumentEditClose" (click)="close()">
      {{ 'tech-graphic.editDocument.close' | translate }}
    </button>
  </iw-form-toolbar>
</form>
