<button
  iw-button
  type="button"
  [disabled]="!!(createPolicyDisabled$ | async)"
  class="inline-item"
  [iwPopover]="createPolitics"
  placement="bottom-start"
>
  {{ 'lists-files.create-policy' | translate }}
</button>

<input
  type="text"
  [tmGridFilter]="grid"
  [propertyToFilter]="FORMATS_CUSTOM_FILTER"
  [startsWithAny]="true"
  class="inline-item tm-input search-input"
  placeholder="{{ 'lists-files.search' | translate }}"
  data-qa="files-search"
/>

<tm-grid
  data-qa="files-table-grid"
  [service]="tableApi"
  [gridOptions]="tableConfig$ | async"
  class="grid"
  #grid
  [displayPagination]="false"
  [initialRequest]="false"
  [ngStyle]="{ height: screenHeight }"
>
</tm-grid>

<ng-template #createPolitics>
  <div class="dropdown">
    <button (click)="createPolicyFromFiletypes('OBJECT')">
      {{ 'lists-files.create-policy' | translate }}
    </button>
    <button (click)="createPolicyFromFiletypes('AGENT')">
      {{ 'lists-files.create-policy-agent' | translate }}
    </button>
  </div>
</ng-template>
