import { Route } from '@angular/router';
import { ROUTER_CONFIG_DIFF_PAGE } from './config-exports';
import { TmConfigDiffPageComponent } from './configdiff-page/configdiff-page.component';

export const TM_PLUGIN_CONFIG_ROUTES: Route[] = [
  {
    path: ROUTER_CONFIG_DIFF_PAGE,
    component: TmConfigDiffPageComponent,
  },
];
