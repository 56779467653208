import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { SystemList, SystemListCommon } from 'plugins/lists-resources/generated/resources-group';
import { Observable } from 'rxjs';

@Injectable()
export class TmResourcesGroupService extends TmCollectionLoader<SystemList, SystemListCommon> {
  public readonly src = '/api/systemList';
  public idAttribute = 'LIST_ID';

  public restore(options?: TmApi.GetOptions): Observable<TmApi.GetArrayResponse<SystemList>> {
    return this.patch('restoreSystem', {}, options);
  }
}
