<ul class="tabs" [class.empty]="!tabs.length">
  <li
    *ngFor="let tab of tabs; let i = index"
    (click)="selectTab(i)"
    data-qa="tab-title"
    class="tab"
    [class.active]="i === activeIndex"
    [class.disabled]="tab.disabled"
  >
    <span>{{ tab.tabTitle }}</span>
  </li>
</ul>
<div class="tabs-container">
  <ng-content></ng-content>
</div>
