import { EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { TmGridComponent } from '@tm-shared/grid';
import { GridOptions } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil, tap } from 'rxjs/operators';

/**
 * @Directive is used due to Angular 10  DI system:
 * https://angular.io/guide/migration-undecorated-classes
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class TmAccessSelectModalComponent implements OnInit, OnDestroy {
  @Input() public selectedIds: string[];

  @Output() public change: EventEmitter<(string | number)[]> = new EventEmitter();

  @Output() public exit: EventEmitter<void> = new EventEmitter();

  public filterControl = new FormControl();

  public abstract service: TmCollectionLoader<any>;

  public abstract readonly gridOptions: GridOptions;

  public tableParams: any;

  private _destroy$: Subject<void> = new Subject();

  @ViewChild('grid', { static: true }) private _grid: TmGridComponent<TmApi.role.CollectionItem>;

  constructor(protected _t: TranslateService) {}

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnInit(): void {
    this._grid.initialized$
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe(() => this._grid.selectById(this.selectedIds));

    this.filterControl.valueChanges
      .pipe(
        debounceTime(200),
        tap((value) => this._grid.updateFilterAndRefresh('DISPLAY_NAME', value ? value + '*' : '')),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  public acceptChanges(): void {
    this.change.emit(this._grid.getAllSelectedIds());
    this.exit.emit();
  }

  public rejectChanges(): void {
    this.exit.emit();
  }
}
