import { UserModel } from 'plugins/settings-access/user/user.model';
import { PolicyAuditLog, PolicyDiffAuditLog } from 'typings/generated/audit-policy';
import { QueryAuditLog } from 'typings/generated/audit-event-query';
import { ProtectedCatalogAuditLog } from 'typings/generated/audit-protected-catalog';
import {
  ObjectAuditLog,
  ObjectExportDiffAuditLog,
  TagDiffAuditLog,
  UserDesicionDiffAuditLog,
} from '../../typings/generated/audit-object';
import { PerimeterAuditLog } from '../../typings/generated/audit-perimeter';
import { BruteforceAuditLog, BruteforceDiffAuditLog } from 'typings/generated/audit-bruteforce';
import { FingerprintAuditLog } from 'typings/generated/audit-fingerprint';

export enum AuditEventEntityType {
  Adlibitum = 'Adlibitum',
  Agent = 'Agent',
  AgentJob = 'AgentJob',
  Audit = 'Audit',
  Autoling = 'Autoling',
  Category = 'Category',
  CategoryAutoling = 'CategoryAutoling',
  CategoryGraphic = 'CategoryGraphic',
  Classifier = 'Classifier',
  Config = 'Config',
  CrawlerScanner = 'CrawlerScanner',
  CrawlerTask = 'CrawlerTask',
  Dashboard = 'Dashboard',
  DashboardWidget = 'DashboardWidget',
  EtForm = 'EtForm',
  EtStamp = 'EtStamp',
  EtTable = 'EtTable',
  Fingerprint = 'Fingerprint',
  ImageClassifier = 'ImageClassifier',
  LdapContact = 'LdapContact',
  LdapGroup = 'LdapGroup',
  LdapPerson = 'LdapPerson',
  LdapScreenshot = 'LdapScreenshot',
  LdapSetting = 'LdapSetting',
  LdapStatus = 'LdapStatus',
  LdapWorkstation = 'LdapWorkstation',
  LocalSetting = 'LocalSetting',
  NetworkSettings = 'NetworkSettings',
  Notification = 'Notification',
  NotificationTemplate = 'NotificationTemplate',
  NotificationSettings = 'NotificationSettings',
  Object = 'Object',
  Export = 'Export',
  Perimeter = 'Perimeter',
  Plugin = 'Plugin',
  Policy = 'Policy',
  Privilege = 'Privilege',
  ProtectedCatalog = 'ProtectedCatalog',
  ProtectedDocument = 'ProtectedDocument',
  Query = 'Query',
  QueryFolder = 'QueryFolder',
  ReportFolder = 'ReportFolder',
  QueryReport = 'QueryReport',
  QueryReportRun = 'QueryReportRun',
  QueryReportWidget = 'QueryReportWidget',
  Role = 'Role',
  ServiceLog = 'ServiceLog',
  Setting = 'Setting',
  SystemList = 'SystemList',
  SystemListItem = 'SystemListItem',
  SmtpServer = 'SmtpServer',
  Tag = 'Tag',
  Term = 'Term',
  TextObject = 'TextObject',
  Token = 'Token',
  UpdateSystem = 'UpdateSystem',
  User = 'User',
  VisibilityArea = 'VisibilityArea',
  license = 'license',
}

export enum AuditEventOperation {
  add = 'add',
  addRole = 'add_role',
  addTag = 'add_tag',
  addVisibilityArea = 'add_visibility_area',
  applyResultScanJob = 'apply_result_scan_job',
  bruteforce = 'bruteforce',
  canceled = 'canceled',
  change = 'change',
  changePassword = 'change_password',
  commit = 'commit',
  compile = 'compile',
  copy = 'copy',
  create = 'create',
  createLog = 'create_log',
  delete = 'delete',
  deleteHash = 'delete_hash',
  deleteRef = 'delete_ref',
  download = 'download',
  draft = 'draft',
  export = 'export',
  exportObject = 'export_object',
  import = 'import',
  login = 'login',
  logout = 'logout',
  move = 'move',
  notificationTest = 'notification_test',
  privilegesUpdate = 'privileges_update',
  privilegesCreate = 'privileges_create',
  pluginRegister = 'plugin_register',
  pluginUpdate = 'plugin_update',
  removeRole = 'remove_role',
  removeTag = 'remove_tag',
  removeVisibilityArea = 'remove_visibility_area',
  restart = 'restart',
  rollback = 'rollback',
  run = 'run',
  start = 'start',
  startScanJob = 'start_scan_job',
  stop = 'stop',
  sync = 'sync',
  update = 'update',
  updateJobSchedule = 'update_job_schedule',
  xapiDecisionUpdate = 'xapi_decision_update',
  decisionUpdate = 'decision_update',
  view = 'view',
}

export type AuditEventChangeKey = 'old' | 'new' | 'request' | 'entity';

export interface AuditEventChangeDiff<T> {
  ENTITY_DISPLAY_NAME?: string;
  new?: T;
  old?: T;
  request?: T;
  entity?: T;
}

export interface AuditEventLicense {
  DISPLAY_NAME: string;
}

export interface AuditUserRole {
  CHANGE_DATE: string;
  CREATE_DATE: string;
  DISPLAY_NAME: string;
  EDITABLE: number;
  NOTE: string;
  ROLE_ID: number;
  ROLE_TYPE: number;
}

export interface AuditUserScope {
  CHANGE_DATE: string;
  CREATE_DATE: string;
  DISPLAY_NAME: string;
  IS_SYSTEM: number;
  VISIBILITY_AREA_CONDITION: {
    data: {
      link_operator: string;
      children: [];
    };
  };
  VISIBILITY_AREA_ID: string;
}

export interface AuditEventUserLoginLogout {
  request: {
    hostname: string;
    ip: string;
    result: string;
    login: string;
  };
}

export type UserAuditLog = {
  AUDIT_LOG_ID: number;
  USER_ID: number;
  CHANGE_DATE: string;
  ENTITY_ID: string;
  ENTITY_DISPLAY_NAME: string;
  ENTITY_TYPE: string;
  OPERATION: 'login' | 'logout';
  user_or_token?: {
    DISPLAY_NAME?: string;
  };
};

export type AuditEventUserChange = AuditEventChangeDiff<Partial<UserModel>> | AuditEventUserLoginLogout;

export type AuditEventChange =
  | AuditEventLicense
  | AuditEventUserChange
  | PolicyDiffAuditLog['PROPERTY_CHANGES']
  | ObjectDiffLog
  | BruteforceDiffAuditLog['PROPERTY_CHANGES'];

export type AuditEvent =
  | PolicyAuditLog
  | QueryAuditLog
  | UserAuditLog
  | ProtectedCatalogAuditLog
  | ObjectAuditLog
  | PerimeterAuditLog
  | BruteforceAuditLog
  | FingerprintAuditLog;

export type ObjectDiffLog =
  | UserDesicionDiffAuditLog['PROPERTY_CHANGES']
  | TagDiffAuditLog['PROPERTY_CHANGES']
  | ObjectExportDiffAuditLog['PROPERTY_CHANGES'];

export interface AuditResponse {
  data: AuditEvent[];
  meta: {
    totalCount: number;
  };
}

export interface AuditPolicyPluginAttribute {
  [k: string]: any;
}
