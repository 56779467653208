import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { TmRoleApiService } from '@tm-shared/api-services/role-api.service';
import { TmGridComponent, TmGridOptions } from '@tm-shared/grid';
import { CheckboxCellComponent } from '@tm-shared/grid/cell-renderers';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmSidebarService } from '@tm-shared/structure/sidebar/sidebar.service';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { auditTime, filter, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ACCESS_PATH, CREATE_PATH, EDIT_PATH, ROLE_PATH } from '../access-exports';
import { RoleDeleteModalComponent } from './role-delete';
import { RoleFormComponent } from './role-form.component';
import { MODEL_ID_KEY, RoleModel, getPrivilegeRequest } from './role.model';

@Component({
  selector: 'tm-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
  providers: [TmSidebarService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('grid', { static: true }) public grid: TmGridComponent<RoleModel>;

  public RoleDeleteModalComponent = RoleDeleteModalComponent;

  public CREATE_PATH = CREATE_PATH;
  public EDIT_PATH = EDIT_PATH;

  /**
   * Define can streams
   */
  public canEdit$: Observable<boolean> = of(null).pipe(
    switchMap(() => this.grid.selected$),
    map((data) => !!data.length && this.service.isModelEditable(data))
  );
  public canCreate$: Observable<boolean> = this._can('edit');
  /**
   * all selected roles are not used in users
   */
  public isNotUsedInUsers$: Observable<boolean>;
  public canRemove$: Observable<boolean>;

  public defaultTableParams = {
    'merge_with[]': 'users',
  };

  public gridOptions: TmGridOptions = {
    columnDefs: [
      {
        width: 40,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        sort: 'asc',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('settings-access.role.fieldNames.displayName'),
      },
      {
        resizable: true,
        sortable: true,
        field: 'users',
        headerValueGetter: () => this._t.instant('settings-access.role.fieldNames.users'),
        valueGetter: ({ data }: { data: TmApi.role.CollectionItem }): string => {
          return data.users
            .map((user) => {
              return user.USERNAME;
            })
            .sort()
            .join(', ');
        },
      },
      {
        resizable: true,
        sortable: true,
        field: 'NOTE',
        headerValueGetter: () => this._t.instant('settings-access.role.fieldNames.note'),
      },
    ],
  };

  /**
   * Unsubscribe trigger
   */
  private _destroyed$: Subject<void> = new Subject();

  constructor(
    public service: TmRoleApiService,
    public router: Router,
    private _activatedRoute: ActivatedRoute,
    private _t: TranslateService,
    private _sidebarService: TmSidebarService,
    private _notify: IwNotificationsService,
    private _privileges: TmPrivilegesService
  ) {}

  public ngOnInit(): void {
    this._sidebarService
      .onAction('*', 'complete')
      .pipe(
        switchMap(() => this.grid.initialized$),
        takeUntil(this._destroyed$)
      )
      .subscribe((gridApi) => {
        gridApi.sizeColumnsToFit();
      });

    this.grid.selectedOrdered$.pipe(skip(1), takeUntil(this._destroyed$)).subscribe((selection) => {
      if (!selection.length) {
        this._sidebarService.close('right');
        this.router.navigate([ACCESS_PATH, ROLE_PATH], {
          queryParamsHandling: 'merge',
        });
      } else {
        this.showDialogEdit(selection[0][MODEL_ID_KEY]);
      }
    });
  }

  public ngAfterViewInit(): void {
    if (this._activatedRoute.firstChild && this._activatedRoute.firstChild.snapshot.params['id']) {
      this.grid.selectById(this._activatedRoute.firstChild.snapshot.params['id']);
    }
    this.isNotUsedInUsers$ = this.grid.allSelected$.pipe(map((roles) => !roles.every((role) => role.users.length)));
    this.canRemove$ = this._can('delete', this.canEdit$, this.isNotUsedInUsers$);
  }

  /**
   * Ag destroy hook
   */
  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public onActivate(component: RoleFormComponent): void {
    component.close$
      .pipe(
        tap(() => this._sidebarService.close('right')),
        auditTime(200),
        take(1),
        filter(() => !!this.grid),
        tap(() => this.grid.resetSelection()),
        tap(() => this.grid.refresh()),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /**
   * Open create dialog
   */
  public showDialogCreate(): void {
    this.router.navigate([CREATE_PATH], {
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge',
    });

    this._sidebarService.open('right');
  }

  /**
   * Open edit dialog
   */
  public showDialogEdit(id: number): void {
    this.router.navigate([EDIT_PATH.replace(':id', `${id}`)], {
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge',
    });

    this._sidebarService.open('right');
  }

  public deleteSelectedItems() {
    this.router.navigate([ACCESS_PATH, ROLE_PATH], {
      queryParamsHandling: 'merge',
    });
    this.grid
      .deleteByFn((item) => item.users.length === 0)
      .pipe(tap((resp) => this._notifyOnBackendValidation(resp)))
      .subscribe();
  }

  /**
   * Check privileges
   */
  private _can(actionKey: TmPluginAccess.role.PrivelegeKey, ...rules$: Observable<boolean>[]): Observable<boolean> {
    let can$: Observable<boolean> = this._privileges.can(getPrivilegeRequest(actionKey));

    return combineLatest([can$, ...rules$]).pipe(map((bools: boolean[]) => bools.every((b) => b)));
  }

  private _notifyOnBackendValidation(responses: any[]) {
    responses.forEach((item) => {
      if (
        item instanceof HttpErrorResponse &&
        item.error &&
        item.error.error === 'validation' &&
        item.error.meta.users_exist
      ) {
        const role = item.error.meta.users_exist[0].DISPLAY_NAME;
        this._notify.error(
          this._t.instant('settings-access.validation.failed'),
          this._t.instant('settings-access.role.validation.users_exist', { role })
        );
      }
    });
  }
}
