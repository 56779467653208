import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Inject,
  Input,
  OnChanges,
  TemplateRef,
  forwardRef,
} from '@angular/core';
import { TmTabsetComponent } from '../tab-set/tab-set.component';

@Component({
  selector: 'tm-tab',
  templateUrl: './tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmTabComponent implements OnChanges {
  @Input() public tabTitle: string;
  @Input() public disabled = false;
  @ContentChild(TemplateRef, { static: false }) public template: TemplateRef<any>;

  public active = false;

  constructor(
    private _cd: ChangeDetectorRef,
    @Inject(forwardRef(() => TmTabsetComponent)) private _tabset: TmTabsetComponent
  ) {}

  public ngOnChanges() {
    this._tabset.markForCheck();
  }
  public calcActiveState() {
    const newTabActiveState: boolean = this._tabset.tabs.toArray().indexOf(this) === this._tabset.activeIndex;
    if (this.active !== newTabActiveState) {
      this.active = newTabActiveState;
      this._cd.detectChanges();
    }
  }
}
