import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TmAsyncValidatorsService } from '@tm-shared/helpers/validators';
import { Observable } from 'rxjs';
import { PATTERN_STRING_256_7 } from '@tm-shared/helpers/patterns';

@Injectable()
export class ResourcesTableFormService {
  public itemId$: Observable<string | number>;

  constructor(private _fb: FormBuilder, private _asyncValidation: TmAsyncValidatorsService) {}

  public createResourcesForm(itemId$: Observable<string | number>, resourceId: string | number) {
    this.itemId$ = itemId$;
    return this._fb.group({
      LIST_ITEM_ID: null,
      LIST_ID: resourceId,
      VALUE: [
        null,
        [Validators.required, Validators.maxLength(256), Validators.pattern(PATTERN_STRING_256_7)],
        this._asyncValidation.getAsyncObjectValidation('resource', this.itemId$),
      ],
      NOTE: [null, [Validators.maxLength(1024)]],
    });
  }

  public handleFormDisableState(form: FormGroup, canEditResources: boolean) {
    if (canEditResources) {
      form.enable();
    } else {
      form.disable();
    }
  }
}
