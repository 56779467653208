import { AfterViewInit, Directive, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { TmGridComponent, TmGridOptions } from '@tm-shared/grid';
import { AbstractTabComponentDirective } from './abstract-tab-component.directive';

@Directive({})
export abstract class TableTabComponentDirective extends AbstractTabComponentDirective implements AfterViewInit {
  @ViewChild('grid') public grid: TmGridComponent<any>;

  public abstract options: TmGridOptions;

  public ngAfterViewInit() {
    if (this.selected) {
      this.grid.selectById(this.selected);
    }
    this.grid.allSelectedIds$.pipe(takeUntil(this.destroy$)).subscribe((currentlySelected) => {
      this.selectedChange.emit(currentlySelected);
    });
  }
}
