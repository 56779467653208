import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmSharedModule } from '@tm-shared';
import { TmCronjobSelectComponent } from '@tm-shared/cronjob/cronjob-select.component';
import { TmCronJobPresetNamePipe } from '@tm-shared/cronjob/cronjob.pipe';
import { TmDeleteConfirmModule } from '@tm-shared/delete-confirm/delete-confirm.module';
import { TmDescriptionModule } from '@tm-shared/description/description.module';
import { TmDropzoneModule } from '@tm-shared/dragndrop/dropzone.module';
import { CanDeactivateTmForm } from '@tm-shared/form';
import { TmLuxonDetectLangChangePipe } from '@tm-shared/luxon/luxon-detect-lang-change.pipe';
import { TmModals } from '@tm-shared/modals';
import { TmOverlayModule } from '@tm-shared/overlay';
import { TmSpinnerModule } from '@tm-shared/spinner';
import { TmStructureModule } from '@tm-shared/structure';
import { TmToolbarModule } from '@tm-shared/toolbar';
import { TmTreeModule } from '@tm-shared/tree';
import { LuxonModule } from 'luxon-angular';
import en from './i18n/settings-ldap.en.json';
import ru from './i18n/settings-ldap.ru.json';
import { TmLdapPageComponent } from './ldap-page.component';
import { TmLdapServerEditComponent } from './ldap-server-edit/ldap-server-edit.component';
import { TmLdapServerComponent } from './ldap-server/ldap-server.component';
import { routes } from './ldap.routes';

export const LANG_DATA = { en, ru };

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    TmTreeModule,
    TmDescriptionModule,
    TmOverlayModule,
    TmSpinnerModule,
    LuxonModule,
    IwSharedModule,
    TmToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    TmDropzoneModule,
    TmDeleteConfirmModule,
    TmSharedModule,
    TmModals,
    TmStructureModule,
  ],
  declarations: [
    TmLdapPageComponent,
    TmLdapServerComponent,
    TmLdapServerEditComponent,
    TmCronJobPresetNamePipe,
    TmCronjobSelectComponent,
    TmLuxonDetectLangChangePipe,
  ],
  providers: [CanDeactivateTmForm],
})
export class TmLdapModule {
  constructor(private _t: TranslateService) {
    this._t.setTranslation('ru', ru, true);
    this._t.setTranslation('en', en, true);
  }
}
