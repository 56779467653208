import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  Type,
} from '@angular/core';

import { TmOverlayComponent } from '../overlay';
import { TmContextMenuComponent } from './context-menu.component';
import { TmContextMenuItem } from './context-menu.model';

@Directive({
  selector: '[tmContextMenu]',
})
export class TmContextMenuDirective {
  public get _contextMenuIsEmpty(): boolean {
    return !this.tmContextMenu || !this.tmContextMenuData || this.tmContextMenu.length === 0;
  }
  public menuComponent: ComponentRef<TmContextMenuComponent>;
  public overlayComponent: ComponentRef<TmOverlayComponent>;

  @Input() public tmContextMenu: TmContextMenuItem[];
  @Input() public tmContextMenuData: any;

  constructor(
    private _applicationRef: ApplicationRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector
  ) {}

  @HostListener('contextmenu', ['$event'])
  public onClick(event: MouseEvent): boolean | void {
    if (this._contextMenuIsEmpty) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this._closeMenu();

    this._createMenu(event);

    return false;
  }

  /**
   * Initialize context menu
   *
   * @private
   * @param {MouseEvent} event
   * @memberof TmContextMenuDirective
   */
  private _createMenu(event: MouseEvent): void {
    this.overlayComponent = this._appendComponent(TmOverlayComponent);
    this.menuComponent = this._appendComponent(TmContextMenuComponent);

    this.menuComponent.instance.onClose.subscribe(() => {
      this._closeMenu();
    });

    this.overlayComponent.instance.onClick.subscribe(() => {
      this._closeMenu();
    });

    this.menuComponent.instance.items = this.tmContextMenu;
    this.menuComponent.instance.context = this.tmContextMenuData;

    this._setPosition(event);
  }

  /**
   * Set current position
   *
   * @private
   * @param {MouseEvent} event
   * @memberof TmContextMenuDirective
   */
  private _setPosition(event: MouseEvent): void {
    const { clientX, clientY } = event;

    this.menuComponent.instance.position = {
      top: clientY,
      left: clientX,
    };
  }

  /**
   * Close context menu
   *
   * @private
   * @memberof TmContextMenuDirective
   */
  private _closeMenu(): void {
    if (this.menuComponent) {
      this.menuComponent.destroy();
    }

    if (this.overlayComponent) {
      this.overlayComponent.destroy();
    }
  }

  /**
   * Dynamic append component
   *
   * @private
   * @template T
   * @param {Type<T>} component
   * @returns {ComponentRef<T>}
   * @memberof TmContextMenuDirective
   */
  private _appendComponent<T>(component: Type<T>): ComponentRef<T> {
    const rootComponents = this._applicationRef['components'];
    const location = (rootComponents[0].hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = componentFactory.create(this._injector);
    const componentRootNode = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    componentRootNode.classList.add('transparent');
    this._applicationRef.attachView(componentRef.hostView);

    componentRef.onDestroy(() => {
      this._applicationRef.detachView(componentRef.hostView);
    });

    location.appendChild(componentRootNode);

    return componentRef;
  }
}
