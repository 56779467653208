import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TmAsyncValidatorsService } from '@tm-shared/helpers/validators';
import { Observable } from 'rxjs';
import { DEFAULT_LIST_TYPE } from '../resources.model';
import { PATTERN_STRING_256_7 } from '@tm-shared/helpers/patterns';

@Injectable()
export class ResourcesFormService {
  public resourcesId$: Observable<string | number>;
  constructor(private _fb: FormBuilder, private _asyncValidation: TmAsyncValidatorsService) {}

  public createResourcesFormGroup(resourcesId$: Observable<string | number>) {
    this.resourcesId$ = resourcesId$;
    return this._fb.group({
      LIST_ID: null,
      LIST_TYPE: DEFAULT_LIST_TYPE,
      DISPLAY_NAME: [
        null,
        [Validators.required, Validators.maxLength(256), Validators.pattern(PATTERN_STRING_256_7)],
        this._asyncValidation.getAsyncObjectValidation('resourceGroup', this.resourcesId$),
      ],
      NOTE: [null, [Validators.maxLength(1024)]],
    });
  }

  public handleFormDisableState(form: FormGroup, canEditResources: boolean) {
    if (canEditResources) {
      form.enable();
    } else {
      form.disable();
    }
  }
}
