import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  templateUrl: 'routing-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutingCellComponent implements AgRendererComponent {
  public data: TmGrid.routingCell.RoutingData;

  public agInit(data: any): void {
    this._setData(data.value);
  }

  public refresh(data: any): boolean {
    this._setData(data.value);
    return true;
  }

  private _setData(data: TmGrid.routingCell.RoutingData) {
    this.data = data;
  }
}
