<span class="tag" *ngFor="let value of settledValues; index as i">
  {{ value }}
  <span class="inputItemClose" (click)="deleteItemByIndex(i)"></span>
</span>
<input
  #input
  [value]="inputElValue"
  (input)="onInputChange(input.value)"
  (keydown)="onInputElKeypress($event)"
  [disabled]="disabled"
  [attr.maxlength]="maxlength"
  (blur)="onBlur()"
/>
