<div
  #childRef
  class="tm-context-menu-container"
  [class.dark]="true"
  [style.left]="position.left + 'px'"
  [style.top]="position.top + 'px'"
>
  <ul>
    <li
      *ngFor="let item of items"
      (mouseover)="closeSubMenu(item)"
      [ngClass]="getClassItem(item)"
      (click)="onClick(item)"
    >
      <div *ngIf="!item.divider && !item.subMenu" [innerHTML]="getLabel(item)"></div>
      <div
        #subMenuRef
        *ngIf="item.subMenu"
        [tmContextMenuSub]="item.subMenuItems || []"
        (closeSubMenu)="close()"
        [innerHTML]="getLabel(item)"
      ></div>
    </li>
  </ul>
</div>
