import { AuditEvent, AuditEventChange, AuditEventOperation } from '../audit.model';
import {
  AuditExtendRowData,
  AuditExtendGridData,
  AuditGridDataColumn,
  AuditGridNameColumn,
  AuditGridCellRenderer,
} from '../audit-extend.service';
import { TranslateService } from '@ngx-translate/core';
import { get, isEqual } from 'lodash';
import { Type } from '@angular/core';

export type FilterFnCols = Omit<AuditExtendRowData, 'name' | 'component'>;
export type VisibleAttributesFilterFn = (cols: FilterFnCols) => boolean;
export type VisibleAttributesFilter = VisibleAttributesFilterFn | false | 'deepEqual' | 'jsonStringify';

/**
 * Each visible attribute stands for a single grid row.
 * "i18nKey" and "name" - both stands for attribute name, "name" has priority.
 */
export type VisibleAttributesOption = {
  path: string;
  transform?: (value: unknown, options: TransformOptions) => unknown;
  component?: Type<AuditGridCellRenderer<unknown>>;
  filter?: VisibleAttributesFilter;
} & ({ i18nKey: string } | { name: string });

/**
 * data - contains any data to be parsed.
 * cols - should be listed in the correct order
 *
 * @example
 * {
 *  t: translateService,
 *  cols: [ 'old', 'new' ],
 *  data: { new: { a: 1 }, old: { a: 2 } },
 *  visibleAttributes: [
 *    { "path": 'a', "i18nKey": 'translation.key' }
 *  ]
 * }
 */
export interface AuditDataParseOptions {
  data: unknown;
  t: TranslateService;
  visibleAttributes: VisibleAttributesOption[];
  cols: AuditGridDataColumn[];
}

/**
 * Column data may be used to access other same-level attributes
 */
export interface TransformOptions extends AuditDataParseOptions {
  columnData: unknown;
}

/**
 * Try to get PROPERTY_CHANGES attribute which contains main audit event data
 */
export function getAuditAttributesFrom(auditEvent: AuditEvent): AuditEventChange | null {
  if ('PROPERTY_CHANGES' in auditEvent) {
    if (typeof auditEvent.PROPERTY_CHANGES === 'string') {
      try {
        return JSON.parse(auditEvent.PROPERTY_CHANGES) as AuditEventChange;
      } catch {
        return null;
      }
    }

    if (typeof auditEvent.PROPERTY_CHANGES === 'object') {
      return auditEvent.PROPERTY_CHANGES;
    }
  }

  return null;
}

/**
 * Parse data to audit extend row model.
 * If any row has no values in cells the row is skipped.
 * If any row has the same value in each cell, the row is skipped.
 */
export function getAuditExtendRowData(options: AuditDataParseOptions): AuditExtendRowData[] {
  const result: AuditExtendRowData[] = [];

  for (let i = 0; i < options.visibleAttributes.length; i++) {
    const attr = options.visibleAttributes[i];
    const cols = options.cols.reduce((rowData, colKey) => {
      rowData[colKey] = get(options.data, [colKey, attr.path].filter((x) => !!x).join('.'));

      if (attr.transform) {
        rowData[colKey] = attr.transform(
          rowData[colKey],
          Object.assign({}, options, {
            columnData: get(options.data, colKey),
          })
        );
      }

      if (!rowData[colKey]) {
        delete rowData[colKey];
      }

      return rowData;
    }, {} as Record<string, unknown>);

    // Do not emit empty rows and rows with equal values
    if (omitRowData(cols, attr)) {
      continue;
    }

    const rowData: AuditExtendRowData = {
      [AuditGridNameColumn]: 'name' in attr ? attr.name : options.t.instant(attr.i18nKey),
      ...cols,
    };

    if (attr.component) {
      rowData.component = attr.component;
    }
    result.push(rowData);
  }

  return result;
}

export function omitRowData(
  cols: Pick<AuditExtendRowData, AuditGridDataColumn.old | AuditGridDataColumn.new>,
  attr: VisibleAttributesOption
): boolean {
  /**
   * Omit always if no data
   */
  if (
    Object.keys(cols).length === 0 ||
    Object.values(cols).every((item) => {
      return !item || Array.isArray(item) ? !(item as unknown[]).length : false;
    })
  ) {
    return true;
  }

  /**
   * Never omit if filter is false
   */
  if (attr.filter === false) {
    return false;
  }

  /**
   * Compare old and new values if filter is a preset
   */
  if (AuditGridDataColumn.new in cols && AuditGridDataColumn.old in cols) {
    if (attr.filter === 'deepEqual') {
      return isEqual(cols.new, cols.old);
    }

    if (attr.filter === 'jsonStringify') {
      return JSON.stringify(cols.new) === JSON.stringify(cols.old);
    }
  }

  /**
   * Compare by function
   */
  if (typeof attr.filter === 'function') {
    return !attr.filter(cols);
  }

  /**
   * Else use default behavior with strict comparison
   */
  return (
    AuditGridDataColumn.new in cols &&
    AuditGridDataColumn.old in cols &&
    cols[AuditGridDataColumn.new] === cols[AuditGridDataColumn.old]
  );
}

/**
 * This is a fallback function, it displays all possible keys and values without localization.
 * Do not extend it! Just add more cases to switch value at audit-extend.service.ts
 */
export function getUnknownGridData(auditEvent: AuditEvent, t: TranslateService): AuditExtendGridData[] {
  const attrs = (getAuditAttributesFrom(auditEvent) as unknown) as Record<string, unknown> | null;

  if (!attrs || typeof attrs !== 'object') {
    return [];
  }

  const cols: AuditGridDataColumn[] = [
    AuditGridDataColumn.request,
    AuditGridDataColumn.old,
    AuditGridDataColumn.new,
  ].filter((key) => key in attrs);

  const visibleAttributes = cols.reduce((result, colKey) => {
    const colData = attrs[colKey] as Record<string, unknown> | undefined;
    if (colData) {
      for (const key in colData) {
        // eslint-disable-next-line no-prototype-builtins
        if (colData.hasOwnProperty(key)) {
          result.push({ path: key, i18nKey: key, transform: processUnknownData });
        }
      }
    }
    return result;
  }, [] as VisibleAttributesOption[]);

  const data = getAuditExtendRowData({
    t,
    cols,
    data: attrs,
    visibleAttributes,
  });

  return [
    {
      cols,
      data,
    },
  ];
}

export function transformBooleanYesNo(data: boolean | null | undefined | 0 | 1, o: TransformOptions): string {
  /**
   * We should not return falsy value if there is no initial value
   */
  if (data === undefined || data === null) {
    return '';
  }
  return data ? o.t.instant('audit.auditCommon.booleanYes') : o.t.instant('audit.auditCommon.booleanNo');
}

export function transformLanguage(value: string | null | undefined, options: TransformOptions): string {
  if (value === undefined || value === null) {
    return '';
  }
  /**
   * @translate audit.language.ara
   * @translate audit.language.aze
   * @translate audit.language.bel
   * @translate audit.language.ces
   * @translate audit.language.deu
   * @translate audit.language.ell
   * @translate audit.language.eng
   * @translate audit.language.fas
   * @translate audit.language.fin
   * @translate audit.language.fra
   * @translate audit.language.hin
   * @translate audit.language.hye
   * @translate audit.language.ind
   * @translate audit.language.ita
   * @translate audit.language.jpn
   * @translate audit.language.kat
   * @translate audit.language.kaz
   * @translate audit.language.kir
   * @translate audit.language.lav
   * @translate audit.language.lit
   * @translate audit.language.msa
   * @translate audit.language.nld
   * @translate audit.language.pol
   * @translate audit.language.por
   * @translate audit.language.ron
   * @translate audit.language.rus
   * @translate audit.language.sah
   * @translate audit.language.sin
   * @translate audit.language.slk
   * @translate audit.language.spa
   * @translate audit.language.srp
   * @translate audit.language.tat
   * @translate audit.language.tgk
   * @translate audit.language.tur
   * @translate audit.language.ukr
   * @translate audit.language.uzb
   * @translate audit.language.vie
   * @translate audit.language.zho
   */
  return options.t.instant(`audit.language.${value}`);
}
/**
 * Try to stringify unknown data (fallback parser)
 */
export function processUnknownData(data: unknown): string {
  if (data === undefined || data === null) {
    return '';
  }

  if (typeof data === 'string' || typeof data === 'number') {
    return data.toString().trim();
  }

  try {
    return JSON.stringify(data).trim();
  } catch {
    return '';
  }
}

export function getDefaultColumns(data: AuditEvent): (AuditGridDataColumn.old | AuditGridDataColumn.new)[] {
  let cols: (AuditGridDataColumn.old | AuditGridDataColumn.new)[] = [];
  switch (data.OPERATION) {
    case AuditEventOperation.delete:
      cols = [AuditGridDataColumn.old];
      break;
    case AuditEventOperation.create:
      cols = [AuditGridDataColumn.new];
      break;
    default:
      cols = [AuditGridDataColumn.old, AuditGridDataColumn.new];
      break;
  }

  return cols;
}

export function transformTech2Category(
  tech2category: { CATEGORY_ID: string }[] | undefined,
  o: TransformOptions & { columnData: { category?: { CATEGORY_ID: string; DISPLAY_NAME: string }[] } }
): string {
  const data = o.columnData;
  return (
    tech2category
      ?.map((item) => data.category?.find((category) => category.CATEGORY_ID === item.CATEGORY_ID)?.DISPLAY_NAME)
      .filter((x) => !!x)
      .join(', ') || ''
  );
}
