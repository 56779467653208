import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AuditGridCellRenderer } from '../audit-extend.service';
import { TmBookwormProtocolsService } from '@tm-shared/bookworm';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tm-audit-protocol',
  template: `
    <ng-container *ngIf="localizedProtocol !== undefined">{{ localizedProtocol }}</ng-container>
    <ng-container *ngIf="localizedProtocol === undefined"
      ><tm-spinner size="0.9rem" class="spinner"></tm-spinner
    ></ng-container>
  `,
  styles: [
    `
      :host {
        display: block;
        position: relative;
        flex: 0 0 auto;
      }
      .spinner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
    `,
  ],
})
export class TmAuditProtocolCellComponent implements OnInit, AuditGridCellRenderer<string[]> {
  @Input() public set data(eventKeys: string[]) {
    if (this.localizedProtocol !== undefined && this.isEventKeysTheSame(eventKeys)) {
      return;
    }

    this.eventKeys = eventKeys;
    this.updateLocalization();
  }

  public localizedProtocol?: string;

  private eventKeys?: string[];

  constructor(private service: TmBookwormProtocolsService, private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {}

  /**
   * Check if array contains the same keys in the same order
   */
  private isEventKeysTheSame(keys: string[] | undefined): boolean {
    return keys?.toString() === this.eventKeys?.toString();
  }

  private updateLocalization(): void {
    const eventKeys = this.eventKeys;

    if (eventKeys && eventKeys.length) {
      Promise.all(eventKeys.map((key) => this.service.getNameByKey(key).toPromise()))
        .then((names) => {
          this.localizedProtocol = names.join(', ');
        })
        .catch(() => {
          this.localizedProtocol = eventKeys.join(', ');
        })
        .finally(() => {
          this.cd.markForCheck();
        });
    } else {
      this.localizedProtocol = '';
    }
  }
}
