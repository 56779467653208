import {
  ApplicationCondition,
  ResourcesDataCondition,
  ChildCondition,
  PercipientCondition,
  PerimeterDataCondition,
  WorkstationsDataCondition,
  HeaderDataCondition,
  InCondition,
  TagsDataCondition,
  PoliciesDataCondition,
  OperationCondition,
  DateTypeDataCondition,
  BooleanAsStringOrInteger,
  BooleanAsString,
  DocumentsDataCondition,
  TextCondition,
  AnalysisDataCondition,
  FileExchangeDataCondition,
  FileFormatCondition,
  RemovableCondition,
  FixedCondition,
  NetshareCondition,
  TerminalCondition,
  FtpCondition,
  FileSizeCondition,
  CloudCondition,
} from 'typings/generated/audit-event-query';
import { TranslateService } from '@ngx-translate/core';
import { AuditGridCellRenderer } from '../audit-extend.service';
import { Type } from '@angular/core';
import { DateTime } from 'luxon';
import { TmAuditObjectTypeCellComponent } from '../audit-cell-components/audit-object-type-cell.component';
import { TmAuditProtocolCellComponent } from '../audit-cell-components/audit-protocol-cell.component';
import { processUnknownData } from './audit-common';
import { TmAuditBrItemsCellComponent } from '../audit-cell-components/audit-br-items.component';
import { toBool } from '@tm-shared/helpers/form';

export interface SimpleCondition {
  data: string;
  labelPostfix?: string;
}

export interface ComponentCondition {
  data: unknown;
  labelPostfix?: string;
  component?: Type<AuditGridCellRenderer<unknown>>;
}

export type ParsedCondition = SimpleCondition | ComponentCondition | null;

export function parseCondition(condition: ChildCondition, t: TranslateService): ParsedCondition {
  switch (condition.category) {
    case 'capture_date':
      return transformCaptureDate(condition, t);
    case 'senders':
    case 'recipients':
    case 'perimeter_in':
    case 'perimeter_out':
    case 'analysis':
      return transformSendersRecipientsPerimetersAnalysis(condition, t);
    case 'crawler_destination_type':
    case 'destination_host':
    case 'destination_id':
    case 'destination_name':
    case 'destination_path':
    case 'destination_type':
    case 'device_id':
    case 'device_name':
    case 'device_path':
    case 'device_type':
    case 'file_name':
    case 'object_id':
    case 'object_type_code':
    case 'protocol':
    case 'rule_group_type':
    case 'service_code':
    case 'user_decision':
    case 'verdict':
    case 'violation_level':
    case 'workstation_type':
    case 'text':
    case 'file_format':
      return transformValuesArrayWithOptionalCondition(condition, t);
    case 'recipient_count':
      return transformRecipientCount(condition, t);
    case 'attachment_count':
      return transformAttachmentCount(condition, t);
    case 'workstations':
      return transformWorkstations(condition);
    case 'resources':
    case 'tags':
    case 'policies':
    case 'documents':
      return transformQueries(condition);
    case 'file_size':
      return transformFileSize(condition, t);
    case 'object_header':
      return transformObjectHeader(condition, t);
    case 'application':
    case 'application_to':
    case 'application_from':
      return transformApplicationCondition(condition, t);
    case 'source_destination_copy':
      return transformSourceDestinationCondition(condition, t);
    default:
      return {
        data: JSON.stringify(condition),
      };
  }
}

export function transformApplicationCondition(
  condition: ApplicationCondition | undefined,
  t: TranslateService
): ParsedCondition {
  if (!condition) {
    return null;
  }

  const result: ParsedCondition = {
    data: condition.value?.map((x) => x.NAME).join(', '),
  };

  const negativeValue = condition && 'is_negative' in condition ? transformNegative(condition.is_negative) : null;
  const terminalValue =
    condition && 'terminal_session' in condition ? transforTerminalSession(condition.terminal_session, t) : null;

  if (negativeValue) {
    result.labelPostfix = negativeValue;
  }

  if (terminalValue) {
    result.data = terminalValue;
  }

  return result;
}

export function transformValuesArrayWithOptionalCondition(
  condition: InCondition | TextCondition | FileFormatCondition | undefined,
  t: TranslateService
): ParsedCondition {
  let value: ParsedCondition;

  if (!condition || !condition.value) {
    return null;
  }

  switch (condition.category) {
    case 'violation_level':
    case 'rule_group_type':
    case 'user_decision':
    case 'verdict':
    case 'crawler_destination_type':
      value = transformViolationLevel(condition, t);
      break;
    case 'object_type_code':
      return {
        data: condition.value,
        component: TmAuditObjectTypeCellComponent,
      };
    case 'protocol':
      return {
        data: condition.value,
        component: TmAuditProtocolCellComponent,
      };
    case 'text':
      value = {
        data: transformTextFormat(condition, t),
        component: TmAuditBrItemsCellComponent,
      };
      break;
    case 'file_format':
      value = {
        data: transformFileFormat(condition, t),
        component: TmAuditBrItemsCellComponent,
      };
      break;
    default:
      value = {
        data: condition.value.join(', '),
      };
      break;
  }

  const negativeValue = value && 'is_negative' in condition ? transformNegative(condition.is_negative) : null;

  if (value && negativeValue) {
    value.labelPostfix = negativeValue;
  }

  return value;
}

export function transformNegative(isNegative: BooleanAsStringOrInteger | null | undefined): string | null {
  if (isNegative !== undefined && isNegative !== null) {
    return +isNegative === 1 ? '(≠)' : '(=)';
  }

  return null;
}

export function transforTerminalSession(
  isTerminal: BooleanAsString | null | undefined,
  t: TranslateService
): string | null {
  if (isTerminal && +isTerminal) {
    return t.instant('audit.auditQuery.attr.terminalSession');
  }

  return null;
}

export function transformCaptureDate(
  condition: { value?: DateTypeDataCondition['value'] },
  t: TranslateService
): ParsedCondition {
  let result: string;

  if (condition.value?.type === 'last_hours') {
    result = `${t.instant('audit.date.last_several_hours')}: ${condition.value.hours}`;
  } else if (condition.value?.type === 'last_days') {
    if (condition.value.days === 3 || condition.value.days === 7 || condition.value.days === 30) {
      /**
       * @translate audit.date.last_3_days
       * @translate audit.date.last_7_days
       * @translate audit.date.last_30_days
       */
      result = t.instant(`audit.date.last_${condition.value.days}_days`);
    } else {
      result = `${t.instant('audit.date.last_several_days')}: ${condition.value.days}`;
    }
  } else if (condition.value?.type === 'period') {
    result =
      `${t.instant('audit.date.period')}: ` +
      condition.value.period
        .map((dateMs) => {
          return DateTime.fromMillis(dateMs * 1000)
            .toLocal()
            .toFormat('f');
        })
        .join(' - ');
  } else {
    /**
     * @translate audit.date.none
     * @translate audit.date.all_time
     * @translate audit.date.this_day
     * @translate audit.date.this_week
     * @translate audit.date.this_month
     */
    result = t.instant(`audit.date.${condition.value?.type}`);
  }

  return {
    data: result,
  };
}

export function transformRecipientCount(condition: OperationCondition, t: TranslateService): ParsedCondition {
  const count = condition.value?.value;

  if (typeof count === 'number' && !isNaN(count)) {
    return {
      data: `${getCondition(condition.value?.operation, t)} ${condition.value?.value}`,
    };
  }

  return null;
}

export function transformAttachmentCount(data: FileSizeCondition, t: TranslateService): ParsedCondition {
  if (data === undefined || data === null) {
    return {
      data: '',
    };
  }
  const from = data.value?.[0];
  const to = data.value?.[1];
  let result = '';

  if (from) {
    result += t.instant(`audit.auditCommon.from`) + ' ' + from.toString();
  }

  if (to) {
    if (from) {
      result += ' - ';
    }
    result += t.instant(`audit.auditCommon.to`) + ' ' + to.toString();
  }

  if (!from && !to) {
    result += t.instant(`audit.auditCommon.booleanNo`);
  }

  return {
    data: result,
  };
}

export function transformSendersRecipientsPerimetersAnalysis(
  data: PercipientCondition | PerimeterDataCondition | AnalysisDataCondition | null | undefined,
  t: TranslateService
): ParsedCondition {
  if (!data || !Array.isArray(data.value)) {
    return null;
  }

  let result = '';

  for (let i = 0; i < data.value.length; i++) {
    const element = data.value[i];

    if (typeof element === 'string') {
      continue;
    }

    if (element.TYPE) {
      /**
       * @translate audit.type.status
       * @translate audit.type.stamp
       * @translate audit.type.group
       * @translate audit.type.person
       * @translate audit.type.form
       * @translate audit.type.graphic
       * @translate audit.type.homography
       * @translate audit.type.table
       * @translate audit.type.text_object
       * @translate audit.type.classifier
       * @translate audit.type.fingerprint
       * @translate audit.type.category
       * @translate audit.type.card
       * @translate audit.type.autoling
       * @translate audit.type.meta
       */
      result += t.instant(`audit.type.${element.TYPE}`);
    }
    result += getCondition(data.is_negative);

    if (element.NAME) {
      result += element.NAME + ' ';
    }
  }
  return { data: result };
}

export function transformViolationLevel(data: InCondition | undefined, t: TranslateService): ParsedCondition {
  const result = data?.value
    ?.map((action) => {
      /**
       * @translate audit.commonAttributes.LOW
       * @translate audit.commonAttributes.MEDIUM
       * @translate audit.commonAttributes.HIGH
       * @translate audit.commonAttributes.NO
       * @translate audit.commonAttributes.TRANSFER
       * @translate audit.commonAttributes.COPY
       * @translate audit.commonAttributes.BLOCKADE
       * @translate audit.commonAttributes.PLACEMENT
       * @translate audit.commonAttributes.APPLICATION
       * @translate audit.commonAttributes.CLIPBOARD
       * @translate audit.commonAttributes.VIOLATION
       * @translate audit.commonAttributes.NOVIOLATION
       * @translate audit.commonAttributes.NOTPROCESSED
       * @translate audit.commonAttributes.ADDITIONALPROCESSINGNEEDED
       * @translate audit.commonAttributes.ALLOWED
       * @translate audit.commonAttributes.FORBIDDEN
       * @translate audit.commonAttributes.QUARANTINED
       * @translate audit.commonAttributes.NETWORKSHARESSEARCH
       * @translate audit.commonAttributes.LOCALDISKSOVERADMINSHARESSEARCH
       * @translate audit.commonAttributes.SP2007SEARCH
       */
      return t.instant(`audit.commonAttributes.${action.toUpperCase()}`);
    })
    .filter((x) => !!x)
    .join(', ');

  return result ? { data: result } : null;
}

export function transformObjectHeader(data: HeaderDataCondition, t: TranslateService): ParsedCondition {
  if (!data.value || data.value === undefined || data.value === null) {
    return null;
  }

  const value = data.value.value;

  if (typeof value === 'string') {
    return {
      data: value,
    };
  }

  if (typeof value === 'number') {
    return {
      data: `${value}`,
    };
  }

  /**
   * Value may be of date type
   */
  if (data.value.operation === 'between' && Array.isArray(data.value.value)) {
    return transformObjectHeaderBetweenDate(
      (data.value as unknown) as {
        operation: 'between';
        value: [number | null, number | null];
        name: string;
      },
      t
    );
  }

  if (Array.isArray(value)) {
    const result: string[] = [];
    for (let i = 0; i < value.length; i++) {
      const element = value[i];
      if (
        [
          'computer',
          'mobile',
          'terminal',
          'ICAP',
          'DM',
          'DM Mobile',
          'Adapter',
          'SMTPD',
          'Sniffer',
          'PushApi',
          'Crawler',
        ].includes(element)
      ) {
        let element = value[i];
        /**
         * @translate audit.objectHeader.computer
         * @translate audit.objectHeader.mobile
         * @translate audit.objectHeader.terminal
         * @translate audit.objectHeader.ICAP
         * @translate audit.objectHeader.DM
         * @translate audit.objectHeader.DMMobile
         * @translate audit.objectHeader.Adapter
         * @translate audit.objectHeader.SMTPD
         * @translate audit.objectHeader.Sniffer
         * @translate audit.objectHeader.PushApi
         * @translate audit.objectHeader.Crawler
         */
        if (element === 'DM Mobile') {
          element = 'DMMobile';
        }
        result.push(t.instant(`audit.objectHeader.${element}`));
      } else {
        result.push(element);
      }
    }
    return result.length ? { data: result.join(', ') } : null;
  }

  if (typeof data.value.value === 'string') {
    return {
      data: data.value.value,
    };
  }

  return {
    data: processUnknownData(data.value),
  };
}

export function transformObjectHeaderBetweenDate(
  data: {
    operation: 'between';
    value: [number | null, number | null];
    name: string;
  },
  t: TranslateService
): ParsedCondition {
  const from = data.value[0];
  const to = data.value[1];
  const format: string = data.name === 'task_run_date' ? 'f' : 'D';

  if (from !== null && to === null) {
    return {
      data: `${t.instant('audit.auditQuery.attrs.between.from')} ${DateTime.fromMillis(from * 1000)
        .toLocal()
        .toFormat(format)}`,
    };
  }

  if (to !== null && from === null) {
    return {
      data: `${t.instant('audit.auditQuery.attrs.between.to')} ${DateTime.fromMillis(to * 1000)
        .toLocal()
        .toFormat(format)}`,
    };
  }

  if (from !== null && to !== null) {
    return {
      data: data.value
        .map((sec: number) => {
          return DateTime.fromMillis(sec * 1000)
            .toLocal()
            .toFormat(format);
        })
        .join(' - '),
    };
  }

  if (from === null && to === null) {
    return {
      data: t.instant('audit.auditQuery.attrs.between.undefined'),
    };
  }

  return null;
}

export function getCondition(
  value?: '0' | '1' | 1 | 0 | '>' | '<' | '<=' | '>=' | '=' | null | undefined,
  t?: TranslateService
): string {
  switch (value) {
    case '1':
    case 1:
      return ' ≠ ';
    case '0':
    case 0:
      return ' = ';
    case '<':
      return t ? t.instant('audit.auditQuery.attrs.queryConditions.less') : value;
    case '>':
      return t ? t.instant('audit.auditQuery.attrs.queryConditions.more') : value;
    default:
      return ' : ';
  }
}

export function transformSourceDestinationCondition(
  data: FileExchangeDataCondition | null | undefined,
  t: TranslateService
): ParsedCondition {
  if (!data?.value) {
    return null;
  }

  let result = '';

  for (const [key, value] of Object.entries(data.value)) {
    if (key === 'both_sides') {
      result = transformDirection(value as BooleanAsString, t);
      result += ' ';
    } else {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const resType:
            | FixedCondition
            | RemovableCondition
            | NetshareCondition
            | TerminalCondition
            | FtpCondition
            | CloudCondition = value[i];

          /**
           *  @translate audit.type.destinations
           *  @translate audit.type.sources
           */
          result += t.instant(`audit.type.${key}`);
          result += ' = ';
          /**
           *  @translate audit.type.FTP
           *  @translate audit.type.NetworkResource
           *  @translate audit.type.TerminalSessionDevice
           *  @translate audit.type.RemovableDevice
           *  @translate audit.type.Computer
           *  @translate audit.type.CloudStorage
           *  @translate audit.type.LocalDevice
           */
          result += t.instant(`audit.type.${resType.TYPE}`);
          result += ' ';
          ['PATHS', 'IDS', 'NAMES'].forEach((name: 'PATHS' | 'IDS' | 'NAMES') => {
            const values = (resType as RemovableCondition)[name];
            if (values) {
              /**
               *  @translate audit.type.NAMES
               *  @translate audit.type.PATHS
               *  @translate audit.type.IDS
               */
              result += t.instant(`audit.type.${name}`);
              result += getCondition(values.is_negative);
              result += values.REGEXPS?.join(', ');
              result += ' ';
            }
          });
        }
      }
    }
  }
  return result.length ? { data: result } : null;
}

export function transformFileFormat(data: FileFormatCondition | undefined, t: TranslateService): string[] {
  const result: string[] = [];
  const condition = data?.value;
  const fileFormat: string | undefined = condition?.formats?.map((x: { NAME: string }) => x.NAME).join(', ');
  const isNegative = toBool(data?.is_negative);

  if (fileFormat) {
    result.push((isNegative ? '(≠) ' : '(=) ') + fileFormat);
  }

  if (condition?.merged) {
    result.push(t.instant('audit.auditQuery.attrs.queryFileFormatsConditions.merged'));
  }

  if (condition?.wrong_extension) {
    result.push(t.instant('audit.auditQuery.attrs.queryFileFormatsConditions.wrong_extension'));
  }

  if (condition?.encrypted) {
    result.push(t.instant('audit.auditQuery.attrs.queryFileFormatsConditions.encrypted'));
  }

  return result;
}

export function transformTextFormat(data: TextCondition | undefined, t: TranslateService): string[] {
  const result: string[] = [];
  const condition = data?.value;
  const yes = t.instant('audit.auditQuery.common.yes');
  const no = t.instant('audit.auditQuery.common.no');

  if (condition?.DATA) {
    result.push(
      t.instant(`audit.auditQuery.attrs.queryTextFormatsConditions.query`) +
        getCondition(data?.is_negative) +
        condition.DATA
    );
  }

  if (condition?.mode) {
    /**
     *  @translate audit.auditQuery.attrs.queryTextFormatsConditions.modes.all
     *  @translate audit.auditQuery.attrs.queryTextFormatsConditions.modes.any
     *  @translate audit.auditQuery.attrs.queryTextFormatsConditions.modes.exact
     *  @translate audit.auditQuery.attrs.queryTextFormatsConditions.modes.phrase
     *  @translate audit.auditQuery.attrs.queryTextFormatsConditions.modes.raw
     */
    result.push(
      t.instant(`audit.auditQuery.attrs.queryTextFormatsConditions.mode`) +
        ' = ' +
        t.instant(`audit.auditQuery.attrs.queryTextFormatsConditions.modes.${condition.mode}`)
    );
  }

  if (condition?.morphology) {
    result.push(
      t.instant(`audit.auditQuery.attrs.queryTextFormatsConditions.morphology`) +
        ' = ' +
        (condition.morphology ? yes : no)
    );
  }

  if (condition?.scope) {
    /**
     *  @translate audit.auditQuery.attrs.queryTextFormatsConditions.scopes.attachment
     *  @translate audit.auditQuery.attrs.queryTextFormatsConditions.scopes.filename
     *  @translate audit.auditQuery.attrs.queryTextFormatsConditions.scopes.object
     *  @translate audit.auditQuery.attrs.queryTextFormatsConditions.scopes.subject
     *  @translate audit.auditQuery.attrs.queryTextFormatsConditions.scopes.text
     */
    result.push(
      t.instant(`audit.auditQuery.attrs.queryTextFormatsConditions.scope`) +
        ' = ' +
        t.instant(`audit.auditQuery.attrs.queryTextFormatsConditions.scopes.${condition.scope}`)
    );
  }

  return result;
}

export function transformDirection(value: BooleanAsString | null | undefined, t: TranslateService): string {
  if (value === undefined || value === null) {
    return '';
  }

  if (+value === 1) {
    return t.instant('audit.both_direction_rule');
  }
  if (+value === 0) {
    return t.instant('audit.single_direction_rule');
  }

  return '';
}

export function transformWorkstations(data: WorkstationsDataCondition): ParsedCondition {
  if (!data || !Array.isArray(data.value)) {
    return null;
  }

  const queryData = [];
  let result = '';

  for (let i = 0; i < data.value.length; i++) {
    const element = data.value[i];

    if (typeof element === 'string') {
      continue;
    }

    if (element.NAME) {
      queryData.push(element.NAME);
    }
  }

  result += queryData.join(', ');

  let value: ParsedCondition = {
    data: result,
  };

  const negativeValue = value && data.is_negative ? transformNegative(data.is_negative) : null;

  if (value && negativeValue) {
    value.labelPostfix = negativeValue;
  }

  return result.length ? value : null;
}

export function transformQueries(
  data:
    | ResourcesDataCondition
    | ApplicationCondition
    | TagsDataCondition
    | PoliciesDataCondition
    | DocumentsDataCondition
): ParsedCondition {
  if (!data || !Array.isArray(data.value)) {
    return null;
  }

  const queryData = [];
  let result = '';

  for (let i = 0; i < data.value.length; i++) {
    const element = data.value[i];

    if (typeof element === 'string') {
      continue;
    }

    if (element.NAME) {
      queryData.push(element.NAME);
    }
  }

  result += queryData.join(', ');

  let value: ParsedCondition = {
    data: result,
  };

  const negativeValue = value && 'is_negative' in data ? transformNegative(data.is_negative) : null;

  if (value && negativeValue) {
    value.labelPostfix = negativeValue;
  }

  return result.length ? value : null;
}

export function transformFileSize(data: FileSizeCondition, t: TranslateService): ParsedCondition {
  if (data === undefined || data === null) {
    return {
      data: '',
    };
  }
  const startFileSize = getFileSize(data.value?.[0], data.size?.[0]);
  const finishFileSize = getFileSize(data.value?.[1], data.size?.[1]);
  let result = '';
  if (startFileSize) {
    /**
     *  @translate audit.fileSize.byte
     *  @translate audit.fileSize.KB
     *  @translate audit.fileSize.MB
     *  @translate audit.fileSize.GB
     */
    result +=
      t.instant(`audit.auditCommon.from`) +
      ' ' +
      startFileSize.toString() +
      ' ' +
      t.instant(`audit.fileSize.${data.size?.[0]}`);
  }
  if (finishFileSize) {
    if (startFileSize) {
      result += ' - ';
    }

    result +=
      t.instant(`audit.auditCommon.to`) + ' ' + finishFileSize + ' ' + t.instant(`audit.fileSize.${data.size?.[1]}`);
  }

  return {
    data: result,
  };
}

function getFileSize(value: number | undefined, size: string | undefined): number | null {
  if (value) {
    switch (size) {
      case 'KB':
        return value / 1024;
      case 'MB':
        return value / (1024 * 1024);
      case 'GB':
        return value / (1024 * 1024 * 1024);
      default:
        return value;
    }
  }
  return null;
}
