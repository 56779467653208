import { Injectable } from '@angular/core';
import { TmStatefulService } from '@tm-shared/dataloader';
import { shareReplay } from 'rxjs/operators';
import { ObjectHeaderInfo } from '../../typings/generated/bookworm';

@Injectable()
export class TmBookwormObjectHeaderService extends TmStatefulService<TmApi.GetResponse<ObjectHeaderInfo[]>> {
  public src = '/api/bookworm/objectHeaders';

  public sharedData = this.getDataStream().pipe(shareReplay(1));
}
