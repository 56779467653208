import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { TmFormComponent } from '@tm-shared/form';
import { PATTERN_STRING_1000, PATTERN_STRING_256_1 } from '@tm-shared/helpers/patterns/patterns';
import { merge } from 'rxjs';
import { delay, take, takeUntil } from 'rxjs/operators';
import { CategoryGet } from 'typings/generated/technology-imageClassifier';
import { ModalDialogRef } from '@platform/shared';

export type TmGraphicCategoryEditData = Partial<Pick<CategoryGet, 'DISPLAY_NAME' | 'CATEGORY_ID'>>;

@Component({
  selector: 'tm-graphic-category-edit',
  templateUrl: 'graphic-category-edit.component.html',
  styleUrls: ['./graphic-category-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmGraphicCategoryEditComponent extends TmFormComponent<FormGroup> implements OnInit {
  public categoryIsNew = false;
  @Input() public set data(data: TmGraphicCategoryEditData) {
    this.categoryIsNew = !(data && data.CATEGORY_ID);
    this.form.reset(data);
  }

  constructor(private _fb: FormBuilder, private _dialog: ModalDialogRef<unknown>) {
    super();

    this.form = this._fb.group({
      DISPLAY_NAME: [null, [Validators.required, Validators.pattern(PATTERN_STRING_256_1), Validators.maxLength(256)]],
      NOTE: [null, [Validators.pattern(PATTERN_STRING_1000), Validators.maxLength(1000)]],
    });
  }

  public ngOnInit(): void {
    merge(this.submit$, this.close$)
      .pipe(
        take(1),
        takeUntil(this._destroyed$),
        // Dismiss after decision emits
        delay(0)
      )
      .subscribe(() => {
        this._dialog.dismiss(null);
      });
  }
}
