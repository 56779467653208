import { Component, Input } from '@angular/core';
import { TmElement } from '@tm-shared/custom-elements';

@TmElement('tme-d-group')
@Component({
  selector: 'tm-d-group',
  template: '<div *ngIf="title" class="title">{{ title }}</div><ng-content></ng-content>',
  styleUrls: ['./description-group.component.scss'],
})
export class TmDescriptionGroupComponent {
  @Input() public title = '';
}
