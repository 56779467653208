<div class="color-picker">
  <div class="summary-info">
    <div
      [ngClass]="(disabled$ | async) ? 'disabled swatch' : 'swatch'"
      [ngStyle]="{ background: colorSubject$ | async }"
      [iwPopover]="changeColorPopover"
      #pickColor="iwPopover"
      data-qa="color-picker"
      placement="bottom-start"
    ></div>
  </div>
</div>

<ng-template #changeColorPopover>
  <div class="opened">
    <div class="colors">
      <button
        (click)="writeValue(paint)"
        *ngFor="let paint of defaultColors"
        class="preset swatch"
        [attr.data-qa]="paint"
        [ngStyle]="{ background: paint }"
      ></button>
    </div>
  </div>
</ng-template>
