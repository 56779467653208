import { stringifyAsJson } from './data';

export function toBoolAsInt(value?: string | number | boolean): number {
  switch (typeof value) {
    case 'string':
      return value === '0' || !value ? 0 : 1;
    case 'number':
    case 'boolean':
    default:
      return value ? 1 : 0;
  }
}

export function toBool(value?: string | number | boolean): boolean {
  switch (typeof value) {
    case 'string':
      return !!value && value !== '0';
    case 'number':
    case 'boolean':
    default:
      return Boolean(value);
  }
}

/**
 * Convert object to FormData instance
 *
 * @example
 * const newOfficerData = objectToFormData({
 *   firstName: 'Dude',
 *   lastName: 'Postal',
 *   age: 30,
 *   imageFile: new File(...),
 *   tags: ['violent', 'junky', 'sociopath']
 * });
 *
 * httpClient.post('api/user', newOfficerData)
 */
export function objectToFormData(obj: { [key: string]: any }): FormData {
  const formData = new FormData();

  if (!obj || !(obj instanceof Object)) {
    return formData;
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      appendToFormData(key, (obj as any)[key], formData);
    }
  }

  return formData;
}

const MAX_ARRAY_DEPTH = 10;
function appendToFormData(key: string, value: unknown, formData: FormData, _safetyCounter = 0): void {
  // Process arrays
  if (Array.isArray(value)) {
    if (_safetyCounter > MAX_ARRAY_DEPTH) {
      // eslint-disable-next-line
      console.error(`MAX_DEPTH=${MAX_ARRAY_DEPTH} is exceeded`);
      return;
    }

    for (let i = 0; i < value.length; i++) {
      appendToFormData(key, value[i], formData, _safetyCounter + 1);
    }
    return;
  }

  // Store undefined and null as "null"
  if (value === undefined || value === null) {
    formData.append(key, 'null');
    return;
  }

  // Store number as string
  if (typeof value === 'number') {
    formData.append(key, isNaN(value) ? 'null' : value.toString());
    return;
  }

  // Process File (add explicit filename)
  if (value instanceof File) {
    formData.append(key, value, value.name);
    return;
  }

  // Process regular FormData input value
  if (typeof value === 'string' || value instanceof Blob) {
    formData.append(key, value);
    return;
  }

  // Try to process other values as json
  // eslint-disable-next-line
  console.warn('Incorrect form data, fallback to json');
  formData.append(key, stringifyAsJson(value, true));
}
