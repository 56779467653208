import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import { TmConfigDiffComponentService } from '../configdiff/configdiff-component.service';

@Component({
  selector: 'tm-configdiff-item-details',
  templateUrl: './configdiff-item-details.component.html',
  styleUrls: ['./configdiff-item-details.component.scss'],
})
export class TmConfigDiffItemDetailsComponent implements OnInit {
  @Input() public data: TmPluginConfig.diff.DetailedItem;

  constructor(private _container: ViewContainerRef, private _service: TmConfigDiffComponentService) {}

  /**
   * Apply appropriate renderer (if any) for provided data
   */
  public ngOnInit() {
    let customElement = this._service.getRenderer(this.data.entity);

    if (customElement) {
      this._renderAsCustomElement(customElement, this.data);
    }
  }

  /**
   * Clear tempalte and render data with provided renderer
   */
  private _renderAsCustomElement(customElTag: string, data: TmPluginConfig.diff.DetailedItem): void {
    let el = document.createElement(customElTag) as NgElement &
      WithProperties<{ data: TmPluginConfig.diff.DetailedItem }>;
    this._container.clear();
    this._container.element.nativeElement.appendChild(el);
    el.data = data;
  }
}
