import { Component } from '@angular/core';
import { TmElement } from '@tm-shared/custom-elements';

@TmElement('tme-d-key')
@Component({
  selector: 'tm-d-key',
  template: '<ng-content></ng-content>',
  styleUrls: ['./description-key.component.scss'],
})
export class TmDescriptionValComponent {}
