import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { TmAdlibitumServerService, TmLdapUserSearchService, TmUserApiService } from '@tm-shared/api-services';
import { TmGridComponent, TmGridOptions } from '@tm-shared/grid';
import { CheckboxCellComponent } from '@tm-shared/grid/cell-renderers';
import { TmLdapServer } from '@tm-shared/ldap';
import { TmPersonSearchModel } from '@tm-shared/person-search';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TmPluginAccessUserProvider } from '../../access-exports';
import { UserModel } from '../user.model';

@Injectable()
export class LdapUserSelectService implements OnDestroy {
  public grid: TmGridComponent<TmPersonSearchModel>;

  public gridOptions: TmGridOptions = {
    domLayout: 'normal',
    columnDefs: [
      {
        width: 40,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        field: 'USERNAME',
        headerValueGetter: () => this._t.instant('settings-access.ldapUserSelect.ldap_user'),
      },
      {
        field: 'DOMAINNAME',
        headerValueGetter: () => this._t.instant('settings-access.search.headerNames.ldapPerson.mail'),
      },
      {
        field: 'ADDRESS',
        headerValueGetter: () => this._t.instant('settings-access.ldapUserSelect.server_address'),
      },
      {
        field: 'DEPARTMENT',
        headerValueGetter: () => this._t.instant('settings-access.ldapUserSelect.department'),
      },
    ],
  };

  public defaultTableParams = {
    server_id: '',
  };

  private form: FormGroup;

  private _destroyed$ = new Subject();

  constructor(
    private _t: TranslateService,
    private _adlibitumServerService: TmAdlibitumServerService,
    public ldapUserService: TmLdapUserSearchService,
    private _userApiService: TmUserApiService,
    private _notify: IwNotificationsService
  ) {}

  public getForm(): FormGroup {
    this.form = new FormGroup({
      server: new FormControl(null, Validators.required),
      search: new FormControl(null),
    });
    this._watchServerChanges();
    this._watchUserChanges();

    return this.form;
  }

  public getServerOptions() {
    return this._adlibitumServerService.getWithRetries().pipe(
      map((response) => response.data.map(this._marshalLdapServerModelToSelectOption)),
      tap((data) => {
        if (data.length) {
          this.form.get('server')!.patchValue(data[0].value);
        }
      })
    );
  }

  public getSubmitLogic() {
    return this.ldapUserService.getItemsByIds(this.grid.getAllSelectedIds()).pipe(
      switchMap((persons) => {
        return forkJoin(...persons.map((person) => this.mapPersonsToUserCreateRequests(person)));
      })
    );
  }

  public ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public mapPersonsToUserCreateRequests(person: TmPersonSearchModel): Observable<TmApi.user.CollectionItem | null> {
    const model: TmApi.user.CollectionItem = new UserModel();
    model.DISPLAY_NAME = person.NAME;
    model.ADDRESS = person.ADDRESS;
    model.DEPARTMENT = person.DEPARTMENT;
    model.DOMAINNAME = person.DOMAINNAME;
    model.EMAIL = person.EMAIL;
    model.IMPORTED = person.IMPORTED;
    model.NOTE = person.NOTE;
    model.OBJECTGUID = person.OBJECTGUID;
    model.USERNAME = person.USERNAME;
    model.PROVIDER = TmPluginAccessUserProvider.LDAP;
    model.LDAP_SERVER_ID = this.form.value.server;
    return this._userApiService.create(model).pipe(
      map((response) => response.data),
      catchError((response) => {
        if (response.error.meta) {
          if (response.error.meta.USERNAME && response.error.meta.USERNAME.not_unique_field) {
            const message =
              this._t.instant('settings-access.ldapUserSelect.ldap_user') +
              ' ' +
              person.USERNAME +
              ' ' +
              this._t.instant('settings-access.ldapUserSelect.duplicate_user');
            this._notify.warn(this._t.instant('settings-access.error'), message);
          }
          if (response.error.meta.EMAIL && response.error.meta.EMAIL.required) {
            const message =
              this._t.instant('settings-access.ldapUserSelect.required_field') +
              ' ' +
              this._t.instant('settings-access.user.fieldNames.email') +
              ' ' +
              this._t.instant('settings-access.ldapUserSelect.not_filled_for') +
              ' ' +
              person.USERNAME;
            this._notify.warn(this._t.instant('settings-access.error'), message);
          }
        }
        return of(null);
      })
    );
  }

  private _marshalLdapServerModelToSelectOption(server: TmLdapServer): IwSelectItem<TmLdapServer> {
    return {
      label: server.display_name,
      value: server.name,
      data: server,
    };
  }

  private _watchServerChanges() {
    this.form
      .get('server')!
      .valueChanges.pipe(
        tap((server) => {
          this.defaultTableParams.server_id = server;
          this.form.get('search')!.reset(null, { emitEvent: false });
        }),
        filter((server) => !!server && !!this.grid),
        tap((server) => {
          this.grid.updateFilter('query', '');
          this.grid.tableService.setTableParams({ server_id: server });
          this.grid.refresh();
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  private _watchUserChanges() {
    this.form
      .get('search')!
      .valueChanges.pipe(
        debounceTime(300),
        filter(() => !!this.grid),
        tap((user) => this.grid.updateFilterAndRefresh('query', user)),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }
}
