import { Injectable } from '@angular/core';
import { TmPolicyRuleService } from './policy-rule.service';

@Injectable()
export class TmPolicyService {
  constructor(private _ruleService: TmPolicyRuleService) {}

  public getEmptyPolicy(policyType: 'PERSON'): ApiPolicy.PolicyPerson;
  public getEmptyPolicy(policyType: 'AGENT'): ApiPolicy.PolicyAgent;
  public getEmptyPolicy(policyType: 'OBJECT'): ApiPolicy.PolicyObject;
  public getEmptyPolicy(policyType: ApiPolicy.PolicyType): any {
    let policyCommonProperties = {
      TYPE: policyType,
      STATUS: 1,
      NOTE: '',
      DATA: {
        ITEMS: [],
      },
    };

    switch (policyType) {
      case 'AGENT':
        return Object.assign(policyCommonProperties, {
          rules: this._ruleService.getEmptyAgentRules(),
        });
      case 'OBJECT':
        return Object.assign(policyCommonProperties, {
          rules: this._ruleService.getEmptyObjectRules(),
        });
      case 'PERSON':
        return Object.assign(policyCommonProperties, {
          rules: this._ruleService.getEmptyPersonRules(),
        });
      default:
        throw new Error('unknown policy type');
    }
  }
}
