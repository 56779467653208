import { ServerRange, DateRangeMode, DateRange } from './date-range.model';
import { sqlStringToDateTime } from '@tm-shared/helpers/date';
import { DateTime } from 'luxon';

export function isPredefinedMode(data: DateRange): boolean {
  return [
    DateRangeMode.none,
    DateRangeMode.thisDay,
    DateRangeMode.thisWeek,
    DateRangeMode.thisMonth,
    DateRangeMode.last3Days,
    DateRangeMode.last7Days,
    DateRangeMode.last30Days,
  ].includes(data.mode);
}

export function isVariableMode(data: DateRange): boolean {
  return [DateRangeMode.lastNHours, DateRangeMode.lastNDays].includes(data.mode);
}

export function isFromToMode(data: DateRange): boolean {
  return data.mode === DateRangeMode.fromTo;
}

/**
 * Get DateRange from TM API range format
 */
export function fromServerRange(data: ServerRange): DateRange {
  switch (data.interval) {
    case DateRangeMode.fromTo:
      return {
        mode: data.interval,
        from: sqlStringToDateTime(data.start_date).toJSDate(),
        to: sqlStringToDateTime(data.end_date).toJSDate(),
      };
    case DateRangeMode.lastNHours:
    case DateRangeMode.lastNDays:
      return {
        mode: data.interval,
        value: data.number,
      };
    case DateRangeMode.none:
    case DateRangeMode.thisDay:
    case DateRangeMode.thisWeek:
    case DateRangeMode.thisMonth:
    case DateRangeMode.last3Days:
    case DateRangeMode.last7Days:
    case DateRangeMode.last30Days:
      return {
        mode: data.interval,
      };
  }
}

/**
 * Convert DateRange to format compatible with the TM API
 */
export function toServerRange(data: DateRange): ServerRange {
  switch (data.mode) {
    case DateRangeMode.fromTo:
      // eslint-disable-next-line no-case-declarations
      const from = DateTime.fromJSDate(data.from).toUTC().toSQL({ includeOffset: false, includeZone: false });
      // eslint-disable-next-line no-case-declarations
      const to = DateTime.fromJSDate(data.to).toUTC().toSQL({ includeOffset: false, includeZone: false });

      if (!from || !to) {
        throw new Error('Bad from-to range values');
      }

      return {
        interval: data.mode,
        start_date: from,
        end_date: to,
      };
    case DateRangeMode.lastNHours:
    case DateRangeMode.lastNDays:
      return {
        interval: data.mode,
        number: data.value,
      };
    case DateRangeMode.none:
    case DateRangeMode.thisDay:
    case DateRangeMode.thisWeek:
    case DateRangeMode.thisMonth:
    case DateRangeMode.last3Days:
    case DateRangeMode.last7Days:
    case DateRangeMode.last30Days:
      return {
        interval: data.mode,
      };
  }
}
