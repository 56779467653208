/**
 * Wraps a number inside a range
 * @param x - number to wrap
 * @param range - [min, max] bounds
 *
 * @example
 *
 * // we need to calculate 23 hours + 3 hours, but we have only 24 hours a day:
 * // the result will be 2
 * wrapNum(23 + 3, [0, 23])
 *
 * // we need to calculate Wednesday (3) + 6 days:
 * // the result will be 2
 * wrapNum(3 + 6, [1, 7])
 */

export function wrapNum(x: number, range: [number, number]): number {
  const d = range[1] - range[0] + 1;
  return ((((x - range[0]) % d) + d) % d) + range[0];
}
