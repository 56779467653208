import { ITreeState, TreeNode } from '@circlon/angular-tree-component';

export type TmTreeIdType = string;

export type TmTreeNodeDataState = {
  isHidden?: boolean;
  isSelected?: boolean;
  isExpanded?: boolean;
  isDisabled?: boolean;
  isDisabledByDependency?: boolean;
};

export type TmTreeNodeData = TmTreeNodeDataState & {
  id: TmTreeIdType;
  name: string;
  value?: any;
  hasChildren?: boolean;
  children?: TmTreeNodeData[];
  dependencies?: ({ id: TmTreeIdType } & TmTreeNodeDataState)[];
  /**
   * Any payload you wish to use in custom templates
   */
  data?: any;
  /**
   * path to node in tree(for async loaded nodes)
   */
  ID_PATH?: string;
};

export type TmTreeState = {
  disabledNodeIds?: { [key: string]: boolean };
} & ITreeState;

export interface TmTreeNodeBuffer {
  node: TreeNode;
  action: string;
}

export const ACTIONS = {
  copy: 'copy',
  cut: 'cut',
};

export interface TmCatalog {
  CATALOG_ID: string;
  DISPLAY_NAME: string;
  ENABLED: number;
  OWNER: number;
  CREATE_DATE: string;
  CHANGE_DATE: string;
  GLOBALITY_TYPE: number;
}

export interface TmTreeDropEvent<T = any> {
  node: TreeNode;
  data: T;
}
