import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { TmTreeComponent, TmTreeNodeData } from '@tm-shared/tree';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TmAccessService } from './access-service';

@Component({
  selector: 'tm-access',
  templateUrl: 'access.component.html',
  styleUrls: ['./access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TmAccessService],
})
export class AccessComponent implements AfterViewInit {
  @ViewChild(TmTreeComponent, { static: false }) public treeComponent: TmTreeComponent;

  public tabs: Observable<TmTreeNodeData[]>;

  constructor(private _accessService: TmAccessService) {
    this.tabs = _accessService.takeAvailableTabs();
  }

  public ngAfterViewInit() {
    this.tabs.pipe(take(1)).subscribe((tabs) => this._setInitialTabsState(tabs));
  }

  private _setInitialTabsState(tabs: TmTreeNodeData[]) {
    // Try to get active tab from route
    if (!this._accessService.selectCurrentTreeNode(this.treeComponent) && tabs.length) {
      // If none - select first
      this.treeComponent.activateById(tabs[0].id);
    }
  }
}
