import { ChangeDetectionStrategy, Component, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IwPopoverDirective, IwPopoverOptions } from '@platform/shared';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'tm-color-picker',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TmColorPickerComponent),
      multi: true,
    },
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
      }),
    },
  ],
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmColorPickerComponent implements ControlValueAccessor {
  public value = '';
  @ViewChild('pickColor', { static: false }) public pickColor: IwPopoverDirective;
  @Input() public defaultColors: string[] = [
    '#4e954e',
    '#5484ed',
    '#a4bdfc',
    '#af5050',
    '#7ae7bf',
    '#cc7a29',
    '#898989',
    '#fbd75b',
    '#ffb878',
    '#ff0000',
    '#dc2127',
    '#dbadff',
    '#be9b33',
    '#8cc152',
    '#f6bb42',
    '#da4453',
    '#e9640b',
    '#0e71bd',
    '#3baa21',
    '#e02525',
  ];
  public colorSubject$ = new BehaviorSubject<string>(this.value);
  public disabled$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  /**
   * Change color from default colors
   * @param {string} color
   */
  public changeColor(color: string): void {
    if (this.pickColor) {
      this.pickColor.hide();
    }
    this.value = color;
    this.colorSubject$.next(this.value);
    this.onChange(color);
    this.onTouched();
  }

  public writeValue(value: string) {
    if (value) {
      this.changeColor(value);
    }
  }

  public onChange: any = () => {};
  public onTouched: any = () => {};

  public registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled$.next(isDisabled);
  }
}
