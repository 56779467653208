import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmAuditServiceModule } from './audit-service.module';
import {
  AuditEventOperation,
  AuditEventEntityType,
  AuditEvent,
  AuditEventLicense,
  AuditEventChange,
  AuditEventUserChange,
} from './audit.model';
import { getAuditAttributesFrom } from './audit-parsers/audit-common';
import { PolicyDiffAuditLog } from 'typings/generated/audit-policy';

@Injectable({
  providedIn: TmAuditServiceModule,
})
export class TmAuditService {
  constructor(protected t: TranslateService) {}

  public hasDetailedData(data: AuditEvent): boolean {
    const attrs = this.getAuditAttributesFrom(data);

    if (!attrs) {
      return false;
    }

    // TODO: add typings and remove any
    return ('rule' in attrs ? Object.keys((attrs as any).rule) : Object.keys(attrs)).some((x) => {
      return ['old', 'new', 'request'].includes(x);
    });
  }

  /**
   * Return localized change summary
   *
   * This is pretty tricky. Logic is taken from tm backbone.
   * We should try to show summary by entity type.
   */
  public getChangeSummary(auditEvent: AuditEvent): string {
    switch (auditEvent.ENTITY_TYPE as AuditEventEntityType) {
      case AuditEventEntityType.CrawlerScanner:
        return this.getCrawlerChangeSummary(auditEvent);
      case AuditEventEntityType.Notification:
        return this.getNotificationChangeSummary(auditEvent);
      case AuditEventEntityType.User:
        return this.getUserChangeSummary(auditEvent);
      case AuditEventEntityType.Policy:
        return this.getPolicyChangeSummary(auditEvent);
      case AuditEventEntityType.license:
        return this.getLicenseChangeSummary(auditEvent);
      default:
        return this.getDefaultChangeSummary(auditEvent);
    }
  }

  /**
   * Return instant localization of an entity
   */
  public getLocalizedEntityType(entityTypeKey: AuditEventEntityType): string {
    /**
     *
     * @translate audit.entityType.Adlibitum
     * @translate audit.entityType.Agent
     * @translate audit.entityType.AgentJob
     * @translate audit.entityType.Audit
     * @translate audit.entityType.Autoling
     * @translate audit.entityType.Category
     * @translate audit.entityType.CategoryAutoling
     * @translate audit.entityType.CategoryGraphic
     * @translate audit.entityType.Classifier
     * @translate audit.entityType.Config
     * @translate audit.entityType.CrawlerScanner
     * @translate audit.entityType.CrawlerTask
     * @translate audit.entityType.Dashboard
     * @translate audit.entityType.DashboardWidget
     * @translate audit.entityType.EtForm
     * @translate audit.entityType.EtStamp
     * @translate audit.entityType.EtTable
     * @translate audit.entityType.Fingerprint
     * @translate audit.entityType.ImageClassifier
     * @translate audit.entityType.LdapContact
     * @translate audit.entityType.LdapGroup
     * @translate audit.entityType.LdapPerson
     * @translate audit.entityType.LdapScreenshot
     * @translate audit.entityType.LdapSetting
     * @translate audit.entityType.LdapStatus
     * @translate audit.entityType.LdapWorkstation
     * @translate audit.entityType.LocalSetting
     * @translate audit.entityType.NetworkSettings
     * @translate audit.entityType.Notification
     * @translate audit.entityType.NotificationTemplate
     * @translate audit.entityType.NotificationSettings
     * @translate audit.entityType.Object
     * @translate audit.entityType.Export
     * @translate audit.entityType.Perimeter
     * @translate audit.entityType.Plugin
     * @translate audit.entityType.Policy
     * @translate audit.entityType.Privilege
     * @translate audit.entityType.ProtectedCatalog
     * @translate audit.entityType.ProtectedDocument
     * @translate audit.entityType.Query
     * @translate audit.entityType.QueryFolder
     * @translate audit.entityType.ReportFolder
     * @translate audit.entityType.QueryReport
     * @translate audit.entityType.QueryReportRun
     * @translate audit.entityType.QueryReportWidget
     * @translate audit.entityType.Role
     * @translate audit.entityType.ServiceLog
     * @translate audit.entityType.Setting
     * @translate audit.entityType.SystemList
     * @translate audit.entityType.SystemListItem
     * @translate audit.entityType.SmtpServer
     * @translate audit.entityType.Tag
     * @translate audit.entityType.Term
     * @translate audit.entityType.TextObject
     * @translate audit.entityType.Token
     * @translate audit.entityType.UpdateSystem
     * @translate audit.entityType.User
     * @translate audit.entityType.VisibilityArea
     * @translate audit.entityType.license
     */

    return this.t.instant(`audit.entityType.${entityTypeKey}`);
  }

  /**
   * Return instant localization of an operation
   */
  public getLocalizedOperation(operationKey: AuditEventOperation): string {
    /**
     * All keys are from enum AuditEventOperation
     *
     * @translate audit.operations.add
     * @translate audit.operations.add_role
     * @translate audit.operations.add_tag
     * @translate audit.operations.add_visibility_area
     * @translate audit.operations.apply_result_scan_job
     * @translate audit.operations.bruteforce
     * @translate audit.operations.canceled
     * @translate audit.operations.change
     * @translate audit.operations.change_password
     * @translate audit.operations.commit
     * @translate audit.operations.compile
     * @translate audit.operations.copy
     * @translate audit.operations.create
     * @translate audit.operations.create_log
     * @translate audit.operations.delete
     * @translate audit.operations.delete_hash
     * @translate audit.operations.delete_ref
     * @translate audit.operations.download
     * @translate audit.operations.draft
     * @translate audit.operations.export
     * @translate audit.operations.export_object
     * @translate audit.operations.import
     * @translate audit.operations.login
     * @translate audit.operations.logout
     * @translate audit.operations.move
     * @translate audit.operations.notification_test
     * @translate audit.operations.privileges_update
     * @translate audit.operations.privileges_create
     * @translate audit.operations.plugin_register
     * @translate audit.operations.plugin_update
     * @translate audit.operations.remove_role
     * @translate audit.operations.remove_tag
     * @translate audit.operations.remove_visibility_area
     * @translate audit.operations.restart
     * @translate audit.operations.rollback
     * @translate audit.operations.run
     * @translate audit.operations.start
     * @translate audit.operations.start_scan_job
     * @translate audit.operations.stop
     * @translate audit.operations.sync
     * @translate audit.operations.update
     * @translate audit.operations.update_job_schedule
     * @translate audit.operations.xapi_decision_update
     * @translate audit.operations.decision_update
     * @translate audit.operations.view
     */

    return this.t.instant(`audit.operations.${operationKey}`);
  }

  /**
   * Get changed audit event attributes
   */
  public getAuditAttributesFrom(auditEvent: AuditEvent): AuditEventChange | null {
    return getAuditAttributesFrom(auditEvent);
  }

  /**
   * Return user summary ($user_name ($user_login))
   */
  private getUserChangeSummary(auditEvent: AuditEvent): string {
    let result = auditEvent.ENTITY_DISPLAY_NAME || '';
    const attrs = this.getAuditAttributesFrom(auditEvent) as AuditEventUserChange | null;

    /**
     * Login / logout operations
     */
    if (attrs?.request && 'login' in attrs.request) {
      result = `${result} (${attrs.request.login})`;
    }

    return result;
  }

  /**
   * Return crawler summary
   */
  private getCrawlerChangeSummary(auditEvent: AuditEvent): string {
    /**
     * Check special cases
     */
    if (
      auditEvent.ENTITY_DISPLAY_NAME &&
      ['full_scan_job', 'apply_result_scan_job'].includes(auditEvent.ENTITY_DISPLAY_NAME)
    ) {
      /**
       * @translate audit.eventSummary.full_scan_job
       * @translate audit.eventSummary.apply_result_scan_job
       */
      return this.t.instant(`audit.eventSummary.${auditEvent.ENTITY_DISPLAY_NAME}`);
    }

    return this.getDefaultChangeSummary(auditEvent, true);
  }

  /**
   * Return notification summary
   */
  private getNotificationChangeSummary(auditEvent: AuditEvent): string {
    if (auditEvent.OPERATION === (AuditEventOperation.notificationTest as AuditEventOperation)) {
      return this.t.instant('audit.eventSummary.notificationTest');
    }

    return this.getDefaultChangeSummary(auditEvent, true);
  }

  /**
   * Return license summary ($due_date | $license_issuer")
   */
  private getLicenseChangeSummary(auditEvent: AuditEvent): string {
    let result = auditEvent.ENTITY_DISPLAY_NAME || '';
    const attrs = this.getAuditAttributesFrom(auditEvent) as AuditEventLicense;

    if (attrs) {
      result = `${attrs.DISPLAY_NAME} | ${result}`;
    }

    return result;
  }

  /**
   * Return policy summary
   */
  private getPolicyChangeSummary(auditEvent: AuditEvent): string {
    const attrs = this.getAuditAttributesFrom(auditEvent) as PolicyDiffAuditLog['PROPERTY_CHANGES'];

    if (attrs?.rule?.ENTITY_DISPLAY_NAME.startsWith('rule_')) {
      let result = '';
      /**
       * If we have display name in changedAttrs, most likely it is the policy name itself.
       */
      if ('DISPLAY_NAME' in attrs) {
        result += (attrs as any).DISPLAY_NAME || '';
      } else {
        result = auditEvent.ENTITY_DISPLAY_NAME || '';
      }

      /**
       * Check for rule types
       */
      if (
        ['rule_application', 'rule_blockade', 'rule_copy', 'rule_placement', 'rule_transfer', 'rule_person'].includes(
          attrs.rule.ENTITY_DISPLAY_NAME
        )
      ) {
        /**
         * @translate audit.eventSummary.rule_application
         * @translate audit.eventSummary.rule_blockade
         * @translate audit.eventSummary.rule_copy
         * @translate audit.eventSummary.rule_default
         * @translate audit.eventSummary.rule_placement
         * @translate audit.eventSummary.rule_transfer
         * @translate audit.eventSummary.rule_person
         */
        const ruleNameLocalized = this.t.instant(`audit.eventSummary.${attrs.rule.ENTITY_DISPLAY_NAME}`);

        if (attrs.rule.IS_SYSTEM) {
          const defaultRule = this.t.instant(`audit.eventSummary.rule_default`);
          return `${result} | ${ruleNameLocalized} | ${defaultRule}`;
        } else {
          return `${result} | ${ruleNameLocalized}`;
        }
      }
    }

    return this.getOldPolicyChange(auditEvent);
  }

  // < TM 7.1
  private getOldPolicyChange(auditEvent: AuditEvent) {
    const attrs = this.getAuditAttributesFrom(auditEvent) as PolicyDiffAuditLog['PROPERTY_CHANGES'];
    let result = '';
    if (attrs && 'DISPLAY_NAME' in attrs) {
      result += (attrs as any).DISPLAY_NAME || '';
    }
    /**
     * ENTITY_DISPLAY_NAME здесь может содержать тип правила, локализацию правила и название политики
     */
    if (auditEvent.ENTITY_DISPLAY_NAME.startsWith('rule_')) {
      /**
       * Check for rule types
       */
      if (
        ['rule_application', 'rule_blockade', 'rule_copy', 'rule_placement', 'rule_transfer', 'rule_person'].includes(
          auditEvent.ENTITY_DISPLAY_NAME
        )
      ) {
        /**
         * @translate audit.eventSummary.rule_application
         * @translate audit.eventSummary.rule_blockade
         * @translate audit.eventSummary.rule_copy
         * @translate audit.eventSummary.rule_default
         * @translate audit.eventSummary.rule_placement
         * @translate audit.eventSummary.rule_transfer
         * @translate audit.eventSummary.rule_person
         */
        const ruleNameLocalized = this.t.instant(`audit.eventSummary.${auditEvent.ENTITY_DISPLAY_NAME}`);
        return `${result} | ${ruleNameLocalized}`;
      }
    } else {
      return result ? `${result} | ${auditEvent.ENTITY_DISPLAY_NAME}` : auditEvent.ENTITY_DISPLAY_NAME;
    }

    return this.getDefaultChangeSummary(auditEvent, true);
  }

  /**
   * Default summary
   */
  private getDefaultChangeSummary(auditEvent: AuditEvent, reportUnknown: boolean = false): string {
    if (!auditEvent.ENTITY_DISPLAY_NAME || auditEvent.ENTITY_DISPLAY_NAME === 'untitled') {
      return reportUnknown ? this.t.instant('audit.eventSummary.unknown') : '';
    }

    return auditEvent.ENTITY_DISPLAY_NAME;
  }
}
