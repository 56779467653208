import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TmElement } from '@tm-shared/custom-elements';
import { TranslateService } from '@ngx-translate/core';
import { AuditEvent } from '../audit.model';
import { TmAuditExtendService, AuditExtendGridData } from '../audit-extend.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@TmElement('tme-extend-audit-show')
@Component({
  selector: 'tm-audit-item-extend',
  templateUrl: './audit-item-extend.component.html',
  styleUrls: ['./audit-item-extend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmAuditItemExtendComponent implements OnInit {
  @Input() public set data(data: AuditEvent) {
    if (this.initialData === data) {
      return;
    }

    this.resetGrids(data);
  }

  public get grids(): AuditExtendGridData[] | undefined {
    this.checkGridsAreInitialized();
    return this.extendGrids;
  }

  public moreInfoIsShowed = false;

  private initialData: AuditEvent;

  private extendGrids?: AuditExtendGridData[];

  constructor(private service: TmAuditExtendService, private t: TranslateService) {}

  public toggleShow(): void {
    this.moreInfoIsShowed = !this.moreInfoIsShowed;
  }

  public ngOnInit(): void {
    this.t.onLangChange.pipe(untilDestroyed(this)).subscribe(() => this.resetGrids(this.initialData));
  }

  private resetGrids(data: AuditEvent): void {
    this.initialData = data;
    delete this.extendGrids;

    if (this.moreInfoIsShowed) {
      this.checkGridsAreInitialized();
    }
  }

  private checkGridsAreInitialized(): void {
    if (!this.extendGrids) {
      this.extendGrids = this.service.getAuditExtendGrids(this.initialData);
    }
  }
}
