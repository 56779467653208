<tm-content title="{{ 'settings-access.role.title' | translate }}">
  <tm-toolbar>
    <button
      iw-icon-button
      type="button"
      [disabled]="!(canCreate$ | async)"
      name="{{ 'settings-access.role.toolbar.create' | translate }}'"
      (click)="showDialogCreate()"
    >
      <tm-icon svgIcon="create"></tm-icon>
    </button>
    <button
      iw-icon-button
      type="button"
      [disabled]="!(canRemove$ | async)"
      tmDeleteConfirm
      [tmDeleteConfirmDialogData]="{
        roles: grid?.allSelected$ | async,
        title: 'settings-access.confirmAction' | translate
      }"
      [tmDeleteConfirmDialog]="RoleDeleteModalComponent"
      (tmDeleteConfirm)="deleteSelectedItems()"
      name="{{ 'settings-access.role.toolbar.remove' | translate }}"
    >
      <tm-icon svgIcon="delete"></tm-icon>
    </button>
  </tm-toolbar>

  <tm-grid #grid class="grid" [service]="service" [gridOptions]="gridOptions" [tableConfig]="defaultTableParams">
  </tm-grid>
</tm-content>

<tm-sidebar
  [disabled]="!router.url.includes(CREATE_PATH) && !router.url.includes(EDIT_PATH)"
  class="sidebar"
  #sidebar
  direction="right"
  [isCollapsed]="true"
>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</tm-sidebar>
