import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import ConditionChild = TmApi.scope.ConditionChild;
import ConditionNode = TmApi.scope.ConditionNode;

@Injectable()
export class TmScopeApiService extends TmCollectionLoader<TmApi.scope.CollectionItem> {
  public readonly src = '/api/visibilityArea';
  public idAttribute = 'VISIBILITY_AREA_ID';

  public createRootNode(): TmApi.scope.ConditionNode {
    return { link_operator: 'and', children: [] };
  }

  public createScopeModel(): TmApi.scope.CollectionItem {
    const model = {} as TmApi.scope.CollectionItem;
    model.VISIBILITY_AREA_CONDITION = { data: this.createRootNode() };
    return model;
  }

  public getValue(children: TmApi.scope.Condition[], condition: string): TmApi.scope.ConditionValue[] | undefined {
    let value = undefined;
    const getData = (child: TmApi.scope.Condition): boolean => {
      if (this._isConditionChild(child) && child.category === condition) {
        value = child.value;
        return true;
      }

      return this._isConditionNode(child) && child.children.some(getData);
    };
    children.some(getData);
    return value;
  }

  public getValueWithCondition(children: TmApi.scope.Condition[], isNegative: string, condition: string) {
    let scopeCondition: TmApi.scope.Condition[] = [];
    this.processScopeCondition(children, scopeCondition);
    for (const child of scopeCondition) {
      if (this._isConditionChild(child) && child.category === condition && child.is_negative === isNegative) {
        return child.value;
      }
    }
  }

  private processScopeCondition(children: TmApi.scope.Condition[], scopeCondition: TmApi.scope.Condition[]): void {
    for (const child of children) {
      if (this._isConditionNode(child)) {
        this.processScopeCondition(child.children, scopeCondition);
      } else {
        if (child.is_negative === undefined) {
          child.is_negative = '0';
        }
        scopeCondition.push(child);
      }
    }
  }

  private _isConditionChild(condition: TmApi.scope.Condition): condition is ConditionChild {
    return 'category' in condition;
  }

  private _isConditionNode(condition: TmApi.scope.Condition): condition is ConditionNode {
    return 'children' in condition;
  }
}
