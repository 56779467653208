<div #container class="container">
  <div #topPad class="pad top" [style.height.px]="offsetTop"></div>
  <div
    *ngFor="let block of blocksInLoadport"
    data-lazy-block
    class="lazyBlock"
    [ngClass]="{ loading: block.loading, error: !block.state }"
    [attr.data-index]="block.index"
  >
    <!-- Display loading state -->
    <tm-spinner class="spinner" *ngIf="block.loading"></tm-spinner>
    <!-- Support HTML -->
    <div *ngIf="block.supportHtml" [innerHTML]="block.content"></div>
    <!-- Support plain text -->
    <ng-container *ngIf="!block.supportHtml">{{ block.content }}</ng-container>
  </div>
  <div #bottomPad class="pad bottom" [style.height.px]="offsetBottom"></div>
</div>
