<iw-form *ngIf="form" class="form" [formGroup]="form" (ngSubmit)="submit()" iwFormSubmit>
  <header>{{ title }}</header>
  <iw-form-block>
    <iw-form-row>
      <label iwFormRowLabel>{{ 'lists-resources.group.form.display-name' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <input
          iwInput
          type="text"
          placeholder="{{ 'lists-resources.group.form.placeholder.display-name' | translate }}"
          data-qa="resources_display_name"
          tmAutofocus
          formControlName="DISPLAY_NAME"
        />

        <!--
                @translate lists-resources.group.validation.required
                @translate lists-resources.group.validation.empty
                @translate lists-resources.group.validation.maxlength
                @translate lists-resources.group.validation.not_unique_field
                @translate lists-resources.group.validation.validationError
              -->
        <div data-qa="resources-display-name-error" iwErrorHints *ngFor="let error of getErrors('DISPLAY_NAME')">
          {{ 'lists-resources.group.validation.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>
    <iw-form-row>
      <label iwFormRowLabel>{{ 'lists-resources.group.form.note' | translate }}</label>
      <iw-input-container iwFormRowContent>
        <textarea iwInput formControlName="NOTE" data-qa="resources_note" maxlength="1025"></textarea>

        <!--
          @translate lists-resources.group.validation.maxlength
        -->
        <div iwErrorHints *ngFor="let error of getErrors('NOTE')">
          {{ 'lists-resources.group.validation.' + error.key | translate: error.value }}
        </div>
      </iw-input-container>
    </iw-form-row>
    <div class="dates" *ngIf="createDate && changeDate">
      {{ 'lists-resources.group.created' | translate }}: <span [tmLocalizeDate]="createDate"></span> —
      {{ 'lists-resources.group.changed' | translate }}: <span [tmLocalizeDate]="changeDate"></span>
    </div>
  </iw-form-block>

  <div class="footer">
    <iw-form-toolbar>
      <button
        iw-button
        type="submit"
        color="success"
        data-qa="resources_save"
        [disabled]="form.invalid || form.disabled || form.status === 'PENDING'"
      >
        {{ 'lists-resources.group.form.save' | translate }}
      </button>
      <button iw-button type="button" (click)="close()">
        {{ 'lists-resources.group.form.cancel' | translate }}
      </button>
    </iw-form-toolbar>
  </div>
</iw-form>
