import { Injectable } from '@angular/core';
import { TmCollectionLoader } from '@tm-shared/dataloader';

/**
 * Config history service v1
 * TODO: remove after migration to v2 (/api/configdiff)
 */
@Injectable()
export class TmConfigLogService extends TmCollectionLoader<TmApi.configLog.CollectionItem[]> {
  public readonly src = '/api/configLog';
}
