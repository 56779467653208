import { HttpErrorResponse } from '@angular/common/http';
import { Input, OnDestroy, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { EMPTY, Observable, Subject, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';

const PATH_DELIMITER = '.';

export type FormControlPath = string | (string | number)[];

/**
 * Use disabledValueOverride to set defaults instead of current value on disabled controls
 */
export type GetValueOptions<T = any> = {
  excludePristine?: boolean;
  excludeDisabled?: boolean;
  disabledValueOverride?: T;
};

/**
 * @Directive is used due to Angular 10  DI system:
 * https://angular.io/guide/migration-undecorated-classes
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class TmFormComponent<T extends AbstractControl = AbstractControl> implements OnDestroy {
  /**
   * Allow to use external callback to follow the single responsibility principle and
   * reduce component's responsibility and dependencies to improve testability and lower complexity,
   * also it makes it possible to implement forms as simple tme- elements in tmbb.
   */
  @Input() public onSubmit?: (tmForm: this) => Observable<any>;

  /**
   * Awaiting server response on save request
   */
  public saveInProgress = false;

  public get changedValues(): any {
    if (this.form instanceof FormGroup) {
      const controls = this.form.controls;
      const controlIsEnabled = this.form.enabled;
      return Object.keys(this.form.controls).reduce((result: any, key: string) => {
        if (controls[key].enabled === controlIsEnabled && controls[key].valid && controls[key].dirty) {
          result[key] = controls[key].value;
        }

        return result;
      }, {});
    }

    if (this.form instanceof FormArray) {
      return this.form.controls.reduce((result: any, control: AbstractControl) => {
        if (control.valid && control.dirty) {
          result.push(control.value);
        }

        return result;
      }, []);
    }

    throw new Error('Unknown form control');
  }

  /**
   * Show dialog when user tries to close form with unsaved data
   */
  @Input() public confirmDataLoss = true;

  /**
   * Show dialog when user tries to submit form data
   */
  @Input() public confirmDataSubmit = false;

  /**
   * Stream: used by router on navigation
   */
  public canClose$: Observable<boolean> = of(null).pipe(
    switchMap(() => (this.isDirty() && this.confirmDataLoss ? this.confirmDataLoss$ : of(true)))
  );

  /**
   * Stream: confirm form data loss
   */
  @Input() public confirmDataLoss$: Observable<boolean> = of(true);

  /**
   * Stream: confirm form data submit
   */
  @Input() public confirmDataSubmit$: Observable<boolean> = of(true);

  /**
   * Form group
   */
  public form: T;
  public close$: Observable<void> = of(null).pipe(switchMap(() => this._close$));
  public submit$: Observable<void> = of(null).pipe(switchMap(() => this._submit$));

  /**
   * Stream: component is destroyed
   */
  protected _destroyed$: Subject<void> = new Subject();
  private _confirmDataLossReq$?: Observable<boolean>;

  /**
   * Stream: default close component logic.
   */
  private _close$: Subject<void> = new Subject();

  /**
   * Stream: submit form data
   */
  private _submit$: Subject<void> = new Subject();

  /**
   * Clean up component on destroy event
   */
  public ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Default close form behavior
   */
  public close(doNotConfirm: boolean = false): Observable<boolean> {
    if (this._confirmDataLossReq$) {
      return this._confirmDataLossReq$;
    }

    const close$ = (this._confirmDataLossReq$ = of(null).pipe(
      takeUntil(this._destroyed$),
      switchMap(() => (this.isDirty() && !doNotConfirm ? this.confirmDataLoss$ : of(true))),
      shareReplay(1)
    ));

    close$.pipe(take(1)).subscribe((confirmed) => {
      if (confirmed) {
        this.form.reset();
        this._close$.next();
      }

      delete this._confirmDataLossReq$;
    });
    return close$;
  }

  public disable(path?: FormControlPath): void {
    const control = this.get(path);

    if (control) {
      control.disable();
    }
  }

  public enable(path?: FormControlPath): void {
    const control = this.get(path);

    if (control) {
      control.enable();
    }
  }

  public get(path?: FormControlPath): AbstractControl | null {
    return path ? (this.form && this.form.get(path)) || null : this.form;
  }

  /**
   * Check if there's any unsaved changes
   */
  public isDirty(path?: FormControlPath): boolean {
    const control = this.get(path);

    return control ? control.dirty : false;
  }

  /**
   * Check if control is disabled
   */
  public isDisabled(path?: FormControlPath): boolean {
    if (!path) {
      return Boolean(this.form && this.form.disabled);
    }

    return Boolean(this.form && this.form.get(path) && this.form.get(path)!.disabled);
  }

  /**
   * Check if control is enabled
   */
  public isEnabled(path?: FormControlPath): boolean {
    return !this.isDisabled(path);
  }

  public getErrors<T = any>(path?: FormControlPath): { key: string; value: T }[] {
    const errors = this.get(path)?.errors || ({} as ValidationErrors);

    return Object.keys(errors).map((key) => ({ key, value: errors[key] }));
  }

  /**
   * Reset data
   */
  public reset(force: boolean = false): Observable<boolean> {
    const reset$ = of(null).pipe(
      takeUntil(this._destroyed$),
      switchMap(() => (!this.isDirty() || force ? of(true) : this.confirmDataLoss$)),
      take(1),
      shareReplay(1)
    );

    reset$.subscribe();
    return reset$;
  }

  public validateAndSubmit(): Observable<boolean> {
    this.form.updateValueAndValidity();
    return this.submit();
  }

  /**
   * Default submit form behavior
   */
  public submit(): Observable<boolean> {
    if (this.form.invalid) {
      return EMPTY;
    }

    this.saveInProgress = true;

    const submit$ = (this.confirmDataSubmit ? this.confirmDataSubmit$ : of(true)).pipe(
      switchMap((confirmed) => {
        return confirmed
          ? this._onSubmit().pipe(
              map(() => {
                this._submit$.next();
                return true;
              }),
              catchError((e) => {
                this.setErrorsAfterSubmit(e);
                return of(false);
              })
            )
          : of(false);
      }),
      shareReplay(1)
    );

    submit$.pipe(take(1)).subscribe({
      complete: () => (this.saveInProgress = false),
    });

    return submit$;
  }

  /**
   * Get control value by path
   * it's shortcut for: form.get(path) && form.get(path).value || undefined
   * with optional filters: pristine, disabled
   */
  public value<Value = any>(path: FormControlPath = '', options: GetValueOptions<Value> = {}): Value | undefined {
    const control = this.get(path);

    if (!control) {
      return undefined;
    }

    if (options.excludeDisabled && control.disabled) {
      return undefined;
    }

    if (options.excludePristine && control.pristine) {
      return undefined;
    }

    if (control.disabled && 'disabledValueOverride' in options) {
      return options.disabledValueOverride;
    } else {
      return control.value;
    }
  }

  /**
   * Get control path string representation
   */
  protected controlPathAsString(path: FormControlPath): string {
    if (typeof path === 'string') {
      return path;
    }

    return Array.isArray(path) ? path.join(PATH_DELIMITER) : '';
  }

  /**
   * Get control path from it's string representation
   */
  protected controlPathFromString(path: string): FormControlPath {
    return path ? path.split(PATH_DELIMITER) : '';
  }

  /**
   * Submit data logic
   */
  protected _onSubmit(): Observable<any> {
    if (this.onSubmit) {
      return this.onSubmit(this);
    }

    return of(true);
  }

  /**
   * if validation of server submit failed, set errors to controls
   */
  protected setErrorsAfterSubmit(response: HttpErrorResponse): void {
    const data = response.error;
    if (data && data.meta) {
      Object.keys(data.meta).forEach((controlName) => {
        if (this.form.get(controlName) || this.form.get(controlName.toLowerCase())) {
          (this.form.get(controlName) || this.form.get(controlName.toLowerCase()))!.setErrors(data.meta[controlName]);
        }
      });
    }
  }
}
