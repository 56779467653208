import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { TmChannelService } from '@tm-shared/channel';
import { TmStatefulService } from '@tm-shared/dataloader/stateful.service';
import { UrlStreams } from '@tm-shared/url-streams';
import { DateTime } from 'luxon';
import { Observable, Subject, empty, merge, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, switchMapTo, takeUntil } from 'rxjs/operators';
import { TmPrivilegesService } from '../../../@tm-shared/privileges';

/**
 * Configuration service with affiliate support
 */
@Injectable()
export class TmConfigAffiliateService extends TmStatefulService<TmApi.GetResponse<TmPluginConfig.affiliate.MergedData>>
  implements OnDestroy {
  public readonly src = UrlStreams.create('/api/config/affiliate');

  public localConfig$: Observable<TmPluginConfig.affiliate.LocalConfig> = of(null).pipe(
    switchMap(() => this.getDataWithUpdatesStream())
  );

  public agentConfig$: Observable<TmPluginConfig.affiliate.AgentConfig> = of(null).pipe(
    switchMap(() => this.getDataWithUpdatesStream())
  );

  public inboundConfig$ = of(null).pipe(
    switchMap(() => this.getDataWithUpdatesStream()),
    filter((data) => Boolean(data.local && data.local.version)),
    map((data) => data.local.version),
    filter((data) => Boolean(data.INBOUND_VERSION_TAG && data.INBOUND_CHANGE_DATE))
  ) as Observable<TmPluginConfig.affiliate.InboundConfig>;

  public outboundConfig$ = of(null).pipe(
    switchMap(() => this.getDataWithUpdatesStream()),
    filter((data) => Boolean(data.local && data.local.version)),
    map((data) => data.local.version),
    filter((data) => Boolean(data.OUTBOUND_VERSION && data.OUTBOUND_CHANGE_DATE))
  ) as Observable<TmPluginConfig.affiliate.OutboundConfig>;

  public queuedInboundConfig$ = of(null).pipe(
    switchMap(() => this.getDataWithUpdatesStream()),
    filter((data) => Boolean(data.global)),
    map((data) => data.global!)
  );

  public dataWithUpdates$: Observable<TmPluginConfig.affiliate.MergedData> = of(null).pipe(
    switchMap(() => merge(this.data$.pipe(map((response) => response.data)), this._getChannelStream())),
    shareReplay(1)
  );

  private _destroy$: Subject<void> = new Subject();

  private _stopSocketNotifications$: Subject<void> = new Subject();

  constructor(
    http: HttpClient,
    private _t: TranslateService,
    @Optional() privileges?: TmPrivilegesService,
    @Optional() private _wsService?: TmChannelService,
    @Optional() private _notify?: IwNotificationsService
  ) {
    super(http);

    if (privileges && _wsService && _notify) {
      privileges
        .can('affiliate')
        .pipe(takeUntil(this._destroy$))
        .subscribe((ok) => {
          ok ? this.startSocketNotifications() : this.stopSocketNotifications();
        });
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public getDataWithUpdatesStream(
    retryInMs: number = -1,
    retryLimit?: number
  ): Observable<TmPluginConfig.affiliate.MergedData> {
    return this.getDataStream(retryInMs, retryLimit).pipe(switchMapTo(this.dataWithUpdates$));
  }

  public startSocketNotifications(): void {
    this._stopSocketNotifications$.next();

    if (!this._notify) {
      return;
    }

    this.inboundConfig$.pipe(takeUntil(this._stopSocketNotifications$)).subscribe((data) => {
      const version = data.INBOUND_VERSION_TAG;
      const date = DateTime.fromISO(data.INBOUND_CHANGE_DATE).toFormat('DD HH:mm');

      this._notify!.info(
        this._t.instant('config.notifications.queuedInbound.title', { version, date }),
        this._t.instant('config.notifications.queuedInbound.description')
      );
    });
  }

  public stopSocketNotifications(): void {
    this._stopSocketNotifications$.next();
  }

  private _getChannelStream(): Observable<TmPluginConfig.affiliate.MergedData> {
    if (!this._wsService) {
      return empty();
    }

    return this._wsService.getChannel('service_config').pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }
}
