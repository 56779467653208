import { NgModule } from '@angular/core';

import { LocalizeDateDirective } from './localize-date.directive';

@NgModule({
  declarations: [LocalizeDateDirective],
  imports: [],
  exports: [LocalizeDateDirective],
  providers: [],
})
export class TmLocalizeDateModule {
  constructor() {}
}
