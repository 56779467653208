import { Injectable } from '@angular/core';
import { EVENT_QUERY_COLUMNS_ORDERED } from './events-query-columns-ordered';

@Injectable()
export class TmEventsQueryService {
  public getDefaultQueryColumns(): TmPluginEvents.query.ColumnPredefined[] {
    return EVENT_QUERY_COLUMNS_ORDERED;
  }

  public getColumnByKey(key: string): TmPluginEvents.query.Column {
    return (
      EVENT_QUERY_COLUMNS_ORDERED.find((col) => col.key === key) || {
        type: 'plugin_attribute',
        key: key,
      }
    );
  }

  public pickSortableColumnsFrom(columns: TmPluginEvents.query.Column[]): TmPluginEvents.query.Column[] {
    return columns.filter((c) => c.sortable);
  }

  /**
   * get columns which can be selected for table view
   */
  public pickColumnsForSelection(columns: TmPluginEvents.query.Column[]): TmPluginEvents.query.Column[] {
    const itemsToRemove = ['TEXT'];
    return columns.filter((item) => !itemsToRemove.includes(item.key));
  }
}
